import { RootEpic } from "src/app/store/root.epic";
import { filter, map } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { changePasswordAsync, createUserAsync, deleteUserByIdAsync, fetchUsersAsync, loginAsync, logoutAsync, resetPasswordAsync } from "src/app/store/features/user/user.actions";
import { apiAsync } from "src/app/store/features/api/api.actions";

export const loginEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(loginAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/login",
				method: "POST",
				data: action.payload,
				onSuccess: loginAsync.success,
				onFailure: loginAsync.failure,
			}),
		),
	);

export const logoutEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(logoutAsync.request)),
		map(_ =>
			apiAsync.request({
				url: "/auth/logout",
				method: "POST",
				onSuccess: logoutAsync.success,
				onFailure: logoutAsync.failure,
			}),
		),
	);

export const changePasswordEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(changePasswordAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/user/password/change",
				method: "POST",
				data: action.payload,
				onSuccess: changePasswordAsync.success,
				onFailure: changePasswordAsync.failure,
			}),
		),
	);

export const fetchUsersEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchUsersAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/user/list",
				method: "GET",
				onSuccess: fetchUsersAsync.success,
				onFailure: fetchUsersAsync.failure,
			}),
		),
	);

export const createUserEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(createUserAsync.request)),
		map((action) =>
			apiAsync.request({
				url: "/user/add",
				method: "POST",
				data: action.payload,
				onSuccess: createUserAsync.success,
				onFailure: createUserAsync.failure,
			}),
		),
	);

export const deleteUserByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(deleteUserByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/user/remove",
				method: "POST",
				data: action.payload,
				onSuccess: deleteUserByIdAsync.success,
				onFailure: deleteUserByIdAsync.failure,
			}),
		),
	);

export const resetPasswordEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(resetPasswordAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/user/password/reset",
				method: "POST",
				data: action.payload,
				onSuccess: resetPasswordAsync.success,
				onFailure: resetPasswordAsync.failure,
			}),
		),
	);