import { PhaseVoltageConfiguration, SetConfigurationPayload, SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import { VoltageRmsForm } from "src/app/components/MeasurementsUser/VoltageRmsCard.component";
import { FrequencyForm } from "src/app/components/MeasurementsUser/FrequencyCard.component";
import { FlicketPstForm } from "src/app/components/MeasurementsUser/FlickerPstCard.component";
import { FormItem } from "src/app/types/ui/form.types";
import { FlickerPltForm } from "src/app/components/MeasurementsUser/FlickerPltCard.component";
import { CurrentRmsForm } from "src/app/components/MeasurementsUser/CurrentRmsCard.component";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { ReactivePowerForm } from "src/app/components/MeasurementsUser/ReactivePowerCard.component";
import { ApparentPowerForm } from "src/app/components/MeasurementsUser/ApparentPowerCard.component";
import { ActivePowerPlusForm } from "src/app/components/MeasurementsUser/ActivePowerPlusCard.component";
import { ActivePowerMinusForm } from "src/app/components/MeasurementsUser/ActivePowerMinusCard.component";
import { DisplacementPowerFactorForm } from "src/app/components/MeasurementsUser/DisplacementPowerFactorCard.component";
import { PowerFactorForm } from "src/app/components/MeasurementsUser/PowerFactorCard.component";
import { VoltageThdfForm } from "src/app/components/MeasurementsUser/VoltageThdfCard.component";
import { CurrentThdfForm } from "src/app/components/MeasurementsUser/CurrentThdfCard.component";
import { Nullable } from "src/app/types/util.types";

export const isChannelsDiff = (formItem: FormItem<string[]>): boolean => {
	return formItem.value.length !== formItem.initialValue.length || formItem.initialValue.some(value => !formItem.value.includes(value));
};

export const mapDipsConfig = (cfgVoltageRms: Nullable<PhaseVoltageConfiguration>): [ channels: VoltageRmsForm["cfgDipChannels"], actions: number ] => {
	let cfgDipChannels: VoltageRmsForm["cfgDipChannels"] = [];
	let cfgDipActions = 0;
	if (isNull(cfgVoltageRms)) return [ cfgDipChannels, cfgDipActions ];
	if (isNull(cfgVoltageRms)) return [ cfgDipChannels, cfgDipActions ];
	if ((cfgVoltageRms?.cfgRelayVoltageDip1RecOpt?.value ?? 0) > 0) {
		cfgDipChannels.push("cfgRelayVoltageDip1RecOpt");
		cfgDipActions = Math.max(cfgVoltageRms?.cfgRelayVoltageDip1Map?.value ?? 0, cfgDipActions);
	}
	if ((cfgVoltageRms?.cfgRelayVoltageDip2RecOpt?.value ?? 0) > 0) {
		cfgDipChannels.push("cfgRelayVoltageDip2RecOpt");
		cfgDipActions = Math.max(cfgVoltageRms?.cfgRelayVoltageDip2Map?.value ?? 0, cfgDipActions);
	}
	if ((cfgVoltageRms?.cfgRelayVoltageDip3RecOpt?.value ?? 0) > 0) {
		cfgDipChannels.push("cfgRelayVoltageDip3RecOpt");
		cfgDipActions = Math.max(cfgVoltageRms?.cfgRelayVoltageDip3Map?.value ?? 0, cfgDipActions);
	}
	if ((cfgVoltageRms?.cfgRelayVoltageDip4RecOpt?.value ?? 0) > 0) {
		cfgDipChannels.push("cfgRelayVoltageDip4RecOpt");
		cfgDipActions = Math.max(cfgVoltageRms?.cfgRelayVoltageDip4Map?.value ?? 0, cfgDipActions);
	}
	return [
		cfgDipChannels,
		cfgDipActions,
	];
};

export const translateDipsConfig = (
	cfgVoltageRms: Nullable<PhaseVoltageConfiguration>,
	channels: VoltageRmsForm["cfgDipChannels"],
	actions: number,
): SetConfigurationPayload => {
	if (isNull(cfgVoltageRms)) return {};

	let dip1RecOptValue = 0;
	let dip2RecOptValue = 0;
	let dip3RecOptValue = 0;
	let dip4RecOptValue = 0;
	if (channels.includes("cfgRelayVoltageDip1RecOpt")) {
		dip1RecOptValue = (cfgVoltageRms?.cfgRelayVoltageDip1RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayVoltageDip2RecOpt")) {
		dip2RecOptValue = (cfgVoltageRms?.cfgRelayVoltageDip2RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayVoltageDip3RecOpt")) {
		dip3RecOptValue = (cfgVoltageRms?.cfgRelayVoltageDip3RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayVoltageDip4RecOpt")) {
		dip4RecOptValue = (cfgVoltageRms?.cfgRelayVoltageDip4RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	return {
		...(((cfgVoltageRms?.cfgRelayVoltageDip1RecOpt?.value ?? 0) !== (dip1RecOptValue)) ? { cfg_relay_voltage_dip1_rec_opt: { value: dip1RecOptValue } } : {}),
		...(((cfgVoltageRms?.cfgRelayVoltageDip1Map?.value ?? 0) !== (dip1RecOptValue > 0 ? actions : 0)) ? { cfg_relay_voltage_dip1_map: { value: dip1RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgVoltageRms?.cfgRelayVoltageDip2RecOpt?.value ?? 0) !== (dip2RecOptValue)) ? { cfg_relay_voltage_dip2_rec_opt: { value: dip2RecOptValue } } : {}),
		...(((cfgVoltageRms?.cfgRelayVoltageDip2Map?.value ?? 0) !== (dip2RecOptValue > 0 ? actions : 0)) ? { cfg_relay_voltage_dip2_map: { value: dip2RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgVoltageRms?.cfgRelayVoltageDip3RecOpt?.value ?? 0) !== (dip3RecOptValue)) ? { cfg_relay_voltage_dip3_rec_opt: { value: dip3RecOptValue } } : {}),
		...(((cfgVoltageRms?.cfgRelayVoltageDip3Map?.value ?? 0) !== (dip3RecOptValue > 0 ? actions : 0)) ? { cfg_relay_voltage_dip3_map: { value: dip3RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgVoltageRms?.cfgRelayVoltageDip4RecOpt?.value ?? 0) !== (dip4RecOptValue)) ? { cfg_relay_voltage_dip4_rec_opt: { value: dip4RecOptValue } } : {}),
		...(((cfgVoltageRms?.cfgRelayVoltageDip4Map?.value ?? 0) !== (dip4RecOptValue > 0 ? actions : 0)) ? { cfg_relay_voltage_dip4_map: { value: dip4RecOptValue > 0 ? actions : 0 } } : {}),
	};
};

export const mapSwellConfig = (cfgVoltageRms: Nullable<PhaseVoltageConfiguration>): [ channels: VoltageRmsForm["cfgSwellChannels"], actions: number ] => {
	let cfgSwellChannels: VoltageRmsForm["cfgSwellChannels"] = [];
	let cfgSwellActions = 0;
	if (isNull(cfgVoltageRms)) return [ cfgSwellChannels, cfgSwellActions ];
	if (isNull(cfgVoltageRms)) return [ cfgSwellChannels, cfgSwellActions ];
	if ((cfgVoltageRms?.cfgRelayVoltageSwell1RecOpt?.value ?? 0) > 0) {
		cfgSwellChannels.push("cfgRelayVoltageSwell1RecOpt");
		cfgSwellActions = Math.max(cfgVoltageRms?.cfgRelayVolatgeSwell1Map?.value ?? 0, cfgSwellActions);
	}
	if ((cfgVoltageRms?.cfgRelayVoltageSwell2RecOpt?.value ?? 0) > 0) {
		cfgSwellChannels.push("cfgRelayVoltageSwell2RecOpt");
		cfgSwellActions = Math.max(cfgVoltageRms?.cfgRelayVolatgeSwell2Map?.value ?? 0, cfgSwellActions);
	}
	if ((cfgVoltageRms?.cfgRelayVoltageSwell3RecOpt?.value ?? 0) > 0) {
		cfgSwellChannels.push("cfgRelayVoltageSwell3RecOpt");
		cfgSwellActions = Math.max(cfgVoltageRms?.cfgRelayVolatgeSwell3Map?.value ?? 0, cfgSwellActions);
	}
	if ((cfgVoltageRms?.cfgRelayVoltageSwell4RecOpt?.value ?? 0) > 0) {
		cfgSwellChannels.push("cfgRelayVoltageSwell4RecOpt");
		cfgSwellActions = Math.max(cfgVoltageRms?.cfgRelayVolatgeSwell4Map?.value ?? 0, cfgSwellActions);
	}
	return [
		cfgSwellChannels,
		cfgSwellActions,
	];
};

export const translateSwellConfig = (
	cfgVoltageRms: Nullable<PhaseVoltageConfiguration>,
	channels: VoltageRmsForm["cfgSwellChannels"],
	actions: number,
): SetConfigurationPayload => {
	if (isNull(cfgVoltageRms)) return {};

	let swell1RecOptValue = 0;
	let swell2RecOptValue = 0;
	let swell3RecOptValue = 0;
	let swell4RecOptValue = 0;
	if (channels.includes("cfgRelayVoltageSwell1RecOpt")) {
		swell1RecOptValue = (cfgVoltageRms?.cfgRelayVoltageSwell1RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayVoltageSwell2RecOpt")) {
		swell2RecOptValue = (cfgVoltageRms?.cfgRelayVoltageSwell2RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayVoltageSwell3RecOpt")) {
		swell3RecOptValue = (cfgVoltageRms?.cfgRelayVoltageSwell3RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayVoltageSwell4RecOpt")) {
		swell4RecOptValue = (cfgVoltageRms?.cfgRelayVoltageSwell4RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	return {
		...(((cfgVoltageRms?.cfgRelayVoltageSwell1RecOpt?.value ?? 0) !== (swell1RecOptValue)) ? { cfg_relay_voltage_swell1_rec_opt: { value: swell1RecOptValue } } : {}),
		...(((cfgVoltageRms?.cfgRelayVolatgeSwell1Map?.value ?? 0) !== (swell1RecOptValue > 0 ? actions : 0)) ? { cfg_relay_volatge_swell1_map: { value: swell1RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgVoltageRms?.cfgRelayVoltageSwell2RecOpt?.value ?? 0) !== (swell2RecOptValue)) ? { cfg_relay_voltage_swell2_rec_opt: { value: swell2RecOptValue } } : {}),
		...(((cfgVoltageRms?.cfgRelayVolatgeSwell2Map?.value ?? 0) !== (swell2RecOptValue > 0 ? actions : 0)) ? { cfg_relay_volatge_swell2_map: { value: swell2RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgVoltageRms?.cfgRelayVoltageSwell3RecOpt?.value ?? 0) !== (swell3RecOptValue)) ? { cfg_relay_voltage_swell3_rec_opt: { value: swell3RecOptValue } } : {}),
		...(((cfgVoltageRms?.cfgRelayVolatgeSwell3Map?.value ?? 0) !== (swell3RecOptValue > 0 ? actions : 0)) ? { cfg_relay_volatge_swell3_map: { value: swell3RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgVoltageRms?.cfgRelayVoltageSwell4RecOpt?.value ?? 0) !== (swell4RecOptValue)) ? { cfg_relay_voltage_swell4_rec_opt: { value: swell4RecOptValue } } : {}),
		...(((cfgVoltageRms?.cfgRelayVolatgeSwell4Map?.value ?? 0) !== (swell4RecOptValue > 0 ? actions : 0)) ? { cfg_relay_volatge_swell4_map: { value: swell4RecOptValue > 0 ? actions : 0 } } : {}),
	};
};

export const mapInterruptConfig = (cfgVoltageRms: Nullable<PhaseVoltageConfiguration>): [ channels: VoltageRmsForm["cfgInterruptChannels"], actions: number ] => {
	let cfgInterruptChannels: VoltageRmsForm["cfgInterruptChannels"] = [];
	let cfgInterruptActions = 0;
	if (isNull(cfgVoltageRms)) return [ cfgInterruptChannels, cfgInterruptActions ];
	if (isNull(cfgVoltageRms)) return [ cfgInterruptChannels, cfgInterruptActions ];
	if ((cfgVoltageRms?.cfgRelayVoltageInterrupt1RecOpt?.value ?? 0) > 0) {
		cfgInterruptChannels.push("cfgRelayVoltageInterrupt1RecOpt");
		cfgInterruptActions = Math.max(cfgVoltageRms?.cfgRelayVoltageInterrupt1Map?.value ?? 0, cfgInterruptActions);
	}
	if ((cfgVoltageRms?.cfgRelayVoltageInterrupt2RecOpt?.value ?? 0) > 0) {
		cfgInterruptChannels.push("cfgRelayVoltageInterrupt2RecOpt");
		cfgInterruptActions = Math.max(cfgVoltageRms?.cfgRelayVoltageInterrupt2Map?.value ?? 0, cfgInterruptActions);
	}
	if ((cfgVoltageRms?.cfgRelayVoltageInterrupt3RecOpt?.value ?? 0) > 0) {
		cfgInterruptChannels.push("cfgRelayVoltageInterrupt3RecOpt");
		cfgInterruptActions = Math.max(cfgVoltageRms?.cfgRelayVoltageInterrupt3Map?.value ?? 0, cfgInterruptActions);
	}
	if ((cfgVoltageRms?.cfgRelayVoltageInterrupt4RecOpt?.value ?? 0) > 0) {
		cfgInterruptChannels.push("cfgRelayVoltageInterrupt4RecOpt");
		cfgInterruptActions = Math.max(cfgVoltageRms?.cfgRelayVoltageInterrupt4Map?.value ?? 0, cfgInterruptActions);
	}
	return [
		cfgInterruptChannels,
		cfgInterruptActions,
	];
};

export const translateInterruptConfig = (
	cfgVoltageRms: Nullable<PhaseVoltageConfiguration>,
	channels: VoltageRmsForm["cfgInterruptChannels"],
	actions: number,
): SetConfigurationPayload => {
	if (isNull(cfgVoltageRms)) return {};
	let interrupt1RecOptValue = 0;
	let interrupt2RecOptValue = 0;
	let interrupt3RecOptValue = 0;
	let interrupt4RecOptValue = 0;
	if (channels.includes("cfgRelayVoltageInterrupt1RecOpt")) {
		interrupt1RecOptValue = (cfgVoltageRms?.cfgRelayVoltageInterrupt1RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayVoltageInterrupt2RecOpt")) {
		interrupt2RecOptValue = (cfgVoltageRms?.cfgRelayVoltageInterrupt2RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayVoltageInterrupt3RecOpt")) {
		interrupt3RecOptValue = (cfgVoltageRms?.cfgRelayVoltageInterrupt3RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayVoltageInterrupt4RecOpt")) {
		interrupt4RecOptValue = (cfgVoltageRms?.cfgRelayVoltageInterrupt4RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}

	return {
		...(((cfgVoltageRms?.cfgRelayVoltageInterrupt1RecOpt?.value ?? 0) !== (interrupt1RecOptValue)) ? { cfg_relay_voltage_interrupt1_rec_opt: { value: interrupt1RecOptValue } } : {}),
		...(((cfgVoltageRms?.cfgRelayVoltageInterrupt1Map?.value ?? 0) !== (interrupt1RecOptValue > 0 ? actions : 0)) ? { cfg_relay_voltage_interrupt1_map: { value: interrupt1RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgVoltageRms?.cfgRelayVoltageInterrupt2RecOpt?.value ?? 0) !== (interrupt2RecOptValue)) ? { cfg_relay_voltage_interrupt2_rec_opt: { value: interrupt2RecOptValue } } : {}),
		...(((cfgVoltageRms?.cfgRelayVoltageInterrupt2Map?.value ?? 0) !== (interrupt2RecOptValue > 0 ? actions : 0)) ? { cfg_relay_voltage_interrupt2_map: { value: interrupt2RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgVoltageRms?.cfgRelayVoltageInterrupt3RecOpt?.value ?? 0) !== (interrupt3RecOptValue)) ? { cfg_relay_voltage_interrupt3_rec_opt: { value: interrupt3RecOptValue } } : {}),
		...(((cfgVoltageRms?.cfgRelayVoltageInterrupt3Map?.value ?? 0) !== (interrupt3RecOptValue > 0 ? actions : 0)) ? { cfg_relay_voltage_interrupt3_map: { value: interrupt3RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgVoltageRms?.cfgRelayVoltageInterrupt4RecOpt?.value ?? 0) !== (interrupt4RecOptValue)) ? { cfg_relay_voltage_interrupt4_rec_opt: { value: interrupt4RecOptValue } } : {}),
		...(((cfgVoltageRms?.cfgRelayVoltageInterrupt4Map?.value ?? 0) !== (interrupt4RecOptValue > 0 ? actions : 0)) ? { cfg_relay_voltage_interrupt4_map: { value: interrupt4RecOptValue > 0 ? actions : 0 } } : {}),
	};
};

export const mapWaveshapeVariationsConfig = (cfgWaveshapeVariations: SettingsMeasurementsUser["cfgWaveshapeVariations"]): [ channels: VoltageRmsForm["cfgWaveshapeVariationsChannels"], actions: number ] => {
	let cfgWaveshapeVariationsChannels: VoltageRmsForm["cfgWaveshapeVariationsChannels"] = [];
	let cfgWaveshapeVariationsActions = 0;
	if (isNull(cfgWaveshapeVariations)) return [ cfgWaveshapeVariationsChannels, cfgWaveshapeVariationsActions ];
	if ((cfgWaveshapeVariations?.cfgRelayWaveshape1RecOpt?.value ?? 0) > 0) {
		cfgWaveshapeVariationsChannels.push("cfgRelayWaveshape1RecOpt");
		cfgWaveshapeVariationsActions = Math.max(cfgWaveshapeVariations?.cfgRelayWaveshape1Map?.value ?? 0, cfgWaveshapeVariationsActions);
	}
	if ((cfgWaveshapeVariations?.cfgRelayWaveshape2RecOpt?.value ?? 0) > 0) {
		cfgWaveshapeVariationsChannels.push("cfgRelayWaveshape2RecOpt");
		cfgWaveshapeVariationsActions = Math.max(cfgWaveshapeVariations?.cfgRelayWaveshape2Map?.value ?? 0, cfgWaveshapeVariationsActions);
	}
	if ((cfgWaveshapeVariations?.cfgRelayWaveshape3RecOpt?.value ?? 0) > 0) {
		cfgWaveshapeVariationsChannels.push("cfgRelayWaveshape3RecOpt");
		cfgWaveshapeVariationsActions = Math.max(cfgWaveshapeVariations?.cfgRelayWaveshape3Map?.value ?? 0, cfgWaveshapeVariationsActions);
	}
	if ((cfgWaveshapeVariations?.cfgRelayWaveshape4RecOpt?.value ?? 0) > 0) {
		cfgWaveshapeVariationsChannels.push("cfgRelayWaveshape4RecOpt");
		cfgWaveshapeVariationsActions = Math.max(cfgWaveshapeVariations?.cfgRelayWaveshape4Map?.value ?? 0, cfgWaveshapeVariationsActions);
	}
	return [
		cfgWaveshapeVariationsChannels,
		cfgWaveshapeVariationsActions,
	];
};

export const translateWaveshapeVariationsConfig = (
	cfgWaveshapeVariations: SettingsMeasurementsUser["cfgWaveshapeVariations"],
	channels: VoltageRmsForm["cfgWaveshapeVariationsChannels"],
	actions: number,
): SetConfigurationPayload => {
	let waveshapeVariations1RecOptValue = 0;
	let waveshapeVariations2RecOptValue = 0;
	let waveshapeVariations3RecOptValue = 0;
	let waveshapeVariations4RecOptValue = 0;
	if (channels.includes("cfgRelayWaveshape1RecOpt")) {
		waveshapeVariations1RecOptValue = (cfgWaveshapeVariations?.cfgRelayWaveshape1RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayWaveshape2RecOpt")) {
		waveshapeVariations2RecOptValue = (cfgWaveshapeVariations?.cfgRelayWaveshape2RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayWaveshape3RecOpt")) {
		waveshapeVariations3RecOptValue = (cfgWaveshapeVariations?.cfgRelayWaveshape3RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayWaveshape4RecOpt")) {
		waveshapeVariations4RecOptValue = (cfgWaveshapeVariations?.cfgRelayWaveshape4RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}

	return {
		...(((cfgWaveshapeVariations?.cfgRelayWaveshape1RecOpt?.value ?? 0) !== (waveshapeVariations1RecOptValue)) ? { cfg_relay_waveshape1_rec_opt: { value: waveshapeVariations1RecOptValue } } : {}),
		...(((cfgWaveshapeVariations?.cfgRelayWaveshape1Map?.value ?? 0) !== (waveshapeVariations1RecOptValue > 0 ? actions : 0)) ? { cfg_relay_waveshape1_map: { value: waveshapeVariations1RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgWaveshapeVariations?.cfgRelayWaveshape2RecOpt?.value ?? 0) !== (waveshapeVariations2RecOptValue)) ? { cfg_relay_waveshape2_rec_opt: { value: waveshapeVariations2RecOptValue } } : {}),
		...(((cfgWaveshapeVariations?.cfgRelayWaveshape2Map?.value ?? 0) !== (waveshapeVariations2RecOptValue > 0 ? actions : 0)) ? { cfg_relay_waveshape2_map: { value: waveshapeVariations2RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgWaveshapeVariations?.cfgRelayWaveshape3RecOpt?.value ?? 0) !== (waveshapeVariations3RecOptValue)) ? { cfg_relay_waveshape3_rec_opt: { value: waveshapeVariations3RecOptValue } } : {}),
		...(((cfgWaveshapeVariations?.cfgRelayWaveshape3Map?.value ?? 0) !== (waveshapeVariations3RecOptValue > 0 ? actions : 0)) ? { cfg_relay_waveshape3_map: { value: waveshapeVariations3RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgWaveshapeVariations?.cfgRelayWaveshape4RecOpt?.value ?? 0) !== (waveshapeVariations4RecOptValue)) ? { cfg_relay_waveshape4_rec_opt: { value: waveshapeVariations4RecOptValue } } : {}),
		...(((cfgWaveshapeVariations?.cfgRelayWaveshape4Map?.value ?? 0) !== (waveshapeVariations4RecOptValue > 0 ? actions : 0)) ? { cfg_relay_waveshape4_map: { value: waveshapeVariations4RecOptValue > 0 ? actions : 0 } } : {}),
	};
};

export const mapPhaseJumpsConfig = (cfgPhaseJumps: SettingsMeasurementsUser["cfgPhaseJumps"]): [ channels: VoltageRmsForm["cfgPhaseJumpsChannels"], actions: number ] => {
	let cfgPhaseJumpsChannels: VoltageRmsForm["cfgPhaseJumpsChannels"] = [];
	let cfgPhaseJumpsActions = 0;
	if (isNull(cfgPhaseJumps)) return [ cfgPhaseJumpsChannels, cfgPhaseJumpsActions ];
	if ((cfgPhaseJumps?.cfgRelayPhaseJump1RecOpt?.value ?? 0) > 0) {
		cfgPhaseJumpsChannels.push("cfgRelayPhaseJump1RecOpt");
		cfgPhaseJumpsActions = Math.max(cfgPhaseJumps?.cfgRelayPhaseJump1Map?.value ?? 0, cfgPhaseJumpsActions);
	}
	if ((cfgPhaseJumps?.cfgRelayPhaseJump2RecOpt?.value ?? 0) > 0) {
		cfgPhaseJumpsChannels.push("cfgRelayPhaseJump2RecOpt");
		cfgPhaseJumpsActions = Math.max(cfgPhaseJumps?.cfgRelayPhaseJump2Map?.value ?? 0, cfgPhaseJumpsActions);
	}
	if ((cfgPhaseJumps?.cfgRelayPhaseJump3RecOpt?.value ?? 0) > 0) {
		cfgPhaseJumpsChannels.push("cfgRelayPhaseJump3RecOpt");
		cfgPhaseJumpsActions = Math.max(cfgPhaseJumps?.cfgRelayPhaseJump3Map?.value ?? 0, cfgPhaseJumpsActions);
	}
	if ((cfgPhaseJumps?.cfgRelayPhaseJump4RecOpt?.value ?? 0) > 0) {
		cfgPhaseJumpsChannels.push("cfgRelayPhaseJump4RecOpt");
		cfgPhaseJumpsActions = Math.max(cfgPhaseJumps?.cfgRelayPhaseJump4Map?.value ?? 0, cfgPhaseJumpsActions);
	}
	return [
		cfgPhaseJumpsChannels,
		cfgPhaseJumpsActions,
	];
};

export const translatePhaseJumpConfig = (
	cfgPhaseJumps: SettingsMeasurementsUser["cfgPhaseJumps"],
	channels: VoltageRmsForm["cfgPhaseJumpsChannels"],
	actions: number,
): SetConfigurationPayload => {
	let phaseJump1RecOptValue = 0;
	let phaseJump2RecOptValue = 0;
	let phaseJump3RecOptValue = 0;
	let phaseJump4RecOptValue = 0;
	if (channels.includes("cfgRelayPhaseJump1RecOpt")) {
		phaseJump1RecOptValue = (cfgPhaseJumps?.cfgRelayPhaseJump1RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayPhaseJump2RecOpt")) {
		phaseJump2RecOptValue = (cfgPhaseJumps?.cfgRelayPhaseJump2RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayPhaseJump3RecOpt")) {
		phaseJump3RecOptValue = (cfgPhaseJumps?.cfgRelayPhaseJump3RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayPhaseJump4RecOpt")) {
		phaseJump4RecOptValue = (cfgPhaseJumps?.cfgRelayPhaseJump4RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}

	return {
		...(((cfgPhaseJumps?.cfgRelayPhaseJump1RecOpt?.value ?? 0) !== (phaseJump1RecOptValue)) ? { cfg_relay_phase_jump1_rec_opt: { value: phaseJump1RecOptValue } } : {}),
		...(((cfgPhaseJumps?.cfgRelayPhaseJump1Map?.value ?? 0) !== (phaseJump1RecOptValue > 0 ? actions : 0)) ? { cfg_relay_phase_jump1_map: { value: phaseJump1RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgPhaseJumps?.cfgRelayPhaseJump2RecOpt?.value ?? 0) !== (phaseJump2RecOptValue)) ? { cfg_relay_phase_jump2_rec_opt: { value: phaseJump2RecOptValue } } : {}),
		...(((cfgPhaseJumps?.cfgRelayPhaseJump2Map?.value ?? 0) !== (phaseJump2RecOptValue > 0 ? actions : 0)) ? { cfg_relay_phase_jump2_map: { value: phaseJump2RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgPhaseJumps?.cfgRelayPhaseJump3RecOpt?.value ?? 0) !== (phaseJump3RecOptValue)) ? { cfg_relay_phase_jump3_rec_opt: { value: phaseJump3RecOptValue } } : {}),
		...(((cfgPhaseJumps?.cfgRelayPhaseJump3Map?.value ?? 0) !== (phaseJump3RecOptValue > 0 ? actions : 0)) ? { cfg_relay_phase_jump3_map: { value: phaseJump3RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgPhaseJumps?.cfgRelayPhaseJump4RecOpt?.value ?? 0) !== (phaseJump4RecOptValue)) ? { cfg_relay_phase_jump4_rec_opt: { value: phaseJump4RecOptValue } } : {}),
		...(((cfgPhaseJumps?.cfgRelayPhaseJump4Map?.value ?? 0) !== (phaseJump4RecOptValue > 0 ? actions : 0)) ? { cfg_relay_phase_jump4_map: { value: phaseJump4RecOptValue > 0 ? actions : 0 } } : {}),
	};
};

export const mapFrequencyConfig = (cfgFrequency: SettingsMeasurementsUser["cfgFrequency"]): [ channels: FrequencyForm["cfgFrequencyChannels"], actions: number ] => {
	let cfgFrequencyChannels: FrequencyForm["cfgFrequencyChannels"] = [];
	let cfgFrequencyActions = 0;
	if (isNull(cfgFrequency)) return [ cfgFrequencyChannels, cfgFrequencyActions ];
	if ((cfgFrequency?.cfgRelayFrequencyRecOpt?.value ?? 0) > 0) {
		cfgFrequencyChannels.push("cfgRelayFrequencyRecOpt");
		cfgFrequencyActions = Math.max(cfgFrequency?.cfgRelayFrequencyMap?.value ?? 0, cfgFrequencyActions);
	}
	return [
		cfgFrequencyChannels,
		cfgFrequencyActions,
	];
};

export const translateFrequencyConfig = (
	cfgFrequency: SettingsMeasurementsUser["cfgFrequency"],
	channels: FrequencyForm["cfgFrequencyChannels"],
	actions: number,
): SetConfigurationPayload => {
	let frequencyRecOptValue = 0;
	if (channels.includes("cfgRelayFrequencyRecOpt")) {
		frequencyRecOptValue = (cfgFrequency?.cfgRelayFrequencyRecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}

	return {
		...(((cfgFrequency?.cfgRelayFrequencyRecOpt?.value ?? 0) !== (frequencyRecOptValue)) ? { cfg_relay_frequency_rec_opt: { value: frequencyRecOptValue } } : {}),
		...(((cfgFrequency?.cfgRelayFrequencyMap?.value ?? 0) !== (frequencyRecOptValue > 0 ? actions : 0)) ? { cfg_relay_frequency_map: { value: frequencyRecOptValue > 0 ? actions : 0 } } : {}),
	};
};

export const mapFlickerPstConfig = (cfgFlickerPst: SettingsMeasurementsUser["cfgFlickerPst"]): [ channels: FlicketPstForm["cfgFlickerPstChannels"], actions: number ] => {
	let cfgFlickerPstChannels: FlicketPstForm["cfgFlickerPstChannels"] = [];
	let cfgFlickerPstActions = 0;
	if (isNull(cfgFlickerPst)) return [ cfgFlickerPstChannels, cfgFlickerPstActions ];
	if ((cfgFlickerPst?.cfgRelayFlickerPst1RecOpt?.value ?? 0) > 0) {
		cfgFlickerPstChannels.push("cfgRelayFlickerPst1RecOpt");
		cfgFlickerPstActions = Math.max(cfgFlickerPst?.cfgRelayFlickerPst1Map?.value ?? 0, cfgFlickerPstActions);
	}
	if ((cfgFlickerPst?.cfgRelayFlickerPst2RecOpt?.value ?? 0) > 0) {
		cfgFlickerPstChannels.push("cfgRelayFlickerPst2RecOpt");
		cfgFlickerPstActions = Math.max(cfgFlickerPst?.cfgRelayFlickerPst2Map?.value ?? 0, cfgFlickerPstActions);
	}
	if ((cfgFlickerPst?.cfgRelayFlickerPst3RecOpt?.value ?? 0) > 0) {
		cfgFlickerPstChannels.push("cfgRelayFlickerPst3RecOpt");
		cfgFlickerPstActions = Math.max(cfgFlickerPst?.cfgRelayFlickerPst3Map?.value ?? 0, cfgFlickerPstActions);
	}
	if ((cfgFlickerPst?.cfgRelayFlickerPst4RecOpt?.value ?? 0) > 0) {
		cfgFlickerPstChannels.push("cfgRelayFlickerPst4RecOpt");
		cfgFlickerPstActions = Math.max(cfgFlickerPst?.cfgRelayFlickerPst4Map?.value ?? 0, cfgFlickerPstActions);
	}
	return [
		cfgFlickerPstChannels,
		cfgFlickerPstActions,
	];
};

export const translateFlickerPstConfig = (
	cfgFlickerPst: SettingsMeasurementsUser["cfgFlickerPst"],
	channels: FlicketPstForm["cfgFlickerPstChannels"],
	actions: number,
): SetConfigurationPayload => {
	let flickerPst1RecOptValue = 0;
	let flickerPst2RecOptValue = 0;
	let flickerPst3RecOptValue = 0;
	let flickerPst4RecOptValue = 0;
	if (channels.includes("cfgRelayFlickerPst1RecOpt")) {
		flickerPst1RecOptValue = (cfgFlickerPst?.cfgRelayFlickerPst1RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayFlickerPst2RecOpt")) {
		flickerPst2RecOptValue = (cfgFlickerPst?.cfgRelayFlickerPst2RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayFlickerPst3RecOpt")) {
		flickerPst3RecOptValue = (cfgFlickerPst?.cfgRelayFlickerPst3RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayFlickerPst4RecOpt")) {
		flickerPst4RecOptValue = (cfgFlickerPst?.cfgRelayFlickerPst4RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}

	return {
		...(((cfgFlickerPst?.cfgRelayFlickerPst1RecOpt?.value ?? 0) !== (flickerPst1RecOptValue)) ? { cfg_relay_flicker_pst1_rec_opt: { value: flickerPst1RecOptValue } } : {}),
		...(((cfgFlickerPst?.cfgRelayFlickerPst1Map?.value ?? 0) !== (flickerPst1RecOptValue > 0 ? actions : 0)) ? { cfg_relay_flicker_pst1_map: { value: flickerPst1RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgFlickerPst?.cfgRelayFlickerPst2RecOpt?.value ?? 0) !== (flickerPst2RecOptValue)) ? { cfg_relay_flicker_pst2_rec_opt: { value: flickerPst2RecOptValue } } : {}),
		...(((cfgFlickerPst?.cfgRelayFlickerPst2Map?.value ?? 0) !== (flickerPst2RecOptValue > 0 ? actions : 0)) ? { cfg_relay_flicker_pst2_map: { value: flickerPst2RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgFlickerPst?.cfgRelayFlickerPst3RecOpt?.value ?? 0) !== (flickerPst3RecOptValue)) ? { cfg_relay_flicker_pst3_rec_opt: { value: flickerPst3RecOptValue } } : {}),
		...(((cfgFlickerPst?.cfgRelayFlickerPst3Map?.value ?? 0) !== (flickerPst3RecOptValue > 0 ? actions : 0)) ? { cfg_relay_flicker_pst3_map: { value: flickerPst3RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgFlickerPst?.cfgRelayFlickerPst4RecOpt?.value ?? 0) !== (flickerPst4RecOptValue)) ? { cfg_relay_flicker_pst4_rec_opt: { value: flickerPst4RecOptValue } } : {}),
		...(((cfgFlickerPst?.cfgRelayFlickerPst4Map?.value ?? 0) !== (flickerPst4RecOptValue > 0 ? actions : 0)) ? { cfg_relay_flicker_pst4_map: { value: flickerPst4RecOptValue > 0 ? actions : 0 } } : {}),
	};
};

export const mapFlickerPltConfig = (cfgFlickerPlt: SettingsMeasurementsUser["cfgFlickerPlt"]): [ channels: FlickerPltForm["cfgFlickerPltChannels"], actions: number ] => {
	let cfgFlickerPltChannels: FlickerPltForm["cfgFlickerPltChannels"] = [];
	let cfgFlickerPltActions = 0;
	if (isNull(cfgFlickerPlt)) return [ cfgFlickerPltChannels, cfgFlickerPltActions ];
	if ((cfgFlickerPlt?.cfgRelayFlickerPlt1RecOpt?.value ?? 0) > 0 && isNotNull(cfgFlickerPlt?.relayChannelLabel?.ch1)) {
		cfgFlickerPltChannels.push("cfgRelayFlickerPlt1RecOpt");
		cfgFlickerPltActions = Math.max(cfgFlickerPlt?.cfgRelayFlickerPlt1Map?.value ?? 0, cfgFlickerPltActions);
	}
	if ((cfgFlickerPlt?.cfgRelayFlickerPlt2RecOpt?.value ?? 0) > 0 && isNotNull(cfgFlickerPlt?.relayChannelLabel?.ch2)) {
		cfgFlickerPltChannels.push("cfgRelayFlickerPlt2RecOpt");
		cfgFlickerPltActions = Math.max(cfgFlickerPlt?.cfgRelayFlickerPlt2Map?.value ?? 0, cfgFlickerPltActions);
	}
	if ((cfgFlickerPlt?.cfgRelayFlickerPlt3RecOpt?.value ?? 0) > 0 && isNotNull(cfgFlickerPlt?.relayChannelLabel?.ch3)) {
		cfgFlickerPltChannels.push("cfgRelayFlickerPlt3RecOpt");
		cfgFlickerPltActions = Math.max(cfgFlickerPlt?.cfgRelayFlickerPlt3Map?.value ?? 0, cfgFlickerPltActions);
	}
	if ((cfgFlickerPlt?.cfgRelayFlickerPlt4RecOpt?.value ?? 0) > 0 && isNotNull(cfgFlickerPlt?.relayChannelLabel?.ch4)) {
		cfgFlickerPltChannels.push("cfgRelayFlickerPlt4RecOpt");
		cfgFlickerPltActions = Math.max(cfgFlickerPlt?.cfgRelayFlickerPlt4Map?.value ?? 0, cfgFlickerPltActions);
	}
	return [
		cfgFlickerPltChannels,
		cfgFlickerPltActions,
	];
};

export const translateFlickerPltConfig = (
	cfgFlickerPlt: SettingsMeasurementsUser["cfgFlickerPlt"],
	channels: FlickerPltForm["cfgFlickerPltChannels"],
	actions: number,
): SetConfigurationPayload => {
	let flickerPlt1RecOptValue = 0;
	let flickerPlt2RecOptValue = 0;
	let flickerPlt3RecOptValue = 0;
	let flickerPlt4RecOptValue = 0;
	if (channels.includes("cfgRelayFlickerPlt1RecOpt")) {
		flickerPlt1RecOptValue = (cfgFlickerPlt?.cfgRelayFlickerPlt1RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayFlickerPlt2RecOpt")) {
		flickerPlt2RecOptValue = (cfgFlickerPlt?.cfgRelayFlickerPlt2RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayFlickerPlt3RecOpt")) {
		flickerPlt3RecOptValue = (cfgFlickerPlt?.cfgRelayFlickerPlt3RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayFlickerPlt4RecOpt")) {
		flickerPlt4RecOptValue = (cfgFlickerPlt?.cfgRelayFlickerPlt4RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}

	return {
		...(((cfgFlickerPlt?.cfgRelayFlickerPlt1RecOpt?.value ?? 0) !== (flickerPlt1RecOptValue)) ? { cfg_relay_flicker_plt1_rec_opt: { value: flickerPlt1RecOptValue } } : {}),
		...(((cfgFlickerPlt?.cfgRelayFlickerPlt1Map?.value ?? 0) !== (flickerPlt1RecOptValue > 0 ? actions : 0)) ? { cfg_relay_flicker_plt1_map: { value: flickerPlt1RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgFlickerPlt?.cfgRelayFlickerPlt2RecOpt?.value ?? 0) !== (flickerPlt2RecOptValue)) ? { cfg_relay_flicker_plt2_rec_opt: { value: flickerPlt2RecOptValue } } : {}),
		...(((cfgFlickerPlt?.cfgRelayFlickerPlt2Map?.value ?? 0) !== (flickerPlt2RecOptValue > 0 ? actions : 0)) ? { cfg_relay_flicker_plt2_map: { value: flickerPlt2RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgFlickerPlt?.cfgRelayFlickerPlt3RecOpt?.value ?? 0) !== (flickerPlt3RecOptValue)) ? { cfg_relay_flicker_plt3_rec_opt: { value: flickerPlt3RecOptValue } } : {}),
		...(((cfgFlickerPlt?.cfgRelayFlickerPlt3Map?.value ?? 0) !== (flickerPlt3RecOptValue > 0 ? actions : 0)) ? { cfg_relay_flicker_plt3_map: { value: flickerPlt3RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgFlickerPlt?.cfgRelayFlickerPlt4RecOpt?.value ?? 0) !== (flickerPlt4RecOptValue)) ? { cfg_relay_flicker_plt4_rec_opt: { value: flickerPlt4RecOptValue } } : {}),
		...(((cfgFlickerPlt?.cfgRelayFlickerPlt4Map?.value ?? 0) !== (flickerPlt4RecOptValue > 0 ? actions : 0)) ? { cfg_relay_flicker_plt4_map: { value: flickerPlt4RecOptValue > 0 ? actions : 0 } } : {}),
	};
};

export const mapCurrentConfig = (cfgCurrentRms: SettingsMeasurementsUser["cfgCurrentRms"]): [ channels: CurrentRmsForm["cfgCurrentChannels"], actions: number ] => {
	let cfgCurrentChannels: CurrentRmsForm["cfgCurrentChannels"] = [];
	let cfgCurrentActions = 0;
	if (isNull(cfgCurrentRms)) return [ cfgCurrentChannels, cfgCurrentActions ];
	if ((cfgCurrentRms?.cfgRelayCurrent1RecOpt?.value ?? 0) > 0 && isNotNull(cfgCurrentRms?.relayChannelLabel?.ch1)) {
		cfgCurrentChannels.push("cfgRelayCurrent1RecOpt");
		cfgCurrentActions = Math.max(cfgCurrentRms?.cfgRelayCurrent1Map?.value ?? 0, cfgCurrentActions);
	}
	if ((cfgCurrentRms?.cfgRelayCurrent2RecOpt?.value ?? 0) > 0 && isNotNull(cfgCurrentRms?.relayChannelLabel?.ch2)) {
		cfgCurrentChannels.push("cfgRelayCurrent2RecOpt");
		cfgCurrentActions = Math.max(cfgCurrentRms?.cfgRelayCurrent2Map?.value ?? 0, cfgCurrentActions);
	}
	if ((cfgCurrentRms?.cfgRelayCurrent3RecOpt?.value ?? 0) > 0 && isNotNull(cfgCurrentRms?.relayChannelLabel?.ch3)) {
		cfgCurrentChannels.push("cfgRelayCurrent3RecOpt");
		cfgCurrentActions = Math.max(cfgCurrentRms?.cfgRelayCurrent3Map?.value ?? 0, cfgCurrentActions);
	}
	if ((cfgCurrentRms?.cfgRelayCurrent4RecOpt?.value ?? 0) > 0 && isNotNull(cfgCurrentRms?.relayChannelLabel?.ch4)) {
		cfgCurrentChannels.push("cfgRelayCurrent4RecOpt");
		cfgCurrentActions = Math.max(cfgCurrentRms?.cfgRelayCurrent4Map?.value ?? 0, cfgCurrentActions);
	}
	if ((cfgCurrentRms?.cfgRelayCurrent5RecOpt?.value ?? 0) > 0 && isNotNull(cfgCurrentRms?.relayChannelLabel?.ch5)) {
		cfgCurrentChannels.push("cfgRelayCurrent5RecOpt");
		cfgCurrentActions = Math.max(cfgCurrentRms?.cfgRelayCurrent5Map?.value ?? 0, cfgCurrentActions);
	}
	return [
		cfgCurrentChannels,
		cfgCurrentActions,
	];
};

export const translateCurrentConfig = (
	cfgCurrentRms: SettingsMeasurementsUser["cfgCurrentRms"],
	channels: CurrentRmsForm["cfgCurrentChannels"],
	actions: number,
): SetConfigurationPayload => {
	let current1RecOptValue = 0;
	let current2RecOptValue = 0;
	let current3RecOptValue = 0;
	let current4RecOptValue = 0;
	let current5RecOptValue = 0;
	if (channels.includes("cfgRelayCurrent1RecOpt")) {
		current1RecOptValue = (cfgCurrentRms?.cfgRelayCurrent1RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayCurrent2RecOpt")) {
		current2RecOptValue = (cfgCurrentRms?.cfgRelayCurrent2RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayCurrent3RecOpt")) {
		current3RecOptValue = (cfgCurrentRms?.cfgRelayCurrent3RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayCurrent4RecOpt")) {
		current4RecOptValue = (cfgCurrentRms?.cfgRelayCurrent4RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayCurrent5RecOpt")) {
		current5RecOptValue = (cfgCurrentRms?.cfgRelayCurrent5RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}

	return {
		...(((cfgCurrentRms?.cfgRelayCurrent1RecOpt?.value ?? 0) !== (current1RecOptValue)) ? { cfg_relay_current1_rec_opt: { value: current1RecOptValue } } : {}),
		...(((cfgCurrentRms?.cfgRelayCurrent1Map?.value ?? 0) !== (current1RecOptValue > 0 ? actions : 0)) ? { cfg_relay_current1_map: { value: current1RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgCurrentRms?.cfgRelayCurrent2RecOpt?.value ?? 0) !== (current2RecOptValue)) ? { cfg_relay_current2_rec_opt: { value: current2RecOptValue } } : {}),
		...(((cfgCurrentRms?.cfgRelayCurrent2Map?.value ?? 0) !== (current2RecOptValue > 0 ? actions : 0)) ? { cfg_relay_current2_map: { value: current2RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgCurrentRms?.cfgRelayCurrent3RecOpt?.value ?? 0) !== (current3RecOptValue)) ? { cfg_relay_current3_rec_opt: { value: current3RecOptValue } } : {}),
		...(((cfgCurrentRms?.cfgRelayCurrent3Map?.value ?? 0) !== (current3RecOptValue > 0 ? actions : 0)) ? { cfg_relay_current3_map: { value: current3RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgCurrentRms?.cfgRelayCurrent4RecOpt?.value ?? 0) !== (current4RecOptValue)) ? { cfg_relay_current4_rec_opt: { value: current4RecOptValue } } : {}),
		...(((cfgCurrentRms?.cfgRelayCurrent4Map?.value ?? 0) !== (current4RecOptValue > 0 ? actions : 0)) ? { cfg_relay_current4_map: { value: current4RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgCurrentRms?.cfgRelayCurrent5RecOpt?.value ?? 0) !== (current5RecOptValue)) ? { cfg_relay_current5_rec_opt: { value: current5RecOptValue } } : {}),
		...(((cfgCurrentRms?.cfgRelayCurrent5Map?.value ?? 0) !== (current5RecOptValue > 0 ? actions : 0)) ? { cfg_relay_current5_map: { value: current5RecOptValue > 0 ? actions : 0 } } : {}),
	};
};

export const mapReactivePowerConfig = (cfgReactivePower: SettingsMeasurementsUser["cfgReactivePower"]): [ channels: ReactivePowerForm["cfgReactivePowerChannels"], actions: number ] => {
	let cfgReactivePowerChannels: ReactivePowerForm["cfgReactivePowerChannels"] = [];
	let cfgReactivePowerActions = 0;
	if (isNull(cfgReactivePower)) return [ cfgReactivePowerChannels, cfgReactivePowerActions ];
	if ((cfgReactivePower?.cfgRelayReactivePower1RecOpt?.value ?? 0) > 0 && isNotNull(cfgReactivePower?.relayChannelLabel?.ch1)) {
		cfgReactivePowerChannels.push("cfgRelayReactivePower1RecOpt");
		cfgReactivePowerActions = Math.max(cfgReactivePower?.cfgRelayReactivePower1Map?.value ?? 0, cfgReactivePowerActions);
	}
	if ((cfgReactivePower?.cfgRelayReactivePower2RecOpt?.value ?? 0) > 0 && isNotNull(cfgReactivePower?.relayChannelLabel?.ch2)) {
		cfgReactivePowerChannels.push("cfgRelayReactivePower2RecOpt");
		cfgReactivePowerActions = Math.max(cfgReactivePower?.cfgRelayReactivePower2Map?.value ?? 0, cfgReactivePowerActions);
	}
	if ((cfgReactivePower?.cfgRelayReactivePower3RecOpt?.value ?? 0) > 0 && isNotNull(cfgReactivePower?.relayChannelLabel?.ch3)) {
		cfgReactivePowerChannels.push("cfgRelayReactivePower3RecOpt");
		cfgReactivePowerActions = Math.max(cfgReactivePower?.cfgRelayReactivePower3Map?.value ?? 0, cfgReactivePowerActions);
	}
	if ((cfgReactivePower?.cfgRelayReactivePower4RecOpt?.value ?? 0) > 0 && isNotNull(cfgReactivePower?.relayChannelLabel?.ch4)) {
		cfgReactivePowerChannels.push("cfgRelayReactivePower4RecOpt");
		cfgReactivePowerActions = Math.max(cfgReactivePower?.cfgRelayReactivePower4Map?.value ?? 0, cfgReactivePowerActions);
	}
	return [
		cfgReactivePowerChannels,
		cfgReactivePowerActions,
	];
};

export const translateReactivePowerConfig = (
	cfgReactivePower: SettingsMeasurementsUser["cfgReactivePower"],
	channels: ReactivePowerForm["cfgReactivePowerChannels"],
	actions: number,
): SetConfigurationPayload => {
	let reactivePower1RecOptValue = 0;
	let reactivePower2RecOptValue = 0;
	let reactivePower3RecOptValue = 0;
	let reactivePower4RecOptValue = 0;
	if (channels.includes("cfgRelayReactivePower1RecOpt")) {
		reactivePower1RecOptValue = (cfgReactivePower?.cfgRelayReactivePower1RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayReactivePower2RecOpt")) {
		reactivePower2RecOptValue = (cfgReactivePower?.cfgRelayReactivePower2RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayReactivePower3RecOpt")) {
		reactivePower3RecOptValue = (cfgReactivePower?.cfgRelayReactivePower3RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayReactivePower4RecOpt")) {
		reactivePower4RecOptValue = (cfgReactivePower?.cfgRelayReactivePower4RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}

	return {
		...(((cfgReactivePower?.cfgRelayReactivePower1RecOpt?.value ?? 0) !== (reactivePower1RecOptValue)) ? { cfg_relay_reactive_power1_rec_opt: { value: reactivePower1RecOptValue } } : {}),
		...(((cfgReactivePower?.cfgRelayReactivePower1Map?.value ?? 0) !== (reactivePower1RecOptValue > 0 ? actions : 0)) ? { cfg_relay_reactive_power1_map: { value: reactivePower1RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgReactivePower?.cfgRelayReactivePower2RecOpt?.value ?? 0) !== (reactivePower2RecOptValue)) ? { cfg_relay_reactive_power2_rec_opt: { value: reactivePower2RecOptValue } } : {}),
		...(((cfgReactivePower?.cfgRelayReactivePower2Map?.value ?? 0) !== (reactivePower2RecOptValue > 0 ? actions : 0)) ? { cfg_relay_reactive_power2_map: { value: reactivePower2RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgReactivePower?.cfgRelayReactivePower3RecOpt?.value ?? 0) !== (reactivePower3RecOptValue)) ? { cfg_relay_reactive_power3_rec_opt: { value: reactivePower3RecOptValue } } : {}),
		...(((cfgReactivePower?.cfgRelayReactivePower3Map?.value ?? 0) !== (reactivePower3RecOptValue > 0 ? actions : 0)) ? { cfg_relay_reactive_power3_map: { value: reactivePower3RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgReactivePower?.cfgRelayReactivePower4RecOpt?.value ?? 0) !== (reactivePower4RecOptValue)) ? { cfg_relay_reactive_power4_rec_opt: { value: reactivePower4RecOptValue } } : {}),
		...(((cfgReactivePower?.cfgRelayReactivePower4Map?.value ?? 0) !== (reactivePower4RecOptValue > 0 ? actions : 0)) ? { cfg_relay_reactive_power4_map: { value: reactivePower4RecOptValue > 0 ? actions : 0 } } : {}),
	};
};

export const mapApparentPowerConfig = (cfgApparentPower: SettingsMeasurementsUser["cfgApparentPower"]): [ channels: ApparentPowerForm["cfgApparentPowerChannels"], actions: number ] => {
	let cfgApparentPowerChannels: ApparentPowerForm["cfgApparentPowerChannels"] = [];
	let cfgApparentPowerActions = 0;
	if (isNull(cfgApparentPower)) return [ cfgApparentPowerChannels, cfgApparentPowerActions ];
	if ((cfgApparentPower?.cfgRelayApparentPower1RecOpt?.value ?? 0) > 0 && isNotNull(cfgApparentPower?.relayChannelLabel?.ch1)) {
		cfgApparentPowerChannels.push("cfgRelayApparentPower1RecOpt");
		cfgApparentPowerActions = Math.max(cfgApparentPower?.cfgRelayApparentPower1Map?.value ?? 0, cfgApparentPowerActions);
	}
	if ((cfgApparentPower?.cfgRelayApparentPower2RecOpt?.value ?? 0) > 0 && isNotNull(cfgApparentPower?.relayChannelLabel?.ch2)) {
		cfgApparentPowerChannels.push("cfgRelayApparentPower2RecOpt");
		cfgApparentPowerActions = Math.max(cfgApparentPower?.cfgRelayApparentPower2Map?.value ?? 0, cfgApparentPowerActions);
	}
	if ((cfgApparentPower?.cfgRelayApparentPower3RecOpt?.value ?? 0) > 0 && isNotNull(cfgApparentPower?.relayChannelLabel?.ch3)) {
		cfgApparentPowerChannels.push("cfgRelayApparentPower3RecOpt");
		cfgApparentPowerActions = Math.max(cfgApparentPower?.cfgRelayApparentPower3Map?.value ?? 0, cfgApparentPowerActions);
	}
	if ((cfgApparentPower?.cfgRelayApparentPower4RecOpt?.value ?? 0) > 0 && isNotNull(cfgApparentPower?.relayChannelLabel?.ch4)) {
		cfgApparentPowerChannels.push("cfgRelayApparentPower4RecOpt");
		cfgApparentPowerActions = Math.max(cfgApparentPower?.cfgRelayApparentPower4Map?.value ?? 0, cfgApparentPowerActions);
	}
	return [
		cfgApparentPowerChannels,
		cfgApparentPowerActions,
	];
};

export const translateApparentPowerConfig = (
	cfgApparentPower: SettingsMeasurementsUser["cfgApparentPower"],
	channels: ApparentPowerForm["cfgApparentPowerChannels"],
	actions: number,
): SetConfigurationPayload => {
	let apparentPower1RecOptValue = 0;
	let apparentPower2RecOptValue = 0;
	let apparentPower3RecOptValue = 0;
	let apparentPower4RecOptValue = 0;
	if (channels.includes("cfgRelayApparentPower1RecOpt")) {
		apparentPower1RecOptValue = (cfgApparentPower?.cfgRelayApparentPower1RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayApparentPower2RecOpt")) {
		apparentPower2RecOptValue = (cfgApparentPower?.cfgRelayApparentPower2RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayApparentPower3RecOpt")) {
		apparentPower3RecOptValue = (cfgApparentPower?.cfgRelayApparentPower3RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayApparentPower4RecOpt")) {
		apparentPower4RecOptValue = (cfgApparentPower?.cfgRelayApparentPower4RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}

	return {
		...(((cfgApparentPower?.cfgRelayApparentPower1RecOpt?.value ?? 0) !== (apparentPower1RecOptValue)) ? { cfg_relay_apparent_power1_rec_opt: { value: apparentPower1RecOptValue } } : {}),
		...(((cfgApparentPower?.cfgRelayApparentPower1Map?.value ?? 0) !== (apparentPower1RecOptValue > 0 ? actions : 0)) ? { cfg_relay_apparent_power1_map: { value: apparentPower1RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgApparentPower?.cfgRelayApparentPower2RecOpt?.value ?? 0) !== (apparentPower2RecOptValue)) ? { cfg_relay_apparent_power2_rec_opt: { value: apparentPower2RecOptValue } } : {}),
		...(((cfgApparentPower?.cfgRelayApparentPower2Map?.value ?? 0) !== (apparentPower2RecOptValue > 0 ? actions : 0)) ? { cfg_relay_apparent_power2_map: { value: apparentPower2RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgApparentPower?.cfgRelayApparentPower3RecOpt?.value ?? 0) !== (apparentPower3RecOptValue)) ? { cfg_relay_apparent_power3_rec_opt: { value: apparentPower3RecOptValue } } : {}),
		...(((cfgApparentPower?.cfgRelayApparentPower3Map?.value ?? 0) !== (apparentPower3RecOptValue > 0 ? actions : 0)) ? { cfg_relay_apparent_power3_map: { value: apparentPower3RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgApparentPower?.cfgRelayApparentPower4RecOpt?.value ?? 0) !== (apparentPower4RecOptValue)) ? { cfg_relay_apparent_power4_rec_opt: { value: apparentPower4RecOptValue } } : {}),
		...(((cfgApparentPower?.cfgRelayApparentPower4Map?.value ?? 0) !== (apparentPower4RecOptValue > 0 ? actions : 0)) ? { cfg_relay_apparent_power4_map: { value: apparentPower4RecOptValue > 0 ? actions : 0 } } : {}),
	};
};

export const mapActivePowerPlusConfig = (cfgActivePowerPlus: SettingsMeasurementsUser["cfgActivePowerPlus"]): [ channels: ActivePowerPlusForm["cfgActivePowerPlusChannels"], actions: number ] => {
	let cfgActivePowerPlusChannels: ActivePowerPlusForm["cfgActivePowerPlusChannels"] = [];
	let cfgActivePowerPlusActions = 0;
	if (isNull(cfgActivePowerPlus)) return [ cfgActivePowerPlusChannels, cfgActivePowerPlusActions ];
	if ((cfgActivePowerPlus?.cfgRelayActivePowerPlus1RecOpt?.value ?? 0) > 0 && isNotNull(cfgActivePowerPlus?.relayChannelLabel?.ch1)) {
		cfgActivePowerPlusChannels.push("cfgRelayActivePowerPlus1RecOpt");
		cfgActivePowerPlusActions = Math.max(cfgActivePowerPlus?.cfgRelayActivePowerPlus1Map?.value ?? 0, cfgActivePowerPlusActions);
	}
	if ((cfgActivePowerPlus?.cfgRelayActivePowerPlus2RecOpt?.value ?? 0) > 0 && isNotNull(cfgActivePowerPlus?.relayChannelLabel?.ch2)) {
		cfgActivePowerPlusChannels.push("cfgRelayActivePowerPlus2RecOpt");
		cfgActivePowerPlusActions = Math.max(cfgActivePowerPlus?.cfgRelayActivePowerPlus2Map?.value ?? 0, cfgActivePowerPlusActions);
	}
	if ((cfgActivePowerPlus?.cfgRelayActivePowerPlus3RecOpt?.value ?? 0) > 0 && isNotNull(cfgActivePowerPlus?.relayChannelLabel?.ch3)) {
		cfgActivePowerPlusChannels.push("cfgRelayActivePowerPlus3RecOpt");
		cfgActivePowerPlusActions = Math.max(cfgActivePowerPlus?.cfgRelayActivePowerPlus3Map?.value ?? 0, cfgActivePowerPlusActions);
	}
	if ((cfgActivePowerPlus?.cfgRelayActivePowerPlus4RecOpt?.value ?? 0) > 0 && isNotNull(cfgActivePowerPlus?.relayChannelLabel?.ch4)) {
		cfgActivePowerPlusChannels.push("cfgRelayActivePowerPlus4RecOpt");
		cfgActivePowerPlusActions = Math.max(cfgActivePowerPlus?.cfgRelayActivePowerPlus4Map?.value ?? 0, cfgActivePowerPlusActions);
	}
	return [
		cfgActivePowerPlusChannels,
		cfgActivePowerPlusActions,
	];
};

export const translateActivePowerPlusConfig = (
	cfgActivePowerPlus: SettingsMeasurementsUser["cfgActivePowerPlus"],
	channels: ActivePowerPlusForm["cfgActivePowerPlusChannels"],
	actions: number,
): SetConfigurationPayload => {
	let activePowerPlus1RecOptValue = 0;
	let activePowerPlus2RecOptValue = 0;
	let activePowerPlus3RecOptValue = 0;
	let activePowerPlus4RecOptValue = 0;
	if (channels.includes("cfgRelayActivePowerPlus1RecOpt")) {
		activePowerPlus1RecOptValue = (cfgActivePowerPlus?.cfgRelayActivePowerPlus1RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayActivePowerPlus2RecOpt")) {
		activePowerPlus2RecOptValue = (cfgActivePowerPlus?.cfgRelayActivePowerPlus2RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayActivePowerPlus3RecOpt")) {
		activePowerPlus3RecOptValue = (cfgActivePowerPlus?.cfgRelayActivePowerPlus3RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayActivePowerPlus4RecOpt")) {
		activePowerPlus4RecOptValue = (cfgActivePowerPlus?.cfgRelayActivePowerPlus4RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}

	return {
		...(((cfgActivePowerPlus?.cfgRelayActivePowerPlus1RecOpt?.value ?? 0) !== (activePowerPlus1RecOptValue)) ? { cfg_relay_active_power_plus1_rec_opt: { value: activePowerPlus1RecOptValue } } : {}),
		...(((cfgActivePowerPlus?.cfgRelayActivePowerPlus1Map?.value ?? 0) !== (activePowerPlus1RecOptValue > 0 ? actions : 0)) ? { cfg_relay_active_power_plus1_map: { value: activePowerPlus1RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgActivePowerPlus?.cfgRelayActivePowerPlus2RecOpt?.value ?? 0) !== (activePowerPlus2RecOptValue)) ? { cfg_relay_active_power_plus2_rec_opt: { value: activePowerPlus2RecOptValue } } : {}),
		...(((cfgActivePowerPlus?.cfgRelayActivePowerPlus2Map?.value ?? 0) !== (activePowerPlus2RecOptValue > 0 ? actions : 0)) ? { cfg_relay_active_power_plus2_map: { value: activePowerPlus2RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgActivePowerPlus?.cfgRelayActivePowerPlus3RecOpt?.value ?? 0) !== (activePowerPlus3RecOptValue)) ? { cfg_relay_active_power_plus3_rec_opt: { value: activePowerPlus3RecOptValue } } : {}),
		...(((cfgActivePowerPlus?.cfgRelayActivePowerPlus3Map?.value ?? 0) !== (activePowerPlus3RecOptValue > 0 ? actions : 0)) ? { cfg_relay_active_power_plus3_map: { value: activePowerPlus3RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgActivePowerPlus?.cfgRelayActivePowerPlus4RecOpt?.value ?? 0) !== (activePowerPlus4RecOptValue)) ? { cfg_relay_active_power_plus4_rec_opt: { value: activePowerPlus4RecOptValue } } : {}),
		...(((cfgActivePowerPlus?.cfgRelayActivePowerPlus4Map?.value ?? 0) !== (activePowerPlus4RecOptValue > 0 ? actions : 0)) ? { cfg_relay_active_power_plus4_map: { value: activePowerPlus4RecOptValue > 0 ? actions : 0 } } : {}),
	};
};

export const mapActivePowerMinusConfig = (cfgActivePowerMinus: SettingsMeasurementsUser["cfgActivePowerMinus"]): [ channels: ActivePowerMinusForm["cfgActivePowerMinusChannels"], actions: number ] => {
	let cfgActivePowerMinusChannels: ActivePowerMinusForm["cfgActivePowerMinusChannels"] = [];
	let cfgActivePowerMinusActions = 0;
	if (isNull(cfgActivePowerMinus)) return [ cfgActivePowerMinusChannels, cfgActivePowerMinusActions ];
	if ((cfgActivePowerMinus?.cfgRelayActivePowerMinus1RecOpt?.value ?? 0) > 0 && isNotNull(cfgActivePowerMinus?.relayChannelLabel?.ch1)) {
		cfgActivePowerMinusChannels.push("cfgRelayActivePowerMinus1RecOpt");
		cfgActivePowerMinusActions = Math.max(cfgActivePowerMinus?.cfgRelayActivePowerMinus1Map?.value ?? 0, cfgActivePowerMinusActions);
	}
	if ((cfgActivePowerMinus?.cfgRelayActivePowerMinus2RecOpt?.value ?? 0) > 0 && isNotNull(cfgActivePowerMinus?.relayChannelLabel?.ch2)) {
		cfgActivePowerMinusChannels.push("cfgRelayActivePowerMinus2RecOpt");
		cfgActivePowerMinusActions = Math.max(cfgActivePowerMinus?.cfgRelayActivePowerMinus2Map?.value ?? 0, cfgActivePowerMinusActions);
	}
	if ((cfgActivePowerMinus?.cfgRelayActivePowerMinus3RecOpt?.value ?? 0) > 0 && isNotNull(cfgActivePowerMinus?.relayChannelLabel?.ch3)) {
		cfgActivePowerMinusChannels.push("cfgRelayActivePowerMinus3RecOpt");
		cfgActivePowerMinusActions = Math.max(cfgActivePowerMinus?.cfgRelayActivePowerMinus3Map?.value ?? 0, cfgActivePowerMinusActions);
	}
	if ((cfgActivePowerMinus?.cfgRelayActivePowerMinus4RecOpt?.value ?? 0) > 0 && isNotNull(cfgActivePowerMinus?.relayChannelLabel?.ch4)) {
		cfgActivePowerMinusChannels.push("cfgRelayActivePowerMinus4RecOpt");
		cfgActivePowerMinusActions = Math.max(cfgActivePowerMinus?.cfgRelayActivePowerMinus4Map?.value ?? 0, cfgActivePowerMinusActions);
	}
	return [
		cfgActivePowerMinusChannels,
		cfgActivePowerMinusActions,
	];
};

export const translateActivePowerMinusConfig = (
	cfgActivePowerMinus: SettingsMeasurementsUser["cfgActivePowerMinus"],
	channels: ActivePowerMinusForm["cfgActivePowerMinusChannels"],
	actions: number,
): SetConfigurationPayload => {
	let activePowerMinus1RecOptValue = 0;
	let activePowerMinus2RecOptValue = 0;
	let activePowerMinus3RecOptValue = 0;
	let activePowerMinus4RecOptValue = 0;
	if (channels.includes("cfgRelayActivePowerMinus1RecOpt")) {
		activePowerMinus1RecOptValue = (cfgActivePowerMinus?.cfgRelayActivePowerMinus1RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayActivePowerMinus2RecOpt")) {
		activePowerMinus2RecOptValue = (cfgActivePowerMinus?.cfgRelayActivePowerMinus2RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayActivePowerMinus3RecOpt")) {
		activePowerMinus3RecOptValue = (cfgActivePowerMinus?.cfgRelayActivePowerMinus3RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayActivePowerMinus4RecOpt")) {
		activePowerMinus4RecOptValue = (cfgActivePowerMinus?.cfgRelayActivePowerMinus4RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}

	return {
		...(((cfgActivePowerMinus?.cfgRelayActivePowerMinus1RecOpt?.value ?? 0) !== (activePowerMinus1RecOptValue)) ? { cfg_relay_active_power_minus1_rec_opt: { value: activePowerMinus1RecOptValue } } : {}),
		...(((cfgActivePowerMinus?.cfgRelayActivePowerMinus1Map?.value ?? 0) !== (activePowerMinus1RecOptValue > 0 ? actions : 0)) ? { cfg_relay_active_power_minus1_map: { value: activePowerMinus1RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgActivePowerMinus?.cfgRelayActivePowerMinus2RecOpt?.value ?? 0) !== (activePowerMinus2RecOptValue)) ? { cfg_relay_active_power_minus2_rec_opt: { value: activePowerMinus2RecOptValue } } : {}),
		...(((cfgActivePowerMinus?.cfgRelayActivePowerMinus2Map?.value ?? 0) !== (activePowerMinus2RecOptValue > 0 ? actions : 0)) ? { cfg_relay_active_power_minus2_map: { value: activePowerMinus2RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgActivePowerMinus?.cfgRelayActivePowerMinus3RecOpt?.value ?? 0) !== (activePowerMinus3RecOptValue)) ? { cfg_relay_active_power_minus3_rec_opt: { value: activePowerMinus3RecOptValue } } : {}),
		...(((cfgActivePowerMinus?.cfgRelayActivePowerMinus3Map?.value ?? 0) !== (activePowerMinus3RecOptValue > 0 ? actions : 0)) ? { cfg_relay_active_power_minus3_map: { value: activePowerMinus3RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgActivePowerMinus?.cfgRelayActivePowerMinus4RecOpt?.value ?? 0) !== (activePowerMinus4RecOptValue)) ? { cfg_relay_active_power_minus4_rec_opt: { value: activePowerMinus4RecOptValue } } : {}),
		...(((cfgActivePowerMinus?.cfgRelayActivePowerMinus4Map?.value ?? 0) !== (activePowerMinus4RecOptValue > 0 ? actions : 0)) ? { cfg_relay_active_power_minus4_map: { value: activePowerMinus4RecOptValue > 0 ? actions : 0 } } : {}),
	};
};

export const mapDisplacementPowerFactorConfig = (cfgDisplacementPowerFactor: SettingsMeasurementsUser["cfgDisplacementPowerFactor"]): [ channels: DisplacementPowerFactorForm["cfgDisplacementPowerFactorChannels"], actions: number ] => {
	let cfgDisplacementPowerFactorChannels: DisplacementPowerFactorForm["cfgDisplacementPowerFactorChannels"] = [];
	let cfgDisplacementPowerFactorActions = 0;
	if (isNull(cfgDisplacementPowerFactor)) return [ cfgDisplacementPowerFactorChannels, cfgDisplacementPowerFactorActions ];
	if ((cfgDisplacementPowerFactor?.cfgRelayDisplacementPowerFactor1RecOpt?.value ?? 0) > 0 && isNotNull(cfgDisplacementPowerFactor?.relayChannelLabel?.ch1)) {
		cfgDisplacementPowerFactorChannels.push("cfgRelayDisplacementPowerFactor1RecOpt");
		cfgDisplacementPowerFactorActions = Math.max(cfgDisplacementPowerFactor?.cfgRelayDisplacementPowerFactor1Map?.value ?? 0, cfgDisplacementPowerFactorActions);
	}
	if ((cfgDisplacementPowerFactor?.cfgRelayDisplacementPowerFactor2RecOpt?.value ?? 0) > 0 && isNotNull(cfgDisplacementPowerFactor?.relayChannelLabel?.ch2)) {
		cfgDisplacementPowerFactorChannels.push("cfgRelayDisplacementPowerFactor2RecOpt");
		cfgDisplacementPowerFactorActions = Math.max(cfgDisplacementPowerFactor?.cfgRelayDisplacementPowerFactor2Map?.value ?? 0, cfgDisplacementPowerFactorActions);
	}
	if ((cfgDisplacementPowerFactor?.cfgRelayDisplacementPowerFactor3RecOpt?.value ?? 0) > 0 && isNotNull(cfgDisplacementPowerFactor?.relayChannelLabel?.ch3)) {
		cfgDisplacementPowerFactorChannels.push("cfgRelayDisplacementPowerFactor3RecOpt");
		cfgDisplacementPowerFactorActions = Math.max(cfgDisplacementPowerFactor?.cfgRelayDisplacementPowerFactor3Map?.value ?? 0, cfgDisplacementPowerFactorActions);
	}
	if ((cfgDisplacementPowerFactor?.cfgRelayDisplacementPowerFactor4RecOpt?.value ?? 0) > 0 && isNotNull(cfgDisplacementPowerFactor?.relayChannelLabel?.ch4)) {
		cfgDisplacementPowerFactorChannels.push("cfgRelayDisplacementPowerFactor4RecOpt");
		cfgDisplacementPowerFactorActions = Math.max(cfgDisplacementPowerFactor?.cfgRelayDisplacementPowerFactor4Map?.value ?? 0, cfgDisplacementPowerFactorActions);
	}
	return [
		cfgDisplacementPowerFactorChannels,
		cfgDisplacementPowerFactorActions,
	];
};

export const translateDisplacementPowerFactorConfig = (
	cfgDisplacementPowerFactor: SettingsMeasurementsUser["cfgDisplacementPowerFactor"],
	channels: DisplacementPowerFactorForm["cfgDisplacementPowerFactorChannels"],
	actions: number,
): SetConfigurationPayload => {
	let displacementPowerFactor1RecOptValue = 0;
	let displacementPowerFactor2RecOptValue = 0;
	let displacementPowerFactor3RecOptValue = 0;
	let displacementPowerFactor4RecOptValue = 0;
	if (channels.includes("cfgRelayDisplacementPowerFactor1RecOpt")) {
		displacementPowerFactor1RecOptValue = (cfgDisplacementPowerFactor?.cfgRelayDisplacementPowerFactor1RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayDisplacementPowerFactor2RecOpt")) {
		displacementPowerFactor2RecOptValue = (cfgDisplacementPowerFactor?.cfgRelayDisplacementPowerFactor2RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayDisplacementPowerFactor3RecOpt")) {
		displacementPowerFactor3RecOptValue = (cfgDisplacementPowerFactor?.cfgRelayDisplacementPowerFactor3RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayDisplacementPowerFactor4RecOpt")) {
		displacementPowerFactor4RecOptValue = (cfgDisplacementPowerFactor?.cfgRelayDisplacementPowerFactor4RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}

	return {
		...(((cfgDisplacementPowerFactor?.cfgRelayDisplacementPowerFactor1RecOpt?.value ?? 0) !== (displacementPowerFactor1RecOptValue)) ? { cfg_relay_displacement_power_factor1_rec_opt: { value: displacementPowerFactor1RecOptValue } } : {}),
		...(((cfgDisplacementPowerFactor?.cfgRelayDisplacementPowerFactor1Map?.value ?? 0) !== (displacementPowerFactor1RecOptValue > 0 ? actions : 0)) ? { cfg_relay_displacement_power_factor1_map: { value: displacementPowerFactor1RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgDisplacementPowerFactor?.cfgRelayDisplacementPowerFactor2RecOpt?.value ?? 0) !== (displacementPowerFactor2RecOptValue)) ? { cfg_relay_displacement_power_factor2_rec_opt: { value: displacementPowerFactor2RecOptValue } } : {}),
		...(((cfgDisplacementPowerFactor?.cfgRelayDisplacementPowerFactor2Map?.value ?? 0) !== (displacementPowerFactor2RecOptValue > 0 ? actions : 0)) ? { cfg_relay_displacement_power_factor2_map: { value: displacementPowerFactor2RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgDisplacementPowerFactor?.cfgRelayDisplacementPowerFactor3RecOpt?.value ?? 0) !== (displacementPowerFactor3RecOptValue)) ? { cfg_relay_displacement_power_factor3_rec_opt: { value: displacementPowerFactor3RecOptValue } } : {}),
		...(((cfgDisplacementPowerFactor?.cfgRelayDisplacementPowerFactor3Map?.value ?? 0) !== (displacementPowerFactor3RecOptValue > 0 ? actions : 0)) ? { cfg_relay_displacement_power_factor3_map: { value: displacementPowerFactor3RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgDisplacementPowerFactor?.cfgRelayDisplacementPowerFactor4RecOpt?.value ?? 0) !== (displacementPowerFactor4RecOptValue)) ? { cfg_relay_displacement_power_factor4_rec_opt: { value: displacementPowerFactor4RecOptValue } } : {}),
		...(((cfgDisplacementPowerFactor?.cfgRelayDisplacementPowerFactor4Map?.value ?? 0) !== (displacementPowerFactor4RecOptValue > 0 ? actions : 0)) ? { cfg_relay_displacement_power_factor4_map: { value: displacementPowerFactor4RecOptValue > 0 ? actions : 0 } } : {}),
	};
};

export const mapPowerFactorConfig = (cfgPowerFactor: SettingsMeasurementsUser["cfgPowerFactor"]): [ channels: PowerFactorForm["cfgPowerFactorChannels"], actions: number ] => {
	let cfgPowerFactorChannels: PowerFactorForm["cfgPowerFactorChannels"] = [];
	let cfgPowerFactorActions = 0;
	if (isNull(cfgPowerFactor)) return [ cfgPowerFactorChannels, cfgPowerFactorActions ];
	if ((cfgPowerFactor?.cfgRelayPowerFactor1RecOpt?.value ?? 0) > 0 && isNotNull(cfgPowerFactor?.relayChannelLabel?.ch1)) {
		cfgPowerFactorChannels.push("cfgRelayPowerFactor1RecOpt");
		cfgPowerFactorActions = Math.max(cfgPowerFactor?.cfgRelayPowerFactor1Map?.value ?? 0, cfgPowerFactorActions);
	}
	if ((cfgPowerFactor?.cfgRelayPowerFactor2RecOpt?.value ?? 0) > 0 && isNotNull(cfgPowerFactor?.relayChannelLabel?.ch2)) {
		cfgPowerFactorChannels.push("cfgRelayPowerFactor2RecOpt");
		cfgPowerFactorActions = Math.max(cfgPowerFactor?.cfgRelayPowerFactor2Map?.value ?? 0, cfgPowerFactorActions);
	}
	if ((cfgPowerFactor?.cfgRelayPowerFactor3RecOpt?.value ?? 0) > 0 && isNotNull(cfgPowerFactor?.relayChannelLabel?.ch3)) {
		cfgPowerFactorChannels.push("cfgRelayPowerFactor3RecOpt");
		cfgPowerFactorActions = Math.max(cfgPowerFactor?.cfgRelayPowerFactor3Map?.value ?? 0, cfgPowerFactorActions);
	}
	if ((cfgPowerFactor?.cfgRelayPowerFactor4RecOpt?.value ?? 0) > 0 && isNotNull(cfgPowerFactor?.relayChannelLabel?.ch4)) {
		cfgPowerFactorChannels.push("cfgRelayPowerFactor4RecOpt");
		cfgPowerFactorActions = Math.max(cfgPowerFactor?.cfgRelayPowerFactor4Map?.value ?? 0, cfgPowerFactorActions);
	}
	return [
		cfgPowerFactorChannels,
		cfgPowerFactorActions,
	];
};

export const translatePowerFactorConfig = (
	cfgPowerFactor: SettingsMeasurementsUser["cfgPowerFactor"],
	channels: PowerFactorForm["cfgPowerFactorChannels"],
	actions: number,
): SetConfigurationPayload => {
	let powerFactor1RecOptValue = 0;
	let powerFactor2RecOptValue = 0;
	let powerFactor3RecOptValue = 0;
	let powerFactor4RecOptValue = 0;
	if (channels.includes("cfgRelayPowerFactor1RecOpt")) {
		powerFactor1RecOptValue = (cfgPowerFactor?.cfgRelayPowerFactor1RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayPowerFactor2RecOpt")) {
		powerFactor2RecOptValue = (cfgPowerFactor?.cfgRelayPowerFactor2RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayPowerFactor3RecOpt")) {
		powerFactor3RecOptValue = (cfgPowerFactor?.cfgRelayPowerFactor3RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayPowerFactor4RecOpt")) {
		powerFactor4RecOptValue = (cfgPowerFactor?.cfgRelayPowerFactor4RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}

	return {
		...(((cfgPowerFactor?.cfgRelayPowerFactor1RecOpt?.value ?? 0) !== (powerFactor1RecOptValue)) ? { cfg_relay_power_factor1_rec_opt: { value: powerFactor1RecOptValue } } : {}),
		...(((cfgPowerFactor?.cfgRelayPowerFactor1Map?.value ?? 0) !== (powerFactor1RecOptValue > 0 ? actions : 0)) ? { cfg_relay_power_factor1_map: { value: powerFactor1RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgPowerFactor?.cfgRelayPowerFactor2RecOpt?.value ?? 0) !== (powerFactor2RecOptValue)) ? { cfg_relay_power_factor2_rec_opt: { value: powerFactor2RecOptValue } } : {}),
		...(((cfgPowerFactor?.cfgRelayPowerFactor2Map?.value ?? 0) !== (powerFactor2RecOptValue > 0 ? actions : 0)) ? { cfg_relay_power_factor2_map: { value: powerFactor2RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgPowerFactor?.cfgRelayPowerFactor3RecOpt?.value ?? 0) !== (powerFactor3RecOptValue)) ? { cfg_relay_power_factor3_rec_opt: { value: powerFactor3RecOptValue } } : {}),
		...(((cfgPowerFactor?.cfgRelayPowerFactor3Map?.value ?? 0) !== (powerFactor3RecOptValue > 0 ? actions : 0)) ? { cfg_relay_power_factor3_map: { value: powerFactor3RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgPowerFactor?.cfgRelayPowerFactor4RecOpt?.value ?? 0) !== (powerFactor4RecOptValue)) ? { cfg_relay_power_factor4_rec_opt: { value: powerFactor4RecOptValue } } : {}),
		...(((cfgPowerFactor?.cfgRelayPowerFactor4Map?.value ?? 0) !== (powerFactor4RecOptValue > 0 ? actions : 0)) ? { cfg_relay_power_factor4_map: { value: powerFactor4RecOptValue > 0 ? actions : 0 } } : {}),
	};
};

export const mapVoltageThdfConfig = (cfgVoltageThdf: SettingsMeasurementsUser["cfgVoltageThdf"]): [ channels: VoltageThdfForm["cfgVoltageThdfChannels"], actions: number ] => {
	let cfgVoltageThdfChannels: VoltageThdfForm["cfgVoltageThdfChannels"] = [];
	let cfgVoltageThdfActions = 0;
	if (isNull(cfgVoltageThdf)) return [ cfgVoltageThdfChannels, cfgVoltageThdfActions ];
	if ((cfgVoltageThdf?.cfgRelayVoltageThdf1RecOpt?.value ?? 0) > 0 && isNotNull(cfgVoltageThdf?.relayChannelLabel?.ch1)) {
		cfgVoltageThdfChannels.push("cfgRelayVoltageThdf1RecOpt");
		cfgVoltageThdfActions = Math.max(cfgVoltageThdf?.cfgRelayVoltageThdf1Map?.value ?? 0, cfgVoltageThdfActions);
	}
	if ((cfgVoltageThdf?.cfgRelayVoltageThdf2RecOpt?.value ?? 0) > 0 && isNotNull(cfgVoltageThdf?.relayChannelLabel?.ch2)) {
		cfgVoltageThdfChannels.push("cfgRelayVoltageThdf2RecOpt");
		cfgVoltageThdfActions = Math.max(cfgVoltageThdf?.cfgRelayVoltageThdf2Map?.value ?? 0, cfgVoltageThdfActions);
	}
	if ((cfgVoltageThdf?.cfgRelayVoltageThdf3RecOpt?.value ?? 0) > 0 && isNotNull(cfgVoltageThdf?.relayChannelLabel?.ch3)) {
		cfgVoltageThdfChannels.push("cfgRelayVoltageThdf3RecOpt");
		cfgVoltageThdfActions = Math.max(cfgVoltageThdf?.cfgRelayVoltageThdf3Map?.value ?? 0, cfgVoltageThdfActions);
	}
	if ((cfgVoltageThdf?.cfgRelayVoltageThdf4RecOpt?.value ?? 0) > 0 && isNotNull(cfgVoltageThdf?.relayChannelLabel?.ch4)) {
		cfgVoltageThdfChannels.push("cfgRelayVoltageThdf4RecOpt");
		cfgVoltageThdfActions = Math.max(cfgVoltageThdf?.cfgRelayVoltageThdf4Map?.value ?? 0, cfgVoltageThdfActions);
	}
	return [
		cfgVoltageThdfChannels,
		cfgVoltageThdfActions,
	];
};

export const translateVoltageThdfConfig = (
	cfgVoltageThdf: SettingsMeasurementsUser["cfgVoltageThdf"],
	channels: VoltageThdfForm["cfgVoltageThdfChannels"],
	actions: number,
): SetConfigurationPayload => {
	let voltageThdf1RecOptValue = 0;
	let voltageThdf2RecOptValue = 0;
	let voltageThdf3RecOptValue = 0;
	let voltageThdf4RecOptValue = 0;
	if (channels.includes("cfgRelayVoltageThdf1RecOpt")) {
		voltageThdf1RecOptValue = (cfgVoltageThdf?.cfgRelayVoltageThdf1RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayVoltageThdf2RecOpt")) {
		voltageThdf2RecOptValue = (cfgVoltageThdf?.cfgRelayVoltageThdf2RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayVoltageThdf3RecOpt")) {
		voltageThdf3RecOptValue = (cfgVoltageThdf?.cfgRelayVoltageThdf3RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayVoltageThdf4RecOpt")) {
		voltageThdf4RecOptValue = (cfgVoltageThdf?.cfgRelayVoltageThdf4RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}

	return {
		...(((cfgVoltageThdf?.cfgRelayVoltageThdf1RecOpt?.value ?? 0) !== (voltageThdf1RecOptValue)) ? { cfg_relay_voltage_thdf1_rec_opt: { value: voltageThdf1RecOptValue } } : {}),
		...(((cfgVoltageThdf?.cfgRelayVoltageThdf1Map?.value ?? 0) !== (voltageThdf1RecOptValue > 0 ? actions : 0)) ? { cfg_relay_voltage_thdf1_map: { value: voltageThdf1RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgVoltageThdf?.cfgRelayVoltageThdf2RecOpt?.value ?? 0) !== (voltageThdf2RecOptValue)) ? { cfg_relay_voltage_thdf2_rec_opt: { value: voltageThdf2RecOptValue } } : {}),
		...(((cfgVoltageThdf?.cfgRelayVoltageThdf2Map?.value ?? 0) !== (voltageThdf2RecOptValue > 0 ? actions : 0)) ? { cfg_relay_voltage_thdf2_map: { value: voltageThdf2RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgVoltageThdf?.cfgRelayVoltageThdf3RecOpt?.value ?? 0) !== (voltageThdf3RecOptValue)) ? { cfg_relay_voltage_thdf3_rec_opt: { value: voltageThdf3RecOptValue } } : {}),
		...(((cfgVoltageThdf?.cfgRelayVoltageThdf3Map?.value ?? 0) !== (voltageThdf3RecOptValue > 0 ? actions : 0)) ? { cfg_relay_voltage_thdf3_map: { value: voltageThdf3RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgVoltageThdf?.cfgRelayVoltageThdf4RecOpt?.value ?? 0) !== (voltageThdf4RecOptValue)) ? { cfg_relay_voltage_thdf4_rec_opt: { value: voltageThdf4RecOptValue } } : {}),
		...(((cfgVoltageThdf?.cfgRelayVoltageThdf4Map?.value ?? 0) !== (voltageThdf4RecOptValue > 0 ? actions : 0)) ? { cfg_relay_voltage_thdf4_map: { value: voltageThdf4RecOptValue > 0 ? actions : 0 } } : {}),
	};
};

export const mapCurrentThdfConfig = (cfgCurrentThdf: SettingsMeasurementsUser["cfgCurrentThdf"]): [ channels: CurrentThdfForm["cfgCurrentThdfChannels"], actions: number ] => {
	let cfgCurrentThdfChannels: CurrentThdfForm["cfgCurrentThdfChannels"] = [];
	let cfgCurrentThdfActions = 0;
	if (isNull(cfgCurrentThdf)) return [ cfgCurrentThdfChannels, cfgCurrentThdfActions ];
	if ((cfgCurrentThdf?.cfgRelayCurrentThdf1RecOpt?.value ?? 0) > 0 && isNotNull(cfgCurrentThdf?.relayChannelLabel?.ch1)) {
		cfgCurrentThdfChannels.push("cfgRelayCurrentThdf1RecOpt");
		cfgCurrentThdfActions = Math.max(cfgCurrentThdf?.cfgRelayCurrentThdf1Map?.value ?? 0, cfgCurrentThdfActions);
	}
	if ((cfgCurrentThdf?.cfgRelayCurrentThdf2RecOpt?.value ?? 0) > 0 && isNotNull(cfgCurrentThdf?.relayChannelLabel?.ch2)) {
		cfgCurrentThdfChannels.push("cfgRelayCurrentThdf2RecOpt");
		cfgCurrentThdfActions = Math.max(cfgCurrentThdf?.cfgRelayCurrentThdf2Map?.value ?? 0, cfgCurrentThdfActions);
	}
	if ((cfgCurrentThdf?.cfgRelayCurrentThdf3RecOpt?.value ?? 0) > 0 && isNotNull(cfgCurrentThdf?.relayChannelLabel?.ch3)) {
		cfgCurrentThdfChannels.push("cfgRelayCurrentThdf3RecOpt");
		cfgCurrentThdfActions = Math.max(cfgCurrentThdf?.cfgRelayCurrentThdf3Map?.value ?? 0, cfgCurrentThdfActions);
	}
	if ((cfgCurrentThdf?.cfgRelayCurrentThdf4RecOpt?.value ?? 0) > 0 && isNotNull(cfgCurrentThdf?.relayChannelLabel?.ch4)) {
		cfgCurrentThdfChannels.push("cfgRelayCurrentThdf4RecOpt");
		cfgCurrentThdfActions = Math.max(cfgCurrentThdf?.cfgRelayCurrentThdf4Map?.value ?? 0, cfgCurrentThdfActions);
	}
	return [
		cfgCurrentThdfChannels,
		cfgCurrentThdfActions,
	];
};

export const translateCurrentThdfConfig = (
	cfgCurrentThdf: SettingsMeasurementsUser["cfgCurrentThdf"],
	channels: CurrentThdfForm["cfgCurrentThdfChannels"],
	actions: number,
): SetConfigurationPayload => {
	let currentThdf1RecOptValue = 0;
	let currentThdf2RecOptValue = 0;
	let currentThdf3RecOptValue = 0;
	let currentThdf4RecOptValue = 0;
	if (channels.includes("cfgRelayCurrentThdf1RecOpt")) {
		currentThdf1RecOptValue = (cfgCurrentThdf?.cfgRelayCurrentThdf1RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayCurrentThdf2RecOpt")) {
		currentThdf2RecOptValue = (cfgCurrentThdf?.cfgRelayCurrentThdf2RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayCurrentThdf3RecOpt")) {
		currentThdf3RecOptValue = (cfgCurrentThdf?.cfgRelayCurrentThdf3RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}
	if (channels.includes("cfgRelayCurrentThdf4RecOpt")) {
		currentThdf4RecOptValue = (cfgCurrentThdf?.cfgRelayCurrentThdf4RecOpt?.options ?? []).reduce((prev, next) => prev + next.mask, 0);
	}

	return {
		...(((cfgCurrentThdf?.cfgRelayCurrentThdf1RecOpt?.value ?? 0) !== (currentThdf1RecOptValue)) ? { cfg_relay_current_thdf1_rec_opt: { value: currentThdf1RecOptValue } } : {}),
		...(((cfgCurrentThdf?.cfgRelayCurrentThdf1Map?.value ?? 0) !== (currentThdf1RecOptValue > 0 ? actions : 0)) ? { cfg_relay_current_thdf1_map: { value: currentThdf1RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgCurrentThdf?.cfgRelayCurrentThdf2RecOpt?.value ?? 0) !== (currentThdf2RecOptValue)) ? { cfg_relay_current_thdf2_rec_opt: { value: currentThdf2RecOptValue } } : {}),
		...(((cfgCurrentThdf?.cfgRelayCurrentThdf2Map?.value ?? 0) !== (currentThdf2RecOptValue > 0 ? actions : 0)) ? { cfg_relay_current_thdf2_map: { value: currentThdf2RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgCurrentThdf?.cfgRelayCurrentThdf3RecOpt?.value ?? 0) !== (currentThdf3RecOptValue)) ? { cfg_relay_current_thdf3_rec_opt: { value: currentThdf3RecOptValue } } : {}),
		...(((cfgCurrentThdf?.cfgRelayCurrentThdf3Map?.value ?? 0) !== (currentThdf3RecOptValue > 0 ? actions : 0)) ? { cfg_relay_current_thdf3_map: { value: currentThdf3RecOptValue > 0 ? actions : 0 } } : {}),
		...(((cfgCurrentThdf?.cfgRelayCurrentThdf4RecOpt?.value ?? 0) !== (currentThdf4RecOptValue)) ? { cfg_relay_current_thdf4_rec_opt: { value: currentThdf4RecOptValue } } : {}),
		...(((cfgCurrentThdf?.cfgRelayCurrentThdf4Map?.value ?? 0) !== (currentThdf4RecOptValue > 0 ? actions : 0)) ? { cfg_relay_current_thdf4_map: { value: currentThdf4RecOptValue > 0 ? actions : 0 } } : {}),
	};
};
