import { RecordingOption, SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateNumberField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import BasicRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/BasicRecordingOptionConfiguration.component";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { useContext, useEffect } from "react";
import { MeasurementsUserContext } from "src/app/components/Measurements/MeasurementsUserContent.component";
import EventRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/EventRecordingOptionConfiguration.component";
import Input from "src/app/components/Form/Input.component";
import { Button, Card } from "flowbite-react";
import { EnumOptionsSetting } from "src/app/types/util.types";
import { isOptionSelected } from "src/app/utils/helpers";
import { isChannelsDiff, mapActivePowerPlusConfig } from "src/app/utils/configuration";
import RelayChannelChooser from "src/app/components/MeasurementsUser/Generic/RelayChannelChooser.component";
import { isEmptyArray, isNotNull } from "src/app/utils/typeguards";
import RelayActionSelect from "src/app/components/MeasurementsUser/Generic/RelayActionSelect.component";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";
import classNames from "classnames";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsMeasurementsUser: SettingsMeasurementsUser
	};

export type ActivePowerPlusForm = {
	cfgActivePowerPlusRecOpt: number
	cfgActivePowerPlusRecOptOptions: EnumOptionsSetting<RecordingOption>["options"]
	cfgActivePowerPlusEventThresholdMax: string
	cfgActivePowerPlusEventThresholdMaxMin: number
	cfgActivePowerPlusEventThresholdMaxMax: number
	cfgActivePowerPlusChannels: ("cfgRelayActivePowerPlus1RecOpt" | "cfgRelayActivePowerPlus2RecOpt" | "cfgRelayActivePowerPlus3RecOpt" | "cfgRelayActivePowerPlus4RecOpt")[]
	cfgActivePowerPlusActions: number
}

const validator: FormValidator<ActivePowerPlusForm> = {
	cfgActivePowerPlusRecOpt: () => null,
	cfgActivePowerPlusRecOptOptions: () => null,
	cfgActivePowerPlusEventThresholdMax: (cfgActivePowerPlusEventThresholdMax, optional, form) => {
		if (!isOptionSelected(form.cfgActivePowerPlusRecOpt.value, RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE, form.cfgActivePowerPlusRecOptOptions.value)) return null;

		return validateNumberField(i18n.t("ENERGY.max"), cfgActivePowerPlusEventThresholdMax, optional, "he", { from: form.cfgActivePowerPlusEventThresholdMaxMin.value, to: form.cfgActivePowerPlusEventThresholdMaxMax.value, decimalPlaces: 2 });
	},
	cfgActivePowerPlusEventThresholdMaxMin: () => null,
	cfgActivePowerPlusEventThresholdMaxMax: () => null,
	cfgActivePowerPlusChannels: () => null,
	cfgActivePowerPlusActions: () => null,
};

const ID = "active-power-plus";

function ActivePowerPlusCard(props: Props) {

	const { t } = useTranslation();

	const {
		settingsMeasurementsUser: {
			cfgActivePowerPlus,
		},
		isAdmin,
	} = props;

	const {
		form: reducerForm,
		handleChange: reducerHandleChange,
	} = useReducerForm(
		"buffor",
		bufforFormActions,
		() => null,
	);

	const { cfgUserEnable } = useContext(MeasurementsUserContext);

	const _handleSubmit = (values: ActivePowerPlusForm) => {
		reducerHandleChange("cfgActivePowerPlusRecOpt", values.cfgActivePowerPlusRecOpt);
		reducerHandleChange("cfgActivePowerPlusEventThresholdMax", values.cfgActivePowerPlusEventThresholdMax);
		reducerHandleChange("cfgActivePowerPlusChannels", values.cfgActivePowerPlusChannels);
		reducerHandleChange("cfgActivePowerPlusActions", values.cfgActivePowerPlusActions);
	};

	const _getInitialState = () => {
		const [ cfgActivePowerPlusChannels, cfgActivePowerPlusActions ] = mapActivePowerPlusConfig(cfgActivePowerPlus);
		return {
			cfgActivePowerPlusRecOpt: createFormField((reducerForm.cfgActivePowerPlusRecOpt.value !== reducerForm.cfgActivePowerPlusRecOpt.initialValue) ? reducerForm.cfgActivePowerPlusRecOpt.value : cfgActivePowerPlus?.cfgActivePowerPlusRecOpt?.value ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgActivePowerPlusRecOptOptions: createFormField(cfgActivePowerPlus?.cfgActivePowerPlusRecOpt?.options ?? []),
			cfgActivePowerPlusEventThresholdMax: createFormField((reducerForm.cfgActivePowerPlusEventThresholdMax.value !== reducerForm.cfgActivePowerPlusEventThresholdMax.initialValue) ? reducerForm.cfgActivePowerPlusEventThresholdMax.value : cfgActivePowerPlus?.cfgActivePowerPlusEventThresholdMax?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
			cfgActivePowerPlusEventThresholdMaxMin: createFormField(cfgActivePowerPlus?.cfgActivePowerPlusEventThresholdMax?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgActivePowerPlusEventThresholdMaxMax: createFormField(cfgActivePowerPlus?.cfgActivePowerPlusEventThresholdMax?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgActivePowerPlusChannels: createFormField(isChannelsDiff(reducerForm.cfgActivePowerPlusChannels) ? reducerForm.cfgActivePowerPlusChannels.value : cfgActivePowerPlusChannels, { disabled: !isAdmin || !cfgUserEnable }),
			cfgActivePowerPlusActions: createFormField((reducerForm.cfgActivePowerPlusActions.value !== reducerForm.cfgActivePowerPlusActions.initialValue) ? reducerForm.cfgActivePowerPlusActions.value : cfgActivePowerPlusActions, { disabled: !isAdmin || !cfgUserEnable }),
		};
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ cfgActivePowerPlus ]);

	useEffect(() => {
		toggleDisable("cfgActivePowerPlusRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgActivePowerPlusEventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgActivePowerPlusChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgActivePowerPlusActions", !cfgUserEnable || !isAdmin);
	}, [ cfgUserEnable ]);

	useEffect(() => {
		toggleDisable("cfgActivePowerPlusRecOpt", false);
		toggleDisable("cfgActivePowerPlusEventThresholdMax", false);
		toggleDisable("cfgActivePowerPlusChannels", false);
		toggleDisable("cfgActivePowerPlusActions", false);

		handleChange("cfgActivePowerPlusRecOpt", reducerForm.cfgActivePowerPlusRecOpt.value);
		handleChange("cfgActivePowerPlusEventThresholdMax", reducerForm.cfgActivePowerPlusEventThresholdMax.value);
		handleChange("cfgActivePowerPlusChannels", reducerForm.cfgActivePowerPlusChannels.value);
		handleChange("cfgActivePowerPlusActions", reducerForm.cfgActivePowerPlusActions.value);

		toggleDisable("cfgActivePowerPlusRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgActivePowerPlusEventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgActivePowerPlusChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgActivePowerPlusActions", !cfgUserEnable || !isAdmin);
	}, [
		reducerForm.cfgActivePowerPlusRecOpt.value,
		reducerForm.cfgActivePowerPlusEventThresholdMax.value,
		reducerForm.cfgActivePowerPlusChannels.value,
		reducerForm.cfgActivePowerPlusActions.value,
	]);

	return (
		<Card className="bg-gray-50">
			<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
				{ t("POWER.active power plus") }
			</h5>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<BasicRecordingOptionConfiguration
					id={ ID }
					options={ cfgActivePowerPlus?.cfgActivePowerPlusRecOpt?.options ?? [] }
					formItem={ form.cfgActivePowerPlusRecOpt }
					handleChange={ value => handleChange("cfgActivePowerPlusRecOpt", value) }
					reducerFormItem={ reducerForm.cfgActivePowerPlusRecOpt }
				/>
				<div className="flex flex-col gap-3">
					<h5 className="text-base sm:text-xl font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
						{ t("POWER.events") }
					</h5>
					<EventRecordingOptionConfiguration
						id={ ID }
						options={ cfgActivePowerPlus?.cfgActivePowerPlusRecOpt?.options ?? [] }
						formItem={ form.cfgActivePowerPlusRecOpt }
						handleChange={ value => handleChange("cfgActivePowerPlusRecOpt", value) }
						reducerFormItem={ reducerForm.cfgActivePowerPlusRecOpt }
					>
						{
							(isLogEventEnabled) =>
								<div className="flex flex-col gap-2.5">
									<h5 className="text-sm sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
										{ t("POWER.events") }
									</h5>
									<hr className="mx-2"/>
									<div className="flex gap-2.5 flex-1">
										<Input
											className="flex-1"
											formItem={ form.cfgActivePowerPlusEventThresholdMax }
											label={ t("POWER.max") }
											name="cfgActivePowerPlusEventThresholdMax"
											inputProps={ {
												type: "number",
												onChange: (e) => handleChange("cfgActivePowerPlusEventThresholdMax", e.target.value),
												onBlur: () => handleBlur("cfgActivePowerPlusEventThresholdMax"),
												disabled: !isLogEventEnabled,
												sizing: "sm",
												className: "[&>span]:!min-w-[40px] [&>span]:justify-center",
											} }
											addonRight={ cfgActivePowerPlus?.cfgActivePowerPlusEventThresholdMax?.unit ?? "-" }
											hasBeenChanged={ reducerForm.cfgActivePowerPlusEventThresholdMax.value !== reducerForm.cfgActivePowerPlusEventThresholdMax.initialValue }
										/>
										<div
											className={
												classNames(
													"flex gap-2",
													"xl:flex-[7_1_0%]",
													"flex-[4_1_0%]",
												)
											}
										>
											<RelayChannelChooser
												options={ [
													(isNotNull(cfgActivePowerPlus) && isNotNull(cfgActivePowerPlus.relayChannelLabel) && isNotNull(cfgActivePowerPlus.relayChannelLabel.ch1)) ? {
														value: "cfgRelayActivePowerPlus1RecOpt",
														label: cfgActivePowerPlus.relayChannelLabel.ch1,
													} : null,
													(isNotNull(cfgActivePowerPlus) && isNotNull(cfgActivePowerPlus.relayChannelLabel) && isNotNull(cfgActivePowerPlus.relayChannelLabel.ch2)) ? {
														value: "cfgRelayActivePowerPlus2RecOpt",
														label: cfgActivePowerPlus.relayChannelLabel.ch2,
													} : null,
													(isNotNull(cfgActivePowerPlus) && isNotNull(cfgActivePowerPlus.relayChannelLabel) && isNotNull(cfgActivePowerPlus.relayChannelLabel.ch3)) ? {
														value: "cfgRelayActivePowerPlus3RecOpt",
														label: cfgActivePowerPlus.relayChannelLabel.ch3,
													} : null,
													(isNotNull(cfgActivePowerPlus) && isNotNull(cfgActivePowerPlus.relayChannelLabel) && isNotNull(cfgActivePowerPlus.relayChannelLabel.ch4)) ? {
														value: "cfgRelayActivePowerPlus4RecOpt",
														label: cfgActivePowerPlus.relayChannelLabel.ch4,
													} : null,
												].filter(isNotNull) }
												disabled={ !isLogEventEnabled }
												formItem={ form.cfgActivePowerPlusChannels }
												reducerFormItem={ reducerForm.cfgActivePowerPlusChannels }
												handleChange={ value => handleChange("cfgActivePowerPlusChannels", value) }
											/>
											<RelayActionSelect
												formItem={ form.cfgActivePowerPlusActions }
												reducerFormItem={ reducerForm.cfgActivePowerPlusActions }
												options={ cfgActivePowerPlus?.cfgRelayActivePowerPlus1Map?.options ?? [] }
												disabled={ !isLogEventEnabled || isEmptyArray(form.cfgActivePowerPlusChannels.value) }
												handleChange={ value => handleChange("cfgActivePowerPlusActions", value) }
											/>
										</div>
									</div>
									<hr className="mx-2"/>
								</div>
						}
					</EventRecordingOptionConfiguration>
				</div>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(ActivePowerPlusCard);
