import { SetLocalizationOrUserInfoPayload, Summary } from "src/app/types/api/dashboard.types";
import { Form, FormValidator } from "src/app/types/ui/form.types";
import { Button, Modal } from "flowbite-react";
import { createFormField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import Input from "src/app/components/Form/Input.component";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		isOpen: boolean
		handleClose: () => void
		handleSave: (payload: SetLocalizationOrUserInfoPayload) => void
		summary: Summary["summary"]
	};

type EditLocalizationOrUserInfoForm = {
	localization: string
	localizationMaxLen: number
	userInfo: string
	userInfoMaxLen: number
}

const validator: FormValidator<EditLocalizationOrUserInfoForm> = {
	localization: (localization, optional, form) => {
		if (localization.length > form.localizationMaxLen.value) {
			return `Location can be up to ${ form.localizationMaxLen.value } characters long`;
		}

		return null;
	},
	localizationMaxLen: () => null,
	userInfo: (userInfo, optional, form) => {
		if (userInfo.length > form.userInfoMaxLen.value) {
			return `User Info can be up to ${ form.userInfoMaxLen.value } characters long`;
		}

		return null;
	},
	userInfoMaxLen: () => null,
};

function EditLocalizationOrUserInfoModal(props: Props) {

	const {
		isOpen,
		handleSave,
		handleClose,
		summary,
		isUpdating,
	} = props;

	const { t } = useTranslation();

	const _handleSubmit = (values: EditLocalizationOrUserInfoForm) => {
		handleSave({
			cfg_localization: {
				value: values.localization,
			},
			cfg_meter_user_info: {
				value: values.userInfo,
			},
		});
		handleClose();
	};

	const _getInitialState = (): Form<EditLocalizationOrUserInfoForm> => ({
		localization: createFormField(summary?.cfgLocalization?.value ?? ""),
		localizationMaxLen: createFormField(summary?.cfgLocalization?.maxLen ?? 40),
		userInfo: createFormField(summary?.cfgMeterUserInfo?.value ?? ""),
		userInfoMaxLen: createFormField(summary?.cfgMeterUserInfo?.maxLen ?? 40),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
	} = useForm(
		_getInitialState(),
		validator,
		_handleSubmit,
	);

	useEffect(() => {
		if (isOpen) {
			setForm(_getInitialState());
		}
	}, [ isOpen, isUpdating ]);

	return (
		<Modal
			show={ isOpen || isUpdating }
			onClose={ handleClose }
		>
			<Modal.Header>{ t("SUMMARY.edit localization and user") }</Modal.Header>
			<form noValidate onSubmit={ handleSubmit }>
				<Modal.Body>
					<div className="flex flex-col gap-4">
						<Input
							formItem={ form.localization }
							label={ t("SUMMARY.localization") }
							name="localization"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("localization", e.target.value),
								onBlur: () => handleBlur("localization"),
							} }
						/>
						<Input
							formItem={ form.userInfo }
							label={ t("SUMMARY.user info") }
							name="userInfo"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("userInfo", e.target.value),
								onBlur: () => handleBlur("userInfo"),
							} }
						/>
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-between gap-2">
					<Button color="gray" onClick={ handleClose }>{ t("UTILS.cancel") }</Button>
					<Button color="primary" type="submit" isProcessing={ isUpdating }>
						{ t("UTILS.save") }
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

const mapStateToProps = (state: RootState) => ({
	isUpdating: didLoadingRecordExist(state, { loadableType: LoadableType.SET_LOCALIZATION_OR_USER_INFO }),
});

export default connect(mapStateToProps)(EditLocalizationOrUserInfoModal);
