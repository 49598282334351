import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import InterharmonicContainer from "src/app/containers/Realtime/Interharmonic.container";
import useWebSocket, { WebSocketUri } from "src/app/utils/hooks/useWebSocket";
import { DataInterharmonicsLive } from "src/app/types/api/ws.types";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function InterharmonicView(props: Props) {

	const [ lastRecord ] = useWebSocket<DataInterharmonicsLive>(WebSocketUri.DATA_INTERHARMONICS_256_LIVE, true);

	return (
		<InterharmonicContainer
			lastRecord={ lastRecord }
		/>
	);
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InterharmonicView);
