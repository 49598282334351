import { SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import FrequencyCard from "src/app/components/MeasurementsUser/FrequencyCard.component";
import VoltageCrestFactorCard from "src/app/components/MeasurementsUser/VoltageCrestFactorCard.component";
import VoltageUnbalanceCard from "src/app/components/MeasurementsUser/VoltageUnbalanceCard.component";
import FlickerPstCard from "src/app/components/MeasurementsUser/FlickerPstCard.component";
import FlickerPltCard from "src/app/components/MeasurementsUser/FlickerPltCard.component";

type Props = {
	settingsMeasurementsUser: SettingsMeasurementsUser
};

function VoltageAdditionalTab(props: Props) {

	const {
		settingsMeasurementsUser,
	} = props;

	return (
		<div className="p-4 flex flex-col gap-3">
			<FrequencyCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<VoltageCrestFactorCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<VoltageUnbalanceCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<FlickerPstCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<FlickerPltCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
		</div>
	);
}

export default (VoltageAdditionalTab);
