import { Button, Card } from "flowbite-react";
import FileInput from "src/app/components/Form/FileInput.component";
import React from "react";
import { Nullable } from "src/app/types/util.types";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateNullableField } from "src/app/utils/forms";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import useForm from "src/app/utils/hooks/useForm";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import SnackbarComponent from "src/app/components/Message/Snackbar.component";
import { SnackbarMessageType } from "src/app/types/ui/message.types";
import { HiDocumentCheck } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";
import classNames from "classnames";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		exportSettings: () => void
		importSettings: (file: File) => void
	};

type ImportForm = {
	file: Nullable<File>
}

const validator: FormValidator<ImportForm> = {
	file: (file, optional) => validateNullableField(i18n.t("IMPORTEXPORT.configuration file"), file, optional, "he"),
};

function ImportExportContainer(props: Props) {

	const { t } = useTranslation();

	const {
		exportSettings,
		importSettings,
		isExporting,
		isImporting,
		isAdmin,
	} = props;

	const _handleSubmit = (values: ImportForm) => {
		if (isNull(values.file)) return;

		importSettings(values.file);
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
	} = useForm({
		file: createFormField(null),
	}, validator, _handleSubmit);

	return (
		<div
			className={
				classNames(
					"grid gap-4",
					"xl:grid-cols-5",
					"grid-cols-1"
				)
			}
		>
			<div className="col-span-2">
				<Card>
					<div className="flex flex-col justify-between gap-2">
						<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
							{ t("IMPORTEXPORT.export configuration") }
						</h5>
					</div>
					<div className="flex justify-end">
						<Button
							color="primary"
							onClick={ exportSettings }
							isProcessing={ isExporting }
						>
							{ t("IMPORTEXPORT.export") }
						</Button>
					</div>
				</Card>
			</div>
			{
				isAdmin &&
                <Card className="col-span-3">
                    <form noValidate onSubmit={ handleSubmit } className="flex flex-col gap-2">
                        <div className="flex flex-col justify-between gap-2">
                            <h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
								{ t("IMPORTEXPORT.import configuration") }
                            </h5>
                        </div>
                        <div className="flex flex-col gap-1">
							{
								isNull(form.file.value) ?
									<>
										<FileInput
											buttonText={ t("IMPORTEXPORT.upload button") }
											name="configuration"
											className="[&>div:first-child]:py-14 flex-grow"
											onChange={ file => handleChange("file", file) }
											options={ {
												accept: {
													"application/octet-stream": [ ".pqc" ],
												},
											} }
											accept=".pqc"
										/>
										{
											isNotNull(form.file.error) &&
                                            <SnackbarComponent
                                                type={ SnackbarMessageType.ERROR }
                                                message={ form.file.error }
                                            />
										}
									</>
									:
									<div className="relative py-14 flex items-center justify-between px-4 border-2 rounded-lg border-green-400 transition duration-100 ease-in-out bg-transparent">
										<div className="flex items-center gap-3">
											<HiDocumentCheck className="w-10 h-10 text-green-400"/>
											<div className="flex flex-col gap-1">
												<div className="text-sm dark:text-white">{ form.file.value.name }</div>
												<div className="text-xs text-gray-400">{ Math.round((form.file.value.size / 1024 / 1000) * 10) / 10 }MB</div>
											</div>
										</div>
										<div className="flex gap-2">
											<Button
												color="failure"
												onClick={ () => handleChange("file", null) }
												disabled={ isImporting }
											>
												{ t("IMPORTEXPORT.cancel") }
											</Button>
											<Button
												color="primary"
												type="submit"
												disabled={ isImporting }
												isProcessing={ isImporting }
											>
												{ t("IMPORTEXPORT.import") }
											</Button>
										</div>
									</div>
							}
                        </div>
                    </form>
                </Card>
			}
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
	isExporting: didLoadingRecordExist(state, { loadableType: LoadableType.EXPORT_SETTINGS }),
	isImporting: didLoadingRecordExist(state, { loadableType: LoadableType.IMPORT_SETTINGS }),
});

export default connect(mapStateToProps)(ImportExportContainer);
