import { useContext, useEffect, useState } from "react";
import { isHostSource, wsUrl } from "src/app/utils/constants/constants";
import { LoggedUserContext } from "src/app/hoc/providers/LoggedUser.provider";
import { DataBasicLive, DataHarmonicsFundLive, DataHarmonicsLive, DataHarmonicsPowerLive, DataHarmonicsRmsLive, DataInterharmonicsLive, DataPhasorLive, DataWaveformsLive } from "src/app/types/api/ws.types";

export enum WebSocketUri {
	DATA_BASIC_LIVE = "/data/basic/live",
	DATA_SHEET_LIVE = "/data/sheet/live",

	DATA_PHASOR_LIVE = "/data/phasor/live",

	DATA_HARMONICS_50_LIVE = "/data/harmonics/50/live",
	DATA_HARMONICS_256_LIVE = "/data/harmonics/256/live",

	DATA_HARMONICS_FUND_50_LIVE = "/data/harmonics/fund/50/live",
	DATA_HARMONICS_FUND_256_LIVE = "/data/harmonics/fund/256/live",

	DATA_HARMONICS_RMS_50_LIVE = "/data/harmonics/rms/50/live",
	DATA_HARMONICS_RMS_256_LIVE = "/data/harmonics/rms/256/live",

	DATA_HARMONICS_POWER_50_LIVE = "/data/harmonics/power/50/live",
	DATA_HARMONICS_POWER_256_LIVE = "/data/harmonics/power/256/live",

	DATA_INTERHARMONICS_50_LIVE = "/data/interharmonics/50/live",
	DATA_INTERHARMONICS_256_LIVE = "/data/interharmonics/256/live",

	DATA_WAVEFORMS = "/data/waveforms"
}

export type BasicLiveConfiguration = {
	[K in keyof Required<Omit<DataBasicLive["basic"], "timestamp">>]: boolean
}

export type PhasorLiveConfiguration = {
	[K in keyof Required<Omit<DataPhasorLive["phasor"], "timestamp">>]: boolean
}

export type HarmonicLiveConfiguration = {
	[K in keyof Required<Omit<DataHarmonicsLive["harmonics"], "timestamp">>]: boolean
}

export type HarmonicsFundLiveConfiguration = {
	[K in keyof Required<Omit<DataHarmonicsFundLive["harmonics"], "timestamp">>]: boolean
}

export type HarmonicsRmsLiveConfiguration = {
	[K in keyof Required<Omit<DataHarmonicsRmsLive["harmonics"], "timestamp">>]: boolean
}

export type HarmonicsPowerLiveConfiguration = {
	[K in keyof Required<Omit<DataHarmonicsPowerLive["harmonics"], "timestamp">>]: boolean
}

export type InterharmonicsLiveConfiguration = {
	[K in keyof Required<Omit<DataInterharmonicsLive["interharmonics"], "timestamp">>]: boolean
}

export type WaveformsLiveConfiguration = {
	[K in keyof Required<Omit<DataWaveformsLive["waveforms"] & DataBasicLive["basic"], "resolution" | "timestamp">>]: boolean
};

function useWebSocket<T>(wsUri: WebSocketUri, overwrite = false): T[] {
	const loggedUser = useContext(LoggedUserContext);

	const [ data, setData ] = useState<T[]>([]);

	useEffect(() => {
		const ws = isHostSource
			?
			new WebSocket(`wss://${ window.location.host }/ws${ wsUri }?token=${ loggedUser.jwt }`)
			:
			new WebSocket(`${ wsUrl }${ wsUri }?token=${ loggedUser.jwt }`);

		ws.addEventListener("message", (event: MessageEvent<string>) => {
			const parsedEvent: T = JSON.parse(event.data);
			setData(prevState =>
				overwrite ?
					[ parsedEvent ]
					:
					[
						...prevState,
						parsedEvent,
					]);
		});

		return () => {
			ws.close();
		};
	}, []);

	return data;
}

export default (useWebSocket);
