import RecordingGeneralCard from "src/app/components/Recording/RecordingGeneralCard.component";

type Props = {};

function AnalyzerContainer(props: Props) {
	return (
		<RecordingGeneralCard/>
	);
}

export default (AnalyzerContainer);
