import { Card } from "flowbite-react";
import LabelValue from "src/app/components/Utils/LabelValue.component";
import { appVersion } from "src/app/utils/constants/constants";
import { useTranslation } from "react-i18next";

type Props = {
	backendVersion: string
};

function AppVersionContainer(props: Props) {

	const {
		backendVersion,
	} = props;

	const { t } = useTranslation();

	return (
		<Card className="[&>div]:p-3 sm:[&>div]:p-6 [&>div]:gap-1 sm:[&>div]:gap-4">
			<div className="flex justify-between items-center gap-2">
				<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
					{ t("UTILS.system version") }
				</h5>
			</div>
			<div className="flex flex-col gap-1.5">
				<LabelValue
					label={ t("UTILS.frontend version") }
					value={ appVersion }
				/>
				<LabelValue
					label={ t("UTILS.backend version") }
					value={ backendVersion }
				/>
			</div>
		</Card>
	);
}

export default (AppVersionContainer);