// @ts-ignore
import { Chart, ChartCanvas } from "react-stockcharts";
// @ts-ignore
import { LineSeries } from "react-stockcharts/lib/series";
// @ts-ignore
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { WaveformConfigState } from "src/app/containers/Realtime/Waveform.container";
import { scaleLinear } from "d3-scale";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { round, roundRange } from "src/app/utils/helpers";
import { appColors } from "src/app/utils/constants/theme";
import { GridLoader } from "react-spinners";
import { formatValueToDeciSONlValue, formatValueToFullDeciSON } from "src/app/utils/dataFormatting";
import ChartHoverTooltip from "src/app/components/Chart/Component/ChartHoverTooltip.component";
import { StockChartIndex } from "src/app/types/ui/chart.types";
import ChartCurrentCoordinate from "src/app/components/Chart/Component/ChartCurrentCoordinate.component";
import { Nullable } from "src/app/types/util.types";
import { MeasurementCategory } from "src/app/types/misc.types";

type Props = {
	width: number
	height: number
	data: WaveformChartData[]
	config: WaveformConfigState
	resolutionSeconds: number
};

export type WaveformChartData = {
	U1: Nullable<number>
	U2: Nullable<number>
	U3: Nullable<number>
	U12: Nullable<number>
	U23: Nullable<number>
	U31: Nullable<number>
	I1: Nullable<number>
	I2: Nullable<number>
	I3: Nullable<number>
	I4: Nullable<number>
	I5: Nullable<number>
	index: number
}

function findClosestIndices(indices: number[], resolutionSeconds: number): number[] {
	// Sortujemy indeksy, aby później łatwiej było znaleźć najbliższe wartości.
	const indexes = indices.sort((a, b) => a - b).map(index => ({ index, value: index * resolutionSeconds * 1000 }));
	const maxIndexVal = Math.max(...indexes.map(index => index.value));

	// Tworzymy zestaw wynikowy na najbliższe indeksy.
	const closestIndices: number[] = [];

	// Pętla od 0 do maksymalnej wartości z tablicy w krokach po 5000.
	for (let multiple = 0 ; multiple <= maxIndexVal ; multiple += 5) {
		let closest = null;
		let minDistance = Infinity;

		// Szukamy najbliższego indeksu do aktualnej wielokrotności 5000.
		for (let index of indexes) {
			const distance = Math.abs(index.value - multiple);

			if (distance < minDistance) {
				minDistance = distance;
				closest = index.index;
			}
		}

		// Dodajemy znaleziony najbliższy indeks do wyniku.
		if (closest !== null && !closestIndices.includes(closest)) {
			closestIndices.push(closest);
		}
	}

	return closestIndices;
}

function WaveformChart(props: Props) {

	const {
		width,
		height,
		data,
		config,
		resolutionSeconds,
	} = props;

	const _calculateYRange = (data: WaveformChartData[]): [ min: number, max: number ] => {
		const firstValue = data[ 0 ];
		if (isNull(firstValue)) return [ 0, 300 ];
		const firstMinValue = Math.min(
			...[
				config.U1 ? (data[ 0 ].U1 ?? 0) : null,
				config.U2 ? (data[ 0 ].U2 ?? 0) : null,
				config.U3 ? (data[ 0 ].U3 ?? 0) : null,
				config.U12 ? (data[ 0 ].U12 ?? 0) : null,
				config.U23 ? (data[ 0 ].U23 ?? 0) : null,
				config.U31 ? (data[ 0 ].U31 ?? 0) : null,
			].filter(isNotNull),
		);
		const firstMaxValue = Math.max(
			...[
				config.U1 ? (data[ 0 ].U1 ?? 0) : null,
				config.U2 ? (data[ 0 ].U2 ?? 0) : null,
				config.U3 ? (data[ 0 ].U3 ?? 0) : null,
				config.U12 ? (data[ 0 ].U12 ?? 0) : null,
				config.U23 ? (data[ 0 ].U23 ?? 0) : null,
				config.U31 ? (data[ 0 ].U31 ?? 0) : null,
			].filter(isNotNull),
		);
		const yAxisRange: [ number, number ] = data.reduce((prev, next) => [
			Math.min(
				prev[ 0 ],
				config.U1 ? (next.U1 ?? 0) : prev[ 0 ],
				config.U2 ? (next.U2 ?? 0) : prev[ 0 ],
				config.U3 ? (next.U3 ?? 0) : prev[ 0 ],
				config.U12 ? (next.U12 ?? 0) : prev[ 0 ],
				config.U23 ? (next.U23 ?? 0) : prev[ 0 ],
				config.U31 ? (next.U31 ?? 0) : prev[ 0 ],
			),
			Math.max(
				prev[ 1 ],
				config.U1 ? (next.U1 ?? 0) : prev[ 1 ],
				config.U2 ? (next.U2 ?? 0) : prev[ 1 ],
				config.U3 ? (next.U3 ?? 0) : prev[ 1 ],
				config.U12 ? (next.U12 ?? 0) : prev[ 1 ],
				config.U23 ? (next.U23 ?? 0) : prev[ 1 ],
				config.U31 ? (next.U31 ?? 0) : prev[ 1 ],
			),
		], [ firstMinValue, firstMaxValue ]);

		const biggerValue = Math.max(
			Math.abs(roundRange(yAxisRange[ 0 ], Math.floor)),
			Math.abs(roundRange(yAxisRange[ 1 ], Math.ceil)),
		);

		return [
			-1 * biggerValue,
			biggerValue,
		];
	};

	const _calculateYRightRange = (data: WaveformChartData[]): [ min: number, max: number ] => {
		const firstValue = data[ 0 ];
		if (isNull(firstValue)) return [ 0, 500 ];
		const firstMinValue = Math.min(
			...[
				config.I1 ? (data[ 0 ].I1 ?? 0) : null,
				config.I2 ? (data[ 0 ].I2 ?? 0) : null,
				config.I3 ? (data[ 0 ].I3 ?? 0) : null,
				config.I4 ? (data[ 0 ].I4 ?? 0) : null,
				config.I5 ? (data[ 0 ].I5 ?? 0) : null,
			].filter(isNotNull),
		);
		const firstMaxValue = Math.max(
			...[
				config.I1 ? (data[ 0 ].I1 ?? 0) : null,
				config.I2 ? (data[ 0 ].I2 ?? 0) : null,
				config.I3 ? (data[ 0 ].I3 ?? 0) : null,
				config.I4 ? (data[ 0 ].I4 ?? 0) : null,
				config.I5 ? (data[ 0 ].I5 ?? 0) : null,
			].filter(isNotNull),
		);
		if (!isFinite(firstMaxValue) || !isFinite(firstMinValue)) return [ 0, 500 ];
		const yAxisRange: [ number, number ] = data.reduce((prev, next) => [
			Math.min(
				prev[ 0 ],
				config.I1 ? (next.I1 ?? 0) : prev[ 0 ],
				config.I2 ? (next.I2 ?? 0) : prev[ 0 ],
				config.I3 ? (next.I3 ?? 0) : prev[ 0 ],
				config.I4 ? (next.I4 ?? 0) : prev[ 0 ],
				config.I5 ? (next.I5 ?? 0) : prev[ 0 ],
			),
			Math.max(
				prev[ 1 ],
				config.I1 ? (next.I1 ?? 0) : prev[ 1 ],
				config.I2 ? (next.I2 ?? 0) : prev[ 1 ],
				config.I3 ? (next.I3 ?? 0) : prev[ 1 ],
				config.I4 ? (next.I4 ?? 0) : prev[ 1 ],
				config.I5 ? (next.I5 ?? 0) : prev[ 1 ],
			),
		], [ firstMinValue, firstMaxValue ]);

		const biggerValue = Math.max(
			Math.abs(roundRange(yAxisRange[ 0 ], Math.floor)),
			Math.abs(roundRange(yAxisRange[ 1 ], Math.ceil)),
		);

		return [
			-1 * biggerValue,
			biggerValue,
		];
	};

	const marginLeft = 50;
	const marginRight = 55;
	const marginTop = 25;
	const marginBottom = 23;

	const xGrid = {
		innerTickSize: -1 * (width - 105),
		tickStrokeDasharray: "Dash",
		tickStrokeOpacity: 0.2,
		tickStrokeWidth: 1,
		tickStroke: "#030712",
		stroke: "#03071200",

		fontFamily: "Segoe UI Symbol, Segoe UI Emoji, ui-sans-serif, sans-serif",
		fontSize: 12,
		fontWeight: 500,
	};

	const rightXGrid = {
		innerTickSize: -1 * (width - 105),
		tickStrokeDasharray: "Dash",
		tickStrokeOpacity: 0,
		tickStrokeWidth: 1,
		tickStroke: "#030712",
		stroke: "#03071200",

		fontFamily: "Segoe UI Symbol, Segoe UI Emoji, ui-sans-serif, sans-serif",
		fontSize: 12,
		fontWeight: 500,
	};

	const yGrid = {
		innerTickSize: -1 * (height - marginTop - marginBottom),
		tickStrokeDasharray: "Dash",
		tickStrokeOpacity: 0.2,
		tickStrokeWidth: 1,

		fontFamily: "Segoe UI Symbol, Segoe UI Emoji, ui-sans-serif, sans-serif",
		fontSize: 12,
		fontWeight: 500,

		tickStroke: "#030712",
		stroke: "#03071200",
	};

	const legendDescriptionY = "-12";

	if (data.length < 4) {
		return (
			<div className="w-full h-full flex items-center justify-center">
				<GridLoader size={ 15 } color="#0093DD"/>
			</div>
		);
	}

	const yRange: [ number, number ] = _calculateYRange(data);
	const yRightRange: [ number, number ] = _calculateYRightRange(data);

	const isDisplayingTensions = config.U1 || config.U2 || config.U3 || config.U12 || config.U23 || config.U31;
	const isDisplayingIntensities = config.I1 || config.I2 || config.I3 || config.I4 || config.I5;

	const firstItem = data[ data.length - 1 ];
	const lastItem = data[ 0 ];

	const tickValues = findClosestIndices(data.map(item => item.index), resolutionSeconds);

	return (
		<ChartCanvas
			ratio={ 1 }
			width={ width }
			height={ height }
			margin={ { left: marginLeft, right: marginRight, top: marginTop, bottom: marginBottom } }
			type="svg"
			seriesName="measurements"
			data={ data }
			xScale={ scaleLinear() }
			xAccessor={ (d: any) => d.index }
			mouseMoveEvent={ true }
			panEvent={ false }
			zoomEvent={ false }
			xExtents={ [ 0, data.length ] }
		>
			<Chart
				id="voltages-chart-U"
				yExtents={ yRange }
			>
				<text
					style={ {
						fill: "#030712",
					} }
					fontFamily="Segoe UI, sans-serif"
					fontSize={ 12 }
					fontWeight={ 600 }
					x={ width - 2 * marginLeft + 10 }
					y={ height - 29 }
				>
					[ms]
				</text>
				<XAxis
					axisAt="bottom"
					orient="bottom"
					tickValues={ tickValues }
					tickFormat={ (index: number) => round(index * resolutionSeconds * 1000, 0).toString() }
					zoomEnabled={ false }
					{ ...yGrid }
				/>

				<YAxis
					axisAt="left"
					orient="left"
					ticks={ 6 }

					// tickFormat={ (v: number) => getSignificantDigitRound(v).toString() }
					tickFormat={ (v: number) => formatValueToDeciSONlValue(v, MeasurementCategory.VOLTAGES_WAVE)?.toString() ?? "" }
					zoomEnabled={ false }
					{ ...xGrid }
				/>
				{
					isDisplayingIntensities &&
                    <YAxis
                        axisAt="right"
                        orient="right"
                        ticks={ 6 }

                        tickFormat={ (x: number) => {
							const [ y1, y2 ] = yRightRange;
							const [ y3, y4 ] = yRange;
							// return getSignificantDigitRound(y1 + Math.abs(y1 - y2) * (Math.abs(x - y3) / Math.abs(y3 - y4))).toString();
							return formatValueToDeciSONlValue(y1 + Math.abs(y1 - y2) * (Math.abs(x - y3) / Math.abs(y3 - y4)), MeasurementCategory.CURRENTS_WAVE)?.toString() ?? "";
						} }
                        zoomEnabled={ false }
						{ ...rightXGrid }
                    />
				}
				{
					config.U1 &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: WaveformChartData) => d.U1 }
                            fill={ appColors.U1 }
                        />
                        <LineSeries
                            yAccessor={ (d: WaveformChartData) => d.U1 }
                            strokeWidth={ 2 }
                            stroke={ appColors.U1 }
                        />
                    </>
				}
				{
					config.U2 &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: WaveformChartData) => d.U2 }
                            fill={ appColors.U2 }
                        />
                        <LineSeries
                            yAccessor={ (d: WaveformChartData) => d.U2 }
                            strokeWidth={ 2 }
                            stroke={ appColors.U2 }
                        />
                    </>
				}
				{
					config.U3 &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: WaveformChartData) => d.U3 }
                            fill={ appColors.U3 }
                        />
                        <LineSeries
                            yAccessor={ (d: WaveformChartData) => d.U3 }
                            strokeWidth={ 2 }
                            stroke={ appColors.U3 }
                        />
                    </>
				}
				{
					config.U12 &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: WaveformChartData) => d.U12 }
                            fill={ appColors.U12 }
                        />
                        <LineSeries
                            yAccessor={ (d: WaveformChartData) => d.U12 }
                            strokeWidth={ 2 }
                            stroke={ appColors.U12 }
                        />
                    </>
				}
				{
					config.U23 &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: WaveformChartData) => d.U23 }
                            fill={ appColors.U23 }
                        />
                        <LineSeries
                            yAccessor={ (d: WaveformChartData) => d.U23 }
                            strokeWidth={ 2 }
                            stroke={ appColors.U23 }
                        />
                    </>
				}
				{
					config.U31 &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: WaveformChartData) => d.U31 }
                            fill={ appColors.U31 }
                        />
                        <LineSeries
                            yAccessor={ (d: WaveformChartData) => d.U31 }
                            strokeWidth={ 2 }
                            stroke={ appColors.U31 }
                        />
                    </>
				}
				{
					isDisplayingTensions &&
                    <>
						{/*<rect x="-50" y="-2" width={ 50 } height={ 16 } fill="white"/>*/ }
                        {/*<text x="-30" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>U[{ getPrefixToUnit(0.0, "V", MeasurementCategory.VOLTAGES_WAVE) }]</text>*/}
                        <text x="-30" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>U[V]</text>
                    </>
				}
			</Chart>
			<Chart
				id="voltages-chart-I"
				yExtents={ yRightRange }
			>
				{
					!isDisplayingTensions &&
                    <YAxis
                        axisAt="right"
                        orient="right"
                        ticks={ 6 }

                        // tickFormat={ (x: number) => x.toString() }
                        tickFormat={ (x: number) => formatValueToDeciSONlValue(x, MeasurementCategory.VOLTAGES_WAVE)?.toString() ?? "" }
                        zoomEnabled={ false }
						{ ...xGrid }
                    />
				}
				{
					config.I1 &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: WaveformChartData) => d.I1 }
                            fill={ appColors.I1 }
                        />
                        <LineSeries
                            yAccessor={ (d: WaveformChartData) => d.I1 }
                            strokeWidth={ 2 }
                            stroke={ appColors.I1 }
                        />
                    </>
				}
				{
					config.I2 &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: WaveformChartData) => d.I2 }
                            fill={ appColors.I2 }
                        />
                        <LineSeries
                            yAccessor={ (d: WaveformChartData) => d.I2 }
                            strokeWidth={ 2 }
                            stroke={ appColors.I2 }
                        />
                    </>
				}
				{
					config.I3 &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: WaveformChartData) => d.I3 }
                            fill={ appColors.I3 }
                        />
                        <LineSeries
                            yAccessor={ (d: WaveformChartData) => d.I3 }
                            strokeWidth={ 2 }
                            stroke={ appColors.I3 }
                        />
                    </>
				}
				{
					config.I4 &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: WaveformChartData) => d.I4 }
                            fill={ appColors.In }
                        />
                        <LineSeries
                            yAccessor={ (d: WaveformChartData) => d.I4 }
                            strokeWidth={ 2 }
                            stroke={ appColors.In }
                        />
                    </>
				}
				{
					config.I5 &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: WaveformChartData) => d.I5 }
                            fill={ appColors.Ie }
                        />
                        <LineSeries
                            yAccessor={ (d: WaveformChartData) => d.I5 }
                            strokeWidth={ 2 }
                            stroke={ appColors.Ie }
                        />
                    </>
				}
				{
					isDisplayingIntensities &&
                    // <text x={ width - 2 * marginLeft + 5 } y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>I[{ getPrefixToUnit(0.0, "A", MeasurementCategory.CURRENTS_WAVE) }]</text>
                    <text x={ width - 2 * marginLeft + 5 } y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>I[A]</text>
				}
				{
					(isDisplayingTensions || isDisplayingIntensities) &&
                    <ChartHoverTooltip
                        firstItem={ firstItem }
                        lastItem={ lastItem }
                        getTooltipContent={ (probeArgument: { currentItem: WaveformChartData & StockChartIndex }) => {
							const { currentItem } = probeArgument;
							const xRoundBy = tickValues.includes(currentItem.index) ? 0 : 2;
							return {
								x: `${ round(currentItem.index * resolutionSeconds * 1000, xRoundBy) } ms`,
								y: [
									(config.U1 && isNotNull(currentItem.U1)) ? {
										label: "U1",
										value: formatValueToFullDeciSON(currentItem.U1, "V", MeasurementCategory.VOLTAGES) ?? "",
									} : null,
									(config.U2 && isNotNull(currentItem.U2)) ? {
										label: "U2",
										value: formatValueToFullDeciSON(currentItem.U2, "V", MeasurementCategory.VOLTAGES) ?? "",
									} : null,
									(config.U3 && isNotNull(currentItem.U3)) ? {
										label: "U3",
										value: formatValueToFullDeciSON(currentItem.U3, "V", MeasurementCategory.VOLTAGES) ?? "",
									} : null,
									(config.U12 && isNotNull(currentItem.U12)) ? {
										label: "U12",
										value: formatValueToFullDeciSON(currentItem.U12, "V", MeasurementCategory.VOLTAGES) ?? "",
									} : null,
									(config.U23 && isNotNull(currentItem.U23)) ? {
										label: "U23",
										value: formatValueToFullDeciSON(currentItem.U23, "V", MeasurementCategory.VOLTAGES) ?? "",
									} : null,
									(config.U31 && isNotNull(currentItem.U31)) ? {
										label: "U31",
										value: formatValueToFullDeciSON(currentItem.U31, "V", MeasurementCategory.VOLTAGES) ?? "",
									} : null,
									(config.I1 && isNotNull(currentItem.I1)) ? {
										label: "I1",
										value: formatValueToFullDeciSON(currentItem.I1, "A", MeasurementCategory.CURRENTS) ?? "",
									} : null,
									(config.I2 && isNotNull(currentItem.I2)) ? {
										label: "I2",
										value: formatValueToFullDeciSON(currentItem.I2, "A", MeasurementCategory.CURRENTS) ?? "",
									} : null,
									(config.I3 && isNotNull(currentItem.I3)) ? {
										label: "I3",
										value: formatValueToFullDeciSON(currentItem.I3, "A", MeasurementCategory.CURRENTS) ?? "",
									} : null,
									(config.I4 && isNotNull(currentItem.I4)) ? {
										label: "I4",
										value: formatValueToFullDeciSON(currentItem.I4, "A", MeasurementCategory.CURRENTS) ?? "",
									} : null,
									(config.I5 && isNotNull(currentItem.I5)) ? {
										label: "I5",
										value: formatValueToFullDeciSON(currentItem.I5, "A", MeasurementCategory.CURRENTS) ?? "",
									} : null,
								].filter(isNotNull),
							};
						} }
                    />
				}
			</Chart>
		</ChartCanvas>
	);
}

export default (WaveformChart);
