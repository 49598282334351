import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import TimeContainer from "src/app/containers/Settings/Time.container";
import { fetchSettingsTimeAsync } from "src/app/store/features/settings/settings.actions";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";
import CallbackPromptProvider from "src/app/hoc/providers/CallbackPrompt.provider";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function TimeView(props: Props) {

	const {
		fetchTimeSettings,
		settings,
	} = props;

	return (
		<CallbackPromptProvider>
			<CachedThenFreshStrategy
				request={ fetchTimeSettings }
				state={ settings }
			>
				{
					settings =>
						<TimeContainer
							settingsTime={ settings }
						/>
				}
			</CachedThenFreshStrategy>
		</CallbackPromptProvider>
	);
}

const mapStateToProps = (state: RootState) => ({
	settings: state.settings.settingsTime,
});

const mapDispatchToProps = {
	fetchTimeSettings: fetchSettingsTimeAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeView);
