import { FormItem } from "src/app/types/ui/form.types";
import { EnumOptionsSetting, SelectOption } from "src/app/types/util.types";
import { MapRelay } from "src/app/types/api/settings.types";
import { mapRelayDictionary } from "src/app/utils/constants/dictionaries";
import { isNull } from "src/app/utils/typeguards";
import MultiSelect from "../../Form/MultiSelect.component";
import { createFormField } from "src/app/utils/forms";
import { useTranslation } from "react-i18next";

type Props = {
	formItem: FormItem<number>
	reducerFormItem: FormItem<number>
	options: EnumOptionsSetting<MapRelay>["options"]
	handleChange: (value: number) => void
	disabled: boolean
};

const mapRelayOptionsToSelectOptions = (options: EnumOptionsSetting<MapRelay>["options"]): SelectOption<number>[] => {
	const noneOption: SelectOption<number> = { value: 0, label: "None" };
	if (options.length === 1) {
		const option = options[ 0 ];
		if (isNull(option)) return [ noneOption ];
		return [ noneOption, { value: option.mask, label: mapRelayDictionary()[ option.text ] } ];
	} else if (options.length === 2) {
		const firstOption = options[ 0 ];
		const secondOption = options[ 1 ];
		if (isNull(firstOption) || isNull(secondOption)) return [ noneOption ];

		return [
			{
				value: firstOption.mask,
				label: mapRelayDictionary()[ firstOption.text ],
			}, {
				value: secondOption.mask,
				label: mapRelayDictionary()[ secondOption.text ],
			},
		];
	}

	return [ noneOption ];
};

function RelayActionSelect(props: Props) {

	const { t } = useTranslation();

	const {
		options,
		formItem,
		reducerFormItem,
		handleChange,
		disabled,
	} = props;

	const selectOptions = mapRelayOptionsToSelectOptions(options);

	const _getFormItem = () => {
		const arr: number[] = [];
		for (let i = 0 ; i < selectOptions.length ; i++) {
			const mask = selectOptions[ i ].value;
			if ((formItem.value & mask) > 0) {
				arr.push(mask);
			}
		}
		return createFormField(arr, { disabled: formItem.disabled });
	};

	const _handleChange = (options: SelectOption<number>[]) => {
		handleChange(options.reduce((prev, next) => prev + next.value, 0));
	};

	return (
		<MultiSelect
			className="flex-1 w-full"
			label={t('MEASUREMENTS.relay action')}
			inputHeight={ 34 }
			isDisabled={ disabled }
			options={ mapRelayOptionsToSelectOptions(options) }
			formItem={ _getFormItem() }
			onChange={ _handleChange }
			placeholder={t('MEASUREMENTS.select action')}
			isClearable={ false }
			isSearchable={ false }
			hasBeenChanged={ reducerFormItem.initialValue !== reducerFormItem.value }
		/>
	);
}

export default (RelayActionSelect);
