import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import PhasorContainer from "src/app/containers/Realtime/Phasor.container";
import useWebSocket, { WebSocketUri } from "src/app/utils/hooks/useWebSocket";
import { DataPhasorLive } from "src/app/types/api/ws.types";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function PhasorView(props: Props) {

	const [ lastRecord ] = useWebSocket<DataPhasorLive>(WebSocketUri.DATA_PHASOR_LIVE, true);

	return (
		<PhasorContainer
			lastRecord={ lastRecord }
		/>
	);
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PhasorView);
