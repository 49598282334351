import { MutableRefObject, useEffect } from "react";
import { Nullable } from "src/app/types/util.types";

function useOutsideAlerter(ref: MutableRefObject<Nullable<HTMLElement>>, callback: () => void) {
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event: MouseEvent) {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				callback();
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ ref ]);
}

export default useOutsideAlerter;
