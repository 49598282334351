//@ts-ignore
import { Chart, ChartCanvas } from "react-stockcharts";
//@ts-ignore
import { GroupedBarSeries } from "react-stockcharts/lib/series";
//@ts-ignore
import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";
//@ts-ignore
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { appColors } from "src/app/utils/constants/theme";
import { GridLoader } from "react-spinners";
import { roundRange } from "src/app/utils/helpers";
import { StockChartIndex } from "src/app/types/ui/chart.types";
import HarmonicChartHoverTooltip from "src/app/components/Chart/Component/HarmonicHoverTooltip.component";
import { Nullable } from "src/app/types/util.types";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import { Dispatch, SetStateAction } from "react";
import { Button } from "flowbite-react";
import { useTranslation } from "react-i18next";

export enum HarmonicLine {
	U1 = "U1",
	U2 = "U2",
	U3 = "U3",
	I1 = "I1",
	I2 = "I2",
	I3 = "I3"
}

export type HarmonicConfigState = {
	[K in HarmonicLine]: boolean
}

type Props = {
	seriesName: string
	width: number
	height: number
	data: HarmonicChartData[]
	config: HarmonicConfigState
	units: {
		U: string
		I: string
	}
	setDisplayedRange: Dispatch<SetStateAction<[ fromIndex: number, toIndex: number ]>>
	getTooltipLabels: (config: HarmonicConfigState, currentItem: HarmonicChartData & StockChartIndex) => { label: string, value: string }[]
};

export type HarmonicChartData =
	{
		index: number
		date: Date
	}
	& {
		[K in HarmonicLine]: Nullable<number>
	};

function HarmonicChart(props: Props) {

	const { t } = useTranslation();

	const {
		seriesName,
		width,
		height,
		data,
		config,
		units: {
			U,
			I,
		},
		setDisplayedRange,
		getTooltipLabels,
	} = props;

	const _calculateTensionsRange = (data: HarmonicChartData[]): [ min: number, max: number ] => {
		const firstValue = data[ 0 ];
		if (isNull(firstValue)) return [ 0, 2 ];
		const firstMinValue = Math.min(
			...[
				config.U1 ? (data[ 0 ].U1 ?? 0) : null,
				config.U2 ? (data[ 0 ].U2 ?? 0) : null,
				config.U3 ? (data[ 0 ].U3 ?? 0) : null,
			].filter(isNotNull),
		);
		const firstMaxValue = Math.max(
			...[
				config.U1 ? (data[ 0 ].U1 ?? 0) : null,
				config.U2 ? (data[ 0 ].U2 ?? 0) : null,
				config.U3 ? (data[ 0 ].U3 ?? 0) : null,
			].filter(isNotNull),
		);
		const yAxisRange: [ number, number ] = data.reduce((prev, next) => [
			Math.min(
				prev[ 0 ],
				config.U1 ? (next.U1 ?? 0) : prev[ 0 ],
				config.U2 ? (next.U2 ?? 0) : prev[ 0 ],
				config.U3 ? (next.U3 ?? 0) : prev[ 0 ],
			),
			Math.max(
				prev[ 1 ],
				config.U1 ? (next.U1 ?? 0) : prev[ 1 ],
				config.U2 ? (next.U2 ?? 0) : prev[ 1 ],
				config.U3 ? (next.U3 ?? 0) : prev[ 1 ],
			),
		], [ firstMinValue, firstMaxValue ]);

		return [
			roundRange(yAxisRange[ 0 ], Math.floor),
			roundRange(yAxisRange[ 1 ], Math.ceil),
		];
	};

	const _calculateIntensitiesRange = (data: HarmonicChartData[]): [ min: number, max: number ] => {
		const firstValue = data[ 0 ];
		if (isNull(firstValue)) return [ 0, 2 ];
		const firstMinValue = Math.min(
			...[
				config.I1 ? (data[ 0 ].I1 ?? 0) : null,
				config.I2 ? (data[ 0 ].I2 ?? 0) : null,
				config.I3 ? (data[ 0 ].I3 ?? 0) : null,
			].filter(isNotNull),
		);
		const firstMaxValue = Math.max(
			...[
				config.I1 ? (data[ 0 ].I1 ?? 0) : null,
				config.I2 ? (data[ 0 ].I2 ?? 0) : null,
				config.I3 ? (data[ 0 ].I3 ?? 0) : null,
			].filter(isNotNull),
		);
		const yAxisRange: [ number, number ] = data.reduce((prev, next) => [
			Math.min(
				prev[ 0 ],
				config.I1 ? (next.I1 ?? 0) : prev[ 0 ],
				config.I2 ? (next.I2 ?? 0) : prev[ 0 ],
				config.I3 ? (next.I3 ?? 0) : prev[ 0 ],
			),
			Math.max(
				prev[ 1 ],
				config.I1 ? (next.I1 ?? 0) : prev[ 1 ],
				config.I2 ? (next.I2 ?? 0) : prev[ 1 ],
				config.I3 ? (next.I3 ?? 0) : prev[ 1 ],
			),
		], [ firstMinValue, firstMaxValue ]);

		return [
			roundRange(yAxisRange[ 0 ], Math.floor),
			roundRange(yAxisRange[ 1 ], Math.ceil),
		];
	};

	if (data.length < 3) {
		return (
			<div className="w-full h-full flex items-center justify-center">
				<GridLoader size={ 15 } color="#0093DD"/>
			</div>
		);
	}

	const yExtentsTensions = _calculateTensionsRange(data);
	const yExtentsIntensities = _calculateIntensitiesRange(data);

	const isDisplayingTensions = config.U1 || config.U2 || config.U3;
	const isDisplayingIntensities = config.I1 || config.I2 || config.I3;

	const margins = {
		left: 60,
		right: 60,
		top: 20,
		bottom: 30,
	};

	const initialIndex = 0;
	const xScaleProvider = discontinuousTimeScaleProvider
		.initialIndex(initialIndex)
		.inputDateAccessor((d: any) => d.date);

	const {
		data: chartData,
		xScale,
		xAccessor,
	} = xScaleProvider(
		data,
	);

	const firstItem = chartData[ data.length - 1 ];
	const lastItem = chartData[ 0 ];

	const xExtents = new Array(chartData.length + 2).fill(initialIndex - 1).map((item, index) => item + index);

	const legendDescriptionY = "-8";

	return (
		<div className="relative">
			<div className="absolute top-1/2 -translate-y-1/2 right-2 z-50 w-[46px] h-[102px]">
				<Button
					outline
					pill
					className="group absolute left-0 top-0 [&>span]:p-1 [&>span]:pl-1.5 [&>span]:pr-0.5"
				>
					<IoChevronForwardOutline
						className="w-8 h-8 group-hover:text-white transition"
						onClick={ () => setDisplayedRange(prevState => [ Math.min(prevState[ 0 ] + 50, 200), Math.min(prevState[ 1 ] + 50, 250) ]) }
					/>
				</Button>
				<Button
					outline
					pill
					className="group absolute left-0 top-14 [&>span]:p-1 [&>span]:pl-0.5 [&>span]:pr-1.5"
				>
					<IoChevronBackOutline
						className="w-8 h-8 group-hover:text-white transition"
						onClick={ () => setDisplayedRange(prevState => [ Math.max(prevState[ 0 ] - 50, 0), Math.max(prevState[ 1 ] - 50, 50) ]) }
					/>
				</Button>
			</div>
			<ChartCanvas
				ratio={ 1 }
				width={ width }
				height={ height }
				margin={ margins }
				type="svg"
				seriesName={ seriesName }
				xExtents={ xExtents }
				data={ chartData }
				xAccessor={ xAccessor }
				xScale={ xScale }
				mouseMoveEvent={ true }
				panEvent={ false }
				zoomEvent={ false }
			>
				<Chart
					height={ (0.5 * height) - 50 }
					id={ `${ seriesName }-1` }
					yExtents={ yExtentsTensions }
				>
					<XAxis
						axisAt="bottom"
						orient="bottom"
						fontFamily="Segoe UI, sans-serif"
						tickFormat={ (index: number) => {
							const axisIndex = (chartData as (HarmonicChartData & StockChartIndex)[]).find(dataItem => dataItem.idx.index === index)?.index;
							if (isNotNull(axisIndex) && axisIndex % 2 !== 1 && axisIndex !== 0) {
								return "";
							}
							return (chartData as (HarmonicChartData & StockChartIndex)[]).find(dataItem => dataItem.idx.index === index)?.index?.toString() ?? "";
						} }
						tickValues={ chartData.map((item: HarmonicChartData & StockChartIndex) => item.idx.index) }
						zoomEnabled={ false }
						fontSize={ 12 }
					/>
					<YAxis
						axisAt="left"
						orient="left"
						fontFamily="Segoe UI, sans-serif"
						zoomEnabled={ false }
					/>
					<GroupedBarSeries
						baseAt={ (xScale: any, yScale: any) => yScale(0) }
						yAccessor={
							[
								config.U1 ? (d: HarmonicChartData) => d.U1 : null,
								config.U2 ? (d: HarmonicChartData) => d.U2 : null,
								config.U3 ? (d: HarmonicChartData) => d.U3 : null,
							].filter(isNotNull)
						}
						fill={ (d: HarmonicChartData, i: number) => {
							// Spaghetti code
							if (!config.U1 && config.U2) i++; // 0 1 1; 0 1 0
							if (!config.U1 && !config.U2) i += 2; // 0 0 1; 0 0 0
							if (config.U1 && !config.U2 && i === 1) i++; // 1 0 1

							// rest is
							// 100, 110, 111

							switch (i) {
								case 0:
									return appColors.U1;
								case 1:
									return appColors.U2;
								case 2:
									return appColors.U3;
								default:
									return "#000000";
							}
						} }
						spaceBetweenBar={ -4 }
					/>
					{
						isDisplayingTensions &&
                        <>
                            <rect x="-30" y="-10" width={ 30 } height={ 16 } fill="white"/>
							{/*<text x="-30" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>{ `U[${ getPrefixToUnit(0.0, U, MeasurementCategory.VOLTAGES_WAVE) }]` }</text>*/ }
                            <text x="-30" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>{ `U[${ U }]` }</text>
                        </>
					}
				</Chart>
				<Chart
					height={ (0.5 * height) - 50 }
					id={ `${ seriesName }-2` }
					yExtents={ yExtentsIntensities }
					origin={ (w: number, h: number) => [ 0, h - (0.5 * height) + 50 ] }
				>
					<XAxis
						axisAt="bottom"
						orient="bottom"
						tickFormat={ (index: number) => {
							const axisIndex = (chartData as (HarmonicChartData & StockChartIndex)[]).find(dataItem => dataItem.idx.index === index)?.index;
							if (isNotNull(axisIndex) && axisIndex % 2 !== 1 && axisIndex !== 0) {
								return "";
							}
							return (chartData as (HarmonicChartData & StockChartIndex)[]).find(dataItem => dataItem.idx.index === index)?.index?.toString() ?? "";
						} }
						fontFamily="Segoe UI, sans-serif"
						tickValues={ chartData.map((item: HarmonicChartData & StockChartIndex) => item.idx.index) }
						zoomEnabled={ false }
						fontSize={ 12 }
					/>
					<YAxis
						axisAt="left"
						orient="left"
						zoomEnabled={ false }
						fontFamily="Segoe UI, sans-serif"
					/>
					<GroupedBarSeries
						baseAt={ (xScale: any, yScale: any) => yScale(0) }
						yAccessor={ [
							config.I1 ? (d: HarmonicChartData) => d.I1 : null,
							config.I2 ? (d: HarmonicChartData) => d.I2 : null,
							config.I3 ? (d: HarmonicChartData) => d.I3 : null,
						].filter(isNotNull) }
						fill={ (d: HarmonicChartData, i: number) => {
							// Spaghetti code
							if (!config.I1 && config.I2) i++; // 0 1 1; 0 1 0
							if (!config.I1 && !config.I2) i += 2; // 0 0 1; 0 0 0
							if (config.I1 && !config.I2 && i === 1) i++; // 1 0 1

							// rest is
							// 100, 110, 111

							switch (i) {
								case 0:
									return appColors.I1;
								case 1:
									return appColors.I2;
								case 2:
									return appColors.I3;
								default:
									return "#000000";
							}
						} }
						spaceBetweenBar={ -4 }
					/>
					{
						isDisplayingIntensities &&
                        <>
                            <rect x="-45" y="-10" width={ 50 } height={ 16 } fill="white"/>
                            <text x="-30" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>{ `I[${ I }]` }</text>
                        </>
					}
				</Chart>
				<Chart
					height={ height }
					id={ `${ seriesName }-3` }
					yExtents={ yExtentsTensions }
				>
					<HarmonicChartHoverTooltip
						firstItem={ firstItem }
						lastItem={ lastItem }
						getTooltipContent={ (probeArgument: { currentItem: HarmonicChartData & StockChartIndex }) => {
							const { currentItem } = probeArgument;
							return {
								x: `${ t("TABLE.index") }: ${ currentItem.index }`,
								y: getTooltipLabels(config, currentItem),
							};
						} }
					/>
				</Chart>
			</ChartCanvas>
		</div>
	);
}

export default (HarmonicChart);
