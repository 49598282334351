import { FormItem } from "src/app/types/ui/form.types";
import classNames from "classnames";
import { isNotNull } from "src/app/utils/typeguards";
import { Label, LabelProps } from "flowbite-react";
import ReactSelect, { MenuPlacement } from "react-select";
import { getSelectClassNames, getSelectStyles } from "src/app/utils/ui";
import { EnumOptionsSetting, SelectOption } from "src/app/types/util.types";
import { RecordingOption } from "src/app/types/api/settings.types";
import { recordingOptionDefaultMasks } from "src/app/utils/constants/dictionaries";
import { useTranslation } from "react-i18next";

type Props =
	{
		className?: string
		label?: string | ReactNode
		labelProps?: LabelProps
		options: EnumOptionsSetting<RecordingOption>["options"]
		unit: string
		unitPercent: string
		formItem: FormItem<number>
		onChange: (value: number) => void
		portalEl?: HTMLElement
		menuPlacement?: MenuPlacement
		optionsHeight?: number
		inputHeight?: number
		hasBeenChanged?: boolean
		isDisabled?: boolean
	};

function RelativeValuesSelect(props: Props) {

	const { t } = useTranslation();

	const {
		className,
		label,
		labelProps,
		options,
		unit,
		unitPercent,
		formItem,
		onChange,
		portalEl,
		menuPlacement = "auto",
		optionsHeight = 240,
		inputHeight = 42,
		hasBeenChanged = false,
		isDisabled = false,
	} = props;

	const _getOptions = () => {
		const firstOption: SelectOption<number> = {
			value: 0,
			label: unit,
		};
		const secondOptionMask = options.find(option => option.text === RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE)?.mask ?? recordingOptionDefaultMasks()[ RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE ];
		const secondOption: SelectOption<number> = {
			value: secondOptionMask,
			label: unitPercent,
		};

		return [
			secondOption,
			firstOption,
		];
	};

	const _getValue = () => {
		const relativeMask = options.find(option => option.text === RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE)?.mask ?? recordingOptionDefaultMasks()[ RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE ];

		return formItem.value & relativeMask;
	};

	const _handleChange = () => {
		const relativeMask = options.find(option => option.text === RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE)?.mask ?? recordingOptionDefaultMasks()[ RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE ];

		if (actualValue > 0) {
			onChange(formItem.value - relativeMask);
		} else {
			onChange(formItem.value + relativeMask);
		}
	};

	const selectOptions = _getOptions();
	const actualValue = _getValue();

	return (
		<div className={ classNames(className, "min-w-[100px] flex flex-col gap-y-0.5") }>
			<Label
				disabled={ formItem.disabled || isDisabled }
				{ ...labelProps }
			>
				{ t("MEASUREMENTS.unit") }
			</Label>
			<ReactSelect
				styles={ getSelectStyles<number, false>(optionsHeight, inputHeight) }
				classNames={ getSelectClassNames<number, false>(formItem, false, hasBeenChanged) }
				options={ selectOptions }
				value={ selectOptions.find(option => option.value === actualValue) ?? null }
				isMulti={ false }
				menuPortalTarget={ portalEl }
				onChange={ _handleChange }
				isOptionDisabled={ option => isNotNull(option.disabled) && option.disabled }
				isDisabled={ formItem.disabled || isDisabled }
				isSearchable={ false }
				isClearable={ false }
				menuPlacement={ menuPlacement }
				placeholder={ t("MEASUREMENTS.select option") }
				// menuIsOpen={ true }
			/>
		</div>
	);
}

export default (RelativeValuesSelect);
