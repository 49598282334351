import { RootEpic } from "src/app/store/root.epic";
import { uiPingFirmwareVersion, uiRemoveFirmwareFile, uiSetUploadProgress, uiUpgradeFirmware, uiUploadFirmware, uiVerifyUpgradeProcess } from "src/app/store/features/ui/firmware/ui.firmware.actions";
import { delay, filter, mergeMap, switchMap, take } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { concat, merge, of } from "rxjs";
import { addLoadingRecord, removeLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.actions";
import { LoadableType } from "src/app/types/ui/loading.types";
import { fetchFirmwareInfoAsync, pingFirmwareInfoAsync, removeFirmwareFileAsync, upgradeFirmwareAsync, uploadFirmwareAsync } from "src/app/store/features/firmware/firmware.actions";
import store from "src/app/store/index";
import { addMessage, displayToast, removeMessagesFromView } from "src/app/store/features/message/message.actions";
import { AddMessageContent, SnackbarMessageType, ToastType, View } from "src/app/types/ui/message.types";
import { forgetSession } from "src/app/store/features/user/user.actions";
import { firmwareUpgradeFormActions } from "src/app/store/features/form/form.actions";
import { FirmwareUpgradeStep } from "src/app/utils/constants/firmwareUpgrade.form";
import i18n from "src/app/translations/i18n";

export const uiUploadFirmwareEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiUploadFirmware)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableType: LoadableType.UPLOADING_FIRMWARE_FILE })),
				of(uploadFirmwareAsync.request({
					file: action.payload,
					onProgress: progressEvent => {
						const progress = Math.round(progressEvent.loaded * 100 / progressEvent.total);
						if (progressEvent.loaded !== progressEvent.total) {
							store.dispatch(uiSetUploadProgress(progress));
						} else {
							store.dispatch(addLoadingRecord({ loadableType: LoadableType.PROCESSING_FIRMWARE_FILE }));
						}
					},
				})),
				action$.pipe(
					filter(action => isActionOf(uploadFirmwareAsync.success, action) || isActionOf(uploadFirmwareAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(uploadFirmwareAsync.success, responseAction)) {
							return merge(
								of(fetchFirmwareInfoAsync.request()),
								action$.pipe(
									filter(action => isActionOf(fetchFirmwareInfoAsync.success, action) || isActionOf(fetchFirmwareInfoAsync.failure, action)),
									take(1),
									mergeMap(() =>
										merge(
											of(removeLoadingRecord({ loadableType: LoadableType.PROCESSING_FIRMWARE_FILE })),
											of(removeLoadingRecord({ loadableType: LoadableType.UPLOADING_FIRMWARE_FILE })),
											of(uiSetUploadProgress(0)),
										),
									),
								),
							);
						} else {
							return merge(
								of(displayToast({ type: ToastType.ERROR, content: i18n.t("RESPONSES.FIRMWARE.upload error") })),
								of(removeLoadingRecord({ loadableType: LoadableType.UPLOADING_FIRMWARE_FILE })),
								of(removeLoadingRecord({ loadableType: LoadableType.PROCESSING_FIRMWARE_FILE })),
							);
						}
					}),
				),
			),
		),
	);

export const uiRemoveFirmwareFileEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiRemoveFirmwareFile)),
		switchMap(() =>
			concat(
				of(addLoadingRecord({ loadableType: LoadableType.REMOVE_FIRMWARE_FILE })),
				of(removeFirmwareFileAsync.request()),
				action$.pipe(
					filter(action => isActionOf(removeFirmwareFileAsync.success, action) || isActionOf(removeFirmwareFileAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(removeFirmwareFileAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord({ loadableType: LoadableType.REMOVE_FIRMWARE_FILE })),
								of(fetchFirmwareInfoAsync.request()),
							);
						} else {
							return of(removeLoadingRecord({ loadableType: LoadableType.REMOVE_FIRMWARE_FILE }));
						}
					}),
				),
			),
		),
	);

export const uiUpgradeFirmwareEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiUpgradeFirmware)),
		switchMap(() =>
			concat(
				of(addLoadingRecord({ loadableType: LoadableType.UPGRADE_FIRMWARE_REQUEST })),
				of(upgradeFirmwareAsync.request()),
				action$.pipe(
					filter(action => isActionOf(upgradeFirmwareAsync.success, action) || isActionOf(upgradeFirmwareAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(upgradeFirmwareAsync.failure, responseAction)) {
							return merge(
								of(removeLoadingRecord({ loadableType: LoadableType.UPGRADE_FIRMWARE_REQUEST })),
								of(displayToast({ type: ToastType.ERROR, content: i18n.t("RESPONSES.FIRMWARE.upgrade error") })),
							);
						}

						return merge(
							of(uiVerifyUpgradeProcess()),
							of(firmwareUpgradeFormActions.handleChange({ prop: "step", value: FirmwareUpgradeStep.UPGRADE })),
							of(removeLoadingRecord({ loadableType: LoadableType.UPGRADE_FIRMWARE_REQUEST })),
						);
					}),
				),
			),
		),
	);

export const upgradeFirmwareSuccessEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiVerifyUpgradeProcess)),
		delay(120_000),
		// delay(120_000),
		mergeMap(() =>
			concat(
				of(pingFirmwareInfoAsync.request()),
				action$.pipe(
					filter(isActionOf(pingFirmwareInfoAsync.failure)),
					take(1),
					mergeMap(action => {
						if (action.payload.status === 403) {
							return merge(
								of(removeMessagesFromView(View.LOGIN)),
								of(addMessage({ type: SnackbarMessageType.SUCCESS, view: View.LOGIN, message: AddMessageContent.LOGIN_FIRMWARE_UPGRADE_SUCCESS })),
							);
						}

						return of(uiPingFirmwareVersion(0));
					}),
				),
			),
		),
	);

export const uiPingFirmwareVersionEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiPingFirmwareVersion)),
		delay(50_000),
		mergeMap(action =>
			concat(
				of(pingFirmwareInfoAsync.request()),
				action$.pipe(
					filter(isActionOf(pingFirmwareInfoAsync.failure)),
					take(1),
					mergeMap(responseAction => {
						if (responseAction.payload.status === 502) {
							const newCounter = action.payload + 1;
							if (newCounter === 3) {
								return merge(
									of(forgetSession()),
									of(addMessage({ type: SnackbarMessageType.ERROR, view: View.LOGIN, message: AddMessageContent.LOGIN_FIRMWARE_UPGRADE_FAILURE })),
								);
							}
							return of(uiPingFirmwareVersion(newCounter));
						} else if (responseAction.payload.status === 403) {
							return merge(
								of(removeMessagesFromView(View.LOGIN)),
								of(addMessage({ type: SnackbarMessageType.SUCCESS, view: View.LOGIN, message: AddMessageContent.LOGIN_FIRMWARE_UPGRADE_SUCCESS })),
							);
						}
						return of(pingFirmwareInfoAsync.request());
					}),
				),
			),
		),
	);
