import { RecordingOption, SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateNumberField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import BasicRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/BasicRecordingOptionConfiguration.component";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { useContext, useEffect } from "react";
import { MeasurementsUserContext } from "src/app/components/Measurements/MeasurementsUserContent.component";
import EventRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/EventRecordingOptionConfiguration.component";
import Input from "src/app/components/Form/Input.component";
import { Button, Card } from "flowbite-react";
import RelayChannelChooser from "src/app/components/MeasurementsUser/Generic/RelayChannelChooser.component";
import { isEmptyArray, isNotNull } from "src/app/utils/typeguards";
import RelayActionSelect from "src/app/components/MeasurementsUser/Generic/RelayActionSelect.component";
import { isOptionSelected } from "src/app/utils/helpers";
import { EnumOptionsSetting } from "src/app/types/util.types";
import { isChannelsDiff, mapApparentPowerConfig } from "src/app/utils/configuration";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";
import classNames from "classnames";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsMeasurementsUser: SettingsMeasurementsUser
	};

export type ApparentPowerForm = {
	cfgApparentPowerRecOpt: number
	cfgApparentPowerRecOptOptions: EnumOptionsSetting<RecordingOption>["options"]
	cfgApparentPowerEventThresholdMax: string
	cfgApparentPowerEventThresholdMaxMin: number
	cfgApparentPowerEventThresholdMaxMax: number
	cfgApparentPowerChannels: ("cfgRelayApparentPower1RecOpt" | "cfgRelayApparentPower2RecOpt" | "cfgRelayApparentPower3RecOpt" | "cfgRelayApparentPower4RecOpt")[]
	cfgApparentPowerActions: number
}

const validator: FormValidator<ApparentPowerForm> = {
	cfgApparentPowerRecOpt: () => null,
	cfgApparentPowerRecOptOptions: () => null,
	cfgApparentPowerEventThresholdMax: (cfgApparentPowerEventThresholdMax, optional, form) => {
		if (!isOptionSelected(form.cfgApparentPowerRecOpt.value, RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE, form.cfgApparentPowerRecOptOptions.value)) return null;

		return validateNumberField(i18n.t("ENERGY.max"), cfgApparentPowerEventThresholdMax, optional, "he", { from: form.cfgApparentPowerEventThresholdMaxMin.value, to: form.cfgApparentPowerEventThresholdMaxMax.value, decimalPlaces: 2 });
	},
	cfgApparentPowerEventThresholdMaxMin: () => null,
	cfgApparentPowerEventThresholdMaxMax: () => null,
	cfgApparentPowerChannels: () => null,
	cfgApparentPowerActions: () => null,
};

const ID = "apparent-power";

function ApparentPowerCard(props: Props) {

	const { t } = useTranslation();

	const {
		settingsMeasurementsUser: {
			cfgApparentPower,
		},
		isAdmin,
	} = props;

	const {
		form: reducerForm,
		handleChange: reducerHandleChange,
	} = useReducerForm(
		"buffor",
		bufforFormActions,
		() => null,
	);

	const { cfgUserEnable } = useContext(MeasurementsUserContext);

	const _handleSubmit = (values: ApparentPowerForm) => {
		reducerHandleChange("cfgApparentPowerRecOpt", values.cfgApparentPowerRecOpt);
		reducerHandleChange("cfgApparentPowerEventThresholdMax", values.cfgApparentPowerEventThresholdMax);
		reducerHandleChange("cfgApparentPowerChannels", values.cfgApparentPowerChannels);
		reducerHandleChange("cfgApparentPowerActions", values.cfgApparentPowerActions);
	};

	const _getInitialState = () => {
		const [ cfgApparentPowerChannels, cfgApparentPowerActions ] = mapApparentPowerConfig(cfgApparentPower);

		return {
			cfgApparentPowerRecOpt: createFormField((reducerForm.cfgApparentPowerRecOpt.value !== reducerForm.cfgApparentPowerRecOpt.initialValue) ? reducerForm.cfgApparentPowerRecOpt.value : cfgApparentPower?.cfgApparentPowerRecOpt?.value ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgApparentPowerRecOptOptions: createFormField(cfgApparentPower?.cfgApparentPowerRecOpt?.options ?? []),
			cfgApparentPowerEventThresholdMax: createFormField((reducerForm.cfgApparentPowerEventThresholdMax.value !== reducerForm.cfgApparentPowerEventThresholdMax.initialValue) ? reducerForm.cfgApparentPowerEventThresholdMax.value : cfgApparentPower?.cfgApparentPowerEventThresholdMax?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
			cfgApparentPowerEventThresholdMaxMin: createFormField(cfgApparentPower?.cfgApparentPowerEventThresholdMax?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgApparentPowerEventThresholdMaxMax: createFormField(cfgApparentPower?.cfgApparentPowerEventThresholdMax?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgApparentPowerChannels: createFormField(isChannelsDiff(reducerForm.cfgApparentPowerChannels) ? reducerForm.cfgApparentPowerChannels.value : cfgApparentPowerChannels, { disabled: !isAdmin || !cfgUserEnable }),
			cfgApparentPowerActions: createFormField((reducerForm.cfgApparentPowerActions.value !== reducerForm.cfgApparentPowerActions.initialValue) ? reducerForm.cfgApparentPowerActions.value : cfgApparentPowerActions, { disabled: !isAdmin || !cfgUserEnable }),
		};
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ cfgApparentPower ]);

	useEffect(() => {
		toggleDisable("cfgApparentPowerRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgApparentPowerEventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgApparentPowerChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgApparentPowerActions", !cfgUserEnable || !isAdmin);
	}, [ cfgUserEnable ]);

	useEffect(() => {
		toggleDisable("cfgApparentPowerRecOpt", false);
		toggleDisable("cfgApparentPowerEventThresholdMax", false);
		toggleDisable("cfgApparentPowerChannels", false);
		toggleDisable("cfgApparentPowerActions", false);

		handleChange("cfgApparentPowerRecOpt", reducerForm.cfgApparentPowerRecOpt.value);
		handleChange("cfgApparentPowerEventThresholdMax", reducerForm.cfgApparentPowerEventThresholdMax.value);
		handleChange("cfgApparentPowerChannels", reducerForm.cfgApparentPowerChannels.value);
		handleChange("cfgApparentPowerActions", reducerForm.cfgApparentPowerActions.value);

		toggleDisable("cfgApparentPowerRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgApparentPowerEventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgApparentPowerChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgApparentPowerActions", !cfgUserEnable || !isAdmin);
	}, [ reducerForm.cfgApparentPowerRecOpt.value,
		reducerForm.cfgApparentPowerEventThresholdMax.value,
		reducerForm.cfgApparentPowerChannels.value,
		reducerForm.cfgApparentPowerActions.value,
	]);

	return (
		<Card className="bg-gray-50">
			<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
				{ t("POWER.apparent power S") }
			</h5>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<BasicRecordingOptionConfiguration
					id={ ID }
					options={ cfgApparentPower?.cfgApparentPowerRecOpt?.options ?? [] }
					formItem={ form.cfgApparentPowerRecOpt }
					handleChange={ value => handleChange("cfgApparentPowerRecOpt", value) }
					reducerFormItem={ reducerForm.cfgApparentPowerRecOpt }
				/>
				<div className="flex flex-col gap-3">
					<h5 className="text-base sm:text-xl font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
						{ t("POWER.events") }
					</h5>
					<EventRecordingOptionConfiguration
						id={ ID }
						options={ cfgApparentPower?.cfgApparentPowerRecOpt?.options ?? [] }
						formItem={ form.cfgApparentPowerRecOpt }
						handleChange={ value => handleChange("cfgApparentPowerRecOpt", value) }
						reducerFormItem={ reducerForm.cfgApparentPowerRecOpt }
					>
						{
							(isLogEventEnabled) =>
								<div className="flex flex-col gap-2.5">
									<h5 className="text-sm sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
										{ t("POWER.thresholds") }
									</h5>
									<hr className="mx-2"/>
									<div className="flex gap-2.5 flex-1">
										<Input
											className="flex-1"
											formItem={ form.cfgApparentPowerEventThresholdMax }
											label={ t("POWER.max") }
											name="cfgApparentPowerEventThresholdMax"
											inputProps={ {
												type: "number",
												onChange: (e) => handleChange("cfgApparentPowerEventThresholdMax", e.target.value),
												onBlur: () => handleBlur("cfgApparentPowerEventThresholdMax"),
												disabled: !isLogEventEnabled,
												sizing: "sm",
												className: "[&>span]:!min-w-[40px] [&>span]:justify-center",
											} }
											addonRight={ cfgApparentPower?.cfgApparentPowerEventThresholdMax?.unit ?? "-" }
											hasBeenChanged={ reducerForm.cfgApparentPowerEventThresholdMax.value !== reducerForm.cfgApparentPowerEventThresholdMax.initialValue }
										/>
										<div
											className={
												classNames(
													"flex gap-2",
													"xl:flex-[7_1_0%]",
													"flex-[4_1_0%]",
												)
											}
										>
											<RelayChannelChooser
												options={ [
													(isNotNull(cfgApparentPower) && isNotNull(cfgApparentPower.relayChannelLabel) && isNotNull(cfgApparentPower.relayChannelLabel.ch1)) ? {
														value: "cfgRelayApparentPower1RecOpt",
														label: cfgApparentPower.relayChannelLabel.ch1,
													} : null,
													(isNotNull(cfgApparentPower) && isNotNull(cfgApparentPower.relayChannelLabel) && isNotNull(cfgApparentPower.relayChannelLabel.ch2)) ? {
														value: "cfgRelayApparentPower2RecOpt",
														label: cfgApparentPower.relayChannelLabel.ch2,
													} : null,
													(isNotNull(cfgApparentPower) && isNotNull(cfgApparentPower.relayChannelLabel) && isNotNull(cfgApparentPower.relayChannelLabel.ch3)) ? {
														value: "cfgRelayApparentPower3RecOpt",
														label: cfgApparentPower.relayChannelLabel.ch3,
													} : null,
													(isNotNull(cfgApparentPower) && isNotNull(cfgApparentPower.relayChannelLabel) && isNotNull(cfgApparentPower.relayChannelLabel.ch4)) ? {
														value: "cfgRelayApparentPower4RecOpt",
														label: cfgApparentPower.relayChannelLabel.ch4,
													} : null,
												].filter(isNotNull) }
												disabled={ !isLogEventEnabled }
												formItem={ form.cfgApparentPowerChannels }
												reducerFormItem={ reducerForm.cfgApparentPowerChannels }
												handleChange={ value => handleChange("cfgApparentPowerChannels", value) }
											/>
											<RelayActionSelect
												formItem={ form.cfgApparentPowerActions }
												reducerFormItem={ reducerForm.cfgApparentPowerActions }
												options={ cfgApparentPower?.cfgRelayApparentPower1Map?.options ?? [] }
												disabled={ !isLogEventEnabled || isEmptyArray(form.cfgApparentPowerChannels.value) }
												handleChange={ value => handleChange("cfgApparentPowerActions", value) }
											/>
										</div>
									</div>
									<hr className="mx-2"/>
								</div>
						}
					</EventRecordingOptionConfiguration>
				</div>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(ApparentPowerCard);
