import { createAction } from "typesafe-actions";
import { ElementSize } from "src/app/types/ui/layout.types";

export const setBodySize = createAction("UI_LAYOUT__SET_BODY_SIZE")<ElementSize>();
export const throttleBodySize = createAction("UI_LAYOUT__THROTTLE_BODY_SIZE")<ElementSize>();

export const startListeningMainSize = createAction("UI_LAYOUT__START_LISTENING_MAIN_SIZE")<undefined>();
export const setMainSize = createAction("UI_LAYOUT__SET_MAIN_SIZE")<ElementSize>();
export const throttleMainSize = createAction("UI_LAYOUT__THROTTLE_MAIN_SIZE")<ElementSize>();

export const setIsDarkTheme = createAction("UI_LAYOUT__SET_APP_THEME")<boolean>();

export const toggleSidebar = createAction("UI_LAYOUT__TOGGLE_SIDEBAR")<boolean>();
