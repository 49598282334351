// @ts-nocheck
import React, { Component } from "react";

class LabelAnnotation extends Component {
	constructor(props: any) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(e: any) {
		const { onClick } = this.props;

		if (onClick) {
			const { xScale, yScale, datum } = this.props;
			onClick({ xScale, yScale, datum }, e);
		}
	}

	render() {
		const { className, textAnchor, fontFamily, fontSize, opacity, rotate } = this.props;
		const { xAccessor, xScale, yScale } = this.props;

		const { xPos, yPos, fill, text, tooltip } = helper(this.props, xAccessor, xScale, yScale);

		return (<g className={ className }>
			<title>{ tooltip }</title>
			<rect
				x={ xPos - fontSize / 2 }
				y={ yPos - 15 - fontSize / 2 }
				width={ fontSize }
				height={ fontSize + 5 }
				fill="white"
				stroke="none"
			/>
			<text x={ xPos } y={ yPos }
				  fontFamily={ fontFamily } fontSize={ fontSize }
				  fill={ fill }
				  opacity={ opacity }

				  transform={ `rotate(${ rotate }, ${ xPos }, ${ yPos })` }
				  onClick={ this.handleClick }
				  textAnchor={ textAnchor }>{ text }</text>
		</g>);
	}
}

export function functor(v) {
	return typeof v === "function" ? v : () => v;
}

export function helper(props, xAccessor, xScale, yScale) {
	const { x = ({ xScale, xAccessor, datum }) => xScale(xAccessor(datum)), y, datum, fill, text, tooltip, plotData } = props;

	const xFunc = functor(x);
	const yFunc = functor(y);

	const [ xPos, yPos ] = [ xFunc({ xScale, xAccessor, datum, plotData }), yFunc({ yScale, datum, plotData }) ];

	return {
		xPos,
		yPos,
		text: functor(text)(datum),
		fill: functor(fill)(datum),
		tooltip: functor(tooltip)(datum),
	};
}

export default LabelAnnotation;
