import { SettingsIo } from "src/app/types/api/settings.types";
import QueryParamTabs from "src/app/components/Utils/QueryParamTabs.component";
import DigitalInputsCard from "src/app/components/InputOutput/DigitalInputsCard.component";
import RelaysCard from "src/app/components/InputOutput/RelaysCard.component";
import { useTranslation } from "react-i18next";

type Props = {
	settingsIo: SettingsIo
};

enum InputOutputTabs {
	DIGITAL_INPUTS = "digital-inputs",
	RELAYS = "relays"
}

function InputOutputContainer(props: Props) {

	const { t } = useTranslation();

	const {
		settingsIo,
	} = props;

	return (
		<div>
			<QueryParamTabs<InputOutputTabs>
				style="underline"
				items={ () => ({
					[ InputOutputTabs.DIGITAL_INPUTS ]: {
						name: t("INPUTOUTPUT.digital inputs"),
						component: (
							<DigitalInputsCard
								settingsIo={ settingsIo }
							/>
						),
					},
					[ InputOutputTabs.RELAYS ]: {
						name: t("INPUTOUTPUT.relays"),
						component: (
							<RelaysCard
								settingsIo={ settingsIo }
							/>
						),
					},
				}) }
			/>
		</div>
	);
}

export default (InputOutputContainer);
