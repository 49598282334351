import { Button, Card, Tabs } from "flowbite-react";
import classNames from "classnames";
import { MdOutlineFileUpload } from "react-icons/md";
import { useEffect, useRef, useState } from "react";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import TimeplotChart, { SPLITTED_TIMEPLOT_CHART_CONFIG, TimeplotChartData } from "src/app/components/Chart/Timeplot/TimeplotChart.component";
import Table from "src/app/components/Utils/Table.component";
import { saveStockchart } from "src/app/utils/helpers";
import { EnumDictionary, Nullable } from "src/app/types/util.types";
import { LayoutVariant } from "src/app/types/ui/layout.types";
import { layoutVariantDictionary, measurementCategoryCellClassNameDictionary, measurementCategoryRowClassNameDictionary } from "src/app/utils/constants/dictionaries";
import { formatValueToDeciSON, getPrefixToUnit } from "src/app/utils/dataFormatting";
import Dropdown, { DropdownItem } from "src/app/components/Utils/Dropdown.component";
import moment from "moment";
import { GrRotateLeft } from "react-icons/gr";
import { MeasurementCategory } from "src/app/types/misc.types";
import ChartFilter, { ChartFilterColor } from "src/app/components/Utils/ChartFilter.component";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		timeplotData: TimeplotChartData[]
	};

export enum TimeplotLine {
	U1N = "U1N",
	U2N = "U2N",
	U3N = "U3N",
	UNE = "UNE",
	U12 = "U12",
	U23 = "U23",
	U31 = "U31",
	I1 = "I1",
	I2 = "I2",
	I3 = "I3",
	L1_P = "L1_P",
	L2_P = "L2_P",
	L3_P = "L3_P",
	L1_Q = "L1_Q",
	L2_Q = "L2_Q",
	L3_Q = "L3_Q",
	L1_S = "L1_S",
	L2_S = "L2_S",
	L3_S = "L3_S",
	FREQUENCY = "FREQUENCY"
}

type TableRecord = {
	name: string
	value: Nullable<string>
	unit: Nullable<string>
	category: MeasurementCategory
}

export type TimeplotConfigState = {
	[K in TimeplotLine]: boolean
}

export enum TimeplotVariant {
	UNITED,
	SPLITTED
}

const timeplotVariantDictionary: EnumDictionary<TimeplotVariant, string> = () => ({
	[ TimeplotVariant.UNITED ]: i18n.t("TIMEPLOT.united"),
	[ TimeplotVariant.SPLITTED ]: i18n.t("TIMEPLOT.splitted"),
});

const MAIN_PADDING = 32;

function TimeplotContainer(props: Props) {

	const {
		timeplotData,
		bodySize,
		isSidebarOpen,
	} = props;

	const { t } = useTranslation();
	const cardRef = useRef<HTMLDivElement>(null);

	const [ cardWidth, setCardWidth ] = useState(0);
	const [ variant, setVariant ] = useState(LayoutVariant.ALL);
	const [ index, setIndex ] = useState(0);
	const [ activeTab, setActiveTab ] = useState(TimeplotVariant.UNITED);

	useEffect(() => {
		if (isNotNull(cardRef.current)) {
			setCardWidth(cardRef.current.getBoundingClientRect().width);
		}
	}, [ bodySize.width, isSidebarOpen, variant ]);

	const newestMeasurement = timeplotData[ timeplotData.length - 1 ];
	const main = document.querySelector("main");

	const isU1n = timeplotData.some(measure => isNotNull(measure.U1n_rms));
	const isU12 = timeplotData.some(measure => isNotNull(measure.U12_rms));
	const isU2n = timeplotData.some(measure => isNotNull(measure.U2n_rms));
	const isU23 = timeplotData.some(measure => isNotNull(measure.U23_rms));
	const isU3n = timeplotData.some(measure => isNotNull(measure.U3n_rms));
	const isU31 = timeplotData.some(measure => isNotNull(measure.U31_rms));
	const isUne = timeplotData.some(measure => isNotNull(measure.Une_rms));
	const isI1 = timeplotData.some(measure => isNotNull(measure.I1_rms));
	const isI2 = timeplotData.some(measure => isNotNull(measure.I2_rms));
	const isI3 = timeplotData.some(measure => isNotNull(measure.I3_rms));
	const isIn = timeplotData.some(measure => isNotNull(measure.In_rms));
	const isIe = timeplotData.some(measure => isNotNull(measure.Ie_rms));
	const isL1p = timeplotData.some(measure => isNotNull(measure.L1_P));
	const isL2p = timeplotData.some(measure => isNotNull(measure.L2_P));
	const isL3p = timeplotData.some(measure => isNotNull(measure.L3_P));
	const isL1q = timeplotData.some(measure => isNotNull(measure.L1_Q));
	const isL2q = timeplotData.some(measure => isNotNull(measure.L2_Q));
	const isL3q = timeplotData.some(measure => isNotNull(measure.L3_Q));
	const isL1s = timeplotData.some(measure => isNotNull(measure.L1_S));
	const isL2s = timeplotData.some(measure => isNotNull(measure.L2_S));
	const isL3s = timeplotData.some(measure => isNotNull(measure.L3_S));
	const isFrequency = timeplotData.some(measure => isNotNull(measure.frequency200ms));

	const isDisplayingU = isU1n || isU2n || isU3n || isUne;
	const isDisplayingCrossU = isU12 || isU23 || isU31;
	const isDisplayingI = isI1 || isI2 || isI3;
	const isDisplayingP = isL1p || isL2p || isL3p;
	const isDisplayingQ = isL1q || isL2q || isL3q;
	const isDisplayingS = isL1s || isL2s || isL3s;
	const isDisplayingFreq = isFrequency;

	const [ voltages, setVoltages ] = useState<TimeplotConfigState>({
		[ TimeplotLine.U1N ]: isU1n,
		[ TimeplotLine.U2N ]: isU2n,
		[ TimeplotLine.U3N ]: isU3n,
		[ TimeplotLine.UNE ]: false,
		[ TimeplotLine.U12 ]: isU12,
		[ TimeplotLine.U23 ]: isU23,
		[ TimeplotLine.U31 ]: isU31,
		[ TimeplotLine.I1 ]: isI1,
		[ TimeplotLine.I2 ]: isI2,
		[ TimeplotLine.I3 ]: isI3,
		[ TimeplotLine.L1_P ]: isL1p,
		[ TimeplotLine.L2_P ]: isL2p,
		[ TimeplotLine.L3_P ]: isL3p,
		[ TimeplotLine.L1_Q ]: isL1q,
		[ TimeplotLine.L2_Q ]: isL2q,
		[ TimeplotLine.L3_Q ]: isL3q,
		[ TimeplotLine.L1_S ]: isL1s,
		[ TimeplotLine.L2_S ]: isL2s,
		[ TimeplotLine.L3_S ]: isL3s,
		[ TimeplotLine.FREQUENCY ]: isFrequency,
	});
	const [ isInitialSetupSet, toggleIsInitialSetupSet ] = useState(false);

	const isPlotData = timeplotData.length > 0;

	useEffect(() => {
		if (isPlotData && !isInitialSetupSet) {
			setVoltages({
				[ TimeplotLine.U1N ]: isU1n,
				[ TimeplotLine.U2N ]: isU2n,
				[ TimeplotLine.U3N ]: isU3n,
				[ TimeplotLine.UNE ]: false,
				[ TimeplotLine.U12 ]: isU12,
				[ TimeplotLine.U23 ]: isU23,
				[ TimeplotLine.U31 ]: isU31,
				[ TimeplotLine.I1 ]: isI1,
				[ TimeplotLine.I2 ]: isI2,
				[ TimeplotLine.I3 ]: isI3,
				[ TimeplotLine.L1_P ]: isL1p,
				[ TimeplotLine.L2_P ]: isL2p,
				[ TimeplotLine.L3_P ]: isL3p,
				[ TimeplotLine.L1_Q ]: isL1q,
				[ TimeplotLine.L2_Q ]: isL2q,
				[ TimeplotLine.L3_Q ]: isL3q,
				[ TimeplotLine.L1_S ]: isL1s,
				[ TimeplotLine.L2_S ]: isL2s,
				[ TimeplotLine.L3_S ]: isL3s,
				[ TimeplotLine.FREQUENCY ]: isFrequency,
			});
			toggleIsInitialSetupSet(true);
		}
	}, [ isPlotData ]);

	const plotCounts = +isDisplayingU + +isDisplayingCrossU + +isDisplayingI + +isDisplayingP + +isDisplayingQ + +isDisplayingS + +isDisplayingFreq;

	const plotHeight = activeTab === TimeplotVariant.SPLITTED
		?
		(
			plotCounts * SPLITTED_TIMEPLOT_CHART_CONFIG.singleChartHeight
			+ Math.max(plotCounts - 1, 0) * SPLITTED_TIMEPLOT_CHART_CONFIG.chartGap
			+ SPLITTED_TIMEPLOT_CHART_CONFIG.margins.top
			+ SPLITTED_TIMEPLOT_CHART_CONFIG.margins.bottom
		)
		:
		600;

	const _getTopPosition = (index: number) => {
		const SINGLE_FILTER_HEIGHT = 34;
		if (index === 0) return Math.floor((SPLITTED_TIMEPLOT_CHART_CONFIG.margins.top - SINGLE_FILTER_HEIGHT) / 2);

		const gapTop = (SPLITTED_TIMEPLOT_CHART_CONFIG.chartGap - SINGLE_FILTER_HEIGHT) * (2 / 3);

		return Math.floor(SPLITTED_TIMEPLOT_CHART_CONFIG.margins.top + (index * SPLITTED_TIMEPLOT_CHART_CONFIG.singleChartHeight) + (Math.max(index - 1, 0) * SPLITTED_TIMEPLOT_CHART_CONFIG.chartGap) + gapTop);
	};

	const data: TableRecord[] = [
		isFrequency ? {
			name: t("UTILS.frequency"),
			value: formatValueToDeciSON(newestMeasurement?.frequency200ms?.value, MeasurementCategory.FREQUENCIES),
			unit: getPrefixToUnit(newestMeasurement?.frequency200ms?.value, newestMeasurement?.frequency200ms?.unit, MeasurementCategory.FREQUENCIES),
			category: MeasurementCategory.FREQUENCIES,
		} : undefined,
		isU1n ? {
			name: "U1N",
			value: formatValueToDeciSON(newestMeasurement?.U1n_rms?.value, MeasurementCategory.VOLTAGES),
			unit: getPrefixToUnit(newestMeasurement?.U1n_rms?.value, newestMeasurement?.U1n_rms?.unit, MeasurementCategory.VOLTAGES),
			category: MeasurementCategory.VOLTAGES,
		} : undefined,
		isU2n ? {
			name: "U2N",
			value: formatValueToDeciSON(newestMeasurement?.U2n_rms?.value, MeasurementCategory.VOLTAGES),
			unit: getPrefixToUnit(newestMeasurement?.U2n_rms?.value, newestMeasurement?.U2n_rms?.unit, MeasurementCategory.VOLTAGES),
			category: MeasurementCategory.VOLTAGES,
		} : undefined,
		isU3n ? {
			name: "U3N",
			value: formatValueToDeciSON(newestMeasurement?.U3n_rms?.value, MeasurementCategory.VOLTAGES),
			unit: getPrefixToUnit(newestMeasurement?.U3n_rms?.value, newestMeasurement?.U3n_rms?.unit, MeasurementCategory.VOLTAGES),
			category: MeasurementCategory.VOLTAGES,
		} : undefined,
		isUne ? {
			name: "UE",
			value: formatValueToDeciSON(newestMeasurement?.Une_rms?.value, MeasurementCategory.VOLTAGES),
			unit: getPrefixToUnit(newestMeasurement?.Une_rms?.value, newestMeasurement?.Une_rms?.unit, MeasurementCategory.VOLTAGES),
			category: MeasurementCategory.VOLTAGES,
		} : undefined,
		isU12 ? {
			name: "U12",
			value: formatValueToDeciSON(newestMeasurement?.U12_rms?.value, MeasurementCategory.VOLTAGES),
			unit: getPrefixToUnit(newestMeasurement?.U12_rms?.value, newestMeasurement?.U12_rms?.unit, MeasurementCategory.VOLTAGES),
			category: MeasurementCategory.VOLTAGES,
		} : undefined,
		isU23 ? {
			name: "U23",
			value: formatValueToDeciSON(newestMeasurement?.U23_rms?.value, MeasurementCategory.VOLTAGES),
			unit: getPrefixToUnit(newestMeasurement?.U23_rms?.value, newestMeasurement?.U23_rms?.unit, MeasurementCategory.VOLTAGES),
			category: MeasurementCategory.VOLTAGES,
		} : undefined,
		isU31 ? {
			name: "U31",
			value: formatValueToDeciSON(newestMeasurement?.U31_rms?.value, MeasurementCategory.VOLTAGES),
			unit: getPrefixToUnit(newestMeasurement?.U31_rms?.value, newestMeasurement?.U31_rms?.unit, MeasurementCategory.VOLTAGES),
			category: MeasurementCategory.VOLTAGES,
		} : undefined,
		isI1 ? {
			name: "I1",
			value: formatValueToDeciSON(newestMeasurement?.I1_rms?.value, MeasurementCategory.CURRENTS),
			unit: getPrefixToUnit(newestMeasurement?.I1_rms?.value, newestMeasurement?.I1_rms?.unit, MeasurementCategory.CURRENTS),
			category: MeasurementCategory.CURRENTS,
		} : undefined,
		isI2 ? {
			name: "I2",
			value: formatValueToDeciSON(newestMeasurement?.I2_rms?.value, MeasurementCategory.CURRENTS),
			unit: getPrefixToUnit(newestMeasurement?.I2_rms?.value, newestMeasurement?.I2_rms?.unit, MeasurementCategory.CURRENTS),
			category: MeasurementCategory.CURRENTS,
		} : undefined,
		isI3 ? {
			name: "I3",
			value: formatValueToDeciSON(newestMeasurement?.I3_rms?.value, MeasurementCategory.CURRENTS),
			unit: getPrefixToUnit(newestMeasurement?.I3_rms?.value, newestMeasurement?.I3_rms?.unit, MeasurementCategory.CURRENTS),
			category: MeasurementCategory.CURRENTS,
		} : undefined,
		isL1p ? {
			name: "PL1",
			value: formatValueToDeciSON(newestMeasurement?.L1_P?.value, MeasurementCategory.ACTIVE_POWERS),
			unit: getPrefixToUnit(newestMeasurement?.L1_P?.value, newestMeasurement?.L1_P?.unit, MeasurementCategory.ACTIVE_POWERS),
			category: MeasurementCategory.ACTIVE_POWERS,
		} : undefined,
		isL2p ? {
			name: "PL2",
			value: formatValueToDeciSON(newestMeasurement?.L2_P?.value, MeasurementCategory.ACTIVE_POWERS),
			unit: getPrefixToUnit(newestMeasurement?.L2_P?.value, newestMeasurement?.L2_P?.unit, MeasurementCategory.ACTIVE_POWERS),
			category: MeasurementCategory.ACTIVE_POWERS,
		} : undefined,
		isL3p ? {
			name: "PL3",
			value: formatValueToDeciSON(newestMeasurement?.L3_P?.value, MeasurementCategory.ACTIVE_POWERS),
			unit: getPrefixToUnit(newestMeasurement?.L3_P?.value, newestMeasurement?.L3_P?.unit, MeasurementCategory.ACTIVE_POWERS),
			category: MeasurementCategory.ACTIVE_POWERS,
		} : undefined,
		isL1q ? {
			name: "QL1",
			value: formatValueToDeciSON(newestMeasurement?.L1_Q?.value, MeasurementCategory.REACTIVE_POWERS),
			unit: getPrefixToUnit(newestMeasurement?.L1_Q?.value, newestMeasurement?.L1_Q?.unit, MeasurementCategory.REACTIVE_POWERS),
			category: MeasurementCategory.REACTIVE_POWERS,
		} : undefined,
		isL2q ? {
			name: "QL2",
			value: formatValueToDeciSON(newestMeasurement?.L2_Q?.value, MeasurementCategory.REACTIVE_POWERS),
			unit: getPrefixToUnit(newestMeasurement?.L2_Q?.value, newestMeasurement?.L2_Q?.unit, MeasurementCategory.REACTIVE_POWERS),
			category: MeasurementCategory.REACTIVE_POWERS,
		} : undefined,
		isL3q ? {
			name: "QL3",
			value: formatValueToDeciSON(newestMeasurement?.L3_Q?.value, MeasurementCategory.REACTIVE_POWERS),
			unit: getPrefixToUnit(newestMeasurement?.L3_Q?.value, newestMeasurement?.L3_Q?.unit, MeasurementCategory.REACTIVE_POWERS),
			category: MeasurementCategory.REACTIVE_POWERS,
		} : undefined,
		isL1s ? {
			name: "SL1",
			value: formatValueToDeciSON(newestMeasurement?.L1_S?.value, MeasurementCategory.APPARENT_POWERS),
			unit: getPrefixToUnit(newestMeasurement?.L1_S?.value, newestMeasurement?.L1_S?.unit, MeasurementCategory.APPARENT_POWERS),
			category: MeasurementCategory.APPARENT_POWERS,
		} : undefined,
		isL2s ? {
			name: "SL2",
			value: formatValueToDeciSON(newestMeasurement?.L2_S?.value, MeasurementCategory.APPARENT_POWERS),
			unit: getPrefixToUnit(newestMeasurement?.L2_S?.value, newestMeasurement?.L2_S?.unit, MeasurementCategory.APPARENT_POWERS),
			category: MeasurementCategory.APPARENT_POWERS,
		} : undefined,
		isL3s ? {
			name: "SL3",
			value: formatValueToDeciSON(newestMeasurement?.L3_S?.value, MeasurementCategory.APPARENT_POWERS),
			unit: getPrefixToUnit(newestMeasurement?.L3_S?.value, newestMeasurement?.L3_S?.unit, MeasurementCategory.APPARENT_POWERS),
			category: MeasurementCategory.APPARENT_POWERS,
		} : undefined,
	].filter(isNotNull);

	const _exportToCsv = () => {
		const rows = [
			[
				t("TIMEPLOT.timestamp"),
				voltages.FREQUENCY ? `f[${ newestMeasurement?.frequency200ms?.unit ?? "Hz" }]` : null,
				voltages.U1N ? `U1N[${ newestMeasurement?.U1n_rms?.unit ?? "V" }]` : null,
				voltages.U2N ? `U2N[${ newestMeasurement?.U2n_rms?.unit ?? "V" }]` : null,
				voltages.U3N ? `U3N[${ newestMeasurement?.U3n_rms?.unit ?? "V" }]` : null,
				voltages.UNE ? `UE[${ newestMeasurement?.Une_rms?.unit ?? "V" }]` : null,
				voltages.U12 ? `U12[${ newestMeasurement?.U12_rms?.unit ?? "V" }]` : null,
				voltages.U23 ? `U23[${ newestMeasurement?.U23_rms?.unit ?? "V" }]` : null,
				voltages.U31 ? `U31[${ newestMeasurement?.U31_rms?.unit ?? "V" }]` : null,
				voltages.I1 ? `I1[${ newestMeasurement?.I1_rms?.unit ?? "A" }]` : null,
				voltages.I2 ? `I2[${ newestMeasurement?.I2_rms?.unit ?? "A" }]` : null,
				voltages.I3 ? `I3[${ newestMeasurement?.I3_rms?.unit ?? "A" }]` : null,
				voltages.L1_P ? `PL1[${ newestMeasurement?.L1_P?.unit ?? "W" }]` : null,
				voltages.L2_P ? `PL2[${ newestMeasurement?.L2_P?.unit ?? "W" }]` : null,
				voltages.L3_P ? `PL3[${ newestMeasurement?.L3_P?.unit ?? "W" }]` : null,
				voltages.L1_Q ? `QL1[${ newestMeasurement?.L1_Q?.unit ?? "var" }]` : null,
				voltages.L2_Q ? `QL2[${ newestMeasurement?.L2_Q?.unit ?? "var" }]` : null,
				voltages.L3_Q ? `QL3[${ newestMeasurement?.L3_Q?.unit ?? "var" }]` : null,
				voltages.L1_S ? `SL1[${ newestMeasurement?.L1_S?.unit ?? "var" }]` : null,
				voltages.L2_S ? `SL2[${ newestMeasurement?.L2_S?.unit ?? "var" }]` : null,
				voltages.L3_S ? `SL3[${ newestMeasurement?.L3_S?.unit ?? "var" }]` : null,
			].filter(isNotNull),
			...timeplotData
				.map(dataItem =>
					[
						moment(dataItem.date).format("YYYY-MM-DDTHH:mm:ss.SSS"),
						voltages.FREQUENCY ? `"${ (dataItem.frequency200ms?.value ?? 0).toFixed(6).replace(".", ",") }"` : null,
						voltages.U1N ? `"${ (dataItem.U1n_rms?.value ?? 0).toFixed(6).replace(".", ",") }"` : null,
						voltages.U2N ? `"${ (dataItem.U2n_rms?.value ?? 0).toFixed(6).replace(".", ",") }"` : null,
						voltages.U3N ? `"${ (dataItem.U3n_rms?.value ?? 0).toFixed(6).replace(".", ",") }"` : null,
						voltages.UNE ? `"${ (dataItem.Une_rms?.value ?? 0).toFixed(6).replace(".", ",") }"` : null,
						voltages.U12 ? `"${ (dataItem.U12_rms?.value ?? 0).toFixed(6).replace(".", ",") }"` : null,
						voltages.U23 ? `"${ (dataItem.U23_rms?.value ?? 0).toFixed(6).replace(".", ",") }"` : null,
						voltages.U31 ? `"${ (dataItem.U31_rms?.value ?? 0).toFixed(6).replace(".", ",") }"` : null,
						voltages.I1 ? `"${ (dataItem.I1_rms?.value ?? 0).toFixed(6).replace(".", ",") }"` : null,
						voltages.I2 ? `"${ (dataItem.I2_rms?.value ?? 0).toFixed(6).replace(".", ",") }"` : null,
						voltages.I3 ? `"${ (dataItem.I3_rms?.value ?? 0).toFixed(6).replace(".", ",") }"` : null,
						voltages.L1_P ? `"${ (dataItem.L1_P?.value ?? 0).toFixed(6).replace(".", ",") }"` : null,
						voltages.L2_P ? `"${ (dataItem.L2_P?.value ?? 0).toFixed(6).replace(".", ",") }"` : null,
						voltages.L3_P ? `"${ (dataItem.L3_P?.value ?? 0).toFixed(6).replace(".", ",") }"` : null,
						voltages.L1_Q ? `"${ (dataItem.L1_Q?.value ?? 0).toFixed(6).replace(".", ",") }"` : null,
						voltages.L2_Q ? `"${ (dataItem.L2_Q?.value ?? 0).toFixed(6).replace(".", ",") }"` : null,
						voltages.L3_Q ? `"${ (dataItem.L3_Q?.value ?? 0).toFixed(6).replace(".", ",") }"` : null,
						voltages.L1_S ? `"${ (dataItem.L1_S?.value ?? 0).toFixed(6).replace(".", ",") }"` : null,
						voltages.L2_S ? `"${ (dataItem.L2_S?.value ?? 0).toFixed(6).replace(".", ",") }"` : null,
						voltages.L3_S ? `"${ (dataItem.L3_S?.value ?? 0).toFixed(6).replace(".", ",") }"` : null,
					]
						.filter(isNotNull),
				),
		];

		let csvContent = "data:text/csv;charset=utf-8,"
			+ rows.map(e => e.join(",")).join("\n");

		const encodedUri = encodeURI(csvContent);
		let link = document.createElement("a");

		link.setAttribute("href", encodedUri);
		link.setAttribute("download", `timeplot_${ moment().format("YYYY-MM-DDTHH:mm:ss.SSS") }.csv`);

		document.body.appendChild(link);

		link.click();

		document.body.removeChild(link);
	};

	const isTableLoading = isNull(newestMeasurement);

	return (
		<div className="flex flex-col gap-4">
			<Button.Group>
				{
					Object.values(LayoutVariant).map(layoutVariant =>
						<Button
							key={ layoutVariant }
							color={ variant === layoutVariant ? "primary" : "gray" }
							onClick={ () => {
								if (variant === LayoutVariant.TABLE) {
									setActiveTab(TimeplotVariant.UNITED);
								}
								setVariant(layoutVariant);
							} }
							pill
							size="sm"
						>
							{ layoutVariantDictionary()[ layoutVariant ] }
						</Button>,
					)
				}
			</Button.Group>
			<div className="grid grid-cols-3 gap-4">
				<div
					className={
						classNames(
							{ "col-span-2": variant === LayoutVariant.ALL },
							{ "col-span-3": variant === LayoutVariant.CHART },
						)
					}
				>
					{
						variant !== LayoutVariant.TABLE &&
                        <Card className="[&>div]:p-0 [&>div]:gap-0">
                            <div className="p-3 sm:p-6 flex justify-between items-center gap-2 pb-4 border-b border-gray-200">
                                <h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
									{ t("SIDEBAR.timeplot") }
                                </h5>
                                <Dropdown
                                    size="sm"
                                    color="transparent"
                                    label={
										<>
											<MdOutlineFileUpload className="mr-2 h-4 w-4 text-gray-600 dark:text-dark-textGray"/>
											{ t("UTILS.export") }
										</>
									}
                                >
                                    <DropdownItem
                                        onClick={ () => {
											const sortedRecords = timeplotData.sort((a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf());
											const name = `PQM750_timeplot_${ timeplotVariantDictionary()[ activeTab ].toLowerCase() }_${ moment(sortedRecords[ 0 ].timestamp).format("MM-DD-HH-mm-ss") }_${ moment(sortedRecords[ sortedRecords.length - 1 ].timestamp).format("MM-DD-HH-mm-ss") }`;
											saveStockchart(name, cardRef.current);
										} }
                                    >
										{ t("UTILS.png") }
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={ _exportToCsv }
                                    >
										{ t("UTILS.csv") }
                                    </DropdownItem>
                                </Dropdown>
                            </div>
                            <Tabs
                                style="underline"
                                onActiveTabChange={ setActiveTab }
                            >
                                <Tabs.Item title={ t("TIMEPLOT.united") }>

                                </Tabs.Item>
                                <Tabs.Item title={ t("TIMEPLOT.splitted") }>

                                </Tabs.Item>
                            </Tabs>
                            <div className="p-3 sm:p-6 flex flex-col gap-4">
								{
									(activeTab === TimeplotVariant.UNITED && isInitialSetupSet) &&
                                    <div className="flex items-center justify-between flex-col gap-2.5">
                                        <div className="flex items-center justify-center gap-x-8 gap-y-2.5 flex-wrap">
											{
												(isDisplayingU || isDisplayingCrossU) &&
                                                <div className="flex gap-2.5 justify-center items-center flex-wrap">
                                                    <span className="text-sm text-gray-500 font-medium">{ t("UTILS.voltages") }: </span>
                                                    <div className="flex gap-2.5 items-center">
														{
															isU1n &&
                                                            <ChartFilter
                                                                text="U1N"
                                                                color={ ChartFilterColor.U1 }
                                                                active={ voltages.U1N }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.U1N ]: !prevState.U1N,
																	}))
																}
                                                            />
														}
														{
															isU2n &&
                                                            <ChartFilter
                                                                text="U2N"
                                                                color={ ChartFilterColor.U2 }
                                                                active={ voltages.U2N }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.U2N ]: !prevState.U2N,
																	}))
																}
                                                            />
														}
														{
															isU3n &&
                                                            <ChartFilter
                                                                text="U3N"
                                                                color={ ChartFilterColor.U3 }
                                                                active={ voltages.U3N }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.U3N ]: !prevState.U3N,
																	}))
																}
                                                            />
														}
														{
															isUne &&
                                                            <ChartFilter
                                                                text="UE"
                                                                color={ ChartFilterColor.Un }
                                                                active={ voltages.UNE }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.UNE ]: !prevState.UNE,
																	}))
																}
                                                            />
														}
                                                    </div>
                                                    <div className="flex gap-2.5 items-center">
														{
															isU12 &&
                                                            <ChartFilter
                                                                text="U12"
                                                                color={ ChartFilterColor.U12 }
                                                                active={ voltages.U12 }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.U12 ]: !prevState.U12,
																	}))
																}
                                                            />
														}
														{
															isU23 &&
                                                            <ChartFilter
                                                                text="U23"
                                                                color={ ChartFilterColor.U23 }
                                                                active={ voltages.U23 }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.U23 ]: !prevState.U23,
																	}))
																}
                                                            />
														}
														{
															isU31 &&
                                                            <ChartFilter
                                                                text="U31"
                                                                color={ ChartFilterColor.U31 }
                                                                active={ voltages.U31 }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.U31 ]: !prevState.U31,
																	}))
																}
                                                            />
														}
                                                    </div>
                                                </div>
											}
											{
												isDisplayingI &&
                                                <div className="flex gap-2.5 items-center">
                                                    <span className="text-sm text-gray-500 font-medium">{ t("UTILS.currents") }: </span>
													{
														isI1 &&
                                                        <ChartFilter
                                                            text="I1"
                                                            color={ ChartFilterColor.I1 }
                                                            active={ voltages.I1 }
                                                            onClick={ () =>
																setVoltages(prevState => ({
																	...prevState,
																	[ TimeplotLine.I1 ]: !prevState.I1,
																}))
															}
                                                        />
													}
													{
														isI2 &&
                                                        <ChartFilter
                                                            text="I2"
                                                            color={ ChartFilterColor.I2 }
                                                            active={ voltages.I2 }
                                                            onClick={ () =>
																setVoltages(prevState => ({
																	...prevState,
																	[ TimeplotLine.I2 ]: !prevState.I2,
																}))
															}
                                                        />
													}
													{
														isI3 &&
                                                        <ChartFilter
                                                            text="I3"
                                                            color={ ChartFilterColor.I3 }
                                                            active={ voltages.I3 }
                                                            onClick={ () =>
																setVoltages(prevState => ({
																	...prevState,
																	[ TimeplotLine.I3 ]: !prevState.I3,
																}))
															}
                                                        />
													}
                                                </div>
											}
                                        </div>
                                        <div className="flex items-center justify-center gap-x-8 gap-y-2.5 flex-wrap">
											{
												isDisplayingP &&
                                                <div className="flex gap-2.5 items-center">
                                                    <span className="text-sm text-gray-500 font-medium">{ t("UTILS.active powers") }: </span>
													{
														isL1p &&
                                                        <ChartFilter
                                                            text="PL1"
                                                            color={ ChartFilterColor.L1P }
                                                            active={ voltages.L1_P }
                                                            onClick={ () =>
																setVoltages(prevState => ({
																	...prevState,
																	[ TimeplotLine.L1_P ]: !prevState.L1_P,
																}))
															}
                                                        />
													}
													{
														isL2p &&
                                                        <ChartFilter
                                                            text="PL2"
                                                            color={ ChartFilterColor.L2P }
                                                            active={ voltages.L2_P }
                                                            onClick={ () =>
																setVoltages(prevState => ({
																	...prevState,
																	[ TimeplotLine.L2_P ]: !prevState.L2_P,
																}))
															}
                                                        />
													}
													{
														isL3p &&
                                                        <ChartFilter
                                                            text="PL3"
                                                            color={ ChartFilterColor.L3P }
                                                            active={ voltages.L3_P }
                                                            onClick={ () =>
																setVoltages(prevState => ({
																	...prevState,
																	[ TimeplotLine.L3_P ]: !prevState.L3_P,
																}))
															}
                                                        />
													}
                                                </div>
											}
											{
												isDisplayingQ &&
                                                <div className="flex gap-2.5 items-center">
                                                    <span className="text-sm text-gray-500 font-medium">{ t("UTILS.reactive powers") }: </span>
													{
														isL1q &&
                                                        <ChartFilter
                                                            text="QL1"
                                                            color={ ChartFilterColor.L1Q }
                                                            active={ voltages.L1_Q }
                                                            onClick={ () =>
																setVoltages(prevState => ({
																	...prevState,
																	[ TimeplotLine.L1_Q ]: !prevState.L1_Q,
																}))
															}
                                                        />
													}
													{
														isL2q &&
                                                        <ChartFilter
                                                            text="QL2"
                                                            color={ ChartFilterColor.L2Q }
                                                            active={ voltages.L2_Q }
                                                            onClick={ () =>
																setVoltages(prevState => ({
																	...prevState,
																	[ TimeplotLine.L2_Q ]: !prevState.L2_Q,
																}))
															}
                                                        />
													}
													{
														isL3q &&
                                                        <ChartFilter
                                                            text="QL3"
                                                            color={ ChartFilterColor.L3Q }
                                                            active={ voltages.L3_Q }
                                                            onClick={ () =>
																setVoltages(prevState => ({
																	...prevState,
																	[ TimeplotLine.L3_Q ]: !prevState.L3_Q,
																}))
															}
                                                        />
													}
                                                </div>
											}
                                        </div>
                                        <div className="flex items-center justify-center gap-x-8 gap-y-2.5 flex-wrap">
											{
												isDisplayingS &&
                                                <div className="flex gap-2.5 items-center">
                                                    <span className="text-sm text-gray-500 font-medium">{ t("UTILS.apparent powers") }: </span>
													{
														isL1s &&
                                                        <ChartFilter
                                                            text="SL1"
                                                            color={ ChartFilterColor.L1S }
                                                            active={ voltages.L1_S }
                                                            onClick={ () =>
																setVoltages(prevState => ({
																	...prevState,
																	[ TimeplotLine.L1_S ]: !prevState.L1_S,
																}))
															}
                                                        />
													}
													{
														isL2s &&
                                                        <ChartFilter
                                                            text="SL2"
                                                            color={ ChartFilterColor.L2S }
                                                            active={ voltages.L2_S }
                                                            onClick={ () =>
																setVoltages(prevState => ({
																	...prevState,
																	[ TimeplotLine.L2_S ]: !prevState.L2_S,
																}))
															}
                                                        />
													}
													{
														isL3s &&
                                                        <ChartFilter
                                                            text="SL3"
                                                            color={ ChartFilterColor.L3S }
                                                            active={ voltages.L3_S }
                                                            onClick={ () =>
																setVoltages(prevState => ({
																	...prevState,
																	[ TimeplotLine.L3_S ]: !prevState.L3_S,
																}))
															}
                                                        />
													}
                                                </div>
											}
											{
												isDisplayingFreq &&
                                                <div className="flex gap-2.5 items-center">
                                                    <span className="text-sm text-gray-500 font-medium">{ t("UTILS.frequency") }: </span>
													{
														isFrequency &&
                                                        <ChartFilter
                                                            text={ t("UTILS.frequency") }
                                                            color={ ChartFilterColor.frequency }
                                                            active={ voltages.FREQUENCY }
                                                            onClick={ () =>
																setVoltages(prevState => ({
																	...prevState,
																	[ TimeplotLine.FREQUENCY ]: !prevState.FREQUENCY,
																}))
															}
                                                        />
													}
                                                </div>
											}
                                        </div>
                                        <div className="ml-auto flex flex-col gap-2">
                                            <Button
                                                color="transparent"
                                                className="p-0 [&>span]:p-2"
                                                onClick={ () => setIndex(prevState => prevState + 1) }
                                            >
                                                <GrRotateLeft className="w-5 h-5 mr-2"/>
												{ t("TIMEPLOT.reset position") }
                                            </Button>
                                        </div>
                                    </div>
								}
                                <div ref={ cardRef }>
                                    <div
                                        onMouseEnter={ () => {
											if (isNotNull(main)) {
												const scrollWidth = main.offsetWidth - main.clientWidth;
												main.style.overflow = "hidden";
												main.style.paddingRight = `${ scrollWidth + MAIN_PADDING }px`;
											}
										} }
                                        onMouseLeave={ () => {
											if (isNotNull(main)) {
												main.style.overflow = "hidden auto";
												main.style.paddingRight = `${ MAIN_PADDING }px`;
											}
										} }
                                        style={ {
											position: "relative",
											width: cardWidth,
											height: plotHeight,
										} }
                                    >
										{
											(activeTab === TimeplotVariant.SPLITTED && isInitialSetupSet) &&
                                            <>
												{
													isDisplayingU &&
                                                    <div className="flex gap-2.5 items-center absolute left-1/2 -translate-x-1/2 z-10" style={ { top: _getTopPosition(0) } }>
                                                        <span className="text-sm text-gray-500 font-medium">{ t("UTILS.voltages") }: </span>
														{
															isU1n &&
                                                            <ChartFilter
                                                                text="U1N"
                                                                color={ ChartFilterColor.U1 }
                                                                active={ voltages.U1N }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.U1N ]: !prevState.U1N,
																	}))
																}
                                                            />
														}
														{
															isU2n &&
                                                            <ChartFilter
                                                                text="U2N"
                                                                color={ ChartFilterColor.U2 }
                                                                active={ voltages.U2N }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.U2N ]: !prevState.U2N,
																	}))
																}
                                                            />
														}
														{
															isU3n &&
                                                            <ChartFilter
                                                                text="U3N"
                                                                color={ ChartFilterColor.U3 }
                                                                active={ voltages.U3N }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.U3N ]: !prevState.U3N,
																	}))
																}
                                                            />
														}
														{
															isUne &&
                                                            <ChartFilter
                                                                text="UE"
                                                                color={ ChartFilterColor.Un }
                                                                active={ voltages.UNE }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.UNE ]: !prevState.UNE,
																	}))
																}
                                                            />
														}
                                                    </div>
												}
												{
													isDisplayingCrossU &&
                                                    <div className="flex gap-2.5 items-center absolute left-1/2 -translate-x-1/2 z-10" style={ { top: _getTopPosition(1) } }>
                                                        <span className="text-sm text-gray-500 font-medium">{ t("UTILS.voltages") }: </span>
														{
															isU12 &&
                                                            <ChartFilter
                                                                text="U12"
                                                                color={ ChartFilterColor.U12 }
                                                                active={ voltages.U12 }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.U12 ]: !prevState.U12,
																	}))
																}
                                                            />
														}
														{
															isU23 &&
                                                            <ChartFilter
                                                                text="U23"
                                                                color={ ChartFilterColor.U23 }
                                                                active={ voltages.U23 }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.U23 ]: !prevState.U23,
																	}))
																}
                                                            />
														}
														{
															isU31 &&
                                                            <ChartFilter
                                                                text="U31"
                                                                color={ ChartFilterColor.U31 }
                                                                active={ voltages.U31 }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.U31 ]: !prevState.U31,
																	}))
																}
                                                            />
														}
                                                    </div>
												}
												{
													isDisplayingI &&
                                                    <div className="flex gap-2.5 items-center absolute left-1/2 -translate-x-1/2 z-10" style={ { top: _getTopPosition(2) } }>
                                                        <span className="text-sm text-gray-500 font-medium">{ t("UTILS.currents") }: </span>
														{
															isI1 &&
                                                            <ChartFilter
                                                                text="I1"
                                                                color={ ChartFilterColor.I1 }
                                                                active={ voltages.I1 }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.I1 ]: !prevState.I1,
																	}))
																}
                                                            />
														}
														{
															isI2 &&
                                                            <ChartFilter
                                                                text="I2"
                                                                color={ ChartFilterColor.I2 }
                                                                active={ voltages.I2 }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.I2 ]: !prevState.I2,
																	}))
																}
                                                            />
														}
														{
															isI3 &&
                                                            <ChartFilter
                                                                text="I3"
                                                                color={ ChartFilterColor.I3 }
                                                                active={ voltages.I3 }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.I3 ]: !prevState.I3,
																	}))
																}
                                                            />
														}
                                                    </div>
												}
												{
													isDisplayingP &&
                                                    <div className="flex gap-2.5 items-center absolute left-1/2 -translate-x-1/2 z-10" style={ { top: _getTopPosition(3) } }>
                                                        <span className="text-sm text-gray-500 font-medium">{ t("UTILS.active powers") }: </span>
														{
															isL1p &&
                                                            <ChartFilter
                                                                text="PL1"
                                                                color={ ChartFilterColor.L1P }
                                                                active={ voltages.L1_P }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.L1_P ]: !prevState.L1_P,
																	}))
																}
                                                            />
														}
														{
															isL2p &&
                                                            <ChartFilter
                                                                text="PL2"
                                                                color={ ChartFilterColor.L2P }
                                                                active={ voltages.L2_P }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.L2_P ]: !prevState.L2_P,
																	}))
																}
                                                            />
														}
														{
															isL3p &&
                                                            <ChartFilter
                                                                text="PL3"
                                                                color={ ChartFilterColor.L3P }
                                                                active={ voltages.L3_P }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.L3_P ]: !prevState.L3_P,
																	}))
																}
                                                            />
														}
                                                    </div>
												}
												{
													isDisplayingQ &&
                                                    <div className="flex gap-2.5 items-center absolute left-1/2 -translate-x-1/2 z-10" style={ { top: _getTopPosition(4) } }>
                                                        <span className="text-sm text-gray-500 font-medium">{ t("UTILS.reactive powers") }: </span>
														{
															isL1q &&
                                                            <ChartFilter
                                                                text="QL1"
                                                                color={ ChartFilterColor.L1Q }
                                                                active={ voltages.L1_Q }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.L1_Q ]: !prevState.L1_Q,
																	}))
																}
                                                            />
														}
														{
															isL2q &&
                                                            <ChartFilter
                                                                text="QL2"
                                                                color={ ChartFilterColor.L2Q }
                                                                active={ voltages.L2_Q }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.L2_Q ]: !prevState.L2_Q,
																	}))
																}
                                                            />
														}
														{
															isL3q &&
                                                            <ChartFilter
                                                                text="QL3"
                                                                color={ ChartFilterColor.L3Q }
                                                                active={ voltages.L3_Q }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.L3_Q ]: !prevState.L3_Q,
																	}))
																}
                                                            />
														}
                                                    </div>
												}
												{
													isDisplayingS &&
                                                    <div className="flex gap-2.5 items-center absolute left-1/2 -translate-x-1/2 z-10" style={ { top: _getTopPosition(5) } }>
                                                        <span className="text-sm text-gray-500 font-medium">{ t("UTILS.apparent powers") }: </span>
														{
															isL1s &&
                                                            <ChartFilter
                                                                text="SL1"
                                                                color={ ChartFilterColor.L1S }
                                                                active={ voltages.L1_S }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.L1_S ]: !prevState.L1_S,
																	}))
																}
                                                            />
														}
														{
															isL2s &&
                                                            <ChartFilter
                                                                text="SL2"
                                                                color={ ChartFilterColor.L2S }
                                                                active={ voltages.L2_S }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.L2_S ]: !prevState.L2_S,
																	}))
																}
                                                            />
														}
														{
															isL3s &&
                                                            <ChartFilter
                                                                text="SL3"
                                                                color={ ChartFilterColor.L3S }
                                                                active={ voltages.L3_S }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.L3_S ]: !prevState.L3_S,
																	}))
																}
                                                            />
														}
                                                    </div>
												}
												{
													isDisplayingFreq &&
                                                    <div className="flex gap-2.5 items-center absolute left-1/2 -translate-x-1/2 z-10" style={ { top: _getTopPosition(6) } }>
                                                        <span className="text-sm text-gray-500 font-medium">{ t("UTILS.frequency") }: </span>
														{
															isFrequency &&
                                                            <ChartFilter
                                                                text={ t("UTILS.frequency") }
                                                                color={ ChartFilterColor.frequency }
                                                                active={ voltages.FREQUENCY }
                                                                onClick={ () =>
																	setVoltages(prevState => ({
																		...prevState,
																		[ TimeplotLine.FREQUENCY ]: !prevState.FREQUENCY,
																	}))
																}
                                                            />
														}
                                                    </div>
												}
                                            </>
										}
                                        <TimeplotChart
                                            key={ cardWidth }
                                            width={ cardWidth }
                                            height={ plotHeight }
                                            config={ voltages }
                                            data={ timeplotData }
                                            index={ index }
                                            isSplitted={ activeTab === TimeplotVariant.SPLITTED }
                                            basicConfig={ {
												U1n_rms: isU1n,
												U2n_rms: isU2n,
												U3n_rms: isU3n,
												Une_rms: isUne,
												U12_rms: isU12,
												U23_rms: isU23,
												U31_rms: isU31,
												frequency200ms: isFrequency,
												I1_rms: isI1,
												I2_rms: isI2,
												I3_rms: isI3,
												In_rms: isIn,
												Ie_rms: isIe,
												L1_P: isL1p,
												L2_P: isL2p,
												L3_P: isL3p,
												Tot_P: false,
												L1_Q: isL1q,
												L2_Q: isL2q,
												L3_Q: isL3q,
												Tot_Q: false,
												L1_S: isL1s,
												L2_S: isL2s,
												L3_S: isL3s,
												Tot_S: false,
											} }
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
					}
				</div>
				<div
					className={
						classNames(
							{ "col-span-3": variant === LayoutVariant.TABLE },
							{ "hidden": variant === LayoutVariant.CHART },
						)
					}
				>
					<Table
						className="[&>div]:border-b-0"
						title={ t("TABLE.actual values") }
						options={ {
							filter: false,
							search: false,
							pagination: false,
							setRowProps: (_, dataIndex) => {
								if (isTableLoading) return {};
								const category = data[ dataIndex ]?.category;
								if (isNull(category)) return {};
								return {
									className: measurementCategoryRowClassNameDictionary()[ category ],
								};
							},
						} }
						isLoading={ isTableLoading }
						cellPadding={ 0 }
						columns={ [
							{
								name: "Parameter",
								label: t("TABLE.parameter").toUpperCase(),
								options: {
									setCellProps: () => ({
										// className: "[&:not(div)]:border-r [&:not(div)]:border-r-[#e5e7eb]",
									}),
									customBodyRender: (parameter: TableRecord) =>
										<div>
											<div className={ `!p-2 ${ measurementCategoryCellClassNameDictionary()[ parameter.category ] }` }>
												{ parameter.name }
											</div>
										</div>,
									sort: false,
									filter: false,
								},
							}, {
								name: "Value",
								label: t("TABLE.value").toUpperCase(),
								options: {
									customBodyRender: (parameter: TableRecord) => (
										<span>
											{ parameter.value !== null && parameter.value !== undefined ? parameter.value : "---" }
										</span>
									),
									sort: false,
									filter: false,
								},
							}, {
								name: "Unit",
								label: t("TABLE.unit").toUpperCase(),
								options: {
									customBodyRender: (parameter: TableRecord) => parameter.unit ?? "---",
									sort: false,
									filter: false,
								},
							},
						] }
						data={ data }
					/>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	bodySize: state.ui.layout.bodySize,
	isSidebarOpen: state.ui.layout.isSidebarOpen,
});

export default connect(mapStateToProps)(TimeplotContainer);
