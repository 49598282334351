import { FormItem } from "src/app/types/ui/form.types";
import { Enum, EnumOptionsSetting } from "src/app/types/util.types";
import { getOptionMask, isOptionsContainsOption, isOptionSelected } from "src/app/utils/helpers";
import { isEmptyArray } from "src/app/utils/typeguards";
import { ToggleSwitch } from "flowbite-react";
import classNames from "classnames";

type Props<T extends Enum> = {
	formItem: FormItem<number>
	label: string
	options: EnumOptionsSetting<T>["options"]
	option: T
	handleChange: (value: number) => void
	hasBeenChanged?: boolean
	dependingOn?: T[]
};

function EnumOptionsToggle<T extends Enum>(props: Props<T>) {

	const {
		formItem,
		label,
		options,
		option,
		handleChange,
		hasBeenChanged = false,
		dependingOn = [],
	} = props;

	if (!isOptionsContainsOption(option, options)) return null;

	const isSelected = isOptionSelected(formItem.value, option, options);

	const isDisabled = !isEmptyArray(dependingOn) && !options.filter(settingOption => dependingOn.includes(settingOption.text)).every(settingOption => isOptionSelected(formItem.value, settingOption.text, options));

	return (
		<ToggleSwitch
			className={
				classNames(
					"[&>div]:w-9 [&>div]:h-5 [&>div]:after:absolute [&>div]:after:top-[2px] [&>div]:after:left-[2px] [&>div]:after:h-4 [&>div]:after:w-4",
					{ "[&>span]:!text-yellow-400": hasBeenChanged },
				)
			}
			checked={ isSelected }
			label={ label }
			disabled={ isDisabled || formItem.disabled }
			onChange={ () => {
				const mask = getOptionMask(option, options) ?? 0;
				if (isSelected) {
					handleChange(formItem.value - mask);
				} else {
					handleChange(formItem.value + mask);
				}
			} }
		/>
	);
}

export default (EnumOptionsToggle);
