// @ts-ignore
import { Chart, ChartCanvas } from "react-stockcharts";
import { Nullable } from "src/app/types/util.types";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { round, roundToFirstNonZeroValue } from "src/app/utils/helpers";
import { scaleRange } from "src/app/utils/chart";
import { scaleLinear } from "d3-scale";
// @ts-ignore
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
// @ts-ignore
import { Annotate } from "react-stockcharts/lib/annotation";
import { appColors } from "src/app/utils/constants/theme";
// @ts-ignore
import { LineSeries, StraightLine } from "react-stockcharts/lib/series";
import { EventWaveformConfigState } from "src/app/containers/Event/EventWaveform.container";
import ChartCurrentCoordinate from "src/app/components/Chart/Component/ChartCurrentCoordinate.component";
import ChartHoverTooltip from "src/app/components/Chart/Component/ChartHoverTooltip.component";
import LabelAnnotation from "src/app/components/Chart/Component/CustomLabelAnnotation.component";
import { formatValueToDeciSONlValue, formatValueToFullDeciSON } from "src/app/utils/dataFormatting";
import { MeasurementCategory } from "src/app/types/misc.types";

type Props = {
	width: number
	height: number
	data: EventWaveformChartData[]
	config: EventWaveformConfigState
	pretriggerIndex?: number
	endEventIndex?: number
	symetricYAxis?: boolean
};

export type EventWaveformChartData = {
	U1: Nullable<number>
	U2: Nullable<number>
	U3: Nullable<number>
	U1PE: Nullable<number>
	U2PE: Nullable<number>
	U3PE: Nullable<number>
	UNPE: Nullable<number>
	U12: Nullable<number>
	U23: Nullable<number>
	U31: Nullable<number>
	I1: Nullable<number>
	I2: Nullable<number>
	I3: Nullable<number>
	I4: Nullable<number>
	I5: Nullable<number>
	INS: Nullable<number>
	sample: number
	index: number
}

function OneSideEventWaveformChart(props: Props) {

	const {
		width,
		height,
		data,
		config,
		pretriggerIndex,
		endEventIndex,
		symetricYAxis = false,
	} = props;

	const marginLeft = 50;
	const marginRight = 55;
	const marginTop = 20;
	const marginBottom = 17;

	const xGrid = {
		innerTickSize: -1 * (width - 105),
		tickStrokeDasharray: "Dash",
		tickStrokeOpacity: 0.2,
		tickStrokeWidth: 1,
		tickStroke: "#030712",
		stroke: "#03071200",

		fontFamily: "Segoe UI Symbol, Segoe UI Emoji, ui-sans-serif, sans-serif",
		fontSize: 12,
		fontWeight: 500,
	};

	const rightXGrid = {
		innerTickSize: -1 * (width - 105),
		tickStrokeDasharray: "Dash",
		tickStrokeOpacity: 0,
		tickStrokeWidth: 1,
		tickStroke: "#030712",
		stroke: "#03071200",

		fontFamily: "Segoe UI Symbol, Segoe UI Emoji, ui-sans-serif, sans-serif",
		fontSize: 12,
		fontWeight: 500,
	};

	const yGrid = {
		innerTickSize: -1 * (height - marginTop - marginBottom),
		tickStrokeDasharray: "Dash",
		tickStrokeOpacity: 0.2,
		tickStrokeWidth: 1,

		fontFamily: "Segoe UI Symbol, Segoe UI Emoji, ui-sans-serif, sans-serif",
		fontSize: 12,
		fontWeight: 500,

		tickStroke: "#030712",
		stroke: "#03071200",
	};

	const _calculateYRange = (data: EventWaveformChartData[]): [ min: number, max: number ] => {
		const firstValue = data[ 0 ];
		if (isNull(firstValue)) return [ 0, 300 ];
		const firstMinValue = Math.min(
			...[
				config.U1 ? (data[ 0 ].U1 ?? 0) : null,
				config.U2 ? (data[ 0 ].U2 ?? 0) : null,
				config.U3 ? (data[ 0 ].U3 ?? 0) : null,
				config.U12 ? (data[ 0 ].U12 ?? 0) : null,
				config.U23 ? (data[ 0 ].U23 ?? 0) : null,
				config.U31 ? (data[ 0 ].U31 ?? 0) : null,
				config.U1PE ? (data[ 0 ].U1PE ?? 0) : null,
				config.U2PE ? (data[ 0 ].U2PE ?? 0) : null,
				config.U3PE ? (data[ 0 ].U3PE ?? 0) : null,
				config.UNPE ? (data[ 0 ].UNPE ?? 0) : null,
			].filter(isNotNull),
		);

		const firstMaxValue = Math.max(
			...[
				config.U1 ? (data[ 0 ].U1 ?? 0) : null,
				config.U2 ? (data[ 0 ].U2 ?? 0) : null,
				config.U3 ? (data[ 0 ].U3 ?? 0) : null,
				config.U12 ? (data[ 0 ].U12 ?? 0) : null,
				config.U23 ? (data[ 0 ].U23 ?? 0) : null,
				config.U31 ? (data[ 0 ].U31 ?? 0) : null,
				config.U1PE ? (data[ 0 ].U1PE ?? 0) : null,
				config.U2PE ? (data[ 0 ].U2PE ?? 0) : null,
				config.U3PE ? (data[ 0 ].U3PE ?? 0) : null,
				config.UNPE ? (data[ 0 ].UNPE ?? 0) : null,
			].filter(isNotNull),
		);

		const yAxisRange: [ number, number ] = data.reduce((prev, next) => [
			Math.min(
				prev[ 0 ],
				config.U1 ? (next.U1 ?? 0) : prev[ 0 ],
				config.U2 ? (next.U2 ?? 0) : prev[ 0 ],
				config.U3 ? (next.U3 ?? 0) : prev[ 0 ],
				config.U12 ? (next.U12 ?? 0) : prev[ 0 ],
				config.U23 ? (next.U23 ?? 0) : prev[ 0 ],
				config.U31 ? (next.U31 ?? 0) : prev[ 0 ],
				config.U1PE ? (next.U1PE ?? 0) : prev[ 0 ],
				config.U2PE ? (next.U2PE ?? 0) : prev[ 0 ],
				config.U3PE ? (next.U3PE ?? 0) : prev[ 0 ],
				config.UNPE ? (next.UNPE ?? 0) : prev[ 0 ],
			),
			Math.max(
				prev[ 1 ],
				config.U1 ? (next.U1 ?? 0) : prev[ 1 ],
				config.U2 ? (next.U2 ?? 0) : prev[ 1 ],
				config.U3 ? (next.U3 ?? 0) : prev[ 1 ],
				config.U12 ? (next.U12 ?? 0) : prev[ 1 ],
				config.U23 ? (next.U23 ?? 0) : prev[ 1 ],
				config.U31 ? (next.U31 ?? 0) : prev[ 1 ],
				config.U1PE ? (next.U1PE ?? 0) : prev[ 1 ],
				config.U2PE ? (next.U2PE ?? 0) : prev[ 1 ],
				config.U3PE ? (next.U3PE ?? 0) : prev[ 1 ],
				config.UNPE ? (next.UNPE ?? 0) : prev[ 1 ],
			),
		], [ firstMinValue, firstMaxValue ]);

		const ceiledAxisRange: [ number, number ] = symetricYAxis ? [
			roundToFirstNonZeroValue(yAxisRange[ 0 ], Math.floor),
			roundToFirstNonZeroValue(-1 * yAxisRange[ 0 ], Math.ceil),
		] : [
			roundToFirstNonZeroValue(yAxisRange[ 0 ], Math.floor),
			roundToFirstNonZeroValue(yAxisRange[ 1 ], Math.ceil),
		];

		return scaleRange(ceiledAxisRange);
	};

	const _calculateYRightRange = (data: EventWaveformChartData[]): [ min: number, max: number ] => {
		const firstValue = data[ 0 ];
		if (isNull(firstValue)) return [ 0, 500 ];
		const firstMinValue = Math.min(
			...[
				config.I1 ? (data[ 0 ].I1 ?? 0) : null,
				config.I2 ? (data[ 0 ].I2 ?? 0) : null,
				config.I3 ? (data[ 0 ].I3 ?? 0) : null,
				config.I4 ? (data[ 0 ].I4 ?? 0) : null,
				config.I5 ? (data[ 0 ].I5 ?? 0) : null,
				config.INS ? (data[ 0 ].INS ?? 0) : null,
			].filter(isNotNull),
		);
		const firstMaxValue = Math.max(
			...[
				config.I1 ? (data[ 0 ].I1 ?? 0) : null,
				config.I2 ? (data[ 0 ].I2 ?? 0) : null,
				config.I3 ? (data[ 0 ].I3 ?? 0) : null,
				config.I4 ? (data[ 0 ].I4 ?? 0) : null,
				config.I5 ? (data[ 0 ].I5 ?? 0) : null,
				config.INS ? (data[ 0 ].INS ?? 0) : null,
			].filter(isNotNull),
		);
		if (!isFinite(firstMaxValue) || !isFinite(firstMinValue)) return [ 0, 500 ];
		const yAxisRange: [ number, number ] = data.reduce((prev, next) => [
			Math.min(
				prev[ 0 ],
				config.I1 ? (next.I1 ?? 0) : prev[ 0 ],
				config.I2 ? (next.I2 ?? 0) : prev[ 0 ],
				config.I3 ? (next.I3 ?? 0) : prev[ 0 ],
				config.I4 ? (next.I4 ?? 0) : prev[ 0 ],
				config.I5 ? (next.I5 ?? 0) : prev[ 0 ],
				config.INS ? (next.INS ?? 0) : prev[ 0 ],
			),
			Math.max(
				prev[ 1 ],
				config.I1 ? (next.I1 ?? 0) : prev[ 1 ],
				config.I2 ? (next.I2 ?? 0) : prev[ 1 ],
				config.I3 ? (next.I3 ?? 0) : prev[ 1 ],
				config.I4 ? (next.I4 ?? 0) : prev[ 1 ],
				config.I5 ? (next.I5 ?? 0) : prev[ 1 ],
				config.INS ? (next.INS ?? 0) : prev[ 1 ],
			),
		], [ firstMinValue, firstMaxValue ]);

		const ceiledAxisRange: [ number, number ] = symetricYAxis ? [
			roundToFirstNonZeroValue(yAxisRange[ 0 ], Math.floor),
			roundToFirstNonZeroValue(-1 * yAxisRange[ 0 ], Math.ceil),
		] : [
			roundToFirstNonZeroValue(yAxisRange[ 0 ], Math.floor),
			roundToFirstNonZeroValue(yAxisRange[ 1 ], Math.ceil),
		];

		return scaleRange(ceiledAxisRange);
	};

	const legendDescriptionY = "-8";

	const yRange: [ number, number ] = _calculateYRange(data);
	const yRightRange: [ number, number ] = _calculateYRightRange(data);

	const isDisplayingTensions = config.U1 || config.U2 || config.U3 || config.U1PE || config.U2PE || config.U3PE || config.UNPE || config.U12 || config.U23 || config.U31;
	const isDisplayingIntensities = config.I1 || config.I2 || config.I3 || config.I4 || config.I5 || config.INS;

	const firstItem = data[ data.length - 1 ];
	const lastItem = data[ 0 ];

	return (
		<ChartCanvas
			ratio={ 1 }
			width={ width }
			height={ height }
			margin={ { left: marginLeft, right: marginRight, top: marginTop, bottom: marginBottom } }
			type="svg"
			seriesName="measurements"
			data={ data }
			xScale={ scaleLinear() }
			xAccessor={ (d: any) => d.index }
			mouseMoveEvent={ true }
			xExtents={ [ 0, data.length ] }
			pointsPerPxThreshold={ 20 }
			clamp
		>
			<Chart
				id="voltages-chart-U"
				yExtents={ yRange }
			>
				<text
					style={ {
						fill: "#030712",
					} }
					fontFamily="Segoe UI, sans-serif"
					fontSize={ 12 }
					fontWeight={ 600 }
					x={ width - 2 * marginLeft + 10 }
					y={ height - 23 }
				>
					[ms]
				</text>
				<XAxis
					axisAt="bottom"
					orient="bottom"
					ticks={ 10 }
					// tickValues={ [ 0, 2000, data.length - 1 ] } // todo: logic for calculate this

					tickFormat={ (index: number) => round((data[ index ].sample ?? 0) / 1000, 0).toString() }
					zoomEnabled={ false }
					{ ...yGrid }
				/>

				<YAxis
					axisAt="left"
					orient="left"
					ticks={ 6 }

					// tickFormat={ (v: number) => getSignificantDigitRound(v).toString() }
					tickFormat={ (v: number) => formatValueToDeciSONlValue(v, MeasurementCategory.VOLTAGES_WAVE)?.toString() ?? "" }
					zoomEnabled={ false }
					{ ...xGrid }
				/>
				{
					isDisplayingIntensities &&
                    <YAxis
                        axisAt="right"
                        orient="right"
                        ticks={ 6 }

                        tickFormat={ (x: number) => {
							const [ y1, y2 ] = yRightRange;
							const [ y3, y4 ] = yRange;
							// return getSignificantDigitRound(y1 + Math.abs(y1 - y2) * (Math.abs(x - y3) / Math.abs(y3 - y4))).toString();
							return formatValueToDeciSONlValue(y1 + Math.abs(y1 - y2) * (Math.abs(x - y3) / Math.abs(y3 - y4)), MeasurementCategory.CURRENTS_WAVE)?.toString() ?? "";
						} }
                        zoomEnabled={ false }
						{ ...rightXGrid }
                    />
				}
				{
					isNotNull(pretriggerIndex) &&
                    <>
                        <StraightLine
                            type="vertical"
                            stroke="red"
                            strokeWidth={ 3 }
                            opacity={ 1 }
                            xValue={ pretriggerIndex }
                        />
                        <Annotate
                            with={ LabelAnnotation }
                            when={ (d: EventWaveformChartData) => d.index === pretriggerIndex }
                            usingProps={ {
								y: 25,
								text: "S",
								fill: "red",
								fontSize: 32,
								textAnchor: "middle",
								opacity: 1,
								fontWeight: 700,
							} }
                        />
                    </>
				}
				{
					isNotNull(endEventIndex) &&
                    <>
                        <StraightLine
                            type="vertical"
                            stroke="red"
                            strokeWidth={ 3 }
                            opacity={ 1 }
                            xValue={ endEventIndex }
                        />
                        <Annotate
                            with={ LabelAnnotation }
                            when={ (d: EventWaveformChartData) => d.index === endEventIndex }
                            usingProps={ {
								y: 25,
								text: "E",
								fill: "red",
								fontSize: 32,
								textAnchor: "middle",
								opacity: 1,
								fontWeight: 700,
							} }
                        />
                    </>
				}
				{
					config.U1 &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: EventWaveformChartData) => d.U1 ?? undefined }
                            fill={ appColors.U1 }
                        />
                        <LineSeries
                            yAccessor={ (d: EventWaveformChartData) => d.U1 ?? undefined }
                            strokeWidth={ 2 }
                            stroke={ appColors.U1 }
                        />
                    </>
				}
				{
					config.U2 &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: EventWaveformChartData) => d.U2 ?? undefined }
                            fill={ appColors.U2 }
                        />
                        <LineSeries
                            yAccessor={ (d: EventWaveformChartData) => d.U2 ?? undefined }
                            strokeWidth={ 2 }
                            stroke={ appColors.U2 }
                        />
                    </>
				}
				{
					config.U3 &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: EventWaveformChartData) => d.U3 ?? undefined }
                            fill={ appColors.U3 }
                        />
                        <LineSeries
                            yAccessor={ (d: EventWaveformChartData) => d.U3 ?? undefined }
                            strokeWidth={ 2 }
                            stroke={ appColors.U3 }
                        />
                    </>
				}
				{
					config.U12 &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: EventWaveformChartData) => d.U12 ?? undefined }
                            fill={ appColors.U12 }
                        />
                        <LineSeries
                            yAccessor={ (d: EventWaveformChartData) => d.U12 ?? undefined }
                            strokeWidth={ 2 }
                            stroke={ appColors.U12 }
                        />
                    </>
				}
				{
					config.U23 &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: EventWaveformChartData) => d.U23 ?? undefined }
                            fill={ appColors.U23 }
                        />
                        <LineSeries
                            yAccessor={ (d: EventWaveformChartData) => d.U23 ?? undefined }
                            strokeWidth={ 2 }
                            stroke={ appColors.U23 }
                        />
                    </>
				}
				{
					config.U31 &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: EventWaveformChartData) => d.U31 ?? undefined }
                            fill={ appColors.U31 }
                        />
                        <LineSeries
                            yAccessor={ (d: EventWaveformChartData) => d.U31 ?? undefined }
                            strokeWidth={ 2 }
                            stroke={ appColors.U31 }
                        />
                    </>
				}
				{
					config.U1PE &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: EventWaveformChartData) => d.U1PE ?? undefined }
                            fill={ appColors.U1 } // fixme: color of
                        />
                        <LineSeries
                            yAccessor={ (d: EventWaveformChartData) => d.U1PE ?? undefined }
                            strokeWidth={ 2 }
                            stroke={ appColors.U1 } // fixme: color of
                        />
                    </>
				}
				{
					config.U2PE &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: EventWaveformChartData) => d.U2PE ?? undefined }
                            fill={ appColors.U2 } // fixme: color of
                        />
                        <LineSeries
                            yAccessor={ (d: EventWaveformChartData) => d.U2PE ?? undefined }
                            strokeWidth={ 2 }
                            stroke={ appColors.U2 } // fixme: color of
                        />
                    </>
				}
				{
					config.U3PE &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: EventWaveformChartData) => d.U3PE ?? undefined }
                            fill={ appColors.U3 } // fixme: color of
                        />
                        <LineSeries
                            yAccessor={ (d: EventWaveformChartData) => d.U3PE ?? undefined }
                            strokeWidth={ 2 }
                            stroke={ appColors.U3 } // fixme: color of
                        />
                    </>
				}
				{
					config.UNPE &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: EventWaveformChartData) => d.UNPE ?? undefined }
                            fill={ appColors.Un } // fixme: color of
                        />
                        <LineSeries
                            yAccessor={ (d: EventWaveformChartData) => d.UNPE ?? undefined }
                            strokeWidth={ 2 }
                            stroke={ appColors.Un } // fixme: color of
                        />
                    </>
				}
				{
					isDisplayingTensions &&
                    <>
                        <rect x="-50" y="-2" width={ 50 } height={ 16 } fill="white"/>
                        <text x="-30" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>U[V]</text>
                    </>
				}
			</Chart>
			<Chart
				id="voltages-chart-I"
				yExtents={ yRightRange }
			>
				{
					(!isDisplayingTensions && isDisplayingIntensities) &&
                    <YAxis
                        axisAt="right"
                        orient="right"
                        ticks={ 6 }

                        tickFormat={ (x: number) => formatValueToDeciSONlValue(x, MeasurementCategory.CURRENTS_WAVE)?.toString() ?? "" }
                        zoomEnabled={ false }
						{ ...xGrid }
                    />
				}
				{
					config.I1 &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: EventWaveformChartData) => d.I1 ?? undefined }
                            fill={ appColors.I1 }
                        />
                        <LineSeries
                            yAccessor={ (d: EventWaveformChartData) => d.I1 ?? undefined }
                            strokeWidth={ 2 }
                            stroke={ appColors.I1 }
                        />
                    </>
				}
				{
					config.I2 &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: EventWaveformChartData) => d.I2 ?? undefined }
                            fill={ appColors.I2 }
                        />
                        <LineSeries
                            yAccessor={ (d: EventWaveformChartData) => d.I2 ?? undefined }
                            strokeWidth={ 2 }
                            stroke={ appColors.I2 }
                        />
                    </>
				}
				{
					config.I3 &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: EventWaveformChartData) => d.I3 ?? undefined }
                            fill={ appColors.I3 }
                        />
                        <LineSeries
                            yAccessor={ (d: EventWaveformChartData) => d.I3 ?? undefined }
                            strokeWidth={ 2 }
                            stroke={ appColors.I3 }
                        />
                    </>
				}
				{
					config.I4 &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: EventWaveformChartData) => d.I4 ?? undefined }
                            fill={ appColors.In }
                        />
                        <LineSeries
                            yAccessor={ (d: EventWaveformChartData) => d.I4 ?? undefined }
                            strokeWidth={ 2 }
                            stroke={ appColors.In }
                        />
                    </>
				}
				{
					config.I5 &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: EventWaveformChartData) => d.I5 ?? undefined }
                            fill={ appColors.Ie }
                        />
                        <LineSeries
                            yAccessor={ (d: EventWaveformChartData) => d.I5 ?? undefined }
                            strokeWidth={ 2 }
                            stroke={ appColors.Ie }
                        />
                    </>
				}
				{
					config.INS &&
                    <>
                        <ChartCurrentCoordinate
                            yAccessor={ (d: EventWaveformChartData) => d.INS ?? undefined }
                            fill={ appColors.Ie } // fixme: color of
                        />
                        <LineSeries
                            yAccessor={ (d: EventWaveformChartData) => d.INS ?? undefined }
                            strokeWidth={ 2 }
                            stroke={ appColors.Ie } // fixme: color of
                        />
                    </>
				}
				{
					isDisplayingIntensities &&
                    <>
                        <rect x={ width - 2 * marginLeft + 5 } y="-2" width={ 50 } height={ 16 } fill="white"/>
                        <text x={ width - 2 * marginLeft + 5 } y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>I[A]</text>
                    </>
				}
				{
					(isDisplayingTensions || isDisplayingIntensities) &&
                    <ChartHoverTooltip
                        firstItem={ firstItem }
                        lastItem={ lastItem }
                        getTooltipContent={ (probeArgument: { currentItem: EventWaveformChartData }) => {
							const { currentItem } = probeArgument;
							return {
								x: `${ round(currentItem.sample / 1000, 2) } ms`,
								y: [
									(config.U1 && isNotNull(currentItem.U1)) ? {
										label: "U1",
										value: formatValueToFullDeciSON(currentItem.U1, "V", MeasurementCategory.VOLTAGES) ?? "",
									} : null,
									(config.U2 && isNotNull(currentItem.U2)) ? {
										label: "U2",
										value: formatValueToFullDeciSON(currentItem.U2, "V", MeasurementCategory.VOLTAGES) ?? "",
									} : null,
									(config.U3 && isNotNull(currentItem.U3)) ? {
										label: "U3",
										value: formatValueToFullDeciSON(currentItem.U3, "V", MeasurementCategory.VOLTAGES) ?? "",
									} : null,
									(config.U12 && isNotNull(currentItem.U12)) ? {
										label: "U12",
										value: formatValueToFullDeciSON(currentItem.U12, "V", MeasurementCategory.VOLTAGES) ?? "",
									} : null,
									(config.U23 && isNotNull(currentItem.U23)) ? {
										label: "U23",
										value: formatValueToFullDeciSON(currentItem.U23, "V", MeasurementCategory.VOLTAGES) ?? "",
									} : null,
									(config.U31 && isNotNull(currentItem.U31)) ? {
										label: "U31",
										value: formatValueToFullDeciSON(currentItem.U31, "V", MeasurementCategory.VOLTAGES) ?? "",
									} : null,
									(config.U1PE && isNotNull(currentItem.U1PE)) ? {
										label: "U1E",
										value: formatValueToFullDeciSON(currentItem.U1PE, "V", MeasurementCategory.VOLTAGES) ?? "",
									} : null,
									(config.U2PE && isNotNull(currentItem.U2PE)) ? {
										label: "U2E",
										value: formatValueToFullDeciSON(currentItem.U2PE, "V", MeasurementCategory.VOLTAGES) ?? "",
									} : null,
									(config.U3PE && isNotNull(currentItem.U3PE)) ? {
										label: "U3E",
										value: formatValueToFullDeciSON(currentItem.U3PE, "V", MeasurementCategory.VOLTAGES) ?? "",
									} : null,
									(config.UNPE && isNotNull(currentItem.UNPE)) ? {
										label: "UE",
										value: formatValueToFullDeciSON(currentItem.UNPE, "V", MeasurementCategory.VOLTAGES) ?? "",
									} : null,
									(config.I1 && isNotNull(currentItem.I1)) ? {
										label: "I1",
										value: formatValueToFullDeciSON(currentItem.I1, "A", MeasurementCategory.CURRENTS) ?? "",
									} : null,
									(config.I2 && isNotNull(currentItem.I2)) ? {
										label: "I2",
										value: formatValueToFullDeciSON(currentItem.I2, "A", MeasurementCategory.CURRENTS) ?? "",
									} : null,
									(config.I3 && isNotNull(currentItem.I3)) ? {
										label: "I3",
										value: formatValueToFullDeciSON(currentItem.I3, "A", MeasurementCategory.CURRENTS) ?? "",
									} : null,
									(config.I4 && isNotNull(currentItem.I4)) ? {
										label: "I4",
										value: formatValueToFullDeciSON(currentItem.I4, "A", MeasurementCategory.CURRENTS) ?? "",
									} : null,
									(config.I5 && isNotNull(currentItem.I5)) ? {
										label: "I5",
										value: formatValueToFullDeciSON(currentItem.I5, "A", MeasurementCategory.CURRENTS) ?? "",
									} : null,
									(config.INS && isNotNull(currentItem.INS)) ? {
										label: "INS",
										value: formatValueToFullDeciSON(currentItem.INS, "A", MeasurementCategory.CURRENTS) ?? "",
									} : null,
								].filter(isNotNull),
							};
						} }
                    />
				}
			</Chart>
		</ChartCanvas>
	);
}

export default (OneSideEventWaveformChart);
