import { PowerCalculationMethod, SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import ActivePowerCard from "src/app/components/MeasurementsUser/ActivePowerCard.component";
import ReactivePowerCard from "src/app/components/MeasurementsUser/ReactivePowerCard.component";
import ApparentPowerCard from "src/app/components/MeasurementsUser/ApparentPowerCard.component";
import DistortionPowerBudeanuCard from "src/app/components/MeasurementsUser/DistortionPowerBudeanuCard.component";
import ActivePowerPlusCard from "src/app/components/MeasurementsUser/ActivePowerPlusCard.component";
import ActivePowerMinusCard from "src/app/components/MeasurementsUser/ActivePowerMinusCard.component";
import ApparentPowerFundamentalCard from "src/app/components/MeasurementsUser/ApparentPowerFundamentalCard.component";
import ApparentPowerNonfundamentalCard from "src/app/components/MeasurementsUser/ApparentPowerNonfundamentalCard.component";
import { FormValidator } from "src/app/types/ui/form.types";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { createFormField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import { useContext, useEffect } from "react";
import Select from "../../Form/Select.component";
import { mapEnumSettingToSelectOptions } from "src/app/utils/helpers";
import { powerCalculationMethodDictionary } from "src/app/utils/constants/dictionaries";
import { isNull } from "src/app/utils/typeguards";
import { Button, Card } from "flowbite-react";
import ActivePowerFundamentalCard from "src/app/components/MeasurementsUser/ActivePowerFundamentalCard.component";
import { MeasurementsUserContext } from "src/app/components/Measurements/MeasurementsUserContent.component";
import { useTranslation } from "react-i18next";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsMeasurementsUser: SettingsMeasurementsUser
	};

type PowerAndEnergyForm = {
	cfgPowerCalculationMethod: number
}

const validator: FormValidator<PowerAndEnergyForm> = {
	cfgPowerCalculationMethod: () => null,
};

function PowerAndEnergyPowerTab(props: Props) {

	const { t } = useTranslation();

	const {
		settingsMeasurementsUser,
		settingsMeasurementsUser: {
			measurementUser,
		},
		isAdmin,
	} = props;

	const { cfgUserEnable } = useContext(MeasurementsUserContext);

	const reducerForm = useReducerForm(
		"buffor",
		bufforFormActions,
		() => undefined,
	);

	const _handleSubmit = (values: PowerAndEnergyForm) => {
		reducerForm.handleChange("cfgPowerCalculationMethod", values.cfgPowerCalculationMethod);
	};

	const _getInitialState = () => ({
		cfgPowerCalculationMethod: createFormField(reducerForm.form.cfgPowerCalculationMethod.value !== reducerForm.form.cfgPowerCalculationMethod.initialValue ? reducerForm.form.cfgPowerCalculationMethod.value : measurementUser?.cfgPowerCalculationMethod?.value ?? 0, { disabled: !isAdmin }),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ measurementUser ]);

	useEffect(() => {
		handleChange("cfgPowerCalculationMethod", reducerForm.form.cfgPowerCalculationMethod.value);
	}, [
		reducerForm.form.cfgPowerCalculationMethod.value,
	]);

	return (
		<div className="pt-0 p-4 flex flex-col gap-3">
			<Card className="bg-gray-50">
				<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
					{ t('POWER.reactive power') }
				</h5>
				<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
					<Select
						label=""
						options={ mapEnumSettingToSelectOptions(measurementUser?.cfgPowerCalculationMethod, powerCalculationMethodDictionary) }
						formItem={ form.cfgPowerCalculationMethod }
						onChange={ option => {
							if (isNull(option)) return;

							handleChange("cfgPowerCalculationMethod", option?.value);
						} }
						isSearchable={ false }
						isClearable={ false }
						isDisabled={ !cfgUserEnable }
						hasBeenChanged={ reducerForm.form.cfgPowerCalculationMethod.value !== reducerForm.form.cfgPowerCalculationMethod.initialValue }
					/>
					{
						isAdmin &&
                        <div className="flex justify-end items-center gap-2">
                            <Button
                                color="primary"
                                type="submit"
                            >
								{ t('UTILS.save') }
                            </Button>
                        </div>
					}
				</form>
			</Card>
			<ActivePowerCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<ActivePowerPlusCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<ActivePowerMinusCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<ActivePowerFundamentalCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			{
				measurementUser?.cfgPowerCalculationMethod?.enum?.find(enumOption => enumOption.value === form.cfgPowerCalculationMethod.value)?.text === PowerCalculationMethod.POWER_METHOD_IEEE1459 &&
                <ReactivePowerCard
                    title={ t('POWER.reactive power Q1') }
                    settingsMeasurementsUser={ settingsMeasurementsUser }
                />
			}
			{
				measurementUser?.cfgPowerCalculationMethod?.enum?.find(enumOption => enumOption.value === form.cfgPowerCalculationMethod.value)?.text === PowerCalculationMethod.POWER_METHOD_BUDEANU &&
                <ReactivePowerCard
                    title={ t('POWER.reactive power QB') }
                    settingsMeasurementsUser={ settingsMeasurementsUser }
                />
			}
			<ApparentPowerCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<ApparentPowerFundamentalCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>

			{
				measurementUser?.cfgPowerCalculationMethod?.enum?.find(enumOption => enumOption.value === form.cfgPowerCalculationMethod.value)?.text === PowerCalculationMethod.POWER_METHOD_IEEE1459 &&
                <ApparentPowerNonfundamentalCard
                    settingsMeasurementsUser={ settingsMeasurementsUser }
                />
			}

			{
				measurementUser?.cfgPowerCalculationMethod?.enum?.find(enumOption => enumOption.value === form.cfgPowerCalculationMethod.value)?.text === PowerCalculationMethod.POWER_METHOD_BUDEANU &&
                <DistortionPowerBudeanuCard
                    settingsMeasurementsUser={ settingsMeasurementsUser }
                />
			}

		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(PowerAndEnergyPowerTab);
