import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import { SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import { FormValidator } from "src/app/types/ui/form.types";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { useContext, useEffect } from "react";
import { MeasurementsUserContext } from "src/app/components/Measurements/MeasurementsUserContent.component";
import { createFormField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import { Button, Card } from "flowbite-react";
import BasicRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/BasicRecordingOptionConfiguration.component";
import { useTranslation } from "react-i18next";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsMeasurementsUser: SettingsMeasurementsUser
	};

type Voltage29kForm = {
	cfgVoltage29KRecOpt: number
}

const validator: FormValidator<Voltage29kForm> = {
	cfgVoltage29KRecOpt: () => null,
};

const ID = "voltage-29k";

function Voltage29KCard(props: Props) {

	const { t } = useTranslation();

	const {
		settingsMeasurementsUser: {
			cfgVoltage29K,
		},
		isAdmin,
	} = props;

	const {
		form: reducerForm,
		handleChange: reducerHandleChange,
	} = useReducerForm(
		"buffor",
		bufforFormActions,
		() => null,
	);

	const { cfgUserEnable } = useContext(MeasurementsUserContext);

	const _handleSubmit = (values: Voltage29kForm) => {
		reducerHandleChange("cfgVoltage29KRecOpt", values.cfgVoltage29KRecOpt);
	};

	const _getInitialState = () => ({
		cfgVoltage29KRecOpt: createFormField((reducerForm.cfgVoltage29KRecOpt.value !== reducerForm.cfgVoltage29KRecOpt.initialValue) ? reducerForm.cfgVoltage29KRecOpt.value : cfgVoltage29K?.cfgVoltage29KRecOpt?.value ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ cfgVoltage29K ]);

	useEffect(() => {
		toggleDisable("cfgVoltage29KRecOpt", !cfgUserEnable || !isAdmin);
	}, [ cfgUserEnable ]);

	useEffect(() => {
		toggleDisable("cfgVoltage29KRecOpt", false);

		handleChange("cfgVoltage29KRecOpt", reducerForm.cfgVoltage29KRecOpt.value);

		toggleDisable("cfgVoltage29KRecOpt", !cfgUserEnable || !isAdmin);
	}, [
		reducerForm.cfgVoltage29KRecOpt.value,
	]);

	return (
		<Card className="bg-gray-50">
			<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
				{t('MEASUREMENTS.2 9 kHz monitoring')}
			</h5>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<BasicRecordingOptionConfiguration
					id={ ID }
					options={ cfgVoltage29K?.cfgVoltage29KRecOpt?.options ?? [] }
					formItem={ form.cfgVoltage29KRecOpt }
					handleChange={ value => handleChange("cfgVoltage29KRecOpt", value) }
					reducerFormItem={ reducerForm.cfgVoltage29KRecOpt }
				/>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t('UTILS.save') }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(Voltage29KCard);
