import { FormValidator } from "src/app/types/ui/form.types";
import { SettingsIo } from "src/app/types/api/settings.types";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { createFormField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import { useEffect } from "react";
import EventRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/EventRecordingOptionConfiguration.component";
import { digitalInputStateDictionary } from "src/app/utils/constants/dictionaries";
import Select from "src/app/components/Form/Select.component";
import { mapEnumSettingToSelectOptions } from "src/app/utils/helpers";
import { isNull } from "src/app/utils/typeguards";
import { Button, Card } from "flowbite-react";
import { useTranslation } from "react-i18next";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsIo: SettingsIo
	}

type IoForm = {
	cfgDigitalInputInternal1ActiveState: number
	cfgDigitalInputInternal1RecOpt: number
	cfgDigitalInputInternal2ActiveState: number
	cfgDigitalInputInternal2RecOpt: number
}

const validator: FormValidator<IoForm> = {
	cfgDigitalInputInternal1ActiveState: () => null,
	cfgDigitalInputInternal1RecOpt: () => null,
	cfgDigitalInputInternal2ActiveState: () => null,
	cfgDigitalInputInternal2RecOpt: () => null,
};

function DigitalInputsCard(props: Props) {

	const { t } = useTranslation();

	const {
		settingsIo,
		isAdmin,
	} = props;

	const reducerForm = useReducerForm(
		"buffor",
		bufforFormActions,
		() => undefined,
	);

	const _handleSubmit = (values: IoForm) => {
		reducerForm.handleChange("cfgDigitalInputInternal1ActiveState", values.cfgDigitalInputInternal1ActiveState);
		reducerForm.handleChange("cfgDigitalInputInternal1RecOpt", values.cfgDigitalInputInternal1RecOpt);
		reducerForm.handleChange("cfgDigitalInputInternal2ActiveState", values.cfgDigitalInputInternal2ActiveState);
		reducerForm.handleChange("cfgDigitalInputInternal2RecOpt", values.cfgDigitalInputInternal2RecOpt);
	};

	const _getInitialState = () => ({
		cfgDigitalInputInternal1ActiveState: createFormField((reducerForm.form.cfgDigitalInputInternal1ActiveState.value !== reducerForm.form.cfgDigitalInputInternal1ActiveState.initialValue) ? reducerForm.form.cfgDigitalInputInternal1ActiveState.value : settingsIo.io?.cfgDigitalInputInternal1ActiveState?.value ?? 0, { disabled: !isAdmin }),
		cfgDigitalInputInternal1RecOpt: createFormField((reducerForm.form.cfgDigitalInputInternal1RecOpt.value !== reducerForm.form.cfgDigitalInputInternal1RecOpt.initialValue) ? reducerForm.form.cfgDigitalInputInternal1RecOpt.value : settingsIo.io?.cfgDigitalInputInternal1RecOpt?.value ?? 0, { disabled: !isAdmin }),
		cfgDigitalInputInternal2ActiveState: createFormField((reducerForm.form.cfgDigitalInputInternal2ActiveState.value !== reducerForm.form.cfgDigitalInputInternal2ActiveState.initialValue) ? reducerForm.form.cfgDigitalInputInternal2ActiveState.value : settingsIo.io?.cfgDigitalInputInternal2ActiveState?.value ?? 0, { disabled: !isAdmin }),
		cfgDigitalInputInternal2RecOpt: createFormField((reducerForm.form.cfgDigitalInputInternal2RecOpt.value !== reducerForm.form.cfgDigitalInputInternal2RecOpt.initialValue) ? reducerForm.form.cfgDigitalInputInternal2RecOpt.value : settingsIo.io?.cfgDigitalInputInternal2RecOpt?.value ?? 0, { disabled: !isAdmin }),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, []);

	useEffect(() => {
		handleChange("cfgDigitalInputInternal1ActiveState", reducerForm.form.cfgDigitalInputInternal1ActiveState.value);
		handleChange("cfgDigitalInputInternal1RecOpt", reducerForm.form.cfgDigitalInputInternal1RecOpt.value);
		handleChange("cfgDigitalInputInternal2ActiveState", reducerForm.form.cfgDigitalInputInternal2ActiveState.value);
		handleChange("cfgDigitalInputInternal2RecOpt", reducerForm.form.cfgDigitalInputInternal2RecOpt.value);
	}, [
		reducerForm.form.cfgDigitalInputInternal1ActiveState.value,
		reducerForm.form.cfgDigitalInputInternal1RecOpt.value,
		reducerForm.form.cfgDigitalInputInternal2ActiveState.value,
		reducerForm.form.cfgDigitalInputInternal2RecOpt.value,
	]);

	return (
		<Card>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<div className="grid grid-cols-2 gap-4">
					<div className="flex flex-col gap-2">
						<h6 className="text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
							{ t("INPUTOUTPUT.digital input 1") }
						</h6>
						<EventRecordingOptionConfiguration
							id="digital-input-1"
							options={ settingsIo.io?.cfgDigitalInputInternal1RecOpt?.options ?? [] }
							formItem={ form.cfgDigitalInputInternal1RecOpt }
							handleChange={ value => handleChange("cfgDigitalInputInternal1RecOpt", value) }
							reducerFormItem={ reducerForm.form.cfgDigitalInputInternal1RecOpt }
						>
							{
								(isLogEventEnabled) =>
									<div className="flex flex-col gap-1">
										<Select
											label={ t("INPUTOUTPUT.digital input 1 state") }
											isDisabled={ !isLogEventEnabled }
											options={ mapEnumSettingToSelectOptions(settingsIo.io?.cfgDigitalInputInternal1ActiveState, digitalInputStateDictionary) }
											formItem={ form.cfgDigitalInputInternal1ActiveState }
											onChange={ option => {
												if (isNull(option)) return;

												handleChange("cfgDigitalInputInternal1ActiveState", option?.value);
												handleBlur("cfgDigitalInputInternal1ActiveState");
											} }
											isSearchable={ false }
											isClearable={ false }
											hasBeenChanged={ reducerForm.form.cfgDigitalInputInternal1ActiveState.value !== reducerForm.form.cfgDigitalInputInternal1ActiveState.initialValue }
										/>
									</div>
							}
						</EventRecordingOptionConfiguration>
					</div>
					<div className="flex flex-col gap-2">
						<h6 className="text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
							{ t("INPUTOUTPUT.digital input 2") }
						</h6>
						<EventRecordingOptionConfiguration
							id="digital-input-2"
							options={ settingsIo.io?.cfgDigitalInputInternal2RecOpt?.options ?? [] }
							formItem={ form.cfgDigitalInputInternal2RecOpt }
							handleChange={ value => handleChange("cfgDigitalInputInternal2RecOpt", value) }
							reducerFormItem={ reducerForm.form.cfgDigitalInputInternal2RecOpt }
						>
							{
								(isLogEventEnabled) =>
									<div className="flex flex-col gap-1">
										<Select
											label={ t("INPUTOUTPUT.digital input 2 state") }
											isDisabled={ !isLogEventEnabled }
											options={ mapEnumSettingToSelectOptions(settingsIo.io?.cfgDigitalInputInternal2ActiveState, digitalInputStateDictionary) }
											formItem={ form.cfgDigitalInputInternal2ActiveState }
											onChange={ option => {
												if (isNull(option)) return;

												handleChange("cfgDigitalInputInternal2ActiveState", option?.value);
												handleBlur("cfgDigitalInputInternal2ActiveState");
											} }
											isSearchable={ false }
											isClearable={ false }
											hasBeenChanged={ reducerForm.form.cfgDigitalInputInternal2ActiveState.value !== reducerForm.form.cfgDigitalInputInternal2ActiveState.initialValue }
										/>
									</div>
							}
						</EventRecordingOptionConfiguration>
					</div>
				</div>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(DigitalInputsCard);
