import { CountryCode } from "src/app/types/misc.types";

type Props = {
	className?: string
	countryCode: CountryCode
};

function FlagIcon(props: Props) {

	const {
		className,
		countryCode,
	} = props;

	return (
		<span className={ `fi h-4 bg-cover border-[0.5px] border-solid border-gray-500 fi-${ countryCode.toLowerCase() } ${ className }` }></span>
	);
}

export default (FlagIcon);