import { SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import CurrentRmsCard from "src/app/components/MeasurementsUser/CurrentRmsCard.component";
import CurrentCrestFactorCard from "src/app/components/MeasurementsUser/CurrentCrestFactorCard.component";
import CurrentUnbalanceCard from "src/app/components/MeasurementsUser/CurrentUnbalanceCard.component";

type Props = {
	settingsMeasurementsUser: SettingsMeasurementsUser
};

function CurrentBasicTab(props: Props) {

	const {
		settingsMeasurementsUser,
	} = props;

	return (
		<div className="p-4 flex flex-col gap-3">
			<CurrentRmsCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<CurrentCrestFactorCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<CurrentUnbalanceCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
		</div>
	);
}

export default (CurrentBasicTab);
