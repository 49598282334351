// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.1
//   protoc               v5.27.0
// source: src/app/proto/event_waveforms.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "event_waveforms";

export interface wave {
  sample: number[];
  U1N: number[];
  U2N: number[];
  U3N: number[];
  U1PE: number[];
  U2PE: number[];
  U3PE: number[];
  UNPE: number[];
  U12: number[];
  U23: number[];
  U31: number[];
  I1: number[];
  I2: number[];
  I3: number[];
  I4: number[];
  I5: number[];
  INS: number[];
}

export interface waveforms {
  left?: wave | undefined;
  right?: wave | undefined;
}

function createBasewave(): wave {
  return {
    sample: [],
    U1N: [],
    U2N: [],
    U3N: [],
    U1PE: [],
    U2PE: [],
    U3PE: [],
    UNPE: [],
    U12: [],
    U23: [],
    U31: [],
    I1: [],
    I2: [],
    I3: [],
    I4: [],
    I5: [],
    INS: [],
  };
}

export const wave = {
  encode(message: wave, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.sample) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(18).fork();
    for (const v of message.U1N) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(26).fork();
    for (const v of message.U2N) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(34).fork();
    for (const v of message.U3N) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(42).fork();
    for (const v of message.U1PE) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(50).fork();
    for (const v of message.U2PE) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(58).fork();
    for (const v of message.U3PE) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(66).fork();
    for (const v of message.UNPE) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(74).fork();
    for (const v of message.U12) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(82).fork();
    for (const v of message.U23) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(90).fork();
    for (const v of message.U31) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(98).fork();
    for (const v of message.I1) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(106).fork();
    for (const v of message.I2) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(114).fork();
    for (const v of message.I3) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(122).fork();
    for (const v of message.I4) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(130).fork();
    for (const v of message.I5) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(138).fork();
    for (const v of message.INS) {
      writer.float(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): wave {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasewave();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.sample.push(reader.uint32());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.sample.push(reader.uint32());
            }

            continue;
          }

          break;
        case 2:
          if (tag === 21) {
            message.U1N.push(reader.float());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.U1N.push(reader.float());
            }

            continue;
          }

          break;
        case 3:
          if (tag === 29) {
            message.U2N.push(reader.float());

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.U2N.push(reader.float());
            }

            continue;
          }

          break;
        case 4:
          if (tag === 37) {
            message.U3N.push(reader.float());

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.U3N.push(reader.float());
            }

            continue;
          }

          break;
        case 5:
          if (tag === 45) {
            message.U1PE.push(reader.float());

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.U1PE.push(reader.float());
            }

            continue;
          }

          break;
        case 6:
          if (tag === 53) {
            message.U2PE.push(reader.float());

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.U2PE.push(reader.float());
            }

            continue;
          }

          break;
        case 7:
          if (tag === 61) {
            message.U3PE.push(reader.float());

            continue;
          }

          if (tag === 58) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.U3PE.push(reader.float());
            }

            continue;
          }

          break;
        case 8:
          if (tag === 69) {
            message.UNPE.push(reader.float());

            continue;
          }

          if (tag === 66) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.UNPE.push(reader.float());
            }

            continue;
          }

          break;
        case 9:
          if (tag === 77) {
            message.U12.push(reader.float());

            continue;
          }

          if (tag === 74) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.U12.push(reader.float());
            }

            continue;
          }

          break;
        case 10:
          if (tag === 85) {
            message.U23.push(reader.float());

            continue;
          }

          if (tag === 82) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.U23.push(reader.float());
            }

            continue;
          }

          break;
        case 11:
          if (tag === 93) {
            message.U31.push(reader.float());

            continue;
          }

          if (tag === 90) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.U31.push(reader.float());
            }

            continue;
          }

          break;
        case 12:
          if (tag === 101) {
            message.I1.push(reader.float());

            continue;
          }

          if (tag === 98) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.I1.push(reader.float());
            }

            continue;
          }

          break;
        case 13:
          if (tag === 109) {
            message.I2.push(reader.float());

            continue;
          }

          if (tag === 106) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.I2.push(reader.float());
            }

            continue;
          }

          break;
        case 14:
          if (tag === 117) {
            message.I3.push(reader.float());

            continue;
          }

          if (tag === 114) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.I3.push(reader.float());
            }

            continue;
          }

          break;
        case 15:
          if (tag === 125) {
            message.I4.push(reader.float());

            continue;
          }

          if (tag === 122) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.I4.push(reader.float());
            }

            continue;
          }

          break;
        case 16:
          if (tag === 133) {
            message.I5.push(reader.float());

            continue;
          }

          if (tag === 130) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.I5.push(reader.float());
            }

            continue;
          }

          break;
        case 17:
          if (tag === 141) {
            message.INS.push(reader.float());

            continue;
          }

          if (tag === 138) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.INS.push(reader.float());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): wave {
    return {
      sample: globalThis.Array.isArray(object?.sample) ? object.sample.map((e: any) => globalThis.Number(e)) : [],
      U1N: globalThis.Array.isArray(object?.U1N) ? object.U1N.map((e: any) => globalThis.Number(e)) : [],
      U2N: globalThis.Array.isArray(object?.U2N) ? object.U2N.map((e: any) => globalThis.Number(e)) : [],
      U3N: globalThis.Array.isArray(object?.U3N) ? object.U3N.map((e: any) => globalThis.Number(e)) : [],
      U1PE: globalThis.Array.isArray(object?.U1PE) ? object.U1PE.map((e: any) => globalThis.Number(e)) : [],
      U2PE: globalThis.Array.isArray(object?.U2PE) ? object.U2PE.map((e: any) => globalThis.Number(e)) : [],
      U3PE: globalThis.Array.isArray(object?.U3PE) ? object.U3PE.map((e: any) => globalThis.Number(e)) : [],
      UNPE: globalThis.Array.isArray(object?.UNPE) ? object.UNPE.map((e: any) => globalThis.Number(e)) : [],
      U12: globalThis.Array.isArray(object?.U12) ? object.U12.map((e: any) => globalThis.Number(e)) : [],
      U23: globalThis.Array.isArray(object?.U23) ? object.U23.map((e: any) => globalThis.Number(e)) : [],
      U31: globalThis.Array.isArray(object?.U31) ? object.U31.map((e: any) => globalThis.Number(e)) : [],
      I1: globalThis.Array.isArray(object?.I1) ? object.I1.map((e: any) => globalThis.Number(e)) : [],
      I2: globalThis.Array.isArray(object?.I2) ? object.I2.map((e: any) => globalThis.Number(e)) : [],
      I3: globalThis.Array.isArray(object?.I3) ? object.I3.map((e: any) => globalThis.Number(e)) : [],
      I4: globalThis.Array.isArray(object?.I4) ? object.I4.map((e: any) => globalThis.Number(e)) : [],
      I5: globalThis.Array.isArray(object?.I5) ? object.I5.map((e: any) => globalThis.Number(e)) : [],
      INS: globalThis.Array.isArray(object?.INS) ? object.INS.map((e: any) => globalThis.Number(e)) : [],
    };
  },

  toJSON(message: wave): unknown {
    const obj: any = {};
    if (message.sample?.length) {
      obj.sample = message.sample.map((e) => Math.round(e));
    }
    if (message.U1N?.length) {
      obj.U1N = message.U1N;
    }
    if (message.U2N?.length) {
      obj.U2N = message.U2N;
    }
    if (message.U3N?.length) {
      obj.U3N = message.U3N;
    }
    if (message.U1PE?.length) {
      obj.U1PE = message.U1PE;
    }
    if (message.U2PE?.length) {
      obj.U2PE = message.U2PE;
    }
    if (message.U3PE?.length) {
      obj.U3PE = message.U3PE;
    }
    if (message.UNPE?.length) {
      obj.UNPE = message.UNPE;
    }
    if (message.U12?.length) {
      obj.U12 = message.U12;
    }
    if (message.U23?.length) {
      obj.U23 = message.U23;
    }
    if (message.U31?.length) {
      obj.U31 = message.U31;
    }
    if (message.I1?.length) {
      obj.I1 = message.I1;
    }
    if (message.I2?.length) {
      obj.I2 = message.I2;
    }
    if (message.I3?.length) {
      obj.I3 = message.I3;
    }
    if (message.I4?.length) {
      obj.I4 = message.I4;
    }
    if (message.I5?.length) {
      obj.I5 = message.I5;
    }
    if (message.INS?.length) {
      obj.INS = message.INS;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<wave>, I>>(base?: I): wave {
    return wave.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<wave>, I>>(object: I): wave {
    const message = createBasewave();
    message.sample = object.sample?.map((e) => e) || [];
    message.U1N = object.U1N?.map((e) => e) || [];
    message.U2N = object.U2N?.map((e) => e) || [];
    message.U3N = object.U3N?.map((e) => e) || [];
    message.U1PE = object.U1PE?.map((e) => e) || [];
    message.U2PE = object.U2PE?.map((e) => e) || [];
    message.U3PE = object.U3PE?.map((e) => e) || [];
    message.UNPE = object.UNPE?.map((e) => e) || [];
    message.U12 = object.U12?.map((e) => e) || [];
    message.U23 = object.U23?.map((e) => e) || [];
    message.U31 = object.U31?.map((e) => e) || [];
    message.I1 = object.I1?.map((e) => e) || [];
    message.I2 = object.I2?.map((e) => e) || [];
    message.I3 = object.I3?.map((e) => e) || [];
    message.I4 = object.I4?.map((e) => e) || [];
    message.I5 = object.I5?.map((e) => e) || [];
    message.INS = object.INS?.map((e) => e) || [];
    return message;
  },
};

function createBasewaveforms(): waveforms {
  return { left: undefined, right: undefined };
}

export const waveforms = {
  encode(message: waveforms, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.left !== undefined) {
      wave.encode(message.left, writer.uint32(10).fork()).ldelim();
    }
    if (message.right !== undefined) {
      wave.encode(message.right, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): waveforms {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasewaveforms();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.left = wave.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.right = wave.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): waveforms {
    return {
      left: isSet(object.left) ? wave.fromJSON(object.left) : undefined,
      right: isSet(object.right) ? wave.fromJSON(object.right) : undefined,
    };
  },

  toJSON(message: waveforms): unknown {
    const obj: any = {};
    if (message.left !== undefined) {
      obj.left = wave.toJSON(message.left);
    }
    if (message.right !== undefined) {
      obj.right = wave.toJSON(message.right);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<waveforms>, I>>(base?: I): waveforms {
    return waveforms.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<waveforms>, I>>(object: I): waveforms {
    const message = createBasewaveforms();
    message.left = (object.left !== undefined && object.left !== null) ? wave.fromPartial(object.left) : undefined;
    message.right = (object.right !== undefined && object.right !== null) ? wave.fromPartial(object.right) : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
