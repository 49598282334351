import { SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import PowerFactorCard from "src/app/components/MeasurementsUser/PowerFactorCard.component";
import DisplacementPowerFactorCard from "src/app/components/MeasurementsUser/DisplacementPowerFactorCard.component";
import Tangent4QCard from "src/app/components/MeasurementsUser/Tangent4qCard.component";
import PowerFactorFundamentalCard from "src/app/components/MeasurementsUser/PowerFactorFundamentalCard.component";

type Props = {
	settingsMeasurementsUser: SettingsMeasurementsUser
};

function PowerAndEnergyAdditionalTab(props: Props) {

	const {
		settingsMeasurementsUser,
	} = props;

	return (
		<div className="p-4 flex flex-col gap-3">
			<DisplacementPowerFactorCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<PowerFactorCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			{/*<PowerFactorFundamentalCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>*/}
			<Tangent4QCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
		</div>
	);
}

export default (PowerAndEnergyAdditionalTab);
