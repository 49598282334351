import { OnOff, SettingsFtp, TestFtpConnectionRequest } from "src/app/types/api/settings.types";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateASCIIField, validateField, validateIPAddress, validateNumberField } from "src/app/utils/forms";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import useForm from "src/app/utils/hooks/useForm";
import { useEffect, useState } from "react";
import Select from "src/app/components/Form/Select.component";
import { mapEnumSettingToSelectOptions } from "src/app/utils/helpers";
import { ftpConnectionTypeDictionary, ftpModeDictionary } from "src/app/utils/constants/dictionaries";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { Button, Card, Label, ToggleSwitch } from "flowbite-react";
import classNames from "classnames";
import Input from "src/app/components/Form/Input.component";
import { getRtcLocalTimestampDiffSeconds, getRtcUtcTimestampDiffSeconds } from "src/app/store/features/settings/settings.selectors";
import LabelValue from "src/app/components/Utils/LabelValue.component";
import Moment from "react-moment";
import { ftpUploadTimeSelectOptions } from "src/app/utils/constants/constants";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import StaticSnackbar from "src/app/components/Message/StaticSnackbar.component";
import { View } from "src/app/types/ui/message.types";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		ftpSettings: SettingsFtp
		testConnection: (payload: TestFtpConnectionRequest) => void
	};

type FtpForm = {
	ftpEnable: boolean
	ftpConnectionType: number
	ftpMode: number
	ftpUploadTime: number
	// ftpUploadTimeMinValue: number
	// ftpUploadTimeMaxValue: number
	ftpServerIp: string
	ftpServerPort: string
	ftpServerPortMin: number
	ftpServerPortMax: number
	ftpUserName: string
	ftpUserNameMaxValue: number
	ftpPassword: string
	ftpPasswordMaxValue: number
	ftpDestDirectory: string
	ftpDestDirectoryMaxValue: number
}

const validator: FormValidator<FtpForm> = {
	ftpEnable: () => null,
	ftpConnectionType: () => null,
	ftpMode: () => null,
	ftpUploadTime: () => null,
	// ftpUploadTime: (ftpUploadTime, optional, form) => {
	// 	if (!form.ftpEnable.value) return null;
	//
	// 	return validateNumberField("Upload time", ftpUploadTime, optional, form.ftpUploadTimeMinValue.value, form.ftpUploadTimeMaxValue.value);
	// },
	// ftpUploadTimeMinValue: () => null,
	// ftpUploadTimeMaxValue: () => null,
	ftpServerIp: (ftpServerIp, optional, form) => {
		if (!form.ftpEnable.value) return null;

		return validateIPAddress(i18n.t("FTP.server ip"), ftpServerIp, optional, "he");
	},
	ftpServerPort: (ftpServerPort, optional, form) => {
		if (!form.ftpEnable.value) return null;

		return validateNumberField(i18n.t("FTP.server port"), ftpServerPort, optional, "he", { from: form.ftpServerPortMin.value, to: form.ftpServerPortMax.value });
	},
	ftpServerPortMin: () => null,
	ftpServerPortMax: () => null,
	ftpUserName: (ftpUserName, optional, form) => {
		if (!form.ftpEnable.value) return null;

		return validateASCIIField(i18n.t("FTP.login"), ftpUserName, optional, "she", { maxLength: form.ftpUserNameMaxValue.value });
	},
	ftpUserNameMaxValue: () => null,
	ftpPassword: (ftpPassword, optional, form) => {
		if (!form.ftpEnable.value) return null;

		return validateASCIIField(i18n.t("FTP.password"), ftpPassword, optional, "it", { maxLength: form.ftpPasswordMaxValue.value });
	},
	ftpPasswordMaxValue: () => null,
	ftpDestDirectory: (ftpDestDirectory, optional, form) => {
		if (!form.ftpEnable.value) return null;

		return validateField(i18n.t("FTP.destination directory"), ftpDestDirectory, optional, "he", { maxLength: form.ftpDestDirectoryMaxValue.value });
	},
	ftpDestDirectoryMaxValue: () => null,
};

function FtpContainer(props: Props) {

	const { t } = useTranslation();

	const {
		ftpSettings,
		testConnection,
		utcDiff,
		localDiff,
		isAdmin,
		isTestingConnection,
	} = props;

	const reducerForm = useReducerForm(
		"buffor",
		bufforFormActions,
		() => undefined,
	);

	const _handleSubmit = (values: FtpForm) => {
		reducerForm.handleChange("ftpEnable", values.ftpEnable);
		reducerForm.handleChange("ftpConnectionType", values.ftpConnectionType);
		reducerForm.handleChange("ftpMode", values.ftpMode);
		reducerForm.handleChange("ftpUploadTime", values.ftpUploadTime);
		reducerForm.handleChange("ftpServerIp", values.ftpServerIp);
		reducerForm.handleChange("ftpServerPort", values.ftpServerPort);
		reducerForm.handleChange("ftpUserName", values.ftpUserName);
		reducerForm.handleChange("ftpPassword", values.ftpPassword);
		reducerForm.handleChange("ftpDestDirectory", values.ftpDestDirectory);
	};

	const _getInitialState = () => {
		const ftpEnable = (reducerForm.form.ftpEnable.value !== reducerForm.form.ftpEnable.initialValue) ? reducerForm.form.ftpEnable.value : ftpSettings?.communications?.cfgFtpEnable?.enum?.find(enumValue => enumValue.value === ftpSettings?.communications?.cfgFtpEnable?.value)?.text === OnOff.ON;
		return {
			ftpEnable: createFormField(ftpEnable, { disabled: !isAdmin }),
			ftpConnectionType: createFormField((reducerForm.form.ftpConnectionType.value !== reducerForm.form.ftpConnectionType.initialValue) ? reducerForm.form.ftpConnectionType.value : ftpSettings?.communications?.cfgFtpConnectionType?.value ?? 0, { disabled: (!isAdmin || !ftpEnable) }),
			ftpMode: createFormField((reducerForm.form.ftpMode.value !== reducerForm.form.ftpMode.initialValue) ? reducerForm.form.ftpMode.value : ftpSettings?.communications?.cfgFtpMode?.value ?? 0, { disabled: (!isAdmin || !ftpEnable) }),
			ftpUploadTime: createFormField((reducerForm.form.ftpUploadTime.value !== reducerForm.form.ftpUploadTime.initialValue) ? reducerForm.form.ftpUploadTime.value : ftpSettings?.communications?.cfgFtpUploadTime?.value ?? 0, { disabled: (!isAdmin || !ftpEnable) }),
			// ftpUploadTimeMinValue: createFormField(ftpSettings?.communications?.cfgFtpUploadTime.minValue),
			// ftpUploadTimeMaxValue: createFormField(ftpSettings?.communications?.cfgFtpUploadTime.maxValue),
			ftpServerIp: createFormField((reducerForm.form.ftpServerIp.value !== reducerForm.form.ftpServerIp.initialValue) ? reducerForm.form.ftpServerIp.value : ftpSettings?.communications?.cfgFtpServerIp?.value ?? "", { disabled: (!isAdmin || !ftpEnable) }),
			ftpServerPort: createFormField((reducerForm.form.ftpServerPort.value !== reducerForm.form.ftpServerPort.initialValue) ? reducerForm.form.ftpServerPort.value : ftpSettings?.communications?.cfgFtpServerPort?.value?.toString() ?? "", { disabled: (!isAdmin || !ftpEnable) }),
			ftpServerPortMin: createFormField(ftpSettings?.communications?.cfgFtpServerPort?.minValue ?? 0),
			ftpServerPortMax: createFormField(ftpSettings?.communications?.cfgFtpServerPort?.maxValue ?? 0),
			ftpUserName: createFormField((reducerForm.form.ftpUserName.value !== reducerForm.form.ftpUserName.initialValue) ? reducerForm.form.ftpUserName.value : ftpSettings?.communications?.cfgFtpUserName?.value ?? "", { disabled: (!isAdmin || !ftpEnable) }),
			ftpUserNameMaxValue: createFormField(ftpSettings?.communications?.cfgFtpUserName?.maxValue ?? 0),
			ftpPassword: createFormField((reducerForm.form.ftpPassword.value !== reducerForm.form.ftpPassword.initialValue) ? reducerForm.form.ftpPassword.value : ftpSettings?.communications?.cfgFtpPassword?.value ?? "", { disabled: (!isAdmin || !ftpEnable) }),
			ftpPasswordMaxValue: createFormField(ftpSettings?.communications?.cfgFtpPassword?.maxValue ?? 0),
			ftpDestDirectory: createFormField((reducerForm.form.ftpDestDirectory.value !== reducerForm.form.ftpDestDirectory.initialValue) ? reducerForm.form.ftpDestDirectory.value : ftpSettings?.communications?.cfgFtpDestDirectory?.value ?? "", { disabled: (!isAdmin || !ftpEnable) }),
			ftpDestDirectoryMaxValue: createFormField(ftpSettings?.communications?.cfgFtpDestDirectory?.maxValue ?? 0),
		};
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ ftpSettings ]);

	useEffect(() => {
		toggleDisable("ftpConnectionType", false);
		toggleDisable("ftpMode", false);
		toggleDisable("ftpUploadTime", false);
		toggleDisable("ftpServerIp", false);
		toggleDisable("ftpServerPort", false);
		toggleDisable("ftpUserName", false);
		toggleDisable("ftpPassword", false);
		toggleDisable("ftpDestDirectory", false);

		handleChange("ftpEnable", reducerForm.form.ftpEnable.value);
		handleChange("ftpConnectionType", reducerForm.form.ftpConnectionType.value);
		handleChange("ftpMode", reducerForm.form.ftpMode.value);
		handleChange("ftpUploadTime", reducerForm.form.ftpUploadTime.value);
		handleChange("ftpServerIp", reducerForm.form.ftpServerIp.value);
		handleChange("ftpServerPort", reducerForm.form.ftpServerPort.value);
		handleChange("ftpUserName", reducerForm.form.ftpUserName.value);
		handleChange("ftpPassword", reducerForm.form.ftpPassword.value);
		handleChange("ftpDestDirectory", reducerForm.form.ftpDestDirectory.value);

		toggleDisable("ftpConnectionType", !reducerForm.form.ftpEnable.value || !isAdmin);
		toggleDisable("ftpMode", !reducerForm.form.ftpEnable.value || !isAdmin);
		toggleDisable("ftpUploadTime", !reducerForm.form.ftpEnable.value || !isAdmin);
		toggleDisable("ftpServerIp", !reducerForm.form.ftpEnable.value || !isAdmin);
		toggleDisable("ftpServerPort", !reducerForm.form.ftpEnable.value || !isAdmin);
		toggleDisable("ftpUserName", !reducerForm.form.ftpEnable.value || !isAdmin);
		toggleDisable("ftpPassword", !reducerForm.form.ftpEnable.value || !isAdmin);
		toggleDisable("ftpDestDirectory", !reducerForm.form.ftpEnable.value || !isAdmin);
	}, [
		reducerForm.form.ftpEnable.value,
		reducerForm.form.ftpConnectionType.value,
		reducerForm.form.ftpMode.value,
		reducerForm.form.ftpUploadTime.value,
		reducerForm.form.ftpServerIp.value,
		reducerForm.form.ftpServerPort.value,
		reducerForm.form.ftpUserName.value,
		reducerForm.form.ftpPassword.value,
		reducerForm.form.ftpDestDirectory.value,
	]);

	const _handleTestConnection = () => {
		testConnection({
			cfg_ftp_connection_type: form.ftpConnectionType.value,
			cfg_ftp_mode: form.ftpMode.value,
			cfg_ftp_server_ip: form.ftpServerIp.value,
			cfg_ftp_server_port: +form.ftpServerPort.value,
			cfg_ftp_user_name: form.ftpUserName.value,
			cfg_ftp_password: form.ftpPassword.value,
			cfg_ftp_dest_directory: form.ftpDestDirectory.value,
		});
	};

	const [ isPasswordVisible, togglePasswordVisibility ] = useState(false);

	return (
		<Card>
			<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
				{ t("FTP.FTP client") }
			</h5>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<div className="flex gap-4">
					<div className="min-w-[150px]">
						<LabelValue
							label={ t("FTP.UTC") }
							value={
								<Moment
									utc
									format="YYYY-MM-DD HH:mm:ss"
									add={ { seconds: utcDiff } }
									interval={ 1000 }
								/>
							}
						/>
					</div>
					<div className="min-w-[150px]">
						<LabelValue
							label={ t("FTP.local time") }
							value={
								<Moment
									utc
									format="YYYY-MM-DD HH:mm:ss"
									add={ { seconds: localDiff } }
									interval={ 1000 }
								/>
							}
						/>
					</div>
				</div>
				<ToggleSwitch
					className={
						classNames(
							"[&>div]:w-9 [&>div]:h-5 [&>div]:after:absolute [&>div]:after:top-[2px] [&>div]:after:left-[2px] [&>div]:after:h-4 [&>div]:after:w-4",
							{ "[&>span]:!text-yellow-400": reducerForm.form.ftpEnable.value !== reducerForm.form.ftpEnable.initialValue },
						)
					}
					disabled={ form.ftpEnable.disabled }
					checked={ form.ftpEnable.value }
					label={ t("FTP.enable") }
					onChange={ () => {
						handleChange("ftpEnable", !form.ftpEnable.value);

						toggleDisable("ftpConnectionType", form.ftpEnable.value);
						toggleDisable("ftpMode", form.ftpEnable.value);
						toggleDisable("ftpUploadTime", form.ftpEnable.value);
						toggleDisable("ftpServerIp", form.ftpEnable.value);
						toggleDisable("ftpServerPort", form.ftpEnable.value);
						toggleDisable("ftpUserName", form.ftpEnable.value);
						toggleDisable("ftpPassword", form.ftpEnable.value);
						toggleDisable("ftpDestDirectory", form.ftpEnable.value);
					} }
				/>
				<Input
					formItem={ form.ftpUserName }
					label={ t("FTP.login") }
					name="ftpUserName"
					inputProps={ {
						type: "text",
						onChange: (e) => handleChange("ftpUserName", e.target.value),
						onBlur: () => handleBlur("ftpUserName"),
					} }
					hasBeenChanged={ reducerForm.form.ftpUserName.value !== reducerForm.form.ftpUserName.initialValue }
				/>
				<div className="flex flex-col gap-y-0.5">
					<Label
						disabled={ form.ftpPassword.disabled }
					>
						{ t("FTP.password") }
					</Label>
					<div className="relative [&_input]:!pr-[52px]">
						<Input
							formItem={ form.ftpPassword }
							name="ftpPassword"
							// className="[&_div[data-testid='right-icon']]:!pointer-events-auto"
							inputProps={ {
								type: isPasswordVisible ? "text" : "password",
								onChange: (e) => handleChange("ftpPassword", e.target.value),
								onBlur: () => handleBlur("ftpPassword"),
							} }
							hasBeenChanged={ reducerForm.form.ftpPassword.value !== reducerForm.form.ftpPassword.initialValue }
							displayErrorMessage={ false }
						/>
						{
							isPasswordVisible ?
								<HiEyeOff
									className={
										classNames(
											"text-gray-600  h-5 w-5 absolute right-4 top-1/2 -translate-y-1/2",
											{ "cursor-pointer": !form.ftpPassword.disabled },
											{ "opacity-50 cursor-not-allowed pointer-events-none": form.ftpPassword.disabled },
										)
									}
									onClick={ () => togglePasswordVisibility(p => !p) }
								/>
								:
								<HiEye
									className={
										classNames(
											"text-gray-600  h-5 w-5 absolute right-4 top-1/2 -translate-y-1/2",
											{ "cursor-pointer": !form.ftpPassword.disabled },
											{ "opacity-50 cursor-not-allowed pointer-events-none": form.ftpPassword.disabled },
										)
									}
									onClick={ () => togglePasswordVisibility(p => !p) }
								/>
						}
					</div>
					{
						isNotNull(form.ftpPassword.error) &&
                        <div className="text-sm text-red-600 dark:text-red-500 font-medium">
							{ form.ftpPassword.error }
                        </div>
					}
				</div>
				<Input
					formItem={ form.ftpServerIp }
					label={ t("FTP.server ip") }
					name="ftpServerIp"
					inputProps={ {
						type: "text",
						onChange: (e) => handleChange("ftpServerIp", e.target.value),
						onBlur: () => handleBlur("ftpServerIp"),
					} }
					hasBeenChanged={ reducerForm.form.ftpServerIp.value !== reducerForm.form.ftpServerIp.initialValue }
				/>
				<Input
					formItem={ form.ftpServerPort }
					label={ t("FTP.server port") }
					name="ftpServerPort"
					inputProps={ {
						type: "text",
						onChange: (e) => handleChange("ftpServerPort", e.target.value),
						onBlur: () => handleBlur("ftpServerPort"),
					} }
					hasBeenChanged={ reducerForm.form.ftpServerPort.value !== reducerForm.form.ftpServerPort.initialValue }
				/>
				<Select
					label={ t("FTP.operating mode") }
					options={ mapEnumSettingToSelectOptions(ftpSettings?.communications?.cfgFtpMode, ftpModeDictionary) }
					formItem={ form.ftpMode }
					onChange={ option => {
						if (isNull(option)) return;

						handleChange("ftpMode", option?.value);
						handleBlur("ftpMode");
					} }
					isSearchable={ false }
					isClearable={ false }
					hasBeenChanged={ reducerForm.form.ftpMode.value !== reducerForm.form.ftpMode.initialValue }
				/>
				<Select
					label={ t("FTP.encryption") }
					options={ mapEnumSettingToSelectOptions(ftpSettings?.communications?.cfgFtpConnectionType, ftpConnectionTypeDictionary) }
					formItem={ form.ftpConnectionType }
					onChange={ option => {
						if (isNull(option)) return;

						handleChange("ftpConnectionType", option?.value);
						handleBlur("ftpConnectionType");
					} }
					isSearchable={ false }
					isClearable={ false }
					hasBeenChanged={ reducerForm.form.ftpConnectionType.value !== reducerForm.form.ftpConnectionType.initialValue }
				/>

				{/*<Input
					formItem={ form.ftpUploadTime }
					label={ `${t('FTP.upload time')} [${ ftpSettings?.communications?.cfgFtpUploadTime.unit }]` }
					name="ftpUploadTime"
					inputProps={ {
						type: "number",
						onChange: (e) => handleChange("ftpUploadTime", e.target.value),
						onBlur: () => handleBlur("ftpUploadTime"),
					} }
					hasBeenChanged={ reducerForm.form.ftpUploadTime.value !== reducerForm.form.ftpUploadTime.initialValue }
				/>*/ }
				<Input
					formItem={ form.ftpDestDirectory }
					label={ t("FTP.destination directory") }
					name="ftpDestDirectory"
					inputProps={ {
						type: "text",
						onChange: (e) => handleChange("ftpDestDirectory", e.target.value),
						onBlur: () => handleBlur("ftpDestDirectory"),
					} }
					hasBeenChanged={ reducerForm.form.ftpDestDirectory.value !== reducerForm.form.ftpDestDirectory.initialValue }
				/>
				<div className="flex">
					<Button
						color="primary"
						onClick={ _handleTestConnection }
						isProcessing={ isTestingConnection }
						disabled={ !form.ftpEnable.value || !isAdmin }
					>
						{ t("FTP.test connection") }
					</Button>
				</div>
				<StaticSnackbar
					view={ View.FTP }
				/>
				<hr className="mx-2"/>
				<Select
					label={ t("FTP.automatic upload time") }
					options={ ftpUploadTimeSelectOptions((ftpSettings?.communications?.cfgFtpUploadTime?.maxValue ?? 0) - (ftpSettings?.communications?.cfgFtpUploadTime?.minValue ?? 0) + 1, (ftpSettings?.communications?.cfgFtpUploadTime?.minValue ?? 0)) }
					formItem={ form.ftpUploadTime }
					onChange={ option => {
						if (isNull(option)) return;

						handleChange("ftpUploadTime", option?.value);
						handleBlur("ftpUploadTime");
					} }
					isSearchable={ false }
					isClearable={ false }
					hasBeenChanged={ reducerForm.form.ftpUploadTime.value !== reducerForm.form.ftpUploadTime.initialValue }
				/>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
	utcDiff: getRtcUtcTimestampDiffSeconds(state),
	localDiff: getRtcLocalTimestampDiffSeconds(state),
	isTestingConnection: didLoadingRecordExist(state, { loadableType: LoadableType.TEST_FTP_CONNECTION }),
});

export default connect(mapStateToProps)(FtpContainer);
