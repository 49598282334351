import { lazy } from "react";
import { RouteObject } from "react-router/dist/lib/context";

const LoginView = lazy(() => import("src/app/views/Auth/Login.view"));

const authRoutes: RouteObject[] = [
	{
		path: "/login",
		element: <LoginView/>,
	},
];

export default authRoutes;
