import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { OnOff, SettingsMeasurementsStandard } from "src/app/types/api/settings.types";
import { FormValidator } from "src/app/types/ui/form.types";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { createFormField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import { useEffect } from "react";
import Select from "src/app/components/Form/Select.component";
import { mapEnumSettingToSelectOptions } from "src/app/utils/helpers";
import { standardTypeDictionary } from "src/app/utils/constants/dictionaries";
import { isNull } from "src/app/utils/typeguards";
import { Button, Card, ToggleSwitch } from "flowbite-react";
import classNames from "classnames";
import { useTranslation } from 'react-i18next';

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsMeasurementsStandard: SettingsMeasurementsStandard
	};

type MeasurementsStandardForm = {
	cfgStdEnable: boolean
	cfgStdType: number
	cfgStdAggregationWaveformEnable: boolean
}

const validator: FormValidator<MeasurementsStandardForm> = {
	cfgStdEnable: () => null,
	cfgStdType: () => null,
	cfgStdAggregationWaveformEnable: () => null,
};

function MeasurementsStandardCard(props: Props) {

	const { t } = useTranslation();

	const {
		settingsMeasurementsStandard,
		isAdmin,
	} = props;

	const reducerForm = useReducerForm(
		"buffor",
		bufforFormActions,
		() => undefined,
	);

	const _handleSubmit = (values: MeasurementsStandardForm) => {
		reducerForm.handleChange("cfgStdEnable", values.cfgStdEnable);
		reducerForm.handleChange("cfgStdType", values.cfgStdType);
		reducerForm.handleChange("cfgStdAggregationWaveformEnable", values.cfgStdAggregationWaveformEnable);

	};

	const _getInitialState = () => {
		const cfgStdEnable = settingsMeasurementsStandard.measurementStandard?.cfgStdDataRecEnable?.enum?.find(enumValue => enumValue.value === settingsMeasurementsStandard.measurementStandard?.cfgStdDataRecEnable?.value)?.text === OnOff.ON;
		return {
			cfgStdEnable: createFormField((reducerForm.form.cfgStdEnable.value !== reducerForm.form.cfgStdEnable.initialValue) ? reducerForm.form.cfgStdEnable.value : cfgStdEnable, { disabled: true }),
			cfgStdType: createFormField((reducerForm.form.cfgStdType.value !== reducerForm.form.cfgStdType.initialValue) ? reducerForm.form.cfgStdType.value : settingsMeasurementsStandard.measurementStandard?.cfgStdType?.value ?? 0, { disabled: !isAdmin || !cfgStdEnable }),
			cfgStdAggregationWaveformEnable: createFormField((reducerForm.form.cfgStdAggregationWaveformEnable.value !== reducerForm.form.cfgStdAggregationWaveformEnable.initialValue) ? reducerForm.form.cfgStdAggregationWaveformEnable.value : settingsMeasurementsStandard.measurementStandard?.cfgStdWaveformsAndRms12RecEnable?.enum?.find(enumValue => enumValue.value === settingsMeasurementsStandard.measurementStandard?.cfgStdWaveformsAndRms12RecEnable?.value)?.text === OnOff.ON, { disabled: !isAdmin || !cfgStdEnable }),
		};
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ settingsMeasurementsStandard ]);

	useEffect(() => {
		toggleDisable("cfgStdType", false);
		toggleDisable("cfgStdAggregationWaveformEnable", false);

		handleChange("cfgStdEnable", reducerForm.form.cfgStdEnable.value);
		handleChange("cfgStdType", reducerForm.form.cfgStdType.value);
		handleChange("cfgStdAggregationWaveformEnable", reducerForm.form.cfgStdAggregationWaveformEnable.value);

		toggleDisable("cfgStdType", !reducerForm.form.cfgStdEnable.value || !isAdmin);
		toggleDisable("cfgStdAggregationWaveformEnable", !reducerForm.form.cfgStdEnable.value || !isAdmin);
	}, [
		reducerForm.form.cfgStdEnable.value,
		reducerForm.form.cfgStdType.value,
		reducerForm.form.cfgStdAggregationWaveformEnable.value,
	]);

	return (
		<Card>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<ToggleSwitch
					className={
						classNames(
							"[&>div]:w-9 [&>div]:h-5 [&>div]:after:absolute [&>div]:after:top-[2px] [&>div]:after:left-[2px] [&>div]:after:h-4 [&>div]:after:w-4",
							{ "[&>span]:!text-yellow-400": reducerForm.form.cfgStdEnable.value !== reducerForm.form.cfgStdEnable.initialValue },
						)
					}
					checked={ form.cfgStdEnable.value }
					disabled={ form.cfgStdEnable.disabled }
					label={t('MEASUREMENTS.enable')}
					onChange={ () => {
						handleChange("cfgStdEnable", !form.cfgStdEnable.value);

						toggleDisable("cfgStdType", form.cfgStdEnable.value);
						toggleDisable("cfgStdAggregationWaveformEnable", form.cfgStdEnable.value);
					} }
				/>
				<Select
					label={t('MEASUREMENTS.logging according to')}
					options={ mapEnumSettingToSelectOptions(settingsMeasurementsStandard.measurementStandard?.cfgStdType, standardTypeDictionary) }
					formItem={ form.cfgStdType }
					onChange={ option => {
						if (isNull(option)) return;

						handleChange("cfgStdType", option?.value);
						handleBlur("cfgStdType");
					} }
					isSearchable={ false }
					isClearable={ false }
					hasBeenChanged={ reducerForm.form.cfgStdType.value !== reducerForm.form.cfgStdType.initialValue }
				/>
				<ToggleSwitch
					className={
						classNames(
							"[&>div]:w-9 [&>div]:h-5 [&>div]:after:absolute [&>div]:after:top-[2px] [&>div]:after:left-[2px] [&>div]:after:h-4 [&>div]:after:w-4",
							{ "[&>span]:!text-yellow-400": reducerForm.form.cfgStdAggregationWaveformEnable.value !== reducerForm.form.cfgStdAggregationWaveformEnable.initialValue },
							{ "[&>div]:opacity-50": form.cfgStdAggregationWaveformEnable.disabled },
						)
					}
					disabled={ form.cfgStdAggregationWaveformEnable.disabled }
					checked={ form.cfgStdAggregationWaveformEnable.value }
					label={t('MEASUREMENTS.log waveforms and RMS 1/2')}
					onChange={ () => handleChange("cfgStdAggregationWaveformEnable", !form.cfgStdAggregationWaveformEnable.value) }
				/>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
                            {t('UTILS.save')}
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(MeasurementsStandardCard);
