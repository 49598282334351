import { SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { FormValidator } from "src/app/types/ui/form.types";
import { Button, Card } from "flowbite-react";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { useContext, useEffect } from "react";
import { MeasurementsUserContext } from "src/app/components/Measurements/MeasurementsUserContent.component";
import { createFormField, validateNumberField } from "src/app/utils/forms";
import BasicRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/BasicRecordingOptionConfiguration.component";
import useForm from "src/app/utils/hooks/useForm";
import Input from "src/app/components/Form/Input.component";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";
import classNames from "classnames";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsMeasurementsUser: SettingsMeasurementsUser
	};

type MainsSignallingUr1Form = {
	cfgMainsSignallingUr1RecOpt: number
	cfgMainsSignallingUr1Frequency: string
	cfgMainsSignallingUr1FrequencyMin: number
	cfgMainsSignallingUr1FrequencyMax: number
}

const validator: FormValidator<MainsSignallingUr1Form> = {
	cfgMainsSignallingUr1RecOpt: () => null,
	cfgMainsSignallingUr1Frequency: (cfgMainsSignallingUr1Frequency, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.frequency"), cfgMainsSignallingUr1Frequency, optional, "she", { from: form.cfgMainsSignallingUr1FrequencyMin.value, to: form.cfgMainsSignallingUr1FrequencyMax.value, decimalPlaces: 2 }),
	cfgMainsSignallingUr1FrequencyMin: () => null,
	cfgMainsSignallingUr1FrequencyMax: () => null,
};

const ID = "mains-signalling-ur1";

function MainsSignallingUr1Card(props: Props) {

	const { t } = useTranslation();

	const {
		settingsMeasurementsUser: {
			cfgMainsSignallingUr1,
		},
		isAdmin,
	} = props;

	const {
		form: reducerForm,
		handleChange: reducerHandleChange,
	} = useReducerForm(
		"buffor",
		bufforFormActions,
		() => null,
	);

	const { cfgUserEnable } = useContext(MeasurementsUserContext);

	const _handleSubmit = (values: MainsSignallingUr1Form) => {
		reducerHandleChange("cfgMainsSignallingUr1RecOpt", values.cfgMainsSignallingUr1RecOpt);
		reducerHandleChange("cfgMainsSignallingUr1Frequency", values.cfgMainsSignallingUr1Frequency);
	};

	const _getInitialState = () => ({
		cfgMainsSignallingUr1RecOpt: createFormField((reducerForm.cfgMainsSignallingUr1RecOpt.value !== reducerForm.cfgMainsSignallingUr1RecOpt.initialValue) ? reducerForm.cfgMainsSignallingUr1RecOpt.value : cfgMainsSignallingUr1?.cfgMainsSignallingUr1RecOpt?.value ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		cfgMainsSignallingUr1Frequency: createFormField((reducerForm.cfgMainsSignallingUr1Frequency.value !== reducerForm.cfgMainsSignallingUr1Frequency.initialValue) ? reducerForm.cfgMainsSignallingUr1Frequency.value : cfgMainsSignallingUr1?.cfgMainsSignallingUr1Frequency?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
		cfgMainsSignallingUr1FrequencyMin: createFormField(cfgMainsSignallingUr1?.cfgMainsSignallingUr1Frequency?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		cfgMainsSignallingUr1FrequencyMax: createFormField(cfgMainsSignallingUr1?.cfgMainsSignallingUr1Frequency?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ cfgMainsSignallingUr1 ]);

	useEffect(() => {
		toggleDisable("cfgMainsSignallingUr1RecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgMainsSignallingUr1Frequency", !cfgUserEnable || !isAdmin);
	}, [ cfgUserEnable ]);

	useEffect(() => {
		toggleDisable("cfgMainsSignallingUr1RecOpt", false);
		toggleDisable("cfgMainsSignallingUr1Frequency", false);

		handleChange("cfgMainsSignallingUr1RecOpt", reducerForm.cfgMainsSignallingUr1RecOpt.value);
		handleChange("cfgMainsSignallingUr1Frequency", reducerForm.cfgMainsSignallingUr1Frequency.value);

		toggleDisable("cfgMainsSignallingUr1RecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgMainsSignallingUr1Frequency", !cfgUserEnable || !isAdmin);
	}, [
		reducerForm.cfgMainsSignallingUr1RecOpt.value,
		reducerForm.cfgMainsSignallingUr1Frequency.value,
	]);

	return (
		<Card className="bg-gray-50">
			<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
				{ t("MEASUREMENTS HARMONICS.ripple control signal UR1") }
			</h5>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<BasicRecordingOptionConfiguration
					id={ ID }
					options={ cfgMainsSignallingUr1?.cfgMainsSignallingUr1RecOpt?.options ?? [] }
					formItem={ form.cfgMainsSignallingUr1RecOpt }
					handleChange={ value => handleChange("cfgMainsSignallingUr1RecOpt", value) }
					reducerFormItem={ reducerForm.cfgMainsSignallingUr1RecOpt }
				/>
				<hr className="mx-2"/>
				<div className="flex gap-2.5">
					<Input
						className="flex-1"
						formItem={ form.cfgMainsSignallingUr1Frequency }
						label={ t("MEASUREMENTS HARMONICS.frequency") }
						name="cfgMainsSignallingUr1Frequency"
						inputProps={ {
							type: "number",
							onChange: (e) => handleChange("cfgMainsSignallingUr1Frequency", e.target.value),
							onBlur: () => handleBlur("cfgMainsSignallingUr1Frequency"),
							sizing: "sm",
							className: "[&>span]:!min-w-[40px] [&>span]:justify-center",
						} }
						addonRight={ cfgMainsSignallingUr1?.cfgMainsSignallingUr1Frequency?.unit ?? "-" }
						hasBeenChanged={ reducerForm.cfgMainsSignallingUr1Frequency.value !== reducerForm.cfgMainsSignallingUr1Frequency.initialValue }
					/>
					<div
						className={
							classNames(
								"flex gap-2",
								"xl:flex-[7_1_0%]",
								"flex-[4_1_0%]"
							)
						}
					/>
				</div>
				<hr className="mx-2"/>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(MainsSignallingUr1Card);
