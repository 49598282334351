import { SVG } from "src/app/types/util.types";

export enum ValidRoutes {
	// Settings
	ANALYZER = "/analyzer",
	RECORDINGS = "/recordings",
	COMMUNICATIONS = "/communications",
	INPUT_OUTPUT = "/input-output",
	MEMORY = "/memory",
	FTP_CLIENT = "/ftp-client",
	TIME = "/time",
	MEASUREMENTS_CONFIG = "/measurements",
	EXPORT_IMPORT = "/export-import",
	FIRMWARE = "/firmware-upgrade",
	CREDENTIALS = "/password",
	// Real time
	MEASUREMENTS = "/realtime/measurements",
	WAVEFORMS = "/realtime/waveforms",
	TIMEPLOT = "/realtime/timeplot",
	PHASORS = "/realtime/phasors",
	HARMONICS = "/realtime/harmonics",
	INTERHARMONICS = "/realtime/interharmonics",
	// Events
	STANDARD_EVENTS = "/standard-events",
	USER_EVENTS = "/user-events",
	USERS = "/users",
}

export type ValidRouteObject = {
	name: string
	icon: SVG
	pathname: ValidRoutes
}
