import { useEffect, useRef } from "react";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { FormValidator } from "src/app/types/ui/form.types";
import { SettingsMemory } from "src/app/types/api/settings.types";
import { mapEnumSettingToSelectOptions, round } from "src/app/utils/helpers";
import { createFormField, validateNumberField } from "src/app/utils/forms";
import { Button, Card } from "flowbite-react";
import Input from "src/app/components/Form/Input.component";
import useForm from "src/app/utils/hooks/useForm";
import { memoryModelDictionary } from "src/app/utils/constants/dictionaries";
import { isNull, isNumber } from "src/app/utils/typeguards";
import Select from "src/app/components/Form/Select.component";
import { EnumDictionary } from "src/app/types/util.types";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";
import classNames from "classnames";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settings: SettingsMemory
	};

enum PieIndex {
	COUNTER,
	STANDARD,
	USER,
}

const COUNTER_PROPORTION = 8;
const REAL_COUNTER_PROPORTION = 1;

const convertNumberToReal = (value: number, counterProportion = COUNTER_PROPORTION) => (value * (100 - counterProportion)) / (100 - REAL_COUNTER_PROPORTION);
const convertNumberToAbstract = (value: number, counterProportion = COUNTER_PROPORTION) => (value * (100 - REAL_COUNTER_PROPORTION)) / (100 - counterProportion);

function drawSegment(context: any, piechart: any, centerX: any, centerY: any, radius: any, startingAngle: any, arcSize: any, element: any, collapsed: any) {
	if (collapsed) {
		return;
	}

	let endingAngle = startingAngle + arcSize;
	let r = startingAngle + (endingAngle - startingAngle) / 3;
	let fontSize = Math.floor(context.canvas.height / 25);
	let dx = radius - fontSize;
	let dy = centerY / 10;

	// Draw segment
	context.save();
	context.beginPath();
	context.moveTo(centerX, centerY);
	context.arc(centerX, centerY, radius, startingAngle, endingAngle, false);
	context.strokeStyle = "#d1d5db";
	context.stroke();
	context.closePath();
	context.fillStyle = element.format.color;
	context.fill();
	context.stroke();
	context.restore();

	// Draw label on top
	context.save();
	//////

	context.font = fontSize + "pt Helvetica";

	const absVal = Math.abs(startingAngle) * 2;
	let a;
	let b;

	if (absVal > Math.PI) {
		a = 0 - (radius - fontSize);
		b = 5 - fontSize;
		context.translate(centerX, centerY);
		context.rotate(Math.PI + startingAngle);
		context.textAlign = "left";
	} else {
		a = radius - fontSize;
		b = fontSize + 5;
		context.translate(centerX, centerY);
		context.rotate(startingAngle);
		context.textAlign = "right";
	}

	context.fillText(element.format.label, a, b);
	context.restore();
	context.save();

	////

	context.translate(centerX, centerY);
	context.rotate(r);
	context.textAlign = "right";
	context.font = fontSize + "pt Helvetica";
	// context.fillText(format.label + " " + p + "%", dx, dy);

	// Draw percent label
	context.textAlign = "center";
	context.textBaseline = "middle";
	context.globalAlpha = 0.7;
	context.font = fontSize * 1.5 + "pt Helvetica";
	context.fillStyle = "#0b4362";
	context.translate(dx / 1.13, dy);
	context.rotate(-r);

	const [ energyCountersPerc, stdRecordingPerc, userRecordingPerc ] = piechart.getAllSliceSizePercentages();
	const roundedEnergyCountersPerc = round(energyCountersPerc);
	const roundedStdRecordingPerc = round(convertNumberToAbstract(stdRecordingPerc, round(energyCountersPerc)));
	const roundedUserRecordingPerc = round(convertNumberToAbstract(userRecordingPerc, round(energyCountersPerc)));
	const values = [
		roundedEnergyCountersPerc,
		roundedStdRecordingPerc,
		roundedUserRecordingPerc,
	];

	if (element.index === PieIndex.COUNTER) {
		context.fillText(1, 0, 0);
	} else {
		context.fillText(values[ element.index ], 0, 0);
	}
	context.restore();
}

function drawNode(context: any, piechart: any, x: any, y: any, centerX: any, centerY: any, hover: any, index: any) {
	let TAU = Math.PI * 2;

	if (
		index === PieIndex.COUNTER ||
		index === PieIndex.STANDARD ||
		piechart.disabled
	) {
		return;
	}

	context.save();
	context.translate(centerX, centerY);
	context.fillStyle = "#009FE3";
	context.strokeStyle = "#009FE3";
	let rad = 7; //hover ? 7 : 5;
	context.beginPath();
	context.arc(x, y, rad, 0, TAU, true);
	context.fill();
	context.stroke();
	context.restore();
}

type MemoryForm = {
	spaceMB: number
	userRecordingMinSpaceMB: number
	stdRecordingMinSpaceMB: number
	energyCountersMinSpaceMB: number
	userRecordingPerc: string
	stdRecordingPerc: string
	energyCountersPerc: string
	userDataModel: number
	stdDataModel: number
	energyDataModel: number
}

const validator: FormValidator<MemoryForm> = {
	spaceMB: () => null,
	userRecordingMinSpaceMB: () => null,
	stdRecordingMinSpaceMB: () => null,
	energyCountersMinSpaceMB: () => null,
	userRecordingPerc: (userRecordingPerc, optional, form) => {
		const minPerc = round((form.userRecordingMinSpaceMB.value / form.spaceMB.value) * 100, 1, Math.ceil);

		return validateNumberField(i18n.t("MEMORY.user recording label"), userRecordingPerc, optional, "plural", { from: minPerc, to: 100, decimalPlaces: 1 });
	},
	stdRecordingPerc: (stdRecordingPerc, optional, form) => {
		const minPerc = round((form.stdRecordingMinSpaceMB.value / form.spaceMB.value) * 100, 1, Math.ceil);

		return validateNumberField(i18n.t("MEMORY.standard recording label"), stdRecordingPerc, optional, "plural", { from: minPerc, to: 100, decimalPlaces: 1 });
	},
	energyCountersPerc: (energyCountersPerc, optional, form) => {
		const minPerc = round((form.stdRecordingMinSpaceMB.value / form.spaceMB.value) * 100, 1, Math.ceil);

		return validateNumberField(i18n.t("MEMORY.counters label"), energyCountersPerc, optional, "plural", { from: minPerc, to: 100, decimalPlaces: 1 });
	},
	userDataModel: () => null,
	stdDataModel: () => null,
	energyDataModel: () => null,
};

type ProportionsEntry = {
	label: string
	color: string
}

const pieIndexDictionary: EnumDictionary<PieIndex, ProportionsEntry> = () => ({
	[ PieIndex.COUNTER ]: {
		label: i18n.t("MEMORY.counters"),
		color: "#F0F9FD",
	},
	[ PieIndex.STANDARD ]: {
		label: i18n.t("MEMORY.standard"),
		color: "#E0F2FB",
	},
	[ PieIndex.USER ]: {
		label: i18n.t("MEMORY.user"),
		color: "#C0E4F8",
	},
});

type Proportion = {
	index: PieIndex
	proportion: number
	format: {
		label: string
		color: string
	},
	collapsed: boolean
}

const getProportions = (values: EnumDictionary<PieIndex, number>): Proportion[] =>
	Object.values(PieIndex)
		  .filter((index: string | PieIndex): index is PieIndex => isNumber(index))
		  .map<Proportion>(index => {
			  const {
				  label,
				  color,
			  } = pieIndexDictionary()[ index ];

			  return {
				  index,
				  proportion: values()[ index ],
				  format: {
					  label,
					  color,
				  },
				  collapsed: false,
			  };
		  });

const verifyPercents = (piechart: any) => {
	const [ realEnergyCountersPerc, realStdRecordingPerc, realUserRecordingPerc ] = piechart.getAllSliceSizePercentages();

	const energyCountersPerc = convertNumberToAbstract(realEnergyCountersPerc);
	const stdRecordingPerc = convertNumberToAbstract(realStdRecordingPerc);
	const userRecordingPerc = convertNumberToAbstract(realUserRecordingPerc);

	if (
		round(energyCountersPerc) < piechart.counterPerc ||
		round(stdRecordingPerc) < piechart.minPerc ||
		round(userRecordingPerc) < piechart.minPerc
	) {
		const maxVal = Math.max(energyCountersPerc, stdRecordingPerc, userRecordingPerc);
		const isStandardMax = maxVal === stdRecordingPerc;
		const isUserMax = maxVal === userRecordingPerc;

		const counterValue = piechart.counterPerc;
		let standardValue: number;
		let userValue: number;
		if (isStandardMax) {
			userValue = round(convertNumberToReal(Math.max(userRecordingPerc, piechart.minPerc)));
			standardValue = 100 - counterValue - userValue;
		} else if (isUserMax) {
			standardValue = round(convertNumberToReal(Math.max(stdRecordingPerc, piechart.minPerc)));
			userValue = 100 - counterValue - standardValue;
		} else {
			standardValue = Math.max(stdRecordingPerc, piechart.minPerc);
			userValue = Math.max(userRecordingPerc, piechart.minPerc);
		}

		const proportions = getProportions(() => ({
			[ PieIndex.COUNTER ]: counterValue,
			[ PieIndex.STANDARD ]: standardValue,
			[ PieIndex.USER ]: userValue,
		}));

		piechart.data = piechart.generateDataFromProportions(proportions);
		piechart.draw();
	}
};

function MemoryContainer(props: Props) {

	const { t } = useTranslation();

	const {
		settings,
		isAdmin,
	} = props;

	const reducerForm = useReducerForm(
		"buffor",
		bufforFormActions,
		() => undefined,
	);

	const _handleSubmit = (values: MemoryForm) => {
		reducerForm.handleChange("userRecordingPerc", values.userRecordingPerc);
		reducerForm.handleChange("stdRecordingPerc", values.stdRecordingPerc);
		reducerForm.handleChange("energyCountersPerc", values.energyCountersPerc);
		reducerForm.handleChange("userDataModel", values.userDataModel);
		reducerForm.handleChange("stdDataModel", values.stdDataModel);
		reducerForm.handleChange("energyDataModel", values.energyDataModel);
	};

	const _getInitialState = () => ({
		spaceMB: createFormField(settings?.memory?.infoSpaceAvailable?.value ?? 0, { disabled: !isAdmin }),
		userRecordingMinSpaceMB: createFormField(settings?.memory?.infoUserRecordingMinSpace?.value ?? 0, { disabled: !isAdmin }),
		stdRecordingMinSpaceMB: createFormField(settings?.memory?.infoStdRecordingMinSpace?.value ?? 0, { disabled: !isAdmin }),
		energyCountersMinSpaceMB: createFormField(settings?.memory?.infoEnergyCountersMinSpace?.value ?? 0, { disabled: !isAdmin }),
		userRecordingPerc: createFormField(settings?.memory?.cfgUserRecordingSpaceQuotaInPercent?.value?.toFixed(1) ?? "", { disabled: !isAdmin }),
		stdRecordingPerc: createFormField(settings?.memory?.cfgStdRecordingSpaceQuotaInPercent?.value?.toFixed(1) ?? "", { disabled: !isAdmin }),
		energyCountersPerc: createFormField(settings?.memory?.cfgEnergyCountersSpaceQuotaInPercent?.value?.toFixed(1) ?? "", { disabled: true }),
		userDataModel: createFormField(settings?.memory?.cfgUserDataMemoryModel?.value ?? 0, { disabled: !isAdmin }),
		stdDataModel: createFormField(settings?.memory?.cfgStdDataMemoryModel?.value ?? 0, { disabled: !isAdmin }),
		energyDataModel: createFormField(settings?.memory?.cfgEnergyDataMemoryModel?.value ?? 0, { disabled: !isAdmin }),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	const minUserPerc = round((form.userRecordingMinSpaceMB.value / form.spaceMB.value) * 100, 1, Math.ceil);
	const minStandardPerc = round((form.stdRecordingMinSpaceMB.value / form.spaceMB.value) * 100, 1, Math.ceil);
	const minEnergyPerc = round((form.energyCountersMinSpaceMB.value / form.spaceMB.value) * 100, 1, Math.ceil);

	const canvasRef = useRef<any>(null);

	useEffect(() => {

		const proportions = getProportions(() => ({
			[ PieIndex.COUNTER ]: COUNTER_PROPORTION,
			[ PieIndex.STANDARD ]: round(convertNumberToReal(settings?.memory?.cfgStdRecordingSpaceQuotaInPercent?.value ?? 0)),
			[ PieIndex.USER ]: round(convertNumberToReal(settings?.memory?.cfgUserRecordingSpaceQuotaInPercent?.value ?? 0)),
		}));

		const setup = {
			canvas: document.getElementById("memory-canvas"),
			radius: 0.95,
			collapsing: false,
			proportions: proportions,
			minPerc: round((form.userRecordingMinSpaceMB.value / form.spaceMB.value) * 100, 1, Math.ceil),
			counterPerc: 8,
			blockedIndexes: [ PieIndex.COUNTER, PieIndex.STANDARD ],
			disabled: !isAdmin,
			drawSegment: drawSegment,
			drawNode: drawNode,
			onchange: (piechart: any) => {
				const [ energyCountersPerc, stdRecordingPerc, userRecordingPerc ] = piechart.getAllSliceSizePercentages();
				const roundedEnergyCountersPerc = round(energyCountersPerc);
				const roundedStdRecordingPerc = round(convertNumberToAbstract(stdRecordingPerc, round(energyCountersPerc)));
				const roundedUserRecordingPerc = round(convertNumberToAbstract(userRecordingPerc, round(energyCountersPerc)));

				handleChange("energyCountersPerc", roundedEnergyCountersPerc.toFixed(1));
				handleChange("stdRecordingPerc", roundedStdRecordingPerc.toFixed(1));
				handleChange("userRecordingPerc", roundedUserRecordingPerc.toFixed(1));

				handleBlur("energyCountersPerc");
				handleBlur("stdRecordingPerc");
				handleBlur("userRecordingPerc");
			},
			touchEnd: verifyPercents,
		};

		// @ts-ignore
		canvasRef.current = new DraggablePiechart(setup);
	}, []);

	useEffect(() => {
		handleChange("userRecordingPerc", reducerForm.form.userRecordingPerc.value);
		handleChange("stdRecordingPerc", reducerForm.form.stdRecordingPerc.value);
		handleChange("energyCountersPerc", reducerForm.form.energyCountersPerc.value);
		handleChange("userDataModel", reducerForm.form.userDataModel.value);
		handleChange("stdDataModel", reducerForm.form.stdDataModel.value);
		handleChange("energyDataModel", reducerForm.form.energyDataModel.value);

		if (isNull(canvasRef)) return;

		const proportions = getProportions(() => ({
			[ PieIndex.COUNTER ]: COUNTER_PROPORTION,
			[ PieIndex.USER ]: round(convertNumberToReal(+reducerForm.form.userRecordingPerc.value)),
			[ PieIndex.STANDARD ]: round(convertNumberToReal(+reducerForm.form.stdRecordingPerc.value)),
		}));

		canvasRef.current.data = canvasRef.current.generateDataFromProportions(proportions);
		canvasRef.current.draw();

		verifyPercents(canvasRef.current);

		handleBlur("userRecordingPerc");
		handleBlur("stdRecordingPerc");
	}, [
		reducerForm.form.userRecordingPerc.value,
		reducerForm.form.stdRecordingPerc.value,
		reducerForm.form.energyCountersPerc.value,
		reducerForm.form.userDataModel.value,
		reducerForm.form.stdDataModel.value,
		reducerForm.form.energyDataModel.value,
	]);

	const _handleBlurValue = (prop: "userRecordingPerc" | "stdRecordingPerc") => {
		if (isNull(canvasRef)) return;

		const counterValue = canvasRef.current.counterPerc;

		let userValue: number;
		let standardValue: number;

		if (prop === "userRecordingPerc") {
			userValue = round(convertNumberToReal(+form.userRecordingPerc.value));
			standardValue = 100 - userValue - counterValue;
		} else {
			standardValue = round(convertNumberToReal(+form.stdRecordingPerc.value));
			userValue = 100 - standardValue - counterValue;
		}

		const proportions = getProportions(() => ({
			[ PieIndex.COUNTER ]: counterValue,
			[ PieIndex.STANDARD ]: standardValue,
			[ PieIndex.USER ]: userValue,
		}));

		canvasRef.current.data = canvasRef.current.generateDataFromProportions(proportions);
		canvasRef.current.draw();

		verifyPercents(canvasRef.current);

		handleBlur("userRecordingPerc");
		handleBlur("stdRecordingPerc");
	};

	return (
		<Card>
			<form noValidate className="flex gap-14 justify-between" onSubmit={ handleSubmit }>
				<div className="flex flex-col flex-grow gap-4">
					<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
						{ t("MEMORY.memory") }
					</h5>
					<div className="flex flex-col gap-6">
						<div
							className={
								classNames(
									"flex gap-4",
									"xl:flex-row",
									"flex-col"
								)
							}
						>
							<div className="flex-1">
								<Input
									formItem={ form.userRecordingPerc }
									label={ t("MEMORY.user recording label %") }
									name="userRecordingPerc"
									inputProps={ {
										type: "number",
										min: 0,
										step: 0.1,
										max: 100,
										onChange: (e) => handleChange("userRecordingPerc", e.target.value),
										onBlur: () => _handleBlurValue("userRecordingPerc"),
										helperText: `${ t("MEMORY.minimum") } = ${ minUserPerc }% (${ form.userRecordingMinSpaceMB.value }MB)`,
									} }
									addonRight={ <span className="whitespace-nowrap">{ `= ${ round((+form.userRecordingPerc.value / 100) * form.spaceMB.value, 1) }MB` }</span> }
									hasBeenChanged={ reducerForm.form.userRecordingPerc.value !== reducerForm.form.userRecordingPerc.initialValue }
								/>
							</div>
							<Select
								className="flex-1"
								label={ t("MEMORY.user data recording method") }
								options={ mapEnumSettingToSelectOptions(settings?.memory?.cfgUserDataMemoryModel, memoryModelDictionary) }
								formItem={ form.userDataModel }
								onChange={ option => {
									if (isNull(option)) return;

									handleChange("userDataModel", option?.value);
									handleBlur("userDataModel");
								} }
								isSearchable={ false }
								isClearable={ false }
								hasBeenChanged={ reducerForm.form.userDataModel.value !== reducerForm.form.userDataModel.initialValue }
							/>
						</div>
						<div
							className={
								classNames(
									"flex gap-4",
									"xl:flex-row",
									"flex-col",
								)
							}
						>
							<Input
								className="flex-1"
								formItem={ form.stdRecordingPerc }
								label={ t("MEMORY.standard recording label %") }
								name="stdRecordingPerc"
								inputProps={ {
									type: "number",
									min: 0,
									step: 0.1,
									max: 100,
									onChange: (e) => handleChange("stdRecordingPerc", e.target.value),
									onBlur: () => _handleBlurValue("stdRecordingPerc"),
									helperText: `${ t("MEMORY.minimum") } = ${ minStandardPerc }% (${ form.stdRecordingMinSpaceMB.value }MB)`,
								} }
								addonRight={ <span className="whitespace-nowrap">{ `= ${ round((+form.stdRecordingPerc.value / 100) * form.spaceMB.value, 1) }MB` }</span> }
								hasBeenChanged={ reducerForm.form.stdRecordingPerc.value !== reducerForm.form.stdRecordingPerc.initialValue }
							/>
							<Select
								className="flex-1"
								label={ t("MEMORY.standard data recording method") }
								options={ mapEnumSettingToSelectOptions(settings?.memory?.cfgStdDataMemoryModel, memoryModelDictionary) }
								formItem={ form.stdDataModel }
								onChange={ option => {
									if (isNull(option)) return;

									handleChange("stdDataModel", option?.value);
									handleBlur("stdDataModel");
								} }
								isSearchable={ false }
								isClearable={ false }
								hasBeenChanged={ reducerForm.form.stdDataModel.value !== reducerForm.form.stdDataModel.initialValue }
							/>
						</div>
						<div
							className={
								classNames(
									"flex gap-4",
									"xl:flex-row",
									"flex-col",
								)
							}
						>
							<Input
								className="flex-1"
								formItem={ form.energyCountersPerc }
								label={ t("MEMORY.counters label %") }
								name="energyCountersPerc"
								inputProps={ {
									type: "number",
									min: 0,
									step: 0.1,
									max: 100,
									onChange: (e) => handleChange("energyCountersPerc", e.target.value),
									onBlur: () => handleBlur("energyCountersPerc"),
									// helperText: `Minimum = ${ minEnergyPerc }% (${ form.energyCountersMinSpaceMB.value }MB)`,
								} }
								addonRight={ <span className="whitespace-nowrap">{ `= ${ round((+form.energyCountersPerc.value / 100) * form.spaceMB.value, 1) }MB` }</span> }
								hasBeenChanged={ reducerForm.form.energyCountersPerc.value !== reducerForm.form.energyCountersPerc.initialValue }
							/>
							<Select
								className="flex-1"
								label={ t("MEMORY.energy data recording method") }
								options={ mapEnumSettingToSelectOptions(settings?.memory?.cfgEnergyDataMemoryModel, memoryModelDictionary) }
								formItem={ form.energyDataModel }
								onChange={ option => {
									if (isNull(option)) return;

									handleChange("energyDataModel", option?.value);
									handleBlur("energyDataModel");
								} }
								isSearchable={ false }
								isClearable={ false }
								hasBeenChanged={ reducerForm.form.energyDataModel.value !== reducerForm.form.energyDataModel.initialValue }
							/>
						</div>
						{
							isAdmin &&
                            <div className="flex justify-end items-center gap-2">
							<Button
                                    color="primary"
                                    type="submit"
                                >
									{ t("UTILS.save") }
                                </Button>
                            </div>
						}
					</div>
				</div>
				<div>
					<canvas
						id="memory-canvas"
						width={ 315 }
						height={ 315 }
					>
						{ t("MEMORY.browser is too old") }
					</canvas>
				</div>
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(MemoryContainer);
