import { FormItem } from "src/app/types/ui/form.types";
import { SelectOption } from "src/app/types/util.types";
import MultiSelect from "src/app/components/Form/MultiSelect.component";
import { useTranslation } from "react-i18next";

type Props<T> = {
	options: SelectOption<T>[]
	formItem: FormItem<T[]>
	reducerFormItem: FormItem<T[]>
	handleChange: (value: T[]) => void
	disabled: boolean
};

function RelayChannelChooser<T>(props: Props<T>) {

	const { t } = useTranslation();

	const {
		options,
		formItem,
		reducerFormItem,
		handleChange,
		disabled,
	} = props;

	const _hasBeenChanged = () => {
		if (reducerFormItem.value.length !== reducerFormItem.initialValue.length) return true;

		return reducerFormItem.initialValue.some(field => !reducerFormItem.value.includes(field));
	};

	return (
		/*@ts-ignore*/
		<MultiSelect
			className="flex-1 w-full"
			label={t('MEASUREMENTS.relay channels')}
			inputHeight={ 34 }
			options={ options }
			isDisabled={ disabled }
			formItem={ formItem }
			onChange={ options => handleChange(options.map(option => option.value)) }
			isClearable={ false }
			isSearchable={ false }
			placeholder={t('MEASUREMENTS.select channel')}
			hasBeenChanged={ _hasBeenChanged() }
		/>
	);
}

export default (RelayChannelChooser);
