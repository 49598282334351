import { RecordingOption, SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateNumberField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import BasicRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/BasicRecordingOptionConfiguration.component";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { useContext, useEffect } from "react";
import { MeasurementsUserContext } from "src/app/components/Measurements/MeasurementsUserContent.component";
import EventRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/EventRecordingOptionConfiguration.component";
import Input from "src/app/components/Form/Input.component";
import { Button, Card } from "flowbite-react";
import { EnumOptionsSetting } from "src/app/types/util.types";
import { isOptionSelected } from "src/app/utils/helpers";
import { isChannelsDiff, mapPowerFactorConfig } from "src/app/utils/configuration";
import RelayChannelChooser from "src/app/components/MeasurementsUser/Generic/RelayChannelChooser.component";
import { isEmptyArray, isNotNull } from "src/app/utils/typeguards";
import RelayActionSelect from "src/app/components/MeasurementsUser/Generic/RelayActionSelect.component";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";
import classNames from "classnames";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsMeasurementsUser: SettingsMeasurementsUser
	};

export type PowerFactorForm = {
	cfgPowerFactorRecOpt: number
	cfgPowerFactorRecOptOptions: EnumOptionsSetting<RecordingOption>["options"]
	cfgPowerFactorEventThresholdMax: string
	cfgPowerFactorEventThresholdMaxMin: number
	cfgPowerFactorEventThresholdMaxMax: number
	cfgPowerFactorChannels: ("cfgRelayPowerFactor1RecOpt" | "cfgRelayPowerFactor2RecOpt" | "cfgRelayPowerFactor3RecOpt" | "cfgRelayPowerFactor4RecOpt")[]
	cfgPowerFactorActions: number
}

const validator: FormValidator<PowerFactorForm> = {
	cfgPowerFactorRecOpt: () => null,
	cfgPowerFactorRecOptOptions: () => null,
	cfgPowerFactorEventThresholdMax: (cfgPowerFactorEventThresholdMax, optional, form) => {
		if (!isOptionSelected(form.cfgPowerFactorRecOpt.value, RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE, form.cfgPowerFactorRecOptOptions.value)) return null;

		return validateNumberField(i18n.t("ENERGY.max"), cfgPowerFactorEventThresholdMax, optional, "he", { from: form.cfgPowerFactorEventThresholdMaxMin.value, to: form.cfgPowerFactorEventThresholdMaxMax.value, decimalPlaces: 2 });
	},
	cfgPowerFactorEventThresholdMaxMin: () => null,
	cfgPowerFactorEventThresholdMaxMax: () => null,
	cfgPowerFactorChannels: () => null,
	cfgPowerFactorActions: () => null,
};

const ID = "power-factor";

function PowerFactorCard(props: Props) {

	const { t } = useTranslation();

	const {
		settingsMeasurementsUser: {
			cfgPowerFactor,
		},
		isAdmin,
	} = props;

	const {
		form: reducerForm,
		handleChange: reducerHandleChange,
	} = useReducerForm(
		"buffor",
		bufforFormActions,
		() => null,
	);

	const { cfgUserEnable } = useContext(MeasurementsUserContext);

	const _handleSubmit = (values: PowerFactorForm) => {
		reducerHandleChange("cfgPowerFactorRecOpt", values.cfgPowerFactorRecOpt);
		reducerHandleChange("cfgPowerFactorEventThresholdMax", values.cfgPowerFactorEventThresholdMax);
		reducerHandleChange("cfgPowerFactorChannels", values.cfgPowerFactorChannels);
		reducerHandleChange("cfgPowerFactorActions", values.cfgPowerFactorActions);
	};

	const _getInitialState = () => {
		const [ cfgPowerFactorChannels, cfgPowerFactorActions ] = mapPowerFactorConfig(cfgPowerFactor);
		return {
			cfgPowerFactorRecOpt: createFormField((reducerForm.cfgPowerFactorRecOpt.value !== reducerForm.cfgPowerFactorRecOpt.initialValue) ? reducerForm.cfgPowerFactorRecOpt.value : cfgPowerFactor?.cfgPowerFactorRecOpt?.value ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgPowerFactorRecOptOptions: createFormField(cfgPowerFactor?.cfgPowerFactorRecOpt?.options ?? []),
			cfgPowerFactorEventThresholdMax: createFormField((reducerForm.cfgPowerFactorEventThresholdMax.value !== reducerForm.cfgPowerFactorEventThresholdMax.initialValue) ? reducerForm.cfgPowerFactorEventThresholdMax.value : cfgPowerFactor?.cfgPowerFactorEventThresholdMax?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
			cfgPowerFactorEventThresholdMaxMin: createFormField(cfgPowerFactor?.cfgPowerFactorEventThresholdMax?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgPowerFactorEventThresholdMaxMax: createFormField(cfgPowerFactor?.cfgPowerFactorEventThresholdMax?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgPowerFactorChannels: createFormField(isChannelsDiff(reducerForm.cfgPowerFactorChannels) ? reducerForm.cfgPowerFactorChannels.value : cfgPowerFactorChannels, { disabled: !isAdmin || !cfgUserEnable }),
			cfgPowerFactorActions: createFormField((reducerForm.cfgPowerFactorActions.value !== reducerForm.cfgPowerFactorActions.initialValue) ? reducerForm.cfgPowerFactorActions.value : cfgPowerFactorActions, { disabled: !isAdmin || !cfgUserEnable }),
		};
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ cfgPowerFactor ]);

	useEffect(() => {
		toggleDisable("cfgPowerFactorRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgPowerFactorEventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgPowerFactorChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgPowerFactorActions", !cfgUserEnable || !isAdmin);
	}, [ cfgUserEnable ]);

	useEffect(() => {
		toggleDisable("cfgPowerFactorRecOpt", false);
		toggleDisable("cfgPowerFactorEventThresholdMax", false);
		toggleDisable("cfgPowerFactorChannels", false);
		toggleDisable("cfgPowerFactorActions", false);

		handleChange("cfgPowerFactorRecOpt", reducerForm.cfgPowerFactorRecOpt.value);
		handleChange("cfgPowerFactorEventThresholdMax", reducerForm.cfgPowerFactorEventThresholdMax.value);
		handleChange("cfgPowerFactorChannels", reducerForm.cfgPowerFactorChannels.value);
		handleChange("cfgPowerFactorActions", reducerForm.cfgPowerFactorActions.value);

		toggleDisable("cfgPowerFactorRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgPowerFactorEventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgPowerFactorChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgPowerFactorActions", !cfgUserEnable || !isAdmin);
	}, [
		reducerForm.cfgPowerFactorRecOpt.value,
		reducerForm.cfgPowerFactorEventThresholdMax.value,
		reducerForm.cfgPowerFactorChannels.value,
		reducerForm.cfgPowerFactorActions.value,
	]);

	return (
		<Card className="bg-gray-50">
			<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
				{ t("POWER.power factor") }
			</h5>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<BasicRecordingOptionConfiguration
					id={ ID }
					options={ cfgPowerFactor?.cfgPowerFactorRecOpt?.options ?? [] }
					formItem={ form.cfgPowerFactorRecOpt }
					handleChange={ value => handleChange("cfgPowerFactorRecOpt", value) }
					reducerFormItem={ reducerForm.cfgPowerFactorRecOpt }
				/>
				<div className="flex flex-col gap-3">
					<h5 className="text-base sm:text-xl font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
						{ t("POWER.events") }
					</h5>
					<EventRecordingOptionConfiguration
						id={ ID }
						options={ cfgPowerFactor?.cfgPowerFactorRecOpt?.options ?? [] }
						formItem={ form.cfgPowerFactorRecOpt }
						handleChange={ value => handleChange("cfgPowerFactorRecOpt", value) }
						reducerFormItem={ reducerForm.cfgPowerFactorRecOpt }
					>
						{
							(isLogEventEnabled) =>
								<div className="flex flex-col gap-2.5">
									<h5 className="text-sm sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
										{ t("POWER.thresholds") }
									</h5>
									<hr className="mx-2"/>
									<div className="flex gap-2.5 flex-1">
										<Input
											className="flex-1"
											formItem={ form.cfgPowerFactorEventThresholdMax }
											label={ t("POWER.max") }
											name="cfgPowerFactorEventThresholdMax"
											inputProps={ {
												type: "number",
												onChange: (e) => handleChange("cfgPowerFactorEventThresholdMax", e.target.value),
												onBlur: () => handleBlur("cfgPowerFactorEventThresholdMax"),
												disabled: !isLogEventEnabled,
												sizing: "sm",
											} }
											hasBeenChanged={ reducerForm.cfgPowerFactorEventThresholdMax.value !== reducerForm.cfgPowerFactorEventThresholdMax.initialValue }
										/>
										<div
											className={
												classNames(
													"flex gap-2",
													"xl:flex-[7_1_0%]",
													"flex-[4_1_0%]",
												)
											}
										>
											<RelayChannelChooser
												options={ [
													(isNotNull(cfgPowerFactor) && isNotNull(cfgPowerFactor.relayChannelLabel) && isNotNull(cfgPowerFactor.relayChannelLabel.ch1)) ? {
														value: "cfgRelayPowerFactor1RecOpt",
														label: cfgPowerFactor.relayChannelLabel.ch1,
													} : null,
													(isNotNull(cfgPowerFactor) && isNotNull(cfgPowerFactor.relayChannelLabel) && isNotNull(cfgPowerFactor.relayChannelLabel.ch2)) ? {
														value: "cfgRelayPowerFactor2RecOpt",
														label: cfgPowerFactor.relayChannelLabel.ch2,
													} : null,
													(isNotNull(cfgPowerFactor) && isNotNull(cfgPowerFactor.relayChannelLabel) && isNotNull(cfgPowerFactor.relayChannelLabel.ch3)) ? {
														value: "cfgRelayPowerFactor3RecOpt",
														label: cfgPowerFactor.relayChannelLabel.ch3,
													} : null,
													(isNotNull(cfgPowerFactor) && isNotNull(cfgPowerFactor.relayChannelLabel) && isNotNull(cfgPowerFactor.relayChannelLabel.ch4)) ? {
														value: "cfgRelayPowerFactor4RecOpt",
														label: cfgPowerFactor.relayChannelLabel.ch4,
													} : null,
												].filter(isNotNull) }
												disabled={ !isLogEventEnabled }
												formItem={ form.cfgPowerFactorChannels }
												reducerFormItem={ reducerForm.cfgPowerFactorChannels }
												handleChange={ value => handleChange("cfgPowerFactorChannels", value) }
											/>
											<RelayActionSelect
												formItem={ form.cfgPowerFactorActions }
												reducerFormItem={ reducerForm.cfgPowerFactorActions }
												options={ cfgPowerFactor?.cfgRelayPowerFactor1Map?.options ?? [] }
												disabled={ !isLogEventEnabled || isEmptyArray(form.cfgPowerFactorChannels.value) }
												handleChange={ value => handleChange("cfgPowerFactorActions", value) }
											/>
										</div>
									</div>
									<hr className="mx-2"/>
								</div>
						}
					</EventRecordingOptionConfiguration>
				</div>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(PowerFactorCard);
