import { SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateNumberField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import BasicRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/BasicRecordingOptionConfiguration.component";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { useContext, useEffect } from "react";
import { MeasurementsUserContext } from "src/app/components/Measurements/MeasurementsUserContent.component";
import EventRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/EventRecordingOptionConfiguration.component";
import Input from "src/app/components/Form/Input.component";
import { Button, Card } from "flowbite-react";
import { Trans, useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsMeasurementsUser: SettingsMeasurementsUser
	};

type ReactiveEnergy4qForm = {
	cfgReactiveEnergy4QRecOpt: number
	cfgReactiveEnergyQuadrant1EventThresholdMax: string
	cfgReactiveEnergyQuadrant1EventThresholdMaxMin: number
	cfgReactiveEnergyQuadrant1EventThresholdMaxMax: number
	cfgReactiveEnergyQuadrant2EventThresholdMax: string
	cfgReactiveEnergyQuadrant2EventThresholdMaxMin: number
	cfgReactiveEnergyQuadrant2EventThresholdMaxMax: number
	cfgReactiveEnergyQuadrant3EventThresholdMax: string
	cfgReactiveEnergyQuadrant3EventThresholdMaxMin: number
	cfgReactiveEnergyQuadrant3EventThresholdMaxMax: number
	cfgReactiveEnergyQuadrant4EventThresholdMax: string
	cfgReactiveEnergyQuadrant4EventThresholdMaxMin: number
	cfgReactiveEnergyQuadrant4EventThresholdMaxMax: number
}

const validator: FormValidator<ReactiveEnergy4qForm> = {
	cfgReactiveEnergy4QRecOpt: () => null,
	cfgReactiveEnergyQuadrant1EventThresholdMax: (cfgReactiveEnergyQuadrant1EventThresholdMax, optional, form) => validateNumberField(`(QI) ${ i18n.t("ENERGY.max") } EQL+`, cfgReactiveEnergyQuadrant1EventThresholdMax, optional, "he", { from: form.cfgReactiveEnergyQuadrant1EventThresholdMaxMin.value, to: form.cfgReactiveEnergyQuadrant1EventThresholdMaxMax.value, decimalPlaces: 2 }),
	cfgReactiveEnergyQuadrant1EventThresholdMaxMin: () => null,
	cfgReactiveEnergyQuadrant1EventThresholdMaxMax: () => null,
	cfgReactiveEnergyQuadrant2EventThresholdMax: (cfgReactiveEnergyQuadrant2EventThresholdMax, optional, form) => validateNumberField(`(QII) ${ i18n.t("ENERGY.max") } EQC-`, cfgReactiveEnergyQuadrant2EventThresholdMax, optional, "he", { from: form.cfgReactiveEnergyQuadrant2EventThresholdMaxMin.value, to: form.cfgReactiveEnergyQuadrant2EventThresholdMaxMax.value, decimalPlaces: 2 }),
	cfgReactiveEnergyQuadrant2EventThresholdMaxMin: () => null,
	cfgReactiveEnergyQuadrant2EventThresholdMaxMax: () => null,
	cfgReactiveEnergyQuadrant3EventThresholdMax: (cfgReactiveEnergyQuadrant3EventThresholdMax, optional, form) => validateNumberField(`(QIII) ${ i18n.t("ENERGY.max") } EQL-`, cfgReactiveEnergyQuadrant3EventThresholdMax, optional, "he", { from: form.cfgReactiveEnergyQuadrant3EventThresholdMaxMin.value, to: form.cfgReactiveEnergyQuadrant3EventThresholdMaxMax.value, decimalPlaces: 2 }),
	cfgReactiveEnergyQuadrant3EventThresholdMaxMin: () => null,
	cfgReactiveEnergyQuadrant3EventThresholdMaxMax: () => null,
	cfgReactiveEnergyQuadrant4EventThresholdMax: (cfgReactiveEnergyQuadrant4EventThresholdMax, optional, form) => validateNumberField(`(QIV) ${ i18n.t("ENERGY.max") } EQC+`, cfgReactiveEnergyQuadrant4EventThresholdMax, optional, "he", { from: form.cfgReactiveEnergyQuadrant4EventThresholdMaxMin.value, to: form.cfgReactiveEnergyQuadrant4EventThresholdMaxMax.value, decimalPlaces: 2 }),
	cfgReactiveEnergyQuadrant4EventThresholdMaxMin: () => null,
	cfgReactiveEnergyQuadrant4EventThresholdMaxMax: () => null,
};

const ID = "reactive-energy-4q";

function ReactiveEnergy4QCard(props: Props) {

	const { t } = useTranslation();

	const {
		settingsMeasurementsUser: {
			cfgReactiveEnergy4Q,
		},
		isAdmin,
	} = props;

	const {
		form: reducerForm,
		handleChange: reducerHandleChange,
	} = useReducerForm(
		"buffor",
		bufforFormActions,
		() => null,
	);

	const { cfgUserEnable } = useContext(MeasurementsUserContext);

	const _handleSubmit = (values: ReactiveEnergy4qForm) => {
		reducerHandleChange("cfgReactiveEnergy4QRecOpt", values.cfgReactiveEnergy4QRecOpt);
		reducerHandleChange("cfgReactiveEnergyQuadrant1EventThresholdMax", values.cfgReactiveEnergyQuadrant1EventThresholdMax);
		reducerHandleChange("cfgReactiveEnergyQuadrant2EventThresholdMax", values.cfgReactiveEnergyQuadrant2EventThresholdMax);
		reducerHandleChange("cfgReactiveEnergyQuadrant3EventThresholdMax", values.cfgReactiveEnergyQuadrant3EventThresholdMax);
		reducerHandleChange("cfgReactiveEnergyQuadrant4EventThresholdMax", values.cfgReactiveEnergyQuadrant4EventThresholdMax);
	};

	const _getInitialState = () => ({
		cfgReactiveEnergy4QRecOpt: createFormField((reducerForm.cfgReactiveEnergy4QRecOpt.value !== reducerForm.cfgReactiveEnergy4QRecOpt.initialValue) ? reducerForm.cfgReactiveEnergy4QRecOpt.value : cfgReactiveEnergy4Q?.cfgReactiveEnergy4QRecOpt?.value ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		cfgReactiveEnergyQuadrant1EventThresholdMax: createFormField((reducerForm.cfgReactiveEnergyQuadrant1EventThresholdMax.value !== reducerForm.cfgReactiveEnergyQuadrant1EventThresholdMax.initialValue) ? reducerForm.cfgReactiveEnergyQuadrant1EventThresholdMax.value : cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant1EventThresholdMax?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
		cfgReactiveEnergyQuadrant1EventThresholdMaxMin: createFormField(cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant1EventThresholdMax?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		cfgReactiveEnergyQuadrant1EventThresholdMaxMax: createFormField(cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant1EventThresholdMax?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		cfgReactiveEnergyQuadrant2EventThresholdMax: createFormField((reducerForm.cfgReactiveEnergyQuadrant2EventThresholdMax.value !== reducerForm.cfgReactiveEnergyQuadrant2EventThresholdMax.initialValue) ? reducerForm.cfgReactiveEnergyQuadrant2EventThresholdMax.value : cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant2EventThresholdMax?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
		cfgReactiveEnergyQuadrant2EventThresholdMaxMin: createFormField(cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant2EventThresholdMax?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		cfgReactiveEnergyQuadrant2EventThresholdMaxMax: createFormField(cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant2EventThresholdMax?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		cfgReactiveEnergyQuadrant3EventThresholdMax: createFormField((reducerForm.cfgReactiveEnergyQuadrant3EventThresholdMax.value !== reducerForm.cfgReactiveEnergyQuadrant3EventThresholdMax.initialValue) ? reducerForm.cfgReactiveEnergyQuadrant3EventThresholdMax.value : cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant3EventThresholdMax?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
		cfgReactiveEnergyQuadrant3EventThresholdMaxMin: createFormField(cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant3EventThresholdMax?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		cfgReactiveEnergyQuadrant3EventThresholdMaxMax: createFormField(cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant3EventThresholdMax?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		cfgReactiveEnergyQuadrant4EventThresholdMax: createFormField((reducerForm.cfgReactiveEnergyQuadrant4EventThresholdMax.value !== reducerForm.cfgReactiveEnergyQuadrant4EventThresholdMax.initialValue) ? reducerForm.cfgReactiveEnergyQuadrant4EventThresholdMax.value : cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant4EventThresholdMax?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
		cfgReactiveEnergyQuadrant4EventThresholdMaxMin: createFormField(cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant4EventThresholdMax?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		cfgReactiveEnergyQuadrant4EventThresholdMaxMax: createFormField(cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant4EventThresholdMax?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ cfgReactiveEnergy4Q ]);

	useEffect(() => {
		toggleDisable("cfgReactiveEnergy4QRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgReactiveEnergyQuadrant1EventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgReactiveEnergyQuadrant2EventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgReactiveEnergyQuadrant3EventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgReactiveEnergyQuadrant4EventThresholdMax", !cfgUserEnable || !isAdmin);
	}, [ cfgUserEnable ]);

	useEffect(() => {
		toggleDisable("cfgReactiveEnergy4QRecOpt", false);
		toggleDisable("cfgReactiveEnergyQuadrant1EventThresholdMax", false);
		toggleDisable("cfgReactiveEnergyQuadrant2EventThresholdMax", false);
		toggleDisable("cfgReactiveEnergyQuadrant3EventThresholdMax", false);
		toggleDisable("cfgReactiveEnergyQuadrant4EventThresholdMax", false);

		handleChange("cfgReactiveEnergy4QRecOpt", reducerForm.cfgReactiveEnergy4QRecOpt.value);
		handleChange("cfgReactiveEnergyQuadrant1EventThresholdMax", reducerForm.cfgReactiveEnergyQuadrant1EventThresholdMax.value);
		handleChange("cfgReactiveEnergyQuadrant2EventThresholdMax", reducerForm.cfgReactiveEnergyQuadrant2EventThresholdMax.value);
		handleChange("cfgReactiveEnergyQuadrant3EventThresholdMax", reducerForm.cfgReactiveEnergyQuadrant3EventThresholdMax.value);
		handleChange("cfgReactiveEnergyQuadrant4EventThresholdMax", reducerForm.cfgReactiveEnergyQuadrant4EventThresholdMax.value);

		toggleDisable("cfgReactiveEnergy4QRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgReactiveEnergyQuadrant1EventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgReactiveEnergyQuadrant2EventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgReactiveEnergyQuadrant3EventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgReactiveEnergyQuadrant4EventThresholdMax", !cfgUserEnable || !isAdmin);
	}, [
		reducerForm.cfgReactiveEnergy4QRecOpt.value,
		reducerForm.cfgReactiveEnergyQuadrant1EventThresholdMax.value,
		reducerForm.cfgReactiveEnergyQuadrant2EventThresholdMax.value,
		reducerForm.cfgReactiveEnergyQuadrant3EventThresholdMax.value,
		reducerForm.cfgReactiveEnergyQuadrant4EventThresholdMax.value,
	]);

	return (
		<Card className="bg-gray-50">
			<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
				<Trans i18nKey="ENERGY.reactive energy" components={ { sub: <sub/> } }/>
			</h5>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<BasicRecordingOptionConfiguration
					id={ ID }
					options={ cfgReactiveEnergy4Q?.cfgReactiveEnergy4QRecOpt?.options ?? [] }
					formItem={ form.cfgReactiveEnergy4QRecOpt }
					handleChange={ value => handleChange("cfgReactiveEnergy4QRecOpt", value) }
					reducerFormItem={ reducerForm.cfgReactiveEnergy4QRecOpt }
				/>
				<div className="flex flex-col gap-3">
					<h5 className="text-base sm:text-xl font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
						{ t("ENERGY.events") }
					</h5>
					<EventRecordingOptionConfiguration
						id={ ID }
						options={ cfgReactiveEnergy4Q?.cfgReactiveEnergy4QRecOpt?.options ?? [] }
						formItem={ form.cfgReactiveEnergy4QRecOpt }
						handleChange={ value => handleChange("cfgReactiveEnergy4QRecOpt", value) }
						reducerFormItem={ reducerForm.cfgReactiveEnergy4QRecOpt }
					>

						{
							(isLogEventEnabled) =>
								<div className="flex flex-col gap-2.5">
									<h5 className="text-sm sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
										{ t("ENERGY.thresholds") }
									</h5>
									<hr className="mx-2"/>
									<div className="flex gap-2.5 flex-1">
										<div className="flex flex-col gap-2.5">
											<Input
												className="flex-1"
												formItem={ form.cfgReactiveEnergyQuadrant1EventThresholdMax }
												label={
													<Trans i18nKey="ENERGY.reactive energy q1" components={ { sub: <sub/> } }/>
												}
												name="cfgReactiveEnergyQuadrant1EventThresholdMax"
												inputProps={ {
													type: "number",
													onChange: (e) => handleChange("cfgReactiveEnergyQuadrant1EventThresholdMax", e.target.value),
													onBlur: () => handleBlur("cfgReactiveEnergyQuadrant1EventThresholdMax"),
													disabled: !isLogEventEnabled,
													sizing: "sm",
													className: "[&>span]:!min-w-[40px] [&>span]:justify-center",
												} }
												addonRight={ cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant1EventThresholdMax?.unit ?? "-" }
												hasBeenChanged={ reducerForm.cfgReactiveEnergyQuadrant1EventThresholdMax.value !== reducerForm.cfgReactiveEnergyQuadrant1EventThresholdMax.initialValue }
											/>
											<Input
												className="flex-1"
												formItem={ form.cfgReactiveEnergyQuadrant2EventThresholdMax }
												label={
													<Trans i18nKey="ENERGY.reactive energy q2" components={ { sub: <sub/> } }/>
												}
												name="cfgReactiveEnergyQuadrant2EventThresholdMax"
												inputProps={ {
													type: "number",
													onChange: (e) => handleChange("cfgReactiveEnergyQuadrant2EventThresholdMax", e.target.value),
													onBlur: () => handleBlur("cfgReactiveEnergyQuadrant2EventThresholdMax"),
													disabled: !isLogEventEnabled,
													sizing: "sm",
													className: "[&>span]:!min-w-[40px] [&>span]:justify-center",
												} }
												addonRight={ cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant2EventThresholdMax?.unit ?? "-" }
												hasBeenChanged={ reducerForm.cfgReactiveEnergyQuadrant2EventThresholdMax.value !== reducerForm.cfgReactiveEnergyQuadrant2EventThresholdMax.initialValue }
											/>
											<Input
												className="flex-1"
												formItem={ form.cfgReactiveEnergyQuadrant3EventThresholdMax }
												label={
													<Trans i18nKey="ENERGY.reactive energy q3" components={ { sub: <sub/> } }/>
												}
												name="cfgReactiveEnergyQuadrant3EventThresholdMax"
												inputProps={ {
													type: "number",
													onChange: (e) => handleChange("cfgReactiveEnergyQuadrant3EventThresholdMax", e.target.value),
													onBlur: () => handleBlur("cfgReactiveEnergyQuadrant3EventThresholdMax"),
													disabled: !isLogEventEnabled,
													sizing: "sm",
													className: "[&>span]:!min-w-[40px] [&>span]:justify-center",
												} }
												addonRight={ cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant3EventThresholdMax?.unit ?? "-" }
												hasBeenChanged={ reducerForm.cfgReactiveEnergyQuadrant3EventThresholdMax.value !== reducerForm.cfgReactiveEnergyQuadrant3EventThresholdMax.initialValue }
											/>
											<Input
												className="flex-1"
												formItem={ form.cfgReactiveEnergyQuadrant4EventThresholdMax }
												label={
													<Trans i18nKey="ENERGY.reactive energy q4" components={ { sub: <sub/> } }/>
												}
												name="cfgReactiveEnergyQuadrant4EventThresholdMax"
												inputProps={ {
													type: "number",
													onChange: (e) => handleChange("cfgReactiveEnergyQuadrant4EventThresholdMax", e.target.value),
													onBlur: () => handleBlur("cfgReactiveEnergyQuadrant4EventThresholdMax"),
													disabled: !isLogEventEnabled,
													sizing: "sm",
													className: "[&>span]:!min-w-[40px] [&>span]:justify-center",
												} }
												addonRight={ cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant4EventThresholdMax?.unit ?? "-" }
												hasBeenChanged={ reducerForm.cfgReactiveEnergyQuadrant4EventThresholdMax.value !== reducerForm.cfgReactiveEnergyQuadrant4EventThresholdMax.initialValue }
											/>
										</div>
										<div className="flex-[7_1_0%] items-center flex gap-2">
											{/*<RelayChannelChooser
												options={ [
													isNotNull(cfgCurrentRms.relayChannelLabel.ch1) ? {
														value: "cfgRelayCurrent1RecOpt",
														label: cfgCurrentRms.relayChannelLabel.ch1,
													} : null,
													isNotNull(cfgCurrentRms.relayChannelLabel.ch2) ? {
														value: "cfgRelayCurrent2RecOpt",
														label: cfgCurrentRms.relayChannelLabel.ch2,
													} : null,
													isNotNull(cfgCurrentRms.relayChannelLabel.ch3) ? {
														value: "cfgRelayCurrent3RecOpt",
														label: cfgCurrentRms.relayChannelLabel.ch3,
													} : null,
													isNotNull(cfgCurrentRms.relayChannelLabel.ch4) ? {
														value: "cfgRelayCurrent4RecOpt",
														label: cfgCurrentRms.relayChannelLabel.ch4,
													} : null,
												].filter(isNotNull) }
												disabled={ !isLogEventEnabled }
												formItem={ form.cfgCurrentChannels }
												reducerFormItem={ reducerForm.cfgCurrentChannels }
												handleChange={ value => handleChange("cfgCurrentChannels", value) }
											/>
											<RelayActionSelect
												formItem={ form.cfgCurrentActions }
												reducerFormItem={ reducerForm.cfgCurrentActions }
												options={ cfgCurrentRms.cfgRelayCurrent1Map.options }
												disabled={ !isLogEventEnabled || isEmptyArray(form.cfgCurrentChannels.value) }
												handleChange={ value => handleChange("cfgCurrentActions", value) }
											/>*/ }
										</div>
									</div>
									<hr className="mx-2"/>
								</div>
						}
					</EventRecordingOptionConfiguration>
				</div>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(ReactiveEnergy4QCard);
