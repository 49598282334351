import { createAction } from "typesafe-actions";
import { CreateUserPayload, DeleteUserPayload, LoginPayload, ResetPasswordPayload, UpdateUserPasswordPayload } from "src/app/types/api/user.types";

// Auth
export const uiLogin = createAction("UI_USER__LOGIN")<LoginPayload>();
export const uiAuthMe = createAction("UI_USER__AUTH_ME")<undefined>();

export const uiChangePassword = createAction("UI_USER__CHANGE_PASSWORD")<UpdateUserPasswordPayload>();

export const uiFetchUsers = createAction("UI_USER__FETCH_USERS")<undefined>();
export const uiCreateUser = createAction("UI_USER__CREATE_USER")<CreateUserPayload>();
export const uiDeleteUser = createAction("UI_USER__DELETE_USER")<DeleteUserPayload>();
export const uiResetPassword = createAction("UI_USER__RESET_PASSWORD")<ResetPasswordPayload>();