import { useEffect, useState } from "react";
import { FormItem } from "src/app/types/ui/form.types";
import Select, { createFilter, MenuPlacement, MultiValue } from "react-select";
import { isNotNull } from "src/app/utils/typeguards";
import { SelectOption } from "src/app/types/util.types";
import { Label, LabelProps } from "flowbite-react";
import classNames from "classnames";
import { getMultiSelectStyles, getSelectClassNames } from "src/app/utils/ui";
import { useTranslation } from "react-i18next";

type Props<T> =
	{
		className?: string
		label?: string | ReactNode
		labelProps?: LabelProps
		options: SelectOption<T>[]
		formItem: FormItem<T[]>
		onChange: (options: SelectOption<T>[]) => void
		displayErrorMessage?: boolean
		isSearchable?: boolean
		isClearable?: boolean
		isDisabled?: boolean
		portalEl?: HTMLElement
		menuPlacement?: MenuPlacement
		optionsHeight?: number
		inputHeight?: number
		hasBeenChanged?: boolean
		placeholder?: string
	}
	& (T extends object ? { compareValue: (a: T[], b: T) => boolean } : { compareValue?: never });

function MultiSelect<T>(props: Props<T>) {

	const { t } = useTranslation();

	const {
		className,
		label,
		labelProps,
		options,
		formItem,
		onChange,
		displayErrorMessage = true,
		isSearchable = true,
		isClearable = true,
		isDisabled = false,
		compareValue,
		menuPlacement = "auto",
		portalEl,
		optionsHeight = 240,
		inputHeight = 34,
		hasBeenChanged = false,
		placeholder = t('SETTINGS.select option'),
	} = props;

	useEffect(() => {
		setValues(options.filter(option => compareValue?.(formItem.value, option.value) ?? formItem.value.includes(option.value)));
	}, [ formItem.value, options ]);

	const [ inputValue, onInputValueChange ] = useState("");
	const [ values, setValues ] = useState<SelectOption<T>[]>([]);

	const _handleChange = (newValue: MultiValue<SelectOption<T>>) => {
		setValues([ ...newValue ]);
		onChange([ ...newValue ]);
	};

	return (
		<div className={ classNames(className, "flex flex-col gap-y-0.5") }>
			{
				isNotNull(label) &&
                <Label { ...labelProps } disabled={ formItem.disabled || isDisabled }>
					{ label }
                </Label>
			}
			<Select
				styles={ getMultiSelectStyles<T, true>(optionsHeight, inputHeight) }
				classNames={ getSelectClassNames<T, true>(formItem, isClearable, hasBeenChanged) }
				inputValue={ inputValue }
				menuPlacement={ menuPlacement }
				isClearable={ isClearable }
				isSearchable={ isSearchable }
				isMulti
				isDisabled={ formItem.disabled || isDisabled }
				onChange={ _handleChange }
				onInputChange={ onInputValueChange }
				placeholder={ placeholder }
				options={ options }
				value={ values }
				menuPortalTarget={ portalEl }
				filterOption={ createFilter({ ignoreAccents: false }) }
			/>
			{
				(isNotNull(formItem.error) && displayErrorMessage) &&
                <div className="text-sm text-red-600 dark:text-red-500 font-medium">
					{ formItem.error }
                </div>
			}
		</div>
	);
}

export default (MultiSelect);
