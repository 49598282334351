import { SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateNumberField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import BasicRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/BasicRecordingOptionConfiguration.component";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { useContext, useEffect } from "react";
import { MeasurementsUserContext } from "src/app/components/Measurements/MeasurementsUserContent.component";
import { Button, Card } from "flowbite-react";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsMeasurementsUser: SettingsMeasurementsUser
	};

type ApparentEnergyForm = {
	cfgApparentEnergyRecOpt: number
	cfgApparentEnergyEventThresholdMax: string
	cfgApparentEnergyEventThresholdMaxMin: number
	cfgApparentEnergyEventThresholdMaxMax: number
}

const validator: FormValidator<ApparentEnergyForm> = {
	cfgApparentEnergyRecOpt: () => null,
	cfgApparentEnergyEventThresholdMax: (cfgApparentEnergyEventThresholdMax, optional, form) => validateNumberField(i18n.t("ENERGY.max"), cfgApparentEnergyEventThresholdMax, optional, "he", { from: form.cfgApparentEnergyEventThresholdMaxMin.value, to: form.cfgApparentEnergyEventThresholdMaxMax.value, decimalPlaces: 2 }),
	cfgApparentEnergyEventThresholdMaxMin: () => null,
	cfgApparentEnergyEventThresholdMaxMax: () => null,
};

const ID = "apparent-energy";

function ApparentEnergyCard(props: Props) {

	const { t } = useTranslation();

	const {
		settingsMeasurementsUser: {
			cfgApparentEnergy,
		},
		isAdmin,
	} = props;

	const {
		form: reducerForm,
		handleChange: reducerHandleChange,
	} = useReducerForm(
		"buffor",
		bufforFormActions,
		() => null,
	);

	const { cfgUserEnable } = useContext(MeasurementsUserContext);

	const _handleSubmit = (values: ApparentEnergyForm) => {
		reducerHandleChange("cfgApparentEnergyRecOpt", values.cfgApparentEnergyRecOpt);
		reducerHandleChange("cfgApparentEnergyEventThresholdMax", values.cfgApparentEnergyEventThresholdMax);
	};

	const _getInitialState = () => ({
		cfgApparentEnergyRecOpt: createFormField((reducerForm.cfgApparentEnergyRecOpt.value !== reducerForm.cfgApparentEnergyRecOpt.initialValue) ? reducerForm.cfgApparentEnergyRecOpt.value : cfgApparentEnergy?.cfgApparentEnergyRecOpt?.value ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		cfgApparentEnergyEventThresholdMax: createFormField((reducerForm.cfgApparentEnergyEventThresholdMax.value !== reducerForm.cfgApparentEnergyEventThresholdMax.initialValue) ? reducerForm.cfgApparentEnergyEventThresholdMax.value : cfgApparentEnergy?.cfgApparentEnergyEventThresholdMax?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
		cfgApparentEnergyEventThresholdMaxMin: createFormField(cfgApparentEnergy?.cfgApparentEnergyEventThresholdMax?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		cfgApparentEnergyEventThresholdMaxMax: createFormField(cfgApparentEnergy?.cfgApparentEnergyEventThresholdMax?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		toggleDisable("cfgApparentEnergyRecOpt", false);
		toggleDisable("cfgApparentEnergyEventThresholdMax", false);

		handleChange("cfgApparentEnergyRecOpt", reducerForm.cfgApparentEnergyRecOpt.value);
		handleChange("cfgApparentEnergyEventThresholdMax", reducerForm.cfgApparentEnergyEventThresholdMax.value);

		toggleDisable("cfgApparentEnergyRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgApparentEnergyEventThresholdMax", !cfgUserEnable || !isAdmin);
	}, [
		reducerForm.cfgApparentEnergyRecOpt.value,
		reducerForm.cfgApparentEnergyEventThresholdMax.value,
	]);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ cfgApparentEnergy ]);

	useEffect(() => {
		toggleDisable("cfgApparentEnergyRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgApparentEnergyEventThresholdMax", !cfgUserEnable || !isAdmin);
	}, [ cfgUserEnable ]);

	return (
		<Card className="bg-gray-50">
			<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
				{ t("ENERGY.apparent energy Es") }
			</h5>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<BasicRecordingOptionConfiguration
					id={ ID }
					options={ cfgApparentEnergy?.cfgApparentEnergyRecOpt?.options ?? [] }
					formItem={ form.cfgApparentEnergyRecOpt }
					handleChange={ value => handleChange("cfgApparentEnergyRecOpt", value) }
					reducerFormItem={ reducerForm.cfgApparentEnergyRecOpt } // fixme
				/>
				{/*				<hr/>
				<EventRecordingOptionConfiguration
					id={ ID }
					options={ cfgApparentEnergy.cfgApparentEnergyRecOpt.options }
					formItem={ form.cfgApparentEnergyRecOpt }
					handleChange={ value => handleChange("cfgApparentEnergyRecOpt", value) }
					reducerFormItem={ reducerForm.cfgApparentEnergyRecOpt } // fixme
				>
					{
						(isLogEventEnabled) =>
							<div className="flex flex-col gap-1">
								<Input
									className="flex-1"
									formItem={ form.cfgApparentEnergyEventThresholdMax }
									label={ `Max [${ cfgApparentEnergy.cfgApparentEnergyEventThresholdMax.unit }]` }
									name="cfgApparentEnergyEventThresholdMax"
									inputProps={ {
										type: "number",
										onChange: (e) => handleChange("cfgApparentEnergyEventThresholdMax", e.target.value),
										onBlur: () => handleBlur("cfgApparentEnergyEventThresholdMax"),
										disabled: !isLogEventEnabled,
										sizing: "sm",
									} }
									hasBeenChanged={ reducerForm.cfgApparentEnergyEventThresholdMax.value !== reducerForm.cfgApparentEnergyEventThresholdMax.initialValue }
								/>
							</div>
					}
				</EventRecordingOptionConfiguration>*/ }
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(ApparentEnergyCard);
