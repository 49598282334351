import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateField } from "src/app/utils/forms";
import { isNotNull } from "src/app/utils/typeguards";
import useForm from "src/app/utils/hooks/useForm";
import { Button, Card } from "flowbite-react";
import Input from "src/app/components/Form/Input.component";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { UpdateUserPasswordPayload } from "src/app/types/api/user.types";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		onUpdatePassword: (payload: UpdateUserPasswordPayload) => void
	};

type UpdateUserPasswordForm = {
	currentPassword: string
	newPassword: string
	confirmNewPassword: string
}

const validator: FormValidator<UpdateUserPasswordForm> = {
	currentPassword: (currentPassword, optional) => validateField(i18n.t("PASSWORD.password"), currentPassword, optional, "it"),
	newPassword: (newPassword, optional, form) => {
		const validateFieldError = validateField(i18n.t("PASSWORD.new password"), newPassword, optional, "it");
		if (isNotNull(validateFieldError)) return validateFieldError;

		if (
			form.confirmNewPassword.touched &&
			form.newPassword.value !== form.confirmNewPassword.value
		) {
			return i18n.t("PASSWORD.passwords must be the same");
		}

		return null;
	},
	confirmNewPassword: (confirmNewPassword, optional, form) => {
		const validateFieldError = validateField(i18n.t("PASSWORD.confirm new password"), confirmNewPassword, optional, "it");
		if (isNotNull(validateFieldError)) return validateFieldError;

		if (
			form.newPassword.touched &&
			form.newPassword.value !== form.confirmNewPassword.value
		) {
			return i18n.t("PASSWORD.passwords must be the same");
		}

		return null;
	},
};

function ChangePasswordContainer(props: Props) {

	const { t } = useTranslation();

	const {
		onUpdatePassword,
		isChanging,
	} = props;

	const _handleSubmit = (values: UpdateUserPasswordForm) => {
		onUpdatePassword({
			new_password: values.newPassword,
			old_password: values.currentPassword,
		});
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
	} = useForm({
		currentPassword: createFormField(""),
		newPassword: createFormField(""),
		confirmNewPassword: createFormField(""),
	}, validator, _handleSubmit);

	return (
		<Card className="col-span-6 [&>div]:p-3 sm:[&>div]:p-6 [&>div]:gap-1 sm:[&>div]:gap-4">
			<div className="flex justify-between items-center gap-2">
				<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
					{ t("PASSWORD.change password") }
				</h5>
			</div>
			<form noValidate className="flex flex-col gap-4" onSubmit={ handleSubmit }>
				<Input
					formItem={ form.currentPassword }
					label={ t("PASSWORD.current password") }
					name="currentPassword"
					inputProps={ {
						type: "password",
						onChange: (e) => handleChange("currentPassword", e.target.value),
						onBlur: () => handleBlur("currentPassword"),
					} }
				/>
				<Input
					formItem={ form.newPassword }
					label={ t("PASSWORD.new password") }
					name="newPassword"
					inputProps={ {
						type: "password",
						onChange: (e) => handleChange("newPassword", e.target.value),
						onBlur: () => {
							handleBlur("newPassword");
							handleBlur("confirmNewPassword");
						},
					} }
				/>
				<Input
					formItem={ form.confirmNewPassword }
					label={ t("PASSWORD.confirm new password") }
					name="confirmNewPassword"
					inputProps={ {
						type: "password",
						onChange: (e) => handleChange("confirmNewPassword", e.target.value),
						onBlur: () => {
							handleBlur("newPassword");
							handleBlur("confirmNewPassword");
						},
					} }
				/>
				<Button
					className="ml-auto"
					type="submit"
					color="primary"
					isProcessing={ isChanging }
				>
					{ t("PASSWORD.change") }
				</Button>
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isChanging: didLoadingRecordExist(state, { loadableType: LoadableType.UPDATE_PASSWORD }),
});

export default connect(mapStateToProps)(ChangePasswordContainer);
