import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import { Button, Card, ToggleSwitch } from "flowbite-react";
import { createFormField, validateNumberField } from "src/app/utils/forms";
import { ModbusParity, OnOff, SettingsModbus } from "src/app/types/api/settings.types";
import useForm from "src/app/utils/hooks/useForm";
import { FormValidator } from "src/app/types/ui/form.types";
import classNames from "classnames";
import { mapEnumSettingToSelectOptions } from "src/app/utils/helpers";
import { modbusBaudrateDictionary, modbusEndiannessDictionary, modbusParityDictionary } from "src/app/utils/constants/dictionaries";
import { isNull } from "src/app/utils/typeguards";
import Select from "src/app/components/Form/Select.component";
import Input from "src/app/components/Form/Input.component";
import { useEffect } from "react";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsModbus: SettingsModbus
	};

type ModbusRtuForm = {
	rtuEnabled: boolean
	rtuSlaveAddress: string
	rtuSlaveAddressMinValue: number
	rtuSlaveAddressMaxValue: number
	rtuBaudrate: number
	rtuParity: number
	rtuEndianness: number
}

const validator: FormValidator<ModbusRtuForm> = {
	rtuEnabled: () => null,
	rtuSlaveAddressMinValue: () => null,
	rtuSlaveAddressMaxValue: () => null,
	rtuSlaveAddress: (rtuSlaveAddress, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.slave address"), rtuSlaveAddress, optional, "he", { from: form.rtuSlaveAddressMinValue.value, to: form.rtuSlaveAddressMaxValue.value }),
	rtuBaudrate: () => null,
	rtuParity: () => null,
	rtuEndianness: () => null,
};

function ModbusRtuCard(props: Props) {

	const { t } = useTranslation();

	const {
		settingsModbus,
		isAdmin,
	} = props;

	const reducerForm = useReducerForm(
		"buffor",
		bufforFormActions,
		() => undefined,
	);

	const _handleSubmit = (values: ModbusRtuForm) => {
		reducerForm.handleChange("rtuEnabled", values.rtuEnabled);
		reducerForm.handleChange("rtuSlaveAddress", values.rtuSlaveAddress);
		reducerForm.handleChange("rtuBaudrate", values.rtuBaudrate);
		reducerForm.handleChange("rtuParity", values.rtuParity);
		reducerForm.handleChange("rtuEndianness", values.rtuEndianness);
	};

	const _getInitialState = () => {
		const rtuEnabled = (reducerForm.form.rtuEnabled.value !== reducerForm.form.rtuEnabled.initialValue) ? reducerForm.form.rtuEnabled.value : settingsModbus.communications?.cfgModbusRtuEnable?.enum?.find(enumValue => enumValue.value === settingsModbus.communications?.cfgModbusRtuEnable?.value)?.text === OnOff.ON;
		return {
			rtuEnabled: createFormField(rtuEnabled, { disabled: !isAdmin }),
			rtuSlaveAddress: createFormField((reducerForm.form.rtuSlaveAddress.value !== reducerForm.form.rtuSlaveAddress.initialValue) ? reducerForm.form.rtuSlaveAddress.value : settingsModbus.communications?.cfgModbusRtuSlaveAddress?.value?.toString() ?? "", { disabled: !isAdmin || !rtuEnabled }),
			rtuSlaveAddressMinValue: createFormField(settingsModbus.communications?.cfgModbusRtuSlaveAddress?.minValue ?? 1),
			rtuSlaveAddressMaxValue: createFormField(settingsModbus.communications?.cfgModbusRtuSlaveAddress?.maxValue ?? 247),
			rtuBaudrate: createFormField((reducerForm.form.rtuBaudrate.value !== reducerForm.form.rtuBaudrate.initialValue) ? reducerForm.form.rtuBaudrate.value : settingsModbus.communications?.cfgModbusRtuBaudrate?.value ?? 0, { disabled: !isAdmin || !rtuEnabled }),
			rtuParity: createFormField((reducerForm.form.rtuParity.value !== reducerForm.form.rtuParity.initialValue) ? reducerForm.form.rtuParity.value : settingsModbus.communications?.cfgModbusRtuParity?.value ?? 0, { disabled: !isAdmin || !rtuEnabled }),
			rtuEndianness: createFormField((reducerForm.form.rtuEndianness.value !== reducerForm.form.rtuEndianness.initialValue) ? reducerForm.form.rtuEndianness.value : settingsModbus.communications?.cfgModbusRtuEndianness?.value ?? 0, { disabled: true }),
		};
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ settingsModbus ]);

	useEffect(() => {
		toggleDisable("rtuSlaveAddress", false);
		toggleDisable("rtuBaudrate", false);
		toggleDisable("rtuParity", false);

		handleChange("rtuEnabled", reducerForm.form.rtuEnabled.value);
		handleChange("rtuSlaveAddress", reducerForm.form.rtuSlaveAddress.value);
		handleChange("rtuBaudrate", reducerForm.form.rtuBaudrate.value);
		handleChange("rtuParity", reducerForm.form.rtuParity.value);
		handleChange("rtuEndianness", reducerForm.form.rtuEndianness.value);

		toggleDisable("rtuSlaveAddress", !reducerForm.form.rtuEnabled.value);
		toggleDisable("rtuBaudrate", !reducerForm.form.rtuEnabled.value);
		toggleDisable("rtuParity", !reducerForm.form.rtuEnabled.value);
	}, [
		reducerForm.form.rtuEnabled.value,
		reducerForm.form.rtuSlaveAddress.value,
		reducerForm.form.rtuBaudrate.value,
		reducerForm.form.rtuParity.value,
		reducerForm.form.rtuEndianness.value,
	]);

	const _getStopBits = () => {
		const option = mapEnumSettingToSelectOptions(settingsModbus.communications?.cfgModbusRtuParity, modbusParityDictionary).find(option => option.value === form.rtuParity.value);
		if (isNull(option)) return t("COMMUNICATIONS.unknown");
		if (option.label === modbusParityDictionary()[ ModbusParity.MODBUS_PARITY_EVEN ] || option.label === modbusParityDictionary()[ ModbusParity.MODBUS_PARITY_ODD ]) return "1";
		if (option.label === modbusParityDictionary()[ ModbusParity.MODBUS_PARITY_NONE ]) return "2";

		return t("COMMUNICATIONS.unknown");
	};

	return (
		<Card>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<ToggleSwitch
					className={
						classNames(
							"[&>div]:w-9 [&>div]:h-5 [&>div]:after:absolute [&>div]:after:top-[2px] [&>div]:after:left-[2px] [&>div]:after:h-4 [&>div]:after:w-4",
							{ "[&>span]:!text-yellow-400": reducerForm.form.rtuEnabled.value !== reducerForm.form.rtuEnabled.initialValue },
						)
					}
					disabled={ form.rtuEnabled.disabled }
					checked={ form.rtuEnabled.value }
					label={ t("COMMUNICATIONS.enable") }
					onChange={ () => {
						handleChange("rtuEnabled", !form.rtuEnabled.value);

						toggleDisable("rtuSlaveAddress", form.rtuEnabled.value);
						toggleDisable("rtuBaudrate", form.rtuEnabled.value);
						toggleDisable("rtuParity", form.rtuEnabled.value);
					} }
				/>
				<Input
					formItem={ form.rtuSlaveAddress }
					label={ t("COMMUNICATIONS.slave address") }
					name="rtuSlaveAddress"
					inputProps={ {
						type: "number",
						onChange: (e) => handleChange("rtuSlaveAddress", e.target.value),
						onBlur: () => handleBlur("rtuSlaveAddress"),
					} }
					hasBeenChanged={ reducerForm.form.rtuSlaveAddress.value !== reducerForm.form.rtuSlaveAddress.initialValue }
				/>
				<Select
					label={ t("COMMUNICATIONS.baudrate") }
					options={ mapEnumSettingToSelectOptions(settingsModbus.communications?.cfgModbusRtuBaudrate, modbusBaudrateDictionary) }
					formItem={ form.rtuBaudrate }
					onChange={ option => {
						if (isNull(option)) return;

						handleChange("rtuBaudrate", option?.value);
						handleBlur("rtuBaudrate");
					} }
					isSearchable={ false }
					isClearable={ false }
					hasBeenChanged={ reducerForm.form.rtuBaudrate.value !== reducerForm.form.rtuBaudrate.initialValue }
				/>
				<Select
					label={ t("COMMUNICATIONS.word order") }
					options={ mapEnumSettingToSelectOptions(settingsModbus.communications?.cfgModbusRtuEndianness, modbusEndiannessDictionary) }
					formItem={ form.rtuEndianness }
					onChange={ option => {
						if (isNull(option)) return;

						handleChange("rtuEndianness", option?.value);
						handleBlur("rtuEndianness");
					} }
					isSearchable={ false }
					isClearable={ false }
					hasBeenChanged={ reducerForm.form.rtuEndianness.value !== reducerForm.form.rtuEndianness.initialValue }
				/>
				<Select
					label={ t("COMMUNICATIONS.parity") }
					options={ mapEnumSettingToSelectOptions(settingsModbus.communications?.cfgModbusRtuParity, modbusParityDictionary) }
					formItem={ form.rtuParity }
					onChange={ option => {
						if (isNull(option)) return;

						handleChange("rtuParity", option?.value);
						handleBlur("rtuParity");
					} }
					isSearchable={ false }
					isClearable={ false }
					helperText={ `${ t("COMMUNICATIONS.stop bits") }: ${ _getStopBits() }` }
					hasBeenChanged={ reducerForm.form.rtuParity.value !== reducerForm.form.rtuParity.initialValue }
				/>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(ModbusRtuCard);
