import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import HarmonicContainer from "src/app/containers/Realtime/Harmonic.container";
import useWebSocket, { WebSocketUri } from "src/app/utils/hooks/useWebSocket";
import { DataHarmonicsFundLive, DataHarmonicsLive, DataHarmonicsPowerLive, DataHarmonicsRmsLive } from "src/app/types/api/ws.types";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function HarmonicView(props: Props) {

	const [ lastRecordBase ] = useWebSocket<DataHarmonicsLive>(WebSocketUri.DATA_HARMONICS_256_LIVE, true);
	const [ lastRecordFund ] = useWebSocket<DataHarmonicsFundLive>(WebSocketUri.DATA_HARMONICS_FUND_256_LIVE, true);
	const [ lastRecordRms ] = useWebSocket<DataHarmonicsRmsLive>(WebSocketUri.DATA_HARMONICS_RMS_256_LIVE, true);
	const [ lastRecordPower ] = useWebSocket<DataHarmonicsPowerLive>(WebSocketUri.DATA_HARMONICS_POWER_256_LIVE, true);

	return (
		<HarmonicContainer
			baseHarmonics={ lastRecordBase }
			fundHarmonics={ lastRecordFund }
			rmsHarmonics={ lastRecordRms }
			powerHarmonics={ lastRecordPower }
		/>
	);
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HarmonicView);
