import { FormItem } from "src/app/types/ui/form.types";
import { Nullable } from "src/app/types/util.types";
import { Label, LabelProps, TextInput, TextInputProps } from "flowbite-react";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { RefAttributes } from "react";
import classNames from "classnames";

type Props = {
	className?: string
	formItem: FormItem<Nullable<string | number>>
	name: string // Friendly name for HTML Semantic
	inputProps?: TextInputProps & RefAttributes<HTMLInputElement>
	labelProps?: LabelProps
	label?: string | ReactNode
	displayErrorMessage?: boolean
	role?: string
	hasBeenChanged?: boolean
	addonRight?: ReactNode
	addonRightClassName?: string
}

export const INPUT_EXTERNAL_ICON_CLASSNAMES = "h-5 w-5 text-gray-500 dark:text-gray-400";

function Input(props: Props) {
	const {
		className,
		formItem,
		name,
		inputProps = {},
		labelProps = {},
		label,
		displayErrorMessage = true,
		role,
		hasBeenChanged = false,
		addonRight,
		addonRightClassName,
	} = props;

	const _getErrorMessage = () => {
		if (isNull(formItem.error)) return null;

		if (formItem.error instanceof Array) {
			return (
				<ul className="form__error--list">
					{
						formItem.error.map((err, i) =>
							<li key={ i } className="mb-1">{ err }</li>,
						)
					}
				</ul>
			);
		} else {
			return formItem.error;
		}
	};

	return (
		<div
			className={
				classNames(
					"flex flex-col gap-y-0.5",
					className,
				)
			}
			role={ role }
		>
			{
				isNotNull(label) &&
                <Label
                    htmlFor={ name }
                    disabled={ formItem.disabled || (inputProps?.disabled ?? false) }
					{ ...labelProps }
                >
					{ label }
                </Label>
			}
			<TextInput
				{ ...inputProps }
				step={ inputProps.type === "number" ? inputProps?.step ?? 0.01 : undefined }
				name={ name }
				color={ isNotNull(formItem.error) ? "failure" : (hasBeenChanged ? "warning" : undefined) }
				helperText={ (isNotNull(formItem.error) && displayErrorMessage) ? <><span className="font-medium" role="input-error">{ _getErrorMessage() }</span></> : (isNotNull(inputProps?.helperText) ? <><span className="text-sm text-gray-500">{ inputProps?.helperText }</span></> : undefined) }
				disabled={ formItem.disabled || (inputProps?.disabled ?? false) }
				value={ isNotNull(formItem.value) ? formItem.value : undefined }
				className={
					classNames(
						inputProps?.className,
						{ "[&>span]:min-w-[105px] [&>span]:order-2 [&_input]:rounded-l-lg [&_input]:rounded-r-none [&_input]:border-r-0 [&>span]:rounded-r-lg [&>span]:rounded-l-none [&>span]:border-r": isNotNull(addonRight) },
						addonRightClassName,
						{ "[&>span]:opacity-50 [&>span]:cursor-not-allowed": isNotNull(addonRight) && (formItem.disabled || (inputProps?.disabled ?? false)) },
					)
				}
				addon={ addonRight }
			/>
		</div>
	);
}

export default (Input);
