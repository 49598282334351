import SummaryView from "src/app/views/Setup/Summary.view";
import realtimeRoutes from "src/app/utils/routes/private/realtime.routes";
import StandardEventsView from "src/app/views/Event/StandardEvents.view";
import UserEventsView from "src/app/views/Event/UserEvents.view";
import UsersView from "src/app/views/User/Users.view";
import { RouteObject } from "react-router/dist/lib/context";
import settingsRoutes from "src/app/utils/routes/private/settings.routes";
import AppVersionView from "src/app/views/Utils/AppVersion.view";
// Routes import plop - don't remove

const privateRoutes: RouteObject[] = [
	{
		path: "/summary",
		element: <SummaryView/>,
	}, {
		path: "/users",
		element: <UsersView/>,
	}, {
		path: "/standard-events",
		element: <StandardEventsView/>,
	}, {
		path: "/user-events",
		element: <UserEventsView/>,
	}, {
		path: "/version",
		element: <AppVersionView/>,
	},
	...settingsRoutes,
	realtimeRoutes,
	// Routes add route plop - don't remove
];

export default privateRoutes;
