import { Nullable } from "src/app/types/util.types";
import { Badge, Card } from "flowbite-react";
import { Dispatch, memo, SetStateAction, useState } from "react";
import { FaCheckCircle, FaRegCircle } from "react-icons/fa";
import { DataSheetLive } from "src/app/types/api/ws.types";
import { isNull } from "src/app/utils/typeguards";
import Table from "src/app/components/Utils/Table.component";
import { MeasurementCategory } from "src/app/types/misc.types";
import { formatValueToFullDeciSON, formatValueWithoutChanges } from "src/app/utils/dataFormatting";
import { measurementCategoryCellClassNameDictionary, measurementCategoryRowClassNameDictionary } from "src/app/utils/constants/dictionaries";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

type Props = {
	dataSheetLive: Nullable<DataSheetLive>
};

type TableRecord = {
	name: string | ReactNode
	l1: Nullable<string>
	l2: Nullable<string>
	l3: Nullable<string>
	ln: Nullable<string>
	le: Nullable<string>
	l12: Nullable<string>
	l23: Nullable<string>
	l31: Nullable<string>
	total: Nullable<string>
	// unit: Nullable<string>
	category: MeasurementCategory
}

type MeasurementsConfigState = {
	[K in MeasurementCategory]: boolean
}

function MeasurementsContainer(props: Props) {

	const { t } = useTranslation();

	const {
		dataSheetLive,
	} = props;

	const [ config, setConfig ] = useState<MeasurementsConfigState>({
		voltages: true,
		voltagesDc: false,
		currents: true,
		activePowers: true,
		reactivePowers: true,
		apparentPowers: true,
		frequencies: true,
		energies: true,
		phasors: true,
		factors: true,
		flicker: false,
		harmonics: true,
		interharmonics: true,
		powerFactor: false,
		tangent: false,
		activeEnergy: false,
		reactiveEnergy: false,
		apparentEnergy: false,
		voltagesWave: false,
		currentsWave: false,
		activePowersWave: false,
		reactivePowersWave: false,
		apparentPowersWave: false,
		frequenciesWave: false,
		angles: false,
		percent: false,
	});

	const data: TableRecord[] = [
		{
			name: "f",
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.L1_f?.value, dataSheetLive?.sheet?.L1_f?.unit, MeasurementCategory.FREQUENCIES),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.L2_f?.value, dataSheetLive?.sheet?.L2_f?.unit, MeasurementCategory.FREQUENCIES),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.L3_f?.value, dataSheetLive?.sheet?.L3_f?.unit, MeasurementCategory.FREQUENCIES),
			ln: undefined,
			le: undefined,
			l12: formatValueToFullDeciSON(dataSheetLive?.sheet?.L12_f?.value, dataSheetLive?.sheet?.L12_f?.unit, MeasurementCategory.FREQUENCIES),
			l23: formatValueToFullDeciSON(dataSheetLive?.sheet?.L23_f?.value, dataSheetLive?.sheet?.L23_f?.unit, MeasurementCategory.FREQUENCIES),
			l31: formatValueToFullDeciSON(dataSheetLive?.sheet?.L31_f?.value, dataSheetLive?.sheet?.L31_f?.unit, MeasurementCategory.FREQUENCIES),
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.Tot_f?.value, dataSheetLive?.sheet?.Tot_f?.unit, MeasurementCategory.FREQUENCIES),
			//unit: dataSheetLive?.sheet?.frequency200ms?.unit,
			category: MeasurementCategory.FREQUENCIES,
		}, {
			name: "U",
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.U1n_rms?.value ?? dataSheetLive?.sheet?.U1e_rms?.value, dataSheetLive?.sheet?.U1n_rms?.unit ?? dataSheetLive?.sheet?.U1e_rms?.unit, MeasurementCategory.VOLTAGES),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.U2n_rms?.value ?? dataSheetLive?.sheet?.U2e_rms?.value, dataSheetLive?.sheet?.U2n_rms?.unit ?? dataSheetLive?.sheet?.U2e_rms?.unit, MeasurementCategory.VOLTAGES),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.U3n_rms?.value ?? dataSheetLive?.sheet?.U3e_rms?.value, dataSheetLive?.sheet?.U3n_rms?.unit ?? dataSheetLive?.sheet?.U3e_rms?.unit, MeasurementCategory.VOLTAGES),
			ln: formatValueToFullDeciSON(dataSheetLive?.sheet?.Une_rms?.value, dataSheetLive?.sheet?.Une_rms?.unit, MeasurementCategory.VOLTAGES),
			le: undefined,
			l12: formatValueToFullDeciSON(dataSheetLive?.sheet?.U12_rms?.value, dataSheetLive?.sheet?.U12_rms?.unit, MeasurementCategory.VOLTAGES),
			l23: formatValueToFullDeciSON(dataSheetLive?.sheet?.U23_rms?.value, dataSheetLive?.sheet?.U23_rms?.unit, MeasurementCategory.VOLTAGES),
			l31: formatValueToFullDeciSON(dataSheetLive?.sheet?.U31_rms?.value, dataSheetLive?.sheet?.U31_rms?.unit, MeasurementCategory.VOLTAGES),
			total: undefined,
			//unit: dataSheetLive?.sheet?.U1n_rms?.unit,
			category: MeasurementCategory.VOLTAGES,
		}, {
			name: <span>U<sub>DC</sub></span>,
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.U1n_dc?.value ?? dataSheetLive?.sheet?.U1e_dc?.value, dataSheetLive?.sheet?.U1n_rms?.unit ?? dataSheetLive?.sheet?.U1e_rms?.unit, MeasurementCategory.VOLTAGES_DC),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.U2n_dc?.value ?? dataSheetLive?.sheet?.U2e_dc?.value, dataSheetLive?.sheet?.U2n_rms?.unit ?? dataSheetLive?.sheet?.U2e_rms?.unit, MeasurementCategory.VOLTAGES_DC),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.U3n_dc?.value ?? dataSheetLive?.sheet?.U3e_dc?.value, dataSheetLive?.sheet?.U3n_rms?.unit ?? dataSheetLive?.sheet?.U3e_rms?.unit, MeasurementCategory.VOLTAGES_DC),
			ln: formatValueToFullDeciSON(dataSheetLive?.sheet?.Une_dc?.value, dataSheetLive?.sheet?.Une_dc?.unit, MeasurementCategory.VOLTAGES_DC),
			le: undefined,
			l12: formatValueToFullDeciSON(dataSheetLive?.sheet?.U12_dc?.value, dataSheetLive?.sheet?.U12_dc?.unit, MeasurementCategory.VOLTAGES_DC),
			l23: formatValueToFullDeciSON(dataSheetLive?.sheet?.U23_dc?.value, dataSheetLive?.sheet?.U23_dc?.unit, MeasurementCategory.VOLTAGES_DC),
			l31: formatValueToFullDeciSON(dataSheetLive?.sheet?.U31_dc?.value, dataSheetLive?.sheet?.U31_dc?.unit, MeasurementCategory.VOLTAGES_DC),
			total: undefined,
			//unit: dataSheetLive?.sheet?.U1n_rms?.unit,
			category: MeasurementCategory.VOLTAGES,
		}, {
			name: "I",
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.I1_rms?.value, dataSheetLive?.sheet?.I1_rms?.unit, MeasurementCategory.CURRENTS),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.I2_rms?.value, dataSheetLive?.sheet?.I2_rms?.unit, MeasurementCategory.CURRENTS),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.I3_rms?.value, dataSheetLive?.sheet?.I3_rms?.unit, MeasurementCategory.CURRENTS),
			ln: formatValueToFullDeciSON(dataSheetLive?.sheet?.In_rms?.value, dataSheetLive?.sheet?.In_rms?.unit, MeasurementCategory.CURRENTS),
			le: formatValueToFullDeciSON(dataSheetLive?.sheet?.Ie_rms?.value, dataSheetLive?.sheet?.Ie_rms?.unit, MeasurementCategory.CURRENTS),
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: undefined,
			//unit: dataSheetLive?.sheet?.I1_rms?.unit,
			category: MeasurementCategory.CURRENTS,
		}, {
			name: <span>I<sub>DC</sub></span>,
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.I1_dc?.value, dataSheetLive?.sheet?.I1_dc?.unit, MeasurementCategory.CURRENTS),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.I2_dc?.value, dataSheetLive?.sheet?.I2_dc?.unit, MeasurementCategory.CURRENTS),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.I3_dc?.value, dataSheetLive?.sheet?.I3_dc?.unit, MeasurementCategory.CURRENTS),
			ln: formatValueToFullDeciSON(dataSheetLive?.sheet?.In_dc?.value, dataSheetLive?.sheet?.In_dc?.unit, MeasurementCategory.CURRENTS),
			le: formatValueToFullDeciSON(dataSheetLive?.sheet?.Ie_dc?.value, dataSheetLive?.sheet?.Ie_dc?.unit, MeasurementCategory.CURRENTS),
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: undefined,
			//unit: dataSheetLive?.sheet?.I1_dc?.unit,
			category: MeasurementCategory.CURRENTS,
		}, {
			name: "P",
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.L1_P?.value, dataSheetLive?.sheet?.L1_P?.unit, MeasurementCategory.ACTIVE_POWERS),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.L2_P?.value, dataSheetLive?.sheet?.L2_P?.unit, MeasurementCategory.ACTIVE_POWERS),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.L3_P?.value, dataSheetLive?.sheet?.L3_P?.unit, MeasurementCategory.ACTIVE_POWERS),
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.Tot_P?.value, dataSheetLive?.sheet?.L1_P?.unit, MeasurementCategory.ACTIVE_POWERS),
			//unit: dataSheetLive?.sheet?.L1_P?.unit,
			category: MeasurementCategory.ACTIVE_POWERS,
		}, {
			name: <span>Q<sub>1</sub></span>,
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.L1_Q?.value, dataSheetLive?.sheet?.L1_Q?.unit, MeasurementCategory.REACTIVE_POWERS),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.L2_Q?.value, dataSheetLive?.sheet?.L2_Q?.unit, MeasurementCategory.REACTIVE_POWERS),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.L3_Q?.value, dataSheetLive?.sheet?.L3_Q?.unit, MeasurementCategory.REACTIVE_POWERS),
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.Tot_Q?.value, dataSheetLive?.sheet?.L1_Q?.unit, MeasurementCategory.REACTIVE_POWERS),
			//unit: dataSheetLive?.sheet?.L1_Q?.unit,
			category: MeasurementCategory.REACTIVE_POWERS,
		}, {
			name: "S",
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.L1_S?.value, dataSheetLive?.sheet?.L1_S?.unit, MeasurementCategory.APPARENT_POWERS),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.L2_S?.value, dataSheetLive?.sheet?.L2_S?.unit, MeasurementCategory.APPARENT_POWERS),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.L3_S?.value, dataSheetLive?.sheet?.L3_S?.unit, MeasurementCategory.APPARENT_POWERS),
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.Tot_S?.value, dataSheetLive?.sheet?.L1_S?.unit, MeasurementCategory.APPARENT_POWERS),
			//unit: dataSheetLive?.sheet?.L1_S?.unit,
			category: MeasurementCategory.APPARENT_POWERS,
		}, {
			name: <span>S<sub>N</sub></span>,
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.L1_Sn?.value, dataSheetLive?.sheet?.L1_Sn?.unit, MeasurementCategory.APPARENT_POWERS),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.L2_Sn?.value, dataSheetLive?.sheet?.L2_Sn?.unit, MeasurementCategory.APPARENT_POWERS),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.L3_Sn?.value, dataSheetLive?.sheet?.L3_Sn?.unit, MeasurementCategory.APPARENT_POWERS),
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.Tot_Sn?.value, dataSheetLive?.sheet?.L1_Sn?.unit, MeasurementCategory.APPARENT_POWERS),
			//unit: dataSheetLive?.sheet?.L1_Sn?.unit,
			category: MeasurementCategory.APPARENT_POWERS,
		}, {
			name: <span>E<sub>q</sub>L+</span>,
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.L1_eq_quadrant1?.value, dataSheetLive?.sheet?.L1_eq_quadrant1?.unit, MeasurementCategory.REACTIVE_ENERGY),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.L2_eq_quadrant1?.value, dataSheetLive?.sheet?.L2_eq_quadrant1?.unit, MeasurementCategory.REACTIVE_ENERGY),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.L3_eq_quadrant1?.value, dataSheetLive?.sheet?.L3_eq_quadrant1?.unit, MeasurementCategory.REACTIVE_ENERGY),
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.Tot_eq_quadrant1?.value, dataSheetLive?.sheet?.L1_eq_quadrant1?.unit, MeasurementCategory.REACTIVE_ENERGY),
			//unit: dataSheetLive?.sheet?.L1_eq_quadrant1?.unit,
			category: MeasurementCategory.ENERGY,
		}, {
			name: <span>E<sub>q</sub>C-</span>,
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.L1_eq_quadrant2?.value, dataSheetLive?.sheet?.L1_eq_quadrant2?.unit, MeasurementCategory.REACTIVE_ENERGY),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.L2_eq_quadrant2?.value, dataSheetLive?.sheet?.L2_eq_quadrant2?.unit, MeasurementCategory.REACTIVE_ENERGY),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.L3_eq_quadrant2?.value, dataSheetLive?.sheet?.L3_eq_quadrant2?.unit, MeasurementCategory.REACTIVE_ENERGY),
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.Tot_eq_quadrant2?.value, dataSheetLive?.sheet?.L1_eq_quadrant2?.unit, MeasurementCategory.REACTIVE_ENERGY),
			//unit: dataSheetLive?.sheet?.L1_eq_quadrant2?.unit,
			category: MeasurementCategory.ENERGY,
		}, {
			name: <span>E<sub>q</sub>L-</span>,
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.L1_eq_quadrant3?.value, dataSheetLive?.sheet?.L1_eq_quadrant3?.unit, MeasurementCategory.REACTIVE_ENERGY),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.L2_eq_quadrant3?.value, dataSheetLive?.sheet?.L2_eq_quadrant3?.unit, MeasurementCategory.REACTIVE_ENERGY),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.L3_eq_quadrant3?.value, dataSheetLive?.sheet?.L3_eq_quadrant3?.unit, MeasurementCategory.REACTIVE_ENERGY),
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.Tot_eq_quadrant3?.value, dataSheetLive?.sheet?.L1_eq_quadrant3?.unit, MeasurementCategory.REACTIVE_ENERGY),
			//unit: dataSheetLive?.sheet?.L1_eq_quadrant3?.unit,
			category: MeasurementCategory.ENERGY,
		}, {
			name: <span>E<sub>q</sub>C+</span>,
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.L1_eq_quadrant4?.value, dataSheetLive?.sheet?.L1_eq_quadrant4?.unit, MeasurementCategory.REACTIVE_ENERGY),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.L2_eq_quadrant4?.value, dataSheetLive?.sheet?.L2_eq_quadrant4?.unit, MeasurementCategory.REACTIVE_ENERGY),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.L3_eq_quadrant4?.value, dataSheetLive?.sheet?.L3_eq_quadrant4?.unit, MeasurementCategory.REACTIVE_ENERGY),
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.Tot_eq_quadrant4?.value, dataSheetLive?.sheet?.L1_eq_quadrant4?.unit, MeasurementCategory.REACTIVE_ENERGY),
			//unit: dataSheetLive?.sheet?.L1_eq_quadrant4?.unit,
			category: MeasurementCategory.ENERGY,
		}, {
			name: <span>E<sub>p+</sub></span>,
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.L1_ep_plus?.value, dataSheetLive?.sheet?.L1_ep_plus?.unit, MeasurementCategory.ACTIVE_ENERGY),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.L2_ep_plus?.value, dataSheetLive?.sheet?.L2_ep_plus?.unit, MeasurementCategory.ACTIVE_ENERGY),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.L3_ep_plus?.value, dataSheetLive?.sheet?.L3_ep_plus?.unit, MeasurementCategory.ACTIVE_ENERGY),
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.Tot_ep_plus?.value, dataSheetLive?.sheet?.L1_ep_plus?.unit, MeasurementCategory.ACTIVE_ENERGY),
			//unit: dataSheetLive?.sheet?.L1_ep_plus?.unit,
			category: MeasurementCategory.ENERGY,
		}, {
			name: <span>E<sub>p-</sub></span>,
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.L1_ep_minus?.value, dataSheetLive?.sheet?.L1_ep_minus?.unit, MeasurementCategory.ACTIVE_ENERGY),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.L2_ep_minus?.value, dataSheetLive?.sheet?.L2_ep_minus?.unit, MeasurementCategory.ACTIVE_ENERGY),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.L3_ep_minus?.value, dataSheetLive?.sheet?.L3_ep_minus?.unit, MeasurementCategory.ACTIVE_ENERGY),
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.Tot_ep_minus?.value, dataSheetLive?.sheet?.L1_ep_minus?.unit, MeasurementCategory.ACTIVE_ENERGY),
			//unit: dataSheetLive?.sheet?.L1_ep_minus?.unit,
			category: MeasurementCategory.ENERGY,
		}, {
			name: <span>E<sub>S</sub></span>,
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.L1_es?.value, dataSheetLive?.sheet?.L1_es?.unit, MeasurementCategory.APPARENT_ENERGY),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.L2_es?.value, dataSheetLive?.sheet?.L2_es?.unit, MeasurementCategory.APPARENT_ENERGY),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.L3_es?.value, dataSheetLive?.sheet?.L3_es?.unit, MeasurementCategory.APPARENT_ENERGY),
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.Tot_es?.value, dataSheetLive?.sheet?.L1_es?.unit, MeasurementCategory.APPARENT_ENERGY),
			//unit: dataSheetLive?.sheet?.L1_es?.unit,
			category: MeasurementCategory.ENERGY,
		}, {
			name: "PF",
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.L1_pf?.value, undefined, MeasurementCategory.POWER_FACTOR),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.L2_pf?.value, undefined, MeasurementCategory.POWER_FACTOR),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.L3_pf?.value, undefined, MeasurementCategory.POWER_FACTOR),
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.Tot_pf?.value, undefined, MeasurementCategory.POWER_FACTOR),
			//unit: undefined,
			category: MeasurementCategory.FACTOR,
		}, {
			name: "cos(φ)",
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.L1_dpf?.value, undefined, MeasurementCategory.TANGENT),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.L2_dpf?.value, undefined, MeasurementCategory.TANGENT),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.L3_dpf?.value, undefined, MeasurementCategory.TANGENT),
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.Tot_dpf?.value, undefined, MeasurementCategory.TANGENT),
			//unit: undefined,
			category: MeasurementCategory.FACTOR,
		}, {
			name: "tan(φ) L+",
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.L1_tangent_quadrant1?.value, undefined, MeasurementCategory.TANGENT),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.L2_tangent_quadrant1?.value, undefined, MeasurementCategory.TANGENT),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.L3_tangent_quadrant1?.value, undefined, MeasurementCategory.TANGENT),
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.Tot_tangent_quadrant1?.value, undefined, MeasurementCategory.TANGENT),
			//unit: undefined,
			category: MeasurementCategory.FACTOR,
		}, {
			name: "tan(φ) C-",
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.L1_tangent_quadrant2?.value, undefined, MeasurementCategory.TANGENT),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.L2_tangent_quadrant2?.value, undefined, MeasurementCategory.TANGENT),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.L3_tangent_quadrant2?.value, undefined, MeasurementCategory.TANGENT),
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.Tot_tangent_quadrant2?.value, undefined, MeasurementCategory.TANGENT),
			//unit: undefined,
			category: MeasurementCategory.FACTOR,
		}, {
			name: "tan(φ) L-",
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.L1_tangent_quadrant3?.value, undefined, MeasurementCategory.TANGENT),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.L2_tangent_quadrant3?.value, undefined, MeasurementCategory.TANGENT),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.L3_tangent_quadrant3?.value, undefined, MeasurementCategory.TANGENT),
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.Tot_tangent_quadrant3?.value, undefined, MeasurementCategory.TANGENT),
			//unit: undefined,
			category: MeasurementCategory.FACTOR,
		}, {
			name: "tan(φ) C+",
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.L1_tangent_quadrant4?.value, undefined, MeasurementCategory.TANGENT),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.L2_tangent_quadrant4?.value, undefined, MeasurementCategory.TANGENT),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.L3_tangent_quadrant4?.value, undefined, MeasurementCategory.TANGENT),
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.Tot_tangent_quadrant4?.value, undefined, MeasurementCategory.TANGENT),
			//unit: undefined,
			category: MeasurementCategory.FACTOR,
		}, {
			name: <span>THD<sub>F</sub> U</span>,
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.U1n_thdf?.value ?? dataSheetLive?.sheet?.U1e_thdf?.value, dataSheetLive?.sheet?.U1n_thdf?.unit ?? dataSheetLive?.sheet?.U1e_thdf?.unit, MeasurementCategory.HARMONICS),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.U2n_thdf?.value ?? dataSheetLive?.sheet?.U2e_thdf?.value, dataSheetLive?.sheet?.U2n_thdf?.unit ?? dataSheetLive?.sheet?.U2e_thdf?.unit, MeasurementCategory.HARMONICS),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.U3n_thdf?.value ?? dataSheetLive?.sheet?.U3e_thdf?.value, dataSheetLive?.sheet?.U3n_thdf?.unit ?? dataSheetLive?.sheet?.U3e_thdf?.unit, MeasurementCategory.HARMONICS),
			ln: formatValueToFullDeciSON(dataSheetLive?.sheet?.Une_thdf?.value, dataSheetLive?.sheet?.Une_thdf?.unit, MeasurementCategory.HARMONICS),
			le: undefined,
			l12: formatValueToFullDeciSON(dataSheetLive?.sheet?.U12_thdf?.value, dataSheetLive?.sheet?.U12_thdf?.unit, MeasurementCategory.HARMONICS),
			l23: formatValueToFullDeciSON(dataSheetLive?.sheet?.U23_thdf?.value, dataSheetLive?.sheet?.U23_thdf?.unit, MeasurementCategory.HARMONICS),
			l31: formatValueToFullDeciSON(dataSheetLive?.sheet?.U31_thdf?.value, dataSheetLive?.sheet?.U31_thdf?.unit, MeasurementCategory.HARMONICS),
			total: undefined,
			//unit: dataSheetLive?.sheet?.U3n_thdf?.unit,
			category: MeasurementCategory.FACTOR,
		}, {
			name: <span>THD<sub>F</sub> I</span>,
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.I1_thdf?.value, dataSheetLive?.sheet?.I1_thdf?.unit, MeasurementCategory.HARMONICS),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.I2_thdf?.value, dataSheetLive?.sheet?.I2_thdf?.unit, MeasurementCategory.HARMONICS),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.I3_thdf?.value, dataSheetLive?.sheet?.I3_thdf?.unit, MeasurementCategory.HARMONICS),
			ln: formatValueToFullDeciSON(dataSheetLive?.sheet?.In_thdf?.value, dataSheetLive?.sheet?.In_thdf?.unit, MeasurementCategory.HARMONICS),
			le: formatValueToFullDeciSON(dataSheetLive?.sheet?.Ie_thdf?.value, dataSheetLive?.sheet?.Ie_thdf?.unit, MeasurementCategory.HARMONICS),
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: undefined,
			//unit: dataSheetLive?.sheet?.I1_thdf?.unit,
			category: MeasurementCategory.FACTOR,
		}, {
			name: <span>TID<sub>F</sub> U</span>,
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.U1n_tidf?.value ?? dataSheetLive?.sheet?.U1e_tidf?.value, dataSheetLive?.sheet?.U1n_tidf?.unit ?? dataSheetLive?.sheet?.U1e_tidf?.unit, MeasurementCategory.HARMONICS),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.U2n_tidf?.value ?? dataSheetLive?.sheet?.U2e_tidf?.value, dataSheetLive?.sheet?.U2n_tidf?.unit ?? dataSheetLive?.sheet?.U2e_tidf?.unit, MeasurementCategory.HARMONICS),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.U3n_tidf?.value ?? dataSheetLive?.sheet?.U3e_tidf?.value, dataSheetLive?.sheet?.U3n_tidf?.unit ?? dataSheetLive?.sheet?.U3e_tidf?.unit, MeasurementCategory.HARMONICS),
			ln: formatValueToFullDeciSON(dataSheetLive?.sheet?.Une_tidf?.value, dataSheetLive?.sheet?.Une_tidf?.unit, MeasurementCategory.HARMONICS),
			le: undefined,
			l12: formatValueToFullDeciSON(dataSheetLive?.sheet?.U12_tidf?.value, dataSheetLive?.sheet?.U12_tidf?.unit, MeasurementCategory.HARMONICS),
			l23: formatValueToFullDeciSON(dataSheetLive?.sheet?.U23_tidf?.value, dataSheetLive?.sheet?.U23_tidf?.unit, MeasurementCategory.HARMONICS),
			l31: formatValueToFullDeciSON(dataSheetLive?.sheet?.U31_tidf?.value, dataSheetLive?.sheet?.U31_tidf?.unit, MeasurementCategory.HARMONICS),
			total: undefined,
			//unit: dataSheetLive?.sheet?.U1n_tidf?.unit,
			category: MeasurementCategory.FACTOR,
		}, {
			name: <span>TID<sub>F</sub> I</span>,
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.I1_tidf?.value, dataSheetLive?.sheet?.I1_tidf?.unit, MeasurementCategory.HARMONICS),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.I2_tidf?.value, dataSheetLive?.sheet?.I2_tidf?.unit, MeasurementCategory.HARMONICS),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.I3_tidf?.value, dataSheetLive?.sheet?.I3_tidf?.unit, MeasurementCategory.HARMONICS),
			ln: formatValueToFullDeciSON(dataSheetLive?.sheet?.In_tidf?.value, dataSheetLive?.sheet?.In_tidf?.unit, MeasurementCategory.HARMONICS),
			le: formatValueToFullDeciSON(dataSheetLive?.sheet?.Ie_tidf?.value, dataSheetLive?.sheet?.Ie_tidf?.unit, MeasurementCategory.HARMONICS),
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: undefined,
			//unit: dataSheetLive?.sheet?.I1_tidf?.unit,
			category: MeasurementCategory.FACTOR,
		}, {
			name: "CF U",
			l1: formatValueWithoutChanges(dataSheetLive?.sheet?.U1n_cf?.value ?? dataSheetLive?.sheet?.U1e_cf?.value, undefined),
			l2: formatValueWithoutChanges(dataSheetLive?.sheet?.U2n_cf?.value ?? dataSheetLive?.sheet?.U2e_cf?.value, undefined),
			l3: formatValueWithoutChanges(dataSheetLive?.sheet?.U3n_cf?.value ?? dataSheetLive?.sheet?.U3e_cf?.value, undefined),
			ln: formatValueWithoutChanges(dataSheetLive?.sheet?.Une_cf?.value, undefined),
			le: undefined,
			l12: formatValueWithoutChanges(dataSheetLive?.sheet?.U12_cf?.value, undefined),
			l23: formatValueWithoutChanges(dataSheetLive?.sheet?.U23_cf?.value, undefined),
			l31: formatValueWithoutChanges(dataSheetLive?.sheet?.U31_cf?.value, undefined),
			total: undefined,
			//unit: undefined,
			category: MeasurementCategory.FACTOR,
		}, {
			name: "CF I",
			l1: formatValueWithoutChanges(dataSheetLive?.sheet?.I1_cf?.value, undefined),
			l2: formatValueWithoutChanges(dataSheetLive?.sheet?.I2_cf?.value, undefined),
			l3: formatValueWithoutChanges(dataSheetLive?.sheet?.I3_cf?.value, undefined),
			ln: formatValueWithoutChanges(dataSheetLive?.sheet?.In_cf?.value, undefined),
			le: formatValueWithoutChanges(dataSheetLive?.sheet?.Ie_cf?.value, undefined),
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: undefined,
			//unit: undefined,
			category: MeasurementCategory.FACTOR,
		}, {
			name: "Pst",
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.U1_flicker_pst?.value, null, MeasurementCategory.FLICKER),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.U2_flicker_pst?.value, null, MeasurementCategory.FLICKER),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.U3_flicker_pst?.value, null, MeasurementCategory.FLICKER),
			ln: undefined,
			le: undefined,
			l12: formatValueToFullDeciSON(dataSheetLive?.sheet?.U12_flicker_pst?.value, null, MeasurementCategory.FLICKER),
			l23: formatValueToFullDeciSON(dataSheetLive?.sheet?.U23_flicker_pst?.value, null, MeasurementCategory.FLICKER),
			l31: formatValueToFullDeciSON(dataSheetLive?.sheet?.U31_flicker_pst?.value, null, MeasurementCategory.FLICKER),
			total: undefined,
			//unit: undefined,
			category: MeasurementCategory.FACTOR,
		}, {
			name: "Plt",
			l1: formatValueToFullDeciSON(dataSheetLive?.sheet?.U1_flicker_plt?.value, null, MeasurementCategory.FLICKER),
			l2: formatValueToFullDeciSON(dataSheetLive?.sheet?.U2_flicker_plt?.value, null, MeasurementCategory.FLICKER),
			l3: formatValueToFullDeciSON(dataSheetLive?.sheet?.U3_flicker_plt?.value, null, MeasurementCategory.FLICKER),
			ln: undefined,
			le: undefined,
			l12: formatValueToFullDeciSON(dataSheetLive?.sheet?.U12_flicker_plt?.value, null, MeasurementCategory.FLICKER),
			l23: formatValueToFullDeciSON(dataSheetLive?.sheet?.U23_flicker_plt?.value, null, MeasurementCategory.FLICKER),
			l31: formatValueToFullDeciSON(dataSheetLive?.sheet?.U31_flicker_plt?.value, null, MeasurementCategory.FLICKER),
			total: undefined,
			//unit: undefined,
			category: MeasurementCategory.FACTOR,
		}, {
			name: "K",
			l1: formatValueWithoutChanges(dataSheetLive?.sheet?.I1_kfactor?.value, undefined),
			l2: formatValueWithoutChanges(dataSheetLive?.sheet?.I2_kfactor?.value, undefined),
			l3: formatValueWithoutChanges(dataSheetLive?.sheet?.I3_kfactor?.value, undefined),
			ln: formatValueWithoutChanges(dataSheetLive?.sheet?.In_kfactor?.value, undefined),
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: undefined,
			//unit: undefined,
			category: MeasurementCategory.FACTOR,
		}, {
			name: <span>U<sub>0</sub></span>,
			l1: undefined,
			l2: undefined,
			l3: undefined,
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.U_unbalance_zero_sequence?.value, dataSheetLive?.sheet?.U_unbalance_zero_sequence?.unit, MeasurementCategory.PHASORS),
			//unit: dataSheetLive?.sheet?.U_unbalance_zero_sequence?.unit,
			category: MeasurementCategory.PHASORS,
		}, {
			name: <span>U<sub>1</sub></span>,
			l1: undefined,
			l2: undefined,
			l3: undefined,
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.U_unbalance_positive_sequence?.value, dataSheetLive?.sheet?.U_unbalance_positive_sequence?.unit, MeasurementCategory.PHASORS),
			//unit: dataSheetLive?.sheet?.U_unbalance_positive_sequence?.unit,
			category: MeasurementCategory.PHASORS,
		}, {
			name: <span>U<sub>2</sub></span>,
			l1: undefined,
			l2: undefined,
			l3: undefined,
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.U_unbalance_negative_sequence?.value, dataSheetLive?.sheet?.U_unbalance_negative_sequence?.unit, MeasurementCategory.PHASORS),
			//unit: dataSheetLive?.sheet?.U_unbalance_zero_sequence?.unit,
			category: MeasurementCategory.PHASORS,
		}, {
			name: <span>U<sub>2</sub>/U<sub>1</sub></span>,
			l1: undefined,
			l2: undefined,
			l3: undefined,
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.U_unbalance_zero?.value, dataSheetLive?.sheet?.U_unbalance_zero?.unit, MeasurementCategory.PHASORS),
			//unit: dataSheetLive?.sheet?.U_unbalance_zero?.unit,
			category: MeasurementCategory.PHASORS,
		}, {
			name: <span>U<sub>0</sub>/U<sub>1</sub></span>,
			l1: undefined,
			l2: undefined,
			l3: undefined,
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.U_unbalance_negative?.value, dataSheetLive?.sheet?.U_unbalance_negative?.unit, MeasurementCategory.PHASORS),
			//unit: dataSheetLive?.sheet?.U_unbalance_negative?.unit,
			category: MeasurementCategory.PHASORS,
		}, {
			name: <span>I<sub>0</sub></span>,
			l1: undefined,
			l2: undefined,
			l3: undefined,
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.I_unbalance_zero_sequence?.value, dataSheetLive?.sheet?.I_unbalance_zero_sequence?.unit, MeasurementCategory.PHASORS),
			//unit: dataSheetLive?.sheet?.I_unbalance_zero_sequence?.unit,
			category: MeasurementCategory.PHASORS,
		}, {
			name: <span>I<sub>1</sub></span>,
			l1: undefined,
			l2: undefined,
			l3: undefined,
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.I_unbalance_positive_sequence?.value, dataSheetLive?.sheet?.I_unbalance_positive_sequence?.unit, MeasurementCategory.PHASORS),
			//unit: dataSheetLive?.sheet?.I_unbalance_positive_sequence?.unit,
			category: MeasurementCategory.PHASORS,
		}, {
			name: <span>I<sub>2</sub></span>,
			l1: undefined,
			l2: undefined,
			l3: undefined,
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.I_unbalance_negative_sequence?.value, dataSheetLive?.sheet?.I_unbalance_negative_sequence?.unit, MeasurementCategory.PHASORS),
			//unit: dataSheetLive?.sheet?.I_unbalance_negative_sequence?.unit,
			category: MeasurementCategory.PHASORS,
		}, {
			name: <span>I<sub>2</sub>/I<sub>1</sub></span>,
			l1: undefined,
			l2: undefined,
			l3: undefined,
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.I_unbalance_zero?.value, dataSheetLive?.sheet?.I_unbalance_zero?.unit, MeasurementCategory.PHASORS),
			//unit: dataSheetLive?.sheet?.I_unbalance_zero?.unit,
			category: MeasurementCategory.PHASORS,
		}, {
			name: <span>I<sub>0</sub>/I<sub>1</sub></span>,
			l1: undefined,
			l2: undefined,
			l3: undefined,
			ln: undefined,
			le: undefined,
			l12: undefined,
			l23: undefined,
			l31: undefined,
			total: formatValueToFullDeciSON(dataSheetLive?.sheet?.I_unbalance_negative?.value, dataSheetLive?.sheet?.I_unbalance_negative?.unit, MeasurementCategory.PHASORS),
			//unit: dataSheetLive?.sheet?.I_unbalance_negative?.unit,
			category: MeasurementCategory.PHASORS,
		},
	]
		.filter(item => config.voltages || item.category !== MeasurementCategory.VOLTAGES)
		.filter(item => config.currents || item.category !== MeasurementCategory.CURRENTS)
		.filter(item => config.activePowers || item.category !== MeasurementCategory.ACTIVE_POWERS)
		.filter(item => config.energies || item.category !== MeasurementCategory.ENERGY)
		.filter(item => config.factors || item.category !== MeasurementCategory.FACTOR)
		.filter(item => config.reactivePowers || item.category !== MeasurementCategory.REACTIVE_POWERS)
		.filter(item => config.apparentPowers || item.category !== MeasurementCategory.APPARENT_POWERS)
		.filter(item => config.frequencies || item.category !== MeasurementCategory.FREQUENCIES)
		.filter(item => config.phasors || item.category !== MeasurementCategory.PHASORS)
		.filter(item => config.harmonics || item.category !== MeasurementCategory.HARMONICS)
		.filter(item => config.interharmonics || item.category !== MeasurementCategory.INTERHARMONICS);

	const isTableLoading = isNull(dataSheetLive);

	return (
		<div className="flex flex-col gap-4">

			<MeasurementFilters
				config={ config }
				setConfig={ setConfig }
			/>

			<Table
				className="[&>div]:border-b-0"
				title={ t("TABLE.actual values") }
				cellPadding={ 0 }
				options={ {
					filter: false,
					search: false,
					pagination: false,
					setRowProps: (_, dataIndex) => {
						if (isTableLoading) return {};
						const category = data[ dataIndex ]?.category;
						if (isNull(category)) return {};
						return {
							className: measurementCategoryRowClassNameDictionary()[ category ],
						};
					},
				} }
				isLoading={ isTableLoading }
				// 1 - font-bold
				// 2 - text-base
				// 3 - text-base font-bold
				// 4 - text-lg
				// 5 - text-lg font-bold
				columns={ [
					{
						name: "",
						label: "",
						options: {
							setCellHeaderProps: () => ({ className: "[&>div]:text-center" }),
							setCellProps: () => ({
								className: "[&:not(div)]:border-r [&:not(div)]:border-r-[#e5e7eb] w-24 text-center",
							}),
							customBodyRender: (parameter: TableRecord) =>
								<div>
									<div className={ `text-lg !p-2 ${ measurementCategoryCellClassNameDictionary()[ parameter.category ] }` }>
										{ parameter.name }
									</div>
								</div>,
							viewColumns: false,
							sort: false,
							filter: false,
						},
					}, {
						//	name: "UNIT",
						//	options: {
						//		setCellHeaderProps: () => ({ className: "[&>div]:text-center" }),
						//		setCellProps: () => ({
						//			className: "[&:not(div)]:border-r [&:not(div)]:border-[#e5e7eb] w-20 text-center",
						//		}),
						//		customBodyRender: (parameter: TableRecord) =>
						//			<div>
						//				<span className="text-lg">{ `[${ parameter.unit ?? "---" }]` }</span>
						//			</div>,
						//		sort: false,
						//		filter: false,
						//	},
						//}, {
						name: "L1",
						label: "L1",
						options: {
							setCellHeaderProps: () => ({ className: "[&>div]:text-center" }),
							setCellProps: () => ({
								className: "[&:not(div)]:border-r [&:not(div)]:border-[#e5e7eb] text-center",
							}),
							customBodyRender: (parameter: TableRecord) => (
								<span className="text-lg">
									{ parameter.l1 !== null && parameter.l1 !== undefined ? parameter.l1 : "---" }
								</span>
							),
							sort: false,
							filter: false,
						},
					}, {
						name: "L2",
						label: "L2",
						options: {
							setCellHeaderProps: () => ({ className: "[&>div]:text-center" }),
							setCellProps: () => ({
								className: "[&:not(div)]:border-r [&:not(div)]:border-r-[#e5e7eb] text-center",
							}),
							customBodyRender: (parameter: TableRecord) => (
								<span className="text-lg">
									{ parameter.l2 !== null && parameter.l2 !== undefined ? parameter.l2 : "---" }
								</span>
							),
							sort: false,
							filter: false,
						},
					}, {
						name: "L3",
						label: "L3",
						options: {
							setCellHeaderProps: () => ({ className: "[&>div]:text-center" }),
							setCellProps: () => ({
								className: "[&:not(div)]:border-r [&:not(div)]:border-[#e5e7eb] text-center",
							}),
							customBodyRender: (parameter: TableRecord) => (
								<span className="text-lg">
									{ parameter.l3 !== null && parameter.l3 !== undefined ? parameter.l3 : "---" }
								</span>
							),
							sort: false,
							filter: false,
						},
					}, {
						name: "N",
						label: "N",
						options: {
							setCellHeaderProps: () => ({ className: "[&>div]:text-center" }),
							setCellProps: () => ({
								className: "[&:not(div)]:border-r [&:not(div)]:border-[#e5e7eb] text-center",
							}),
							customBodyRender: (parameter: TableRecord) => (
								<span className="text-lg">
									{ parameter.ln !== null && parameter.ln !== undefined ? parameter.ln : "---" }
								</span>
							),
							sort: false,
							filter: false,
						},
					}, {
						name: "E",
						label: "E",
						options: {
							setCellHeaderProps: () => ({ className: "[&>div]:text-center" }),
							setCellProps: () => ({
								className: "[&:not(div)]:border-r [&:not(div)]:border-[#e5e7eb] text-center",
							}),
							customBodyRender: (parameter: TableRecord) => (
								<span className="text-lg">
									{ parameter.le !== null && parameter.le !== undefined ? parameter.le : "---" }
								</span>
							),
							sort: false,
							filter: false,
						},
					}, {
						name: "L12",
						label: "L12",
						options: {
							setCellHeaderProps: () => ({ className: "[&>div]:text-center" }),
							setCellProps: () => ({
								className: "[&:not(div)]:border-r [&:not(div)]:border-[#e5e7eb] text-center",
							}),
							customBodyRender: (parameter: TableRecord) => (
								<span className="text-lg">
									{ parameter.l12 !== null && parameter.l12 !== undefined ? parameter.l12 : "---" }
								</span>
							),
							sort: false,
							filter: false,
						},
					}, {
						name: "L23",
						label: "L23",
						options: {
							setCellHeaderProps: () => ({ className: "[&>div]:text-center" }),
							setCellProps: () => ({
								className: "[&:not(div)]:border-r [&:not(div)]:border-[#e5e7eb] text-center",
							}),
							customBodyRender: (parameter: TableRecord) => (
								<span className="text-lg">
									{ parameter.l23 !== null && parameter.l23 !== undefined ? parameter.l23 : "---" }
								</span>
							),
							sort: false,
							filter: false,
						},
					}, {
						name: "L31",
						label: "L31",
						options: {
							setCellHeaderProps: () => ({ className: "[&>div]:text-center" }),
							setCellProps: () => ({
								className: "[&:not(div)]:border-r [&:not(div)]:border-[#e5e7eb] text-center",
							}),
							customBodyRender: (parameter: TableRecord) => (
								<span className="text-lg">
									{ parameter.l31 !== null && parameter.l31 !== undefined ? parameter.l31 : "---" }
								</span>
							),
							sort: false,
							filter: false,
						},
					}, {
						name: "TOTAL",
						label: t("TABLE.total").toUpperCase(),
						options: {
							setCellHeaderProps: () => ({ className: "[&>div]:text-center" }),
							setCellProps: () => ({
								className: "text-center",
							}),
							customBodyRender: (parameter: TableRecord) => (
								<span className="text-lg">
									{ parameter.total !== null && parameter.total !== undefined ? parameter.total : "---" }
								</span>
							),
							sort: false,
							filter: false,
						},
					},
				] }
				data={ data }
			/>
		</div>
	);
}

type MeasurementFiltersProps = {
	config: MeasurementsConfigState
	setConfig: Dispatch<SetStateAction<MeasurementsConfigState>>
}

const MeasurementFilters = memo((props: MeasurementFiltersProps) => {

	const { t } = useTranslation();

	const {
		config,
		setConfig,
	} = props;

	return (
		<Card>
			<div className="flex gap-x-4 gap-y-2 flex-wrap">
				<Badge
					className={
						classNames(
							"cursor-pointer text-xs [&>svg]:w-3 [&>svg]:h-3 gap-2 py-[5px] px-2.5 [&>span]:leading-none font-bold",
							{ "[&>svg]:stroke-[20px]": !config.voltages },
						)
					}
					color="voltages"
					icon={ config.voltages ? FaCheckCircle : FaRegCircle }
					size="sm"
					onClick={ () => setConfig(prevState => ({ ...prevState, voltages: !prevState.voltages })) }
				>
					{ t("UTILS.voltages") }
				</Badge>
				<Badge
					className={
						classNames(
							"cursor-pointer text-xs [&>svg]:w-3 [&>svg]:h-3 gap-2 py-[5px] px-2.5 [&>span]:leading-none font-bold",
							{ "[&>svg]:stroke-[20px]": !config.currents },
						)
					}
					color="currents"
					icon={ config.currents ? FaCheckCircle : FaRegCircle }
					size="sm"
					onClick={ () => setConfig(prevState => ({ ...prevState, currents: !prevState.currents })) }
				>
					{ t("UTILS.currents") }
				</Badge>
				<Badge
					className={
						classNames(
							"cursor-pointer text-xs [&>svg]:w-3 [&>svg]:h-3 gap-2 py-[5px] px-2.5 [&>span]:leading-none font-bold",
							{ "[&>svg]:stroke-[20px]": !config.activePowers },
						)
					}
					color="activePowers"
					icon={ config.activePowers ? FaCheckCircle : FaRegCircle }
					size="sm"
					onClick={ () => setConfig(prevState => ({ ...prevState, activePowers: !prevState.activePowers })) }
				>
					{ t("UTILS.active powers") }
				</Badge>
				<Badge
					className={
						classNames(
							"cursor-pointer text-xs [&>svg]:w-3 [&>svg]:h-3 gap-2 py-[5px] px-2.5 [&>span]:leading-none font-bold",
							{ "[&>svg]:stroke-[20px]": !config.reactivePowers },
						)
					}
					color="reactivePowers"
					icon={ config.reactivePowers ? FaCheckCircle : FaRegCircle }
					size="sm"
					onClick={ () => setConfig(prevState => ({ ...prevState, reactivePowers: !prevState.reactivePowers })) }
				>
					{ t("UTILS.reactive powers") }
				</Badge>
				<Badge
					className={
						classNames(
							"cursor-pointer text-xs [&>svg]:w-3 [&>svg]:h-3 gap-2 py-[5px] px-2.5 [&>span]:leading-none font-bold",
							{ "[&>svg]:stroke-[20px]": !config.apparentPowers },
						)
					}
					color="apparentPowers"
					icon={ config.apparentPowers ? FaCheckCircle : FaRegCircle }
					size="sm"
					onClick={ () => setConfig(prevState => ({ ...prevState, apparentPowers: !prevState.apparentPowers })) }
				>
					{ t("UTILS.apparent powers") }
				</Badge>
				<Badge
					className={
						classNames(
							"cursor-pointer text-xs [&>svg]:w-3 [&>svg]:h-3 gap-2 py-[5px] px-2.5 [&>span]:leading-none font-bold",
							{ "[&>svg]:stroke-[20px]": !config.frequencies },
						)
					}
					color="frequencies"
					icon={ config.frequencies ? FaCheckCircle : FaRegCircle }
					size="sm"
					onClick={ () => setConfig(prevState => ({ ...prevState, frequencies: !prevState.frequencies })) }
				>
					{ t("UTILS.frequency") }
				</Badge>
				<Badge
					className={
						classNames(
							"cursor-pointer text-xs [&>svg]:w-3 [&>svg]:h-3 gap-2 py-[5px] px-2.5 [&>span]:leading-none font-bold",
							{ "[&>svg]:stroke-[20px]": !config.energies },
						)
					}
					color="energies"
					icon={ config.energies ? FaCheckCircle : FaRegCircle }
					size="sm"
					onClick={ () => setConfig(prevState => ({ ...prevState, energies: !prevState.energies })) }
				>
					{ t("UTILS.energy") }
				</Badge>
				<Badge
					className={
						classNames(
							"cursor-pointer text-xs [&>svg]:w-3 [&>svg]:h-3 gap-2 py-[5px] px-2.5 [&>span]:leading-none font-bold",
							{ "[&>svg]:stroke-[20px]": !config.factors },
						)
					}
					color="factors"
					icon={ config.factors ? FaCheckCircle : FaRegCircle }
					size="sm"
					onClick={ () => setConfig(prevState => ({ ...prevState, factors: !prevState.factors })) }
				>
					{ t("UTILS.factor") }
				</Badge>
				<Badge
					className={
						classNames(
							"cursor-pointer text-xs [&>svg]:w-3 [&>svg]:h-3 gap-2 py-[5px] px-2.5 [&>span]:leading-none font-bold",
							{ "[&>svg]:stroke-[20px]": !config.phasors },
						)
					}
					color="phasors"
					icon={ config.phasors ? FaCheckCircle : FaRegCircle }
					size="sm"
					onClick={ () => setConfig(prevState => ({ ...prevState, phasors: !prevState.phasors })) }
				>
					{ t("UTILS.unbalance") }
				</Badge>
				{/*<Badge
					className={
						classNames(
							"cursor-pointer text-xs [&>svg]:w-3 [&>svg]:h-3 gap-2 py-[5px] px-2.5 [&>span]:leading-none font-bold",
							{ "[&>svg]:stroke-[20px]": !config.harmonics },
						)
					}
					color="harmonics"
					icon={ config.harmonics ? FaCheckCircle : FaRegCircle }
					size="sm"
					onClick={ () => setConfig(prevState => ({ ...prevState, harmonics: !prevState.harmonics })) }
				>
					{t('UTILS.harmonics')}
				</Badge>
				<Badge
					className={
						classNames(
							"cursor-pointer text-xs [&>svg]:w-3 [&>svg]:h-3 gap-2 py-[5px] px-2.5 [&>span]:leading-none font-bold",
							{ "[&>svg]:stroke-[20px]": !config.interharmonics },
						)
					}
					color="interharmonics"
					icon={ config.interharmonics ? FaCheckCircle : FaRegCircle }
					size="sm"
					onClick={ () => setConfig(prevState => ({ ...prevState, interharmonics: !prevState.interharmonics })) }
				>
					{t('UTILS.interharmonics')}
				</Badge>*/ }
			</div>
		</Card>
	);
});

export default (MeasurementsContainer);
