import { SettingsMeasurementsStandard, SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import QueryParamTabs from "src/app/components/Utils/QueryParamTabs.component";
import MeasurementsStandardCard from "src/app/components/Measurements/MeasurementsStandardCard.component";
import MeasurementsUserContent from "src/app/components/Measurements/MeasurementsUserContent.component";
import { useTranslation } from "react-i18next";

type Props = {
	settingsStandard: SettingsMeasurementsStandard
	settingsUser: SettingsMeasurementsUser
};

enum MeasurementsTab {
	STANDARD = "STANDARD",
	USER = "USER"
}

function MeasurementsContainer(props: Props) {

	const { t } = useTranslation();

	const {
		settingsStandard,
		settingsUser,
	} = props;

	return (
		<div>
			<QueryParamTabs<MeasurementsTab>
				style="underline"
				items={ () => ({
					[ MeasurementsTab.STANDARD ]: {
						name: t("MEASUREMENTS.standard"),
						component: (
							<MeasurementsStandardCard
								settingsMeasurementsStandard={ settingsStandard }
							/>
						),
					},
					[ MeasurementsTab.USER ]: {
						name: t("MEASUREMENTS.user"),
						component: (
							<MeasurementsUserContent
								settingsMeasurementsUser={ settingsUser }
							/>
						),
					},
				}) }
			/>
		</div>
	);
}

export default (MeasurementsContainer);
