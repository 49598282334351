import { Event, EventChannel, EventsCore, EventStatus, EventType, StandardEvents } from "src/app/types/api/event.types";
import { MUIDataTableColumn, MUIDataTableOptions, MUIDataTableState } from "mui-datatables";
import { SortCompare } from "src/app/types/util.types";
import Table from "src/app/components/Utils/Table.component";
import { eventChannelDictionary, eventFlagDictionary, eventTypeDictionary } from "src/app/utils/constants/dictionaries";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import moment from "moment";
import { Button, Card, Checkbox, Label, Tooltip } from "flowbite-react";
import { memo, useMemo, useState } from "react";
import { PiChartLineLight, PiWaveformBold } from "react-icons/pi";
import { Link } from "react-router-dom";
import { formatDuration } from "src/app/utils/helpers";
import { useTranslation } from "react-i18next";
import { HiInformationCircle } from "react-icons/hi";
import { formatValueToFullDeciSON } from "src/app/utils/dataFormatting";
import { MeasurementCategory } from "src/app/types/misc.types";
import classNames from "classnames";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		events: EventsCore
		fetchMoreEvents: (eventsAmount: number) => void
		title: string
		getEventWaveformLink: (event: Event) => string
		getEventRmsWaveformLink: (event: Event) => string
		onDownloadPqDif: (event: Event) => void
	};

function EventsContainer(props: Props) {

	const { t } = useTranslation();

	const {
		events,
		fetchMoreEvents,
		title,
		mainHeight,
		hasMoreEvents,
		getEventWaveformLink,
		getEventRmsWaveformLink,
		onDownloadPqDif,
		isFetchingPqDif,
	} = props;

	const [ types, setTypes ] = useState(events.types.map(type => type.text));
	const [ sources, setSources ] = useState(events.channels.map(channel => channel.text));

	const _changeType = (type: EventType) => {
		setTypes(prevState => {
			if (prevState.includes(type)) {
				return prevState.filter(stateType => stateType !== type);
			} else {
				return [ ...prevState, type ];
			}
		});
	};

	const _changeSource = (source: EventChannel) => {
		setSources(prevState => {
			if (prevState.includes(source)) {
				return prevState.filter(stateSource => stateSource !== source);
			} else {
				return [ ...prevState, source ];
			}
		});
	};

	const _getEventType = (eventType: number) => {
		const type = events.types.find(type => type.value === eventType);
		return type?.text ?? null;
	};

	const _getEventChannel = (eventChannel: number) => {
		const channel = events.channels.find(channel => channel.value === eventChannel);
		return channel?.text ?? null;
	};

	const data = useMemo(() => {
		return events.event
					 .filter(event => {
						 const type = _getEventType(event.type);
						 if (isNull(type)) return false;

						 return types.includes(type);
					 })
					 .filter(event => {
						 const channel = _getEventChannel(event.channels);
						 if (isNull(channel)) return false;

						 return sources.includes(channel);
					 });
	}, [ types.length, sources.length, events.event.length ]);

	return (
		<div className="grid grid-cols-12 gap-4">
			<div
				className={
					classNames(
						"2xl:col-span-2",
						"col-span-3"
					)
				}
			>
				<Card>
					<div>
						<h2 className="text-xl font-medium mb-2">{ t("EVENTS.type") }</h2>
						<div className="mb-4">
							{
								Object
									.values(EventType)
									.map(type =>
										<div
											key={ type }
											className="flex items-center gap-x-3"
										>
											<Checkbox
												id={ type }
												name={ type }
												checked={ types.includes(type) }
												onChange={ () => _changeType(type) }
											/>
											<Label
												htmlFor={ type }
												className="cursor-pointer"
											>
												{ `${ eventTypeDictionary()[ type ] } (${ events.event.filter(event => _getEventType(event.type) === type).length })` }
											</Label>
										</div>,
									)
							}
						</div>
						<h2 className="text-xl font-medium mb-2">{ t("EVENTS.source") }</h2>
						<div>
							{
								Object
									.values(EventChannel)
									.map(channel =>
										<div
											key={ channel }
											className="flex items-center gap-x-3"
										>
											<Checkbox
												id={ channel }
												name={ channel }
												checked={ sources.includes(channel) }
												onChange={ () => _changeSource(channel) }
											/>
											<Label
												htmlFor={ channel }
												className="cursor-pointer"
											>
												{ `${ eventChannelDictionary()[ channel ] } (${ events.event.filter(event => _getEventChannel(event.channels) === channel).length })` }
											</Label>
										</div>,
									)
							}
						</div>
					</div>
				</Card>
			</div>
			<div
				className={
					classNames(
						"2xl:col-span-10",
						"col-span-9"
					)
				}
			>
				<EventTable
					title={ title }
					mainHeight={ mainHeight }
					hasMoreEvents={ hasMoreEvents }
					fetchMoreEvents={ fetchMoreEvents }
					getEventWaveformLink={ getEventWaveformLink }
					getEventRmsWaveformLink={ getEventRmsWaveformLink }
					onDownloadPqDif={ onDownloadPqDif }
					events={ data }
					types={ events.types }
					channels={ events.channels }
					status={ events.status }
					flags={ events.flags }
					isFetchingPqDif={ isFetchingPqDif }
				/>
			</div>
		</div>
	);
}

type EventTableProps = {
	mainHeight: number
	hasMoreEvents: boolean
	title: string
	fetchMoreEvents: (eventsAmount: number) => void
	events: Event[]
	getEventWaveformLink: (event: Event) => string
	getEventRmsWaveformLink: (event: Event) => string
	onDownloadPqDif: (event: Event) => void
	types: StandardEvents["events"]["types"]
	channels: StandardEvents["events"]["channels"]
	status: StandardEvents["events"]["status"]
	flags: StandardEvents["events"]["flags"]
	isFetchingPqDif: (eventId: number) => boolean
}

const EventTable = memo((props: EventTableProps) => {

	const { t } = useTranslation();

	const {
		mainHeight,
		hasMoreEvents,
		title,
		fetchMoreEvents,
		events,
		getEventWaveformLink,
		getEventRmsWaveformLink,
		onDownloadPqDif,
		types,
		channels,
		status,
		flags,
		isFetchingPqDif,
	} = props;

	const _getEventType = (eventType: number) => {
		const type = types.find(type => type.value === eventType);
		return type?.text ?? null;
	};

	const _getEventChannel = (eventChannel: number) => {
		const channel = channels.find(channel => channel.value === eventChannel);
		return channel?.text ?? null;
	};

	const _getEventStatus = (eventStatus: number) => {
		const findedStatus = status.find(status => status.value === eventStatus);
		return findedStatus?.text ?? null;
	};

	const _getFlags = (eventFlag: number) =>
		flags
			.filter(flag => (eventFlag & flag.mask) > 0)
			.map(flag => flag.text);

	const eventColumns: MUIDataTableColumn[] = [
		{
			name: "ID",
			label: t("EVENTS.id").toUpperCase(),
			options: {
				filter: false,
				customBodyRender: (event: Event) => event.id,
				sort: true,
				sortCompare: order => (a: SortCompare<Event>, b: SortCompare<Event>) => {
					if (order === "asc") {
						return a.data.id - b.data.id;
					} else {
						return b.data.id - a.data.id;
					}
				},
			},
		}, {
			name: "Type",
			label: t("EVENTS.type").toUpperCase(),
			options: {
				filter: true,
				filterOptions: {
					names: Object
						.values<EventType>(EventType)
						.map(type => eventTypeDictionary()[ type ]),
					logic: (item, filters, row) => {
						if (isNull(row) || isNull(row[ 0 ])) return false;
						const event: Event = row[ 0 ];
						const eventType = _getEventType(event.type);
						let translatedType = t("EVENTS.unknown");
						if (isNotNull(eventType)) translatedType = eventTypeDictionary()[ eventType ] ?? t("EVENTS.unknown");
						return isNotNull(row) && !filters.map(filterElement => filterElement.toLowerCase()).includes(translatedType.toLowerCase());
					},
				},
				customBodyRender: (event: Event) => {
					const type = _getEventType(event.type);
					if (isNull(type)) return t("EVENTS.unknown");
					return eventTypeDictionary()[ type ] ?? t("EVENTS.unknown");
				},
				sort: true,
				sortCompare: order => (a: SortCompare<Event>, b: SortCompare<Event>) => {
					const eventTypeA = _getEventType(a.data.type);
					const eventTypeB = _getEventType(b.data.type);
					const translatedTypeA = isNotNull(eventTypeA) ? eventTypeDictionary()[ eventTypeA ] : t("EVENTS.unknown");
					const translatedTypeB = isNotNull(eventTypeB) ? eventTypeDictionary()[ eventTypeB ] : t("EVENTS.unknown");
					if (order === "asc") {
						return translatedTypeA.localeCompare(translatedTypeB);
					} else {
						return translatedTypeB.localeCompare(translatedTypeA);
					}
				},
			},
		}, {
			name: "Source",
			label: t("EVENTS.source").toUpperCase(),
			options: {
				filter: true,
				filterOptions: {
					names: Object
						.values<EventChannel>(EventChannel)
						.map(channel => eventChannelDictionary()[ channel ]),
					logic: (item, filters, row) => {
						if (isNull(row) || isNull(row[ 0 ])) return false;
						const event: Event = row[ 0 ];
						const eventChannel = _getEventChannel(event.channels);
						let translatedChannel = t("EVENTS.unknown");
						if (isNotNull(eventChannel)) translatedChannel = eventChannelDictionary()[ eventChannel ] ?? t("EVENTS.unknown");
						return isNotNull(row) && !filters.map(filterElement => filterElement.toLowerCase()).includes(translatedChannel.toLowerCase());
					},
				},
				customBodyRender: (event: Event) => {
					const channel = _getEventChannel(event.channels);
					if (isNull(channel)) return t("EVENTS.unknown");
					return eventChannelDictionary()[ channel ] ?? t("EVENTS.unknown");
				},
				sort: true,
				sortCompare: order => (a: SortCompare<Event>, b: SortCompare<Event>) => {
					const eventChannelA = _getEventChannel(a.data.channels);
					const eventChannelB = _getEventChannel(b.data.channels);
					const translatedChannelA = isNotNull(eventChannelA) ? eventChannelDictionary()[ eventChannelA ] : t("EVENTS.unknown");
					const translatedChannelB = isNotNull(eventChannelB) ? eventChannelDictionary()[ eventChannelB ] : t("EVENTS.unknown");
					if (order === "asc") {
						return translatedChannelA.localeCompare(translatedChannelB);
					} else {
						return translatedChannelB.localeCompare(translatedChannelA);
					}
				},
			},
		}, {
			name: "Flags",
			label: t("EVENTS.flags").toUpperCase(),
			options: {
				sort: false,
				/*filter: true,
				filterOptions: {
					names: Object
						.values<EventFlag>(EventFlag)
						.map(flag => eventFlagDictionary()[ flag ].name),
					logic: (item, filters, row) => {
						if (isNull(row) || isNull(row[ 0 ])) return false;
						const event: Event = row[ 0 ];
						const eventFlags = _getFlags(event.flags);
						return isNotNull(row) && eventFlags.some(flag => !filters.map(filterElement => filterElement.toLowerCase()).includes(eventFlagDictionary()[ flag ].name.toLowerCase()));
					},
				},*/
				customBodyRender: (event: Event) =>
					<div className="flex gap-0.5">
						{
							_getFlags(event.flags).map(flag => {
								const { icon: Icon, name } = eventFlagDictionary()[ flag ];
								return (
									<Tooltip key={ flag } content={ name }>
										<Icon className="text-gray-500 w-6 h-6"/>
									</Tooltip>
								);
							})
						}
					</div>,
			},
		}, {
			name: "Start",
			label: t("EVENTS.start").toUpperCase(),
			options: {
				filter: false,
				customBodyRender: (event: Event) => {
					const isBegin = _getEventStatus(event.timestampStatus) === EventStatus.BEGIN;
					if (isBegin) {
						return <span className="whitespace-nowrap">{ `< ${ moment(event.timestampStart / 1000).format("YYYY-MM-DD HH:mm:ss.SSS") }` }</span>;
					} else {
						return <span className="whitespace-nowrap">{ moment(event.timestampStart / 1000).format("YYYY-MM-DD HH:mm:ss.SSS") }</span>;
					}
				},
				sort: true,
				sortCompare: order => (a: SortCompare<Event>, b: SortCompare<Event>) => {
					if (order === "asc") {
						return b.data.timestampStart - a.data.timestampStart;
					} else {
						return a.data.timestampStart - b.data.timestampStart;
					}
				},
			},
		}, {
			name: "End",
			label: t("EVENTS.end").toUpperCase(),
			options: {
				filter: false,
				customBodyRender: (event: Event) => {
					const isProgress = _getEventStatus(event.timestampStatus) === EventStatus.PROGRESS;
					if (isProgress) {
						return <span className="whitespace-nowrap">{ `> ${ moment(event.timestampEnd / 1000).format("YYYY-MM-DD HH:mm:ss.SSS") }` }</span>;
					} else {
						return <span className="whitespace-nowrap">{ moment(event.timestampEnd / 1000).format("YYYY-MM-DD HH:mm:ss.SSS") }</span>;
					}
				},
				sort: true,
				sortCompare: order => (a: SortCompare<Event>, b: SortCompare<Event>) => {
					if (order === "asc") {
						return b.data.timestampEnd - a.data.timestampEnd;
					} else {
						return a.data.timestampEnd - b.data.timestampEnd;
					}
				},
			},
		}, {
			name: "Duration",
			label: t("EVENTS.duration").toUpperCase(),
			options: {
				filter: false,
				customBodyRender: (event: Event) => formatDuration(event.duration / 1000, "ms"),
				sort: true,
				sortCompare: order => (a: SortCompare<Event>, b: SortCompare<Event>) => {
					if (order === "asc") {
						return b.data.duration - a.data.duration;
					} else {
						return a.data.duration - b.data.duration;
					}
				},
			},
		}, {
			name: "Extremum",
			label: t("EVENTS.extremum").toUpperCase(),
			options: {
				filter: false,
				customBodyRender: (event: Event) => formatValueToFullDeciSON(event?.extremum?.value, event?.extremum?.unit, MeasurementCategory.VOLTAGES) ?? "---",
				sort: true,
				sortCompare: order => (a: SortCompare<Event>, b: SortCompare<Event>) => {
					if (order === "asc") {
						return (b.data.extremum.value ?? 0) - (a.data.extremum.value ?? 0);
					} else {
						return (a.data.extremum.value ?? 0) - (b.data.extremum.value ?? 0);
					}
				},
			},
		}, {
			name: "Average",
			label: t("EVENTS.average").toUpperCase(),
			options: {
				filter: false,
				customBodyRender: (event: Event) => formatValueToFullDeciSON(event?.avgValue?.value, event?.avgValue?.unit, MeasurementCategory.VOLTAGES) ?? "---",
				sort: true,
				sortCompare: order => (a: SortCompare<Event>, b: SortCompare<Event>) => {
					if (order === "asc") {
						return (b.data.avgValue.value ?? 0) - (a.data.avgValue.value ?? 0);
					} else {
						return (a.data.avgValue.value ?? 0) - (b.data.avgValue.value ?? 0);
					}
				},
			},
		}, {
			name: "Threshold",
			label: t("EVENTS.threshold").toUpperCase(),
			options: {
				filter: false,
				customBodyRender: (event: Event) => formatValueToFullDeciSON(event?.threshold?.value, event?.threshold?.unit, MeasurementCategory.VOLTAGES) ?? "---",
				sort: true,
				sortCompare: order => (a: SortCompare<Event>, b: SortCompare<Event>) => {
					if (order === "asc") {
						return (b.data.threshold.value ?? 0) - (a.data.threshold.value ?? 0);
					} else {
						return (a.data.threshold.value ?? 0) - (b.data.threshold.value ?? 0);
					}
				},
			},
		}, {
			name: "Actions",
			label: t("EVENTS.actions").toUpperCase(),
			options: {
				filter: false,
				sort: false,
				customBodyRender: (event: Event) => {
					const isFetching = isFetchingPqDif(event.id);
					return (
						<div className="flex gap-2 items-center sm:justify-end">
							<Link to={ getEventWaveformLink(event) } target="_blank">
								<Tooltip placement="left" content={ t("EVENTS.waveform") }>
									<Button
										color="transparent"
										className="p-0 [&>span]:p-2"
									>
										<PiWaveformBold
											className="h-5 w-5 text-[#009FE3] dark:text-dark-textGray"
										/>
									</Button>
								</Tooltip>
							</Link>
							<Link to={ getEventRmsWaveformLink(event) } target="_blank">
								<Tooltip placement="left" content={ t("EVENTS.rms") }>
									<Button
										color="transparent"
										className="p-0 [&>span]:p-2"
									>
										<PiChartLineLight
											className="h-5 w-5 text-[#009FE3] dark:text-dark-textGray"
										/>
									</Button>
								</Tooltip>
							</Link>
							<Button
								color="transparent"
								className={
									classNames(
										"p-0 [&>span]:p-2",
										{ "[&>span>span]:left-[20px]": isFetching },
									)
								}
								isProcessing={ isFetching }
								onClick={ () => onDownloadPqDif(event) }
							>
								<span
									className={
										classNames(
											"font-bold text-sm text-[#009FE3]",
											{ "opacity-0": isFetching },
										)
									}
								>
									PQDIF
								</span>
							</Button>
						</div>
					);
				},
				setCellHeaderProps: () => ({ style: { textAlign: "right" } }),
			},
		},
	];

	const tableOptions: MUIDataTableOptions = {
		search: false,
		filter: false,
		onTableChange: (action: string, tableState: MUIDataTableState) => {
			const pages = Math.ceil(tableState.data.length / tableState.rowsPerPage);
			const actualPage = tableState.page + 1; // Goes from 0;
			const pageOffset = 3;
			if (pages - pageOffset <= actualPage && hasMoreEvents) {
				fetchMoreEvents(tableState.data.length);
			}
		},
	};

	return (
		<Table
			title={
				<div className="flex gap-2 items-center">
					<h6 className="text-xl font-medium">{ title }</h6>
					<Tooltip
						placement="right"
						content={
							<div className="flex flex-col">
								<span>{ t("EVENTS.display times for graphs") }</span>
								<span>{ t("EVENTS.oscillograms") }</span>
								<span>{ t("EVENTS.rms 1/2") }</span>
							</div>
						}
					>
						<HiInformationCircle
							className="cursor-pointer h-6 w-6 text-[#009FE3] dark:text-dark-textGray"
						/>
					</Tooltip>
				</div>
			}
			columns={ eventColumns }
			options={ tableOptions }
			wrapperHeight={ mainHeight }
			data={ events }
			cellHeight={ 53 }
		/>
	);
});

const mapStateToProps = (state: RootState) => ({
	mainHeight: state.ui.layout.mainSize.height,
	hasMoreEvents: state.dashboard.hasMoreStandardEvents,
	isFetchingPqDif: (eventId: number) => didLoadingRecordExist(state, { loadableId: eventId, loadableType: LoadableType.DOWNLOAD_EVENT_PQDIF }),
});

export default connect(mapStateToProps)(EventsContainer);
