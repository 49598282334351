import StandardEventWaveformView from "src/app/views/Event/StandardEventWaveform.view";
import UserEventWaveformView from "src/app/views/Event/UserEventWaveform.view";
import StandardEventRmsWaveformView from "src/app/views/Event/StandardEventRmsWaveform.view";
import UserEventRmsWaveformView from "src/app/views/Event/UserEventRmsWaveform.view";

// Routes import plop - don't remove

const privateNoSidebarRoutes = [
	{
		path: "/standard-events/:standardEventId/waveform",
		element: <StandardEventWaveformView/>,
	}, {
		path: "/user-events/:userEventId/waveform",
		element: <UserEventWaveformView/>,
	}, {
		path: "/standard-events/:standardEventId/rms",
		element: <StandardEventRmsWaveformView/>,
	}, {
		path: "/user-events/:userEventId/rms",
		element: <UserEventRmsWaveformView/>,
	},
	// Routes add route plop - don't remove
];

export default privateNoSidebarRoutes;
