import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import React from "react";
import ImportExportContainer from "src/app/containers/Settings/ImportExport.container";
import { uiExportSettings, uiImportSettings } from "src/app/store/features/ui/dashboard/ui.dashboard.actions";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function ImportExportView(props: Props) {

	const {
		exportSettings,
		importSettings,
	} = props;

	return (
		<ImportExportContainer
			exportSettings={ exportSettings }
			importSettings={ importSettings }
		/>
	);
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {
	exportSettings: uiExportSettings,
	importSettings: uiImportSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportExportView);
