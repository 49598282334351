import { SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import HarmonicsAnglesUiCard from "src/app/components/MeasurementsUser/HarmonicsAnglesUiCard.component";
import ActivePowerHarmonicsCard from "src/app/components/MeasurementsUser/ActivePowerHarmonicsCard.component";
import ReactivePowerHarmonicsCard from "src/app/components/MeasurementsUser/ReactivePowerHarmonicsCard.component";
import KfactorCard from "src/app/components/MeasurementsUser/KfactorCard.component";
import MainsSignallingUr1Card from "src/app/components/MeasurementsUser/MainsSignallingUr1Card.component";
import MainsSignallingUr2Card from "src/app/components/MeasurementsUser/MainsSignallingUr2Card.component";
import Voltage29KCard from "src/app/components/MeasurementsUser/Voltage29kCard.component";
import Voltage8150KCard from "src/app/components/MeasurementsUser/Voltage8150kCard.component";

type Props = {
	settingsMeasurementsUser: SettingsMeasurementsUser
};

function HarmonicsAdditionalTab(props: Props) {

	const {
		settingsMeasurementsUser,
	} = props;

	return (
		<div className="p-4 flex flex-col gap-3">
			<ActivePowerHarmonicsCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<ReactivePowerHarmonicsCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<HarmonicsAnglesUiCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<KfactorCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<MainsSignallingUr1Card
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<MainsSignallingUr2Card
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<Voltage29KCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<Voltage8150KCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
		</div>
	);
}

export default (HarmonicsAdditionalTab);
