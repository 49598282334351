import { RouteObject } from "react-router/dist/lib/context";
import DefaultRoute from "src/app/hoc/routes/Default.route";
import AuthRoute from "src/app/hoc/routes/Auth.route";
import authRoutes from "src/app/utils/routes/auth.routes";
import PrivateRoute from "src/app/hoc/routes/Private.route";
import privateRoutes from "src/app/utils/routes/private.routes";
import NotFoundView from "src/app/views/Utils/NotFound.view";
import CheckQueryParamsView from "src/app/views/Utils/CheckQueryParams.view";
import { lazy } from "react";
import DashboardNoSidebarLayout from "src/app/hoc/layouts/DashboardNoSidebar.layout";
import privateNoSidebarRoutes from "src/app/utils/routes/privateNoSidebar.routes";

const AuthLayout = lazy(() => import("src/app/hoc/layouts/Auth.layout"));
const DashboardLayout = lazy(() => import("src/app/hoc/layouts/Dashboard.layout"));

export const appRoutes: RouteObject[] = ([
	{
		index: true,
		element: <DefaultRoute/>,
	},
	{
		element: <AuthRoute/>,
		children: [
			{
				element: <AuthLayout/>,
				children: authRoutes,
			},
		],
	},
	{
		element: <PrivateRoute/>,
		children: [
			{
				element: <DashboardLayout/>,
				children: [
					...privateRoutes,
					{
						// Not found page redirect to /login when isAuthorized === false
						path: "*",
						element: <NotFoundView/>,
					},
				],
			},
			{
				element: <DashboardNoSidebarLayout/>,
				children: [
					...privateNoSidebarRoutes,
					{
						// Not found page redirect to /login when isAuthorized === false
						path: "*",
						element: <NotFoundView/>,
					},
				],
			},
		],
	},
	{
		path: "/route/:action",
		element: <CheckQueryParamsView/>,
	},
]);
