import UsersContainer from "src/app/containers/User/Users.container";
import { uiCreateUser, uiDeleteUser, uiFetchUsers, uiResetPassword } from "src/app/store/features/ui/user/ui.user.actions";
import { connect } from "react-redux";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";
import { RootState } from "src/app/store/root.reducer";
import { useTranslation } from "react-i18next";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function UsersView(props: Props) {

	const { t } = useTranslation();

	const {
		fetchUsers,
		users,
		createUser,
		deleteUser,
		resetPassword,
	} = props;

	return (
		<CachedThenFreshStrategy
			request={ fetchUsers }
			state={ users }
		>
			{
				({ users }) =>
					<UsersContainer
						title={ t("SIDEBAR.users") }
						users={ users.user }
						onCreateUser={ createUser }
						onDeleteUser={ deleteUser }
						onResetPassword={ resetPassword }
					/>
			}
		</CachedThenFreshStrategy>
	);
}

const mapStateToProps = (state: RootState) => ({
	users: state.user.users,
});

const mapDispatchToProps = {
	fetchUsers: uiFetchUsers,
	createUser: uiCreateUser,
	deleteUser: uiDeleteUser,
	resetPassword: uiResetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersView);