import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import CommunicationsContainer from "src/app/containers/Settings/Communications.container";
import { fetchSettingsIecAsync, fetchSettingsModbusAsync } from "src/app/store/features/settings/settings.actions";
import CallbackPromptProvider from "src/app/hoc/providers/CallbackPrompt.provider";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";
import { getCommunicationsSettings } from "src/app/store/features/settings/settings.selectors";
import { uiFetchSettingsIecProfile } from "src/app/store/features/ui/settings/ui.settings.actions";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function CommunicationsView(props: Props) {

	const {
		fetchSettingsModbus,
		fetchIecSettings,
		fetchIecSettingsProfile,
		communicationsSettings,
	} = props;

	const _handleRequest = () => {
		fetchSettingsModbus();
		fetchIecSettings();
	};

	return (
		<CallbackPromptProvider>
			<CachedThenFreshStrategy
				request={ _handleRequest }
				state={ communicationsSettings }
			>
				{
					({ modbus, iec }) =>
						<CommunicationsContainer
							settingsModbus={ modbus }
							settingsIec={ iec }
							fetchIecSettingsProfile={ fetchIecSettingsProfile }
						/>
				}
			</CachedThenFreshStrategy>
		</CallbackPromptProvider>
	);
}

const mapStateToProps = (state: RootState) => ({
	communicationsSettings: getCommunicationsSettings(state),
});

const mapDispatchToProps = {
	fetchSettingsModbus: fetchSettingsModbusAsync.request,
	fetchIecSettings: fetchSettingsIecAsync.request,
	fetchIecSettingsProfile: uiFetchSettingsIecProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationsView);
