import { DataHarmonicsFundLive, DataHarmonicsLive, DataHarmonicsPowerLive, DataHarmonicsRmsLive } from "src/app/types/api/ws.types";
import { EnumDictionary, Nullable } from "src/app/types/util.types";
import HarmonicChart, { HarmonicChartData, HarmonicConfigState, HarmonicLine } from "src/app/components/Chart/Harmonic/HarmonicChart.component";
import classNames from "classnames";
import { Button, Card, Tabs } from "flowbite-react";
import { MdOutlineFileUpload } from "react-icons/md";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { LayoutVariant } from "src/app/types/ui/layout.types";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import { layoutVariantDictionary, measurementCategoryCellClassNameDictionary, measurementCategoryRowClassNameDictionary } from "src/app/utils/constants/dictionaries";
import Table from "src/app/components/Utils/Table.component";
import { saveStockchart } from "src/app/utils/helpers";
import Dropdown, { DropdownItem } from "src/app/components/Utils/Dropdown.component";
import { formatValueToDeciSON, formatValueToFullDeciSON, getPrefixToUnit } from "src/app/utils/dataFormatting";
import moment from "moment/moment";
import HarmonicPowerChart, { HarmonicPowerChartData, HarmonicPowerConfigState, HarmonicPowerLine } from "src/app/components/Chart/Harmonic/HarmonicPowerChart.component";
import ChartFilter, { ChartFilterColor } from "src/app/components/Utils/ChartFilter.component";
import { MeasurementCategory } from "src/app/types/misc.types";
import { useTranslation } from "react-i18next";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		baseHarmonics: Nullable<DataHarmonicsLive>
		fundHarmonics: Nullable<DataHarmonicsFundLive>
		rmsHarmonics: Nullable<DataHarmonicsRmsLive>
		powerHarmonics: Nullable<DataHarmonicsPowerLive>
	};

type TableRecord = {
	name: ReactNode
	value: Nullable<string>
	unit: Nullable<string>
}

const CHART_CARD_HEIGHT = 600;

enum HarmonicVariant {
	BASE = 0,
	FUND = 1,
	RMS = 2,
	POWERS = 3,
}

const harmonicVariantDictionary: EnumDictionary<HarmonicVariant, string> = () => ({
	[ HarmonicVariant.BASE ]: "base",
	[ HarmonicVariant.FUND ]: "fund",
	[ HarmonicVariant.RMS ]: "rms",
	[ HarmonicVariant.POWERS ]: "power",
});

type ChartBaseConfigState = {
	withFirst: boolean
	showOdd: boolean
}

function HarmonicContainer(props: Props) {

	const {
		baseHarmonics,
		fundHarmonics,
		rmsHarmonics,
		powerHarmonics,
		isSidebarOpen,
		bodySize,
	} = props;

	const { t } = useTranslation();
	const cardRef = useRef<HTMLDivElement>(null);
	const [ cardWidth, setCardWidth ] = useState(0);
	const [ displayedRange, setDisplayedRange ] = useState<[ fromIndex: number, toIndex: number ]>([ 0, 50 ]);
	const [ variant, setVariant ] = useState(LayoutVariant.ALL);
	const [ activeTab, setActiveTab ] = useState(HarmonicVariant.BASE);

	const [ chartConfig, setChartConfig ] = useState<ChartBaseConfigState>({
		withFirst: false,
		showOdd: false,
	});

	const isBaseHarmonic = isNotNull(baseHarmonics);
	const isFundHarmonic = isNotNull(fundHarmonics);
	const isRmsHarmonic = isNotNull(rmsHarmonics);
	const isPowerHarmonic = isNotNull(powerHarmonics);

	const [ isHarmonicConfigSet, toggleHarmonicConfig ] = useState(false);
	const [ isFundHarmonicConfigSet, toggleFundHarmonicConfig ] = useState(false);
	const [ isRmsHarmonicConfigSet, toggleRmsHarmonicConfig ] = useState(false);
	const [ isPowerHarmonicConfigSet, togglePowerHarmonicConfig ] = useState(false);

	useEffect(() => {
		if (isBaseHarmonic && !isHarmonicConfigSet) {
			setHarmonics({
				[ HarmonicLine.U1 ]: isU1Harmonic,
				[ HarmonicLine.U2 ]: isU2Harmonic,
				[ HarmonicLine.U3 ]: isU3Harmonic,
				[ HarmonicLine.I1 ]: isI1Harmonic,
				[ HarmonicLine.I2 ]: isI2Harmonic,
				[ HarmonicLine.I3 ]: isI3Harmonic,
			});
			toggleHarmonicConfig(true);
		}
	}, [ isBaseHarmonic ]);

	useEffect(() => {
		if (isFundHarmonic && !isFundHarmonicConfigSet) {
			setFundHarmonics({
				[ HarmonicLine.U1 ]: isU1FundHarmonic,
				[ HarmonicLine.U2 ]: isU2FundHarmonic,
				[ HarmonicLine.U3 ]: isU3FundHarmonic,
				[ HarmonicLine.I1 ]: isI1FundHarmonic,
				[ HarmonicLine.I2 ]: isI2FundHarmonic,
				[ HarmonicLine.I3 ]: isI3FundHarmonic,
			});
			toggleFundHarmonicConfig(true);
		}
	}, [ isFundHarmonic ]);

	useEffect(() => {
		if (isRmsHarmonic && !isRmsHarmonicConfigSet) {
			setRmsHarmonics({
				[ HarmonicLine.U1 ]: isU1RmsHarmonic,
				[ HarmonicLine.U2 ]: isU2RmsHarmonic,
				[ HarmonicLine.U3 ]: isU3RmsHarmonic,
				[ HarmonicLine.I1 ]: isI1RmsHarmonic,
				[ HarmonicLine.I2 ]: isI2RmsHarmonic,
				[ HarmonicLine.I3 ]: isI3RmsHarmonic,
			});
			toggleRmsHarmonicConfig(true);
		}
	}, [ isRmsHarmonic ]);

	useEffect(() => {
		if (isPowerHarmonic && !isPowerHarmonicConfigSet) {
			setHarmonicPowers({
				[ HarmonicPowerLine.U1_I1_active ]: isU1ActiveHarmonic,
				[ HarmonicPowerLine.U2_I2_active ]: isU2ActiveHarmonic,
				[ HarmonicPowerLine.U3_I3_active ]: isU3ActiveHarmonic,
				[ HarmonicPowerLine.U1_I1_reactive ]: isU1ReactiveHarmonic,
				[ HarmonicPowerLine.U2_I2_reactive ]: isU2ReactiveHarmonic,
				[ HarmonicPowerLine.U3_I3_reactive ]: isU3ReactiveHarmonic,
			});
			togglePowerHarmonicConfig(true);
		}
	}, [ isPowerHarmonic ]);

	const isU1Harmonic = isNotNull(baseHarmonics?.harmonics?.U1_harmonic);
	const isU2Harmonic = isNotNull(baseHarmonics?.harmonics?.U2_harmonic);
	const isU3Harmonic = isNotNull(baseHarmonics?.harmonics?.U3_harmonic);
	const isI1Harmonic = isNotNull(baseHarmonics?.harmonics?.I1_harmonic);
	const isI2Harmonic = isNotNull(baseHarmonics?.harmonics?.I2_harmonic);
	const isI3Harmonic = isNotNull(baseHarmonics?.harmonics?.I3_harmonic);

	const isU1FundHarmonic = isNotNull(fundHarmonics?.harmonics?.U1_harmonic_fund);
	const isU2FundHarmonic = isNotNull(fundHarmonics?.harmonics?.U2_harmonic_fund);
	const isU3FundHarmonic = isNotNull(fundHarmonics?.harmonics?.U3_harmonic_fund);
	const isI1FundHarmonic = isNotNull(fundHarmonics?.harmonics?.I1_harmonic_fund);
	const isI2FundHarmonic = isNotNull(fundHarmonics?.harmonics?.I2_harmonic_fund);
	const isI3FundHarmonic = isNotNull(fundHarmonics?.harmonics?.I3_harmonic_fund);

	const isU1RmsHarmonic = isNotNull(rmsHarmonics?.harmonics?.U1_harmonic_rms);
	const isU2RmsHarmonic = isNotNull(rmsHarmonics?.harmonics?.U2_harmonic_rms);
	const isU3RmsHarmonic = isNotNull(rmsHarmonics?.harmonics?.U3_harmonic_rms);
	const isI1RmsHarmonic = isNotNull(rmsHarmonics?.harmonics?.I1_harmonic_rms);
	const isI2RmsHarmonic = isNotNull(rmsHarmonics?.harmonics?.I2_harmonic_rms);
	const isI3RmsHarmonic = isNotNull(rmsHarmonics?.harmonics?.I3_harmonic_rms);

	const isU1ActiveHarmonic = isNotNull(powerHarmonics?.harmonics?.U1_I1_harmonic_active_power);
	const isU2ActiveHarmonic = isNotNull(powerHarmonics?.harmonics?.U2_I2_harmonic_active_power);
	const isU3ActiveHarmonic = isNotNull(powerHarmonics?.harmonics?.U3_I3_harmonic_active_power);
	const isU1ReactiveHarmonic = isNotNull(powerHarmonics?.harmonics?.U1_I1_harmonic_reactive_power);
	const isU2ReactiveHarmonic = isNotNull(powerHarmonics?.harmonics?.U2_I2_harmonic_reactive_power);
	const isU3ReactiveHarmonic = isNotNull(powerHarmonics?.harmonics?.U3_I3_harmonic_reactive_power);

	const [ harmonics, setHarmonics ] = useState<HarmonicConfigState>({
		[ HarmonicLine.U1 ]: isU1Harmonic,
		[ HarmonicLine.U2 ]: isU2Harmonic,
		[ HarmonicLine.U3 ]: isU3Harmonic,
		[ HarmonicLine.I1 ]: isI1Harmonic,
		[ HarmonicLine.I2 ]: isI2Harmonic,
		[ HarmonicLine.I3 ]: isI3Harmonic,
	});
	const [ fundHarmonicsConfig, setFundHarmonics ] = useState<HarmonicConfigState>({
		[ HarmonicLine.U1 ]: isU1FundHarmonic,
		[ HarmonicLine.U2 ]: isU2FundHarmonic,
		[ HarmonicLine.U3 ]: isU3FundHarmonic,
		[ HarmonicLine.I1 ]: isI1FundHarmonic,
		[ HarmonicLine.I2 ]: isI2FundHarmonic,
		[ HarmonicLine.I3 ]: isI3FundHarmonic,
	});
	const [ rmsHarmonicsConfig, setRmsHarmonics ] = useState<HarmonicConfigState>({
		[ HarmonicLine.U1 ]: isU1RmsHarmonic,
		[ HarmonicLine.U2 ]: isU2RmsHarmonic,
		[ HarmonicLine.U3 ]: isU3RmsHarmonic,
		[ HarmonicLine.I1 ]: isI1RmsHarmonic,
		[ HarmonicLine.I2 ]: isI2RmsHarmonic,
		[ HarmonicLine.I3 ]: isI3RmsHarmonic,
	});
	const [ harmonicPowers, setHarmonicPowers ] = useState<HarmonicPowerConfigState>({
		[ HarmonicPowerLine.U1_I1_active ]: isU1ActiveHarmonic,
		[ HarmonicPowerLine.U2_I2_active ]: isU2ActiveHarmonic,
		[ HarmonicPowerLine.U3_I3_active ]: isU3ActiveHarmonic,
		[ HarmonicPowerLine.U1_I1_reactive ]: isU1ReactiveHarmonic,
		[ HarmonicPowerLine.U2_I2_reactive ]: isU2ReactiveHarmonic,
		[ HarmonicPowerLine.U3_I3_reactive ]: isU3ReactiveHarmonic,
	});

	useEffect(() => {
		if (isNotNull(cardRef.current)) {
			setCardWidth(cardRef.current.getBoundingClientRect().width);
		}
	}, [ bodySize.width, isSidebarOpen, variant ]);

	const _getHarmonicData = (displayedRange: [ fromIndex: number, toIndex: number ], showOdd: boolean, withFirst: boolean): HarmonicChartData[] => {
		if (isNull(baseHarmonics)) return [];

		const baseData: HarmonicChartData[] = [];
		for (let i = displayedRange[ 0 ] ; i < displayedRange[ 0 ] + Math.abs(displayedRange[ 1 ] - displayedRange[ 0 ]) ; i++) {
			if (showOdd && i % 2 === 0) continue;

			baseData.push({
				U1: baseHarmonics?.harmonics?.U1_harmonic?.value?.[ i ],
				U2: baseHarmonics?.harmonics?.U2_harmonic?.value?.[ i ],
				U3: baseHarmonics?.harmonics?.U3_harmonic?.value?.[ i ],
				I1: baseHarmonics?.harmonics?.I1_harmonic?.value?.[ i ],
				I2: baseHarmonics?.harmonics?.I2_harmonic?.value?.[ i ],
				I3: baseHarmonics?.harmonics?.I3_harmonic?.value?.[ i ],
				index: i,
				date: new Date(i),
			});
		}

		if (displayedRange[ 0 ] !== 0) return baseData;

		return withFirst ? baseData : baseData.filter(item => item.index !== 1);
	};

	const _getHarmonicFundData = (displayedRange: [ fromIndex: number, toIndex: number ], showOdd: boolean, withFirst: boolean): HarmonicChartData[] => {
		if (isNull(fundHarmonics)) return [];

		const fundData: HarmonicChartData[] = [];
		for (let i = displayedRange[ 0 ] ; i < displayedRange[ 0 ] + Math.abs(displayedRange[ 1 ] - displayedRange[ 0 ]) ; i++) {
			if (showOdd && i % 2 === 0) continue;

			fundData.push({
				U1: fundHarmonics.harmonics.U1_harmonic_fund?.value?.[ i ],
				U2: fundHarmonics.harmonics.U2_harmonic_fund?.value?.[ i ],
				U3: fundHarmonics.harmonics.U3_harmonic_fund?.value?.[ i ],
				I1: fundHarmonics.harmonics.I1_harmonic_fund?.value?.[ i ],
				I2: fundHarmonics.harmonics.I2_harmonic_fund?.value?.[ i ],
				I3: fundHarmonics.harmonics.I3_harmonic_fund?.value?.[ i ],
				index: i,
				date: new Date(i),
			});
		}

		if (displayedRange[ 0 ] !== 0) return fundData;

		return withFirst ? fundData : fundData.filter(item => item.index !== 1);
	};

	const _getHarmonicRmsData = (displayedRange: [ fromIndex: number, toIndex: number ], showOdd: boolean, withFirst: boolean): HarmonicChartData[] => {
		if (isNull(rmsHarmonics)) return [];

		const rmsData: HarmonicChartData[] = [];
		for (let i = displayedRange[ 0 ] ; i < displayedRange[ 0 ] + Math.abs(displayedRange[ 1 ] - displayedRange[ 0 ]) ; i++) {
			if (showOdd && i % 2 === 0) continue;

			rmsData.push({
				U1: rmsHarmonics.harmonics.U1_harmonic_rms?.value?.[ i ],
				U2: rmsHarmonics.harmonics.U2_harmonic_rms?.value?.[ i ],
				U3: rmsHarmonics.harmonics.U3_harmonic_rms?.value?.[ i ],
				I1: rmsHarmonics.harmonics.I1_harmonic_rms?.value?.[ i ],
				I2: rmsHarmonics.harmonics.I2_harmonic_rms?.value?.[ i ],
				I3: rmsHarmonics.harmonics.I3_harmonic_rms?.value?.[ i ],
				index: i,
				date: new Date(i),
			});
		}

		if (displayedRange[ 0 ] !== 0) return rmsData;

		return withFirst ? rmsData : rmsData.filter(item => item.index !== 1);
	};

	const _getHarmonicPowerData = (displayedRange: [ fromIndex: number, toIndex: number ], showOdd: boolean, withFirst: boolean): HarmonicPowerChartData[] => {
		if (isNull(powerHarmonics)) return [];

		const powerData: HarmonicPowerChartData[] = [];
		for (let i = displayedRange[ 0 ] ; i < displayedRange[ 0 ] + Math.abs(displayedRange[ 1 ] - displayedRange[ 0 ]) ; i++) {
			if (showOdd && i % 2 === 0) continue;

			powerData.push({
				U1_I1_active: powerHarmonics.harmonics.U1_I1_harmonic_active_power?.value?.[ i ],
				U2_I2_active: powerHarmonics.harmonics.U2_I2_harmonic_active_power?.value?.[ i ],
				U3_I3_active: powerHarmonics.harmonics.U3_I3_harmonic_active_power?.value?.[ i ],
				U1_I1_reactive: powerHarmonics.harmonics.U1_I1_harmonic_reactive_power?.value?.[ i ],
				U2_I2_reactive: powerHarmonics.harmonics.U2_I2_harmonic_reactive_power?.value?.[ i ],
				U3_I3_reactive: powerHarmonics.harmonics.U3_I3_harmonic_reactive_power?.value?.[ i ],
				index: i,
				date: new Date(i),
			});
		}

		if (displayedRange[ 0 ] !== 0) return powerData;

		return withFirst ? powerData : powerData.filter(item => item.index !== 1);
	};

	const _exportToCsv = () => {
		let rows: string[][] = [];
		const range: [ number, number ] = [ 0, 256 ];
		switch (activeTab) {
			case HarmonicVariant.BASE:
				const harmonicData = _getHarmonicData(range, false, true);
				rows = [
					[
						t("TABLE.index"),
						harmonics.U1 ? `U1[${ baseHarmonics?.harmonics?.U1_harmonic?.unit ?? "V" }]` : null,
						harmonics.U2 ? `U2[${ baseHarmonics?.harmonics?.U2_harmonic?.unit ?? "V" }]` : null,
						harmonics.U3 ? `U3[${ baseHarmonics?.harmonics?.U3_harmonic?.unit ?? "V" }]` : null,
						harmonics.I1 ? `I1[${ baseHarmonics?.harmonics?.I1_harmonic?.unit ?? "A" }]` : null,
						harmonics.I2 ? `I2[${ baseHarmonics?.harmonics?.I2_harmonic?.unit ?? "A" }]` : null,
						harmonics.I3 ? `I3[${ baseHarmonics?.harmonics?.I3_harmonic?.unit ?? "A" }]` : null,
					].filter(isNotNull),
					...harmonicData
						.map(dataItem =>
							[
								`"${ dataItem.index.toFixed().replace(".", ",") }"`,
								harmonics.U1 ? `"${ (dataItem.U1 ?? 0).toFixed(6).replace(".", ",") }"` : null,
								harmonics.U2 ? `"${ (dataItem.U2 ?? 0).toFixed(6).replace(".", ",") }"` : null,
								harmonics.U3 ? `"${ (dataItem.U3 ?? 0).toFixed(6).replace(".", ",") }"` : null,
								harmonics.I1 ? `"${ (dataItem.I1 ?? 0).toFixed(6).replace(".", ",") }"` : null,
								harmonics.I2 ? `"${ (dataItem.I2 ?? 0).toFixed(6).replace(".", ",") }"` : null,
								harmonics.I3 ? `"${ (dataItem.I3 ?? 0).toFixed(6).replace(".", ",") }"` : null,
							].filter(isNotNull),
						),
				];
				break;
			case HarmonicVariant.FUND:
				const harmonicFundData = _getHarmonicFundData(range, false, true);
				rows = [
					[
						t("TABLE.index"),
						harmonics.U1 ? `U1[${ fundHarmonics?.harmonics?.U1_harmonic_fund?.unit ?? "V" }]` : null,
						harmonics.U2 ? `U2[${ fundHarmonics?.harmonics?.U2_harmonic_fund?.unit ?? "V" }]` : null,
						harmonics.U3 ? `U3[${ fundHarmonics?.harmonics?.U3_harmonic_fund?.unit ?? "V" }]` : null,
						harmonics.I1 ? `I1[${ fundHarmonics?.harmonics?.I1_harmonic_fund?.unit ?? "A" }]` : null,
						harmonics.I2 ? `I2[${ fundHarmonics?.harmonics?.I2_harmonic_fund?.unit ?? "A" }]` : null,
						harmonics.I3 ? `I3[${ fundHarmonics?.harmonics?.I3_harmonic_fund?.unit ?? "A" }]` : null,
					].filter(isNotNull),
					...harmonicFundData
						.map(dataItem =>
							[
								`"${ dataItem.index.toFixed().replace(".", ",") }"`,
								harmonics.U1 ? `"${ (dataItem.U1 ?? 0).toFixed(6).replace(".", ",") }"` : null,
								harmonics.U2 ? `"${ (dataItem.U2 ?? 0).toFixed(6).replace(".", ",") }"` : null,
								harmonics.U3 ? `"${ (dataItem.U3 ?? 0).toFixed(6).replace(".", ",") }"` : null,
								harmonics.I1 ? `"${ (dataItem.I1 ?? 0).toFixed(6).replace(".", ",") }"` : null,
								harmonics.I2 ? `"${ (dataItem.I2 ?? 0).toFixed(6).replace(".", ",") }"` : null,
								harmonics.I3 ? `"${ (dataItem.I3 ?? 0).toFixed(6).replace(".", ",") }"` : null,
							].filter(isNotNull),
						),
				];
				break;
			case HarmonicVariant.RMS:
				const harmonicRmsData = _getHarmonicRmsData(range, false, true);
				rows = [
					[
						t("TABLE.index"),
						harmonics.U1 ? `U1[${ rmsHarmonics?.harmonics?.U1_harmonic_rms?.unit ?? "V" }]` : null,
						harmonics.U2 ? `U2[${ rmsHarmonics?.harmonics?.U2_harmonic_rms?.unit ?? "V" }]` : null,
						harmonics.U3 ? `U3[${ rmsHarmonics?.harmonics?.U3_harmonic_rms?.unit ?? "V" }]` : null,
						harmonics.I1 ? `I1[${ rmsHarmonics?.harmonics?.I1_harmonic_rms?.unit ?? "A" }]` : null,
						harmonics.I2 ? `I2[${ rmsHarmonics?.harmonics?.I2_harmonic_rms?.unit ?? "A" }]` : null,
						harmonics.I3 ? `I3[${ rmsHarmonics?.harmonics?.I3_harmonic_rms?.unit ?? "A" }]` : null,
					].filter(isNotNull),
					...harmonicRmsData
						.map(dataItem =>
							[
								`"${ dataItem.index.toFixed().replace(".", ",") }"`,
								harmonics.U1 ? `"${ (dataItem.U1 ?? 0).toFixed(6).replace(".", ",") }"` : null,
								harmonics.U2 ? `"${ (dataItem.U2 ?? 0).toFixed(6).replace(".", ",") }"` : null,
								harmonics.U3 ? `"${ (dataItem.U3 ?? 0).toFixed(6).replace(".", ",") }"` : null,
								harmonics.I1 ? `"${ (dataItem.I1 ?? 0).toFixed(6).replace(".", ",") }"` : null,
								harmonics.I2 ? `"${ (dataItem.I2 ?? 0).toFixed(6).replace(".", ",") }"` : null,
								harmonics.I3 ? `"${ (dataItem.I3 ?? 0).toFixed(6).replace(".", ",") }"` : null,
							].filter(isNotNull),
						),
				];
				break;
			case HarmonicVariant.POWERS:
				const harmonicPowerData = _getHarmonicPowerData(range, false, true);
				rows = [
					[
						t("TABLE.index"),
						harmonicPowers.U1_I1_active ? `PL1[${ powerHarmonics?.harmonics?.U1_I1_harmonic_active_power?.unit ?? "W" }]` : null,
						harmonicPowers.U2_I2_active ? `PL2[${ powerHarmonics?.harmonics?.U2_I2_harmonic_active_power?.unit ?? "W" }]` : null,
						harmonicPowers.U3_I3_active ? `PL3[${ powerHarmonics?.harmonics?.U3_I3_harmonic_active_power?.unit ?? "W" }]` : null,
						harmonicPowers.U1_I1_reactive ? `QL1[${ powerHarmonics?.harmonics?.U1_I1_harmonic_reactive_power?.unit ?? "var" }]` : null,
						harmonicPowers.U2_I2_reactive ? `QL2[${ powerHarmonics?.harmonics?.U2_I2_harmonic_reactive_power?.unit ?? "var" }]` : null,
						harmonicPowers.U3_I3_reactive ? `QL3[${ powerHarmonics?.harmonics?.U3_I3_harmonic_reactive_power?.unit ?? "var" }]` : null,
					].filter(isNotNull),
					...harmonicPowerData
						.map(dataItem =>
							[
								`"${ dataItem.index.toFixed().replace(".", ",") }"`,
								harmonicPowers.U1_I1_active ? `"${ (dataItem.U1_I1_active ?? 0).toFixed(6).replace(".", ",") }"` : null,
								harmonicPowers.U2_I2_active ? `"${ (dataItem.U2_I2_active ?? 0).toFixed(6).replace(".", ",") }"` : null,
								harmonicPowers.U3_I3_active ? `"${ (dataItem.U3_I3_active ?? 0).toFixed(6).replace(".", ",") }"` : null,
								harmonicPowers.U1_I1_reactive ? `"${ (dataItem.U1_I1_reactive ?? 0).toFixed(6).replace(".", ",") }"` : null,
								harmonicPowers.U2_I2_reactive ? `"${ (dataItem.U2_I2_reactive ?? 0).toFixed(6).replace(".", ",") }"` : null,
								harmonicPowers.U3_I3_reactive ? `"${ (dataItem.U3_I3_reactive ?? 0).toFixed(6).replace(".", ",") }"` : null,
							].filter(isNotNull),
						),
				];
				break;
			default:
				return;
		}

		let csvContent = "data:text/csv;charset=utf-8,"
			+ rows.map(e => e.join(",")).join("\n");

		const encodedUri = encodeURI(csvContent);
		let link = document.createElement("a");

		link.setAttribute("href", encodedUri);
		link.setAttribute("download", `harmonic_${ harmonicVariantDictionary()[ activeTab ] }_${ moment().format("YYYY-MM-DDTHH:mm:ss.SSS") }.csv`);

		document.body.appendChild(link);

		link.click();

		document.body.removeChild(link);
	};

	return (
		<div className="flex flex-col gap-4">
			<Button.Group>
				{
					Object.values(LayoutVariant).map(layoutVariant =>
						<Button
							key={ layoutVariant }
							color={ variant === layoutVariant ? "primary" : "gray" }
							onClick={ () => setVariant(layoutVariant) }
							pill
							size="sm"
						>
							{ layoutVariantDictionary()[ layoutVariant ] }
						</Button>,
					)
				}
			</Button.Group>
			<div className="grid grid-cols-3 gap-4">
				<div
					className={
						classNames(
							{ "col-span-2": variant === LayoutVariant.ALL },
							{ "col-span-3": variant === LayoutVariant.CHART },
						)
					}
				>
					{
						variant !== LayoutVariant.TABLE &&
                        <Card className="[&>div]:p-0 [&>div]:gap-0">
                            <div className="p-3 sm:p-6 flex justify-between items-center gap-2 pb-4 border-b border-gray-200">
                                <h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
									{ t("SIDEBAR.harmonics") }
                                </h5>
                                <Dropdown
                                    size="sm"
                                    color="transparent"
                                    label={
										<>
											<MdOutlineFileUpload className="mr-2 h-4 w-4 text-gray-600 dark:text-dark-textGray"/>
											{ t("UTILS.export") }
										</>
									}
                                >
                                    <DropdownItem
                                        onClick={ () => {
											const name = `PQM750_harmonics_${ harmonicVariantDictionary()[ activeTab ].toLowerCase() }_from${ displayedRange[ 0 ] }to${ displayedRange[ 1 ] }_${ moment(baseHarmonics?.harmonics.timestamp).format("MM-DD-HH-mm-ss") }`;
											saveStockchart(name, cardRef.current);
										} }
                                    >
										{ t("UTILS.png") }
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={ _exportToCsv }
                                    >
										{ t("UTILS.csv") }
                                    </DropdownItem>
                                </Dropdown>
                            </div>
                            <Tabs
                                style="underline"
                                onActiveTabChange={ setActiveTab }
                            >
                                <Tabs.Item active title={ t("HARMONICS.basic") }>

                                </Tabs.Item>
                                <Tabs.Item title={ t("HARMONICS.% fund") }>

                                </Tabs.Item>
                                <Tabs.Item title={ t("HARMONICS.% rms") }>

                                </Tabs.Item>
                                <Tabs.Item title={ t("HARMONICS.powers") }>

                                </Tabs.Item>
                            </Tabs>
                            <div className="p-3 sm:p-6 flex flex-col gap-4">
								{
									(activeTab === HarmonicVariant.BASE && isHarmonicConfigSet) &&
                                    <HarmonicFilters
                                        chartConfigState={ [ chartConfig, setChartConfig ] }
                                        harmonicsState={ [ harmonics, setHarmonics ] }
                                        baseConfig={ {
											U1: isU1Harmonic,
											U2: isU2Harmonic,
											U3: isU3Harmonic,
											I1: isI1Harmonic,
											I2: isI2Harmonic,
											I3: isI3Harmonic,
										} }
                                    />
								}
								{
									(activeTab === HarmonicVariant.FUND && isFundHarmonicConfigSet) &&
                                    <HarmonicFilters
                                        chartConfigState={ [ chartConfig, setChartConfig ] }
                                        harmonicsState={ [ fundHarmonicsConfig, setFundHarmonics ] }
                                        baseConfig={ {
											U1: isU1FundHarmonic,
											U2: isU2FundHarmonic,
											U3: isU3FundHarmonic,
											I1: isI1FundHarmonic,
											I2: isI2FundHarmonic,
											I3: isI3FundHarmonic,
										} }
                                    />
								}
								{
									(activeTab === HarmonicVariant.RMS && isRmsHarmonicConfigSet) &&
                                    <HarmonicFilters
                                        chartConfigState={ [ chartConfig, setChartConfig ] }
                                        harmonicsState={ [ rmsHarmonicsConfig, setRmsHarmonics ] }
                                        baseConfig={ {
											U1: isU1RmsHarmonic,
											U2: isU2RmsHarmonic,
											U3: isU3RmsHarmonic,
											I1: isI1RmsHarmonic,
											I2: isI2RmsHarmonic,
											I3: isI3RmsHarmonic,
										} }
                                    />
								}
								{
									(activeTab === HarmonicVariant.POWERS && isPowerHarmonicConfigSet) &&
                                    <HarmonicPowerFilters
                                        chartConfigState={ [ chartConfig, setChartConfig ] }
                                        harmonicsPowerState={ [ harmonicPowers, setHarmonicPowers ] }
                                        baseConfig={ {
											L1P: isU1ActiveHarmonic,
											L2P: isU2ActiveHarmonic,
											L3P: isU3ActiveHarmonic,
											L1Q: isU1ReactiveHarmonic,
											L2Q: isU2ReactiveHarmonic,
											L3Q: isU3ReactiveHarmonic,
										} }
                                    />
								}
                                <div ref={ cardRef } style={ { height: CHART_CARD_HEIGHT } }>
									{
										activeTab === HarmonicVariant.BASE &&
                                        <HarmonicChart
                                            key={ cardWidth }
                                            seriesName="harmonics"
                                            width={ cardWidth }
                                            height={ CHART_CARD_HEIGHT }
                                            data={ _getHarmonicData(displayedRange, chartConfig.showOdd, chartConfig.withFirst) }
                                            config={ harmonics }
                                            units={ {
												U: baseHarmonics?.harmonics?.U1_harmonic?.unit ?? "V",
												I: baseHarmonics?.harmonics?.I1_harmonic?.unit ?? "A",
											} }
                                            setDisplayedRange={ setDisplayedRange }
                                            getTooltipLabels={
												(config, currentItem) => [
													(config.U1 && isNotNull(currentItem.U1)) ? {
														label: "U1",
														value: formatValueToFullDeciSON(currentItem.U1, baseHarmonics?.harmonics?.U1_harmonic?.unit ?? "V", MeasurementCategory.VOLTAGES) ?? "",
													} : null,
													(config.U2 && isNotNull(currentItem.U2)) ? {
														label: "U2",
														value: formatValueToFullDeciSON(currentItem.U2, baseHarmonics?.harmonics?.U2_harmonic?.unit ?? "V", MeasurementCategory.VOLTAGES) ?? "",
													} : null,
													(config.U3 && isNotNull(currentItem.U3)) ? {
														label: "U3",
														value: formatValueToFullDeciSON(currentItem.U3, baseHarmonics?.harmonics?.U3_harmonic?.unit ?? "V", MeasurementCategory.VOLTAGES) ?? "",
													} : null,
													(config.I1 && isNotNull(currentItem.I1)) ? {
														label: "I1",
														value: formatValueToFullDeciSON(currentItem.I1, baseHarmonics?.harmonics?.I1_harmonic?.unit ?? "A", MeasurementCategory.CURRENTS) ?? "",
													} : null,
													(config.I2 && isNotNull(currentItem.I2)) ? {
														label: "I2",
														value: formatValueToFullDeciSON(currentItem.I2, baseHarmonics?.harmonics?.I2_harmonic?.unit ?? "A", MeasurementCategory.CURRENTS) ?? "",
													} : null,
													(config.I3 && isNotNull(currentItem.I3)) ? {
														label: "I3",
														value: formatValueToFullDeciSON(currentItem.I3, baseHarmonics?.harmonics?.I3_harmonic?.unit ?? "A", MeasurementCategory.CURRENTS) ?? "",
													} : null,
												].filter(isNotNull)
											}
                                        />
									}
									{
										activeTab === HarmonicVariant.FUND &&
                                        <HarmonicChart
                                            key={ cardWidth }
                                            seriesName="harmonicsFund"
                                            width={ cardWidth }
                                            height={ CHART_CARD_HEIGHT }
                                            data={ _getHarmonicFundData(displayedRange, chartConfig.showOdd, chartConfig.withFirst) }
                                            config={ harmonics }
                                            units={ {
												U: fundHarmonics?.harmonics?.U1_harmonic_fund?.unit ?? "%",
												I: fundHarmonics?.harmonics?.I1_harmonic_fund?.unit ?? "%",
											} }
                                            setDisplayedRange={ setDisplayedRange }
                                            getTooltipLabels={
												(config, currentItem) => [
													(config.U1 && isNotNull(currentItem.U1)) ? {
														label: "U1",
														value: formatValueToFullDeciSON(currentItem.U1, fundHarmonics?.harmonics?.U1_harmonic_fund?.unit ?? "%", MeasurementCategory.PERCENT) ?? "",
													} : null,
													(config.U2 && isNotNull(currentItem.U2)) ? {
														label: "U2",
														value: formatValueToFullDeciSON(currentItem.U2, fundHarmonics?.harmonics?.U2_harmonic_fund?.unit ?? "%", MeasurementCategory.PERCENT) ?? "",
													} : null,
													(config.U3 && isNotNull(currentItem.U3)) ? {
														label: "U3",
														value: formatValueToFullDeciSON(currentItem.U3, fundHarmonics?.harmonics?.U3_harmonic_fund?.unit ?? "%", MeasurementCategory.PERCENT) ?? "",
													} : null,
													(config.I1 && isNotNull(currentItem.I1)) ? {
														label: "I1",
														value: formatValueToFullDeciSON(currentItem.I1, fundHarmonics?.harmonics?.I1_harmonic_fund?.unit ?? "%", MeasurementCategory.PERCENT) ?? "",
													} : null,
													(config.I2 && isNotNull(currentItem.I2)) ? {
														label: "I2",
														value: formatValueToFullDeciSON(currentItem.I2, fundHarmonics?.harmonics?.I2_harmonic_fund?.unit ?? "%", MeasurementCategory.PERCENT) ?? "",
													} : null,
													(config.I3 && isNotNull(currentItem.I3)) ? {
														label: "I3",
														value: formatValueToFullDeciSON(currentItem.I3, fundHarmonics?.harmonics?.I3_harmonic_fund?.unit ?? "%", MeasurementCategory.PERCENT) ?? "",
													} : null,
												].filter(isNotNull)
											}
                                        />
									}
									{
										activeTab === HarmonicVariant.RMS &&
                                        <HarmonicChart
                                            key={ cardWidth }
                                            seriesName="harmonicsRms"
                                            width={ cardWidth }
                                            height={ CHART_CARD_HEIGHT }
                                            data={ _getHarmonicRmsData(displayedRange, chartConfig.showOdd, chartConfig.withFirst) }
                                            config={ harmonics }
                                            units={ {
												U: rmsHarmonics?.harmonics?.U1_harmonic_rms?.unit ?? "%",
												I: rmsHarmonics?.harmonics?.I1_harmonic_rms?.unit ?? "%",
											} }
                                            setDisplayedRange={ setDisplayedRange }
                                            getTooltipLabels={
												(config, currentItem) => [
													(config.U1 && isNotNull(currentItem.U1)) ? {
														label: "U1",
														value: formatValueToFullDeciSON(currentItem.U1, rmsHarmonics?.harmonics?.U1_harmonic_rms?.unit ?? "%", MeasurementCategory.PERCENT) ?? "",
													} : null,
													(config.U2 && isNotNull(currentItem.U2)) ? {
														label: "U2",
														value: formatValueToFullDeciSON(currentItem.U2, rmsHarmonics?.harmonics?.U2_harmonic_rms?.unit ?? "%", MeasurementCategory.PERCENT) ?? "",
													} : null,
													(config.U3 && isNotNull(currentItem.U3)) ? {
														label: "U3",
														value: formatValueToFullDeciSON(currentItem.U3, rmsHarmonics?.harmonics?.U3_harmonic_rms?.unit ?? "%", MeasurementCategory.PERCENT) ?? "",
													} : null,
													(config.I1 && isNotNull(currentItem.I1)) ? {
														label: "I1",
														value: formatValueToFullDeciSON(currentItem.I1, rmsHarmonics?.harmonics?.I1_harmonic_rms?.unit ?? "%", MeasurementCategory.PERCENT) ?? "",
													} : null,
													(config.I2 && isNotNull(currentItem.I2)) ? {
														label: "I2",
														value: formatValueToFullDeciSON(currentItem.I2, rmsHarmonics?.harmonics?.I2_harmonic_rms?.unit ?? "%", MeasurementCategory.PERCENT) ?? "",
													} : null,
													(config.I3 && isNotNull(currentItem.I3)) ? {
														label: "I3",
														value: formatValueToFullDeciSON(currentItem.I3, rmsHarmonics?.harmonics?.I3_harmonic_rms?.unit ?? "%", MeasurementCategory.PERCENT) ?? "",
													} : null,
												].filter(isNotNull)
											}
                                        />
									}
									{
										activeTab === HarmonicVariant.POWERS &&
                                        <HarmonicPowerChart
                                            key={ cardWidth }
                                            seriesName="harmonicsPower"
                                            width={ cardWidth }
                                            height={ CHART_CARD_HEIGHT }
                                            data={ _getHarmonicPowerData(displayedRange, chartConfig.showOdd, chartConfig.withFirst) }
                                            config={ harmonicPowers }
                                            units={ {
												P: powerHarmonics?.harmonics?.U1_I1_harmonic_active_power?.unit ?? "W",
												Q: powerHarmonics?.harmonics?.U1_I1_harmonic_reactive_power?.unit ?? "var",
											} }
                                            setDisplayedRange={ setDisplayedRange }
                                            getTooltipLabels={
												(config, currentItem) => [
													(config.U1_I1_active && isNotNull(currentItem.U1_I1_active)) ? {
														label: "PL1",
														value: formatValueToFullDeciSON(currentItem.U1_I1_active, powerHarmonics?.harmonics?.U1_I1_harmonic_active_power?.unit ?? "W", MeasurementCategory.ACTIVE_POWERS) ?? "",
													} : null,
													(config.U2_I2_active && isNotNull(currentItem.U2_I2_active)) ? {
														label: "PL2",
														value: formatValueToFullDeciSON(currentItem.U2_I2_active, powerHarmonics?.harmonics?.U2_I2_harmonic_active_power?.unit ?? "W", MeasurementCategory.ACTIVE_POWERS) ?? "",
													} : null,
													(config.U3_I3_active && isNotNull(currentItem.U3_I3_active)) ? {
														label: "PL3",
														value: formatValueToFullDeciSON(currentItem.U3_I3_active, powerHarmonics?.harmonics?.U3_I3_harmonic_active_power?.unit ?? "W", MeasurementCategory.ACTIVE_POWERS) ?? "",
													} : null,
													(config.U1_I1_reactive && isNotNull(currentItem.U1_I1_reactive)) ? {
														label: "QL1",
														value: formatValueToFullDeciSON(currentItem.U1_I1_reactive, powerHarmonics?.harmonics?.U1_I1_harmonic_reactive_power?.unit ?? "var", MeasurementCategory.REACTIVE_POWERS) ?? "",
													} : null,
													(config.U2_I2_reactive && isNotNull(currentItem.U2_I2_reactive)) ? {
														label: "QL2",
														value: formatValueToFullDeciSON(currentItem.U2_I2_reactive, powerHarmonics?.harmonics?.U2_I2_harmonic_reactive_power?.unit ?? "var", MeasurementCategory.REACTIVE_POWERS) ?? "",
													} : null,
													(config.U3_I3_reactive && isNotNull(currentItem.U3_I3_reactive)) ? {
														label: "QL3",
														value: formatValueToFullDeciSON(currentItem.U3_I3_reactive, powerHarmonics?.harmonics?.U3_I3_harmonic_reactive_power?.unit ?? "var", MeasurementCategory.REACTIVE_POWERS) ?? "",
													} : null,
												].filter(isNotNull)
											}
                                        />
									}
                                </div>
                            </div>
                        </Card>
					}
				</div>
				<div
					className={
						classNames(
							{ "col-span-3": variant === LayoutVariant.TABLE },
							{ "hidden": variant === LayoutVariant.CHART },
						)
					}
				>
					<Table
						className="[&>div]:border-b-0"
						title={ t("TABLE.actual values") }
						options={ {
							filter: false,
							search: false,
							pagination: false,
							setRowProps: (_, dataIndex) => {
								if (isNull(baseHarmonics)) return {};
								return {
									className: measurementCategoryRowClassNameDictionary()[ MeasurementCategory.HARMONICS ],
								};
							},
						} }
						isLoading={ isNull(baseHarmonics) }
						cellPadding={ 0 }
						columns={ [
							{
								name: "Parameter",
								label: t("TABLE.parameter").toUpperCase(),
								options: {
									customBodyRender: (parameter: TableRecord) =>
										<div>
											<div className={ `!p-2 ${ measurementCategoryCellClassNameDictionary()[ MeasurementCategory.HARMONICS ] }` }>
												{ parameter.name }
											</div>
										</div>, sort: false,
									filter: false,
								},
							}, {
								name: "Value",
								label: t("TABLE.value").toUpperCase(),
								options: {
									customBodyRender: (parameter: TableRecord) => (
										<span>
											{ parameter.value !== null && parameter.value !== undefined ? parameter.value : "---" }
										</span>
									),
									sort: false,
									filter: false,
								},
							}, {
								name: "Unit",
								label: t("TABLE.unit").toUpperCase(),
								options: {
									customBodyRender: (parameter: TableRecord) => parameter.unit ?? "---",
									sort: false,
									filter: false,
								},
							},
						] }
						data={
							isNotNull(baseHarmonics) ? [
								isNotNull(baseHarmonics?.harmonics?.U1n_thdf) ? {
									name: <span>U1N THD<sub>F</sub></span>,
									value: formatValueToDeciSON(baseHarmonics?.harmonics?.U1n_thdf?.value, MeasurementCategory.HARMONICS),
									unit: getPrefixToUnit(baseHarmonics.harmonics.U1n_thdf?.value, baseHarmonics?.harmonics?.U1n_thdf?.unit, MeasurementCategory.HARMONICS),
								} : undefined,
								isNotNull(baseHarmonics?.harmonics?.U2n_thdf) ? {
									name: <span>U2N THD<sub>F</sub></span>,
									value: formatValueToDeciSON(baseHarmonics?.harmonics?.U2n_thdf?.value, MeasurementCategory.HARMONICS),
									unit: getPrefixToUnit(baseHarmonics.harmonics.U2n_thdf?.value, baseHarmonics?.harmonics?.U2n_thdf?.unit, MeasurementCategory.HARMONICS),
								} : undefined,
								isNotNull(baseHarmonics?.harmonics?.U3n_thdf) ? {
									name: <span>U3N THD<sub>F</sub></span>,
									value: formatValueToDeciSON(baseHarmonics?.harmonics?.U3n_thdf?.value, MeasurementCategory.HARMONICS),
									unit: getPrefixToUnit(baseHarmonics.harmonics.U3n_thdf?.value, baseHarmonics?.harmonics?.U3n_thdf?.unit, MeasurementCategory.HARMONICS),
								} : undefined,
								isNotNull(baseHarmonics?.harmonics?.I1_thdf) ? {
									name: <span>I1 THD<sub>F</sub></span>,
									value: formatValueToDeciSON(baseHarmonics?.harmonics?.I1_thdf?.value, MeasurementCategory.HARMONICS),
									unit: getPrefixToUnit(baseHarmonics.harmonics.I1_thdf?.value, baseHarmonics?.harmonics?.I1_thdf?.unit, MeasurementCategory.HARMONICS),
								} : undefined,
								isNotNull(baseHarmonics?.harmonics?.I2_thdf) ? {
									name: <span>I2 THD<sub>F</sub></span>,
									value: formatValueToDeciSON(baseHarmonics?.harmonics?.I2_thdf?.value, MeasurementCategory.HARMONICS),
									unit: getPrefixToUnit(baseHarmonics.harmonics.I2_thdf?.value, baseHarmonics?.harmonics?.I2_thdf?.unit, MeasurementCategory.HARMONICS),
								} : undefined,
								isNotNull(baseHarmonics?.harmonics?.I3_thdf) ? {
									name: <span>I3 THD<sub>F</sub></span>,
									value: formatValueToDeciSON(baseHarmonics?.harmonics?.I3_thdf?.value, MeasurementCategory.HARMONICS),
									unit: getPrefixToUnit(baseHarmonics.harmonics.I3_thdf?.value, baseHarmonics?.harmonics?.I3_thdf?.unit, MeasurementCategory.HARMONICS),
								} : undefined,
								isNotNull(baseHarmonics?.harmonics?.In_thdf) ? {
									name: <span>In THD<sub>F</sub></span>,
									value: formatValueToDeciSON(baseHarmonics?.harmonics?.In_thdf?.value, MeasurementCategory.HARMONICS),
									unit: getPrefixToUnit(baseHarmonics.harmonics.In_thdf?.value, baseHarmonics?.harmonics?.In_thdf?.unit, MeasurementCategory.HARMONICS),
								} : undefined,
								/*harmonicConfiguration.Ie_thdf ? {
									name: "Ie THD F",
									value: baseHarmonics?.harmonics?.Ie_thdf?.value,
									unit: baseHarmonics?.harmonics?.Ie_thdf?.unit,
								} : undefined,*/
							].filter(isNotNull) : []
						}
					/>
				</div>
			</div>
		</div>
	);
}

type HarmonicFiltersProps = {
	chartConfigState: [ ChartBaseConfigState, Dispatch<SetStateAction<ChartBaseConfigState>> ]
	harmonicsState: [ HarmonicConfigState, Dispatch<SetStateAction<HarmonicConfigState>> ]
	baseConfig: {
		U1: boolean
		U2: boolean
		U3: boolean
		I1: boolean
		I2: boolean
		I3: boolean
	}
}

function HarmonicFilters(props: HarmonicFiltersProps) {

	const {
		chartConfigState,
		harmonicsState,
		baseConfig,
	} = props;

	const { t } = useTranslation();
	const [ chartConfig, setChartConfig ] = chartConfigState;
	const [ harmonics, setHarmonics ] = harmonicsState;

	return (
		<div className="flex items-center justify-between flex-col gap-2.5">
			<div className="flex items-center justify-center gap-2.5 flex-col">
				<div className="flex gap-2.5 items-center">
					<span className="text-sm text-gray-500 font-medium">{ t("HARMONICS.configuration") }: </span>
					<ChartFilter
						text={ t("HARMONICS.with fund") }
						color={ ChartFilterColor.default }
						active={ chartConfig.withFirst }
						onClick={ () =>
							setChartConfig(prevState => ({
								...prevState,
								withFirst: !prevState.withFirst,
							}))
						}
					/>
					<ChartFilter
						text={ t("HARMONICS.only odd") }
						color={ ChartFilterColor.default }
						active={ chartConfig.showOdd }
						onClick={ () =>
							setChartConfig(prevState => ({
								...prevState,
								showOdd: !prevState.showOdd,
							}))
						}
					/>
				</div>
				<div className="flex gap-x-8 gap-y-2.5 flex-wrap justify-center items-center">
					{
						(
							baseConfig.U1 ||
							baseConfig.U2 ||
							baseConfig.U3
						) &&
                        <div className="flex gap-2.5 items-center">
                            <span className="text-sm text-gray-500 font-medium">{ t("UTILS.voltages") }: </span>
							{
								baseConfig.U1 &&
                                <ChartFilter
                                    text="U1"
                                    color={ ChartFilterColor.U1 }
                                    active={ harmonics.U1 }
                                    onClick={ () =>
										setHarmonics(prevState => ({
											...prevState,
											[ HarmonicLine.U1 ]: !prevState.U1,
										}))
									}
                                />
							}
							{
								baseConfig.U2 &&
                                <ChartFilter
                                    text="U2"
                                    color={ ChartFilterColor.U2 }
                                    active={ harmonics.U2 }
                                    onClick={ () =>
										setHarmonics(prevState => ({
											...prevState,
											[ HarmonicLine.U2 ]: !prevState.U2,
										}))
									}
                                />
							}
							{
								baseConfig.U3 &&
                                <ChartFilter
                                    text="U3"
                                    color={ ChartFilterColor.U3 }
                                    active={ harmonics.U3 }
                                    onClick={ () =>
										setHarmonics(prevState => ({
											...prevState,
											[ HarmonicLine.U3 ]: !prevState.U3,
										}))
									}
                                />
							}
                        </div>
					}
					{
						(
							baseConfig.I1 ||
							baseConfig.I2 ||
							baseConfig.I3
						) &&
                        <div className="flex gap-2.5 items-center">
                            <span className="text-sm text-gray-500 font-medium">{ t("UTILS.currents") }: </span>
							{
								baseConfig.I1 &&
                                <ChartFilter
                                    text="I1"
                                    color={ ChartFilterColor.I1 }
                                    active={ harmonics.I1 }
                                    onClick={ () =>
										setHarmonics(prevState => ({
											...prevState,
											[ HarmonicLine.I1 ]: !prevState.I1,
										}))
									}
                                />
							}
							{
								baseConfig.I2 &&
                                <ChartFilter
                                    text="I2"
                                    color={ ChartFilterColor.I2 }
                                    active={ harmonics.I2 }
                                    onClick={ () =>
										setHarmonics(prevState => ({
											...prevState,
											[ HarmonicLine.I2 ]: !prevState.I2,
										}))
									}
                                />
							}
							{
								baseConfig.I3 &&
                                <ChartFilter
                                    text="I3"
                                    color={ ChartFilterColor.I3 }
                                    active={ harmonics.I3 }
                                    onClick={ () =>
										setHarmonics(prevState => ({
											...prevState,
											[ HarmonicLine.I3 ]: !prevState.I3,
										}))
									}
                                />
							}
                        </div>
					}
				</div>
			</div>
		</div>
	);
}

type HarmonicPowerFiltersProps = {
	chartConfigState: [ ChartBaseConfigState, Dispatch<SetStateAction<ChartBaseConfigState>> ]
	harmonicsPowerState: [ HarmonicPowerConfigState, Dispatch<SetStateAction<HarmonicPowerConfigState>> ]
	baseConfig: {
		L1P: boolean
		L2P: boolean
		L3P: boolean
		L1Q: boolean
		L2Q: boolean
		L3Q: boolean
	}
}

function HarmonicPowerFilters(props: HarmonicPowerFiltersProps) {

	const {
		chartConfigState,
		harmonicsPowerState,
		baseConfig,
	} = props;

	const { t } = useTranslation();
	const [ chartConfig, setChartConfig ] = chartConfigState;
	const [ harmonicPowers, setHarmonicPowers ] = harmonicsPowerState;

	return (
		<div className="flex items-center justify-between flex-col gap-2.5">
			<div className="flex items-center justify-center gap-2.5 flex-col">
				<div className="flex gap-2.5 items-center">
					<span className="text-sm text-gray-500 font-medium">{ t("HARMONICS.configuration") }: </span>
					<ChartFilter
						text={ t("HARMONICS.with fund") }
						color={ ChartFilterColor.default }
						active={ chartConfig.withFirst }
						onClick={ () =>
							setChartConfig(prevState => ({
								...prevState,
								withFirst: !prevState.withFirst,
							}))
						}
					/>
					<ChartFilter
						text={ t("HARMONICS.only odd") }
						color={ ChartFilterColor.default }
						active={ chartConfig.showOdd }
						onClick={ () =>
							setChartConfig(prevState => ({
								...prevState,
								showOdd: !prevState.showOdd,
							}))
						}
					/>
				</div>
				<div className="flex gap-x-8 gap-y-2.5 flex-wrap justify-center items-center">
					{
						(
							baseConfig.L1P ||
							baseConfig.L2P ||
							baseConfig.L3P
						) &&
                        <div className="flex gap-2.5 items-center">
                            <span className="text-sm text-gray-500 font-medium">{ t("UTILS.active powers") }: </span>
							{
								baseConfig.L1P &&
                                <ChartFilter
                                    text="PL1"
                                    color={ ChartFilterColor.L1P }
                                    active={ harmonicPowers.U1_I1_active }
                                    onClick={ () =>
										setHarmonicPowers(prevState => ({
											...prevState,
											[ HarmonicPowerLine.U1_I1_active ]: !prevState.U1_I1_active,
										}))
									}
                                />
							}
							{
								baseConfig.L2P &&
                                <ChartFilter
                                    text="PL2"
                                    color={ ChartFilterColor.L2P }
                                    active={ harmonicPowers.U2_I2_active }
                                    onClick={ () =>
										setHarmonicPowers(prevState => ({
											...prevState,
											[ HarmonicPowerLine.U2_I2_active ]: !prevState.U2_I2_active,
										}))
									}
                                />
							}
							{
								baseConfig.L3P &&
                                <ChartFilter
                                    text="PL3"
                                    color={ ChartFilterColor.L3P }
                                    active={ harmonicPowers.U3_I3_active }
                                    onClick={ () =>
										setHarmonicPowers(prevState => ({
											...prevState,
											[ HarmonicPowerLine.U3_I3_active ]: !prevState.U3_I3_active,
										}))
									}
                                />
							}
                        </div>
					}
					{
						(
							baseConfig.L1Q ||
							baseConfig.L2Q ||
							baseConfig.L3Q
						) &&
                        <div className="flex gap-2.5 items-center">
                            <span className="text-sm text-gray-500 font-medium">{ t("UTILS.reactive powers") }: </span>
							{
								baseConfig.L1Q &&
                                <ChartFilter
                                    text="QL1"
                                    color={ ChartFilterColor.L1Q }
                                    active={ harmonicPowers.U1_I1_reactive }
                                    onClick={ () =>
										setHarmonicPowers(prevState => ({
											...prevState,
											[ HarmonicPowerLine.U1_I1_reactive ]: !prevState.U1_I1_reactive,
										}))
									}
                                />
							}
							{
								baseConfig.L2Q &&
                                <ChartFilter
                                    text="QL2"
                                    color={ ChartFilterColor.L2Q }
                                    active={ harmonicPowers.U2_I2_reactive }
                                    onClick={ () =>
										setHarmonicPowers(prevState => ({
											...prevState,
											[ HarmonicPowerLine.U2_I2_reactive ]: !prevState.U2_I2_reactive,
										}))
									}
                                />
							}
							{
								baseConfig.L3Q &&
                                <ChartFilter
                                    text="QL3"
                                    color={ ChartFilterColor.L3Q }
                                    active={ harmonicPowers.U3_I3_reactive }
                                    onClick={ () =>
										setHarmonicPowers(prevState => ({
											...prevState,
											[ HarmonicPowerLine.U3_I3_reactive ]: !prevState.U3_I3_reactive,
										}))
									}
                                />
							}
                        </div>
					}
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	bodySize: state.ui.layout.bodySize,
	isSidebarOpen: state.ui.layout.isSidebarOpen,
});

export default connect(mapStateToProps)(HarmonicContainer);
