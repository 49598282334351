import { OnOff, SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import { createContext, useEffect, useState } from "react";
import VoltageCard from "src/app/components/MeasurementsUser/Voltage/VoltageCard.component";
import { Nullable } from "src/app/types/util.types";
import CurrentCard from "src/app/components/MeasurementsUser/Current/CurrentCard.component";
import PowerAndEnergyCard from "src/app/components/MeasurementsUser/PowerAndEnergy/PowerAndEnergyCard.component";
import HarmonicsCard from "src/app/components/MeasurementsUser/Harmonics/HarmonicsCard.component";
import { FormValidator } from "src/app/types/ui/form.types";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { createFormField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import { Button, Card, ToggleSwitch } from "flowbite-react";
import classNames from "classnames";
import Select from "src/app/components/Form/Select.component";
import { mapEnumSettingToSelectOptions } from "src/app/utils/helpers";
import { aggregationPeriodUserDictionary } from "src/app/utils/constants/dictionaries";
import { isNull } from "src/app/utils/typeguards";
import EnergyCard from "src/app/components/MeasurementsUser/PowerAndEnergy/EnergyCard.component";
import AdditionalsCard from "src/app/components/MeasurementsUser/Additionals/AdditionalsCard.component";
import { useTranslation } from "react-i18next";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsMeasurementsUser: SettingsMeasurementsUser
	};

enum MeasurementsUserCollapseCard {
	VOLTAGE = "VOLTAGE",
	CURRENT = "CURRENT",
	POWER = "POWER",
	ENERGY = "ENERGY",
	HARMONICS = "HARMONICS",
	ADDITIONAL = "ADDITIONAL"
}

type MeasurementsUserGeneralForm = {
	cfgUserEnable: boolean
	cfgAggregationPeriodUser: number
	cfgUserAggregationWaveformEnable: boolean
}

const validator: FormValidator<MeasurementsUserGeneralForm> = {
	cfgUserEnable: () => null,
	cfgAggregationPeriodUser: () => null,
	cfgUserAggregationWaveformEnable: () => null,
};

type MeasurementsUserContextType = {
	cfgUserEnable: boolean
}

export const MeasurementsUserContext = createContext<MeasurementsUserContextType>({ cfgUserEnable: true });

function MeasurementsUserContent(props: Props) {

	const { t } = useTranslation();

	const {
		settingsMeasurementsUser,
		settingsMeasurementsUser: {
			measurementUser,
		},
		isAdmin,
	} = props;

	const reducerForm = useReducerForm(
		"buffor",
		bufforFormActions,
		() => undefined,
	);

	const _handleSubmit = (values: MeasurementsUserGeneralForm) => {
		reducerForm.handleChange("cfgUserEnable", values.cfgUserEnable);
		reducerForm.handleChange("cfgAggregationPeriodUser", values.cfgAggregationPeriodUser);
		reducerForm.handleChange("cfgUserAggregationWaveformEnable", values.cfgUserAggregationWaveformEnable);
	};

	const _getInitialState = () => {
		const cfgUserEnable = (reducerForm.form.cfgUserEnable.value !== reducerForm.form.cfgUserEnable.initialValue) ? reducerForm.form.cfgUserEnable.value : measurementUser?.cfgUserDataRecEnable?.enum?.find(enumValue => enumValue.value === measurementUser?.cfgUserDataRecEnable?.value)?.text === OnOff.ON;
		return {
			cfgUserEnable: createFormField(cfgUserEnable, { disabled: !isAdmin }),
			cfgAggregationPeriodUser: createFormField(reducerForm.form.cfgAggregationPeriodUser.value !== reducerForm.form.cfgAggregationPeriodUser.initialValue ? reducerForm.form.cfgAggregationPeriodUser.value : measurementUser?.cfgAggregationPeriodUser?.value ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgUserAggregationWaveformEnable: createFormField((reducerForm.form.cfgUserAggregationWaveformEnable.value !== reducerForm.form.cfgUserAggregationWaveformEnable.initialValue) ? reducerForm.form.cfgUserAggregationWaveformEnable.value : measurementUser?.cfgUserWaveformsAndRms12RecEnable?.enum?.find(enumValue => enumValue.value === measurementUser?.cfgUserWaveformsAndRms12RecEnable?.value)?.text === OnOff.ON, { disabled: !isAdmin || !cfgUserEnable }),
		};
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ measurementUser ]);

	useEffect(() => {
		toggleDisable("cfgAggregationPeriodUser", false);
		toggleDisable("cfgUserAggregationWaveformEnable", false);

		handleChange("cfgUserEnable", reducerForm.form.cfgUserEnable.value);
		handleChange("cfgAggregationPeriodUser", reducerForm.form.cfgAggregationPeriodUser.value);
		handleChange("cfgUserAggregationWaveformEnable", reducerForm.form.cfgUserAggregationWaveformEnable.value);

		toggleDisable("cfgAggregationPeriodUser", !reducerForm.form.cfgUserEnable.value || !isAdmin);
		toggleDisable("cfgUserAggregationWaveformEnable", !reducerForm.form.cfgUserEnable.value || !isAdmin);
	}, [
		reducerForm.form.cfgUserEnable.value,
		reducerForm.form.cfgAggregationPeriodUser.value,
		reducerForm.form.cfgUserAggregationWaveformEnable.value,
	]);

	const [ cardExpanded, toggleCardExpanded ] = useState<Nullable<MeasurementsUserCollapseCard>>(MeasurementsUserCollapseCard.VOLTAGE);

	const _handleChangeCollapse = (tab: MeasurementsUserCollapseCard) => {
		if (cardExpanded === tab) {
			toggleCardExpanded(null);
		} else {
			toggleCardExpanded(tab);
		}
	};

	return (
		<div className="flex flex-col gap-4">
			<Card>
				<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
					<ToggleSwitch
						className={
							classNames(
								"[&>div]:w-9 [&>div]:h-5 [&>div]:after:absolute [&>div]:after:top-[2px] [&>div]:after:left-[2px] [&>div]:after:h-4 [&>div]:after:w-4",
								{ "[&>span]:!text-yellow-400": reducerForm.form.cfgUserEnable.value !== reducerForm.form.cfgUserEnable.initialValue },
							)
						}
						disabled={ form.cfgUserEnable.disabled }
						checked={ form.cfgUserEnable.value }
						label={ t("MEASUREMENTS.enable") }
						onChange={ () => {
							handleChange("cfgUserEnable", !form.cfgUserEnable.value);

							toggleDisable("cfgAggregationPeriodUser", form.cfgUserEnable.value);
							toggleDisable("cfgUserAggregationWaveformEnable", form.cfgUserEnable.value);
						} }
					/>
					<Select
						label={ t("MEASUREMENTS.averaging period") }
						options={ mapEnumSettingToSelectOptions(measurementUser?.cfgAggregationPeriodUser, aggregationPeriodUserDictionary) }
						formItem={ form.cfgAggregationPeriodUser }
						onChange={ option => {
							if (isNull(option)) return;

							handleChange("cfgAggregationPeriodUser", option?.value);
							handleBlur("cfgAggregationPeriodUser");
						} }
						isSearchable={ false }
						isClearable={ false }
						hasBeenChanged={ reducerForm.form.cfgAggregationPeriodUser.value !== reducerForm.form.cfgAggregationPeriodUser.initialValue }
					/>
					<ToggleSwitch
						className={
							classNames(
								"[&>div]:w-9 [&>div]:h-5 [&>div]:after:absolute [&>div]:after:top-[2px] [&>div]:after:left-[2px] [&>div]:after:h-4 [&>div]:after:w-4",
								{ "[&>span]:!text-yellow-400": reducerForm.form.cfgUserAggregationWaveformEnable.value !== reducerForm.form.cfgUserAggregationWaveformEnable.initialValue },
							)
						}
						disabled={ form.cfgUserAggregationWaveformEnable.disabled }
						checked={ form.cfgUserAggregationWaveformEnable.value }
						label={ t("MEASUREMENTS.log waveforms and RMS 1/2") }
						onChange={ () => handleChange("cfgUserAggregationWaveformEnable", !form.cfgUserAggregationWaveformEnable.value) }
					/>
					{
						isAdmin &&
                        <div className="flex justify-end items-center gap-2">
                            <Button
                                color="primary"
                                type="submit"
                            >
								{ t("UTILS.save") }
                            </Button>
                        </div>
					}
				</form>
			</Card>
			<MeasurementsUserContext.Provider value={ { cfgUserEnable: form.cfgUserEnable.value } }>
				<VoltageCard
					settingsMeasurementsUser={ settingsMeasurementsUser }
					isExpanded={ cardExpanded === MeasurementsUserCollapseCard.VOLTAGE }
					handleCollapse={ () => _handleChangeCollapse(MeasurementsUserCollapseCard.VOLTAGE) }
				/>
				<CurrentCard
					settingsMeasurementsUser={ settingsMeasurementsUser }
					isExpanded={ cardExpanded === MeasurementsUserCollapseCard.CURRENT }
					handleCollapse={ () => _handleChangeCollapse(MeasurementsUserCollapseCard.CURRENT) }
				/>
				<PowerAndEnergyCard
					settingsMeasurementsUser={ settingsMeasurementsUser }
					isExpanded={ cardExpanded === MeasurementsUserCollapseCard.POWER }
					handleCollapse={ () => _handleChangeCollapse(MeasurementsUserCollapseCard.POWER) }
				/>
				<EnergyCard
					settingsMeasurementsUser={ settingsMeasurementsUser }
					isExpanded={ cardExpanded === MeasurementsUserCollapseCard.ENERGY }
					handleCollapse={ () => _handleChangeCollapse(MeasurementsUserCollapseCard.ENERGY) }
				/>
				<HarmonicsCard
					settingsMeasurementsUser={ settingsMeasurementsUser }
					isExpanded={ cardExpanded === MeasurementsUserCollapseCard.HARMONICS }
					handleCollapse={ () => _handleChangeCollapse(MeasurementsUserCollapseCard.HARMONICS) }
				/>
				<AdditionalsCard
					settingsMeasurementsUser={ settingsMeasurementsUser }
					isExpanded={ cardExpanded === MeasurementsUserCollapseCard.ADDITIONAL }
					handleCollapse={ () => _handleChangeCollapse(MeasurementsUserCollapseCard.ADDITIONAL) }
				/>
			</MeasurementsUserContext.Provider>
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(MeasurementsUserContent);
