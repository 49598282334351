import { Navigate } from "react-router-dom";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { defaultView } from "src/app/utils/constants/constants";
import { isAuthorized } from "src/app/store/features/user/user.selectors";

type Props = ReturnType<typeof mapStateToProps>;

function DefaultRoute(props: Props) {
	const {
		isAuthorized,
	} = props;

	if (isAuthorized) {
		return <Navigate to={ defaultView }/>;
	} else {
		return <Navigate to="/login"/>;
	}
}

const mapStateToProps = (state: RootState) => ({
	isAuthorized: isAuthorized(state),
});

export default connect(mapStateToProps)(DefaultRoute);
