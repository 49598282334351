import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import ForceFreshStrategy from "src/app/hoc/caching/ForceFreshStrategy.hoc";
import { getStandardEventWithWaveform } from "src/app/store/features/dashboard/dashboard.selectors";
import { useParams } from "react-router-dom";
import EventWaveformContainer from "src/app/containers/Event/EventWaveform.container";
import { uiFetchStandardEventWaveform } from "src/app/store/features/ui/dashboard/ui.dashboard.actions";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function StandardEventWaveformView(props: Props) {

	const {
		fetchEventAndWaveform,
		eventWithWaveform,
	} = props;

	const { standardEventId = "" } = useParams();

	return (
		<ForceFreshStrategy
			request={ () => fetchEventAndWaveform({ event_id: +standardEventId }) }
			state={ eventWithWaveform(+standardEventId) }
		>
			{
				({ event, eventWaveform }) =>
					<EventWaveformContainer
						event={ event }
						eventWaveform={ eventWaveform }
					/>
			}
		</ForceFreshStrategy>
	);
}

const mapStateToProps = (state: RootState) => ({
	eventWithWaveform: (eventId: number) => getStandardEventWithWaveform(state, eventId),
});

const mapDispatchToProps = {
	fetchEventAndWaveform: uiFetchStandardEventWaveform,
};

export default connect(mapStateToProps, mapDispatchToProps)(StandardEventWaveformView);
