import React, { Component } from "react";
// @ts-ignore
import { first, hexToRGBA, isDefined, isNotDefined, last } from "react-stockcharts/lib/utils";
// @ts-ignore
import GenericComponent from "react-stockcharts/lib/GenericComponent";
import { isNull } from "src/app/utils/typeguards";
import { sum } from "d3-array";
import { StockChartIndex } from "src/app/types/ui/chart.types";

type Props<T> = {
	firstItem: T
	lastItem: T
	getTooltipContent: (arg: { currentItem: T & StockChartIndex }) => { x: string, y: { label: string, value: string }[] }
};

function ChartHoverTooltip<T>(props: Props<T>) {

	const { firstItem, lastItem, getTooltipContent } = props;

	return (
		<CustomHoverTooltip
			firstItem={ firstItem }
			lastItem={ lastItem }
			tooltipContent={ getTooltipContent }
			tooltipSVG={ tooltipSVG }
			tooltipCanvas={ tooltipCanvas }
			origin={ origin }
			fill="#909BAD"
			bgFill="#909BAD"
			bgOpacity={ 0.3 }
			stroke="#9B9BFF"
			fontFill="#13161D" // dark-mainBg
			opacity={ 0.9 }
			backgroundShapeSVG={ backgroundShapeSVG }
			backgroundShapeCanvas={ backgroundShapeCanvas }
			fontFamily={ "Segoe UI Symbol, Segoe UI Emoji, ui-sans-serif" }
			fontSize={ 14 }
		/>
	);
}

class CustomHoverTooltip extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.renderSVG = this.renderSVG.bind(this);
		this.drawOnCanvas = this.drawOnCanvas.bind(this);
	}

	drawOnCanvas(ctx: any, moreProps: any) {
		const pointer = helper(this.props, moreProps, ctx);
		const { height } = moreProps;

		if (isNotDefined(pointer)) return null;
		drawOnCanvas(ctx, this.props, this.context, pointer, height);
	}

	render() {
		return <GenericComponent
			svgDraw={ this.renderSVG }
			canvasDraw={ this.drawOnCanvas }
			drawOn={ [ "mousemove", "pan" ] }
		/>;
	}

	renderSVG(moreProps: any) {
		const pointer = helper(this.props, moreProps);

		if (isNotDefined(pointer) || isNull(pointer)) return null;

		const { bgFill, bgOpacity, backgroundShapeSVG, tooltipSVG, bgheight, bgwidth } = this.props;
		const { height } = moreProps;

		const { x, y, content, pointWidth, bgSize } = pointer;

		const bgShape = isDefined(bgwidth) && isDefined(bgheight)
			? { width: bgwidth, height: bgheight }
			: bgSize;

		const firstRecordXValue = moreProps.xAccessor(this.props.firstItem);
		const firstItemX = moreProps.xScale(firstRecordXValue);
		const lastRecordXValue = moreProps.xAccessor(this.props.lastItem);
		const lastItemX = moreProps.xScale(lastRecordXValue);

		let customX = moreProps.mouseXY[ 0 ];
		if (firstItemX < moreProps.mouseXY[ 0 ]) {
			customX = firstItemX;
		} else if (lastItemX > moreProps.mouseXY[ 0 ]) {
			customX = lastItemX;
		}

		return (
			<g>
				<rect
					x={ customX - pointWidth / 2 }
					y={ 0 }
					width={ pointWidth }
					height={ height }
					fill={ bgFill }
					opacity={ bgOpacity }
				/>
				<g
					className="react-stockcharts-tooltip-content"
					transform={ `translate(${ x }, ${ y })` }
				>
					{ backgroundShapeSVG(this.props, bgShape, content) }
					{ tooltipSVG(this.props, content) }
				</g>
			</g>
		);
	}
}

const PADDING = 5;
const X = 10;
const Y = 10;

function backgroundShapeSVG({ opacity }: any, { height, width }: any, content: any) {
	return (
		<rect
			height={ height }
			width={ width }
			fill={ hexToRGBA("#C0E4F8", 1) }
			opacity={ opacity }
			stroke={ hexToRGBA("#0093DD", 1) }
		/>
	);
}

function tooltipSVG({ fontFamily, fontSize, fontFill }: any, content: any) {
	const tspans = [];
	const startY = Y + fontSize * 0.9;

	for (let i = 0 ; i < content.y.length ; i++) {
		const y = content.y[ i ];
		const textY = startY + (fontSize * (i + 1));

		tspans.push(<tspan key={ `L-${ i }` } x={ X } y={ textY } fill={ y.stroke }>{ y.label }</tspan>);
		tspans.push(<tspan key={ i }>: </tspan>);
		tspans.push(<tspan key={ `V-${ i }` }>{ y.value }</tspan>);
	}
	return (
		<text fontFamily={ fontFamily } fontSize={ fontSize } fill={ fontFill }>
			<tspan x={ X } y={ startY }>{ content.x }</tspan>
			{ tspans }
		</text>
	);
}

function backgroundShapeCanvas(props: any, { width, height }: any, ctx?: any) {
	const { fill, stroke, opacity } = props;

	ctx.fillStyle = hexToRGBA(fill, opacity);
	ctx.strokeStyle = stroke;
	ctx.beginPath();
	ctx.rect(0, 0, width, height);
	ctx.fill();
	ctx.stroke();
}

function tooltipCanvas({ fontFamily, fontSize, fontFill }: any, content: any, ctx?: any) {
	const startY = Y + fontSize * 0.9;
	ctx.font = `${ fontSize }px ${ fontFamily }`;
	ctx.fillStyle = fontFill;
	ctx.textAlign = "left";
	ctx.fillText(content.x, X, startY);

	for (let i = 0 ; i < content.y.length ; i++) {
		const y = content.y[ i ];
		const textY = startY + (fontSize * (i + 1));
		ctx.fillStyle = y.stroke || fontFill;
		ctx.fillText(y.label, X, textY);

		ctx.fillStyle = fontFill;
		ctx.fillText(": " + y.value, X + ctx.measureText(y.label).width, textY);
	}
}

function drawOnCanvas(ctx: any, props: any, context: any, pointer: any, height: any) {

	const { margin, ratio } = context;
	const { bgFill, bgOpacity } = props;
	const { backgroundShapeCanvas, tooltipCanvas } = props;

	const originX = 0.5 * ratio + margin.left;
	const originY = 0.5 * ratio + margin.top;

	ctx.save();

	ctx.setTransform(1, 0, 0, 1, 0, 0);
	ctx.scale(ratio, ratio);

	ctx.translate(originX, originY);

	const { x, y, content, centerX, pointWidth, bgSize } = pointer;

	ctx.fillStyle = hexToRGBA(bgFill, bgOpacity);
	ctx.beginPath();
	ctx.rect(centerX - pointWidth / 2, 0, pointWidth, height);
	ctx.fill();

	ctx.translate(x, y);
	backgroundShapeCanvas(props, bgSize, ctx);
	tooltipCanvas(props, content, ctx);

	ctx.restore();
}

function calculateTooltipSize({ fontFamily, fontSize, fontFill }: any, content: any, ctx?: any) {
	if (isNotDefined(ctx)) {
		const canvas = document.createElement("canvas");
		ctx = canvas.getContext("2d");
	}

	ctx.font = `${ fontSize }px ${ fontFamily }`;
	ctx.fillStyle = fontFill;
	ctx.textAlign = "left";

	const measureText = (str: any) => ({
		width: ctx.measureText(str).width,
		height: fontSize,
	});

	const { width, height } = content.y
									 .map(({ label, value }: any) => measureText(`${ label }: ${ value }`))
		// Sum all y and x sizes (begin with x label size)
									 .reduce((res: any, size: any) => sumSizes(res, size), measureText(String(content.x)));

	return {
		width: width + 2 * X,
		height: height + 2 * Y,
	};
}

function sumSizes(...sizes: any) {
	return {
		width: Math.max(...sizes.map((size: any) => size.width)),
		height: sum(sizes, (d: any) => d.height),
	};
}

function normalizeX(x: any, bgSize: any, pointWidth: any, width: any) {
	return x < width / 2
		? x + pointWidth / 2 + PADDING
		: x - bgSize.width - pointWidth / 2 - PADDING;
}

function normalizeY(y: any, bgSize: any) {
	return y - bgSize.height <= 0
		? y + PADDING
		: y - bgSize.height - PADDING;
}

function origin(props: any, moreProps: any, bgSize: any, pointWidth: any) {
	const { chartId, yAccessor } = props;
	const { mouseXY, xAccessor, currentItem, xScale, chartConfig, width } = moreProps;
	let y = last(mouseXY);

	const xValue = xAccessor(currentItem);
	let x = Math.round(xScale(xValue));

	if (isDefined(chartId) && isDefined(yAccessor)
		&& isDefined(chartConfig) && isDefined(chartConfig.findIndex)) {
		const yValue = yAccessor(currentItem);
		const chartIndex = chartConfig.findIndex((x: any) => x.id === chartId);

		y = Math.round(chartConfig[ chartIndex ].yScale(yValue));
	}

	x = normalizeX(x, bgSize, pointWidth, width);
	y = normalizeY(y, bgSize);

	return [ x, y ];
}

function helper(props: any, moreProps: any, ctx?: any) {
	const { show, xScale, currentItem, plotData } = moreProps;
	const { origin, tooltipContent } = props;
	const { xAccessor, displayXAccessor } = moreProps;

	if (!show || isNotDefined(currentItem)) return;

	const xValue = xAccessor(currentItem);

	if (!show || isNotDefined(xValue)) return;

	const updatedCurrentItem = plotData.find((dataItem: any) => xAccessor(dataItem) === xAccessor(currentItem));

	if (isNull(updatedCurrentItem)) return;

	const content = tooltipContent({ currentItem: updatedCurrentItem, xAccessor: displayXAccessor });
	const centerX = xScale(xValue);
	const pointWidth = Math.abs(xScale(xAccessor(last(plotData))) - xScale(xAccessor(first(plotData)))) / (plotData.length - 1);

	const bgSize = calculateTooltipSize(props, content, ctx);

	const [ x, y ] = origin(props, moreProps, bgSize, pointWidth);

	const firstRecordXValue = moreProps.xAccessor(props.firstItem);
	const firstItemX = moreProps.xScale(firstRecordXValue);
	const lastRecordXValue = moreProps.xAccessor(props.lastItem);
	const lastItemX = moreProps.xScale(lastRecordXValue);

	let customX = moreProps.mouseXY[ 0 ];
	if (firstItemX < moreProps.mouseXY[ 0 ]) {
		customX = firstItemX;
	} else if (lastItemX > moreProps.mouseXY[ 0 ]) {
		customX = lastItemX;
	}

	return { x: normalizeX(customX, bgSize, pointWidth, moreProps.width), y, content, centerX, pointWidth, bgSize };
}

export default (ChartHoverTooltip);
