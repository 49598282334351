import { isString } from "src/app/utils/typeguards";
import i18n from "src/app/translations/i18n";

export const parseFile = (file: File): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => isString(reader.result) && resolve(reader.result);
		reader.onerror = error => reject(error);
	});

export type ParseFileWithIdPayload = {
	result: string
	id: string
}

export const parseFileWithId = (file: File, id: string): Promise<ParseFileWithIdPayload> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => isString(reader.result) ? resolve({ result: reader.result, id }) : reject(i18n.t("RESPONSES.API.unexpected output"));
		reader.onerror = error => reject(error);
	});

export const parseFileToBinary = (file: File): Promise<string | ArrayBuffer | null> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsArrayBuffer(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
