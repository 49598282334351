import { combineEpics, Epic } from "redux-observable";
import { RootAction } from "src/app/store/root.actions";
import { RootState } from "src/app/store/root.reducer";
import { Services } from "src/app/store/services";

import * as apiEpics from "src/app/store/features/api/api.epics";
import * as messageEpics from "src/app/store/features/message/message.epics";
import * as miscEpics from "src/app/store/features/misc/misc.epics";
import * as userEpics from "src/app/store/features/user/user.epics";
import * as wsEpics from "src/app/store/features/ws/ws.epics";
import * as dashboardEpics from "src/app/store/features/dashboard/dashboard.epics";
import * as firmwareEpics from "src/app/store/features/firmware/firmware.epics";
import * as settingsEpics from "src/app/store/features/settings/settings.epics";
// Epics imports - don't remove
import * as uiLayoutEpics from "src/app/store/features/ui/layout/ui.layout.epics";
import * as uiUserEpics from "src/app/store/features/ui/user/ui.user.epics";
import * as uiDashboardEpics from "src/app/store/features/ui/dashboard/ui.dashboard.epics";
import * as uiFirmwareEpics from "src/app/store/features/ui/firmware/ui.firmware.epics";
import * as uiSettingsEpics from "src/app/store/features/ui/settings/ui.settings.epics";
// Ui epics imports - don't remove

export type RootEpic = Epic<RootAction, RootAction, RootState, Services>;

export default combineEpics(
	...Object.values(apiEpics),
	...Object.values(messageEpics),
	...Object.values(miscEpics),
	...Object.values(userEpics),
	...Object.values(wsEpics),
	...Object.values(dashboardEpics),
	...Object.values(firmwareEpics),
	...Object.values(settingsEpics),
	// Epics object - don't remove

	...Object.values(uiLayoutEpics),
	...Object.values(uiUserEpics),
	...Object.values(uiDashboardEpics),
	...Object.values(uiFirmwareEpics),
	...Object.values(uiSettingsEpics),
	// Ui epics object - don't remove
);
