import { RootEpic } from "src/app/store/root.epic";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { apiAsync } from "src/app/store/features/api/api.actions";
import {
	exportSettingsAsync,
	fetchNewStandardEventsAsync,
	fetchNewUserEventsAsync,
	fetchStandardEventByIdAsync,
	fetchStandardEventPqdifAsync,
	fetchStandardEventsAsync,
	fetchStandardEventsWithOffsetAsync,
	fetchStandardEventWaveformAsync,
	fetchStandardRmsAsync,
	fetchSummaryAsync,
	fetchUserEventByIdAsync,
	fetchUserEventPqdifAsync,
	fetchUserEventsAsync,
	fetchUserEventsWithOffsetAsync,
	fetchUserEventWaveformAsync,
	fetchUserRmsAsync,
	importSettingsAsync,
	setLocalizationOrUserInfoAsync,
} from "src/app/store/features/dashboard/dashboard.actions";
import { FailurePayload, SuccessPayload } from "src/app/types/api/api.types";
import { SingleEvent, StandardEvents, UserEvents } from "src/app/types/api/event.types";
import { waveforms } from "src/app/proto/event_waveforms";
import { EventWaveform, RmsWaveform } from "src/app/types/api/dashboard.types";
import { rms12 } from "src/app/proto/event_rms12";
import { from, of } from "rxjs";
import { displayToast } from "src/app/store/features/message/message.actions";
import { ToastType } from "src/app/types/ui/message.types";
import i18n from "src/app/translations/i18n";

export const fetchSummaryEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchSummaryAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/summary",
				method: "GET",
				onSuccess: fetchSummaryAsync.success,
				onFailure: fetchSummaryAsync.failure,
			}),
		),
	);

export const fetchStandardEventsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchStandardEventsAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/events/list/standard",
				method: "POST",
				onSuccess: (payload: SuccessPayload<StandardEvents>) =>
					fetchStandardEventsAsync.success({
						data: {
							events: {
								...payload.data.events,
								event: payload.data.events.event.sort((a, b) => b.timestampStart - a.timestampStart),
							},
						},
					}),
				onFailure: fetchStandardEventsAsync.failure,
			}),
		),
	);

export const fetchStandardEventsWithOffsetEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchStandardEventsWithOffsetAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/events/list/standard",
				method: "POST",
				data: { offset: action.payload },
				onSuccess: fetchStandardEventsWithOffsetAsync.success,
				onFailure: fetchStandardEventsWithOffsetAsync.failure,
			}),
		),
	);

export const fetchNewStandardEventsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchNewStandardEventsAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/events/list/standard",
				method: "POST",
				onSuccess: fetchNewStandardEventsAsync.success,
				onFailure: fetchNewStandardEventsAsync.failure,
			}),
		),
	);

export const fetchUserEventsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchUserEventsAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/events/list/user",
				method: "POST",
				onSuccess: (payload: SuccessPayload<UserEvents>) =>
					fetchUserEventsAsync.success({
						data: {
							events: {
								...payload.data.events,
								event: payload.data.events.event.sort((a, b) => b.timestampStart - a.timestampStart),
							},
						},
					}),
				onFailure: fetchUserEventsAsync.failure,
			}),
		),
	);

export const fetchUserEventsWithOffsetEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchUserEventsWithOffsetAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/events/list/user",
				method: "POST",
				data: { offset: action.payload },
				onSuccess: fetchUserEventsWithOffsetAsync.success,
				onFailure: fetchUserEventsWithOffsetAsync.failure,
			}),
		),
	);

export const fetchNewUserEventsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchNewUserEventsAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/events/list/user",
				method: "POST",
				onSuccess: fetchNewUserEventsAsync.success,
				onFailure: fetchNewUserEventsAsync.failure,
			}),
		),
	);

export const setLocalizationOrUserInfoEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(setLocalizationOrUserInfoAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/set",
				method: "POST",
				data: action.payload,
				onSuccess: setLocalizationOrUserInfoAsync.success,
				onFailure: setLocalizationOrUserInfoAsync.failure,
			}),
		),
	);

export const fetchStandardEventByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchStandardEventByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/events/details/standard",
				method: "POST",
				data: action.payload,
				onSuccess: (payload: SuccessPayload<SingleEvent>) => fetchStandardEventByIdAsync.success({ ...payload, id: action.payload.event_id }),
				onFailure: (payload: FailurePayload) => fetchStandardEventByIdAsync.failure({ ...payload, id: action.payload.event_id }),
			}),
		),
	);

export const fetchUserEventByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchUserEventByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/events/details/user",
				method: "POST",
				data: action.payload,
				onSuccess: (payload: SuccessPayload<SingleEvent>) => fetchUserEventByIdAsync.success({ ...payload, id: action.payload.event_id }),
				onFailure: (payload: FailurePayload) => fetchUserEventByIdAsync.failure({ ...payload, id: action.payload.event_id }),
			}),
		),
	);

export const fetchStandardEventWaveformEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchStandardEventWaveformAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/events/waveforms/standard",
				method: "POST",
				data: action.payload,
				responseType: "arraybuffer",
				decoder: waveforms.decode,
				onSuccess: (payload: SuccessPayload<EventWaveform>) => fetchStandardEventWaveformAsync.success({ ...payload, id: action.payload.event_id }),
				onFailure: (payload: FailurePayload) => fetchStandardEventWaveformAsync.failure({ ...payload, id: action.payload.event_id }),
			}),
		),
	);

export const fetchUserEventWaveformEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchUserEventWaveformAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/events/waveforms/user",
				method: "POST",
				data: action.payload,
				responseType: "arraybuffer",
				decoder: waveforms.decode,
				onSuccess: (payload: SuccessPayload<EventWaveform>) => fetchUserEventWaveformAsync.success({ ...payload, id: action.payload.event_id }),
				onFailure: (payload: FailurePayload) => fetchUserEventWaveformAsync.failure({ ...payload, id: action.payload.event_id }),
			}),
		),
	);

export const fetchStandardRmsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchStandardRmsAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/events/rms12/user",
				method: "POST",
				data: action.payload,
				responseType: "arraybuffer",
				decoder: rms12.decode,
				onSuccess: (payload: SuccessPayload<RmsWaveform>) => fetchStandardRmsAsync.success({ ...payload, id: action.payload.event_id }),
				onFailure: (payload: FailurePayload) => fetchStandardRmsAsync.failure({ ...payload, id: action.payload.event_id }),

			}),
		),
	);

export const fetchUserRmsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchUserRmsAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/events/rms12/standard",
				method: "POST",
				data: action.payload,
				responseType: "arraybuffer",
				decoder: rms12.decode,
				onSuccess: (payload: SuccessPayload<RmsWaveform>) => fetchUserRmsAsync.success({ ...payload, id: action.payload.event_id }),
				onFailure: (payload: FailurePayload) => fetchUserRmsAsync.failure({ ...payload, id: action.payload.event_id }),

			}),
		),
	);

export const fetchStandardEventPqdifEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchStandardEventPqdifAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/events/pqdif/standard",
				method: "POST",
				data: action.payload,
				responseType: "arraybuffer",
				noCamelize: true,
				onSuccess: (payload: SuccessPayload<any>) => fetchStandardEventPqdifAsync.success({ ...payload, id: action.payload.event_id }),
				onFailure: fetchStandardEventPqdifAsync.failure,
				withoutNotification: true,
			}),
		),
	);

export const fetchUserEventPqdifEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchUserEventPqdifAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/events/pqdif/user",
				method: "POST",
				data: action.payload,
				responseType: "arraybuffer",
				noCamelize: true,
				onSuccess: (payload: SuccessPayload<any>) => fetchUserEventPqdifAsync.success({ ...payload, id: action.payload.event_id }),
				onFailure: fetchUserEventPqdifAsync.failure,
				withoutNotification: true,
			}),
		),
	);

export const exportSettingsAsyncEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(exportSettingsAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/settings/export",
				method: "GET",
				responseType: "arraybuffer",
				noCamelize: true,
				onSuccess: exportSettingsAsync.success,
				onFailure: exportSettingsAsync.failure,
				withoutNotification: true,
			}),
		),
	);

export const importSettingsAsyncEpic: RootEpic = (action$, _, api) =>
	action$.pipe(
		filter(isActionOf(importSettingsAsync.request)),
		mergeMap((action) =>
			from(api.file.parseFileToBinary(action.payload))
				.pipe(
					map(parsedFile =>
						apiAsync.request({
							url: "/settings/import",
							method: "POST",
							data: parsedFile,
							onSuccess: importSettingsAsync.success,
							onFailure: importSettingsAsync.failure,
							withoutNotification: true,
						}),
					),
					catchError(() =>
						of(displayToast({ type: ToastType.ERROR, content: i18n.t("RESPONSES.parsing file error") })),
					),
				),
		),
	);
