import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { handleBasicActions, initialStateReducer } from "src/app/utils/redux";
import { StateReducer } from "src/app/types/redux.types";
import { FirmwareInfo } from "src/app/types/api/firmware.types";
import { fetchFirmwareInfoAsync } from "src/app/store/features/firmware/firmware.actions";
import { forgetSession } from "src/app/store/features/user/user.actions";
import { uiSetUploadProgress } from "src/app/store/features/ui/firmware/ui.firmware.actions";

const reducer = combineReducers({
	firmwareInfo: createReducer(initialStateReducer as StateReducer<FirmwareInfo>)
		.handleAction([ fetchFirmwareInfoAsync.request, fetchFirmwareInfoAsync.success, fetchFirmwareInfoAsync.failure ], handleBasicActions(fetchFirmwareInfoAsync))
		.handleAction([ forgetSession ], () => initialStateReducer),
	uploadProgress: createReducer(0)
		.handleAction([ uiSetUploadProgress ], (_, action) => action.payload),
});

export default reducer;
