import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import { getUserEventWithRmsWaveform } from "src/app/store/features/dashboard/dashboard.selectors";
import { useParams } from "react-router-dom";
import EventRmsWaveformContainer from "src/app/containers/Event/EventRmsWaveform.container";
import ForceFreshStrategy from "src/app/hoc/caching/ForceFreshStrategy.hoc";
import { uiFetchUserEventRmsWaveform } from "src/app/store/features/ui/dashboard/ui.dashboard.actions";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function UserEventRmsWaveformView(props: Props) {

	const {
		fetchEventAndWaveform,
		eventWithWaveform,
	} = props;

	const { userEventId = "" } = useParams();

	return (
		<ForceFreshStrategy
			request={ () => fetchEventAndWaveform({ event_id: +userEventId }) }
			state={ eventWithWaveform(+userEventId) }
		>
			{
				({ event, eventWaveform }) =>
					<EventRmsWaveformContainer
						event={ event }
						eventWaveform={ eventWaveform }
					/>
			}
		</ForceFreshStrategy>
	);
}

const mapStateToProps = (state: RootState) => ({
	eventWithWaveform: (eventId: number) => getUserEventWithRmsWaveform(state, eventId),
});

const mapDispatchToProps = {
	fetchEventAndWaveform: uiFetchUserEventRmsWaveform,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEventRmsWaveformView);
