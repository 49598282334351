import { createAction } from "typesafe-actions";
import { SetBufforRecordingPayload } from "src/app/types/api/dashboard.types";
import { TestFtpConnectionRequest } from "src/app/types/api/settings.types";

export const uiFetchSettings = createAction("UI_SETTINGS__FETCH_SETTINGS")<undefined>();

export const uiFetchSettingsIecProfile = createAction("UI_SETTINGS__FETCH_SETTINGS_IEC_PROFILE")<string>();

export const uiTestFtpConnection = createAction("UI_SETTINGS__TEST_FTP_CONNECTION")<TestFtpConnectionRequest>();

export const uiSetBufforConfiguration = createAction("UI_SETTINGS__SET_BUFFOR_CONFIGURATION")<SetBufforRecordingPayload>();
