import { FormItem } from "src/app/types/ui/form.types";
import { EnumDictionary, EnumOptionsSetting } from "src/app/types/util.types";
import { RecordingOption } from "src/app/types/api/settings.types";
import { getOptionMask, isOptionsContainsOption, isOptionSelected } from "src/app/utils/helpers";
import { recordingOptionDictionary } from "src/app/utils/constants/dictionaries";
import EnumOptionsCheckbox from "src/app/components/Form/EnumOptionsCheckbox.component";
import classNames from "classnames";

type Props = {
	id: string
	formItem: FormItem<number>
	options: EnumOptionsSetting<RecordingOption>["options"]
	handleChange: (value: number) => void
	reducerFormItem: FormItem<number>
	children?: (isLogEventEnabled: boolean) => JSX.Element
	className?: string
	recordingDictionary?: EnumDictionary<RecordingOption, string>
	disabled?: boolean
	withoutLogEnableClassName?: boolean
	childrenClassName?: string
};

const additionalEventOptions: RecordingOption[] = [
	// RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE,
	RecordingOption.SET_REC_OPT_EVT_WAVEFORM_ENABLE,
	RecordingOption.SET_REC_OPT_EVT_RMS12_ENABLE,
];

function EventRecordingOptionConfiguration(props: Props) {

	const {
		id,
		formItem,
		options,
		handleChange,
		reducerFormItem,
		children = () => <></>,
		className,
		recordingDictionary = recordingOptionDictionary,
		disabled = false,
		withoutLogEnableClassName = false,
		childrenClassName,
	} = props;

	const canLogEvents = isOptionsContainsOption(RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE, options);

	const additionalLogOptions = additionalEventOptions.filter(eventOption => isOptionsContainsOption(eventOption, options));

	if (!canLogEvents) return null;

	const logEventMask = getOptionMask(RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE, options) ?? 0;

	return (
		<div
			className={
				classNames(
					"flex flex-col gap-2",
					className,
				)
			}
		>
			<div>
				<EnumOptionsCheckbox
					className={
						classNames(
							{ "[&>label]:font-bold": !withoutLogEnableClassName },
						)
					}
					id={ `${ id }-evt-log-enable` }
					formItem={ formItem }
					label={ recordingDictionary()[ RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE ] }
					options={ options }
					option={ RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE }
					handleChange={ handleChange }
					hasBeenChanged={ (reducerFormItem.value & logEventMask) !== (reducerFormItem.initialValue & logEventMask) }
					disabled={ disabled }
				/>
			</div>
			<div className={ classNames("flex flex-col gap-2 ml-6", childrenClassName) }>
				{
					additionalLogOptions.length > 0 &&
                    <div className="flex flex-col gap-2">
						{
							additionalLogOptions
								.map(eventOption => {
									const mask = getOptionMask(eventOption, options) ?? 0;
									return (
										<EnumOptionsCheckbox
											key={ `${ id }-${ eventOption }` }
											id={ `${ id }-${ eventOption }` }
											formItem={ formItem }
											label={ recordingDictionary()[ eventOption ] }
											options={ options }
											option={ eventOption }
											handleChange={ handleChange }
											dependingOn={ [ RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE ] }
											hasBeenChanged={ (reducerFormItem.value & mask) !== (reducerFormItem.initialValue & mask) }
											disabled={ disabled }
										/>
									);
								})
						}
                    </div>
				}
				{ children(isOptionSelected(formItem.value, RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE, options) && !disabled) }
			</div>
		</div>
	);
}

export default (EventRecordingOptionConfiguration);
