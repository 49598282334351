import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import AppVersionContainer from "src/app/containers/Utils/AppVersion.container";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";
import { fetchBackendVersionAsync } from "src/app/store/features/misc/misc.actions";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function AppVersionView(props: Props) {

	const {
		fetchBackendVersion,
		backendVersion,
	} = props;

	return (
		<CachedThenFreshStrategy
			request={ fetchBackendVersion }
			state={ backendVersion }
		>
			{
				backendVersion =>
					<AppVersionContainer
						backendVersion={ backendVersion }
					/>
			}
		</CachedThenFreshStrategy>
	);
}

const mapStateToProps = (state: RootState) => ({
	backendVersion: state.misc.backendVersion,
});

const mapDispatchToProps = {
	fetchBackendVersion: fetchBackendVersionAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppVersionView);