import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateNumberField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import BasicRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/BasicRecordingOptionConfiguration.component";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { useContext, useEffect } from "react";
import { MeasurementsUserContext } from "src/app/components/Measurements/MeasurementsUserContent.component";
import { Button, Card } from "flowbite-react";
import Input from "src/app/components/Form/Input.component";
import EventRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/EventRecordingOptionConfiguration.component";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";
import classNames from "classnames";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsMeasurementsUser: SettingsMeasurementsUser
	};

type VoltageUnbalanceForm = {
	cfgVoltageUnbalanceRecOpt: number
	cfgVoltageUnbalanceThresholdMax: string
	cfgVoltageUnbalanceThresholdMaxMin: number
	cfgVoltageUnbalanceThresholdMaxMax: number
}

const validator: FormValidator<VoltageUnbalanceForm> = {
	cfgVoltageUnbalanceRecOpt: () => null,
	cfgVoltageUnbalanceThresholdMax: (cfgVoltageUnbalanceThresholdMax, optional, form) => validateNumberField(i18n.t("ENERGY.max"), cfgVoltageUnbalanceThresholdMax, optional, "he", { from: form.cfgVoltageUnbalanceThresholdMaxMin.value, to: form.cfgVoltageUnbalanceThresholdMaxMax.value, decimalPlaces: 2 }),
	cfgVoltageUnbalanceThresholdMaxMin: () => null,
	cfgVoltageUnbalanceThresholdMaxMax: () => null,
};

const ID = "voltage-unbalance";

function VoltageUnbalanceCard(props: Props) {

	const { t } = useTranslation();

	const {
		settingsMeasurementsUser: {
			cfgVoltageUnbalance,
		},
		isAdmin,
	} = props;

	const {
		form: reducerForm,
		handleChange: reducerHandleChange,
	} = useReducerForm(
		"buffor",
		bufforFormActions,
		() => null,
	);

	const { cfgUserEnable } = useContext(MeasurementsUserContext);

	const _handleSubmit = (values: VoltageUnbalanceForm) => {
		reducerHandleChange("cfgVoltageUnbalanceRecOpt", values.cfgVoltageUnbalanceRecOpt);
		reducerHandleChange("cfgVoltageUnbalanceThresholdMax", values.cfgVoltageUnbalanceThresholdMax);
	};

	const _getInitialState = () => ({
		cfgVoltageUnbalanceRecOpt: createFormField((reducerForm.cfgVoltageUnbalanceRecOpt.value !== reducerForm.cfgVoltageUnbalanceRecOpt.initialValue) ? reducerForm.cfgVoltageUnbalanceRecOpt.value : cfgVoltageUnbalance?.cfgVoltageUnbalanceRecOpt?.value ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		cfgVoltageUnbalanceThresholdMax: createFormField((reducerForm.cfgVoltageUnbalanceThresholdMax.value !== reducerForm.cfgVoltageUnbalanceThresholdMax.initialValue) ? reducerForm.cfgVoltageUnbalanceThresholdMax.value : cfgVoltageUnbalance?.cfgVoltageUnbalanceThresholdMax?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
		cfgVoltageUnbalanceThresholdMaxMin: createFormField(cfgVoltageUnbalance?.cfgVoltageUnbalanceThresholdMax?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		cfgVoltageUnbalanceThresholdMaxMax: createFormField(cfgVoltageUnbalance?.cfgVoltageUnbalanceThresholdMax?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ cfgVoltageUnbalance ]);

	useEffect(() => {
		toggleDisable("cfgVoltageUnbalanceRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgVoltageUnbalanceThresholdMax", !cfgUserEnable || !isAdmin);
	}, [ cfgUserEnable ]);

	useEffect(() => {
		toggleDisable("cfgVoltageUnbalanceRecOpt", false);
		toggleDisable("cfgVoltageUnbalanceThresholdMax", false);

		handleChange("cfgVoltageUnbalanceRecOpt", reducerForm.cfgVoltageUnbalanceRecOpt.value);
		handleChange("cfgVoltageUnbalanceThresholdMax", reducerForm.cfgVoltageUnbalanceThresholdMax.value);

		toggleDisable("cfgVoltageUnbalanceRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgVoltageUnbalanceThresholdMax", !cfgUserEnable || !isAdmin);
	}, [
		reducerForm.cfgVoltageUnbalanceRecOpt.value,
		reducerForm.cfgVoltageUnbalanceThresholdMax.value,
	]);

	return (
		<Card className="bg-gray-50">
			<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
				{ t("MEASUREMENTS.unbalance and symmetrical components") }
			</h5>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<BasicRecordingOptionConfiguration
					id={ ID }
					options={ cfgVoltageUnbalance?.cfgVoltageUnbalanceRecOpt?.options ?? [] }
					formItem={ form.cfgVoltageUnbalanceRecOpt }
					handleChange={ value => handleChange("cfgVoltageUnbalanceRecOpt", value) }
					reducerFormItem={ reducerForm.cfgVoltageUnbalanceRecOpt }
				/>
				<div className="flex flex-col gap-3">
					<h5 className="text-base sm:text-xl font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
						{ t("MEASUREMENTS.events") }
					</h5>
					<EventRecordingOptionConfiguration
						id={ ID }
						options={ cfgVoltageUnbalance?.cfgVoltageUnbalanceRecOpt?.options ?? [] }
						formItem={ form.cfgVoltageUnbalanceRecOpt }
						handleChange={ value => handleChange("cfgVoltageUnbalanceRecOpt", value) }
						reducerFormItem={ reducerForm.cfgVoltageUnbalanceRecOpt }
					>
						{
							(isLogEventEnabled) =>
								<div className="flex flex-col gap-2.5">
									<h5 className="text-sm sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
										{ t("MEASUREMENTS.thresholds") }
									</h5>
									<hr className="mx-2"/>
									<div className="flex gap-2.5 flex-1">
										<Input
											className="flex-1"
											formItem={ form.cfgVoltageUnbalanceThresholdMax }
											label={ t("MEASUREMENTS.max") }
											name="cfgVoltageUnbalanceThresholdMax"
											inputProps={ {
												type: "number",
												onChange: (e) => handleChange("cfgVoltageUnbalanceThresholdMax", e.target.value),
												onBlur: () => handleBlur("cfgVoltageUnbalanceThresholdMax"),
												disabled: !isLogEventEnabled,
												sizing: "sm",
												className: "[&>span]:!min-w-[40px] [&>span]:justify-center",
											} }
											addonRight={ cfgVoltageUnbalance?.cfgVoltageUnbalanceThresholdMax?.unit ?? "-" }
											hasBeenChanged={ reducerForm.cfgVoltageUnbalanceThresholdMax.value !== reducerForm.cfgVoltageUnbalanceThresholdMax.initialValue }
										/>
										<div
											className={
												classNames(
													"flex gap-2",
													"xl:flex-[7_1_0%]",
													"flex-[4_1_0%]",
												)
											}
										>
											{/*<RelayChannelChooser
												options={ [
													{
														value: "cfgRelayFlickerPst1RecOpt",
														label: cfgFlickerPst.relayChannelLabel.ch1 ?? "L1",
													}, {
														value: "cfgRelayFlickerPst2RecOpt",
														label: cfgFlickerPst.relayChannelLabel.ch2 ?? "L2",
													}, {
														value: "cfgRelayFlickerPst3RecOpt",
														label: cfgFlickerPst.relayChannelLabel.ch3 ?? "L3",
													},
												] }
												disabled={ !isLogEventEnabled }
												formItem={ form.cfgFlickerPstChannels }
												reducerFormItem={ reducerForm.cfgFlickerPstChannels }
												handleChange={ value => handleChange("cfgFlickerPstChannels", value) }
											/>
											<RelayActionSelect
												formItem={ form.cfgFlickerPstActions }
												reducerFormItem={ reducerForm.cfgFlickerPstActions }
												options={ cfgFlickerPst.cfgRelayFlickerPst1Map.options }
												disabled={ !isLogEventEnabled || isEmptyArray(form.cfgFlickerPstChannels.value) }
												handleChange={ value => handleChange("cfgFlickerPstActions", value) }
											/>*/ }
										</div>
									</div>
									<hr className="mx-2"/>
								</div>
						}
					</EventRecordingOptionConfiguration>
				</div>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(VoltageUnbalanceCard);
