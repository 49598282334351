import { EnumOptionsSetting } from "src/app/types/util.types";
import { FormItem } from "src/app/types/ui/form.types";
import { RecordingOption } from "src/app/types/api/settings.types";
import { getOptionMask, isOptionsContainsOption, isOptionSelected } from "src/app/utils/helpers";
import { isNull } from "src/app/utils/typeguards";
import EnumOptionsCheckbox from "src/app/components/Form/EnumOptionsCheckbox.component";
import { Checkbox, Label } from "flowbite-react";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { recordingOptionDictionary, recordingOptionSortingOrder } from "src/app/utils/constants/dictionaries";
import { useTranslation } from "react-i18next";

type Props = {
	className?: string
	id: string
	formItem: FormItem<number>
	options: EnumOptionsSetting<RecordingOption>["options"]
	handleChange: (value: number) => void
	reducerFormItem: FormItem<number>
	label?: string
};

const basicRecordingOptions: RecordingOption[] = [
	RecordingOption.SET_REC_OPT_AVG,
	RecordingOption.SET_REC_OPT_MIN,
	RecordingOption.SET_REC_OPT_MAX,
	RecordingOption.SET_REC_OPT_INST,
];

function BasicRecordingOptionConfiguration(props: Props) {

	const { t } = useTranslation();

	const {
		className,
		id,
		formItem,
		options,
		handleChange,
		reducerFormItem,
		label = t('MEASUREMENTS.log'),
	} = props;

	const fields = basicRecordingOptions.filter(option => isOptionsContainsOption(option, options));

	const [ isAllChecked, toggleAllChecked ] = useState(fields.every(option => isOptionSelected(formItem.value, option, options)));

	useEffect(() => {
		if (fields.every(option => isOptionSelected(formItem.value, option, options))) {
			toggleAllChecked(true);
		} else if (fields.every(option => !isOptionSelected(formItem.value, option, options))) {
			toggleAllChecked(false);
		}
	}, [ formItem.value ]);

	if (fields.length === 0) return null;
	if (fields.length === 1) {
		const option = options.find(settingOption => basicRecordingOptions.includes(settingOption.text));
		if (isNull(option)) return null;

		return (
			<div className="flex flex-col gap-3">
				<h5 className="text-base sm:text-xl font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
					{t('MEASUREMENTS.value logging')}
				</h5>
				<EnumOptionsCheckbox
					id={ `${ id }-${ option.mask }` }
					formItem={ formItem }
					label={ label }
					options={ options }
					option={ option.text }
					handleChange={ handleChange }
					hasBeenChanged={ (reducerFormItem.value & option.mask) !== (reducerFormItem.initialValue & option.mask) }
				/>
			</div>
		);
	} else {
		const allId = `${ id }-all`;
		const isHalfChecked = fields.some(option => isOptionSelected(formItem.value, option, options)) && !fields.every(option => isOptionSelected(formItem.value, option, options));

		return (
			<div className="flex flex-col gap-3">
				<h5 className="text-base sm:text-xl font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
					{t('MEASUREMENTS.values logging')}
				</h5>
				<div
					className={
						classNames(
							"flex flex-col gap-4",
							className,
						)
					}
				>
					<div className="flex items-center gap-x-2">
						<Checkbox
							className={
								classNames(
									"my-auto relative",
									{ "after:content-[''] after:absolute after:-top-[1px] after:-left-[1px] after:w-[calc(100%+2px)] after:h-[calc(100%+2px)] after:rounded after:bg-primary-600": isHalfChecked },
									{ "before:content-[''] before:absolute before:w-2 before:h-0.5 before:bg-white before:top-1.5 before:left-[0.1875rem] before:z-10": isHalfChecked },
								)
							}
							id={ allId }
							name={ allId }
							checked={ isAllChecked }
							disabled={ formItem.disabled }
							onChange={ () => {
								toggleAllChecked(prev => {
									const isChecked = !prev;

									let value = formItem.value;
									for (let i = 0 ; i < fields.length ; i++) {
										const option = fields[ i ];
										if (isOptionSelected(formItem.value, option, options) !== isChecked) {
											const mask = getOptionMask(fields[ i ], options) ?? 0;

											isChecked ? value += mask : value -= mask;
										}
									}
									handleChange(value);

									return isChecked;
								});
							} }
						/>
						<Label
							htmlFor={ allId }
							disabled={ formItem.disabled }
							className="cursor-pointer"
						>
							{t('MEASUREMENTS.all')}
						</Label>
					</div>
					<div className="flex flex-col gap-2">
						{
							fields
								.sort((a, b) => recordingOptionSortingOrder()[ a ] - recordingOptionSortingOrder()[ b ])
								.map(field => {
									const option = options.find(settingOption => settingOption.text === field);
									if (isNull(option)) return null;

									return (
										<EnumOptionsCheckbox
											key={ option.mask }
											id={ `${ id }-${ option.mask }` }
											formItem={ formItem }
											label={ recordingOptionDictionary()[ field ] }
											options={ options }
											option={ option.text }
											handleChange={ value => {
												handleChange(value);
												if (fields.every(option => isOptionSelected(value, option, options))) {
													toggleAllChecked(true);
												} else if (fields.every(option => !isOptionSelected(value, option, options))) {
													toggleAllChecked(false);
												}
											} }
											hasBeenChanged={ (reducerFormItem.value & option.mask) !== (reducerFormItem.initialValue & option.mask) }
										/>
									);
								})
						}
					</div>
				</div>
			</div>
		);
	}
}

export default (BasicRecordingOptionConfiguration);
