import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import TimeplotContainer from "src/app/containers/Realtime/Timeplot.container";
import useWebSocket, { WebSocketUri } from "src/app/utils/hooks/useWebSocket";
import { DataBasicLive } from "src/app/types/api/ws.types";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function TimeplotView(props: Props) {

	const data = useWebSocket<DataBasicLive>(WebSocketUri.DATA_BASIC_LIVE);

	return (
		<TimeplotContainer
			timeplotData={
				data.map(item =>
					({
						...item.basic,
						date: new Date(item.basic.timestamp),
					}),
				)
			}
		/>
	);
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TimeplotView);
