import { createContext, PropsWithChildren, useContext, useEffect } from "react";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { toggleSidebar } from "src/app/store/features/ui/layout/ui.layout.actions";
import { isSmallScreen } from "src/app/store/features/ui/layout/ui.layout.selectors";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

type SidebarContextProps = {
	isSidebarOpen: boolean
	isPageWithSidebar: boolean
	setOpenOnSmallScreens: (isOpen: boolean) => void
}

const SidebarContext = createContext<SidebarContextProps>(undefined!);

function SidebarProvider(props: PropsWithChildren<Props>) {

	const {
		children,
		isSmallScreen,
		bodyWidth,
		isSidebarOpen,
		toggleSidebar,
	} = props;

	const location = useLocation();

	// Close Sidebar on page change on mobile
	useEffect(() => {
		if (isSmallScreen && bodyWidth !== 0) {
			toggleSidebar(false);
		}
	}, [ location.pathname ]);

	// Close Sidebar on mobile tap inside main content
	useEffect(() => {
		function handleMobileTapInsideMain(event: MouseEvent) {
			const main = document.querySelector("main");
			const isClickInsideMain = main?.contains(event.target as Node);

			if (isSmallScreen && isClickInsideMain) {
				toggleSidebar(false);
			}
		}

		document.addEventListener("mousedown", handleMobileTapInsideMain);
		return () => {
			document.removeEventListener("mousedown", handleMobileTapInsideMain);
		};
	}, [ isSmallScreen ]);

	return (
		<SidebarContext.Provider
			value={ {
				isSidebarOpen: isSidebarOpen,
				isPageWithSidebar: true,
				setOpenOnSmallScreens: toggleSidebar,
			} }
		>
			{ children }
		</SidebarContext.Provider>
	);
}

const mapStateToProps = (state: RootState) => ({
	isSmallScreen: isSmallScreen(state),
	bodyWidth: state.ui.layout.bodySize.width,
	isSidebarOpen: state.ui.layout.isSidebarOpen,
});

const mapDispatchToProps = {
	toggleSidebar: toggleSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarProvider);

export function useSidebarContext(): SidebarContextProps {
	const context = useContext(SidebarContext);

	if (typeof context === "undefined") {
		throw new Error(
			"useSidebarContext should be used within the SidebarContext provider!",
		);
	}

	return context;
}
