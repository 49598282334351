import { Dropdown } from "flowbite-react";
import { forgetSession } from "src/app/store/features/user/user.actions";
import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function UserDropdown(props: Props) {

	const { t } = useTranslation();

	const {
		logout,
		userName,
	} = props;

	const navigate = useNavigate();

	return (
		<div className="[&>button]:!ring-0 [&>button]:rounded [&>button]:p-[1px] [&>button>span]:px-2 [&>button>span]:py-1 [&>button]:!bg-gray-100 [&>button]:text-gray-600">
			<Dropdown
				className="overflow-hidden"
				label={ userName }
			>
				<Dropdown.Item onClick={ () => navigate("/version") }>
					{ t("NAVBAR.version") }
				</Dropdown.Item>
				<Dropdown.Item onClick={ logout }>
					{ t("NAVBAR.logout") }
				</Dropdown.Item>
			</Dropdown>
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	userName: state.user.userName,
});

const mapDispatchToProps = {
	logout: forgetSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDropdown);
