import { Button, Card } from "flowbite-react";
import { SettingsRecording } from "src/app/types/api/settings.types";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateNumberField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import { useEffect } from "react";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import Input from "src/app/components/Form/Input.component";
import Select from "src/app/components/Form/Select.component";
import { mapEnumSettingToSelectOptions } from "src/app/utils/helpers";
import { waveformFormatDictionary } from "src/app/utils/constants/dictionaries";
import { isNull } from "src/app/utils/typeguards";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsRecording: SettingsRecording
	};

type RecordingEventsForm = {
	eventsHysteresis: string
	minEventsHysteresis: number
	maxEventsHysteresis: number
	waveformFormat: number
	waveformRecDuration: string
	minWaveformRecDuration: number
	maxWaveformRecDuration: number
	varOscDurationPretriggerOffset: number
	waveformPretriggerDuration: string
	minWaveformPretriggerDuration: number
	maxWaveformPretriggerDuration: number
	rms12RecTime: string
	minRms12RecTime: number
	maxRms12RecTime: number
	rms12PretriggerTime: string
	minRms12PretriggerTime: number
	maxRms12PretriggerTime: number
}

const validator: FormValidator<RecordingEventsForm> = {
	eventsHysteresis: (eventsHysteresis, optional, form) => validateNumberField(i18n.t("RECORDINGS.hysteresis"), eventsHysteresis, optional, "she", { from: form.minEventsHysteresis.value, to: form.maxEventsHysteresis.value, decimalPlaces: 2 }),
	minEventsHysteresis: () => null,
	maxEventsHysteresis: () => null,
	waveformFormat: () => null,
	waveformRecDuration: (waveformRecDuration, optional, form) => validateNumberField(i18n.t("RECORDINGS.waveform recording time"), waveformRecDuration, optional, "he", { from: form.minWaveformRecDuration.value, to: form.maxWaveformRecDuration.value, decimalPlaces: 2 }),
	minWaveformRecDuration: () => null,
	maxWaveformRecDuration: () => null,
	varOscDurationPretriggerOffset: () => null,
	waveformPretriggerDuration: (waveformPretriggerDuration, optional, form) => {
		return validateNumberField(i18n.t("RECORDINGS.waveform pretrigger time"), waveformPretriggerDuration, optional, "he", { from: form.minWaveformPretriggerDuration.value, to: Math.min(form.maxWaveformPretriggerDuration.value, Math.max(+form.waveformRecDuration.value - form.varOscDurationPretriggerOffset.value, form.minWaveformPretriggerDuration.value)), decimalPlaces: 2 });
	},
	minWaveformPretriggerDuration: () => null,
	maxWaveformPretriggerDuration: () => null,
	rms12RecTime: (rms12RecTime, optional, form) => validateNumberField(i18n.t("RECORDINGS.RMS 1/2 recording time"), rms12RecTime, optional, "he", { from: form.minRms12RecTime.value, to: form.maxRms12RecTime.value, decimalPlaces: 2 }),
	minRms12RecTime: () => null,
	maxRms12RecTime: () => null,
	rms12PretriggerTime: (rms12PretriggerTime, optional, form) => validateNumberField(i18n.t("RECORDINGS.RMS 1/2 pretrigger time"), rms12PretriggerTime, optional, "he", { from: form.minRms12PretriggerTime.value, to: form.maxRms12PretriggerTime.value, decimalPlaces: 2 }),
	minRms12PretriggerTime: () => null,
	maxRms12PretriggerTime: () => null,
};

function RecordingEventsCard(props: Props) {

	const { t } = useTranslation();

	const {
		settingsRecording,
		isAdmin,
	} = props;

	const reducerForm = useReducerForm(
		"buffor",
		bufforFormActions,
		() => undefined,
	);

	const _handleSubmit = (values: RecordingEventsForm) => {
		reducerForm.handleChange("eventsHysteresis", values.eventsHysteresis);
		reducerForm.handleChange("waveformFormat", values.waveformFormat);
		reducerForm.handleChange("waveformRecDuration", values.waveformRecDuration);
		reducerForm.handleChange("waveformPretriggerDuration", values.waveformPretriggerDuration);
		reducerForm.handleChange("rms12RecTime", values.rms12RecTime);
		reducerForm.handleChange("rms12PretriggerTime", values.rms12PretriggerTime);
	};

	const _getInitialState = () => ({
		eventsHysteresis: createFormField((reducerForm.form.eventsHysteresis.value !== reducerForm.form.eventsHysteresis.initialValue) ? reducerForm.form.eventsHysteresis.value : settingsRecording?.recording?.cfgEventsHysteresis?.value?.toFixed(2) ?? "", { disabled: !isAdmin }),
		minEventsHysteresis: createFormField(settingsRecording?.recording?.cfgEventsHysteresis?.minValue ?? 0.1),
		maxEventsHysteresis: createFormField(settingsRecording?.recording?.cfgEventsHysteresis?.maxValue ?? 9.99),
		waveformFormat: createFormField((reducerForm.form.waveformFormat.value !== reducerForm.form.waveformFormat.initialValue) ? reducerForm.form.waveformFormat.value : settingsRecording?.recording?.cfgWaveformFormat?.value ?? 0, { disabled: !isAdmin }),
		waveformRecDuration: createFormField((reducerForm.form.waveformRecDuration.value !== reducerForm.form.waveformRecDuration.initialValue) ? reducerForm.form.waveformRecDuration.value : settingsRecording?.recording?.cfgWaveformRecDuration?.value?.toFixed(0) ?? "", { disabled: !isAdmin }),
		minWaveformRecDuration: createFormField(settingsRecording?.recording?.cfgWaveformRecDuration?.minValue ?? 100),
		maxWaveformRecDuration: createFormField(settingsRecording?.recording?.cfgWaveformRecDuration?.maxValue ?? 60_000),
		waveformPretriggerDuration: createFormField((reducerForm.form.waveformPretriggerDuration.value !== reducerForm.form.waveformPretriggerDuration.initialValue) ? reducerForm.form.waveformPretriggerDuration.value : settingsRecording?.recording?.cfgWaveformPretriggerDuration?.value?.toFixed(0) ?? "", { disabled: !isAdmin }),
		minWaveformPretriggerDuration: createFormField(settingsRecording?.recording?.cfgWaveformPretriggerDuration?.minValue ?? 40),
		maxWaveformPretriggerDuration: createFormField(settingsRecording?.recording?.cfgWaveformPretriggerDuration?.maxValue ?? 960),
		varOscDurationPretriggerOffset: createFormField(settingsRecording?.recording?.varOscDurationPretriggerOffset?.value ?? 0),
		rms12RecTime: createFormField((reducerForm.form.rms12RecTime.value !== reducerForm.form.rms12RecTime.initialValue) ? reducerForm.form.rms12RecTime.value : settingsRecording?.recording?.cfgRms12RecTime?.value?.toFixed(0) ?? "", { disabled: !isAdmin }),
		minRms12RecTime: createFormField(settingsRecording?.recording?.cfgRms12RecTime?.minValue ?? 1_000),
		maxRms12RecTime: createFormField(settingsRecording?.recording?.cfgRms12RecTime?.maxValue ?? 60_000),
		rms12PretriggerTime: createFormField((reducerForm.form.rms12PretriggerTime.value !== reducerForm.form.rms12PretriggerTime.initialValue) ? reducerForm.form.rms12PretriggerTime.value : settingsRecording?.recording?.cfgRms12PretriggerTime?.value?.toFixed(0) ?? "", { disabled: !isAdmin }),
		minRms12PretriggerTime: createFormField(settingsRecording?.recording?.cfgRms12PretriggerTime?.minValue ?? 100),
		maxRms12PretriggerTime: createFormField(settingsRecording?.recording?.cfgRms12PretriggerTime?.maxValue ?? 5_000),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ settingsRecording ]);

	useEffect(() => {
		handleChange("eventsHysteresis", reducerForm.form.eventsHysteresis.value);
		handleChange("waveformFormat", reducerForm.form.waveformFormat.value);
		handleChange("waveformRecDuration", reducerForm.form.waveformRecDuration.value);
		handleChange("waveformPretriggerDuration", reducerForm.form.waveformPretriggerDuration.value);
		handleChange("rms12RecTime", reducerForm.form.rms12RecTime.value);
		handleChange("rms12PretriggerTime", reducerForm.form.rms12PretriggerTime.value);
	}, [
		reducerForm.form.eventsHysteresis.value,
		reducerForm.form.waveformFormat.value,
		reducerForm.form.waveformRecDuration.value,
		reducerForm.form.waveformPretriggerDuration.value,
		reducerForm.form.rms12RecTime.value,
		reducerForm.form.rms12PretriggerTime.value,
	]);

	return (
		<Card>
			<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
				{ t("RECORDINGS.events") }
			</h5>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<Input
					formItem={ form.eventsHysteresis }
					label={ t("RECORDINGS.hysteresis") + " [%]" }
					name="Hysteresis"
					inputProps={ {
						type: "number",
						onChange: (e) => handleChange("eventsHysteresis", e.target.value),
						onBlur: () => handleBlur("eventsHysteresis"),
					} }
					hasBeenChanged={ reducerForm.form.eventsHysteresis.value !== reducerForm.form.eventsHysteresis.initialValue }
				/>
				<Select
					label={ t("RECORDINGS.waveform format") }
					options={ mapEnumSettingToSelectOptions(settingsRecording?.recording?.cfgWaveformFormat, waveformFormatDictionary) }
					formItem={ form.waveformFormat }
					isDisabled
					onChange={ option => {
						if (isNull(option)) return;

						handleChange("waveformFormat", option?.value);
						handleBlur("waveformFormat");
					} }
					isSearchable={ false }
					isClearable={ false }
					hasBeenChanged={ reducerForm.form.waveformFormat.value !== reducerForm.form.waveformFormat.initialValue }
				/>
				<Input
					formItem={ form.waveformRecDuration }
					label={ `${ t("RECORDINGS.waveform recording time") } [${ settingsRecording?.recording?.cfgWaveformRecDuration?.unit ?? "---" }]` }
					name="waveformRecDuration"
					inputProps={ {
						type: "number",
						step: 1,
						onChange: (e) => handleChange("waveformRecDuration", e.target.value),
						onBlur: () => {
							handleBlur("waveformRecDuration");
							handleBlur("waveformPretriggerDuration");
						},
					} }
					hasBeenChanged={ reducerForm.form.waveformRecDuration.value !== reducerForm.form.waveformRecDuration.initialValue }
				/>
				<Input
					formItem={ form.waveformPretriggerDuration }
					label={ `${ t("RECORDINGS.waveform pretrigger time") } [${ settingsRecording?.recording?.cfgWaveformPretriggerDuration?.unit ?? "---" }]` }
					name="waveformPretriggerDuration"
					inputProps={ {
						type: "number",
						step: 1,
						onChange: (e) => handleChange("waveformPretriggerDuration", e.target.value),
						onBlur: () => {
							handleBlur("waveformRecDuration");
							handleBlur("waveformPretriggerDuration");
						},
					} }
					hasBeenChanged={ reducerForm.form.waveformPretriggerDuration.value !== reducerForm.form.waveformPretriggerDuration.initialValue }
				/>
				<Input
					formItem={ form.rms12RecTime }
					label={ `${ t("RECORDINGS.RMS 1/2 recording time") } [${ settingsRecording?.recording?.cfgRms12RecTime?.unit ?? "---" }]` }
					name="rms12RecTime"
					inputProps={ {
						type: "number",
						step: 1,
						onChange: (e) => handleChange("rms12RecTime", e.target.value),
						onBlur: () => handleBlur("rms12RecTime"),
					} }
					hasBeenChanged={ reducerForm.form.rms12RecTime.value !== reducerForm.form.rms12RecTime.initialValue }
				/>
				<Input
					formItem={ form.rms12PretriggerTime }
					label={ `${ t("RECORDINGS.RMS 1/2 pretrigger time") } [${ settingsRecording?.recording?.cfgRms12PretriggerTime?.unit ?? "---" }]` }
					name="rms12PretriggerTime"
					inputProps={ {
						type: "number",
						step: 1,
						onChange: (e) => handleChange("rms12PretriggerTime", e.target.value),
						onBlur: () => handleBlur("rms12PretriggerTime"),
					} }
					hasBeenChanged={ reducerForm.form.rms12PretriggerTime.value !== reducerForm.form.rms12PretriggerTime.initialValue }
				/>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(RecordingEventsCard);
