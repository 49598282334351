import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "src/app/translations/en.json";
import translationPL from "src/app/translations/pl.json";
import translationES from "src/app/translations/es.json";
import translationDE from "src/app/translations/de.json";
import { CountryCode } from "src/app/types/misc.types";

i18n
	.use(initReactI18next)
	.init({
		resources: {
			[ CountryCode.GB ]: { translation: translationEN },
			[ CountryCode.PL ]: { translation: translationPL },
			[ CountryCode.ES ]: { translation: translationES },
			[ CountryCode.DE ]: { translation: translationDE },
		},
		lng: CountryCode.GB,
		fallbackLng: CountryCode.GB,

		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
