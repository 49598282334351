import { Form, FormComparator, FormHookReturnType, FormItem, FormItemError, FormValidator } from "src/app/types/ui/form.types";
import { FormEvent, useContext, useEffect, useState } from "react";
import { getAllFormValues, isFormValid, retranslateFormErros, validateForm } from "src/app/utils/forms";
import { isNotNull } from "src/app/utils/typeguards";
import { CallbackPromptContext } from "src/app/hoc/providers/CallbackPrompt.provider";
import { useSelector } from "react-redux";
import { RootState } from "src/app/store/root.reducer";

function useForm<T>(
	initialForm: Form<T>,
	validator: FormValidator<T>,
	handleSubmit: (form: T) => void,
	comparator: FormComparator<T> = {},
	ignoreDisabled = false,
): FormHookReturnType<T> {
	const [ form, setForm ] = useState<Form<T>>(initialForm);
	const [ isSubmitted, toggleSubmitted ] = useState(false);

	const appLanguage = useSelector((state: RootState) => state.misc.language);

	useEffect(() => {
		setForm(prevState => retranslateFormErros(prevState, validator));
	}, [ appLanguage ]);

	const _handleChange = (prop: keyof T, value: any) => {
		setForm(prevState => {
			if (ignoreDisabled ? false : prevState[ prop ].disabled) return prevState;

			return {
				...prevState,
				[ prop ]: {
					...prevState[ prop ],
					touched: true,
					value: value,
				},
			};
		});
	};

	const _handleBlur = (prop: keyof T) => {
		setForm(prevState => {
			const formItem = prevState[ prop ];
			if (ignoreDisabled ? formItem.touched : (!formItem.disabled && formItem.touched)) {
				const error = validator[ prop ](formItem.value as never, formItem.optional, prevState);

				return {
					...prevState,
					[ prop ]: {
						...prevState[ prop ],
						error: error,
						success: error === null,
					},
				};
			}

			return prevState;
		});
	};

	const _handleSubmit = (e?: FormEvent<HTMLFormElement>) => {
		isNotNull(e) && e.preventDefault();
		const newForm = validateForm(form, validator, ignoreDisabled);
		setForm(newForm);
		if (isFormValid(newForm)) {
			toggleSubmitted(true);
			handleSubmit(getAllFormValues(newForm));
		}
	};

	const _toggleDisable = (prop: keyof T, isDisabled: boolean) => {
		setForm(prevState => ({
			...prevState,
			[ prop ]: {
				...prevState[ prop ],
				disabled: isDisabled,
			},
		}));
	};

	const _setError = (prop: keyof T, error: FormItemError) => {
		setForm(prevState => ({
			...prevState,
			[ prop ]: {
				...prevState[ prop ],
				error: error,
			},
		}));
	};

	const _resetForm = () => {
		setForm(initialForm);
	};

	const _isFormEdited = () =>
		Object
			.entries<FormItem<any>>(form)
			.some(([ key, entry ]) => {
				const typedKey = key as keyof T;
				const comparatorFunction = comparator[ typedKey ] ?? ((a, b) => a === b);
				return !comparatorFunction(entry.value, entry.initialValue);
			});

	useEffect(() => {
		if (_isFormEdited() && isSubmitted) {
			toggleSubmitted(false);
		}
	}, [ _isFormEdited() ]);

	const callbackPromptContext = useContext(CallbackPromptContext);

	useEffect(() => {
		if (isNotNull(callbackPromptContext?.toggleComponentEdited)) {
			callbackPromptContext.toggleComponentEdited(Object.keys(form).join(), !isSubmitted && _isFormEdited());
		}
	}, [ !isSubmitted && _isFormEdited() ]);

	return {
		form,
		setForm,
		handleChange: _handleChange,
		handleBlur: _handleBlur,
		handleSubmit: _handleSubmit,
		toggleDisable: _toggleDisable,
		setError: _setError,
		resetForm: _resetForm,
		isFormEdited: !isSubmitted && _isFormEdited(),
		// Solution for redirecting after submit
		// and not working when backend returns error
		// (then frontend will allow to redirect to another page without alert)
	};
}

export default (useForm);
