// @ts-ignore
import { isNotDefined } from "react-stockcharts/lib/utils";
// @ts-ignore
import GenericComponent, { getMouseCanvas } from "react-stockcharts/lib/GenericComponent";
import { Component } from "react";
import { Nullable } from "src/app/types/util.types";
import { isNotNull, isNull } from "src/app/utils/typeguards";

type Props<T> = {
	yAccessor: (d: T) => Nullable<number>
	fill: string
	r?: number
}

function ChartCurrentCoordinate<T>(props: Props<T>) {

	const { r = 3 } = props;

	return (
		<CurrentCoordinate { ...props } r={ r }/>
	);
}

class CurrentCoordinate extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.renderSVG = this.renderSVG.bind(this);
		this.drawOnCanvas = this.drawOnCanvas.bind(this);
	}

	drawOnCanvas(ctx: any, moreProps: any) {
		const circle = helper(this.props, moreProps);
		if (!circle) return null;

		ctx.fillStyle = circle.fill;
		ctx.beginPath();
		ctx.arc(circle.x, circle.y, circle.r, 0, 2 * Math.PI, false);
		ctx.fill();
	}

	renderSVG(moreProps: any) {
		const { className } = this.props;

		const circle = helper(this.props, moreProps);
		if (!circle) return null;

		const fillColor = circle.fill instanceof Function ? circle.fill(moreProps.currentItem) : circle.fill;

		return (
			<circle className={ className } cx={ circle.x } cy={ circle.y } r={ circle.r } fill={ fillColor }/>
		);
	}

	render() {
		return <GenericComponent
			svgDraw={ this.renderSVG }
			canvasDraw={ this.drawOnCanvas }
			canvasToDraw={ getMouseCanvas }
			drawOn={ [ "mousemove", "pan" ] }
		/>;
	}
}

function helper(props: any, moreProps: any) {
	const { fill, yAccessor, r } = props;
	const { plotData } = moreProps;

	const { show, xScale, chartConfig: { yScale }, currentItem, xAccessor } = moreProps;

	if (!show || isNotDefined(currentItem)) return null;

	const updatedCurrentItem = plotData.find((dataItem: any) => xAccessor(dataItem) === xAccessor(currentItem));

	if (isNull(updatedCurrentItem)) return null;

	const xValue = xAccessor(updatedCurrentItem);
	const yValue = yAccessor(updatedCurrentItem);

	if (isNotDefined(yValue)) return null;

	const x = Math.round(isNotNull(xScale) ? xScale(xValue) : xValue);
	const y = Math.round(isNotNull(yScale) ? yScale(yValue) : yValue);

	return { x, y, r, fill };
}

export default (ChartCurrentCoordinate);
