import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import WaveformContainer from "src/app/containers/Realtime/Waveform.container";
import useWebSocket, { WebSocketUri } from "src/app/utils/hooks/useWebSocket";
import { DataWaveformsLive } from "src/app/types/api/ws.types";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function WaveformView(props: Props) {

	const [ lastRecord ] = useWebSocket<DataWaveformsLive>(
		WebSocketUri.DATA_WAVEFORMS,
		true,
	);

	return (
		<WaveformContainer
			lastRecord={ lastRecord }
		/>
	);
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WaveformView);
