import { Button, Modal } from "flowbite-react";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { createFormField, validateField, validatePassword } from "src/app/utils/forms";
import { Form, FormValidator } from "src/app/types/ui/form.types";
import useForm from "src/app/utils/hooks/useForm";
import Input from "src/app/components/Form/Input.component";
import { useEffect } from "react";
import { CreateUserPayload } from "src/app/types/api/user.types";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		isOpen: boolean
		handleClose: () => void
		handleCreate: (payload: CreateUserPayload) => void
	};

type CreateUserModalForm = {
	name: string
	password: string
}

const validator: FormValidator<CreateUserModalForm> = {
	name: (name, optional) => validateField(i18n.t("USERS.name"), name, optional, "she"),
	password: (password) => validatePassword(password),
};

function CreateUserModal(props: Props) {

	const { t } = useTranslation();

	const {
		isOpen,
		handleClose,
		isUpdating,
		handleCreate,
	} = props;

	const _handleSubmit = (values: CreateUserModalForm) => {
		handleCreate({
			user_name: values.name,
			user_password: values.password,
		});
		handleClose();
	};

	const _getInitialState = (): Form<CreateUserModalForm> => ({
		name: createFormField(""),
		password: createFormField(""),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
	} = useForm(
		_getInitialState(),
		validator,
		_handleSubmit,
	);

	useEffect(() => {
		if (isOpen) {
			setForm(_getInitialState());
		}
	}, [ isOpen, isUpdating ]);

	return (
		<Modal
			show={ isOpen || isUpdating }
			onClose={ handleClose }
		>
			<Modal.Header>{ t("USERS.create user") }</Modal.Header>
			<form noValidate onSubmit={ handleSubmit }>
				<Modal.Body>
					<div className="flex flex-col gap-4">
						<Input
							formItem={ form.name }
							label={ t("USERS.user name") }
							name="name"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("name", e.target.value),
								onBlur: () => handleBlur("name"),
							} }
						/>
						<Input
							formItem={ form.password }
							label={ t("USERS.user password") }
							name="password"
							inputProps={ {
								type: "password",
								onChange: (e) => handleChange("password", e.target.value),
								onBlur: () => handleBlur("password"),
							} }
						/>
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-between gap-2">
					<Button color="gray" onClick={ handleClose }>{ t("USERS.cancel") }</Button>
					<Button color="primary" type="submit" isProcessing={ isUpdating }>
						{ t("USERS.save") }
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

const mapStateToProps = (state: RootState) => ({
	isUpdating: didLoadingRecordExist(state, { loadableType: LoadableType.CREATE_USER }),
});

export default connect(mapStateToProps)(CreateUserModal);