import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { handleBasicActions, initialStateReducer } from "src/app/utils/redux";
import { DataState, ErrorState, LoadingState, StateReducer } from "src/app/types/redux.types";
import { JwtToken, LoggedUser, Users } from "src/app/types/api/user.types";
import { checkAuthToken, fetchUsersAsync, forgetSession, loginAsync } from "src/app/store/features/user/user.actions";
import moment from "moment";
import { jwtDecode } from "jwt-decode";

const reducer = combineReducers({
	loggedUser: createReducer(initialStateReducer as StateReducer<LoggedUser>)
		.handleAction([ loginAsync.success ], (_, action) => ({
			dataState: DataState.PRESENT,
			errorState: ErrorState.NOT_PRESENT,
			loadingState: LoadingState.NOT_LOADING,
			data: {
				jwt: action.payload.data.token,
			},
			fetchedAt: moment(),
		}))
		.handleAction([ forgetSession ], () => initialStateReducer),
	users: createReducer(initialStateReducer as StateReducer<Users>)
		.handleAction([ fetchUsersAsync.request, fetchUsersAsync.success, fetchUsersAsync.failure ], handleBasicActions(fetchUsersAsync))
		.handleAction([ forgetSession ], () => initialStateReducer),
	userName: createReducer("")
		.handleAction([ loginAsync.success ], (_, action) => jwtDecode<JwtToken>(action.payload.data.token).user_name)
		.handleAction([ checkAuthToken ], (_, action) => jwtDecode<JwtToken>(action.payload).user_name)
		.handleAction([ forgetSession ], () => ""),
	isAdmin: createReducer(false)
		.handleAction([ loginAsync.success ], (_, action) => jwtDecode<JwtToken>(action.payload.data.token).is_admin)
		.handleAction([ checkAuthToken ], (_, action) => jwtDecode<JwtToken>(action.payload).is_admin)
		.handleAction([ forgetSession ], () => false),
});

export default reducer;
