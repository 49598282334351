import { RouteObject } from "react-router/dist/lib/context";
import TimeplotView from "src/app/views/Realtime/Timeplot.view";
import WaveformView from "src/app/views/Realtime/Waveform.view";
import HarmonicView from "src/app/views/Realtime/Harmonic.view";
import PhasorView from "src/app/views/Realtime/Phasor.view";
import InterharmonicView from "src/app/views/Realtime/Interharmonic.view";
import MeasurementsView from "src/app/views/Realtime/Measurements.view";

const realtimeRoutes: RouteObject = {
	path: "/realtime",
	children: [
		{
			path: "measurements",
			element: <MeasurementsView/>,
		}, {
			path: "timeplot",
			element: <TimeplotView/>,
		}, {
			path: "harmonics",
			element: <HarmonicView/>,
		}, {
			path: "interharmonics",
			element: <InterharmonicView/>,
		}, {
			path: "waveforms",
			element: <WaveformView/>,
		}, {
			path: "phasors",
			element: <PhasorView/>,
		},
	],
};

export default realtimeRoutes;
