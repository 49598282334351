import React, { PropsWithChildren } from "react";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { FormItemError } from "src/app/types/ui/form.types";
import { Button, Label, LabelProps } from "flowbite-react";
import { DropzoneOptions, ErrorCode, FileWithPath, useDropzone } from "react-dropzone";
import classNames from "classnames";
import { SnackbarMessageType } from "src/app/types/ui/message.types";
import SnackbarComponent from "src/app/components/Message/Snackbar.component";
import { HiDocumentPlus } from "react-icons/hi2";
import { useTranslation } from "react-i18next";

type Props = {
	className?: string
	labelProps?: LabelProps
	label?: string
	buttonText: string
	onChange: (file: FileWithPath) => void
	name: string // Friendly name for HTML Semantic
	error?: FormItemError
	options?: Omit<DropzoneOptions, "onDrop">
	accept?: string
}

function FileInput(props: PropsWithChildren<Props>) {

	const { t } = useTranslation();

	const {
		className,
		labelProps,
		label,
		buttonText,
		onChange,
		name,
		error,
		options,
		accept,
	} = props;

	const _onDrop = (acceptedFiles: FileWithPath[]) => {
		const file = acceptedFiles[ 0 ];

		if (isNull(file)) return;

		onChange(file);
	};

	const {
		getRootProps,
		getInputProps,
		isDragAccept,
		isDragReject,
		fileRejections,
	} = useDropzone({
		onDrop: _onDrop,
		maxSize: 300 * 1024 * 1024, // 300 MB
		maxFiles: 1,
		...options,
	});

	return (
		<div
			className={
				classNames(
					"flex flex-col gap-y-2",
					className,
				)
			}
		>
			{
				isNotNull(label) &&
                <Label
                    htmlFor={ name }
					{ ...labelProps }
                >
					{ label }
                </Label>
			}
			<div
				{
					...getRootProps({
						className: classNames(
							"relative flex items-center px-4 py-6 border-2 rounded-lg border-dashed border-gray-400 cursor-pointer transition duration-100 ease-in-out bg-transparent",
							{ "border-green-600": isDragAccept || isDragReject },
						),
					})
				}
				onClick={ () => undefined }
			>
				<input
					{ ...getInputProps() }
					id="file-input"
					className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer !block"
					accept={ accept }
					name={ name }
					type="file"
				/>
				<HiDocumentPlus className="w-10 h-10 text-gray-400"/>
				<div className="flex flex-col ml-3 gap-1 mr-3">
					<div className="text-sm dark:text-white">{t('SETTINGS.upload files')} ({ accept })</div>
					<div className="text-xs text-gray-400">{t('SETTINGS.max size 300 MB')}</div>
				</div>
				<Button
					color="light"
					className="ml-auto"
					onClick={ () => document.getElementById("file-input")?.click() }
				>
					{ buttonText }
				</Button>
			</div>
			{
				(isNotNull(error) && fileRejections.length === 0) &&
                <SnackbarComponent
                    type={ SnackbarMessageType.ERROR }
                    message={ error }
                />
			}
			{
				fileRejections.some(fileRejected => fileRejected.errors.some(error => error.code === ErrorCode.FileInvalidType)) &&
                <SnackbarComponent
                    type={ SnackbarMessageType.ERROR }
                    message={ `${t('SETTINGS.file extension must be')} ${ accept }` }
                />
			}
			{
				fileRejections.some(fileRejected => fileRejected.errors.some(error => error.code === ErrorCode.FileTooLarge)) &&
                <SnackbarComponent
                    type={ SnackbarMessageType.ERROR }
                    message={t('SETTINGS.file is larger than 300MB')}
                />
			}
			{
				fileRejections.some(fileRejected => fileRejected.errors.some(error => error.code === ErrorCode.TooManyFiles)) &&
                <SnackbarComponent
                    type={ SnackbarMessageType.ERROR }
                    message={t('SETTINGS.please select only one file')}
                />
			}
		</div>
	);
}

export default (FileInput);
