import { RootEpic } from "src/app/store/root.epic";
import { isActionOf } from "typesafe-actions";
import { applicationStart, changeLanguage, empty, fetchBackendVersionAsync } from "src/app/store/features/misc/misc.actions";
import { filter, map, mergeMap } from "rxjs/operators";
import { merge, of } from "rxjs";
import { uiAuthMe } from "src/app/store/features/ui/user/ui.user.actions";
import { DataState } from "src/app/types/redux.types";
import { removeLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.actions";
import { authLoadingRecord, settingsLoadingRecords } from "src/app/store/features/ui/loading/ui.loading.reducer";
import { checkAuthToken } from "src/app/store/features/user/user.actions";
import i18n from "src/app/translations/i18n";
import { apiAsync } from "src/app/store/features/api/api.actions";

export const applicationStartEpic: RootEpic = (action$, state$) =>
	action$.pipe(
		filter(isActionOf(applicationStart)),
		mergeMap(_ => {
			// window.onload = () => store.dispatch(registerActivity());
			// document.onmousemove = () => store.dispatch(registerActivity());
			// document.onkeydown = () => store.dispatch(registerActivity());
			if (state$.value.user.loggedUser.dataState === DataState.PRESENT) {
				return merge(
					of(uiAuthMe()),
					of(changeLanguage(state$.value.misc.language)),
					of(checkAuthToken(state$.value.user.loggedUser.data.jwt)),
				);
			} else {
				return merge(
					of(removeLoadingRecord(authLoadingRecord)),
					of(changeLanguage(state$.value.misc.language)),
					...settingsLoadingRecords.map(record => of(removeLoadingRecord(record))),
				);
			}
		}),
	);

export const changeLanguageEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(changeLanguage)),
		map(action => {
			i18n.changeLanguage(action.payload);
			return empty();
		}),
	);

// export const registerActivityEpic: RootEpic = action$ =>
// 	action$.pipe(
// 		filter(isActionOf(registerActivity)),
// 		delay(5000),
// 		switchMap(() => {
// 			console.log("aaaaaa");
// 			return of(empty());
// 		}),
// 	);

export const fetchBackendVersionEpic: RootEpic = action$ =>
	action$.pipe(
		filter(action => isActionOf(applicationStart, action) || isActionOf(fetchBackendVersionAsync.request, action)),
		map(_ =>
			apiAsync.request({
				url: "/version",
				method: "GET",
				onSuccess: fetchBackendVersionAsync.success,
				onFailure: fetchBackendVersionAsync.failure,
			}),
		),
	);

// export const sendErrorEpic: RootEpic = action$ =>
// 	action$.pipe(
// 		filter(isActionOf(sendErrorAsync.request)),
// 		map(action =>
// 			apiAsync.request({
// 				url: "/error/frontend-log",
// 				method: "POST",
// 				data: action.payload,
// 				onSuccess: sendErrorAsync.success,
// 				onFailure: sendErrorAsync.failure,
// 			}),
// 		),
// 	);
