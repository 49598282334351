import { RootEpic } from "src/app/store/root.epic";
import { filter, map } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { loginAsync } from "src/app/store/features/user/user.actions";
import { empty } from "src/app/store/features/misc/misc.actions";

export const observeLoggedUserEventsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(loginAsync.success)),
		map(action => {
			// if (isActionOf(loginAsync.success, action) || isActionOf(authMeAsync.success, action)) {
			// const laravelEchoClient: Echo = state$.value.ws.laravelEchoClient;
			// const loggedUserId = action.payload.data.id;

			// laravelEchoClient.leaveAllChannels();

			/*laravelEchoClient
				.private(`App.Models.User.${ loggedUserId }`)
				.notification((notification: EchoNotification) => {
					store.dispatch(handleLoggedUserNotification(notification));
				});

			laravelEchoClient
				.private(`App.Models.User.${ loggedUserId }.Tasks`)
				.listen("TaskAssigneesChangedEvent", (event: unknown[]) => {
					store.dispatch(throttleFetchTasksByAssigneeId(loggedUserId));
				});

			laravelEchoClient
				.private(`App.Models.User.${ loggedUserId }.Warnings`)
				.listen("WarningChangedEvent", (event: unknown[]) => {
					store.dispatch(throttleFetchUserWarnings(loggedUserId));
				});*/
			// }
			return empty();
		}),
	);

/*
export const throttleFetchUserWarningsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(throttleFetchUserWarnings)),
		throttle(() => interval(5000), { leading: true, trailing: true }),
		map(action => fetchUsersWarningsAsync.request(action.payload)),
	);*/
