import { DataState, ErrorState, LoadingState } from "src/app/types/redux.types";
import { useEffect } from "react";
import { isNotNull } from "src/app/utils/typeguards";
import { GridLoader } from "react-spinners";
import ErrorWrapper from "src/app/components/Message/ErrorWrapper.component";
import SnackbarComponent from "src/app/components/Message/Snackbar.component";
import { SnackbarMessageType } from "src/app/types/ui/message.types";
import { StrategyProps } from "src/app/types/ui/caching.types";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";

function CachedThenFreshStrategy<T>(props: StrategyProps<T, (data: T, fetchedAt: moment.Moment, isLoading: boolean) => JSX.Element>) {

	const {
		request,
		unmount,
		state,
		children,
		useEffectDependency,
		withoutLoading = false,
	} = props;

	const { t } = useTranslation();

	useEffect(() => {
		request();

		return () => {
			isNotNull(unmount) && unmount();
		};
	}, [ useEffectDependency ]);

	if (state.dataState === DataState.PRESENT) {
		if (state.errorState === ErrorState.NOT_PRESENT) {
			return children(state.data, state.fetchedAt, state.loadingState === LoadingState.LOADING);
		} else {
			return (
				<div className="flex flex-col gap-4">
					<SnackbarComponent
						className="shadow"
						type={ SnackbarMessageType.WARNING }
						message={ t("UTILS.failed to fetch") }
					/>
					{ children(state.data, state.fetchedAt, state.loadingState === LoadingState.LOADING) }
				</div>
			);
		}
	} else {
		if (state.errorState === ErrorState.PRESENT) {
			const error = state.errors[ 0 ];
			return (
				<ErrorWrapper
					message={ error.message }
					buttonText={ t("UTILS.try again") }
					onButtonClick={ () => request() }
					isLoading={ state.loadingState === LoadingState.LOADING }
				/>
			);
		} else if (!withoutLoading) {
			return (
				<div className="w-full h-full flex items-center justify-center">
					<GridLoader size={ 20 } color="#0093DD"/>
				</div>
			);
		} else {
			return null;
		}
	}
}

export default CachedThenFreshStrategy;
