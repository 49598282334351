import { RootEpic } from "src/app/store/root.epic";
import { isActionOf } from "typesafe-actions";
import { fetchFirmwareInfoAsync, pingFirmwareInfoAsync, removeFirmwareFileAsync, upgradeFirmwareAsync, uploadFirmwareAsync } from "src/app/store/features/firmware/firmware.actions";
import { apiAsync } from "src/app/store/features/api/api.actions";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { displayToast } from "src/app/store/features/message/message.actions";
import { ToastType } from "src/app/types/ui/message.types";
import i18n from "src/app/translations/i18n";

export const fetchFirmwareInfoEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchFirmwareInfoAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/firmware/info",
				method: "GET",
				onSuccess: fetchFirmwareInfoAsync.success,
				onFailure: fetchFirmwareInfoAsync.failure,
			}),
		),
	);

export const pingFirmwareInfoAsyncEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(pingFirmwareInfoAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/firmware/info",
				method: "GET",
				timeout: 10000,
				withoutNotification: true,
				onSuccess: pingFirmwareInfoAsync.success,
				onFailure: pingFirmwareInfoAsync.failure,
			}),
		),
	);

export const uploadFirmwareEpic: RootEpic = (action$, _, api) =>
	action$.pipe(
		filter(isActionOf(uploadFirmwareAsync.request)),
		mergeMap(action =>
			from(api.file.parseFileToBinary(action.payload.file))
				.pipe(
					map(parsedFile =>
						apiAsync.request({
							url: "/firmware/upload",
							method: "POST",
							data: parsedFile,
							withoutNotification: true,
							onUploadProgress: action.payload.onProgress,
							onSuccess: uploadFirmwareAsync.success,
							onFailure: uploadFirmwareAsync.failure,
						}),
					),
					catchError(() =>
						of(displayToast({ type: ToastType.ERROR, content: i18n.t("RESPONSES.parsing file error") })),
					),
				),
		),
	);

export const removeFirmwarefileEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(removeFirmwareFileAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/firmware/remove",
				method: "POST",
				onSuccess: removeFirmwareFileAsync.success,
				onFailure: removeFirmwareFileAsync.failure,
			}),
		),
	);

export const upgradeFirmwarefileEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(upgradeFirmwareAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/firmware/upgrade",
				method: "POST",
				onSuccess: upgradeFirmwareAsync.success,
				onFailure: upgradeFirmwareAsync.failure,
			}),
		),
	);
