import { Button } from "flowbite-react";

type Props = {
	message?: string
	buttonText: string
	onButtonClick: () => void
	isLoading?: boolean
};

function ErrorWrapper(props: Props) {

	const {
		message,
		buttonText,
		onButtonClick,
		isLoading,
	} = props;

	return (
		<div className="w-full h-full flex flex-col items-center justify-center">
			<h1 className="mb-6 text-2xl font-bold dark:text-white md:text-5xl">
				{ message }
			</h1>
			{/*<p className="mb-6 w-4/5 text-center text-lg text-gray-500 dark:text-gray-300">*/ }
			{/*	{ message }*/ }
			{/*</p>*/ }
			<Button
				color="primary"
				onClick={ onButtonClick }
				isProcessing={ isLoading }
			>
				{ buttonText }
			</Button>
		</div>
	);
}

export default (ErrorWrapper);
