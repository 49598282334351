import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import FirmwareContainer from "src/app/containers/Firmware/Firmware.container";
import { fetchFirmwareInfoAsync } from "src/app/store/features/firmware/firmware.actions";
import { uiRemoveFirmwareFile, uiUpgradeFirmware, uiUploadFirmware } from "src/app/store/features/ui/firmware/ui.firmware.actions";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function FirmwareView(props: Props) {

	const {
		fetchFirmwareInfo,
		firmwareInfo,
		uploadFirmware,
		removeFirmware,
		upgradeFirmware,
	} = props;

	const _handleUploadFirmware = (file: File) => {
		uploadFirmware(file);
	};

	return (
		<CachedThenFreshStrategy
			request={ fetchFirmwareInfo }
			state={ firmwareInfo }
		>
			{
				firmwareInfo =>
					<FirmwareContainer
						firmwareInfo={ firmwareInfo }
						uploadFirmware={ _handleUploadFirmware }
						removeFirmware={ removeFirmware }
						upgradeFirmware={ upgradeFirmware }
					/>
			}
		</CachedThenFreshStrategy>
	);
}

const mapStateToProps = (state: RootState) => ({
	firmwareInfo: state.firmware.firmwareInfo,
});

const mapDispatchToProps = {
	fetchFirmwareInfo: fetchFirmwareInfoAsync.request,
	uploadFirmware: uiUploadFirmware,
	removeFirmware: uiRemoveFirmwareFile,
	upgradeFirmware: uiUpgradeFirmware,
};

export default connect(mapStateToProps, mapDispatchToProps)(FirmwareView);
