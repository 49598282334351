import { combineReducers } from "redux";
import uiLoadingReducer from "src/app/store/features/ui/loading/ui.loading.reducer";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import uiLayoutReducer from "src/app/store/features/ui/layout/ui.layout.reducer";
import routerReducer from "src/app/store/features/ui/router/ui.router.reducer";

const persistLayoutConfig = {
	key: "layout",
	storage: storage,
	whitelist: [ "isDarkTheme" ],
};

const reducer = combineReducers({
	loading: uiLoadingReducer,
	layout: persistReducer(persistLayoutConfig, uiLayoutReducer),
	router: routerReducer,
});

export default reducer;
