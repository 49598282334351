import { RootEpic } from "src/app/store/root.epic";
import { filter, map } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { fetchSettingsFtpAsync, fetchSettingsIecAsync, fetchSettingsIecProfileAsync, fetchSettingsIoAsync, fetchSettingsMeasurementsStandardAsync, fetchSettingsMeasurementsUserAsync, fetchSettingsMemoryAsync, fetchSettingsModbusAsync, fetchSettingsRecordingAsync, fetchSettingsTimeAsync, setConfigurationAsync, testFtpConnectionAsync } from "src/app/store/features/settings/settings.actions";
import { apiAsync } from "src/app/store/features/api/api.actions";

export const fetchSettingsRecordingEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchSettingsRecordingAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/settings/recording",
				method: "GET",
				onSuccess: fetchSettingsRecordingAsync.success,
				onFailure: fetchSettingsRecordingAsync.failure,
			}),
		),
	);

export const fetchSettingsModbusEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchSettingsModbusAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/settings/communications/modbus",
				method: "GET",
				onSuccess: fetchSettingsModbusAsync.success,
				onFailure: fetchSettingsModbusAsync.failure,
			}),
		),
	);

export const fetchSettingsFtpEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchSettingsFtpAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/settings/communications/ftp",
				method: "GET",
				onSuccess: fetchSettingsFtpAsync.success,
				onFailure: fetchSettingsFtpAsync.failure,
			}),
		),
	);

export const fetchSettingsTimeEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchSettingsTimeAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/settings/time",
				method: "GET",
				onSuccess: fetchSettingsTimeAsync.success,
				onFailure: fetchSettingsTimeAsync.failure,
			}),
		),
	);

export const fetchSettingsMemoryEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchSettingsMemoryAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/settings/memory",
				method: "GET",
				onSuccess: fetchSettingsMemoryAsync.success,
				onFailure: fetchSettingsMemoryAsync.failure,
			}),
		),
	);

export const fetchSettingsMeasurementsUserEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchSettingsMeasurementsUserAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/settings/measurements/user",
				method: "GET",
				onSuccess: fetchSettingsMeasurementsUserAsync.success,
				onFailure: fetchSettingsMeasurementsUserAsync.failure,
			}),
		),
	);

export const fetchSettingsMeasurementsStandardEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchSettingsMeasurementsStandardAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/settings/measurements/standard",
				method: "GET",
				onSuccess: fetchSettingsMeasurementsStandardAsync.success,
				onFailure: fetchSettingsMeasurementsStandardAsync.failure,
			}),
		),
	);

export const fetchSettingsIoEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchSettingsIoAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/settings/io",
				method: "GET",
				onSuccess: fetchSettingsIoAsync.success,
				onFailure: fetchSettingsIoAsync.failure,
			}),
		),
	);

export const fetchSettingsIecEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchSettingsIecAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/settings/communications/iec61850",
				method: "GET",
				onSuccess: fetchSettingsIecAsync.success,
				onFailure: fetchSettingsIecAsync.failure,
			}),
		),
	);

export const fetchSettingsIecProfileEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchSettingsIecProfileAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/settings/communications/iec61850/profile",
				method: "GET",
				withoutNotification: true,
				onSuccess: fetchSettingsIecProfileAsync.success,
				onFailure: fetchSettingsIecProfileAsync.failure,
			}),
		),
	);

export const testFtpConnectionEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(testFtpConnectionAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/settings/communications/ftp/test",
				method: "POST",
				data: action.payload,
				onSuccess: testFtpConnectionAsync.success,
				onFailure: testFtpConnectionAsync.failure,
			}),
		),
	);

export const setRecordingEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(setConfigurationAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/set",
				method: "POST",
				data: action.payload,
				onSuccess: setConfigurationAsync.success,
				onFailure: setConfigurationAsync.failure,
			}),
		),
	);
