import { SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import { useCollapse } from "react-collapsed";
import { Card } from "flowbite-react";
import { HiChevronDown } from "react-icons/hi";
import classNames from "classnames";
import CurrentBasicTab from "src/app/components/MeasurementsUser/Current/CurrentBasicTab.component";
import { useTranslation } from "react-i18next";

type Props = {
	isExpanded: boolean
	handleCollapse: () => void
	settingsMeasurementsUser: SettingsMeasurementsUser
};

function CurrentCard(props: Props) {

	const { t } = useTranslation();

	const {
		isExpanded,
		handleCollapse,
		settingsMeasurementsUser,
	} = props;

	const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

	return (
		<Card className="col-span-2 [&>div]:p-0 [&>div]:gap-0">
			<div
				className="cursor-pointer p-3 sm:p-6 flex justify-between items-center gap-2 pb-4"
				{ ...getToggleProps({ onClick: handleCollapse }) }
			>
				<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
					{ t("MEASUREMENTS.current") }
				</h5>
				<HiChevronDown
					className={
						classNames(
							"w-7 h-7 my-auto text-dark-textGray transition duration-200",
							{ "rotate-180": isExpanded },
						)
					}
				/>
			</div>
			<div className="border-t border-gray-200" { ...getCollapseProps() }>
				<CurrentBasicTab
					settingsMeasurementsUser={ settingsMeasurementsUser }
				/>
			</div>
		</Card>
	);
}

export default (CurrentCard);
