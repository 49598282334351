import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import AnalyzerContainer from "src/app/containers/Settings/Analyzer.container";
import CallbackPromptProvider from "src/app/hoc/providers/CallbackPrompt.provider";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function AnalyzerView(props: Props) {
	return (
		<CallbackPromptProvider>
			<AnalyzerContainer/>
		</CallbackPromptProvider>
	);
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyzerView);
