import { createNetworkAction } from "src/app/utils/redux";
import { SuccessPayload } from "src/app/types/api/api.types";
import { SetConfigurationPayload, SettingsFtp, SettingsIec, SettingsIo, SettingsMeasurementsStandard, SettingsMeasurementsUser, SettingsMemory, SettingsModbus, SettingsRecording, SettingsTime, TestFtpConnectionRequest, TestFtpConnectionResponse } from "src/app/types/api/settings.types";

export const fetchSettingsRecordingAsync = createNetworkAction<undefined, SuccessPayload<SettingsRecording>>("SETTINGS__FETCH_SETTINGS_RECORDING");
export const fetchSettingsModbusAsync = createNetworkAction<undefined, SuccessPayload<SettingsModbus>>("SETTINGS__FETCH_SETTINGS_MODBUS");
export const fetchSettingsFtpAsync = createNetworkAction<undefined, SuccessPayload<SettingsFtp>>("SETTINGS__FETCH_SETTINGS_FTP");
export const fetchSettingsTimeAsync = createNetworkAction<undefined, SuccessPayload<SettingsTime>>("SETTINGS__FETCH_SETTINGS_TIME");
export const fetchSettingsMemoryAsync = createNetworkAction<undefined, SuccessPayload<SettingsMemory>>("SETTINGS__FETCH_SETTINGS_MEMORY");
export const fetchSettingsMeasurementsUserAsync = createNetworkAction<undefined, SuccessPayload<SettingsMeasurementsUser>>("SETTINGS__FETCH_SETTINGS_MEASUREMENT_USER");
export const fetchSettingsMeasurementsStandardAsync = createNetworkAction<undefined, SuccessPayload<SettingsMeasurementsStandard>>("SETTINGS__FETCH_SETTINGS_MEASUREMENT_STANDARD");
export const fetchSettingsIoAsync = createNetworkAction<undefined, SuccessPayload<SettingsIo>>("SETTINGS__FETCH_SETTINGS_IO");
export const fetchSettingsIecAsync = createNetworkAction<undefined, SuccessPayload<SettingsIec>>("SETTINGS__FETCH_SETTINGS_IEC");
export const fetchSettingsIecProfileAsync = createNetworkAction<undefined, SuccessPayload<string>>("SETTINGS__FETCH_SETTINGS_IEC_PROFILE");

export const testFtpConnectionAsync = createNetworkAction<TestFtpConnectionRequest, SuccessPayload<TestFtpConnectionResponse>>("SETTINGS__TEST_FTP_CONNECTION");

export const setConfigurationAsync = createNetworkAction<SetConfigurationPayload, SuccessPayload<any>>("SETTINGS__SET_RECORDING");
