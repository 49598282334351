import { SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import { Button, Card } from "flowbite-react";
import BasicRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/BasicRecordingOptionConfiguration.component";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { useContext, useEffect } from "react";
import { MeasurementsUserContext } from "src/app/components/Measurements/MeasurementsUserContent.component";
import { useTranslation } from "react-i18next";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsMeasurementsUser: SettingsMeasurementsUser
	};

type Tangent4QForm = {
	cfgTangent4QRecOpt: number
	// cfgTangentQuadrant1EventThresholdMin: string
	// cfgTangentQuadrant1EventThresholdMinMin: number
	// cfgTangentQuadrant1EventThresholdMinMax: number
	// cfgTangentQuadrant1EventThresholdMax: string
	// cfgTangentQuadrant1EventThresholdMaxMin: number
	// cfgTangentQuadrant1EventThresholdMaxMax: number
	// cfgTangentQuadrant2EventThresholdMin: string
	// cfgTangentQuadrant2EventThresholdMinMin: number
	// cfgTangentQuadrant2EventThresholdMinMax: number
	// cfgTangentQuadrant2EventThresholdMax: string
	// cfgTangentQuadrant2EventThresholdMaxMin: number
	// cfgTangentQuadrant2EventThresholdMaxMax: number
	// cfgTangentQuadrant3EventThresholdMin: string
	// cfgTangentQuadrant3EventThresholdMinMin: number
	// cfgTangentQuadrant3EventThresholdMinMax: number
	// cfgTangentQuadrant3EventThresholdMax: string
	// cfgTangentQuadrant3EventThresholdMaxMin: number
	// cfgTangentQuadrant3EventThresholdMaxMax: number
	// cfgTangentQuadrant4EventThresholdMin: string
	// cfgTangentQuadrant4EventThresholdMinMin: number
	// cfgTangentQuadrant4EventThresholdMinMax: number
	// cfgTangentQuadrant4EventThresholdMax: string
	// cfgTangentQuadrant4EventThresholdMaxMin: number
	// cfgTangentQuadrant4EventThresholdMaxMax: number
}

const validator: FormValidator<Tangent4QForm> = {
	cfgTangent4QRecOpt: () => null,
	// cfgTangentQuadrant1EventThresholdMin: (cfgTangentQuadrant1EventThresholdMin, optional, form) => validateNumberField("Min tan(φ) L+", cfgTangentQuadrant1EventThresholdMin, optional, form.cfgTangentQuadrant1EventThresholdMinMin.value, Math.min(form.cfgTangentQuadrant1EventThresholdMinMax.value, +form.cfgTangentQuadrant1EventThresholdMax.value)),
	// cfgTangentQuadrant1EventThresholdMinMin: () => null,
	// cfgTangentQuadrant1EventThresholdMinMax: () => null,
	// cfgTangentQuadrant1EventThresholdMax: (cfgTangentQuadrant1EventThresholdMax, optional, form) => validateNumberField("Max tan(φ) L+", cfgTangentQuadrant1EventThresholdMax, optional, Math.max(form.cfgTangentQuadrant1EventThresholdMaxMin.value, +form.cfgTangentQuadrant1EventThresholdMin.value), form.cfgTangentQuadrant1EventThresholdMaxMax.value),
	// cfgTangentQuadrant1EventThresholdMaxMin: () => null,
	// cfgTangentQuadrant1EventThresholdMaxMax: () => null,
	// cfgTangentQuadrant2EventThresholdMin: (cfgTangentQuadrant2EventThresholdMin, optional, form) => validateNumberField("Min tan(φ) C-", cfgTangentQuadrant2EventThresholdMin, optional, form.cfgTangentQuadrant2EventThresholdMinMin.value, Math.min(form.cfgTangentQuadrant2EventThresholdMinMax.value, +form.cfgTangentQuadrant2EventThresholdMax.value)),
	// cfgTangentQuadrant2EventThresholdMinMin: () => null,
	// cfgTangentQuadrant2EventThresholdMinMax: () => null,
	// cfgTangentQuadrant2EventThresholdMax: (cfgTangentQuadrant2EventThresholdMax, optional, form) => validateNumberField("Max tan(φ) C-", cfgTangentQuadrant2EventThresholdMax, optional, Math.max(form.cfgTangentQuadrant2EventThresholdMaxMin.value, +form.cfgTangentQuadrant2EventThresholdMin.value), form.cfgTangentQuadrant2EventThresholdMaxMax.value),
	// cfgTangentQuadrant2EventThresholdMaxMin: () => null,
	// cfgTangentQuadrant2EventThresholdMaxMax: () => null,
	// cfgTangentQuadrant3EventThresholdMin: (cfgTangentQuadrant3EventThresholdMin, optional, form) => validateNumberField("Min tan(φ) L-", cfgTangentQuadrant3EventThresholdMin, optional, form.cfgTangentQuadrant3EventThresholdMinMin.value, Math.min(form.cfgTangentQuadrant3EventThresholdMinMax.value, +form.cfgTangentQuadrant3EventThresholdMax.value)),
	// cfgTangentQuadrant3EventThresholdMinMin: () => null,
	// cfgTangentQuadrant3EventThresholdMinMax: () => null,
	// cfgTangentQuadrant3EventThresholdMax: (cfgTangentQuadrant3EventThresholdMax, optional, form) => validateNumberField("Max tan(φ) L-", cfgTangentQuadrant3EventThresholdMax, optional, Math.max(form.cfgTangentQuadrant3EventThresholdMaxMin.value, +form.cfgTangentQuadrant2EventThresholdMin.value), form.cfgTangentQuadrant3EventThresholdMaxMax.value),
	// cfgTangentQuadrant3EventThresholdMaxMin: () => null,
	// cfgTangentQuadrant3EventThresholdMaxMax: () => null,
	// cfgTangentQuadrant4EventThresholdMin: (cfgTangentQuadrant4EventThresholdMin, optional, form) => validateNumberField("Min tan(φ) C+", cfgTangentQuadrant4EventThresholdMin, optional, form.cfgTangentQuadrant4EventThresholdMinMin.value, Math.min(form.cfgTangentQuadrant4EventThresholdMinMax.value, +form.cfgTangentQuadrant4EventThresholdMax.value)),
	// cfgTangentQuadrant4EventThresholdMinMin: () => null,
	// cfgTangentQuadrant4EventThresholdMinMax: () => null,
	// cfgTangentQuadrant4EventThresholdMax: (cfgTangentQuadrant4EventThresholdMax, optional, form) => validateNumberField("Max tan(φ) C+", cfgTangentQuadrant4EventThresholdMax, optional, Math.max(form.cfgTangentQuadrant4EventThresholdMaxMin.value, +form.cfgTangentQuadrant4EventThresholdMin.value), form.cfgTangentQuadrant4EventThresholdMaxMax.value),
	// cfgTangentQuadrant4EventThresholdMaxMin: () => null,
	// cfgTangentQuadrant4EventThresholdMaxMax: () => null,
};

const ID = "tangent-4q";

function Tangent4QCard(props: Props) {

	const { t } = useTranslation();

	const {
		settingsMeasurementsUser: {
			cfgTangent4Q,
		},
		isAdmin,
	} = props;

	const {
		form: reducerForm,
		handleChange: reducerHandleChange,
	} = useReducerForm(
		"buffor",
		bufforFormActions,
		() => null,
	);

	const { cfgUserEnable } = useContext(MeasurementsUserContext);

	const _handleSubmit = (values: Tangent4QForm) => {
		reducerHandleChange("cfgTangent4QRecOpt", values.cfgTangent4QRecOpt);
		// reducerHandleChange("cfgTangentQuadrant1EventThresholdMin", values.cfgTangentQuadrant1EventThresholdMin);
		// reducerHandleChange("cfgTangentQuadrant1EventThresholdMax", values.cfgTangentQuadrant1EventThresholdMax);
		// reducerHandleChange("cfgTangentQuadrant2EventThresholdMin", values.cfgTangentQuadrant2EventThresholdMin);
		// reducerHandleChange("cfgTangentQuadrant2EventThresholdMax", values.cfgTangentQuadrant2EventThresholdMax);
		// reducerHandleChange("cfgTangentQuadrant3EventThresholdMin", values.cfgTangentQuadrant3EventThresholdMin);
		// reducerHandleChange("cfgTangentQuadrant3EventThresholdMax", values.cfgTangentQuadrant3EventThresholdMax);
		// reducerHandleChange("cfgTangentQuadrant4EventThresholdMin", values.cfgTangentQuadrant4EventThresholdMin);
		// reducerHandleChange("cfgTangentQuadrant4EventThresholdMax", values.cfgTangentQuadrant4EventThresholdMax);
	};

	const _getInitialState = () => ({
		cfgTangent4QRecOpt: createFormField((reducerForm.cfgTangent4QRecOpt.value !== reducerForm.cfgTangent4QRecOpt.initialValue) ? reducerForm.cfgTangent4QRecOpt.value : cfgTangent4Q?.cfgTangent4QRecOpt?.value ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant1EventThresholdMin: createFormField((reducerForm.cfgTangentQuadrant1EventThresholdMin.value !== reducerForm.cfgTangentQuadrant1EventThresholdMin.initialValue) ? reducerForm.cfgTangentQuadrant1EventThresholdMin.value : cfgTangent4Q?.cfgTangentQuadrant1EventThresholdMin?.value?.toString() ?? "", { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant1EventThresholdMinMin: createFormField(cfgTangent4Q?.cfgTangentQuadrant1EventThresholdMin?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant1EventThresholdMinMax: createFormField(cfgTangent4Q?.cfgTangentQuadrant1EventThresholdMin?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant1EventThresholdMax: createFormField((reducerForm.cfgTangentQuadrant1EventThresholdMax.value !== reducerForm.cfgTangentQuadrant1EventThresholdMax.initialValue) ? reducerForm.cfgTangentQuadrant1EventThresholdMax.value : cfgTangent4Q?.cfgTangentQuadrant1EventThresholdMax?.value?.toString() ?? "", { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant1EventThresholdMaxMin: createFormField(cfgTangent4Q?.cfgTangentQuadrant1EventThresholdMax?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant1EventThresholdMaxMax: createFormField(cfgTangent4Q?.cfgTangentQuadrant1EventThresholdMax?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant2EventThresholdMin: createFormField((reducerForm.cfgTangentQuadrant2EventThresholdMin.value !== reducerForm.cfgTangentQuadrant2EventThresholdMin.initialValue) ? reducerForm.cfgTangentQuadrant2EventThresholdMin.value : cfgTangent4Q?.cfgTangentQuadrant2EventThresholdMin?.value?.toString() ?? "", { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant2EventThresholdMinMin: createFormField(cfgTangent4Q?.cfgTangentQuadrant2EventThresholdMin?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant2EventThresholdMinMax: createFormField(cfgTangent4Q?.cfgTangentQuadrant2EventThresholdMin?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant2EventThresholdMax: createFormField((reducerForm.cfgTangentQuadrant2EventThresholdMax.value !== reducerForm.cfgTangentQuadrant2EventThresholdMax.initialValue) ? reducerForm.cfgTangentQuadrant2EventThresholdMax.value : cfgTangent4Q?.cfgTangentQuadrant2EventThresholdMax?.value?.toString() ?? "", { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant2EventThresholdMaxMin: createFormField(cfgTangent4Q?.cfgTangentQuadrant2EventThresholdMax?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant2EventThresholdMaxMax: createFormField(cfgTangent4Q?.cfgTangentQuadrant2EventThresholdMax?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant3EventThresholdMin: createFormField((reducerForm.cfgTangentQuadrant3EventThresholdMin.value !== reducerForm.cfgTangentQuadrant3EventThresholdMin.initialValue) ? reducerForm.cfgTangentQuadrant3EventThresholdMin.value : cfgTangent4Q?.cfgTangentQuadrant3EventThresholdMin?.value?.toString() ?? "", { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant3EventThresholdMinMin: createFormField(cfgTangent4Q?.cfgTangentQuadrant3EventThresholdMin?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant3EventThresholdMinMax: createFormField(cfgTangent4Q?.cfgTangentQuadrant3EventThresholdMin?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant3EventThresholdMax: createFormField((reducerForm.cfgTangentQuadrant3EventThresholdMax.value !== reducerForm.cfgTangentQuadrant3EventThresholdMax.initialValue) ? reducerForm.cfgTangentQuadrant3EventThresholdMax.value : cfgTangent4Q?.cfgTangentQuadrant3EventThresholdMax?.value?.toString() ?? "", { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant3EventThresholdMaxMin: createFormField(cfgTangent4Q?.cfgTangentQuadrant3EventThresholdMax?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant3EventThresholdMaxMax: createFormField(cfgTangent4Q?.cfgTangentQuadrant3EventThresholdMax?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant4EventThresholdMin: createFormField((reducerForm.cfgTangentQuadrant4EventThresholdMin.value !== reducerForm.cfgTangentQuadrant4EventThresholdMin.initialValue) ? reducerForm.cfgTangentQuadrant4EventThresholdMin.value : cfgTangent4Q?.cfgTangentQuadrant4EventThresholdMin?.value?.toString() ?? "", { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant4EventThresholdMinMin: createFormField(cfgTangent4Q?.cfgTangentQuadrant4EventThresholdMin?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant4EventThresholdMinMax: createFormField(cfgTangent4Q?.cfgTangentQuadrant4EventThresholdMin?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant4EventThresholdMax: createFormField((reducerForm.cfgTangentQuadrant4EventThresholdMax.value !== reducerForm.cfgTangentQuadrant4EventThresholdMax.initialValue) ? reducerForm.cfgTangentQuadrant4EventThresholdMax.value : cfgTangent4Q?.cfgTangentQuadrant4EventThresholdMax?.value?.toString() ?? "", { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant4EventThresholdMaxMin: createFormField(cfgTangent4Q?.cfgTangentQuadrant4EventThresholdMax?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
		// cfgTangentQuadrant4EventThresholdMaxMax: createFormField(cfgTangent4Q?.cfgTangentQuadrant4EventThresholdMax?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ cfgTangent4Q ]);

	useEffect(() => {
		toggleDisable("cfgTangent4QRecOpt", !cfgUserEnable || !isAdmin);
		// toggleDisable("cfgTangentQuadrant1EventThresholdMin", !cfgUserEnable || !isAdmin);
		// toggleDisable("cfgTangentQuadrant1EventThresholdMax", !cfgUserEnable || !isAdmin);
		// toggleDisable("cfgTangentQuadrant2EventThresholdMin", !cfgUserEnable || !isAdmin);
		// toggleDisable("cfgTangentQuadrant2EventThresholdMax", !cfgUserEnable || !isAdmin);
		// toggleDisable("cfgTangentQuadrant3EventThresholdMin", !cfgUserEnable || !isAdmin);
		// toggleDisable("cfgTangentQuadrant3EventThresholdMax", !cfgUserEnable || !isAdmin);
		// toggleDisable("cfgTangentQuadrant4EventThresholdMin", !cfgUserEnable || !isAdmin);
		// toggleDisable("cfgTangentQuadrant4EventThresholdMax", !cfgUserEnable || !isAdmin);
	}, [ cfgUserEnable ]);

	useEffect(() => {
		toggleDisable("cfgTangent4QRecOpt", false);
		// toggleDisable("cfgTangentQuadrant1EventThresholdMin", false);
		// toggleDisable("cfgTangentQuadrant1EventThresholdMax", false);
		// toggleDisable("cfgTangentQuadrant2EventThresholdMin", false);
		// toggleDisable("cfgTangentQuadrant2EventThresholdMax", false);
		// toggleDisable("cfgTangentQuadrant3EventThresholdMin", false);
		// toggleDisable("cfgTangentQuadrant3EventThresholdMax", false);
		// toggleDisable("cfgTangentQuadrant4EventThresholdMin", false);
		// toggleDisable("cfgTangentQuadrant4EventThresholdMax", false);

		handleChange("cfgTangent4QRecOpt", reducerForm.cfgTangent4QRecOpt.value);
		// handleChange("cfgTangentQuadrant1EventThresholdMin", reducerForm.cfgTangentQuadrant1EventThresholdMin.value);
		// handleChange("cfgTangentQuadrant1EventThresholdMax", reducerForm.cfgTangentQuadrant1EventThresholdMax.value);
		// handleChange("cfgTangentQuadrant2EventThresholdMin", reducerForm.cfgTangentQuadrant2EventThresholdMin.value);
		// handleChange("cfgTangentQuadrant2EventThresholdMax", reducerForm.cfgTangentQuadrant2EventThresholdMax.value);
		// handleChange("cfgTangentQuadrant3EventThresholdMin", reducerForm.cfgTangentQuadrant3EventThresholdMin.value);
		// handleChange("cfgTangentQuadrant3EventThresholdMax", reducerForm.cfgTangentQuadrant3EventThresholdMax.value);
		// handleChange("cfgTangentQuadrant4EventThresholdMin", reducerForm.cfgTangentQuadrant4EventThresholdMin.value);
		// handleChange("cfgTangentQuadrant4EventThresholdMax", reducerForm.cfgTangentQuadrant4EventThresholdMax.value);

		toggleDisable("cfgTangent4QRecOpt", !cfgUserEnable || !isAdmin);
		// toggleDisable("cfgTangentQuadrant1EventThresholdMin", !cfgUserEnable || !isAdmin);
		// toggleDisable("cfgTangentQuadrant1EventThresholdMax", !cfgUserEnable || !isAdmin);
		// toggleDisable("cfgTangentQuadrant2EventThresholdMin", !cfgUserEnable || !isAdmin);
		// toggleDisable("cfgTangentQuadrant2EventThresholdMax", !cfgUserEnable || !isAdmin);
		// toggleDisable("cfgTangentQuadrant3EventThresholdMin", !cfgUserEnable || !isAdmin);
		// toggleDisable("cfgTangentQuadrant3EventThresholdMax", !cfgUserEnable || !isAdmin);
		// toggleDisable("cfgTangentQuadrant4EventThresholdMin", !cfgUserEnable || !isAdmin);
		// toggleDisable("cfgTangentQuadrant4EventThresholdMax", !cfgUserEnable || !isAdmin);
	}, [
		reducerForm.cfgTangent4QRecOpt.value,
		// reducerForm.cfgTangentQuadrant1EventThresholdMin.value,
		// reducerForm.cfgTangentQuadrant1EventThresholdMax.value,
		// reducerForm.cfgTangentQuadrant2EventThresholdMin.value,
		// reducerForm.cfgTangentQuadrant2EventThresholdMax.value,
		// reducerForm.cfgTangentQuadrant3EventThresholdMin.value,
		// reducerForm.cfgTangentQuadrant3EventThresholdMax.value,
		// reducerForm.cfgTangentQuadrant4EventThresholdMin.value,
		// reducerForm.cfgTangentQuadrant4EventThresholdMax.value,
	]);

	return (
		<Card className="bg-gray-50">
			<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
				{ t("POWER.tangent") }
			</h5>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<BasicRecordingOptionConfiguration
					id={ ID }
					options={ cfgTangent4Q?.cfgTangent4QRecOpt?.options ?? [] }
					formItem={ form.cfgTangent4QRecOpt }
					handleChange={ value => handleChange("cfgTangent4QRecOpt", value) }
					reducerFormItem={ reducerForm.cfgTangent4QRecOpt }
				/>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(Tangent4QCard);
