import { PhaseVoltageConfiguration, RecordingOption, SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import BasicRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/BasicRecordingOptionConfiguration.component";
import { FormValidator } from "src/app/types/ui/form.types";
import useForm from "src/app/utils/hooks/useForm";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { createFormField, validateNumberField } from "src/app/utils/forms";
import { Button, Card } from "flowbite-react";
import EventRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/EventRecordingOptionConfiguration.component";
import Input from "src/app/components/Form/Input.component";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { useContext, useEffect } from "react";
import { MeasurementsUserContext } from "src/app/components/Measurements/MeasurementsUserContent.component";
import { SettingsContext } from "src/app/hoc/providers/Settings.provider";
import { EnumOptionsSetting } from "src/app/types/util.types";
import { getOptionMask, isOptionSelected, round } from "src/app/utils/helpers";
import RelayActionSelect from "src/app/components/MeasurementsUser/Generic/RelayActionSelect.component";
import RelayChannelChooser from "src/app/components/MeasurementsUser/Generic/RelayChannelChooser.component";
import { isEmptyArray, isNotNull } from "src/app/utils/typeguards";
import RelativeValuesSelect from "src/app/components/MeasurementsUser/Generic/RelativeValuesSelect.component";
import { isChannelsDiff, mapDipsConfig, mapInterruptConfig, mapPhaseJumpsConfig, mapSwellConfig, mapWaveshapeVariationsConfig } from "src/app/utils/configuration";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";
import classNames from "classnames";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		title: string
		cfgVoltageRms: PhaseVoltageConfiguration
		settingsMeasurementsUser: SettingsMeasurementsUser
	};

export type VoltageRmsForm = {
	cfgNominalVoltage: number
	cfgVoltageRmsRecOpt: number
	cfgVoltageRmsRecOptOptions: EnumOptionsSetting<RecordingOption>["options"]
	cfgVoltageRmsEventThresholdUserDip: string
	cfgVoltageRmsEventThresholdUserDipMin: number
	cfgVoltageRmsEventThresholdUserDipMax: number
	cfgVoltageRmsEventThresholdUserSwell: string
	cfgVoltageRmsEventThresholdUserSwellMin: number
	cfgVoltageRmsEventThresholdUserSwellMax: number
	cfgVoltageRmsEventThresholdUserInterruption: string
	cfgVoltageRmsEventThresholdUserInterruptionMin: number
	cfgVoltageRmsEventThresholdUserInterruptionMax: number
	cfgVoltageRmsEventThresholdMaxCh4: string
	cfgVoltageRmsEventThresholdMaxCh4Min: number
	cfgVoltageRmsEventThresholdMaxCh4Max: number
	cfgDipChannels: ("cfgRelayVoltageDip1RecOpt" | "cfgRelayVoltageDip2RecOpt" | "cfgRelayVoltageDip3RecOpt" | "cfgRelayVoltageDip4RecOpt")[]
	cfgDipActions: number
	cfgSwellChannels: ("cfgRelayVoltageSwell1RecOpt" | "cfgRelayVoltageSwell2RecOpt" | "cfgRelayVoltageSwell3RecOpt" | "cfgRelayVoltageSwell4RecOpt")[]
	cfgSwellActions: number
	cfgInterruptChannels: ("cfgRelayVoltageInterrupt1RecOpt" | "cfgRelayVoltageInterrupt2RecOpt" | "cfgRelayVoltageInterrupt3RecOpt" | "cfgRelayVoltageInterrupt4RecOpt")[]
	cfgInterruptActions: number

	cfgWaveshapeVariationsRecOpt: number
	cfgWaveshapeVariationsRecOptOptions: EnumOptionsSetting<RecordingOption>["options"]
	cfgWaveshapeVariationsDeadtime: string
	cfgWaveshapeVariationsDeadtimeMin: number
	cfgWaveshapeVariationsDeadtimeMax: number
	cfgWaveshapeVariationsThreshold: string
	cfgWaveshapeVariationsThresholdMin: number
	cfgWaveshapeVariationsThresholdMax: number
	cfgWaveshapeVariationsChannels: ("cfgRelayWaveshape1RecOpt" | "cfgRelayWaveshape2RecOpt" | "cfgRelayWaveshape3RecOpt" | "cfgRelayWaveshape4RecOpt")[]
	cfgWaveshapeVariationsActions: number

	cfgPhaseJumpsRecOpt: number
	cfgPhaseJumpsRecOptOptions: EnumOptionsSetting<RecordingOption>["options"]
	cfgPhaseJumpsThreshold: string
	cfgPhaseJumpsThresholdMin: number
	cfgPhaseJumpsThresholdMax: number
	cfgPhaseJumpsChannels: ("cfgRelayPhaseJump1RecOpt" | "cfgRelayPhaseJump2RecOpt" | "cfgRelayPhaseJump3RecOpt" | "cfgRelayPhaseJump4RecOpt")[]
	cfgPhaseJumpsActions: number
}

const validator: FormValidator<VoltageRmsForm> = {
	cfgNominalVoltage: () => null,
	cfgVoltageRmsRecOpt: () => null,
	cfgVoltageRmsRecOptOptions: () => null,
	cfgVoltageRmsEventThresholdUserDip: (cfgVoltageRmsEventThresholdUserDip, optional, form) => {
		if (!isOptionSelected(form.cfgVoltageRmsRecOpt.value, RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE, form.cfgVoltageRmsRecOptOptions.value)) return null;

		if (isOptionSelected(form.cfgVoltageRmsRecOpt.value, RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE, form.cfgVoltageRmsRecOptOptions.value)) {
			const min = round((form.cfgVoltageRmsEventThresholdUserDipMin.value / form.cfgNominalVoltage.value) * 100, 2);
			const max = round((form.cfgVoltageRmsEventThresholdUserDipMax.value / form.cfgNominalVoltage.value) * 100, 2);
			return validateNumberField(i18n.t("MEASUREMENTS.dips"), cfgVoltageRmsEventThresholdUserDip, optional, "it", { from: min, to: max, decimalPlaces: 2 });
		} else {
			return validateNumberField(i18n.t("MEASUREMENTS.dips"), cfgVoltageRmsEventThresholdUserDip, optional, "it", { from: form.cfgVoltageRmsEventThresholdUserDipMin.value, to: form.cfgVoltageRmsEventThresholdUserDipMax.value, decimalPlaces: 2 });
		}
	},
	cfgVoltageRmsEventThresholdUserDipMin: () => null,
	cfgVoltageRmsEventThresholdUserDipMax: () => null,
	cfgVoltageRmsEventThresholdUserSwell: (cfgVoltageRmsEventThresholdUserSwell, optional, form) => {
		if (!isOptionSelected(form.cfgVoltageRmsRecOpt.value, RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE, form.cfgVoltageRmsRecOptOptions.value)) return null;

		if (isOptionSelected(form.cfgVoltageRmsRecOpt.value, RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE, form.cfgVoltageRmsRecOptOptions.value)) {
			const min = round((form.cfgVoltageRmsEventThresholdUserSwellMin.value / form.cfgNominalVoltage.value) * 100, 2);
			const max = round((form.cfgVoltageRmsEventThresholdUserSwellMax.value / form.cfgNominalVoltage.value) * 100, 2);
			return validateNumberField(i18n.t("MEASUREMENTS.swells"), cfgVoltageRmsEventThresholdUserSwell, optional, "it", { from: min, to: max, decimalPlaces: 2 });
		} else {
			return validateNumberField(i18n.t("MEASUREMENTS.swells"), cfgVoltageRmsEventThresholdUserSwell, optional, "it", { from: form.cfgVoltageRmsEventThresholdUserSwellMin.value, to: form.cfgVoltageRmsEventThresholdUserSwellMax.value, decimalPlaces: 2 });
		}
	},
	cfgVoltageRmsEventThresholdUserSwellMin: () => null,
	cfgVoltageRmsEventThresholdUserSwellMax: () => null,
	cfgVoltageRmsEventThresholdUserInterruption: (cfgVoltageRmsEventThresholdUserInterruption, optional, form) => {
		if (!isOptionSelected(form.cfgVoltageRmsRecOpt.value, RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE, form.cfgVoltageRmsRecOptOptions.value)) return null;

		if (isOptionSelected(form.cfgVoltageRmsRecOpt.value, RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE, form.cfgVoltageRmsRecOptOptions.value)) {
			const min = round((form.cfgVoltageRmsEventThresholdUserInterruptionMin.value / form.cfgNominalVoltage.value) * 100, 2);
			const max = round((form.cfgVoltageRmsEventThresholdUserInterruptionMax.value / form.cfgNominalVoltage.value) * 100, 2);
			return validateNumberField(i18n.t("MEASUREMENTS.interruptions"), cfgVoltageRmsEventThresholdUserInterruption, optional, "it", { from: min, to: max, decimalPlaces: 2 });
		} else {
			return validateNumberField(i18n.t("MEASUREMENTS.interruptions"), cfgVoltageRmsEventThresholdUserInterruption, optional, "it", { from: form.cfgVoltageRmsEventThresholdUserInterruptionMin.value, to: form.cfgVoltageRmsEventThresholdUserInterruptionMax.value, decimalPlaces: 2 });
		}
	},
	cfgVoltageRmsEventThresholdUserInterruptionMin: () => null,
	cfgVoltageRmsEventThresholdUserInterruptionMax: () => null,
	cfgVoltageRmsEventThresholdMaxCh4: (cfgVoltageRmsEventThresholdMaxCh4, optional, form) => validateNumberField(i18n.t("MEASUREMENTS.N-E voltage over limit"), cfgVoltageRmsEventThresholdMaxCh4, optional, "it", { from: form.cfgVoltageRmsEventThresholdMaxCh4Min.value, to: form.cfgVoltageRmsEventThresholdMaxCh4Max.value, decimalPlaces: 2 }),
	cfgVoltageRmsEventThresholdMaxCh4Min: () => null,
	cfgVoltageRmsEventThresholdMaxCh4Max: () => null,
	cfgDipChannels: () => null,
	cfgDipActions: () => null,
	cfgSwellChannels: () => null,
	cfgSwellActions: () => null,
	cfgInterruptChannels: () => null,
	cfgInterruptActions: () => null,

	cfgWaveshapeVariationsRecOpt: () => null,
	cfgWaveshapeVariationsRecOptOptions: () => null,
	cfgWaveshapeVariationsDeadtime: (cfgWaveshapeVariationsDeadtime, optional, form) => {
		if (!isOptionSelected(form.cfgWaveshapeVariationsRecOpt.value, RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE, form.cfgWaveshapeVariationsRecOptOptions.value)) return null;

		return validateNumberField(i18n.t("MEASUREMENTS.hold off time"), cfgWaveshapeVariationsDeadtime, optional, "he", { from: form.cfgWaveshapeVariationsDeadtimeMin.value, to: form.cfgWaveshapeVariationsDeadtimeMax.value, decimalPlaces: 2 });
	},
	cfgWaveshapeVariationsDeadtimeMin: () => null,
	cfgWaveshapeVariationsDeadtimeMax: () => null,
	cfgWaveshapeVariationsThreshold: (cfgWaveshapeVariationsThreshold, optional, form) => {
		if (!isOptionSelected(form.cfgWaveshapeVariationsRecOpt.value, RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE, form.cfgWaveshapeVariationsRecOptOptions.value)) return null;

		return validateNumberField(i18n.t("MEASUREMENTS.waveshape variations"), cfgWaveshapeVariationsThreshold, optional, "plural", { from: form.cfgWaveshapeVariationsThresholdMin.value, to: form.cfgWaveshapeVariationsThresholdMax.value, decimalPlaces: 2 });
	},
	cfgWaveshapeVariationsThresholdMin: () => null,
	cfgWaveshapeVariationsThresholdMax: () => null,
	cfgWaveshapeVariationsChannels: () => null,
	cfgWaveshapeVariationsActions: () => null,

	cfgPhaseJumpsRecOpt: () => null,
	cfgPhaseJumpsRecOptOptions: () => null,
	cfgPhaseJumpsThreshold: (cfgPhaseJumpsThreshold, optional, form) => {
		if (!isOptionSelected(form.cfgPhaseJumpsRecOpt.value, RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE, form.cfgPhaseJumpsRecOptOptions.value)) return null;

		return validateNumberField(i18n.t("MEASUREMENTS.phase jumps"), cfgPhaseJumpsThreshold, optional, "plural", { from: form.cfgPhaseJumpsThresholdMin.value, to: form.cfgPhaseJumpsThresholdMax.value, decimalPlaces: 2 });
	},
	cfgPhaseJumpsThresholdMin: () => null,
	cfgPhaseJumpsThresholdMax: () => null,
	cfgPhaseJumpsChannels: () => null,
	cfgPhaseJumpsActions: () => null,
};

const ID_VOLTAGE = "voltage-rms";

const ID_WAVESHAPE = "waveshape-variations";

const ID_PHASE_JUMPS = "phase-jumps";

function VoltageRmsCard(props: Props) {

	const { t } = useTranslation();

	const {
		title,
		cfgVoltageRms,
		settingsMeasurementsUser: {
			cfgWaveshapeVariations,
			cfgPhaseJumps,
		},
		isAdmin,
	} = props;

	const {
		form: reducerForm,
		handleChange: reducerHandleChange,
	} = useReducerForm(
		"buffor",
		bufforFormActions,
		() => null,
	);

	const { cfgUserEnable } = useContext(MeasurementsUserContext);
	const { recording } = useContext(SettingsContext);

	const _handleSubmit = (values: VoltageRmsForm) => {
		reducerHandleChange("cfgVoltageRmsRecOpt", values.cfgVoltageRmsRecOpt);
		reducerHandleChange("cfgVoltageRmsEventThresholdUserDip", values.cfgVoltageRmsEventThresholdUserDip);
		reducerHandleChange("cfgVoltageRmsEventThresholdUserSwell", values.cfgVoltageRmsEventThresholdUserSwell);
		reducerHandleChange("cfgVoltageRmsEventThresholdUserInterruption", values.cfgVoltageRmsEventThresholdUserInterruption);
		reducerHandleChange("cfgVoltageRmsEventThresholdMaxCh4", values.cfgVoltageRmsEventThresholdMaxCh4);
		reducerHandleChange("cfgDipChannels", values.cfgDipChannels);
		reducerHandleChange("cfgDipActions", values.cfgDipActions);
		reducerHandleChange("cfgSwellChannels", values.cfgSwellChannels);
		reducerHandleChange("cfgSwellActions", values.cfgSwellActions);
		reducerHandleChange("cfgInterruptChannels", values.cfgInterruptChannels);
		reducerHandleChange("cfgInterruptActions", values.cfgInterruptActions);

		reducerHandleChange("cfgWaveshapeVariationsRecOpt", values.cfgWaveshapeVariationsRecOpt);
		reducerHandleChange("cfgWaveshapeVariationsDeadtime", values.cfgWaveshapeVariationsDeadtime);
		reducerHandleChange("cfgWaveshapeVariationsThreshold", values.cfgWaveshapeVariationsThreshold);
		reducerHandleChange("cfgWaveshapeVariationsChannels", values.cfgWaveshapeVariationsChannels);
		reducerHandleChange("cfgWaveshapeVariationsActions", values.cfgWaveshapeVariationsActions);

		reducerHandleChange("cfgPhaseJumpsRecOpt", values.cfgPhaseJumpsRecOpt);
		reducerHandleChange("cfgPhaseJumpsThreshold", values.cfgPhaseJumpsThreshold);
		reducerHandleChange("cfgPhaseJumpsChannels", values.cfgPhaseJumpsChannels);
		reducerHandleChange("cfgPhaseJumpsActions", values.cfgPhaseJumpsActions);
	};

	const _getInitialState = () => {

		const [ cfgDipChannels, cfgDipActions ] = mapDipsConfig(cfgVoltageRms);
		const [ cfgSwellChannels, cfgSwellActions ] = mapSwellConfig(cfgVoltageRms);
		const [ cfgInterruptChannels, cfgInterruptActions ] = mapInterruptConfig(cfgVoltageRms);
		const [ cfgWaveshapeVariationsChannels, cfgWaveshapeVariationsActions ] = mapWaveshapeVariationsConfig(cfgWaveshapeVariations);
		const [ cfgPhaseJumpsChannels, cfgPhaseJumpsActions ] = mapPhaseJumpsConfig(cfgPhaseJumps);

		return ({
			cfgNominalVoltage: createFormField(recording?.recording?.cfgNominalVoltage?.value ?? 230),
			cfgVoltageRmsRecOpt: createFormField((reducerForm.cfgVoltageRmsRecOpt.value !== reducerForm.cfgVoltageRmsRecOpt.initialValue) ? reducerForm.cfgVoltageRmsRecOpt.value : cfgVoltageRms?.cfgVoltageRmsRecOpt?.value ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgVoltageRmsRecOptOptions: createFormField(cfgVoltageRms?.cfgVoltageRmsRecOpt?.options ?? []),
			cfgVoltageRmsEventThresholdUserDip: createFormField((reducerForm.cfgVoltageRmsEventThresholdUserDip.value !== reducerForm.cfgVoltageRmsEventThresholdUserDip.initialValue) ? reducerForm.cfgVoltageRmsEventThresholdUserDip.value : cfgVoltageRms?.cfgVoltageRmsEventThresholdUserDip?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
			cfgVoltageRmsEventThresholdUserDipMin: createFormField(cfgVoltageRms?.cfgVoltageRmsEventThresholdUserDip?.minValue ?? 0),
			cfgVoltageRmsEventThresholdUserDipMax: createFormField(cfgVoltageRms?.cfgVoltageRmsEventThresholdUserDip?.maxValue ?? 0),
			cfgVoltageRmsEventThresholdUserSwell: createFormField((reducerForm.cfgVoltageRmsEventThresholdUserSwell.value !== reducerForm.cfgVoltageRmsEventThresholdUserSwell.initialValue) ? reducerForm.cfgVoltageRmsEventThresholdUserSwell.value : cfgVoltageRms?.cfgVoltageRmsEventThresholdUserSwell?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
			cfgVoltageRmsEventThresholdUserSwellMin: createFormField(cfgVoltageRms?.cfgVoltageRmsEventThresholdUserSwell?.minValue ?? 0),
			cfgVoltageRmsEventThresholdUserSwellMax: createFormField(cfgVoltageRms?.cfgVoltageRmsEventThresholdUserSwell?.maxValue ?? 0),
			cfgVoltageRmsEventThresholdUserInterruption: createFormField((reducerForm.cfgVoltageRmsEventThresholdUserInterruption.value !== reducerForm.cfgVoltageRmsEventThresholdUserInterruption.initialValue) ? reducerForm.cfgVoltageRmsEventThresholdUserInterruption.value : cfgVoltageRms?.cfgVoltageRmsEventThresholdUserInterruption?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
			cfgVoltageRmsEventThresholdUserInterruptionMin: createFormField(cfgVoltageRms?.cfgVoltageRmsEventThresholdUserInterruption?.minValue ?? 0),
			cfgVoltageRmsEventThresholdUserInterruptionMax: createFormField(cfgVoltageRms?.cfgVoltageRmsEventThresholdUserInterruption?.maxValue ?? 0),
			cfgVoltageRmsEventThresholdMaxCh4: createFormField((reducerForm.cfgVoltageRmsEventThresholdMaxCh4.value !== reducerForm.cfgVoltageRmsEventThresholdMaxCh4.initialValue) ? reducerForm.cfgVoltageRmsEventThresholdMaxCh4.value : cfgVoltageRms?.cfgVoltageRmsEventThresholdMaxCh4?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
			cfgVoltageRmsEventThresholdMaxCh4Min: createFormField(cfgVoltageRms?.cfgVoltageRmsEventThresholdMaxCh4?.minValue ?? 0),
			cfgVoltageRmsEventThresholdMaxCh4Max: createFormField(cfgVoltageRms?.cfgVoltageRmsEventThresholdMaxCh4?.maxValue ?? 0),
			cfgDipChannels: createFormField(isChannelsDiff(reducerForm.cfgDipChannels) ? reducerForm.cfgDipChannels.value : cfgDipChannels, { disabled: !isAdmin || !cfgUserEnable }),
			cfgDipActions: createFormField((reducerForm.cfgDipActions.value !== reducerForm.cfgDipActions.initialValue) ? reducerForm.cfgDipActions.value : cfgDipActions, { disabled: !isAdmin || !cfgUserEnable }),
			cfgSwellChannels: createFormField(isChannelsDiff(reducerForm.cfgSwellChannels) ? reducerForm.cfgSwellChannels.value : cfgSwellChannels, { disabled: !isAdmin || !cfgUserEnable }),
			cfgSwellActions: createFormField((reducerForm.cfgSwellActions.value !== reducerForm.cfgSwellActions.initialValue) ? reducerForm.cfgSwellActions.value : cfgSwellActions, { disabled: !isAdmin || !cfgUserEnable }),
			cfgInterruptChannels: createFormField(isChannelsDiff(reducerForm.cfgInterruptChannels) ? reducerForm.cfgInterruptChannels.value : cfgInterruptChannels, { disabled: !isAdmin || !cfgUserEnable }),
			cfgInterruptActions: createFormField((reducerForm.cfgInterruptActions.value !== reducerForm.cfgInterruptActions.initialValue) ? reducerForm.cfgInterruptActions.value : cfgInterruptActions, { disabled: !isAdmin || !cfgUserEnable }),

			cfgWaveshapeVariationsRecOpt: createFormField((reducerForm.cfgWaveshapeVariationsRecOpt.value !== reducerForm.cfgWaveshapeVariationsRecOpt.initialValue) ? reducerForm.cfgWaveshapeVariationsRecOpt.value : cfgWaveshapeVariations?.cfgWaveshapeVariationsRecOpt?.value ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgWaveshapeVariationsRecOptOptions: createFormField(cfgWaveshapeVariations?.cfgWaveshapeVariationsRecOpt?.options ?? []),
			cfgWaveshapeVariationsDeadtime: createFormField((reducerForm.cfgWaveshapeVariationsDeadtime.value !== reducerForm.cfgWaveshapeVariationsDeadtime.initialValue) ? reducerForm.cfgWaveshapeVariationsDeadtime.value : cfgWaveshapeVariations?.cfgWaveshapeVariationsDeadtime?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
			cfgWaveshapeVariationsDeadtimeMin: createFormField(cfgWaveshapeVariations?.cfgWaveshapeVariationsDeadtime?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgWaveshapeVariationsDeadtimeMax: createFormField(cfgWaveshapeVariations?.cfgWaveshapeVariationsDeadtime?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgWaveshapeVariationsThreshold: createFormField((reducerForm.cfgWaveshapeVariationsThreshold.value !== reducerForm.cfgWaveshapeVariationsThreshold.initialValue) ? reducerForm.cfgWaveshapeVariationsThreshold.value : cfgWaveshapeVariations?.cfgWaveshapeVariationsThreshold?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
			cfgWaveshapeVariationsThresholdMin: createFormField(cfgWaveshapeVariations?.cfgWaveshapeVariationsThreshold?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgWaveshapeVariationsThresholdMax: createFormField(cfgWaveshapeVariations?.cfgWaveshapeVariationsThreshold?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgWaveshapeVariationsChannels: createFormField(isChannelsDiff(reducerForm.cfgWaveshapeVariationsChannels) ? reducerForm.cfgWaveshapeVariationsChannels.value : cfgWaveshapeVariationsChannels, { disabled: !isAdmin || !cfgUserEnable }),
			cfgWaveshapeVariationsActions: createFormField((reducerForm.cfgWaveshapeVariationsActions.value !== reducerForm.cfgWaveshapeVariationsActions.initialValue) ? reducerForm.cfgWaveshapeVariationsActions.value : cfgWaveshapeVariationsActions, { disabled: !isAdmin || !cfgUserEnable }),

			cfgPhaseJumpsRecOpt: createFormField((reducerForm.cfgPhaseJumpsRecOpt.value !== reducerForm.cfgPhaseJumpsRecOpt.initialValue) ? reducerForm.cfgPhaseJumpsRecOpt.value : cfgPhaseJumps?.cfgPhaseJumpsRecOpt?.value ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgPhaseJumpsRecOptOptions: createFormField(cfgPhaseJumps?.cfgPhaseJumpsRecOpt?.options ?? []),
			cfgPhaseJumpsThreshold: createFormField((reducerForm.cfgPhaseJumpsThreshold.value !== reducerForm.cfgPhaseJumpsThreshold.initialValue) ? reducerForm.cfgPhaseJumpsThreshold.value : cfgPhaseJumps?.cfgPhaseJumpsThreshold?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
			cfgPhaseJumpsThresholdMin: createFormField(cfgPhaseJumps?.cfgPhaseJumpsThreshold?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgPhaseJumpsThresholdMax: createFormField(cfgPhaseJumps?.cfgPhaseJumpsThreshold?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgPhaseJumpsChannels: createFormField(isChannelsDiff(reducerForm.cfgPhaseJumpsChannels) ? reducerForm.cfgPhaseJumpsChannels.value : cfgPhaseJumpsChannels, { disabled: !isAdmin || !cfgUserEnable }),
			cfgPhaseJumpsActions: createFormField((reducerForm.cfgPhaseJumpsActions.value !== reducerForm.cfgPhaseJumpsActions.initialValue) ? reducerForm.cfgPhaseJumpsActions.value : cfgPhaseJumpsActions, { disabled: !isAdmin || !cfgUserEnable }),
		});
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [
		cfgVoltageRms,
		cfgWaveshapeVariations,
		cfgPhaseJumps,
	]);

	useEffect(() => {
		toggleDisable("cfgVoltageRmsRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgVoltageRmsEventThresholdUserDip", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgVoltageRmsEventThresholdUserSwell", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgVoltageRmsEventThresholdUserInterruption", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgVoltageRmsEventThresholdMaxCh4", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgDipChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgDipActions", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgSwellChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgSwellActions", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgInterruptChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgInterruptActions", !cfgUserEnable || !isAdmin);

		toggleDisable("cfgWaveshapeVariationsRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgWaveshapeVariationsDeadtime", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgWaveshapeVariationsThreshold", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgWaveshapeVariationsChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgWaveshapeVariationsActions", !cfgUserEnable || !isAdmin);

		toggleDisable("cfgPhaseJumpsRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgPhaseJumpsThreshold", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgPhaseJumpsChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgPhaseJumpsActions", !cfgUserEnable || !isAdmin);
	}, [ cfgUserEnable ]);

	useEffect(() => {
		toggleDisable("cfgVoltageRmsRecOpt", false);
		toggleDisable("cfgVoltageRmsEventThresholdUserDip", false);
		toggleDisable("cfgVoltageRmsEventThresholdUserSwell", false);
		toggleDisable("cfgVoltageRmsEventThresholdUserInterruption", false);
		toggleDisable("cfgVoltageRmsEventThresholdMaxCh4", false);
		toggleDisable("cfgDipChannels", false);
		toggleDisable("cfgDipActions", false);
		toggleDisable("cfgSwellChannels", false);
		toggleDisable("cfgSwellActions", false);
		toggleDisable("cfgInterruptChannels", false);
		toggleDisable("cfgInterruptActions", false);

		toggleDisable("cfgWaveshapeVariationsRecOpt", false);
		toggleDisable("cfgWaveshapeVariationsDeadtime", false);
		toggleDisable("cfgWaveshapeVariationsThreshold", false);
		toggleDisable("cfgWaveshapeVariationsChannels", false);
		toggleDisable("cfgWaveshapeVariationsActions", false);

		toggleDisable("cfgPhaseJumpsRecOpt", false);
		toggleDisable("cfgPhaseJumpsThreshold", false);
		toggleDisable("cfgPhaseJumpsChannels", false);
		toggleDisable("cfgPhaseJumpsActions", false);

		handleChange("cfgVoltageRmsRecOpt", reducerForm.cfgVoltageRmsRecOpt.value);
		handleChange("cfgVoltageRmsEventThresholdUserDip", reducerForm.cfgVoltageRmsEventThresholdUserDip.value);
		handleChange("cfgVoltageRmsEventThresholdUserSwell", reducerForm.cfgVoltageRmsEventThresholdUserSwell.value);
		handleChange("cfgVoltageRmsEventThresholdUserInterruption", reducerForm.cfgVoltageRmsEventThresholdUserInterruption.value);
		handleChange("cfgVoltageRmsEventThresholdMaxCh4", reducerForm.cfgVoltageRmsEventThresholdMaxCh4.value);
		handleChange("cfgDipChannels", reducerForm.cfgDipChannels.value);
		handleChange("cfgDipActions", reducerForm.cfgDipActions.value);
		handleChange("cfgSwellChannels", reducerForm.cfgSwellChannels.value);
		handleChange("cfgSwellActions", reducerForm.cfgSwellActions.value);
		handleChange("cfgInterruptChannels", reducerForm.cfgInterruptChannels.value);
		handleChange("cfgInterruptActions", reducerForm.cfgInterruptActions.value);

		handleChange("cfgWaveshapeVariationsRecOpt", reducerForm.cfgWaveshapeVariationsRecOpt.value);
		handleChange("cfgWaveshapeVariationsDeadtime", reducerForm.cfgWaveshapeVariationsDeadtime.value);
		handleChange("cfgWaveshapeVariationsThreshold", reducerForm.cfgWaveshapeVariationsThreshold.value);
		handleChange("cfgWaveshapeVariationsChannels", reducerForm.cfgWaveshapeVariationsChannels.value);
		handleChange("cfgWaveshapeVariationsActions", reducerForm.cfgWaveshapeVariationsActions.value);

		handleChange("cfgPhaseJumpsRecOpt", reducerForm.cfgPhaseJumpsRecOpt.value);
		handleChange("cfgPhaseJumpsThreshold", reducerForm.cfgPhaseJumpsThreshold.value);
		handleChange("cfgPhaseJumpsChannels", reducerForm.cfgPhaseJumpsChannels.value);
		handleChange("cfgPhaseJumpsActions", reducerForm.cfgPhaseJumpsActions.value);

		toggleDisable("cfgVoltageRmsRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgVoltageRmsEventThresholdUserDip", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgVoltageRmsEventThresholdUserSwell", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgVoltageRmsEventThresholdUserInterruption", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgVoltageRmsEventThresholdMaxCh4", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgDipChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgDipActions", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgSwellChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgSwellActions", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgInterruptChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgInterruptActions", !cfgUserEnable || !isAdmin);

		toggleDisable("cfgWaveshapeVariationsRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgWaveshapeVariationsDeadtime", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgWaveshapeVariationsThreshold", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgWaveshapeVariationsChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgWaveshapeVariationsActions", !cfgUserEnable || !isAdmin);

		toggleDisable("cfgPhaseJumpsRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgPhaseJumpsThreshold", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgPhaseJumpsChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgPhaseJumpsActions", !cfgUserEnable || !isAdmin);
	}, [
		reducerForm.cfgVoltageRmsRecOpt.value,
		reducerForm.cfgVoltageRmsEventThresholdUserDip.value,
		reducerForm.cfgVoltageRmsEventThresholdUserSwell.value,
		reducerForm.cfgVoltageRmsEventThresholdUserInterruption.value,
		reducerForm.cfgVoltageRmsEventThresholdMaxCh4.value,
		reducerForm.cfgDipChannels.value,
		reducerForm.cfgDipActions.value,
		reducerForm.cfgSwellChannels.value,
		reducerForm.cfgSwellActions.value,
		reducerForm.cfgInterruptChannels.value,
		reducerForm.cfgInterruptActions.value,

		reducerForm.cfgWaveshapeVariationsRecOpt.value,
		reducerForm.cfgWaveshapeVariationsDeadtime.value,
		reducerForm.cfgWaveshapeVariationsThreshold.value,
		reducerForm.cfgWaveshapeVariationsChannels.value,
		reducerForm.cfgWaveshapeVariationsActions.value,

		reducerForm.cfgPhaseJumpsRecOpt.value,
		reducerForm.cfgPhaseJumpsThreshold.value,
		reducerForm.cfgPhaseJumpsChannels.value,
		reducerForm.cfgPhaseJumpsActions.value,
	]);

	const relativeValuesMask = getOptionMask(RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE, cfgVoltageRms?.cfgVoltageRmsRecOpt?.options ?? []) ?? 0;

	return (
		<Card className="bg-gray-50">
			<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
				{ title }
			</h5>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<BasicRecordingOptionConfiguration
					id={ ID_VOLTAGE }
					options={ cfgVoltageRms?.cfgVoltageRmsRecOpt?.options ?? [] }
					formItem={ form.cfgVoltageRmsRecOpt }
					handleChange={ value => handleChange("cfgVoltageRmsRecOpt", value) }
					reducerFormItem={ reducerForm.cfgVoltageRmsRecOpt } // fixme
				/>
				<div className="flex flex-col gap-3">
					<h5 className="text-base sm:text-xl font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
						{ t("MEASUREMENTS.events") }
					</h5>
					<EventRecordingOptionConfiguration
						id={ ID_VOLTAGE }
						options={ cfgVoltageRms?.cfgVoltageRmsRecOpt?.options ?? [] }
						formItem={ form.cfgVoltageRmsRecOpt }
						handleChange={ value => handleChange("cfgVoltageRmsRecOpt", value) }
						reducerFormItem={ reducerForm.cfgVoltageRmsRecOpt }
					>
						{
							(isLogEventEnabled) =>
								<div className="flex flex-col gap-2.5">
									<div className="flex flex-col gap-2.5">
										<h5 className="text-sm sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
											{ t("MEASUREMENTS.thresholds") }
										</h5>
										<div className="flex flex-col gap-2.5 flex-1">
											<div className="flex flex-col gap-2.5">
												<hr className="mx-2"/>
												<div className="flex gap-4">
													<div className="flex-[1_1_0%] flex gap-2.5">
														<div className="flex-[2_1_0%] flex flex-col justify-between gap-2.5">
															<Input
																formItem={ form.cfgVoltageRmsEventThresholdUserDip }
																label={ t("MEASUREMENTS.dips") }
																name="cfgVoltageRmsEventThresholdUserDip"
																inputProps={ {
																	type: "number",
																	onChange: (e) => handleChange("cfgVoltageRmsEventThresholdUserDip", e.target.value),
																	onBlur: () => handleBlur("cfgVoltageRmsEventThresholdUserDip"),
																	disabled: !isLogEventEnabled,
																	sizing: "sm",
																} }
																hasBeenChanged={ reducerForm.cfgVoltageRmsEventThresholdUserDip.value !== reducerForm.cfgVoltageRmsEventThresholdUserDip.initialValue }
															/>
															<Input
																formItem={ form.cfgVoltageRmsEventThresholdUserSwell }
																label={ t("MEASUREMENTS.swells") }
																name="cfgVoltageRmsEventThresholdUserSwell"
																inputProps={ {
																	type: "number",
																	onChange: (e) => handleChange("cfgVoltageRmsEventThresholdUserSwell", e.target.value),
																	onBlur: () => handleBlur("cfgVoltageRmsEventThresholdUserSwell"),
																	disabled: !isLogEventEnabled,
																	sizing: "sm",
																} }
																hasBeenChanged={ reducerForm.cfgVoltageRmsEventThresholdUserSwell.value !== reducerForm.cfgVoltageRmsEventThresholdUserSwell.initialValue }
															/>
															<Input
																formItem={ form.cfgVoltageRmsEventThresholdUserInterruption }
																label={ t("MEASUREMENTS.interruptions") }
																name="cfgVoltageRmsEventThresholdUserInterruption"
																inputProps={ {
																	type: "number",
																	onChange: (e) => handleChange("cfgVoltageRmsEventThresholdUserInterruption", e.target.value),
																	onBlur: () => handleBlur("cfgVoltageRmsEventThresholdUserInterruption"),
																	disabled: !isLogEventEnabled,
																	sizing: "sm",
																} }
																hasBeenChanged={ reducerForm.cfgVoltageRmsEventThresholdUserInterruption.value !== reducerForm.cfgVoltageRmsEventThresholdUserInterruption.initialValue }
															/>
														</div>
														<div className="flex-[1_1_0%] flex items-center">
															<RelativeValuesSelect
																className="flex flex-1"
																options={ cfgVoltageRms?.cfgVoltageRmsRecOpt?.options ?? [] }
																formItem={ form.cfgVoltageRmsRecOpt }
																onChange={ value => {
																	const newRelative = isOptionSelected(value, RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE, form.cfgVoltageRmsRecOptOptions.value);
																	const oldRelative = isOptionSelected(form.cfgVoltageRmsRecOpt.value, RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE, form.cfgVoltageRmsRecOptOptions.value);
																	handleChange("cfgVoltageRmsRecOpt", value);

																	if (newRelative !== oldRelative) {
																		if (newRelative) {
																			handleChange("cfgVoltageRmsEventThresholdUserDip", round((+form.cfgVoltageRmsEventThresholdUserDip.value / form.cfgNominalVoltage.value) * 100, 2));
																			handleChange("cfgVoltageRmsEventThresholdUserSwell", round((+form.cfgVoltageRmsEventThresholdUserSwell.value / form.cfgNominalVoltage.value) * 100, 2));
																			handleChange("cfgVoltageRmsEventThresholdUserInterruption", round((+form.cfgVoltageRmsEventThresholdUserInterruption.value / form.cfgNominalVoltage.value) * 100, 2));
																		} else {
																			handleChange("cfgVoltageRmsEventThresholdUserDip", round((+form.cfgVoltageRmsEventThresholdUserDip.value / 100) * form.cfgNominalVoltage.value, 2));
																			handleChange("cfgVoltageRmsEventThresholdUserSwell", round((+form.cfgVoltageRmsEventThresholdUserSwell.value / 100) * form.cfgNominalVoltage.value, 2));
																			handleChange("cfgVoltageRmsEventThresholdUserInterruption", round((+form.cfgVoltageRmsEventThresholdUserInterruption.value / 100) * form.cfgNominalVoltage.value, 2));
																		}

																		handleBlur("cfgVoltageRmsEventThresholdUserDip");
																		handleBlur("cfgVoltageRmsEventThresholdUserSwell");
																		handleBlur("cfgVoltageRmsEventThresholdUserInterruption");
																	}
																} }
																unit="V"
																unitPercent="% Un"
																inputHeight={ 34 }
																isDisabled={ !isLogEventEnabled }
																hasBeenChanged={ (reducerForm.cfgVoltageRmsRecOpt.value & relativeValuesMask) !== (reducerForm.cfgVoltageRmsRecOpt.initialValue & relativeValuesMask) }
															/>
														</div>
													</div>
													<div className="my-2 h-100 border-r border-gray-200"/>
													<div
														className={
															classNames(
																"flex flex-col justify-between gap-2.5",
																"xl:flex-[3_1_0%]",
																"flex-[2_1_0%]",
															)
														}
													>
														<div className="flex gap-2.5">
															<RelayChannelChooser
																options={ [
																	(isNotNull(cfgVoltageRms) && isNotNull(cfgVoltageRms.relayChannelLabel) && isNotNull(cfgVoltageRms.relayChannelLabel.ch1)) ? {
																		value: "cfgRelayVoltageDip1RecOpt",
																		label: cfgVoltageRms.relayChannelLabel.ch1,
																	} : null,
																	(isNotNull(cfgVoltageRms) && isNotNull(cfgVoltageRms.relayChannelLabel) && isNotNull(cfgVoltageRms.relayChannelLabel.ch2)) ? {
																		value: "cfgRelayVoltageDip2RecOpt",
																		label: cfgVoltageRms.relayChannelLabel.ch2,
																	} : null,
																	(isNotNull(cfgVoltageRms) && isNotNull(cfgVoltageRms.relayChannelLabel) && isNotNull(cfgVoltageRms.relayChannelLabel.ch3)) ? {
																		value: "cfgRelayVoltageDip3RecOpt",
																		label: cfgVoltageRms.relayChannelLabel.ch3,
																	} : null,
																	(isNotNull(cfgVoltageRms) && isNotNull(cfgVoltageRms.relayChannelLabel) && isNotNull(cfgVoltageRms.relayChannelLabel.ch4)) ? {
																		value: "cfgRelayVoltageDip4RecOpt",
																		label: cfgVoltageRms.relayChannelLabel.ch4,
																	} : null,
																].filter(isNotNull) }
																disabled={ !isLogEventEnabled }
																formItem={ form.cfgDipChannels }
																reducerFormItem={ reducerForm.cfgDipChannels }
																handleChange={ value => handleChange("cfgDipChannels", value) }
															/>
															<RelayActionSelect
																formItem={ form.cfgDipActions }
																reducerFormItem={ reducerForm.cfgDipActions }
																options={ cfgVoltageRms?.cfgRelayVoltageDip1Map?.options ?? [] }
																disabled={ !isLogEventEnabled }
																handleChange={ value => handleChange("cfgDipActions", value) }
															/>
														</div>
														<div className="flex gap-2.5">
															<RelayChannelChooser
																options={ [
																	(isNotNull(cfgVoltageRms) && isNotNull(cfgVoltageRms.relayChannelLabel) && isNotNull(cfgVoltageRms.relayChannelLabel.ch1)) ? {
																		value: "cfgRelayVoltageSwell1RecOpt",
																		label: cfgVoltageRms.relayChannelLabel.ch1,
																	} : null,
																	(isNotNull(cfgVoltageRms) && isNotNull(cfgVoltageRms.relayChannelLabel) && isNotNull(cfgVoltageRms.relayChannelLabel.ch2)) ? {
																		value: "cfgRelayVoltageSwell2RecOpt",
																		label: cfgVoltageRms.relayChannelLabel.ch2,
																	} : null,
																	(isNotNull(cfgVoltageRms) && isNotNull(cfgVoltageRms.relayChannelLabel) && isNotNull(cfgVoltageRms.relayChannelLabel.ch3)) ? {
																		value: "cfgRelayVoltageSwell3RecOpt",
																		label: cfgVoltageRms.relayChannelLabel.ch3,
																	} : null,
																	(isNotNull(cfgVoltageRms) && isNotNull(cfgVoltageRms.relayChannelLabel) && isNotNull(cfgVoltageRms.relayChannelLabel.ch4)) ? {
																		value: "cfgRelayVoltageSwell4RecOpt",
																		label: cfgVoltageRms.relayChannelLabel.ch4,
																	} : null,
																].filter(isNotNull) }
																disabled={ !isLogEventEnabled }
																formItem={ form.cfgSwellChannels }
																reducerFormItem={ reducerForm.cfgSwellChannels }
																handleChange={ value => handleChange("cfgSwellChannels", value) }
															/>
															<RelayActionSelect
																formItem={ form.cfgSwellActions }
																reducerFormItem={ reducerForm.cfgSwellActions }
																options={ cfgVoltageRms?.cfgRelayVolatgeSwell1Map?.options ?? [] }
																disabled={ !isLogEventEnabled || isEmptyArray(form.cfgSwellChannels.value) }
																handleChange={ value => handleChange("cfgSwellActions", value) }
															/>
														</div>
														<div className="flex gap-2.5">
															<RelayChannelChooser
																options={ [
																	(isNotNull(cfgVoltageRms) && isNotNull(cfgVoltageRms.relayChannelLabel) && isNotNull(cfgVoltageRms.relayChannelLabel.ch1)) ? {
																		value: "cfgRelayVoltageInterrupt1RecOpt",
																		label: cfgVoltageRms.relayChannelLabel.ch1,
																	} : null,
																	(isNotNull(cfgVoltageRms) && isNotNull(cfgVoltageRms.relayChannelLabel) && isNotNull(cfgVoltageRms.relayChannelLabel.ch2)) ? {
																		value: "cfgRelayVoltageInterrupt2RecOpt",
																		label: cfgVoltageRms.relayChannelLabel.ch2,
																	} : null,
																	(isNotNull(cfgVoltageRms) && isNotNull(cfgVoltageRms.relayChannelLabel) && isNotNull(cfgVoltageRms.relayChannelLabel.ch3)) ? {
																		value: "cfgRelayVoltageInterrupt3RecOpt",
																		label: cfgVoltageRms.relayChannelLabel.ch3,
																	} : null,
																	(isNotNull(cfgVoltageRms) && isNotNull(cfgVoltageRms.relayChannelLabel) && isNotNull(cfgVoltageRms.relayChannelLabel.ch4)) ? {
																		value: "cfgRelayVoltageInterrupt4RecOpt",
																		label: cfgVoltageRms.relayChannelLabel.ch4,
																	} : null,
																].filter(isNotNull) }
																disabled={ !isLogEventEnabled }
																formItem={ form.cfgInterruptChannels }
																reducerFormItem={ reducerForm.cfgInterruptChannels }
																handleChange={ value => handleChange("cfgInterruptChannels", value) }
															/>
															<RelayActionSelect
																formItem={ form.cfgInterruptActions }
																reducerFormItem={ reducerForm.cfgInterruptActions }
																options={ cfgVoltageRms?.cfgRelayVoltageInterrupt1Map?.options ?? [] }
																disabled={ !isLogEventEnabled || isEmptyArray(form.cfgInterruptChannels.value) }
																handleChange={ value => handleChange("cfgInterruptActions", value) }
															/>
														</div>
													</div>
												</div>
												<hr className="mx-2"/>
											</div>
											<div className="flex gap-4">
												<div
													className={
														classNames(
															"flex gap-2.5",
															"2xl:flex-[1_1_0%]",
															"xl:flex-[2_1_0%]",
															"flex-[3_1_0%]",
														)
													}
												>
													<Input
														className="flex-[2_1_0%]"
														formItem={ form.cfgVoltageRmsEventThresholdMaxCh4 }
														label={ t("MEASUREMENTS.N-E voltage over limit") }
														name="cfgVoltageRmsEventThresholdMaxCh4"
														inputProps={ {
															type: "number",
															onChange: (e) => handleChange("cfgVoltageRmsEventThresholdMaxCh4", e.target.value),
															onBlur: () => handleBlur("cfgVoltageRmsEventThresholdMaxCh4"),
															disabled: !isLogEventEnabled,
															sizing: "sm",
															className: "[&>span]:!min-w-[60px] [&>span]:justify-center",
														} }
														addonRight={ cfgVoltageRms?.cfgVoltageRmsEventThresholdMaxCh4?.unit ?? "-" }
														hasBeenChanged={ reducerForm.cfgVoltageRmsEventThresholdMaxCh4.value !== reducerForm.cfgVoltageRmsEventThresholdMaxCh4.initialValue }
													/>
													<div className="flex-[1_1_0%]"/>
												</div>
												<div className="w-[1px]"/>
												<div className="flex-[3_1_0%]"/>
											</div>
											<hr className="mx-2"/>
										</div>
									</div>
									<div className="flex flex-col gap-2.5">
										<EventRecordingOptionConfiguration
											childrenClassName="!ml-0"
											id={ ID_WAVESHAPE }
											disabled={ !isLogEventEnabled }
											options={ cfgWaveshapeVariations?.cfgWaveshapeVariationsRecOpt?.options ?? [] }
											formItem={ form.cfgWaveshapeVariationsRecOpt }
											handleChange={ value => handleChange("cfgWaveshapeVariationsRecOpt", value) }
											reducerFormItem={ reducerForm.cfgWaveshapeVariationsRecOpt }
											recordingDictionary={ () => ({
												[ RecordingOption.SET_REC_OPT_AVG ]: "",
												[ RecordingOption.SET_REC_OPT_MIN ]: "",
												[ RecordingOption.SET_REC_OPT_MAX ]: "",
												[ RecordingOption.SET_REC_OPT_INST ]: "",
												[ RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE ]: "",
												[ RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE ]: t("MEASUREMENTS.enable waveshape variations logging"),
												[ RecordingOption.SET_REC_OPT_EVT_WAVEFORM_ENABLE ]: "",
												[ RecordingOption.SET_REC_OPT_EVT_RMS12_ENABLE ]: "",
											}) }
											withoutLogEnableClassName
										>
											{
												(isLogEventEnabled) =>
													<div className="flex flex-col gap-1 flex-1">
														<div
															className={
																classNames(
																	"flex gap-2 flex-1",
																	"xl:flex-row",
																	"flex-col",
																)
															}
														>
															<div className="flex-[1_1_0%] flex gap-2.5">
																<Input
																	className="flex-1"
																	formItem={ form.cfgWaveshapeVariationsThreshold }
																	label={ t("MEASUREMENTS.waveshape variations") }
																	name="cfgWaveshapeVariationsThreshold"
																	inputProps={ {
																		type: "number",
																		onChange: (e) => handleChange("cfgWaveshapeVariationsThreshold", e.target.value),
																		onBlur: () => handleBlur("cfgWaveshapeVariationsThreshold"),
																		disabled: !isLogEventEnabled,
																		sizing: "sm",
																		className: "[&>span]:!min-w-[40px] [&>span]:justify-center",
																	} }
																	addonRight={ cfgWaveshapeVariations?.cfgWaveshapeVariationsThreshold?.unit ?? "-" }
																	hasBeenChanged={ reducerForm.cfgWaveshapeVariationsThreshold.value !== reducerForm.cfgWaveshapeVariationsThreshold.initialValue }
																/>
																<Input
																	className="flex-1"
																	formItem={ form.cfgWaveshapeVariationsDeadtime }
																	label={ t("MEASUREMENTS.hold off time") }
																	name="cfgWaveshapeVariationsDeadtime"
																	inputProps={ {
																		type: "number",
																		onChange: (e) => handleChange("cfgWaveshapeVariationsDeadtime", e.target.value),
																		onBlur: () => handleBlur("cfgWaveshapeVariationsDeadtime"),
																		disabled: !isLogEventEnabled,
																		sizing: "sm",
																		className: "[&>span]:!min-w-[40px] [&>span]:justify-center",
																	} }
																	addonRight={ cfgWaveshapeVariations?.cfgWaveshapeVariationsDeadtime?.unit ?? "-" }
																	hasBeenChanged={ reducerForm.cfgWaveshapeVariationsDeadtime.value !== reducerForm.cfgWaveshapeVariationsDeadtime.initialValue }
																/>
															</div>
															<div className="flex-[3_1_0%] flex gap-2.5">
																<RelayChannelChooser
																	options={ [
																		(isNotNull(cfgWaveshapeVariations) && isNotNull(cfgWaveshapeVariations.relayChannelLabel) && isNotNull(cfgWaveshapeVariations.relayChannelLabel.ch1)) ? {
																			value: "cfgRelayWaveshape1RecOpt",
																			label: cfgWaveshapeVariations.relayChannelLabel.ch1,
																		} : null,
																		(isNotNull(cfgWaveshapeVariations) && isNotNull(cfgWaveshapeVariations.relayChannelLabel) && isNotNull(cfgWaveshapeVariations.relayChannelLabel.ch2)) ? {
																			value: "cfgRelayWaveshape2RecOpt",
																			label: cfgWaveshapeVariations.relayChannelLabel.ch2,
																		} : null,
																		(isNotNull(cfgWaveshapeVariations) && isNotNull(cfgWaveshapeVariations.relayChannelLabel) && isNotNull(cfgWaveshapeVariations.relayChannelLabel.ch3)) ? {
																			value: "cfgRelayWaveshape3RecOpt",
																			label: cfgWaveshapeVariations.relayChannelLabel.ch3,
																		} : null,
																		(isNotNull(cfgWaveshapeVariations) && isNotNull(cfgWaveshapeVariations.relayChannelLabel) && isNotNull(cfgWaveshapeVariations.relayChannelLabel.ch4)) ? {
																			value: "cfgRelayWaveshape4RecOpt",
																			label: cfgWaveshapeVariations.relayChannelLabel.ch4,
																		} : null,
																	].filter(isNotNull) }
																	disabled={ !isLogEventEnabled }
																	formItem={ form.cfgWaveshapeVariationsChannels }
																	reducerFormItem={ reducerForm.cfgWaveshapeVariationsChannels }
																	handleChange={ value => handleChange("cfgWaveshapeVariationsChannels", value) }
																/>
																<RelayActionSelect
																	formItem={ form.cfgWaveshapeVariationsActions }
																	reducerFormItem={ reducerForm.cfgWaveshapeVariationsActions }
																	options={ cfgWaveshapeVariations?.cfgRelayWaveshape1Map?.options ?? [] }
																	disabled={ !isLogEventEnabled || isEmptyArray(form.cfgWaveshapeVariationsChannels.value) }
																	handleChange={ value => handleChange("cfgWaveshapeVariationsActions", value) }
																/>
															</div>
														</div>
													</div>
											}
										</EventRecordingOptionConfiguration>
										<hr className="mx-2"/>
										<EventRecordingOptionConfiguration
											childrenClassName="!ml-0"
											id={ ID_PHASE_JUMPS }
											disabled={ !isLogEventEnabled }
											options={ cfgPhaseJumps?.cfgPhaseJumpsRecOpt?.options ?? [] }
											formItem={ form.cfgPhaseJumpsRecOpt }
											handleChange={ value => handleChange("cfgPhaseJumpsRecOpt", value) }
											reducerFormItem={ reducerForm.cfgPhaseJumpsRecOpt }
											recordingDictionary={ () => ({
												[ RecordingOption.SET_REC_OPT_AVG ]: "",
												[ RecordingOption.SET_REC_OPT_MIN ]: "",
												[ RecordingOption.SET_REC_OPT_MAX ]: "",
												[ RecordingOption.SET_REC_OPT_INST ]: "",
												[ RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE ]: "",
												[ RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE ]: t("MEASUREMENTS.enable phase jumps logging"),
												[ RecordingOption.SET_REC_OPT_EVT_WAVEFORM_ENABLE ]: "",
												[ RecordingOption.SET_REC_OPT_EVT_RMS12_ENABLE ]: "",
											}) }
											withoutLogEnableClassName
										>
											{
												(isLogEventEnabled) =>
													<div className="flex gap-2.5 flex-1">
														<Input
															className="flex-1"
															formItem={ form.cfgPhaseJumpsThreshold }
															label={ t("MEASUREMENTS.phase jumps") }
															name="cfgPhaseJumpsThreshold"
															inputProps={ {
																type: "number",
																onChange: (e) => handleChange("cfgPhaseJumpsThreshold", e.target.value),
																onBlur: () => handleBlur("cfgPhaseJumpsThreshold"),
																disabled: !isLogEventEnabled,
																sizing: "sm",
																className: "[&>span]:!min-w-[40px] [&>span]:justify-center",
															} }
															addonRight={ cfgPhaseJumps?.cfgPhaseJumpsThreshold?.unit ?? "-" }
															hasBeenChanged={ reducerForm.cfgPhaseJumpsThreshold.value !== reducerForm.cfgPhaseJumpsThreshold.initialValue }
														/>
														<div
															className={
																classNames(
																	"flex gap-2",
																	"xl:flex-[7_1_0%]",
																	"flex-[4_1_0%]"
																)
															}
														>
															<RelayChannelChooser
																options={ [
																	(isNotNull(cfgPhaseJumps) && isNotNull(cfgPhaseJumps.relayChannelLabel) && isNotNull(cfgPhaseJumps.relayChannelLabel.ch1)) ? {
																		value: "cfgRelayPhaseJump1RecOpt",
																		label: cfgPhaseJumps.relayChannelLabel.ch1,
																	} : null,
																	(isNotNull(cfgPhaseJumps) && isNotNull(cfgPhaseJumps.relayChannelLabel) && isNotNull(cfgPhaseJumps.relayChannelLabel.ch2)) ? {
																		value: "cfgRelayPhaseJump2RecOpt",
																		label: cfgPhaseJumps.relayChannelLabel.ch2,
																	} : null,
																	(isNotNull(cfgPhaseJumps) && isNotNull(cfgPhaseJumps.relayChannelLabel) && isNotNull(cfgPhaseJumps.relayChannelLabel.ch3)) ? {
																		value: "cfgRelayPhaseJump3RecOpt",
																		label: cfgPhaseJumps.relayChannelLabel.ch3,
																	} : null,
																	(isNotNull(cfgPhaseJumps) && isNotNull(cfgPhaseJumps.relayChannelLabel) && isNotNull(cfgPhaseJumps.relayChannelLabel.ch4)) ? {
																		value: "cfgRelayPhaseJump4RecOpt",
																		label: cfgPhaseJumps.relayChannelLabel.ch4,
																	} : null,
																].filter(isNotNull) }
																disabled={ !isLogEventEnabled }
																formItem={ form.cfgPhaseJumpsChannels }
																reducerFormItem={ reducerForm.cfgPhaseJumpsChannels }
																handleChange={ value => handleChange("cfgPhaseJumpsChannels", value) }
															/>
															<RelayActionSelect
																formItem={ form.cfgPhaseJumpsActions }
																reducerFormItem={ reducerForm.cfgPhaseJumpsActions }
																options={ cfgPhaseJumps?.cfgRelayPhaseJump1Map?.options ?? [] }
																disabled={ !isLogEventEnabled || isEmptyArray(form.cfgPhaseJumpsChannels.value) }
																handleChange={ value => handleChange("cfgPhaseJumpsActions", value) }
															/>
														</div>
													</div>
											}
										</EventRecordingOptionConfiguration>
										<hr className="mx-2"/>
									</div>
								</div>
						}
					</EventRecordingOptionConfiguration>
				</div>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(VoltageRmsCard);
