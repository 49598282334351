import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { isEmptyString, isNotNull, isNull } from "src/app/utils/typeguards";
import { Link, matchRoutes, useLocation, useParams } from "react-router-dom";
import { UseParamsPayload } from "src/app/types/util.types";
import { RouteObject } from "react-router/dist/lib/context";
import { appRoutes } from "src/app/utils/routes/app.routes";
import { useCallback } from "react";
import { Breadcrumb } from "flowbite-react";
import classNames from "classnames";
import { HiChevronRight } from "react-icons/hi";
import { useTranslation } from "react-i18next";
// Breadcrumbs import plop - don't remove

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		className?: string
	};

function translateBreadcrumbName(breadcrumbName: string, translations: [ phrase: string, translation: string ][]): string {
	const translation = translations.find(translation => breadcrumbName.startsWith(translation[ 0 ]) && breadcrumbName.endsWith(translation[ 0 ]));
	return translation?.[ 1 ] ?? breadcrumbName;
}

function Breadcrumbs(props: Props) {
	const { t } = useTranslation();
	const location = useLocation();
	const params: UseParamsPayload = useParams();

	const getBreadcrumbRoutes = useCallback((routes: RouteObject[], withSpacers = true): string[] =>
			routes.map(route => {
				if (isNotNull(route.index) && route.index) { // Map index route
					return withSpacers ? [ "/" ] : [ "" ];
				} else if (isNotNull(route.path) && (isNull(route.children) || route.children.length === 0)) { // Map route without childrens
					return [ route.path ];
				} else if (isNotNull(route.children) && route.children.length !== 0) { // Map route with childrens, call self
					const nestedRoutes = getBreadcrumbRoutes(route.children, false);
					return nestedRoutes.map(nestedRoute =>
						`${ (route.path ?? "") }${ (isNull(route.path) || isEmptyString(nestedRoute)) ? "" : "/" }${ nestedRoute }`,
					);
				}
				return [];
			})
				  .reduce((prev, next) => [ ...prev, ...next ]) // string[][] => string[]
				  .filter((breadcrumbName, i, arr) => arr.indexOf(breadcrumbName) === i), // remove duplicates
		[]);

	const matchedRoutes = matchRoutes(getBreadcrumbRoutes(appRoutes).map(route => ({ path: route })), location);

	const url = (
		(isNotNull(matchedRoutes) && isNotNull(matchedRoutes[ 0 ].route.path))
			?
			matchedRoutes[ 0 ].route.path
			:
			location.pathname
	).split("/").slice(1); // Prepare url for mapping (delete first /)

	const {
		className,
		// Breadcrumbs props plop - don't remove
	} = props;

	return (
		<Breadcrumb className={ classNames(className) }>
			{
				url.map((breadcrumbEl, index, array) => {
					const breadcrumbName = translateBreadcrumbName(array[ index ],
						[
							[ "summary", t("SIDEBAR.summary") ],
							[ "realtime", `${ t("SIDEBAR.real time") }` ],
							[ "firmware-upgrade", `${ t("SIDEBAR.settings") } ➜ ${ t("SIDEBAR.firmware upgrade") }` ],
							[ "standard-events", `${ t("SIDEBAR.settings") } ➜ ${ t("SIDEBAR.events standard") }` ],
							[ "user-events", `${ t("SIDEBAR.settings") } ➜ ${ t("SIDEBAR.events user") }` ],
							[ "password", `${ t("SIDEBAR.settings") } ➜ ${ t("SIDEBAR.password") }` ],
							[ "analyzer", `${ t("SIDEBAR.settings") } ➜ ${ t("SIDEBAR.analyzer") }` ],
							[ "recordings", `${ t("SIDEBAR.settings") } ➜ ${ t("SIDEBAR.recordings") }` ],
							[ "communications", `${ t("SIDEBAR.settings") } ➜ ${ t("SIDEBAR.communications") }` ],
							[ "input-output", `${ t("SIDEBAR.settings") } ➜ ${ t("SIDEBAR.input output") }` ],
							[ "memory", `${ t("SIDEBAR.settings") } ➜ ${ t("SIDEBAR.memory") }` ],
							[ "ftp-client", `${ t("SIDEBAR.settings") } ➜ ${ t("SIDEBAR.ftp") }` ],
							array.includes("realtime") ? [ "measurements", `${ t("SIDEBAR.real time") } ➜ ${ t("SIDEBAR.measurements") }` ] : [ "measurements", `${ t("SIDEBAR.settings") } ➜ ${ t("SIDEBAR.measurements") }` ],
							[ "time", `${ t("SIDEBAR.settings") } ➜ ${ t("SIDEBAR.time") }` ],
							[ ":standardEventId", params.standardEventId ?? "" ],
							[ ":userEventId", params.userEventId ?? "" ],
							[ "waveforms", `${ t("SIDEBAR.real time") } ➜ ${ t("SIDEBAR.waveforms") }` ],
							[ "timeplot", `${ t("SIDEBAR.real time") } ➜ ${ t("SIDEBAR.timeplot") }` ],
							[ "phasors", `${ t("SIDEBAR.real time") } ➜ ${ t("SIDEBAR.phasors") }` ],
							[ "harmonics", `${ t("SIDEBAR.real time") } ➜ ${ t("SIDEBAR.harmonics") }` ],
							[ "interharmonics", `${ t("SIDEBAR.real time") } ➜ ${ t("SIDEBAR.interharmonics") }` ],
							[ "export-import", `${ t("SIDEBAR.settings") } ➜ ${ t("SIDEBAR.export import") }` ],
							[ "users", t("SIDEBAR.users") ],
						],
						// Breadcrumbs replace plop - don't remove
					);

					const link = location.pathname
										 .split("/")
										 .slice(1)
										 .slice(0, index + 1)
										 .join("/");

					if (array[ index ] === "realtime") return null;

					if (
						array.length - 1 === index ||
						array[ index ] === ":standardEventId" ||
						array[ index ] === ":userEventId"
					) {
						return (
							<li className="group flex items-center" key={ link }>
								{/*<HiChevronRight className="mx-1 h-6 w-6 text-gray-400 group-first:hidden md:mx-2"/>*/ }
								<span className="ml-1 group-first:hidden text-gray-500">➜</span>
								<span className="ml-1 flex items-center text-sm font-medium text-gray-500 dark:text-gray-400">
									{ `${ breadcrumbName[ 0 ]?.toUpperCase() }${ breadcrumbName.slice(1) }` }
								</span>
							</li>
						);
					}

					return (
						<li className="group flex items-center" key={ link }>
							<HiChevronRight className="mx-1 h-6 w-6 text-gray-400 group-first:hidden md:mx-2"/>
							<Link to={ link } className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
								{ `${ breadcrumbName[ 0 ]?.toUpperCase() }${ breadcrumbName.slice(1) }` }
							</Link>
						</li>
					);
				})
			}
		</Breadcrumb>
	);
}

const mapStateToProps = (state: RootState) => ({
	// Breadcrumbs mapStateToProps plop - don't remove
});

export default connect(mapStateToProps)(Breadcrumbs);
