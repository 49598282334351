import { RouteObject } from "react-router/dist/lib/context";
import AnalyzerView from "src/app/views/Settings/Analyzer.view";
import RecordingView from "src/app/views/Settings/Recordings.view";
import CommunicationsView from "src/app/views/Settings/Communications.view";
import InputOutputView from "src/app/views/Settings/InputOutput.view";
import MemoryView from "src/app/views/Settings/Memory.view";
import TimeView from "src/app/views/Settings/Time.view";
import MeasurementsView from "src/app/views/Settings/Measurements.view";
import FirmwareView from "src/app/views/Firmware/Firmware.view";
import ChangePasswordView from "src/app/views/User/ChangePassword.view";
import FtpView from "src/app/views/Settings/Ftp.view";
import ImportExportView from "src/app/views/Settings/ImportExport.view";

const settingsRoutes: RouteObject[] = [
	{
		path: "/analyzer",
		element: <AnalyzerView/>,
	}, {
		path: "/recordings",
		element: <RecordingView/>,
	}, {
		path: "/communications",
		element: <CommunicationsView/>,
	}, {
		path: "/input-output",
		element: <InputOutputView/>,
	}, {
		path: "/memory",
		element: <MemoryView/>,
	}, {
		path: "/ftp-client",
		element: <FtpView/>,
	}, {
		path: "/time",
		element: <TimeView/>,
	}, {
		path: "/measurements",
		element: <MeasurementsView/>,
	}, {
		path: "/firmware-upgrade",
		element: <FirmwareView/>,
	}, {
		path: "/password",
		element: <ChangePasswordView/>,
	}, {
		path: "/export-import",
		element: <ImportExportView/>,
	},
];

export default settingsRoutes;
