import Dropdown, { DropdownItem } from "src/app/components/Utils/Dropdown.component";
import { MdOutlineFileUpload } from "react-icons/md";
import moment from "moment/moment";
import { formatDuration, saveStockchart } from "src/app/utils/helpers";
import { isEmptyArray, isNotNull, isNull } from "src/app/utils/typeguards";
import { Card } from "flowbite-react";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { ElementSize } from "src/app/types/ui/layout.types";
import OneSideEventWaveformChart, { EventWaveformChartData } from "src/app/components/Chart/EventWaveform/OneSideEventWaveformChart.component";
import ChartFilter, { ChartFilterColor } from "src/app/components/Utils/ChartFilter.component";
import BothSideEventWaveformChart, { BothSideEventWaveformChartData } from "src/app/components/Chart/EventWaveform/BothSideEventWaveformChart.component";
import { SingleEvent } from "src/app/types/api/event.types";
import { EventWaveform } from "src/app/types/api/dashboard.types";
import LabelValue from "src/app/components/Utils/LabelValue.component";
import { useTranslation } from "react-i18next";
import { eventTypeDictionary } from "src/app/utils/constants/dictionaries";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		event: SingleEvent
		eventWaveform: EventWaveform
	};

const CARD_PADDING = 24 + 24 + 24 + 24;
const CARD_BORDER = 2 + 1;
const CARD_TITLE_HEIGHT = 40;
const CARD_SPACING = 24;
const CHART_FILTERS_HEIGHT = 78;
const CARD_INFO = 48;

export enum EventWaveformLine {
	U1 = "U1",
	U2 = "U2",
	U3 = "U3",
	U1PE = "U1PE",
	U2PE = "U2PE",
	U3PE = "U3PE",
	UNPE = "UNPE",
	U12 = "U12",
	U23 = "U23",
	U31 = "U31",
	I1 = "I1",
	I2 = "I2",
	I3 = "I3",
	I4 = "I4",
	I5 = "I5",
	INS = "INS",
}

export type EventWaveformConfigState = {
	[K in EventWaveformLine]: boolean
}

const EMPTY_SPACE_LENGTH = 0.05; // 5%

function EventWaveformContainer(props: Props) {

	const { t } = useTranslation();

	const {
		event,
		eventWaveform,
		mainSize,
		isSidebarOpen,
	} = props;

	const cardRef = useRef<HTMLDivElement>(null);

	const [ cardSize, setCardSize ] = useState<ElementSize>({
		width: 0,
		height: 600,
	});

	const [ eventWaveforms, setEventWaveForms ] = useState<EventWaveformConfigState>({
		[ EventWaveformLine.U1 ]: (!isEmptyArray(eventWaveform.left?.U1N ?? []) || !isEmptyArray(eventWaveform.right?.U1N ?? [])),
		[ EventWaveformLine.U2 ]: (!isEmptyArray(eventWaveform.left?.U2N ?? []) || !isEmptyArray(eventWaveform.right?.U2N ?? [])),
		[ EventWaveformLine.U3 ]: (!isEmptyArray(eventWaveform.left?.U3N ?? []) || !isEmptyArray(eventWaveform.right?.U3N ?? [])),
		[ EventWaveformLine.U1PE ]: false,
		[ EventWaveformLine.U2PE ]: false,
		[ EventWaveformLine.U3PE ]: false,
		[ EventWaveformLine.UNPE ]: false,
		[ EventWaveformLine.U12 ]: (!isEmptyArray(eventWaveform.left?.U12 ?? []) || !isEmptyArray(eventWaveform.right?.U12 ?? [])),
		[ EventWaveformLine.U23 ]: (!isEmptyArray(eventWaveform.left?.U23 ?? []) || !isEmptyArray(eventWaveform.right?.U23 ?? [])),
		[ EventWaveformLine.U31 ]: (!isEmptyArray(eventWaveform.left?.U31 ?? []) || !isEmptyArray(eventWaveform.right?.U31 ?? [])),
		[ EventWaveformLine.I1 ]: (!isEmptyArray(eventWaveform.left?.I1 ?? []) || !isEmptyArray(eventWaveform.right?.I1 ?? [])),
		[ EventWaveformLine.I2 ]: (!isEmptyArray(eventWaveform.left?.I2 ?? []) || !isEmptyArray(eventWaveform.right?.I2 ?? [])),
		[ EventWaveformLine.I3 ]: (!isEmptyArray(eventWaveform.left?.I3 ?? []) || !isEmptyArray(eventWaveform.right?.I3 ?? [])),
		[ EventWaveformLine.I4 ]: (!isEmptyArray(eventWaveform.left?.I4 ?? []) || !isEmptyArray(eventWaveform.right?.I4 ?? [])),
		[ EventWaveformLine.I5 ]: (!isEmptyArray(eventWaveform.left?.I5 ?? []) || !isEmptyArray(eventWaveform.right?.I5 ?? [])),
		[ EventWaveformLine.INS ]: false,
	});

	useEffect(() => {
		const chartHeight = mainSize.height - CARD_PADDING - CARD_BORDER - CARD_TITLE_HEIGHT - CARD_SPACING - CHART_FILTERS_HEIGHT - CARD_INFO;

		setCardSize(prevState => {
			if (isNotNull(cardRef.current)) {
				return {
					width: cardRef.current.getBoundingClientRect().width,
					height: chartHeight > 0 ? chartHeight : prevState.height,
				};
			}

			return {
				...prevState,
				height: chartHeight > 0 ? chartHeight : prevState.height,
			};
		});
	}, [ mainSize.width, mainSize.height, isSidebarOpen ]);

	const _exportToCsv = () => {
		const rows = (isNotNull(eventWaveform.left) && isNotNull(eventWaveform.right)) ? _getBothSideRows() : _getOneSideRows();

		let csvContent = "data:text/csv;charset=utf-8,"
			+ rows.map(e => e.join(",")).join("\n");

		const encodedUri = encodeURI(csvContent);
		let link = document.createElement("a");

		link.setAttribute("href", encodedUri);

		link.setAttribute("download", `PQM750_standard-event-waveform(${ event.events.event.id })_${ moment().format("YYYY-MM-DDTHH:mm:ss.SSS") }.csv`);

		document.body.appendChild(link);

		link.click();

		document.body.removeChild(link);
	};

	const _getOneSideRows = () => {
		const data = _getData();
		const u1Unit = "V";
		const u2Unit = "V";
		const u3Unit = "V";
		const u12Unit = "V";
		const u23Unit = "V";
		const u31Unit = "V";
		const u1PEUnit = "V";
		const u2PEUnit = "V";
		const u3PEUnit = "V";
		const unPEUnit = "V";
		const i1Unit = "A";
		const i2Unit = "A";
		const i3Unit = "A";
		const i4Unit = "A";
		const i5Unit = "A";
		const insUnit = "A";

		return [
			[
				t("TABLE.index") + "[us]",
				eventWaveforms.U1 ? `U1[${ u1Unit }]` : null,
				eventWaveforms.U2 ? `U2[${ u2Unit }]` : null,
				eventWaveforms.U3 ? `U3[${ u3Unit }]` : null,
				eventWaveforms.U12 ? `U12[${ u12Unit }]` : null,
				eventWaveforms.U23 ? `U23[${ u23Unit }]` : null,
				eventWaveforms.U31 ? `U31[${ u31Unit }]` : null,
				eventWaveforms.U1PE ? `U1E[${ u1PEUnit }]` : null,
				eventWaveforms.U2PE ? `U2E[${ u2PEUnit }]` : null,
				eventWaveforms.U3PE ? `U3E[${ u3PEUnit }]` : null,
				eventWaveforms.UNPE ? `UE[${ unPEUnit }]` : null,
				eventWaveforms.I1 ? `I1[${ i1Unit }]` : null,
				eventWaveforms.I2 ? `I2[${ i2Unit }]` : null,
				eventWaveforms.I3 ? `I3[${ i3Unit }]` : null,
				eventWaveforms.I4 ? `I4[${ i4Unit }]` : null,
				eventWaveforms.I5 ? `I5[${ i5Unit }]` : null,
				eventWaveforms.INS ? `I5[${ insUnit }]` : null,
			].filter(isNotNull),
			...data
				.map(dataItem =>
					[
						`"${ dataItem.sample.toFixed(0).replace(".", ",") }"`,
						eventWaveforms.U1 ? `"${ (dataItem.U1 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.U2 ? `"${ (dataItem.U2 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.U3 ? `"${ (dataItem.U3 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.U12 ? `"${ (dataItem.U12 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.U23 ? `"${ (dataItem.U23 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.U31 ? `"${ (dataItem.U31 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.U1PE ? `"${ (dataItem.U1PE ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.U2PE ? `"${ (dataItem.U2PE ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.U3PE ? `"${ (dataItem.U3PE ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.UNPE ? `"${ (dataItem.UNPE ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.I1 ? `"${ (dataItem.I1 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.I2 ? `"${ (dataItem.I2 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.I3 ? `"${ (dataItem.I3 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.I4 ? `"${ (dataItem.I4 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.I5 ? `"${ (dataItem.I5 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.INS ? `"${ (dataItem.INS ?? 0).toFixed(6).replace(".", ",") }"` : null,
					].filter(isNotNull),
				),
		];
	};

	const _getBothSideRows = () => {
		const data = _getBothSideData();
		const u1Unit = "V";
		const u2Unit = "V";
		const u3Unit = "V";
		const u12Unit = "V";
		const u23Unit = "V";
		const u31Unit = "V";
		const u1PEUnit = "V";
		const u2PEUnit = "V";
		const u3PEUnit = "V";
		const unPEUnit = "V";
		const i1Unit = "A";
		const i2Unit = "A";
		const i3Unit = "A";
		const i4Unit = "A";
		const i5Unit = "A";
		const insUnit = "A";

		return [
			[
				t("TABLE.index") + "[us]",
				eventWaveforms.U1 ? `U1[${ u1Unit }]` : null,
				eventWaveforms.U2 ? `U2[${ u2Unit }]` : null,
				eventWaveforms.U3 ? `U3[${ u3Unit }]` : null,
				eventWaveforms.U12 ? `U12[${ u12Unit }]` : null,
				eventWaveforms.U23 ? `U23[${ u23Unit }]` : null,
				eventWaveforms.U31 ? `U31[${ u31Unit }]` : null,
				eventWaveforms.U1PE ? `U1E[${ u1PEUnit }]` : null,
				eventWaveforms.U2PE ? `U2E[${ u2PEUnit }]` : null,
				eventWaveforms.U3PE ? `U3E[${ u3PEUnit }]` : null,
				eventWaveforms.UNPE ? `UE[${ unPEUnit }]` : null,
				eventWaveforms.I1 ? `I1[${ i1Unit }]` : null,
				eventWaveforms.I2 ? `I2[${ i2Unit }]` : null,
				eventWaveforms.I3 ? `I3[${ i3Unit }]` : null,
				eventWaveforms.I4 ? `I4[${ i4Unit }]` : null,
				eventWaveforms.I5 ? `I5[${ i5Unit }]` : null,
				eventWaveforms.INS ? `I5[${ insUnit }]` : null,
			].filter(isNotNull),
			...data
				.filter(dataItem => isNotNull(dataItem.sample))
				.map(dataItem =>
					[
						`"${ (dataItem.sample ?? 0).toFixed(0).replace(".", ",") }"`,
						eventWaveforms.U1 ? `"${ (dataItem.U1 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.U2 ? `"${ (dataItem.U2 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.U3 ? `"${ (dataItem.U3 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.U12 ? `"${ (dataItem.U12 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.U23 ? `"${ (dataItem.U23 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.U31 ? `"${ (dataItem.U31 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.U1PE ? `"${ (dataItem.U1PE ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.U2PE ? `"${ (dataItem.U2PE ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.U3PE ? `"${ (dataItem.U3PE ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.UNPE ? `"${ (dataItem.UNPE ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.I1 ? `"${ (dataItem.I1 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.I2 ? `"${ (dataItem.I2 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.I3 ? `"${ (dataItem.I3 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.I4 ? `"${ (dataItem.I4 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.I5 ? `"${ (dataItem.I5 ?? 0).toFixed(6).replace(".", ",") }"` : null,
						eventWaveforms.INS ? `"${ (dataItem.INS ?? 0).toFixed(6).replace(".", ",") }"` : null,
					].filter(isNotNull),
				),
		];
	};

	const _getData = () => {
		const data: EventWaveformChartData[] = [];

		if (isNotNull(eventWaveform.left)) {
			for (let i = 0 ; i < eventWaveform.left.sample.length ; i++) {
				data.push({
					U1: eventWaveform.left.U1N?.[ i ] ?? 0,
					U2: eventWaveform.left.U2N?.[ i ] ?? 0,
					U3: eventWaveform.left.U3N?.[ i ] ?? 0,
					U1PE: eventWaveform.left.U1PE?.[ i ] ?? 0,
					U2PE: eventWaveform.left.U2PE?.[ i ] ?? 0,
					U3PE: eventWaveform.left.U3PE?.[ i ] ?? 0,
					UNPE: eventWaveform.left.UNPE?.[ i ] ?? 0,
					U12: eventWaveform.left.U12?.[ i ] ?? 0,
					U23: eventWaveform.left.U23?.[ i ] ?? 0,
					U31: eventWaveform.left.U31?.[ i ] ?? 0,
					I1: eventWaveform.left.I1?.[ i ] ?? 0,
					I2: eventWaveform.left.I2?.[ i ] ?? 0,
					I3: eventWaveform.left.I3?.[ i ] ?? 0,
					I4: eventWaveform.left.I4?.[ i ] ?? 0,
					I5: eventWaveform.left.I5?.[ i ] ?? 0,
					INS: eventWaveform.left.INS?.[ i ] ?? 0,
					index: i,
					sample: eventWaveform.left.sample[ i ],
				});
			}
		} else if (isNotNull(eventWaveform.right)) {
			for (let i = 0 ; i < eventWaveform.right.sample.length ; i++) {
				data.push({
					U1: eventWaveform.right.U1N?.[ i ] ?? 0,
					U2: eventWaveform.right.U2N?.[ i ] ?? 0,
					U3: eventWaveform.right.U3N?.[ i ] ?? 0,
					U1PE: eventWaveform.right.U1PE?.[ i ] ?? 0,
					U2PE: eventWaveform.right.U2PE?.[ i ] ?? 0,
					U3PE: eventWaveform.right.U3PE?.[ i ] ?? 0,
					UNPE: eventWaveform.right.UNPE?.[ i ] ?? 0,
					U12: eventWaveform.right.U12?.[ i ] ?? 0,
					U23: eventWaveform.right.U23?.[ i ] ?? 0,
					U31: eventWaveform.right.U31?.[ i ] ?? 0,
					I1: eventWaveform.right.I1?.[ i ] ?? 0,
					I2: eventWaveform.right.I2?.[ i ] ?? 0,
					I3: eventWaveform.right.I3?.[ i ] ?? 0,
					I4: eventWaveform.right.I4?.[ i ] ?? 0,
					I5: eventWaveform.right.I5?.[ i ] ?? 0,
					INS: eventWaveform.right.INS?.[ i ] ?? 0,
					index: i,
					sample: eventWaveform.right.sample[ i ],
				});
			}
		}

		return data;
	};

	const _getBothSideData = () => {
		const data: BothSideEventWaveformChartData[] = [];

		if (isNull(eventWaveform.left) || isNull(eventWaveform.right)) return [];

		const sampleLength = eventWaveform.left.sample.length + eventWaveform.right.sample.length;
		const emptySpaceLength = Math.floor((EMPTY_SPACE_LENGTH * sampleLength) / (1 - EMPTY_SPACE_LENGTH));

		for (let i = 0 ; i < eventWaveform.left.sample.length ; i++) {
			data.push({
				U1: eventWaveform.left.U1N?.[ i ],
				U2: eventWaveform.left.U2N?.[ i ],
				U3: eventWaveform.left.U3N?.[ i ],
				U1PE: eventWaveform.left.U1PE?.[ i ],
				U2PE: eventWaveform.left.U2PE?.[ i ],
				U3PE: eventWaveform.left.U3PE?.[ i ],
				UNPE: eventWaveform.left.UNPE?.[ i ],
				U12: eventWaveform.left.U12?.[ i ],
				U23: eventWaveform.left.U23?.[ i ],
				U31: eventWaveform.left.U31?.[ i ],
				I1: eventWaveform.left.I1?.[ i ],
				I2: eventWaveform.left.I2?.[ i ],
				I3: eventWaveform.left.I3?.[ i ],
				I4: eventWaveform.left.I4?.[ i ],
				I5: eventWaveform.left.I5?.[ i ],
				INS: eventWaveform.left.INS?.[ i ],
				index: i,
				isEmpty: i === (eventWaveform.left.sample.length - 1),
				sample: eventWaveform.left.sample[ i ],
			});
		}

		for (let i = 0 ; i < emptySpaceLength ; i++) {
			data.push({
				U1: undefined,
				U2: undefined,
				U3: undefined,
				U1PE: undefined,
				U2PE: undefined,
				U3PE: undefined,
				UNPE: undefined,
				U12: undefined,
				U23: undefined,
				U31: undefined,
				I1: undefined,
				I2: undefined,
				I3: undefined,
				I4: undefined,
				I5: undefined,
				INS: undefined,
				index: eventWaveform.left.sample.length + i,
				isEmpty: true,
				sample: undefined,
			});
		}

		for (let i = 0 ; i < eventWaveform.right.sample.length ; i++) {
			data.push({
				U1: eventWaveform.right.U1N?.[ i ],
				U2: eventWaveform.right.U2N?.[ i ],
				U3: eventWaveform.right.U3N?.[ i ],
				U1PE: eventWaveform.right.U1PE?.[ i ],
				U2PE: eventWaveform.right.U2PE?.[ i ],
				U3PE: eventWaveform.right.U3PE?.[ i ],
				UNPE: eventWaveform.right.UNPE?.[ i ],
				U12: eventWaveform.right.U12?.[ i ],
				U23: eventWaveform.right.U23?.[ i ],
				U31: eventWaveform.right.U31?.[ i ],
				I1: eventWaveform.right.I1?.[ i ],
				I2: eventWaveform.right.I2?.[ i ],
				I3: eventWaveform.right.I3?.[ i ],
				I4: eventWaveform.right.I4?.[ i ],
				I5: eventWaveform.right.I5?.[ i ],
				INS: eventWaveform.right.INS?.[ i ],
				index: eventWaveform.left.sample.length + emptySpaceLength + i,
				isEmpty: i === 0,
				sample: eventWaveform.right.sample[ i ],
			});
		}

		return data;
	};

	const _getPretriggerIndex = () => {
		if (isNotNull(eventWaveform.left)) {
			return Math.max(eventWaveform.left.sample.findIndex(sample => sample > event.events.cfgWaveformPretriggerDuration.value * 1000), 0);
		} else if (isNotNull(eventWaveform.right)) {
			return Math.max(eventWaveform.right.sample.findIndex(sample => sample > event.events.cfgWaveformPretriggerDuration.value * 1000), 0);
		} else {
			return 0;
		}
	};

	const _getEndEventIndex = () => {
		if (isNotNull(eventWaveform.left)) {
			const index = Math.max(eventWaveform.left.sample.findIndex(sample => sample > (event.events.cfgWaveformPretriggerDuration.value * 1000) + event.events.event.duration), 0);
			return index > 0 ? index : undefined;
		} else if (isNotNull(eventWaveform.right)) {
			const index = Math.max(eventWaveform.right.sample.findIndex(sample => sample > (event.events.cfgWaveformPretriggerDuration.value * 1000) + event.events.event.duration), 0);
			return index > 0 ? index : undefined;
		} else {
			return undefined;
		}
	};

	const _getLeftPretriggerIndex = () => {
		if (isNull(eventWaveform.left)) return 0;

		return Math.max(eventWaveform.left.sample.findIndex(sample => sample > event.events.cfgWaveformPretriggerDuration.value * 1000), 0);
	};

	const _getRightPretriggerIndex = () => {
		if (isNull(eventWaveform.left) || isNull(eventWaveform.right)) return 0;

		const sampleLength = eventWaveform.left.sample.length + eventWaveform.right.sample.length;
		const emptySpaceLength = Math.floor((EMPTY_SPACE_LENGTH * sampleLength) / (1 - EMPTY_SPACE_LENGTH));

		return eventWaveform.left.sample.length + emptySpaceLength + Math.max(eventWaveform.right.sample.findIndex(sample => sample > event.events.cfgWaveformPretriggerDuration.value * 1000), 0);
	};

	const _getEventType = (eventType: number) => {
		const type = event.events.types.find(type => type.value === eventType);
		return type?.text ?? null;
	};

	const type = _getEventType(event.events.event.type);

	return (
		<Card className="[&>div]:p-0 [&>div]:gap-0">
			<div className="p-3 sm:p-6 flex justify-between items-center gap-2 pb-4 border-b border-gray-200">
				<div className="flex flex-col gap-2 w-full">
					<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">{ t("EVENTS.event waveform") }</h5>
					<div className="flex gap-16">
						<LabelValue
							label={ t("EVENTS.event id") }
							value={ event.events.event.id }
						/>
						<LabelValue
							label={ t("EVENTS.event type") }
							value={ isNotNull(type) ? eventTypeDictionary()[ type ] : t("EVENTS.unknown") }
						/>
						<LabelValue
							label={ t("EVENTS.event start") }
							value={ moment(event.events.event.timestampStart / 1000).format("YYYY-MM-DD HH:mm:ss.SSS") }
						/>
						<LabelValue
							label={ t("EVENTS.event duration") }
							value={ formatDuration(event.events.event.duration / 1000, "ms") }
						/>
					</div>
				</div>
				{
					(isNotNull(eventWaveform.left) || isNotNull(eventWaveform.right)) &&
                    <Dropdown
                        size="sm"
                        color="transparent"
                        label={
							<>
								<MdOutlineFileUpload className="mr-2 h-4 w-4 text-gray-600 dark:text-dark-textGray"/>
								{ t("UTILS.export") }
							</>
						}
                    >
                        <DropdownItem
                            onClick={ () => {
								const name = `PQM750_standard-event-waveform(${ event.events.event.id })_${ moment().format("MM-DD-HH-mm-ss") }`;
								saveStockchart(name, cardRef.current);
							} }
                        >
							{ t("UTILS.png") }
                        </DropdownItem>
                        <DropdownItem
                            onClick={ _exportToCsv }
                        >
							{ t("UTILS.csv") }
                        </DropdownItem>
                    </Dropdown>
				}
			</div>
			{
				(isNull(eventWaveform.left) && isNull(eventWaveform.right)) &&
                <div className="p-3 sm:p-6 flex justify-center text-xl font-semibold">{ t("EVENTS.no data available") }</div>
			}
			{
				(isNotNull(eventWaveform.left) || isNotNull(eventWaveform.right)) &&
                <div className="p-3 sm:p-6 flex flex-col gap-4">
                    <div className="flex items-center justify-center gap-x-8 gap-y-2.5 flex-wrap">
						{
							(
								(!isEmptyArray(eventWaveform.left?.U1N ?? []) || !isEmptyArray(eventWaveform.right?.U1N ?? [])) ||
								(!isEmptyArray(eventWaveform.left?.U2N ?? []) || !isEmptyArray(eventWaveform.right?.U2N ?? [])) ||
								(!isEmptyArray(eventWaveform.left?.U3N ?? []) || !isEmptyArray(eventWaveform.right?.U3N ?? [])) ||
								(!isEmptyArray(eventWaveform.left?.U12 ?? []) || !isEmptyArray(eventWaveform.right?.U12 ?? [])) ||
								(!isEmptyArray(eventWaveform.left?.U23 ?? []) || !isEmptyArray(eventWaveform.right?.U23 ?? [])) ||
								(!isEmptyArray(eventWaveform.left?.U31 ?? []) || !isEmptyArray(eventWaveform.right?.U31 ?? []))
							) &&
                            <div className="flex gap-2.5 items-center">
                                <span className="text-sm text-gray-500 font-medium">{ t("UTILS.voltages") }: </span>
								{
									(!isEmptyArray(eventWaveform.left?.U1N ?? []) || !isEmptyArray(eventWaveform.right?.U1N ?? [])) &&
                                    <ChartFilter
                                        text="U1"
                                        color={ ChartFilterColor.U1 }
                                        active={ eventWaveforms.U1 }
                                        onClick={ () =>
											setEventWaveForms(prevState => ({
												...prevState,
												[ EventWaveformLine.U1 ]: !prevState.U1,
											}))
										}
                                    />
								}
								{
									(!isEmptyArray(eventWaveform.left?.U2N ?? []) || !isEmptyArray(eventWaveform.right?.U2N ?? [])) &&
                                    <ChartFilter
                                        text="U2"
                                        color={ ChartFilterColor.U2 }
                                        active={ eventWaveforms.U2 }
                                        onClick={ () =>
											setEventWaveForms(prevState => ({
												...prevState,
												[ EventWaveformLine.U2 ]: !prevState.U2,
											}))
										}
                                    />
								}
								{
									(!isEmptyArray(eventWaveform.left?.U3N ?? []) || !isEmptyArray(eventWaveform.right?.U3N ?? [])) &&
                                    <ChartFilter
                                        text="U3"
                                        color={ ChartFilterColor.U3 }
                                        active={ eventWaveforms.U3 }
                                        onClick={ () =>
											setEventWaveForms(prevState => ({
												...prevState,
												[ EventWaveformLine.U3 ]: !prevState.U3,
											}))
										}
                                    />
								}
								{
									(!isEmptyArray(eventWaveform.left?.U12 ?? []) || !isEmptyArray(eventWaveform.right?.U12 ?? [])) &&
                                    <ChartFilter
                                        text="U12"
                                        color={ ChartFilterColor.U12 }
                                        active={ eventWaveforms.U12 }
                                        onClick={ () =>
											setEventWaveForms(prevState => ({
												...prevState,
												[ EventWaveformLine.U12 ]: !prevState.U12,
											}))
										}
                                    />
								}
								{
									(!isEmptyArray(eventWaveform.left?.U23 ?? []) || !isEmptyArray(eventWaveform.right?.U23 ?? [])) &&
                                    <ChartFilter
                                        text="U23"
                                        color={ ChartFilterColor.U23 }
                                        active={ eventWaveforms.U23 }
                                        onClick={ () =>
											setEventWaveForms(prevState => ({
												...prevState,
												[ EventWaveformLine.U23 ]: !prevState.U23,
											}))
										}
                                    />
								}
								{
									(!isEmptyArray(eventWaveform.left?.U31 ?? []) || !isEmptyArray(eventWaveform.right?.U31 ?? [])) &&
                                    <ChartFilter
                                        text="U31"
                                        color={ ChartFilterColor.U31 }
                                        active={ eventWaveforms.U31 }
                                        onClick={ () =>
											setEventWaveForms(prevState => ({
												...prevState,
												[ EventWaveformLine.U31 ]: !prevState.U31,
											}))
										}
                                    />
								}
                            </div>
						}
						{
							(
								(!isEmptyArray(eventWaveform.left?.U1PE ?? []) || !isEmptyArray(eventWaveform.right?.U1PE ?? [])) ||
								(!isEmptyArray(eventWaveform.left?.U2PE ?? []) || !isEmptyArray(eventWaveform.right?.U2PE ?? [])) ||
								(!isEmptyArray(eventWaveform.left?.U3PE ?? []) || !isEmptyArray(eventWaveform.right?.U3PE ?? [])) ||
								(!isEmptyArray(eventWaveform.left?.UNPE ?? []) || !isEmptyArray(eventWaveform.right?.UNPE ?? []))
							) &&
                            <div className="flex gap-2.5 items-center">
                                <span className="text-sm text-gray-500 font-medium">{ t("UTILS.voltages pe") }: </span>
								{
									(!isEmptyArray(eventWaveform.left?.U1PE ?? []) || !isEmptyArray(eventWaveform.right?.U1PE ?? [])) &&
                                    <ChartFilter
                                        text="U1E"
                                        color={ ChartFilterColor.U1 } // fixme: color of U1PE
                                        active={ eventWaveforms.U1PE }
                                        onClick={ () =>
											setEventWaveForms(prevState => ({
												...prevState,
												[ EventWaveformLine.U1PE ]: !prevState.U1PE,
											}))
										}
                                    />
								}
								{
									(!isEmptyArray(eventWaveform.left?.U2PE ?? []) || !isEmptyArray(eventWaveform.right?.U2PE ?? [])) &&
                                    <ChartFilter
                                        text="U2E"
                                        color={ ChartFilterColor.U2 } // fixme: color of U2PE
                                        active={ eventWaveforms.U2PE }
                                        onClick={ () =>
											setEventWaveForms(prevState => ({
												...prevState,
												[ EventWaveformLine.U2PE ]: !prevState.U2PE,
											}))
										}
                                    />
								}
								{
									(!isEmptyArray(eventWaveform.left?.U3PE ?? []) || !isEmptyArray(eventWaveform.right?.U3PE ?? [])) &&
                                    <ChartFilter
                                        text="U3E"
                                        color={ ChartFilterColor.U3 } // fixme: color of U3PE
                                        active={ eventWaveforms.U3PE }
                                        onClick={ () =>
											setEventWaveForms(prevState => ({
												...prevState,
												[ EventWaveformLine.U3PE ]: !prevState.U3PE,
											}))
										}
                                    />
								}
								{
									(!isEmptyArray(eventWaveform.left?.UNPE ?? []) || !isEmptyArray(eventWaveform.right?.UNPE ?? [])) &&
                                    <ChartFilter
                                        text="UE"
                                        color={ ChartFilterColor.U3 }
                                        active={ eventWaveforms.UNPE }
                                        onClick={ () =>
											setEventWaveForms(prevState => ({
												...prevState,
												[ EventWaveformLine.UNPE ]: !prevState.UNPE,
											}))
										}
                                    />
								}
                            </div>
						}
						{
							(
								(!isEmptyArray(eventWaveform.left?.I1 ?? []) || !isEmptyArray(eventWaveform.right?.I1 ?? [])) ||
								(!isEmptyArray(eventWaveform.left?.I2 ?? []) || !isEmptyArray(eventWaveform.right?.I2 ?? [])) ||
								(!isEmptyArray(eventWaveform.left?.I3 ?? []) || !isEmptyArray(eventWaveform.right?.I3 ?? [])) ||
								(!isEmptyArray(eventWaveform.left?.I4 ?? []) || !isEmptyArray(eventWaveform.right?.I4 ?? [])) ||
								(!isEmptyArray(eventWaveform.left?.I5 ?? []) || !isEmptyArray(eventWaveform.right?.I5 ?? [])) ||
								(!isEmptyArray(eventWaveform.left?.INS ?? []) || !isEmptyArray(eventWaveform.right?.INS ?? []))
							) &&
                            <div className="flex gap-2.5 items-center">
                                <span className="text-sm text-gray-500 font-medium">{ t("UTILS.currents") }: </span>
								{
									(!isEmptyArray(eventWaveform.left?.I1 ?? []) || !isEmptyArray(eventWaveform.right?.I1 ?? [])) &&
                                    <ChartFilter
                                        text="I1"
                                        color={ ChartFilterColor.I1 }
                                        active={ eventWaveforms.I1 }
                                        onClick={ () =>
											setEventWaveForms(prevState => ({
												...prevState,
												[ EventWaveformLine.I1 ]: !prevState.I1,
											}))
										}
                                    />
								}
								{
									(!isEmptyArray(eventWaveform.left?.I2 ?? []) || !isEmptyArray(eventWaveform.right?.I2 ?? [])) &&
                                    <ChartFilter
                                        text="I2"
                                        color={ ChartFilterColor.I2 }
                                        active={ eventWaveforms.I2 }
                                        onClick={ () =>
											setEventWaveForms(prevState => ({
												...prevState,
												[ EventWaveformLine.I2 ]: !prevState.I2,
											}))
										}
                                    />
								}
								{
									(!isEmptyArray(eventWaveform.left?.I3 ?? []) || !isEmptyArray(eventWaveform.right?.I3 ?? [])) &&
                                    <ChartFilter
                                        text="I3"
                                        color={ ChartFilterColor.I3 }
                                        active={ eventWaveforms.I3 }
                                        onClick={ () =>
											setEventWaveForms(prevState => ({
												...prevState,
												[ EventWaveformLine.I3 ]: !prevState.I3,
											}))
										}
                                    />
								}
								{
									(!isEmptyArray(eventWaveform.left?.I4 ?? []) || !isEmptyArray(eventWaveform.right?.I4 ?? [])) &&
                                    <ChartFilter
                                        text="I4"
                                        color={ ChartFilterColor.In }
                                        active={ eventWaveforms.I4 }
                                        onClick={ () =>
											setEventWaveForms(prevState => ({
												...prevState,
												[ EventWaveformLine.I4 ]: !prevState.I4,
											}))
										}
                                    />
								}
								{
									(!isEmptyArray(eventWaveform.left?.I5 ?? []) || !isEmptyArray(eventWaveform.right?.I5 ?? [])) &&
                                    <ChartFilter
                                        text="I5"
                                        color={ ChartFilterColor.Ie }
                                        active={ eventWaveforms.I5 }
                                        onClick={ () =>
											setEventWaveForms(prevState => ({
												...prevState,
												[ EventWaveformLine.I5 ]: !prevState.I5,
											}))
										}
                                    />
								}
								{
									(!isEmptyArray(eventWaveform.left?.INS ?? []) || !isEmptyArray(eventWaveform.right?.INS ?? [])) &&
                                    <ChartFilter
                                        text="INS"
                                        color={ ChartFilterColor.Ie }
                                        active={ eventWaveforms.INS }
                                        onClick={ () =>
											setEventWaveForms(prevState => ({
												...prevState,
												[ EventWaveformLine.INS ]: !prevState.INS,
											}))
										}
                                    />
								}
                            </div>
						}
                    </div>
                    <div ref={ cardRef }>
                        <div
                            style={ {
								position: "relative",
								width: cardSize.width,
								height: cardSize.height,
							} }
                        >
							{
								(cardSize.width > 0 && cardSize.height > 0) &&
                                <>
									{
										(isNull(eventWaveform.left) || isNull(eventWaveform.right)) ?
											<OneSideEventWaveformChart
												width={ cardSize.width }
												height={ cardSize.height }
												data={ _getData() }
												config={ eventWaveforms }
												pretriggerIndex={ _getPretriggerIndex() }
												endEventIndex={ _getEndEventIndex() }
												symetricYAxis
											/>
											:
											<BothSideEventWaveformChart
												width={ cardSize.width }
												height={ cardSize.height }
												data={ _getBothSideData() }
												config={ eventWaveforms }
												pretriggerLeftIndex={ _getLeftPretriggerIndex() }
												pretriggerRightIndex={ _getRightPretriggerIndex() }
												symetricYAxis
											/>
									}
                                </>
							}
                        </div>
                    </div>
                </div>
			}
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	mainSize: state.ui.layout.mainSize,
	isSidebarOpen: state.ui.layout.isSidebarOpen,
});

export default connect(mapStateToProps)(EventWaveformContainer);
