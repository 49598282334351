import { RecordingOption, SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import { useCollapse } from "react-collapsed";
import { HiChevronDown } from "react-icons/hi";
import classNames from "classnames";
import { Button, Card } from "flowbite-react";
import { FormValidator } from "src/app/types/ui/form.types";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { createFormField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import { useContext, useEffect } from "react";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { MeasurementsUserContext } from "src/app/components/Measurements/MeasurementsUserContent.component";
import EnumOptionsToggle from "src/app/components/Form/EnumOptionsToggle.component";
import { getOptionMask } from "src/app/utils/helpers";
import { useTranslation } from "react-i18next";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		isExpanded: boolean
		handleCollapse: () => void
		settingsMeasurementsUser: SettingsMeasurementsUser
	};

type AdditionalForm = {
	cfgPhasorRecOpt: number
}

const validator: FormValidator<AdditionalForm> = {
	cfgPhasorRecOpt: () => null,
};

function AdditionalsCard(props: Props) {

	const { t } = useTranslation();

	const {
		isExpanded,
		handleCollapse,
		settingsMeasurementsUser: {
			measurementUser,
		},
		isAdmin,
	} = props;

	const { cfgUserEnable } = useContext(MeasurementsUserContext);

	const reducerForm = useReducerForm(
		"buffor",
		bufforFormActions,
		() => undefined,
	);

	const _handleSubmit = (values: AdditionalForm) => {
		reducerForm.handleChange("cfgPhasorRecOpt", values.cfgPhasorRecOpt);
	};

	const _getInitialState = () => ({
		cfgPhasorRecOpt: createFormField((reducerForm.form.cfgPhasorRecOpt.value !== reducerForm.form.cfgPhasorRecOpt.initialValue) ? reducerForm.form.cfgPhasorRecOpt.value : measurementUser?.cfgPhasorRecOpt?.value ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ measurementUser ]);

	useEffect(() => {
		toggleDisable("cfgPhasorRecOpt", !cfgUserEnable || !isAdmin);
	}, [ cfgUserEnable ]);

	useEffect(() => {
		toggleDisable("cfgPhasorRecOpt", false);

		handleChange("cfgPhasorRecOpt", reducerForm.form.cfgPhasorRecOpt.value);

		toggleDisable("cfgPhasorRecOpt", !reducerForm.form.cfgUserEnable.value);
	}, [
		reducerForm.form.cfgPhasorRecOpt.value,
	]);

	const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

	return (
		<Card className="col-span-2 [&>div]:p-0 [&>div]:gap-0">
			<div
				className="cursor-pointer p-3 sm:p-6 flex justify-between items-center gap-2 pb-4"
				{ ...getToggleProps({ onClick: handleCollapse }) }
			>
				<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
					{ t("ADDITIONALS.additionals") }
				</h5>
				<HiChevronDown
					className={
						classNames(
							"w-7 h-7 my-auto text-dark-textGray transition duration-200",
							{ "rotate-180": isExpanded },
						)
					}
				/>
			</div>
			<div className="border-t border-gray-200" { ...getCollapseProps() }>
				<form noValidate onSubmit={ handleSubmit } className="p-6">
					<EnumOptionsToggle
						formItem={ form.cfgPhasorRecOpt }
						label={ t("ADDITIONALS.log phasors after each averaging period") }
						options={ measurementUser?.cfgPhasorRecOpt?.options ?? [] }
						option={ RecordingOption.SET_REC_OPT_INST }
						handleChange={ value => {
							console.log(value);
							handleChange("cfgPhasorRecOpt", value);
						} }
						hasBeenChanged={
							(reducerForm.form.cfgPhasorRecOpt.value & (getOptionMask(RecordingOption.SET_REC_OPT_INST, measurementUser?.cfgPhasorRecOpt?.options ?? []) ?? 0)) !== (reducerForm.form.cfgPhasorRecOpt.initialValue & (getOptionMask(RecordingOption.SET_REC_OPT_INST, measurementUser?.cfgPhasorRecOpt?.options ?? []) ?? 0))
						}
					/>
					{
						isAdmin &&
                        <div className="flex justify-end items-center gap-2">
                            <Button
                                color="primary"
                                type="submit"
                            >
								{ t("UTILS.save") }
                            </Button>
                        </div>
					}
				</form>
			</div>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(AdditionalsCard);
