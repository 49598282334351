import { from, merge, of } from "rxjs";
import { catchError, filter, map, mergeMap, tap } from "rxjs/operators";
import { apiAsync } from "src/app/store/features/api/api.actions";
import { RootEpic } from "src/app/store/root.epic";
import { getType, isActionOf } from "typesafe-actions";
import { ApiFailure, ApiSuccess } from "src/app/types/api/api.types";
import { RootAction } from "src/app/store/root.actions";
import { forgetSession, loginAsync } from "src/app/store/features/user/user.actions";
import { AddMessageContent, SnackbarMessageType, ToastType, View } from "src/app/types/ui/message.types";
import { addMessage, displayToast, removeMessagesFromView } from "src/app/store/features/message/message.actions";
import { DataState } from "src/app/types/redux.types";
import { pingFirmwareInfoAsync } from "src/app/store/features/firmware/firmware.actions";
import i18n from "src/app/translations/i18n";

export const apiAsyncEpic: RootEpic = (action$, state$, api) =>
	action$.pipe(
		filter(isActionOf(apiAsync.request)),
		mergeMap(action =>
			from(
				api.axios.request({
					url: action.payload.url,
					method: action.payload.method,
					data: action.payload.data,
					onUploadProgress: action.payload.onUploadProgress,
					responseType: action.payload.responseType,
					headers: {
						...action.payload.headers,
						...(state$.value.user.loggedUser.dataState === DataState.PRESENT ? { "Authorization": `Bearer ${ state$.value.user.loggedUser.data.jwt }` } : {}),
					},
					decoder: action.payload.decoder,
					timeout: action.payload.timeout,
					noCamelize: action.payload.noCamelize ?? false,
				}),
			).pipe(
				mergeMap(response => {
					if (response.status > 299) {
						if (response.status === 403) {
							let isLoginAction = false;
							let isPingFirmwareAction = false;
							try {
								isLoginAction = getType(action.payload.onFailure) === getType(loginAsync.failure);
							} catch (e) {
								console.log(e);
								console.error("Error during comparing failure actions");
							}
							try {
								isPingFirmwareAction = getType(action.payload.onFailure) === getType(pingFirmwareInfoAsync.failure);
							} catch (e) {
								console.log(e);
								console.error("Error during comparing failure actions");
							}
							if (isLoginAction) {
								return merge(
									of(apiAsync.failure({ data: { status: response.status, message: i18n.t("RESPONSES.API.unknown error") }, onFailure: action.payload.onFailure, withoutNotification: true })),
									of(removeMessagesFromView(View.LOGIN)),
									of(addMessage({ type: SnackbarMessageType.ERROR, view: View.LOGIN, message: AddMessageContent.LOGIN_INCORRECT_CREDENTIALS })),
								);
							} else if (isPingFirmwareAction) {
								return merge(
									of(apiAsync.failure({ data: { status: response.status, message: i18n.t("RESPONSES.API.unknown error") }, onFailure: action.payload.onFailure, withoutNotification: true })),
									of(forgetSession()),
								);
							}
							return merge(
								of(apiAsync.failure({ data: { status: response.status, message: i18n.t("RESPONSES.API.unknown error") }, onFailure: action.payload.onFailure, withoutNotification: true })),
								of(removeMessagesFromView(View.LOGIN)),
								of(addMessage({ type: SnackbarMessageType.ERROR, view: View.LOGIN, message: AddMessageContent.LOGIN_SESSION_EXPIRED })),
								of(forgetSession()),
							);
						}
						return of(apiAsync.failure({ data: { status: response.status, message: i18n.t("RESPONSES.API.unknown error") }, onFailure: action.payload.onFailure, withoutNotification: action.payload.withoutNotification ?? false }));
					} else {
						return of(apiAsync.success({ responseHeaders: response.headers, data: { data: response.data }, onSuccess: action.payload.onSuccess }));
					}
				}),
				catchError(error => {
					if (error.message === "canceled") {
						return merge(
							of(apiAsync.failure({
									data: {
										status: 502,
										message: error.toJSON().message,
									},
									onFailure: action.payload.onFailure,
									withoutNotification: true,
								}),
							),
							of(forgetSession()),
						);
					}

					let isPingFirmwareAction = false;
					try {
						isPingFirmwareAction = getType(action.payload.onFailure) === getType(pingFirmwareInfoAsync.failure);
					} catch (e) {
						console.log(e);
						console.error("Error during comparing failure actions");
					}
					return of(apiAsync.failure({
							data: {
								status: 502,
								message: error.toJSON().message,
							},
							onFailure: action.payload.onFailure,
							withoutNotification: isPingFirmwareAction,
						}),
					);
				}),
			),
		),
	);

export const apiAsyncSuccessEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(apiAsync.success)),
		tap(console.log),
		map((action: { payload: Required<ApiSuccess<any>> }) => (action.payload.onSuccess({ ...action.payload.data, meta: { responseHeaders: action.payload.responseHeaders } }) as RootAction)),
	);

export const apiAsyncFailureEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(apiAsync.failure)),
		tap(console.log),
		mergeMap((action: { payload: Required<ApiFailure<any>> }) => {
			if (action.payload.withoutNotification) {
				return of(action.payload.onFailure(action.payload.data) as RootAction);
			} else {
				return merge(
					of(action.payload.onFailure(action.payload.data) as RootAction),
					of(displayToast({ type: ToastType.ERROR, content: action.payload.data.message })),
				);
			}
		}),
	);
