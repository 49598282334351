export type ElementSize = {
	width: number
	height: number
}

export enum LayoutVariant {
	ALL = "ALL",
	CHART = "CHART",
	TABLE = "TABLE",
}
