import { createReducer } from "typesafe-actions";
import { addLoadingRecord, removeLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.actions";
import { LoadableType, LoadingRecord } from "src/app/types/ui/loading.types";

export const authLoadingRecord: LoadingRecord = {
	loadableType: LoadableType.AUTH_ME,
};

export const settingsLoadingRecords: LoadingRecord[] = [
	{ loadableType: LoadableType.FETCH_SETTINGS_RECORDING },
	{ loadableType: LoadableType.FETCH_SETTINGS_MODBUS },
	{ loadableType: LoadableType.FETCH_SETTINGS_TIME },
	{ loadableType: LoadableType.FETCH_SETTINGS_MEASUREMENTS_USER },
	{ loadableType: LoadableType.FETCH_SETTINGS_MEASUREMENTS_STANDARD },
	{ loadableType: LoadableType.FETCH_SETTINGS_MEMORY },
	{ loadableType: LoadableType.FETCH_SETTINGS_FTP },
	{ loadableType: LoadableType.FETCH_SETTINGS_IEC },
	{ loadableType: LoadableType.FETCH_SETTINGS_IO },
];

const reducer = createReducer([ authLoadingRecord, ...settingsLoadingRecords ] as LoadingRecord[])
	.handleAction([ addLoadingRecord ], (state, action) => state.concat(action.payload))
	.handleAction([ removeLoadingRecord ], (state, action) => {
		const newState: LoadingRecord[] = [];
		if (action.payload.loadableId == null) {
			for (let i = 0 ; i < state.length ; i++) {
				const item = state[ i ];
				if (item.loadableType !== action.payload.loadableType) {
					newState.push(item);
				}
			}
		} else {
			for (let i = 0 ; i < state.length ; i++) {
				const item = state[ i ];
				if (item.loadableId !== action.payload.loadableId || item.loadableType !== action.payload.loadableType) {
					newState.push(item);
				}
			}
		}
		return newState;
	});

export default reducer;
