import { createReducer } from "typesafe-actions";
import { uiAddViewToStack } from "src/app/store/features/ui/router/ui.router.actions";

const reducer = createReducer([] as string[])
	.handleAction([ uiAddViewToStack ], (state, action) => {
		const link = action.payload.location.pathname;
		return [ ...state, link ];
	});

export default reducer;
