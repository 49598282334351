import { Nullable } from "src/app/types/util.types";
import { Form, FormValidator } from "src/app/types/ui/form.types";
import { createFormField } from "src/app/utils/forms";

export enum FirmwareUpgradeStep {
	UPLOAD = "UPLOAD",
	INFO = "INFO",
	UPGRADE = "UPGRADE"
}

export type UploadFirmwareForm = {
	step: FirmwareUpgradeStep
	file: Nullable<File>
}

export const uploadFirmwareInitialState: Form<UploadFirmwareForm> = {
	step: createFormField(FirmwareUpgradeStep.UPLOAD),
	file: createFormField(null),
};

export const uploadFirmwareValidator: FormValidator<UploadFirmwareForm> = {
	step: () => null,
	file: () => null,
};
