import { RootState } from "src/app/store/root.reducer";
import { createSelector } from "reselect";
import { isNotNull } from "src/app/utils/typeguards";
import { initialStateReducer, mergeTwoStateReducers } from "src/app/utils/redux";
import { StateReducer } from "src/app/types/redux.types";
import { EventWaveform, RmsWaveform } from "src/app/types/api/dashboard.types";
import { SingleEvent } from "src/app/types/api/event.types";

const standardEventsSelector = (state: RootState) => state.dashboard.singleStandardEvents;
const userEventsSelector = (state: RootState) => state.dashboard.singleUserEvents;
const standardEventWaveformSelector = (state: RootState) => state.dashboard.standardEventsWaveform;
const userEventWaveformSelector = (state: RootState) => state.dashboard.userEventsWaveform;
const standardEventRmsWaveformSelector = (state: RootState) => state.dashboard.standardEventRmsWaveform;
const userEventRmsWaveformSelector = (state: RootState) => state.dashboard.userEventRmsWaveform;

export const getStandardEventById = createSelector(
	[
		standardEventsSelector,
		(_, eventId: number) => eventId,
	],
	(standardEvents, eventId): StateReducer<SingleEvent> => {
		const standardEvent = standardEvents.find(event => event.id === eventId);
		if (isNotNull(standardEvent)) {
			return standardEvent.reducer;
		} else {
			return initialStateReducer as StateReducer<SingleEvent>;
		}
	},
);

export const getUserEventById = createSelector(
	[
		userEventsSelector,
		(_, eventId: number) => eventId,
	],
	(userEvents, eventId): StateReducer<SingleEvent> => {
		const userEvent = userEvents.find(event => event.id === eventId);
		if (isNotNull(userEvent)) {
			return userEvent.reducer;
		} else {
			return initialStateReducer as StateReducer<SingleEvent>;
		}
	},
);

export const getStandardEventWaveform = createSelector(
	[
		standardEventWaveformSelector,
		(_, eventId: number) => eventId,
	],
	(standardEventWaveforms, eventId): StateReducer<EventWaveform> => {
		const standardEventWaveform = standardEventWaveforms.find(standardEventWaveform => standardEventWaveform.id === eventId);
		if (isNotNull(standardEventWaveform)) {
			return standardEventWaveform.reducer;
		} else {
			return initialStateReducer as StateReducer<EventWaveform>;
		}
	},
);

export const getUserEventWaveform = createSelector(
	[
		userEventWaveformSelector,
		(_, eventId: number) => eventId,
	],
	(userEventWaveforms, eventId): StateReducer<EventWaveform> => {
		const userEventWaveform = userEventWaveforms.find(userEventWaveform => userEventWaveform.id === eventId);
		if (isNotNull(userEventWaveform)) {
			return userEventWaveform.reducer;
		} else {
			return initialStateReducer as StateReducer<EventWaveform>;
		}
	},
);

export const getStandardEventRmsWaveform = createSelector(
	[
		standardEventRmsWaveformSelector,
		(_, eventId: number) => eventId,
	],
	(standardEventWaveforms, eventId): StateReducer<RmsWaveform> => {
		const standardEventWaveform = standardEventWaveforms.find(standardEventWaveform => standardEventWaveform.id === eventId);
		if (isNotNull(standardEventWaveform)) {
			return standardEventWaveform.reducer;
		} else {
			return initialStateReducer as StateReducer<RmsWaveform>;
		}
	},
);

export const getUserEventRmsWaveform = createSelector(
	[
		userEventRmsWaveformSelector,
		(_, eventId: number) => eventId,
	],
	(userEventWaveforms, eventId): StateReducer<RmsWaveform> => {
		const userEventWaveform = userEventWaveforms.find(userEventWaveform => userEventWaveform.id === eventId);
		if (isNotNull(userEventWaveform)) {
			return userEventWaveform.reducer;
		} else {
			return initialStateReducer as StateReducer<RmsWaveform>;
		}
	},
);

export const getStandardEventWithWaveform = createSelector(
	[
		getStandardEventById,
		getStandardEventWaveform,
	],
	(event, eventWaveform) =>
		mergeTwoStateReducers(
			event,
			eventWaveform,
			(event, eventWaveform) => ({
				event,
				eventWaveform,
			}),
		),
);

export const getUserEventWithWaveform = createSelector(
	[
		getUserEventById,
		getUserEventWaveform,
	],
	(event, eventWaveform) =>
		mergeTwoStateReducers(
			event,
			eventWaveform,
			(event, eventWaveform) => ({
				event,
				eventWaveform,
			}),
		),
);

export const getStandardEventWithRmsWaveform = createSelector(
	[
		getStandardEventById,
		getStandardEventRmsWaveform,
	],
	(event, eventWaveform) =>
		mergeTwoStateReducers(
			event,
			eventWaveform,
			(event, eventWaveform) => ({
				event,
				eventWaveform,
			}),
		),
);

export const getUserEventWithRmsWaveform = createSelector(
	[
		getUserEventById,
		getUserEventRmsWaveform,
	],
	(event, eventWaveform) =>
		mergeTwoStateReducers(
			event,
			eventWaveform,
			(event, eventWaveform) => ({
				event,
				eventWaveform,
			}),
		),
);
