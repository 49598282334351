import type { CustomFlowbiteTheme } from "flowbite-react";

const appTheme: CustomFlowbiteTheme = {
	helperText: {
		root: {
			base: "mt-0 text-sm text-red-600 dark:text-red-500",
		},
	},
	button: {
		color: {
			primary: "text-white bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800",
			light: "text-gray-900 bg-white border border-gray-300 enabled:hover:bg-gray-100 focus:ring-4 focus:ring-primary-300 :bg-gray-600 dark:text-white dark:border-gray-600 dark:enabled:hover:bg-gray-700 dark:enabled:hover:border-gray-700 dark:focus:ring-gray-700",
			transparent: "text-gray-600 dark:text-dark-textGray hover:text-gray-900 dark:hover:text-white hover:border-gray-300 dark:hover:border-dark-hoverBg hover:bg-gray-200 dark:hover:bg-dark-hoverBg focus:ring-gray-100 dark:focus:ring-gray-500 transition duration-100 p-0 [&>span]:p-2.5",
		},
		size: {
			md: "text-sm px-3 py-2",
		},
	},
	checkbox: {
		root: {
			base: "h-4 w-4 rounded border border-gray-300 bg-gray-100 focus:ring-2 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-primary-600 text-primary-600 disabled:cursor-not-allowed disabled:opacity-50",
		},
	},
	dropdown: {
		floating: {
			base: "z-10 w-fit rounded-xl divide-y divide-gray-100 shadow",
			content: "rounded-xl text-sm text-gray-700 dark:text-gray-200",
			target: "w-fit dark:text-white",
		},
		content: "",
	},
	modal: {
		root: {
			base: "fixed top-0 right-0 left-0 z-50 h-full overflow-y-auto overflow-x-hidden md:inset-0 md:h-full",
		},
		content: {
			base: "max-h-full relative h-full w-full p-4 md:h-auto max-w-6xl",
			inner: "relative rounded-lg bg-white shadow dark:bg-gray-800",
		},
		header: {
			base: "flex items-start justify-between rounded-t px-5 pt-5",
		},
	},
	navbar: {
		root: {
			base: "fixed z-30 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700",
		},
	},
	sidebar: {
		root: {
			base: "flex fixed top-0 left-0 z-20 flex-col flex-shrink-0 pt-16 h-full duration-75 border-r border-gray-200 lg:flex transition-width dark:border-gray-700",
			collapsed: {
				off: "w-60",
				on: "[&_li]:flex [&_li]:items-center [&_li]:flex-col [&_div[role=tooltip]:flex]",
			},
		},
		item: {
			base: "flex items-center justify-center rounded-lg p-2 text-base font-semibold text-gray-900 hover:bg-primary-200 dark:text-white dark:hover:bg-primary-200 [&>svg]:text-primary-400 [&:hover>svg]:text-primary-400",
			content: {
				base: "px-3 flex-1 whitespace-nowrap text-[15px]",
			},
			icon: {
				base: "h-5 w-5 text-gray-500 transition duration-75 text-primary-400 group-hover:text-primary-400 dark:text-gray-400 dark:group-hover:text-white",
			},
		},
		collapse: {
			button: "group flex w-full items-center rounded-lg p-2 text-base font-semibold text-gray-900 transition duration-75 hover:bg-primary-200 dark:text-white dark:hover:bg-primary-200 [&>svg:last-child]:fill-gray-500",
			label: {
				base: "ml-2.5 flex-1 whitespace-nowrap text-left text-[15px]",
			},
			list: "flex flex-col space-y-1 py-2",
			icon: {
				base: "h-5 w-5 text-gray-500 transition duration-75 text-primary-400 group-hover:text-primary-400 dark:text-gray-400 dark:group-hover:text-white",
				open: {
					on: "h-5 w-5 text-gray-500 transition duration-75 text-primary-400 group-hover:text-primary-400 dark:text-gray-400 dark:group-hover:text-white",
					off: "h-5 w-5 text-gray-500 transition duration-75 text-primary-400 group-hover:text-primary-400 dark:text-gray-400 dark:group-hover:text-white",
				},
			},
		},
	},
	textInput: {
		field: {
			input: {
				base: "block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500",
				colors: {
					gray: "", // For clearing whole input classNames
				},
			},
		},
	},
	textarea: {
		base: "block w-full text-sm p-4 rounded-lg border disabled:cursor-not-allowed disabled:opacity-50",
	},
	toggleSwitch: {
		toggle: {
			checked: {
				off: "!border-gray-200 !bg-gray-200 dark:!border-gray-600 dark:!bg-gray-700",
			},
		},
	},
	avatar: {
		root: {
			base: "flex justify-center items-center space-x-4 rounded",
			initials: {
				text: "font-medium text-gray-600 dark:text-gray-300",
				base: "inline-flex overflow-hidden relative justify-center items-center bg-gray-100 dark:bg-gray-600 rounded leading-none",
			},
			img: {
				base: "rounded bg-gray-100 dark:bg-gray-600",
				off: "relative overflow-hidden bg-gray-100 dark:bg-gray-600",
				on: "",
				placeholder: "absolute w-auto h-auto text-gray-400 -bottom-1",
			},
		},
	},
	tabs: {
		tablist: {
			base: "flex text-center flex-wrap -mb-px border-b border-gray-200",
			tabitem: {
				base: "flex items-center justify-center p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500",
			},
		},
	},
	badge: {
		root: {
			color: {
				voltages: "bg-categories-voltages-200 text-categories-voltages-600 group-hover:bg-categories-voltages-200",
				currents: "bg-categories-currents-200 text-categories-currents-600 group-hover:bg-categories-currents-200",
				activePowers: "bg-categories-activePowers-200 text-categories-activePowers-800 group-hover:bg-categories-activePowers-200",
				reactivePowers: "bg-categories-reactivePowers-200 text-categories-reactivePowers-600 group-hover:bg-categories-reactivePowers-200",
				apparentPowers: "bg-categories-apparentPowers-200 text-categories-apparentPowers-600 group-hover:bg-categories-apparentPowers-200",
				frequencies: "bg-categories-frequencies-200 text-categories-frequencies-600 group-hover:bg-categories-frequencies-200",
				energies: "bg-categories-energies-200 text-categories-energies-600 group-hover:bg-categories-energies-200",
				phasors: "bg-categories-phasors-200 text-categories-phasors-600 group-hover:bg-categories-phasors-200",
				factors: "bg-categories-factors-200 text-categories-factors-600 group-hover:bg-categories-factors-200",
				harmonics: "bg-categories-harmonics-200 text-categories-harmonics-600 group-hover:bg-categories-harmonics-200",
				interharmonics: "bg-categories-interharmonics-200 text-categories-interharmonics-600 group-hover:bg-categories-interharmonics-200",
			},
		},
	},
};

export const appColors = {
	U1: "#60a5fa",
	U2: "#2563eb",
	U3: "#1e40af",
	Un: "#172554",
	U12: "#22d3ee",
	U23: "#0891b2",
	U31: "#155e75",
	I1: "#f87171",
	I2: "#dc2626",
	I3: "#7f1d1d",
	In: "#f472b6", // I4
	Ie: "#db2777", // I5
	L1P: "#a3e635",
	L2P: "#65a30d",
	L3P: "#3f6212",
	TotP: "#1a2e05",
	L1Q: "#a78bfa",
	L2Q: "#7c3aed",
	L3Q: "#5b21b6",
	TotQ: "#2e1065",
	L1S: "#9ca3af",
	L2S: "#4b5563",
	L3S: "#1f2937",
	TotS: "#030712",
	frequency: "#ca8a04",
};

export default appTheme;
