// Toasts
export enum ToastType {
	INFO = "info",
	SUCCESS = "success",
	WARNING = "warning",
	ERROR = "error"
}

export type DisplayToastPayload = {
	type: ToastType
	content: string
};

// Messages
export enum View {
	LOGIN = "LOGIN",
	FORGOT_PASSWORD = "FORGOT_PASSWORD",
	FORGOT_PASSWORD_MESSAGE = "FORGOT_PASSWORD_MESSAGE",
	TWO_FACTOR_AUTHENTICATION_MODAL = "TWO_FACTOR_AUTHENTICATION_MODAL",
	FTP = "FTP"
}

export enum SnackbarMessageType {
	ERROR = "ERROR",
	WARNING = "WARNING",
	INFO = "INFO",
	SUCCESS = "SUCCESS"
}

export type SnackbarMessage = {
	view: View
	type: SnackbarMessageType
	message: AddMessageContent
};

export type ReducerSnackbarMessage =
	SnackbarMessage
	& {
		id: number
	};

export enum AddMessageContent {
	LOGIN_INCORRECT_CREDENTIALS = "LOGIN_INCORRECT_CREDENTIALS",
	LOGIN_SESSION_EXPIRED = "LOGIN_SESSION_EXPIRED",
	LOGIN_FIRMWARE_UPGRADE_SUCCESS = "LOGIN_FIRMWARE_UPGRADE_SUCCESS",
	LOGIN_FIRMWARE_UPGRADE_FAILURE = "LOGIN_FIRMWARE_UPGRADE_FAILURE",
	FTP_TEST_SUCCESS = "FTP_TEST_SUCCESS",
	FTP_TEST_FAILURE = "FTP_TEST_FAILURE"
}