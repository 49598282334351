import { FormItem } from "src/app/types/ui/form.types";
import ReactSelect, { MenuPlacement } from "react-select";
import { isNotNull } from "src/app/utils/typeguards";
import { useState } from "react";
import classNames from "classnames";
import { Nullable, SelectOption } from "src/app/types/util.types";
import { Label, LabelProps } from "flowbite-react";
import { getSelectClassNames, getSelectStyles } from "src/app/utils/ui";
import { useTranslation } from 'react-i18next';

type Props<T> =
	{
		className?: string
		label?: string | ReactNode
		labelProps?: LabelProps
		options: SelectOption<T>[]
		formItem: FormItem<T>
		onChange: (option: Nullable<SelectOption<T>>) => void
		displayErrorMessage?: boolean
		isSearchable?: boolean
		isClearable?: boolean
		portalEl?: HTMLElement
		menuPlacement?: MenuPlacement
		optionsHeight?: number
		inputHeight?: number
		hasBeenChanged?: boolean
		helperText?: string
		isDisabled?: boolean
	}
	& (T extends object ? { compareValue: (a: T, b: T) => boolean } : { compareValue?: never })

function Select<T>(props: Props<T>) {

	const { t } = useTranslation();

	const {
		className,
		label,
		labelProps,
		options,
		formItem,
		onChange,
		displayErrorMessage = true,
		isSearchable = true,
		isClearable = true,
		compareValue,
		portalEl,
		menuPlacement = "auto",
		optionsHeight = 240,
		inputHeight = 42,
		hasBeenChanged = false,
		helperText,
		isDisabled = false,
	} = props;

	const [ inputValue, onInputValueChange ] = useState("");

	const _handleInputChange = (value: string) => {
		if (isSearchable) {
			onInputValueChange(value);
		}
	};

	return (
		<div className={ classNames(className, "flex flex-col gap-y-0.5") }>
			{
				isNotNull(label) &&
                <Label
                    disabled={ formItem.disabled || isDisabled }
					{ ...labelProps }
				>
					{ label }
                </Label>
			}
			<ReactSelect
				styles={ getSelectStyles<T, false>(optionsHeight, inputHeight) }
				classNames={ getSelectClassNames<T, false>(formItem, isClearable, hasBeenChanged) }
				options={ options }
				value={ options.find(option => compareValue?.(option.value, formItem.value) ?? option.value === formItem.value) ?? null }
				isMulti={ false }
				menuPortalTarget={ portalEl }
				onChange={ onChange }
				isOptionDisabled={ option => isNotNull(option.disabled) && option.disabled }
				inputValue={ inputValue }
				onInputChange={ _handleInputChange }
				isDisabled={ formItem.disabled || isDisabled }
				isSearchable={ isSearchable }
				isClearable={ isClearable }
				menuPlacement={ menuPlacement }
				placeholder={t('SETTINGS.select option')}
				// menuIsOpen={ true }
			/>
			{
				(isNotNull(formItem.error) && displayErrorMessage)
					?
					<div className="text-sm text-red-600 dark:text-red-500 font-medium">
						{ formItem.error }
					</div>
					:
					isNotNull(helperText) && <><span className="text-sm text-gray-500">{ helperText }</span></>
			}
		</div>
	);
}

export default Select;
