import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import { Button, Card, ToggleSwitch } from "flowbite-react";
import { createFormField, validateNumberField } from "src/app/utils/forms";
import { OnOff, SettingsModbus } from "src/app/types/api/settings.types";
import useForm from "src/app/utils/hooks/useForm";
import { FormValidator } from "src/app/types/ui/form.types";
import classNames from "classnames";
import { mapEnumSettingToSelectOptions } from "src/app/utils/helpers";
import { modbusEndiannessDictionary } from "src/app/utils/constants/dictionaries";
import { isNull } from "src/app/utils/typeguards";
import Select from "src/app/components/Form/Select.component";
import Input from "src/app/components/Form/Input.component";
import { useEffect } from "react";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { useTranslation } from 'react-i18next';
import i18n from "src/app/translations/i18n";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsModbus: SettingsModbus
	};

type ModbusTcpForm = {
	tcpEnabled: boolean
	// tcpSlaveAddress: string
	// tcpSlaveAddressMinValue: number
	// tcpSlaveAddressMaxValue: number
	tcpPort: string
	tcpPortMinValue: number
	tcpPortMaxValue: number
	tcpEndianness: number
}

const validator: FormValidator<ModbusTcpForm> = {
	tcpEnabled: () => null,
	// tcpSlaveAddressMinValue: () => null,
	// tcpSlaveAddressMaxValue: () => null,
	// tcpSlaveAddress: (tcpSlaveAddress, optional, form) => validateNumberField("Slave address", tcpSlaveAddress, optional, form.tcpSlaveAddressMinValue.value, form.tcpSlaveAddressMaxValue.value),
	tcpPortMinValue: () => null,
	tcpPortMaxValue: () => null,
	tcpPort: (tcpPort, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.port"), tcpPort, optional, "he", { from: form.tcpPortMinValue.value, to: form.tcpPortMaxValue.value }),
	tcpEndianness: () => null,
};

function ModbusTcpCard(props: Props) {

	const { t } = useTranslation();

	const {
		settingsModbus,
		isAdmin,
	} = props;

	const reducerForm = useReducerForm(
		"buffor",
		bufforFormActions,
		() => undefined,
	);

	const _handleSubmit = (values: ModbusTcpForm) => {
		reducerForm.handleChange("tcpEnabled", values.tcpEnabled);
		// reducerForm.handleChange("tcpSlaveAddress", values.tcpSlaveAddress);
		reducerForm.handleChange("tcpPort", values.tcpPort);
		reducerForm.handleChange("tcpEndianness", values.tcpEndianness);
	};

	const _getInitialState = () => {
		const tcpEnabled = (reducerForm.form.tcpEnabled.value !== reducerForm.form.tcpEnabled.initialValue) ? reducerForm.form.tcpEnabled.value : settingsModbus.communications?.cfgModbusTcpEnable?.enum?.find(enumValue => enumValue.value === settingsModbus.communications?.cfgModbusTcpEnable?.value)?.text === OnOff.ON;

		return {
			tcpEnabled: createFormField(tcpEnabled, { disabled: !isAdmin }),
			// tcpSlaveAddress: createFormField((reducerForm.form.tcpSlaveAddress.value !== reducerForm.form.tcpSlaveAddress.initialValue) ? reducerForm.form.tcpSlaveAddress.value.toString() : settingsModbus.communications.cfgModbusTcpSlaveAddress?.value.toString(), { disabled: !isAdmin || !tcpEnabled }),
			// tcpSlaveAddressMinValue: createFormField(settingsModbus.communications.cfgModbusTcpSlaveAddress?.minValue ?? 1),
			// tcpSlaveAddressMaxValue: createFormField(settingsModbus.communications.cfgModbusTcpSlaveAddress?.maxValue ?? 247),
			tcpPort: createFormField((reducerForm.form.tcpPort.value !== reducerForm.form.tcpPort.initialValue) ? reducerForm.form.tcpPort.value.toString() : settingsModbus.communications?.cfgModbusTcpPort?.value?.toString() ?? "", { disabled: true }),
			tcpPortMinValue: createFormField(settingsModbus.communications?.cfgModbusTcpPort?.minValue ?? 0),
			tcpPortMaxValue: createFormField(settingsModbus.communications?.cfgModbusTcpPort?.maxValue ?? 65535),
			tcpEndianness: createFormField((reducerForm.form.tcpEndianness.value !== reducerForm.form.tcpEndianness.initialValue) ? reducerForm.form.tcpEndianness.value : settingsModbus.communications?.cfgModbusTcpEndianness?.value ?? 0, { disabled: true }),
		};
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ settingsModbus ]);

	useEffect(() => {
		// toggleDisable("tcpSlaveAddress", false);

		handleChange("tcpEnabled", reducerForm.form.tcpEnabled.value);
		// handleChange("tcpSlaveAddress", reducerForm.form.tcpSlaveAddress.value);
		handleChange("tcpPort", reducerForm.form.tcpPort.value);
		handleChange("tcpEndianness", reducerForm.form.tcpEndianness.value);

		// toggleDisable("tcpSlaveAddress", !reducerForm.form.tcpEnabled.value);
	}, [
		reducerForm.form.tcpEnabled.value,
		// reducerForm.form.tcpSlaveAddress.value,
		reducerForm.form.tcpPort.value,
		reducerForm.form.tcpEndianness.value,
	]);

	return (
		<Card>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<ToggleSwitch
					className={
						classNames(
							"[&>div]:w-9 [&>div]:h-5 [&>div]:after:absolute [&>div]:after:top-[2px] [&>div]:after:left-[2px] [&>div]:after:h-4 [&>div]:after:w-4",
							{ "[&>span]:!text-yellow-400": reducerForm.form.tcpEnabled.value !== reducerForm.form.tcpEnabled.initialValue },
						)
					}
					disabled={ form.tcpEnabled.disabled }
					checked={ form.tcpEnabled.value }
					label={t('COMMUNICATIONS.enable')}
					onChange={ () => {
						handleChange("tcpEnabled", !form.tcpEnabled.value);

						// toggleDisable("tcpSlaveAddress", form.tcpEnabled.value);
					} }
				/>
				{/*<Input
					formItem={ form.tcpSlaveAddress }
					label="Slave Address"
					name="tcpSlaveAddress"
					inputProps={ {
						type: "number",
						onChange: (e) => handleChange("tcpSlaveAddress", e.target.value),
						onBlur: () => handleBlur("tcpSlaveAddress"),
					} }
					hasBeenChanged={ reducerForm.form.tcpSlaveAddress.value !== reducerForm.form.tcpSlaveAddress.initialValue }
				/>*/ }
				<Input
					formItem={ form.tcpPort }
					label={t('COMMUNICATIONS.port')}
					name="tcpPort"
					inputProps={ {
						type: "number",
						onChange: (e) => handleChange("tcpPort", e.target.value),
						onBlur: () => handleBlur("tcpPort"),
					} }
					hasBeenChanged={ reducerForm.form.tcpPort.value !== reducerForm.form.tcpPort.initialValue }
				/>
				<Select
					label={t('COMMUNICATIONS.word order')}
					options={ mapEnumSettingToSelectOptions(settingsModbus.communications?.cfgModbusTcpEndianness, modbusEndiannessDictionary) }
					formItem={ form.tcpEndianness }
					onChange={ option => {
						if (isNull(option)) return;

						handleChange("tcpEndianness", option?.value);
						handleBlur("tcpEndianness");
					} }
					isSearchable={ false }
					isClearable={ false }
					hasBeenChanged={ reducerForm.form.tcpEndianness.value !== reducerForm.form.tcpEndianness.initialValue }
				/>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
                            {t('UTILS.save')}
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(ModbusTcpCard);
