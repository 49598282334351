import { createNetworkAction } from "src/app/utils/redux";
import { SuccessPayload } from "src/app/types/api/api.types";
import { FirmwareInfo, UploadFirmwareFilePayload } from "src/app/types/api/firmware.types";

export const uploadFirmwareAsync = createNetworkAction<UploadFirmwareFilePayload, SuccessPayload<null>>("FIRMWARE__UPLOAD_FIRMWARE");
export const fetchFirmwareInfoAsync = createNetworkAction<undefined, SuccessPayload<FirmwareInfo>>("FIRMWARE__FETCH_FIRMWARE_INFO");
export const pingFirmwareInfoAsync = createNetworkAction<undefined, SuccessPayload<FirmwareInfo>>("FIRMWARE__PING_FIRMWARE_INFO");
export const removeFirmwareFileAsync = createNetworkAction<undefined, SuccessPayload<null>>("FIRMWARE__REMOVE_FIRMWARE_FILE");
export const upgradeFirmwareAsync = createNetworkAction<undefined, SuccessPayload<null>>("FIRMWARE__UPGRADE_FIRMWARE");

