import ErrorWrapper from "src/app/components/Message/ErrorWrapper.component";
import { useNavigate } from "react-router-dom";
import { defaultView } from "src/app/utils/constants/constants";

function NotFoundView() {

	const navigate = useNavigate();

	return (
		<ErrorWrapper
			message="Page not found"
			buttonText="Go back"
			onButtonClick={ () => navigate(defaultView, { replace: true }) }
		/>
	);
}

export default (NotFoundView);
