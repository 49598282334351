import { Enum, EnumDictionary } from "src/app/types/util.types";
import { IconType } from "react-icons";
import { Tabs, type TabsRef, TabStyles } from "flowbite-react";
import { useEffect, useLayoutEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isNotNull } from "src/app/utils/typeguards";
import classNames from "classnames";

type DictionaryTranslatedEntry = {
	name: string
	icon?: IconType
	disabled?: boolean
	component: ReactNode
}

type Props<T extends Enum> = {
	items: EnumDictionary<T, DictionaryTranslatedEntry>
	style?: keyof TabStyles
	onChangeActiveTab?: (tab: T) => void
};

const QUERY_PARAM_KEY = "pageId";

function QueryParamTabs<T extends Enum>(props: Props<T>) {

	const {
		items,
		style,
		onChangeActiveTab,
	} = props;

	const [ searchParams ] = useSearchParams();
	const navigate = useNavigate();

	const pageId = searchParams.get(QUERY_PARAM_KEY);

	useLayoutEffect(() => {
		tabsRef.current?.setActiveTab(_getTabIndex());
	}, []);

	useEffect(() => {
		_handleChangeTab(_getTabIndex());
	}, []);

	const _getTabIndex = () => {
		let tabIndex = 0;
		if (isNotNull(pageId)) {
			const index = Object.keys(items()).indexOf(pageId);
			if (index !== -1) tabIndex = index;
		}
		return tabIndex;
	};

	const tabsRef = useRef<TabsRef>(null);

	const _handleChangeTab = (tabIndex: number) => {
		const itemKey = Object.keys(items())[ tabIndex ];
		const urlSearchParams = new URLSearchParams({ [ QUERY_PARAM_KEY ]: itemKey });
		navigate({
			search: urlSearchParams.toString(),
		}, {
			replace: true,
		});
	};

	useEffect(() => {
		onChangeActiveTab?.(pageId as T);
	}, [ pageId ]);

	return (
		<>
			<Tabs
				ref={ tabsRef }
				onActiveTabChange={ _handleChangeTab }
				style={ style }
				className={
					classNames(
						"flex-nowrap overflow-x-visible",
					)
				}
			>
				{//Object.entries ?
					(Object.keys(items()) as T[]).map(itemKey => {
						const item = items()[ itemKey ];
						const isDisabled = isNotNull(item.disabled) && item.disabled;
						return (
							<Tabs.Item
								key={ itemKey.toString() }
								icon={ item.icon }
								title={ item.name }
								disabled={ isDisabled }
							>
								{ !isDisabled && items()[ itemKey ].component }
							</Tabs.Item>
						);
					})
				}
			</Tabs>
		</>
	);
}

export default (QueryParamTabs);
