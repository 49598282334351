import { createNetworkAction } from "src/app/utils/redux";
import { SuccessPayload } from "src/app/types/api/api.types";
import { ChangeUserPasswordSuccessPayload, CreateUserPayload, CreateUserSuccessPayload, DeleteUserPayload, DeleteUserSuccessPayload, LoginPayload, ResetPasswordPayload, UpdateUserPasswordPayload, Users } from "src/app/types/api/user.types";
import { createAction } from "typesafe-actions";

// Auth
export const loginAsync = createNetworkAction<LoginPayload, SuccessPayload<{ token: string }>>("USER__LOGIN");
export const logoutAsync = createNetworkAction<undefined, SuccessPayload<{ message: string }>>("USER__LOGOUT");

export const changePasswordAsync = createNetworkAction<UpdateUserPasswordPayload, SuccessPayload<ChangeUserPasswordSuccessPayload>>("USER__CHANGE_PASSWORD");

export const forgetSession = createAction("USER__FORGET_SESSION")<undefined>();
export const checkAuthToken = createAction("USER__CHECK_AUTH_TOKEN")<string>();

export const fetchUsersAsync = createNetworkAction<undefined, SuccessPayload<Users>>("USER__FETCH_USERS");
export const createUserAsync = createNetworkAction<CreateUserPayload, SuccessPayload<CreateUserSuccessPayload>>("USER__CREATE_USER");
export const deleteUserByIdAsync = createNetworkAction<DeleteUserPayload, SuccessPayload<DeleteUserSuccessPayload>>("USER__DELETE_USER");
export const resetPasswordAsync = createNetworkAction<ResetPasswordPayload, SuccessPayload<any>>("USER__RESET_PASSWORD");
