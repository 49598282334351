import { createSelector } from "reselect";
import { RootState } from "src/app/store/root.reducer";
import { View } from "src/app/types/ui/message.types";

export const getMessagesFromView = createSelector(
	[
		(state: RootState) => state.message.messages,
		(_, view: View) => view,
	],
	(messages, view) => messages.filter(message => message.view === view),
);
