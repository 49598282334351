import { DataBasicLive } from "src/app/types/api/ws.types";
import { isNotNull, isNull } from "src/app/utils/typeguards";
// @ts-ignore
import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";
// @ts-ignore
import { Chart, ChartCanvas } from "react-stockcharts";
// @ts-ignore
import { LineSeries } from "react-stockcharts/lib/series";
// @ts-ignore
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { TimeplotConfigState } from "src/app/containers/Realtime/Timeplot.container";
import moment from "moment";
import { scaleRange } from "src/app/utils/chart";
import { roundToFirstNonZeroValue } from "src/app/utils/helpers";
import { formatValueToDeciSONlValue, formatValueToFullDeciSON } from "src/app/utils/dataFormatting";
import { appColors } from "src/app/utils/constants/theme";
import { GridLoader } from "react-spinners";
import { useCallback } from "react";
import ChartCurrentCoordinate from "src/app/components/Chart/Component/ChartCurrentCoordinate.component";
import ChartHoverTooltip from "src/app/components/Chart/Component/ChartHoverTooltip.component";
import { StockChartIndex } from "src/app/types/ui/chart.types";
import { BasicLiveConfiguration } from "src/app/utils/hooks/useWebSocket";
import { MeasurementCategory } from "src/app/types/misc.types";
import { useTranslation } from "react-i18next";

type Props = {
	width: number
	height: number
	data: TimeplotChartData[]
	config: TimeplotConfigState
	index?: number
	isSplitted: boolean
	basicConfig: BasicLiveConfiguration
};

export type TimeplotChartData =
	DataBasicLive["basic"]
	& { date: Date };

type DataItem =
	TimeplotChartData
	& StockChartIndex;

export const SPLITTED_TIMEPLOT_CHART_CONFIG = {
	margins: {
		left: 40,
		right: 10,
		top: 45,
		bottom: 17,
	},
	chartGap: 90,
	singleChartHeight: 100,
};

export const MERGED_TIMEPLOT_CHART_CONFIG = {
	margins: {
		left: 115,
		right: 150,
		rightNoP: 10,
		top: 20,
		bottom: 17,
	},
};

function TimeplotChart(props: Props) {

	const { t } = useTranslation();

	const {
		data,
		width,
		height,
		config,
		index = 0,
		isSplitted,
		basicConfig,
	} = props;

	const _calculateTensionsRange = (data: TimeplotChartData[]): [ min: number, max: number ] => {
		const firstValue = data[ 0 ];
		if (isNull(firstValue)) return [ 0, 500 ];
		const firstMinValue = Math.min(
			...[
				config.U1N ? (data[ 0 ].U1n_rms?.value ?? 0) : null,
				config.U2N ? (data[ 0 ].U2n_rms?.value ?? 0) : null,
				config.U3N ? (data[ 0 ].U3n_rms?.value ?? 0) : null,
				config.UNE ? (data[ 0 ].Une_rms?.value ?? 0) : null,
			].filter(isNotNull),
		);
		const firstMaxValue = Math.max(
			...[
				config.U1N ? (data[ 0 ].U1n_rms?.value ?? 0) : null,
				config.U2N ? (data[ 0 ].U2n_rms?.value ?? 0) : null,
				config.U3N ? (data[ 0 ].U3n_rms?.value ?? 0) : null,
				config.UNE ? (data[ 0 ].Une_rms?.value ?? 0) : null,
			].filter(isNotNull),
		);
		if (!isFinite(firstMaxValue) || !isFinite(firstMinValue)) return [ 0, 500 ];
		const yAxisRange: [ number, number ] = data.reduce((prev, next) => [
			Math.min(
				prev[ 0 ],
				config.U1N ? (next.U1n_rms?.value ?? 0) : prev[ 0 ],
				config.U2N ? (next.U2n_rms?.value ?? 0) : prev[ 0 ],
				config.U3N ? (next.U3n_rms?.value ?? 0) : prev[ 0 ],
				config.UNE ? (next.Une_rms?.value ?? 0) : prev[ 0 ],
			),
			Math.max(
				prev[ 1 ],
				config.U1N ? (next.U1n_rms?.value ?? 0) : prev[ 1 ],
				config.U2N ? (next.U2n_rms?.value ?? 0) : prev[ 1 ],
				config.U3N ? (next.U3n_rms?.value ?? 0) : prev[ 1 ],
				config.UNE ? (next.Une_rms?.value ?? 0) : prev[ 1 ],
			),
		], [ firstMinValue, firstMaxValue ]);

		const ceiledAxisRange: [ number, number ] = [
			roundToFirstNonZeroValue(yAxisRange[ 0 ], Math.floor),
			roundToFirstNonZeroValue(yAxisRange[ 1 ], Math.ceil),
		];

		return scaleRange(ceiledAxisRange);
	};

	const _calculateInterTensionsRange = (data: TimeplotChartData[]): [ min: number, max: number ] => {
		const firstValue = data[ 0 ];
		if (isNull(firstValue)) return [ 0, 500 ];
		const firstMinValue = Math.min(
			...[
				config.U12 ? (data[ 0 ].U12_rms?.value ?? 0) : null,
				config.U23 ? (data[ 0 ].U23_rms?.value ?? 0) : null,
				config.U31 ? (data[ 0 ].U31_rms?.value ?? 0) : null,
			].filter(isNotNull),
		);
		const firstMaxValue = Math.max(
			...[
				config.U12 ? (data[ 0 ].U12_rms?.value ?? 0) : null,
				config.U23 ? (data[ 0 ].U23_rms?.value ?? 0) : null,
				config.U31 ? (data[ 0 ].U31_rms?.value ?? 0) : null,
			].filter(isNotNull),
		);
		if (!isFinite(firstMaxValue) || !isFinite(firstMinValue)) return [ 0, 500 ];
		const yAxisRange: [ number, number ] = data.reduce((prev, next) => [
			Math.min(
				prev[ 0 ],
				config.U12 ? (next.U12_rms?.value ?? 0) : prev[ 0 ],
				config.U23 ? (next.U23_rms?.value ?? 0) : prev[ 0 ],
				config.U31 ? (next.U31_rms?.value ?? 0) : prev[ 0 ],
			),
			Math.max(
				prev[ 1 ],
				config.U12 ? (next.U12_rms?.value ?? 0) : prev[ 1 ],
				config.U23 ? (next.U23_rms?.value ?? 0) : prev[ 1 ],
				config.U31 ? (next.U31_rms?.value ?? 0) : prev[ 1 ],
			),
		], [ firstMinValue, firstMaxValue ]);

		const ceiledAxisRange: [ number, number ] = [
			roundToFirstNonZeroValue(yAxisRange[ 0 ], Math.floor),
			roundToFirstNonZeroValue(yAxisRange[ 1 ], Math.ceil),
		];

		return scaleRange(ceiledAxisRange);
	};

	const _calculateIntensitiesRange = (data: TimeplotChartData[]): [ min: number, max: number ] => {
		const firstValue = data[ 0 ];
		if (isNull(firstValue)) return [ 0, 500 ];
		const firstMinValue = Math.min(
			...[
				config.I1 ? (data[ 0 ].I1_rms?.value ?? 0) : null,
				config.I2 ? (data[ 0 ].I2_rms?.value ?? 0) : null,
				config.I3 ? (data[ 0 ].I3_rms?.value ?? 0) : null,
			].filter(isNotNull),
		);
		const firstMaxValue = Math.max(
			...[
				config.I1 ? (data[ 0 ].I1_rms?.value ?? 0) : null,
				config.I2 ? (data[ 0 ].I2_rms?.value ?? 0) : null,
				config.I3 ? (data[ 0 ].I3_rms?.value ?? 0) : null,
			].filter(isNotNull),
		);
		if (!isFinite(firstMaxValue) || !isFinite(firstMinValue)) return [ 0, 500 ];
		const yAxisRange: [ number, number ] = data.reduce((prev, next) => [
			Math.min(
				prev[ 0 ],
				config.I1 ? (next.I1_rms?.value ?? 0) : prev[ 0 ],
				config.I2 ? (next.I2_rms?.value ?? 0) : prev[ 0 ],
				config.I3 ? (next.I3_rms?.value ?? 0) : prev[ 0 ],
			),
			Math.max(
				prev[ 1 ],
				config.I1 ? (next.I1_rms?.value ?? 0) : prev[ 1 ],
				config.I2 ? (next.I2_rms?.value ?? 0) : prev[ 1 ],
				config.I3 ? (next.I3_rms?.value ?? 0) : prev[ 1 ],
			),
		], [ firstMinValue, firstMaxValue ]);

		const ceiledAxisRange: [ number, number ] = [
			roundToFirstNonZeroValue(yAxisRange[ 0 ], Math.floor),
			roundToFirstNonZeroValue(yAxisRange[ 1 ], Math.ceil),
		];

		return scaleRange(ceiledAxisRange);
	};

	const _calculatePowersPRange = (data: TimeplotChartData[]): [ min: number, max: number ] => {
		const firstValue = data[ 0 ];
		if (isNull(firstValue)) return [ 0, 500 ];
		const firstMinValue = Math.min(
			...[
				config.L1_P ? (data[ 0 ].L1_P?.value ?? 0) : null,
				config.L2_P ? (data[ 0 ].L2_P?.value ?? 0) : null,
				config.L3_P ? (data[ 0 ].L3_P?.value ?? 0) : null,
			].filter(isNotNull),
		);
		const firstMaxValue = Math.max(
			...[
				config.L1_P ? (data[ 0 ].L1_P?.value ?? 0) : null,
				config.L2_P ? (data[ 0 ].L2_P?.value ?? 0) : null,
				config.L3_P ? (data[ 0 ].L3_P?.value ?? 0) : null,
			].filter(isNotNull),
		);
		if (!isFinite(firstMaxValue) || !isFinite(firstMinValue)) return [ 0, 500 ];
		const yAxisRange: [ number, number ] = data.reduce((prev, next) => [
			Math.min(
				prev[ 0 ],
				config.L1_P ? (next.L1_P?.value ?? 0) : prev[ 0 ],
				config.L2_P ? (next.L2_P?.value ?? 0) : prev[ 0 ],
				config.L3_P ? (next.L3_P?.value ?? 0) : prev[ 0 ],
			),
			Math.max(
				prev[ 1 ],
				config.L1_P ? (next.L1_P?.value ?? 0) : prev[ 1 ],
				config.L2_P ? (next.L2_P?.value ?? 0) : prev[ 1 ],
				config.L3_P ? (next.L3_P?.value ?? 0) : prev[ 1 ],
			),
		], [ firstMinValue, firstMaxValue ]);

		const ceiledAxisRange: [ number, number ] = [
			roundToFirstNonZeroValue(yAxisRange[ 0 ], Math.floor),
			roundToFirstNonZeroValue(yAxisRange[ 1 ], Math.ceil),
		];

		return scaleRange(ceiledAxisRange);
	};

	const _calculatePowersQRange = (data: TimeplotChartData[]): [ min: number, max: number ] => {
		const firstValue = data[ 0 ];
		if (isNull(firstValue)) return [ 0, 500 ];
		const firstMinValue = Math.min(
			...[
				config.L1_Q ? (data[ 0 ].L1_Q?.value ?? 0) : null,
				config.L2_Q ? (data[ 0 ].L2_Q?.value ?? 0) : null,
				config.L3_Q ? (data[ 0 ].L3_Q?.value ?? 0) : null,
			].filter(isNotNull),
		);
		const firstMaxValue = Math.max(
			...[
				config.L1_Q ? (data[ 0 ].L1_Q?.value ?? 0) : null,
				config.L2_Q ? (data[ 0 ].L2_Q?.value ?? 0) : null,
				config.L3_Q ? (data[ 0 ].L3_Q?.value ?? 0) : null,
			].filter(isNotNull),
		);
		if (!isFinite(firstMaxValue) || !isFinite(firstMinValue)) return [ 0, 500 ];
		const yAxisRange: [ number, number ] = data.reduce((prev, next) => [
			Math.min(
				prev[ 0 ],
				config.L1_Q ? (next.L1_Q?.value ?? 0) : prev[ 0 ],
				config.L2_Q ? (next.L2_Q?.value ?? 0) : prev[ 0 ],
				config.L3_Q ? (next.L3_Q?.value ?? 0) : prev[ 0 ],
			),
			Math.max(
				prev[ 1 ],
				config.L1_Q ? (next.L1_Q?.value ?? 0) : prev[ 1 ],
				config.L2_Q ? (next.L2_Q?.value ?? 0) : prev[ 1 ],
				config.L3_Q ? (next.L3_Q?.value ?? 0) : prev[ 1 ],
			),
		], [ firstMinValue, firstMaxValue ]);

		const ceiledAxisRange: [ number, number ] = [
			roundToFirstNonZeroValue(yAxisRange[ 0 ], Math.floor),
			roundToFirstNonZeroValue(yAxisRange[ 1 ], Math.ceil),
		];

		return scaleRange(ceiledAxisRange);
	};

	const _calculatePowersSRange = (data: TimeplotChartData[]): [ min: number, max: number ] => {
		const firstValue = data[ 0 ];
		if (isNull(firstValue)) return [ 0, 500 ];
		const firstMinValue = Math.min(
			...[
				config.L1_S ? (data[ 0 ].L1_S?.value ?? 0) : null,
				config.L2_S ? (data[ 0 ].L2_S?.value ?? 0) : null,
				config.L3_S ? (data[ 0 ].L3_S?.value ?? 0) : null,
			].filter(isNotNull),
		);
		const firstMaxValue = Math.max(
			...[
				config.L1_S ? (data[ 0 ].L1_S?.value ?? 0) : null,
				config.L2_S ? (data[ 0 ].L2_S?.value ?? 0) : null,
				config.L3_S ? (data[ 0 ].L3_S?.value ?? 0) : null,
			].filter(isNotNull),
		);
		if (!isFinite(firstMaxValue) || !isFinite(firstMinValue)) return [ 0, 500 ];
		const yAxisRange: [ number, number ] = data.reduce((prev, next) => [
			Math.min(
				prev[ 0 ],
				config.L1_S ? (next.L1_S?.value ?? 0) : prev[ 0 ],
				config.L2_S ? (next.L2_S?.value ?? 0) : prev[ 0 ],
				config.L3_S ? (next.L3_S?.value ?? 0) : prev[ 0 ],
			),
			Math.max(
				prev[ 1 ],
				config.L1_S ? (next.L1_S?.value ?? 0) : prev[ 1 ],
				config.L2_S ? (next.L2_S?.value ?? 0) : prev[ 1 ],
				config.L3_S ? (next.L3_S?.value ?? 0) : prev[ 1 ],
			),
		], [ firstMinValue, firstMaxValue ]);

		const ceiledAxisRange: [ number, number ] = [
			roundToFirstNonZeroValue(yAxisRange[ 0 ], Math.floor),
			roundToFirstNonZeroValue(yAxisRange[ 1 ], Math.ceil),
		];

		return scaleRange(ceiledAxisRange);
	};

	const _calculateFrequencyRange = (data: TimeplotChartData[]): [ min: number, max: number ] => {
		const firstValue = data[ 0 ];
		if (isNull(firstValue)) return [ 0, 500 ];
		const firstMinValue = Math.min(
			...[
				config.FREQUENCY ? (data[ 0 ]?.frequency200ms?.value ?? 0) : null,
			].filter(isNotNull),
		);
		const firstMaxValue = Math.max(
			...[
				config.FREQUENCY ? (data[ 0 ]?.frequency200ms?.value ?? 0) : null,
			].filter(isNotNull),
		);
		if (!isFinite(firstMaxValue) || !isFinite(firstMinValue)) return [ 0, 500 ];

		const yAxisRange: [ number, number ] = data.reduce((prev, next) => [
			Math.min(
				prev[ 0 ],
				config.FREQUENCY ? (next?.frequency200ms?.value ?? 0) : prev[ 0 ],
			),
			Math.max(
				prev[ 1 ],
				config.FREQUENCY ? (next?.frequency200ms?.value ?? 0) : prev[ 1 ],
			),
		], [ firstMinValue, firstMaxValue ]);

		const ceiledAxisRange: [ number, number ] = [
			roundToFirstNonZeroValue(yAxisRange[ 0 ], Math.floor),
			roundToFirstNonZeroValue(yAxisRange[ 1 ], Math.ceil),
		];

		return scaleRange(ceiledAxisRange);
	};

	const yGrid = {
		tickStrokeDasharray: "Dash",
		tickStrokeWidth: 1,
		fontFamily: "Segoe UI Symbol, Segoe UI Emoji, ui-sans-serif, sans-serif",
		fontSize: 12,
		fontWeight: 500,
		tickStroke: "#030712",
		stroke: "#03071200",
		zoomEnabled: false,
	};

	const xGrid = {
		tickStrokeDasharray: "Dash",
		tickStrokeOpacity: 0.2,
		tickStrokeWidth: 1,
		axisAt: "bottom",
		orient: "bottom",
		tickStroke: "#030712",
		stroke: "#03071200",

		fontFamily: "Segoe UI Symbol, Segoe UI Emoji, ui-sans-serif, sans-serif",
		fontSize: 12,
		fontWeight: 500,
	};

	const xExtentsSeconds = 5 * 60;
	const recordGapSeconds = 0.2;
	const xExtents = width > 650 ? [ 0, Math.round(xExtentsSeconds / recordGapSeconds) ] : [ 0, Math.round(xExtentsSeconds / recordGapSeconds / 2) ];

	const _getInitialIndex = useCallback(() => {
		if (index === 0) return index;

		if (data.length < xExtents[ 1 ]) {
			return 0;
		} else {
			return xExtents[ 1 ] - data.length;
		}
	}, [ index ]);

	if (data.length <= 2) {
		return (
			<div className="w-full h-full flex items-center justify-center">
				<GridLoader size={ 15 } color="#0093DD"/>
			</div>
		);
	}

	const tensionsRange: [ number, number ] = _calculateTensionsRange(data);
	const interTensionsRange: [ number, number ] = _calculateInterTensionsRange(data);
	const intensitiesRange: [ number, number ] = _calculateIntensitiesRange(data);
	const powerPRange: [ number, number ] = _calculatePowersPRange(data);
	const powerQRange: [ number, number ] = _calculatePowersQRange(data);
	const powerSRange: [ number, number ] = _calculatePowersSRange(data);
	const frequencyRange: [ number, number ] = _calculateFrequencyRange(data);

	const xScaleProvider = discontinuousTimeScaleProvider
		.initialIndex(_getInitialIndex())
		.inputDateAccessor((d: any) => d.date);

	const {
		data: chartData,
		xScale,
		xAccessor,
		displayXAccessor,
	} = xScaleProvider(data);

	const firstItem: DataItem = chartData[ chartData.length - 1 ];
	const lastItem: DataItem = chartData[ 0 ];

	const firstItemSecondsA = moment((chartData as DataItem[])[ 0 ].timestamp).format("ss");
	const firstItemSecondsB = moment((chartData as DataItem[])[ 0 ].timestamp + 30 * 1000).format("ss");

	const tickValues =
		(chartData as DataItem[])
			.filter((item, index) => {
				if (index === 0) return true;

				const seconds = moment(item.timestamp).format("ss");
				return seconds === firstItemSecondsA || seconds === firstItemSecondsB;
			})
			.map(item =>
				({ index: item.idx.index, time: moment(item.timestamp).format("mm:ss") }),
			)
			.filter((item, index, array) =>
				array.findIndex(arrayItem => arrayItem.time === item.time) === index,
			)
			.map(item => item.index);

	const legendDescriptionY = "-8";

	if (isSplitted) {
		const {
			margins: {
				left: marginLeft,
				right: marginRight,
				top: marginTop,
				bottom: marginBottom,
			},
			chartGap,
			singleChartHeight,
		} = SPLITTED_TIMEPLOT_CHART_CONFIG;

		const _getChartOrigin = (index: number) => (w: number, h: number) => [ 0, h - (height - index * singleChartHeight) + (marginTop + marginBottom) + index * chartGap ];

		if (data.length <= 2 * Math.ceil(xExtents[ 1 ] / (width - marginLeft - marginRight))) {
			return (
				<div className="w-full h-full flex items-center justify-center">
					<GridLoader size={ 15 } color="#0093DD"/>
				</div>
			);
		}

		const isDisplayingU = basicConfig.U1n_rms || basicConfig.U2n_rms || basicConfig.U3n_rms || basicConfig.Une_rms;
		const isDisplayingCrossU = basicConfig.U12_rms || basicConfig.U23_rms || basicConfig.U31_rms;
		const isDisplayingI = basicConfig.I1_rms || basicConfig.I2_rms || basicConfig.I3_rms;
		const isDisplayingP = basicConfig.L1_P || basicConfig.L2_P || basicConfig.L3_P;
		const isDisplayingQ = basicConfig.L1_Q || basicConfig.L2_Q || basicConfig.L3_Q;
		const isDisplayingS = basicConfig.L1_S || basicConfig.L2_S || basicConfig.L3_S;
		const isDisplayingFreq = basicConfig.frequency200ms;

		const uIndex = -1 + +isDisplayingU;
		const crossUIndex = uIndex + +isDisplayingCrossU;
		const iIndex = crossUIndex + +isDisplayingI;
		const pIndex = iIndex + +isDisplayingP;
		const qIndex = pIndex + +isDisplayingQ;
		const sIndex = qIndex + +isDisplayingS;
		const freqIndex = sIndex + +isDisplayingFreq;

		return (
			<ChartCanvas
				ratio={ 1 }
				width={ width }
				height={ height }
				margin={ { left: marginLeft, right: marginRight, top: marginTop, bottom: marginBottom } }
				type="svg"
				seriesName={ `measurements-${ index }` }
				data={ chartData }
				xScale={ xScale }
				xAccessor={ xAccessor }
				displayXAccessor={ displayXAccessor }
				mouseMoveEvent={ true }
				panEvent={ true }
				zoomEvent={ true }
				xExtents={ xExtents }
				pointsPerPxThreshold={ 5 }
			>
				{
					isDisplayingU &&
                    <Chart
                        id="voltages-chart-U"
                        yExtents={ tensionsRange }
                        height={ singleChartHeight }
                        origin={ _getChartOrigin(uIndex) }
                    >
                        <XAxis
                            tickFormat={ (index: number) => {
								const item = chartData?.find((dataItem: any) => dataItem.idx.index === index);

								return moment(item?.date).format("mm:ss");
							} }
                            tickValues={ tickValues }
                            innerTickSize={ -singleChartHeight }
                            zoomEnabled={ false }
							{ ...xGrid }
                        />
                        <rect x={ -marginLeft - 1 } y={ 0 } width={ marginLeft } height={ singleChartHeight + marginTop + 1 } fill="white"/>
                        <YAxis
                            axisAt="left"
                            orient="left"

							// tickFormat={ (v: number) => v.toString() }
                            tickFormat={ (v: number) => formatValueToDeciSONlValue(v, MeasurementCategory.VOLTAGES_WAVE)?.toString() ?? "" }
                            fill="#ff00ff"
                            tickStrokeOpacity={ 0.2 }
                            innerTickSize={ -1 * (width - marginLeft - marginRight) }
                            ticks={ 3 }
							{ ...yGrid }
                        />
						{
							config.U1N &&
                            <>
                                <ChartCurrentCoordinate
                                    yAccessor={ (d: TimeplotChartData) => d.U1n_rms?.value }
                                    fill={ appColors.U1 }
                                />
                                <LineSeries
                                    yAccessor={ (d: TimeplotChartData) => d.U1n_rms?.value }
                                    strokeWidth={ 2 }
                                    stroke={ appColors.U1 }
                                />
                            </>
						}
						{
							config.U2N &&
                            <>
                                <ChartCurrentCoordinate
                                    yAccessor={ (d: TimeplotChartData) => d.U2n_rms?.value }
                                    fill={ appColors.U2 }
                                />
                                <LineSeries
                                    yAccessor={ (d: TimeplotChartData) => d.U2n_rms?.value }
                                    strokeWidth={ 2 }
                                    stroke={ appColors.U2 }
                                />
                            </>
						}
						{
							config.U3N &&
                            <>
                                <ChartCurrentCoordinate
                                    yAccessor={ (d: TimeplotChartData) => d.U3n_rms?.value }
                                    fill={ appColors.U3 }
                                />
                                <LineSeries
                                    yAccessor={ (d: TimeplotChartData) => d.U3n_rms?.value }
                                    strokeWidth={ 2 }
                                    stroke={ appColors.U3 }
                                />
                            </>
						}
						{
							config.UNE &&
                            <>
                                <ChartCurrentCoordinate
                                    yAccessor={ (d: TimeplotChartData) => d.Une_rms?.value }
                                    fill={ appColors.Un }
                                />
                                <LineSeries
                                    yAccessor={ (d: TimeplotChartData) => d.Une_rms?.value }
                                    strokeWidth={ 2 }
                                    stroke={ appColors.Un }
                                />
                            </>
						}
						{/*<text x="-28" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>U[{ getPrefixToUnit(0.0, "V", MeasurementCategory.VOLTAGES_WAVE) }]</text>*/ }
                        <text x="-28" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>U[V]</text>
                    </Chart>
				}
				{
					isDisplayingCrossU &&
                    <Chart
                        id="voltages-chart-interU"
                        yExtents={ interTensionsRange }
                        height={ singleChartHeight }
                        origin={ _getChartOrigin(crossUIndex) }
                    >
                        <XAxis
                            tickFormat={ (index: number) => {
								const item = chartData?.find((dataItem: any) => dataItem.idx.index === index);

								return moment(item?.date).format("mm:ss");
							} }
                            tickValues={ tickValues }
                            innerTickSize={ -singleChartHeight }
                            zoomEnabled={ false }
							{ ...xGrid }
                        />
                        <rect x={ -marginLeft - 1 } y={ 0 } width={ marginLeft } height={ singleChartHeight + 1 } fill="white"/>
                        <YAxis
                            axisAt="left"
                            orient="left"

							// tickFormat={ (v: number) => v.toString() }
                            tickFormat={ (v: number) => formatValueToDeciSONlValue(v, MeasurementCategory.VOLTAGES_WAVE)?.toString() ?? "" }
                            fill="#ff00ff"
                            tickStrokeOpacity={ 0.2 }
                            innerTickSize={ -1 * (width - marginLeft - marginRight) }
                            ticks={ 3 }
							{ ...yGrid }
                        />
						{
							config.U12 &&
                            <>
                                <ChartCurrentCoordinate
                                    yAccessor={ (d: TimeplotChartData) => d.U12_rms?.value }
                                    fill={ appColors.U12 }
                                />
                                <LineSeries
                                    yAccessor={ (d: TimeplotChartData) => d.U12_rms?.value }
                                    strokeWidth={ 2 }
                                    stroke={ appColors.U12 }
                                />
                            </>
						}
						{
							config.U23 &&
                            <>
                                <ChartCurrentCoordinate
                                    yAccessor={ (d: TimeplotChartData) => d.U23_rms?.value }
                                    fill={ appColors.U23 }
                                />
                                <LineSeries
                                    yAccessor={ (d: TimeplotChartData) => d.U23_rms?.value }
                                    strokeWidth={ 2 }
                                    stroke={ appColors.U23 }
                                />
                            </>
						}
						{
							config.U31 &&
                            <>
                                <ChartCurrentCoordinate
                                    yAccessor={ (d: TimeplotChartData) => d.U31_rms?.value }
                                    fill={ appColors.U31 }
                                />
                                <LineSeries
                                    yAccessor={ (d: TimeplotChartData) => d.U31_rms?.value }
                                    strokeWidth={ 2 }
                                    stroke={ appColors.U31 }
                                />
                            </>
						}
						{/*<text x="-28" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>U[{ getPrefixToUnit(0.0, "V", MeasurementCategory.VOLTAGES_WAVE) }]</text>*/ }
                        <text x="-28" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>U[V]</text>
                    </Chart>
				}
				{
					isDisplayingI &&
                    <Chart
                        id="voltages-chart-I"
                        yExtents={ intensitiesRange }
                        height={ singleChartHeight }
                        origin={ _getChartOrigin(iIndex) }
                    >
                        <XAxis
                            tickFormat={ (index: number) => {
								const item = chartData?.find((dataItem: any) => dataItem.idx.index === index);

								return moment(item?.date).format("mm:ss");
							} }
                            tickValues={ tickValues }
                            innerTickSize={ -singleChartHeight }
                            zoomEnabled={ false }
							{ ...xGrid }
                        />
                        <rect x={ -marginLeft - 1 } y={ 0 } width={ marginLeft } height={ singleChartHeight + 1 } fill="white"/>
                        <YAxis
                            axisAt="left"
                            orient="left"

							// tickFormat={ (v: number) => v.toString() }
                            tickFormat={ (v: number) => formatValueToDeciSONlValue(v, MeasurementCategory.CURRENTS_WAVE)?.toString() ?? "" }
                            fill="#ff00ff"
                            tickStrokeOpacity={ 0.2 }
                            innerTickSize={ -1 * (width - marginLeft - marginRight) }
                            ticks={ 3 }
							{ ...yGrid }
                        />
						{
							config.I1 &&
                            <>
                                <ChartCurrentCoordinate
                                    yAccessor={ (d: TimeplotChartData) => d.I1_rms?.value }
                                    fill={ appColors.I1 }
                                />
                                <LineSeries
                                    yAccessor={ (d: TimeplotChartData) => d.I1_rms?.value }
                                    strokeWidth={ 2 }
                                    stroke={ appColors.I1 }
                                />
                            </>
						}
						{
							config.I2 &&
                            <>
                                <ChartCurrentCoordinate
                                    yAccessor={ (d: TimeplotChartData) => d.I2_rms?.value }
                                    fill={ appColors.I2 }
                                />
                                <LineSeries
                                    yAccessor={ (d: TimeplotChartData) => d.I2_rms?.value }
                                    strokeWidth={ 2 }
                                    stroke={ appColors.I2 }
                                />
                            </>
						}
						{
							config.I3 &&
                            <>
                                <ChartCurrentCoordinate
                                    yAccessor={ (d: TimeplotChartData) => d.I3_rms?.value }
                                    fill={ appColors.I3 }
                                />
                                <LineSeries
                                    yAccessor={ (d: TimeplotChartData) => d.I3_rms?.value }
                                    strokeWidth={ 2 }
                                    stroke={ appColors.I3 }
                                />
                            </>
						}
						{/*<text x="-23" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>I[{ getPrefixToUnit(0.0, "A", MeasurementCategory.CURRENTS_WAVE) }]</text>*/ }
                        <text x="-23" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>I[A]</text>
                    </Chart>
				}
				{
					isDisplayingP &&
                    <Chart
                        id="voltages-chart-P"
                        yExtents={ powerPRange }
                        height={ singleChartHeight }
                        origin={ _getChartOrigin(pIndex) }
                    >
                        <XAxis
                            tickFormat={ (index: number) => {
								const item = chartData?.find((dataItem: any) => dataItem.idx.index === index);

								return moment(item?.date).format("mm:ss");
							} }
                            tickValues={ tickValues }
                            innerTickSize={ -singleChartHeight }
                            zoomEnabled={ false }
							{ ...xGrid }
                        />
                        <rect x={ -marginLeft - 1 } y={ 0 } width={ marginLeft } height={ singleChartHeight + 1 } fill="white"/>
                        <YAxis
                            axisAt="left"
                            orient="left"

							// tickFormat={ (v: number) => v.toString() }
                            tickFormat={ (v: number) => formatValueToDeciSONlValue(v, MeasurementCategory.ACTIVE_POWERS_WAVE)?.toString() ?? "" }
                            fill="#ff00ff"
                            tickStrokeOpacity={ 0.2 }
                            innerTickSize={ -1 * (width - marginLeft - marginRight) }
                            ticks={ 3 }
							{ ...yGrid }
                        />
						{
							config.L1_P &&
                            <>
                                <ChartCurrentCoordinate
                                    yAccessor={ (d: TimeplotChartData) => d.L1_P?.value }
                                    fill={ appColors.L1P }
                                />
                                <LineSeries
                                    yAccessor={ (d: TimeplotChartData) => d.L1_P?.value }
                                    strokeWidth={ 2 }
                                    stroke={ appColors.L1P }
                                />
                            </>
						}
						{
							config.L2_P &&
                            <>
                                <ChartCurrentCoordinate
                                    yAccessor={ (d: TimeplotChartData) => d.L2_P?.value }
                                    fill={ appColors.L2P }
                                />
                                <LineSeries
                                    yAccessor={ (d: TimeplotChartData) => d.L2_P?.value }
                                    strokeWidth={ 2 }
                                    stroke={ appColors.L2P }
                                />
                            </>
						}
						{
							config.L3_P &&
                            <>
                                <ChartCurrentCoordinate
                                    yAccessor={ (d: TimeplotChartData) => d.L3_P?.value }
                                    fill={ appColors.L3P }
                                />
                                <LineSeries
                                    yAccessor={ (d: TimeplotChartData) => d.L3_P?.value }
                                    strokeWidth={ 2 }
                                    stroke={ appColors.L3P }
                                />
                            </>
						}
						{/*<text x="-30" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>P[{ getPrefixToUnit(0.0, "W", MeasurementCategory.ACTIVE_POWERS_WAVE) }]</text>*/ }
                        <text x="-30" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>P[W]</text>
                    </Chart>
				}
				{
					isDisplayingQ &&
                    <Chart
                        id="voltages-chart-Q"
                        yExtents={ powerQRange }
                        height={ singleChartHeight }
                        origin={ _getChartOrigin(qIndex) }
                    >
                        <XAxis
                            tickFormat={ (index: number) => {
								const item = chartData?.find((dataItem: any) => dataItem.idx.index === index);

								return moment(item?.date).format("mm:ss");
							} }
                            tickValues={ tickValues }
                            innerTickSize={ -singleChartHeight }
                            zoomEnabled={ false }
							{ ...xGrid }
                        />
                        <rect x={ -marginLeft - 1 } y={ 0 } width={ marginLeft } height={ singleChartHeight + 1 } fill="white"/>
                        <YAxis
                            axisAt="left"
                            orient="left"

							// tickFormat={ (v: number) => v.toString() }
                            tickFormat={ (v: number) => formatValueToDeciSONlValue(v, MeasurementCategory.REACTIVE_POWERS_WAVE)?.toString() ?? "" }
                            fill="#ff00ff"
                            tickStrokeOpacity={ 0.2 }
                            innerTickSize={ -1 * (width - marginLeft - marginRight) }
                            ticks={ 3 }
							{ ...yGrid }
                        />
						{
							config.L1_Q &&
                            <>
                                <ChartCurrentCoordinate
                                    yAccessor={ (d: TimeplotChartData) => d.L1_Q?.value }
                                    fill={ appColors.L1Q }
                                />
                                <LineSeries
                                    yAccessor={ (d: TimeplotChartData) => d.L1_Q?.value }
                                    strokeWidth={ 2 }
                                    stroke={ appColors.L1Q }
                                />
                            </>
						}
						{
							config.L2_Q &&
                            <>
                                <ChartCurrentCoordinate
                                    yAccessor={ (d: TimeplotChartData) => d.L2_Q?.value }
                                    fill={ appColors.L2Q }
                                />
                                <LineSeries
                                    yAccessor={ (d: TimeplotChartData) => d.L2_Q?.value }
                                    strokeWidth={ 2 }
                                    stroke={ appColors.L2Q }
                                />
                            </>
						}
						{
							config.L3_Q &&
                            <>
                                <ChartCurrentCoordinate
                                    yAccessor={ (d: TimeplotChartData) => d.L3_Q?.value }
                                    fill={ appColors.L3Q }
                                />
                                <LineSeries
                                    yAccessor={ (d: TimeplotChartData) => d.L3_Q?.value }
                                    strokeWidth={ 2 }
                                    stroke={ appColors.L3Q }
                                />
                            </>
						}
						{/*<text x="-37" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>Q[{ getPrefixToUnit(0.0, "var", MeasurementCategory.REACTIVE_POWERS_WAVE) }]</text>*/ }
                        <text x="-37" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>Q[var]</text>
                    </Chart>
				}
				{
					isDisplayingS &&
                    <Chart
                        id="voltages-chart-S"
                        yExtents={ powerSRange }
                        height={ singleChartHeight }
                        origin={ _getChartOrigin(sIndex) }
                    >
                        <XAxis
                            tickFormat={ (index: number) => {
								const item = chartData?.find((dataItem: any) => dataItem.idx.index === index);

								return moment(item?.date).format("mm:ss");
							} }
                            tickValues={ tickValues }
                            innerTickSize={ -singleChartHeight }
                            zoomEnabled={ false }
							{ ...xGrid }
                        />
                        <rect x={ -marginLeft - 1 } y={ 0 } width={ marginLeft } height={ singleChartHeight + 1 } fill="white"/>
                        <YAxis
                            axisAt="left"
                            orient="left"

							// tickFormat={ (v: number) => v.toString() }
                            tickFormat={ (v: number) => formatValueToDeciSONlValue(v, MeasurementCategory.APPARENT_POWERS_WAVE)?.toString() ?? "" }
                            fill="#ff00ff"
                            tickStrokeOpacity={ 0.2 }
                            innerTickSize={ -1 * (width - marginLeft - marginRight) }
                            ticks={ 3 }
							{ ...yGrid }
                        />
						{
							config.L1_S &&
                            <>
                                <ChartCurrentCoordinate
                                    yAccessor={ (d: TimeplotChartData) => d.L1_S?.value }
                                    fill={ appColors.L1S }
                                />
                                <LineSeries
                                    yAccessor={ (d: TimeplotChartData) => d.L1_S?.value }
                                    strokeWidth={ 2 }
                                    stroke={ appColors.L1S }
                                />
                            </>
						}
						{
							config.L2_S &&
                            <>
                                <ChartCurrentCoordinate
                                    yAccessor={ (d: TimeplotChartData) => d.L2_S?.value }
                                    fill={ appColors.L2S }
                                />
                                <LineSeries
                                    yAccessor={ (d: TimeplotChartData) => d.L2_S?.value }
                                    strokeWidth={ 2 }
                                    stroke={ appColors.L2S }
                                />
                            </>
						}
						{
							config.L3_S &&
                            <>
                                <ChartCurrentCoordinate
                                    yAccessor={ (d: TimeplotChartData) => d.L3_S?.value }
                                    fill={ appColors.L3S }
                                />
                                <LineSeries
                                    yAccessor={ (d: TimeplotChartData) => d.L3_S?.value }
                                    strokeWidth={ 2 }
                                    stroke={ appColors.L3S }
                                />
                            </>
						}
                        {/*<text x="-33" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>S[{ getPrefixToUnit(0.0, "VA", MeasurementCategory.APPARENT_POWERS_WAVE) }]</text>*/}
                        <text x="-33" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>S[VA]</text>
                    </Chart>
				}
				{
					isDisplayingFreq &&
                    <Chart
                        id="voltages-chart-frequency"
                        yExtents={ frequencyRange }
                        height={ singleChartHeight }
                        origin={ _getChartOrigin(freqIndex) }
                    >
                        <XAxis
                            tickFormat={ (index: number) => {
								const item = chartData?.find((dataItem: any) => dataItem.idx.index === index);

								return moment(item?.date).format("mm:ss");
							} }
                            tickValues={ tickValues }
                            innerTickSize={ -singleChartHeight }
                            zoomEnabled={ false }
							{ ...xGrid }
                        />
                        <rect x={ -marginLeft - 1 } y={ 0 } width={ marginLeft } height={ singleChartHeight + 1 } fill="white"/>
                        <YAxis
                            axisAt="left"
                            orient="left"

                            // tickFormat={ (v: number) => v.toString() }
                            tickFormat={ (v: number) => formatValueToDeciSONlValue(v, MeasurementCategory.FREQUENCIES_WAVE)?.toString() ?? "" }
                            fill="#ff00ff"
                            tickStrokeOpacity={ 0.2 }
                            innerTickSize={ -1 * (width - marginLeft - marginRight) }
                            ticks={ 3 }
							{ ...yGrid }
                        />
						{
							config.FREQUENCY &&
                            <>
                                <ChartCurrentCoordinate
                                    yAccessor={ (d: TimeplotChartData) => d.frequency200ms?.value }
                                    fill={ appColors.frequency }
                                />
                                <LineSeries
                                    yAccessor={ (d: TimeplotChartData) => d.frequency200ms?.value }
                                    strokeWidth={ 2 }
                                    stroke={ appColors.frequency }
                                />
                            </>
						}
                        {/*<text x="-33" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>f[{ getPrefixToUnit(0.0, "Hz", MeasurementCategory.FREQUENCIES_WAVE) }]</text>*/}
                        <text x="-33" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>f[Hz]</text>
                    </Chart>
				}
				<Chart
					id="tooltip"
					yExtents={ tensionsRange }
					height={ height }
				>
					<ChartHoverTooltip
						firstItem={ firstItem }
						lastItem={ lastItem }
						getTooltipContent={ (probeArgument: { currentItem: TimeplotChartData & StockChartIndex }) => {
							const { currentItem } = probeArgument;
							const date = moment(currentItem.timestamp).format("HH:mm:ss");
							return {
								x: date,
								y: [
									(config.U1N && isNotNull(currentItem.U1n_rms) && isNotNull(currentItem.U1n_rms.value)) ? {
										label: "U1N",
										value: formatValueToFullDeciSON(currentItem.U1n_rms.value, currentItem.U1n_rms.unit, MeasurementCategory.VOLTAGES) ?? "",
									} : null,
									(config.U2N && isNotNull(currentItem.U2n_rms) && isNotNull(currentItem.U2n_rms.value)) ? {
										label: "U2N",
										value: formatValueToFullDeciSON(currentItem.U2n_rms.value, currentItem.U2n_rms.unit, MeasurementCategory.VOLTAGES) ?? "",
									} : null,
									(config.U3N && isNotNull(currentItem.U3n_rms) && isNotNull(currentItem.U3n_rms.value)) ? {
										label: "U3N",
										value: formatValueToFullDeciSON(currentItem.U3n_rms.value, currentItem.U3n_rms.unit, MeasurementCategory.VOLTAGES) ?? "",
									} : null,

									(config.UNE && isNotNull(currentItem.Une_rms) && isNotNull(currentItem.Une_rms.value)) ? {
										label: "UE",
										value: formatValueToFullDeciSON(currentItem.Une_rms.value, currentItem.Une_rms.unit, MeasurementCategory.VOLTAGES) ?? "",
									} : null,

									(config.U12 && isNotNull(currentItem.U12_rms) && isNotNull(currentItem.U12_rms.value)) ? {
										label: "U12",
										value: formatValueToFullDeciSON(currentItem.U12_rms.value, currentItem.U12_rms.unit, MeasurementCategory.VOLTAGES) ?? "",
									} : null,
									(config.U23 && isNotNull(currentItem.U23_rms) && isNotNull(currentItem.U23_rms.value)) ? {
										label: "U23",
										value: formatValueToFullDeciSON(currentItem.U23_rms.value, currentItem.U23_rms.unit, MeasurementCategory.VOLTAGES) ?? "",
									} : null,
									(config.U31 && isNotNull(currentItem.U31_rms) && isNotNull(currentItem.U31_rms.value)) ? {
										label: "U31",
										value: formatValueToFullDeciSON(currentItem.U31_rms.value, currentItem.U31_rms.unit, MeasurementCategory.VOLTAGES) ?? "",
									} : null,

									(config.I1 && isNotNull(currentItem.I1_rms) && isNotNull(currentItem.I1_rms.value)) ? {
										label: "I1",
										value: formatValueToFullDeciSON(currentItem.I1_rms.value, currentItem.I1_rms.unit, MeasurementCategory.CURRENTS) ?? "",
									} : null,
									(config.I2 && isNotNull(currentItem.I2_rms) && isNotNull(currentItem.I2_rms.value)) ? {
										label: "I2",
										value: formatValueToFullDeciSON(currentItem.I2_rms.value, currentItem.I2_rms.unit, MeasurementCategory.CURRENTS) ?? "",
									} : null,
									(config.I3 && isNotNull(currentItem.I3_rms) && isNotNull(currentItem.I3_rms.value)) ? {
										label: "I3",
										value: formatValueToFullDeciSON(currentItem.I3_rms.value, currentItem.I3_rms.unit, MeasurementCategory.CURRENTS) ?? "",
									} : null,

									(config.L1_P && isNotNull(currentItem.L1_P) && isNotNull(currentItem.L1_P.value)) ? {
										label: "PL1",
										value: formatValueToFullDeciSON(currentItem.L1_P.value, currentItem.L1_P.unit, MeasurementCategory.ACTIVE_POWERS) ?? "",
									} : null,
									(config.L2_P && isNotNull(currentItem.L2_P) && isNotNull(currentItem.L2_P.value)) ? {
										label: "PL2",
										value: formatValueToFullDeciSON(currentItem.L2_P.value, currentItem.L2_P.unit, MeasurementCategory.ACTIVE_POWERS) ?? "",
									} : null,
									(config.L3_P && isNotNull(currentItem.L3_P) && isNotNull(currentItem.L3_P.value)) ? {
										label: "PL3",
										value: formatValueToFullDeciSON(currentItem.L3_P.value, currentItem.L3_P.unit, MeasurementCategory.ACTIVE_POWERS) ?? "",
									} : null,

									(config.L1_Q && isNotNull(currentItem.L1_Q) && isNotNull(currentItem.L1_Q.value)) ? {
										label: "QL1",
										value: formatValueToFullDeciSON(currentItem.L1_Q.value, currentItem.L1_Q.unit, MeasurementCategory.REACTIVE_POWERS) ?? "",
									} : null,
									(config.L2_Q && isNotNull(currentItem.L2_Q) && isNotNull(currentItem.L2_Q.value)) ? {
										label: "QL2",
										value: formatValueToFullDeciSON(currentItem.L2_Q.value, currentItem.L2_Q.unit, MeasurementCategory.REACTIVE_POWERS) ?? "",
									} : null,
									(config.L3_Q && isNotNull(currentItem.L3_Q) && isNotNull(currentItem.L3_Q.value)) ? {
										label: "QL3",
										value: formatValueToFullDeciSON(currentItem.L3_Q.value, currentItem.L3_Q.unit, MeasurementCategory.REACTIVE_POWERS) ?? "",
									} : null,

									(config.L1_S && isNotNull(currentItem.L1_S) && isNotNull(currentItem.L1_S.value)) ? {
										label: "SL1",
										value: formatValueToFullDeciSON(currentItem.L1_S.value, currentItem.L1_S.unit, MeasurementCategory.APPARENT_POWERS) ?? "",
									} : null,
									(config.L2_S && isNotNull(currentItem.L2_S) && isNotNull(currentItem.L2_S.value)) ? {
										label: "SL2",
										value: formatValueToFullDeciSON(currentItem.L2_S.value, currentItem.L2_S.unit, MeasurementCategory.APPARENT_POWERS) ?? "",
									} : null,
									(config.L3_S && isNotNull(currentItem.L3_S) && isNotNull(currentItem.L3_S.value)) ? {
										label: "SL3",
										value: formatValueToFullDeciSON(currentItem.L3_S.value, currentItem.L3_S.unit, MeasurementCategory.APPARENT_POWERS) ?? "",
									} : null,
									(config.FREQUENCY && isNotNull(currentItem.frequency200ms) && isNotNull(currentItem.frequency200ms.value)) ? {
										label: t("UTILS.frequency"),
										value: formatValueToFullDeciSON(currentItem.frequency200ms.value, currentItem.frequency200ms.unit, MeasurementCategory.FREQUENCIES) ?? "",
									} : null,
								].filter(isNotNull),
							};
						} }
					/>
				</Chart>
			</ChartCanvas>
		);
	} else {
		const {
			margins: {
				left: marginLeft,
				right,
				rightNoP,
				top: marginTop,
				bottom: marginBottom,
			},
		} = MERGED_TIMEPLOT_CHART_CONFIG;

		const isDisplayingTensions = config.U1N || config.U2N || config.U3N || config.UNE;
		const isDisplayingInterTensions = config.U12 || config.U23 || config.U31;
		const isDisplayingIntensities = config.I1 || config.I2 || config.I3;
		const isDisplayingPowersP = config.L1_P || config.L2_P || config.L3_P;
		const isDisplayingPowersQ = config.L1_Q || config.L2_Q || config.L3_Q;
		const isDisplayingPowersS = config.L1_S || config.L2_S || config.L3_S;
		const isDisplayingFrequency = config.FREQUENCY;

		const marginRight = (
			basicConfig.L1_P || basicConfig.L2_P || basicConfig.L3_P ||
			basicConfig.L1_Q || basicConfig.L2_Q || basicConfig.L3_Q ||
			basicConfig.L1_S || basicConfig.L2_S || basicConfig.L3_S
		) ?
			right
			:
			rightNoP;

		const mergedTensionsRange = [
			Math.min(tensionsRange[ 0 ], interTensionsRange[ 0 ]),
			Math.max(tensionsRange[ 1 ], interTensionsRange[ 1 ]),
		];

		if (data.length <= 2 * Math.ceil(xExtents[ 1 ] / (width - marginLeft - marginRight))) {
			return (
				<div className="w-full h-full flex items-center justify-center">
					<GridLoader size={ 15 } color="#0093DD"/>
				</div>
			);
		}

		return (
			<ChartCanvas
				ratio={ 1 }
				width={ width }
				height={ height }
				margin={ { left: marginLeft, right: marginRight, top: marginTop, bottom: marginBottom } }
				type="svg"
				seriesName={ `measurements-${ index }` }
				data={ chartData }
				xScale={ xScale }
				xAccessor={ xAccessor }
				displayXAccessor={ displayXAccessor }
				mouseMoveEvent={ true }
				panEvent={ true }
				zoomEvent={ true }
				xExtents={ xExtents }
				pointsPerPxThreshold={ 5 }
			>
				<Chart
					id="voltages-chart-U"
					yExtents={ mergedTensionsRange }
				>
					<XAxis
						tickFormat={ (index: number) => {
							const item = chartData?.find((dataItem: any) => dataItem.idx.index === index);

							return moment(item?.date).format("mm:ss");
						} }
						tickValues={ tickValues }
						innerTickSize={ -1 * (height - marginTop - marginBottom) }
						{ ...xGrid }
					/>
					<>
						{/* Hide xAxis grid outside chart (left and right)*/ }
						<rect x={ -marginLeft - 1 } y={ -marginTop - 1 } width={ marginLeft } height={ height + marginTop + 1 } fill="white"/>
						<rect x={ width - (marginLeft + marginRight) } y={ -marginTop - 1 } width={ marginLeft + marginRight } height={ height + marginTop + 1 } fill="white"/>
					</>
					<YAxis
						axisAt="left"
						orient="left"

						// tickFormat={ (v: number) => (isDisplayingTensions || isDisplayingInterTensions) ? v.toString() : "" }
						tickFormat={ (v: number) => (isDisplayingTensions || isDisplayingInterTensions) ? formatValueToDeciSONlValue(v, MeasurementCategory.VOLTAGES_WAVE)?.toString() : "" }
						fill="#ff00ff"
						tickStrokeOpacity={ 0.2 }
						innerTickSize={ -1 * (width - (marginLeft + marginRight)) }
						ticks={ 6 }
						{ ...yGrid }
					/>
					{
						isDisplayingIntensities &&
                        <YAxis
                            axisAt="left"
                            orient="left"
                            tickPadding={ 40 }

                            tickFormat={ (x: number) => {
								const [ y1, y2 ] = intensitiesRange;
								const [ y3, y4 ] = mergedTensionsRange;
								// return getSignificantDigitRound(y1 + Math.abs(y1 - y2) * (Math.abs(x - y3) / Math.abs(y3 - y4))).toString();
								return formatValueToDeciSONlValue(y1 + Math.abs(y1 - y2) * (Math.abs(x - y3) / Math.abs(y3 - y4)), MeasurementCategory.CURRENTS_WAVE)?.toString() ?? "";
							} }
                            tickStrokeOpacity={ 0 }
                            innerTickSize={ -1 * (width - (marginLeft + marginRight)) }
                            ticks={ 6 }
							{ ...yGrid }
                        />
					}
					{
						isDisplayingFrequency &&
                        <YAxis
                            axisAt="left"
                            orient="left"
                            tickPadding={ 80 }

                            tickFormat={ (x: number) => {
								const [ y1, y2 ] = frequencyRange;
								const [ y3, y4 ] = mergedTensionsRange;
								// return getSignificantDigitRound(y1 + Math.abs(y1 - y2) * (Math.abs(x - y3) / Math.abs(y3 - y4))).toString();
								return formatValueToDeciSONlValue(y1 + Math.abs(y1 - y2) * (Math.abs(x - y3) / Math.abs(y3 - y4)), MeasurementCategory.FREQUENCIES_WAVE)?.toString() ?? "";
							} }
                            tickStrokeOpacity={ 0 }
                            innerTickSize={ -1 * (width - (marginLeft + marginRight)) }
                            ticks={ 6 }
							{ ...yGrid }
                        />
					}
					{
						isDisplayingPowersP &&
                        <YAxis
                            axisAt="right"
                            orient="right"
                            tickPadding={ 10 }

                            tickFormat={ (x: number) => {
								const [ y1, y2 ] = powerPRange;
								const [ y3, y4 ] = mergedTensionsRange;
								// return getSignificantDigitRound(y1 + Math.abs(y1 - y2) * (Math.abs(x - y3) / Math.abs(y3 - y4))).toString();
								return formatValueToDeciSONlValue(y1 + Math.abs(y1 - y2) * (Math.abs(x - y3) / Math.abs(y3 - y4)), MeasurementCategory.ACTIVE_POWERS_WAVE)?.toString() ?? "";
							} }
                            tickStrokeOpacity={ 0 }
                            innerTickSize={ -1 * (width - (marginLeft + marginRight)) }
                            ticks={ 6 }
							{ ...yGrid }
                        />
					}
					{
						isDisplayingPowersQ &&
                        <YAxis
                            axisAt="right"
                            orient="right"
                            tickPadding={ 60 }

                            tickFormat={ (x: number) => {
								const [ y1, y2 ] = powerQRange;
								const [ y3, y4 ] = mergedTensionsRange;
								// return getSignificantDigitRound(y1 + Math.abs(y1 - y2) * (Math.abs(x - y3) / Math.abs(y3 - y4))).toString();
								return formatValueToDeciSONlValue(y1 + Math.abs(y1 - y2) * (Math.abs(x - y3) / Math.abs(y3 - y4)), MeasurementCategory.REACTIVE_POWERS_WAVE)?.toString() ?? "0";
							} }
                            tickStrokeOpacity={ 0 }
                            innerTickSize={ -1 * (width - (marginLeft + marginRight)) }
                            ticks={ 6 }
							{ ...yGrid }
                        />
					}
					{
						isDisplayingPowersS &&
                        <YAxis
                            axisAt="right"
                            orient="right"
                            tickPadding={ 110 }

                            tickFormat={ (x: number) => {
								const [ y1, y2 ] = powerSRange;
								const [ y3, y4 ] = mergedTensionsRange;
								// return getSignificantDigitRound(y1 + Math.abs(y1 - y2) * (Math.abs(x - y3) / Math.abs(y3 - y4))).toString();
								return formatValueToDeciSONlValue(y1 + Math.abs(y1 - y2) * (Math.abs(x - y3) / Math.abs(y3 - y4)), MeasurementCategory.APPARENT_POWERS_WAVE)?.toString() ?? "";
							} }
                            tickStrokeOpacity={ 0 }
                            innerTickSize={ -1 * (width - (marginLeft + marginRight)) }
                            ticks={ 6 }
							{ ...yGrid }
                        />
					}
					{
						config.U1N &&
                        <>
                            <ChartCurrentCoordinate
                                yAccessor={ (d: TimeplotChartData) => d.U1n_rms?.value }
                                fill={ appColors.U1 }
                            />
                            <LineSeries
                                yAccessor={ (d: TimeplotChartData) => d.U1n_rms?.value }
                                strokeWidth={ 2 }
                                stroke={ appColors.U1 }
                            />
                        </>
					}
					{
						config.U2N &&
                        <>
                            <ChartCurrentCoordinate
                                yAccessor={ (d: TimeplotChartData) => d.U2n_rms?.value }
                                fill={ appColors.U2 }
                            />
                            <LineSeries
                                yAccessor={ (d: TimeplotChartData) => d.U2n_rms?.value }
                                strokeWidth={ 2 }
                                stroke={ appColors.U2 }
                            />
                        </>
					}
					{
						config.U3N &&
                        <>
                            <ChartCurrentCoordinate
                                yAccessor={ (d: TimeplotChartData) => d.U3n_rms?.value }
                                fill={ appColors.U3 }
                            />
                            <LineSeries
                                yAccessor={ (d: TimeplotChartData) => d.U3n_rms?.value }
                                strokeWidth={ 2 }
                                stroke={ appColors.U3 }
                            />
                        </>
					}
					{
						config.UNE &&
                        <>
                            <ChartCurrentCoordinate
                                yAccessor={ (d: TimeplotChartData) => d.Une_rms?.value }
                                fill={ appColors.Un }
                            />
                            <LineSeries
                                yAccessor={ (d: TimeplotChartData) => d.Une_rms?.value }
                                strokeWidth={ 2 }
                                stroke={ appColors.Un }
                            />
                        </>
					}
					{
						config.U12 &&
                        <>
                            <ChartCurrentCoordinate
                                yAccessor={ (d: TimeplotChartData) => d.U12_rms?.value }
                                fill={ appColors.U12 }
                            />
                            <LineSeries
                                yAccessor={ (d: TimeplotChartData) => d.U12_rms?.value }
                                strokeWidth={ 2 }
                                stroke={ appColors.U12 }
                            />
                        </>
					}
					{
						config.U23 &&
                        <>
                            <ChartCurrentCoordinate
                                yAccessor={ (d: TimeplotChartData) => d.U23_rms?.value }
                                fill={ appColors.U23 }
                            />
                            <LineSeries
                                yAccessor={ (d: TimeplotChartData) => d.U23_rms?.value }
                                strokeWidth={ 2 }
                                stroke={ appColors.U23 }
                            />
                        </>
					}
					{
						config.U31 &&
                        <>
                            <ChartCurrentCoordinate
                                yAccessor={ (d: TimeplotChartData) => d.U31_rms?.value }
                                fill={ appColors.U31 }
                            />
                            <LineSeries
                                yAccessor={ (d: TimeplotChartData) => d.U31_rms?.value }
                                strokeWidth={ 2 }
                                stroke={ appColors.U31 }
                            />
                        </>
					}
					{
						(isDisplayingTensions || isDisplayingInterTensions) &&
                        <>
                            <rect x="-48" y="-10" width={ 45 } height={ 16 } fill="white"/>
							{/*<text x="-33" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>U[{ getPrefixToUnit(0.0, "V", MeasurementCategory.VOLTAGES_WAVE) }]</text>*/ }
                            <text x="-33" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>U[V]</text>
                        </>
					}
				</Chart>
				<Chart
					id="voltages-chart-I"
					yExtents={ intensitiesRange }
				>
					{
						config.I1 &&
                        <>
                            <ChartCurrentCoordinate
                                yAccessor={ (d: TimeplotChartData) => d.I1_rms?.value }
                                fill={ appColors.I1 }
                            />
                            <LineSeries
                                yAccessor={ (d: TimeplotChartData) => d.I1_rms?.value }
                                strokeWidth={ 2 }
                                stroke={ appColors.I1 }
                            />
                        </>
					}
					{
						config.I2 &&
                        <>
                            <ChartCurrentCoordinate
                                yAccessor={ (d: TimeplotChartData) => d.I2_rms?.value }
                                fill={ appColors.I2 }
                            />
                            <LineSeries
                                yAccessor={ (d: TimeplotChartData) => d.I2_rms?.value }
                                strokeWidth={ 2 }
                                stroke={ appColors.I2 }
                            />
                        </>
					}
					{
						config.I3 &&
                        <>
                            <ChartCurrentCoordinate
                                yAccessor={ (d: TimeplotChartData) => d.I3_rms?.value }
                                fill={ appColors.I3 }
                            />
                            <LineSeries
                                yAccessor={ (d: TimeplotChartData) => d.I3_rms?.value }
                                strokeWidth={ 2 }
                                stroke={ appColors.I3 }
                            />
                        </>
					}
					{
						isDisplayingIntensities &&
                        <>
                            <rect x="-75" y="-10" width={ 40 } height={ 16 } fill="white"/>
							{/*<text x="-60" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>I[{ getPrefixToUnit(0.0, "A", MeasurementCategory.CURRENTS_WAVE) }]</text>*/ }
                            <text x="-60" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>I[A]</text>
                        </>
					}
				</Chart>
				<Chart
					id="voltages-chart-frequency"
					yExtents={ frequencyRange }
				>
					{
						config.FREQUENCY &&
                        <>
                            <ChartCurrentCoordinate
                                yAccessor={ (d: TimeplotChartData) => d.frequency200ms?.value }
                                fill={ appColors.frequency }
                            />
                            <LineSeries
                                yAccessor={ (d: TimeplotChartData) => d.frequency200ms?.value }
                                strokeWidth={ 2 }
                                stroke={ appColors.frequency }
                            />
                        </>
					}
					{
						isDisplayingFrequency &&
                        <>
                            <rect x="-110" y="-10" width={ 40 } height={ 16 } fill="white"/>
							{/*<text x="-105" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>f[{ getPrefixToUnit(0.0, "Hz", MeasurementCategory.FREQUENCIES_WAVE) }]</text>*/ }
                            <text x="-105" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>f[Hz]</text>
                        </>
					}
				</Chart>
				<Chart
					id="voltages-chart-P"
					yExtents={ powerPRange }
				>
					{
						config.L1_P &&
                        <>
                            <ChartCurrentCoordinate
                                yAccessor={ (d: TimeplotChartData) => d.L1_P?.value }
                                fill={ appColors.L1P }
                            />
                            <LineSeries
                                yAccessor={ (d: TimeplotChartData) => d.L1_P?.value }
                                strokeWidth={ 2 }
                                stroke={ appColors.L1P }
                            />
                        </>
					}
					{
						config.L2_P &&
                        <>
                            <ChartCurrentCoordinate
                                yAccessor={ (d: TimeplotChartData) => d.L2_P?.value }
                                fill={ appColors.L2P }
                            />
                            <LineSeries
                                yAccessor={ (d: TimeplotChartData) => d.L2_P?.value }
                                strokeWidth={ 2 }
                                stroke={ appColors.L2P }
                            />
                        </>
					}
					{
						config.L3_P &&
                        <>
                            <ChartCurrentCoordinate
                                yAccessor={ (d: TimeplotChartData) => d.L3_P?.value }
                                fill={ appColors.L3P }
                            />
                            <LineSeries
                                yAccessor={ (d: TimeplotChartData) => d.L3_P?.value }
                                strokeWidth={ 2 }
                                stroke={ appColors.L3P }
                            />
                        </>
					}
					{
						isDisplayingPowersP &&
                        <>
                            <rect x={ width - marginLeft - marginRight + 6 } y="-10" width={ 40 } height={ 16 } fill="white"/>
							{/*<text x={ width - marginLeft - marginRight + 6 } y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>P[{ getPrefixToUnit(0.0, "W", MeasurementCategory.ACTIVE_POWERS_WAVE) }]</text>*/ }
                            <text x={ width - marginLeft - marginRight + 6 } y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>P[W]</text>
                        </>
					}
				</Chart>
				<Chart
					id="voltages-chart-Q"
					yExtents={ powerQRange }
				>
					{
						config.L1_Q &&
                        <>
                            <ChartCurrentCoordinate
                                yAccessor={ (d: TimeplotChartData) => d.L1_Q?.value }
                                fill={ appColors.L1Q }
                            />
                            <LineSeries
                                yAccessor={ (d: TimeplotChartData) => d.L1_Q?.value }
                                strokeWidth={ 2 }
                                stroke={ appColors.L1Q }
                            />
                        </>
					}
					{
						config.L2_Q &&
                        <>
                            <ChartCurrentCoordinate
                                yAccessor={ (d: TimeplotChartData) => d.L2_Q?.value }
                                fill={ appColors.L2Q }
                            />
                            <LineSeries
                                yAccessor={ (d: TimeplotChartData) => d.L2_Q?.value }
                                strokeWidth={ 2 }
                                stroke={ appColors.L2Q }
                            />
                        </>
					}
					{
						config.L3_Q &&
                        <>
                            <ChartCurrentCoordinate
                                yAccessor={ (d: TimeplotChartData) => d.L3_Q?.value }
                                fill={ appColors.L3Q }
                            />
                            <LineSeries
                                yAccessor={ (d: TimeplotChartData) => d.L3_Q?.value }
                                strokeWidth={ 2 }
                                stroke={ appColors.L3Q }
                            />
                        </>
					}
					{
						isDisplayingPowersQ &&
                        <>
                            <rect x={ width - marginLeft - marginRight + 55 } y="-10" width={ 40 } height={ 16 } fill="white"/>
							{/*<text x={ width - marginLeft - marginRight + 55 } y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>Q[{ getPrefixToUnit(0.0, "var", MeasurementCategory.REACTIVE_POWERS_WAVE) }]</text>*/ }
                            <text x={ width - marginLeft - marginRight + 55 } y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>Q[var]</text>
                        </>
					}
				</Chart>
				<Chart
					id="voltages-chart-S"
					yExtents={ powerSRange }
				>
					{
						config.L1_S &&
                        <>
                            <ChartCurrentCoordinate
                                yAccessor={ (d: TimeplotChartData) => d.L1_S?.value }
                                fill={ appColors.L1S }
                            />
                            <LineSeries
                                yAccessor={ (d: TimeplotChartData) => d.L1_S?.value }
                                strokeWidth={ 2 }
                                stroke={ appColors.L1S }
                            />
                        </>
					}
					{
						config.L2_S &&
                        <>
                            <ChartCurrentCoordinate
                                yAccessor={ (d: TimeplotChartData) => d.L2_S?.value }
                                fill={ appColors.L2S }
                            />
                            <LineSeries
                                yAccessor={ (d: TimeplotChartData) => d.L2_S?.value }
                                strokeWidth={ 2 }
                                stroke={ appColors.L2S }
                            />
                        </>
					}
					{
						config.L3_S &&
                        <>
                            <ChartCurrentCoordinate
                                yAccessor={ (d: TimeplotChartData) => d.L3_S?.value }
                                fill={ appColors.L3S }
                            />
                            <LineSeries
                                yAccessor={ (d: TimeplotChartData) => d.L3_S?.value }
                                strokeWidth={ 2 }
                                stroke={ appColors.L3S }
                            />
                        </>
					}
					{
						isDisplayingPowersS &&
                        <>
                            <rect x={ width - marginLeft - marginRight + 108 } y="-10" width={ 40 } height={ 16 } fill="white"/>
							{/*<text x={ width - marginLeft - marginRight + 108 } y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>S[{ getPrefixToUnit(0.0, "VA", MeasurementCategory.APPARENT_POWERS_WAVE) }]</text>*/ }
                            <text x={ width - marginLeft - marginRight + 108 } y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>S[VA]</text>
                        </>
					}
					{
						(
							isDisplayingTensions ||
							isDisplayingInterTensions ||
							isDisplayingIntensities ||
							isDisplayingFrequency ||
							isDisplayingPowersP ||
							isDisplayingPowersQ ||
							isDisplayingPowersS
						) &&
                        <ChartHoverTooltip
                            firstItem={ firstItem }
                            lastItem={ lastItem }
                            getTooltipContent={ (probeArgument: { currentItem: TimeplotChartData & StockChartIndex }) => {
								const { currentItem } = probeArgument;
								const date = moment(currentItem.timestamp).format("HH:mm:ss");
								return {
									x: date,
									y: [
										(config.U1N && isNotNull(currentItem.U1n_rms) && isNotNull(currentItem.U1n_rms.value)) ? {
											label: "U1N",
											value: formatValueToFullDeciSON(currentItem.U1n_rms.value, currentItem.U1n_rms.unit, MeasurementCategory.VOLTAGES) ?? "",
										} : null,
										(config.U2N && isNotNull(currentItem.U2n_rms) && isNotNull(currentItem.U2n_rms.value)) ? {
											label: "U2N",
											value: formatValueToFullDeciSON(currentItem.U2n_rms.value, currentItem.U2n_rms.unit, MeasurementCategory.VOLTAGES) ?? "",
										} : null,
										(config.U3N && isNotNull(currentItem.U3n_rms) && isNotNull(currentItem.U3n_rms.value)) ? {
											label: "U3N",
											value: formatValueToFullDeciSON(currentItem.U3n_rms.value, currentItem.U3n_rms.unit, MeasurementCategory.VOLTAGES) ?? "",
										} : null,

										(config.UNE && isNotNull(currentItem.Une_rms) && isNotNull(currentItem.Une_rms.value)) ? {
											label: "UE",
											value: formatValueToFullDeciSON(currentItem.Une_rms.value, currentItem.Une_rms.unit, MeasurementCategory.VOLTAGES) ?? "",
										} : null,

										(config.U12 && isNotNull(currentItem.U12_rms) && isNotNull(currentItem.U12_rms.value)) ? {
											label: "U12",
											value: formatValueToFullDeciSON(currentItem.U12_rms.value, currentItem.U12_rms.unit, MeasurementCategory.VOLTAGES) ?? "",
										} : null,
										(config.U23 && isNotNull(currentItem.U23_rms) && isNotNull(currentItem.U23_rms.value)) ? {
											label: "U23",
											value: formatValueToFullDeciSON(currentItem.U23_rms.value, currentItem.U23_rms.unit, MeasurementCategory.VOLTAGES) ?? "",
										} : null,
										(config.U31 && isNotNull(currentItem.U31_rms) && isNotNull(currentItem.U31_rms.value)) ? {
											label: "U31",
											value: formatValueToFullDeciSON(currentItem.U31_rms.value, currentItem.U31_rms.unit, MeasurementCategory.VOLTAGES) ?? "",
										} : null,

										(config.I1 && isNotNull(currentItem.I1_rms) && isNotNull(currentItem.I1_rms.value)) ? {
											label: "I1",
											value: formatValueToFullDeciSON(currentItem.I1_rms.value, currentItem.I1_rms.unit, MeasurementCategory.CURRENTS) ?? "",
										} : null,
										(config.I2 && isNotNull(currentItem.I2_rms) && isNotNull(currentItem.I2_rms.value)) ? {
											label: "I2",
											value: formatValueToFullDeciSON(currentItem.I2_rms.value, currentItem.I2_rms.unit, MeasurementCategory.CURRENTS) ?? "",
										} : null,
										(config.I3 && isNotNull(currentItem.I3_rms) && isNotNull(currentItem.I3_rms.value)) ? {
											label: "I3",
											value: formatValueToFullDeciSON(currentItem.I3_rms.value, currentItem.I3_rms.unit, MeasurementCategory.CURRENTS) ?? "",
										} : null,

										(config.L1_P && isNotNull(currentItem.L1_P) && isNotNull(currentItem.L1_P.value)) ? {
											label: "PL1",
											value: formatValueToFullDeciSON(currentItem.L1_P.value, currentItem.L1_P.unit, MeasurementCategory.ACTIVE_POWERS) ?? "",
										} : null,
										(config.L2_P && isNotNull(currentItem.L2_P) && isNotNull(currentItem.L2_P.value)) ? {
											label: "PL2",
											value: formatValueToFullDeciSON(currentItem.L2_P.value, currentItem.L2_P.unit, MeasurementCategory.ACTIVE_POWERS) ?? "",
										} : null,
										(config.L3_P && isNotNull(currentItem.L3_P) && isNotNull(currentItem.L3_P.value)) ? {
											label: "PL3",
											value: formatValueToFullDeciSON(currentItem.L3_P.value, currentItem.L3_P.unit, MeasurementCategory.ACTIVE_POWERS) ?? "",
										} : null,

										(config.L1_Q && isNotNull(currentItem.L1_Q) && isNotNull(currentItem.L1_Q.value)) ? {
											label: "QL1",
											value: formatValueToFullDeciSON(currentItem.L1_Q.value, currentItem.L1_Q.unit, MeasurementCategory.REACTIVE_POWERS) ?? "",
										} : null,
										(config.L2_Q && isNotNull(currentItem.L2_Q) && isNotNull(currentItem.L2_Q.value)) ? {
											label: "QL2",
											value: formatValueToFullDeciSON(currentItem.L2_Q.value, currentItem.L2_Q.unit, MeasurementCategory.REACTIVE_POWERS) ?? "",
										} : null,
										(config.L3_Q && isNotNull(currentItem.L3_Q) && isNotNull(currentItem.L3_Q.value)) ? {
											label: "QL3",
											value: formatValueToFullDeciSON(currentItem.L3_Q.value, currentItem.L3_Q.unit, MeasurementCategory.REACTIVE_POWERS) ?? "",
										} : null,

										(config.L1_S && isNotNull(currentItem.L1_S) && isNotNull(currentItem.L1_S.value)) ? {
											label: "SL1",
											value: formatValueToFullDeciSON(currentItem.L1_S.value, currentItem.L1_S.unit, MeasurementCategory.APPARENT_POWERS) ?? "",
										} : null,
										(config.L2_S && isNotNull(currentItem.L2_S) && isNotNull(currentItem.L2_S.value)) ? {
											label: "SL2",
											value: formatValueToFullDeciSON(currentItem.L2_S.value, currentItem.L2_S.unit, MeasurementCategory.APPARENT_POWERS) ?? "",
										} : null,
										(config.L3_S && isNotNull(currentItem.L3_S) && isNotNull(currentItem.L3_S.value)) ? {
											label: "SL3",
											value: formatValueToFullDeciSON(currentItem.L3_S.value, currentItem.L3_S.unit, MeasurementCategory.APPARENT_POWERS) ?? "",
										} : null,
										(config.FREQUENCY && isNotNull(currentItem.frequency200ms) && isNotNull(currentItem.frequency200ms.value)) ? {
											label: t("UTILS.frequency"),
											value: formatValueToFullDeciSON(currentItem.frequency200ms.value, currentItem.frequency200ms.unit, MeasurementCategory.FREQUENCIES) ?? "",
										} : null,
									].filter(isNotNull),
								};
							} }
                        />
					}
				</Chart>
			</ChartCanvas>
		);
	}
}

export default (TimeplotChart);
