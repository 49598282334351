import { Nullable } from "src/app/types/util.types";

export enum EventStatus {
	BEGIN = "BEGIN",
	PROGRESS = "PROGRESS",
	END = "END"
}

export enum EventType {
	EVENT_DIP = "EVENT_DIP",
	EVENT_SWELL = "EVENT_SWELL",
	EVENT_INTERRUPT = "EVENT_INTERRUPT",
}

export enum EventChannel {
	EVENTS_LIST_NONE = "EVENTS_LIST_NONE",
	EVENTS_LIST_L1 = "EVENTS_LIST_L1",
	EVENTS_LIST_L1_L2 = "EVENTS_LIST_L1_L2",
	EVENTS_LIST_L1_L2_L3 = "EVENTS_LIST_L1_L2_L3",
	EVENTS_LIST_L2 = "EVENTS_LIST_L2",
	EVENTS_LIST_L2_L3 = "EVENTS_LIST_L2_L3",
	EVENTS_LIST_L3 = "EVENTS_LIST_L3",
	EVENTS_LIST_L3_L1 = "EVENTS_LIST_L3_L1",
}

export enum EventFlag {
	FLAGGED_61000_4_30 = "FLAGGED_61000_4_30",
	PLL_OUT_OF_SYNC = "PLL_OUT_OF_SYNC",
	ADC_OVERFLOW = "ADC_OVERFLOW",
	GPS_OUT_OF_SYNC = "GPS_OUT_OF_SYNC",
	TIME_RESYNC = "TIME_RESYNC"
}

export type StandardEvents = {
	events: EventsCore & {
		eventsRecordedTotal: number
		eventsRecordedDip: number
		eventsRecordedSwell: number
		eventsRecordedInterrupt: number
		eventsRecordedOther: number
	}
}

export type UserEvents = {
	events: EventsCore
}

export type EventsCore = {
	status: {
		value: number
		text: EventStatus
	}[]
	types: {
		value: number
		text: EventType
	}[]
	channels: {
		value: number
		text: EventChannel
	}[]
	flags: {
		mask: number
		text: EventFlag
	}[]
	event: Event[]
}

export type SingleEvent = {
	events: {
		status: {
			value: number
			text: EventStatus
		}[]
		types: {
			value: number
			text: EventType
		}[]
		channels: {
			value: number
			text: EventChannel
		}[]
		flags: {
			mask: number
			text: EventFlag
		}[]
		cfgWaveformRecDuration: {
			value: number
			unit: string
			minValue: number
			maxValue: number
		}
		cfgWaveformPretriggerDuration: {
			value: number
			unit: string
			minValue: number
			maxValue: number
		}
		cfgRms12RecTime: {
			value: number
			unit: string
			minValue: number
			maxValue: number
		}
		cfgRms12PretriggerTime: {
			value: number
			unit: string
			minValue: number
			maxValue: number
		}
		event: Event
	}
}

export type Event = {
	id: number
	timestampStart: number
	timestampEnd: number
	timestampStatus: number
	duration: number // in us (e-6)
	type: number
	channels: number
	flags: number
	avgValue: {
		value: Nullable<number>
		unit: string
	}
	extremum: {
		value: Nullable<number>
		unit: string
	}
	threshold: {
		value: Nullable<number>
		unit: string
	}
}
