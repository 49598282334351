import { SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import VoltageThdfCard from "src/app/components/MeasurementsUser/VoltageThdfCard.component";
import VoltageThdrCard from "src/app/components/MeasurementsUser/VoltageThdrCard.component";
import VoltageHarmonicsCard from "src/app/components/MeasurementsUser/VoltageHarmonicsCard.component";
import VoltageTidrCard from "src/app/components/MeasurementsUser/VoltageTidrCard.component";
import VoltageTidfCard from "src/app/components/MeasurementsUser/VoltageTidfCard.component";
import VoltageInterharmonicsCard from "src/app/components/MeasurementsUser/VoltageInterharmonicsCard.component";

type Props = {
	settingsMeasurementsUser: SettingsMeasurementsUser
};

function HarmonicsVoltageTab(props: Props) {

	const {
		settingsMeasurementsUser,
	} = props;

	return (
		<div className="p-4 flex flex-col gap-3">
			<VoltageThdfCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<VoltageThdrCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<VoltageTidfCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<VoltageTidrCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>



			<VoltageHarmonicsCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>


			<VoltageInterharmonicsCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
		</div>
	);
}

export default (HarmonicsVoltageTab);
