import classNames from "classnames";
import { EnumDictionary } from "src/app/types/util.types";
import { HiEye, HiEyeOff } from "react-icons/hi";

export enum ChartFilterColor {
	U1 = "U1",
	U2 = "U2",
	U3 = "U3",
	U12 = "U12",
	U23 = "U23",
	U31 = "U31",
	Un = "Un",
	I1 = "I1",
	I2 = "I2",
	I3 = "I3",
	In = "In",
	Ie = "Ie",
	L1P = "L1P",
	L2P = "L2P",
	L3P = "L3P",
	TotP = "TotP",
	L1Q = "L1Q",
	L2Q = "L2Q",
	L3Q = "L3Q",
	TotQ = "TotQ",
	L1S = "L1S",
	L2S = "L2S",
	L3S = "L3S",
	TotS = "TotS",
	frequency = "frequency",
	default = "default",
}

type Props = {
	text: string
	color: ChartFilterColor
	active: boolean
	onClick: () => void
};

type FilterDictionary = {
	default: string
	active: string
	text: string
}

const chartFilterDictionary: EnumDictionary<ChartFilterColor, FilterDictionary> = () => ({
	[ ChartFilterColor.U1 ]: {
		default: "border-2 border-U1 transition",
		active: "bg-categories-voltages-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.U2 ]: {
		default: "border-2 border-U2 transition",
		active: "bg-categories-voltages-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.U3 ]: {
		default: "border-2 border-U3 transition",
		active: "bg-categories-voltages-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.U12 ]: {
		default: "border-2 border-U12 transition",
		active: "bg-categories-voltages-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.U23 ]: {
		default: "border-2 border-U23 transition",
		active: "bg-categories-voltages-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.U31 ]: {
		default: "border-2 border-U31 transition",
		active: "bg-categories-voltages-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.Un ]: {
		default: "border-2 border-Un transition",
		active: "bg-categories-voltages-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.I1 ]: {
		default: "border-2 border-I1 transition",
		active: "bg-categories-currents-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.I2 ]: {
		default: "border-2 border-I2 transition",
		active: "bg-categories-currents-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.I3 ]: {
		default: "border-2 border-I3 transition",
		active: "bg-categories-currents-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.In ]: {
		default: "border-2 border-In transition",
		active: "bg-categories-currents-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.Ie ]: {
		default: "border-2 border-Ie transition",
		active: "bg-categories-currents-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.L1P ]: {
		default: "border-2 border-L1P transition",
		active: "bg-categories-activePowers-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.L2P ]: {
		default: "border-2 border-L2P transition",
		active: "bg-categories-activePowers-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.L3P ]: {
		default: "border-2 border-L3P transition",
		active: "bg-categories-activePowers-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.TotP ]: {
		default: "border-2 border-TotP transition",
		active: "bg-categories-activePowers-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.L1Q ]: {
		default: "border-2 border-L1Q transition",
		active: "bg-categories-reactivePowers-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.L2Q ]: {
		default: "border-2 border-L2Q transition",
		active: "bg-categories-reactivePowers-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.L3Q ]: {
		default: "border-2 border-L3Q transition",
		active: "bg-categories-reactivePowers-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.TotQ ]: {
		default: "border-2 border-TotQ transition",
		active: "bg-categories-reactivePowers-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.L1S ]: {
		default: "border-2 border-L1S transition",
		active: "bg-categories-apparentPowers-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.L2S ]: {
		default: "border-2 border-L2S transition",
		active: "bg-categories-apparentPowers-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.L3S ]: {
		default: "border-2 border-L3S transition",
		active: "bg-categories-apparentPowers-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.TotS ]: {
		default: "border-2 border-TotS transition",
		active: "bg-categories-apparentPowers-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.frequency ]: {
		default: "border-2 border-frequency transition",
		active: "bg-categories-frequencies-100",
		text: "text-gray-500",
	},
	[ ChartFilterColor.default ]: {
		default: "border-2 border-primary-400 transition",
		active: "bg-primary-100",
		text: "text-gray-500",
	},
});

function ChartFilter(props: Props) {

	const {
		text,
		color,
		active,
		onClick,
	} = props;

	const {
		default: defaultClassName,
		active: activeClassName,
		text: textClassName,
	} = chartFilterDictionary()[ color ];

	return (
		<div
			onClick={ onClick }
			className={
				classNames(
					"cursor-pointer py-2 px-4 rounded-md flex items-center justify-between gap-[5px]",
					defaultClassName,
					{ [ activeClassName ]: active },
				)
			}
		>
			{
				active
					?
					<HiEye className="text-gray-600 w-3.5 h-3.5"/>
					:
					<HiEyeOff className="text-gray-400 w-3.5 h-3.5"/>
			}
			<span
				className={
					classNames(
						"text-sm font-medium leading-none",
						textClassName,
					)
				}
			>
				{ text }
			</span>
		</div>
	);
}

export default (ChartFilter);
