import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import useWebSocket, { WebSocketUri } from "src/app/utils/hooks/useWebSocket";
import { DataSheetLive } from "src/app/types/api/ws.types";
import MeasurementsContainer from "src/app/containers/Realtime/Measurements.container";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function MeasurementsView(props: Props) {

	const [ dataSheetLive ] = useWebSocket<DataSheetLive>(WebSocketUri.DATA_SHEET_LIVE, true);

	return (
		<MeasurementsContainer
			dataSheetLive={ dataSheetLive }
		/>
	);
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MeasurementsView);
