import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import MemoryContainer from "src/app/containers/Settings/Memory.container";
import { fetchSettingsMemoryAsync } from "src/app/store/features/settings/settings.actions";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";
import CallbackPromptProvider from "src/app/hoc/providers/CallbackPrompt.provider";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function MemoryView(props: Props) {

	const {
		fetchMemorySettings,
		settings,
	} = props;

	return (
		<CallbackPromptProvider>
			<CachedThenFreshStrategy
				request={ () => fetchMemorySettings() }
				state={ settings }
			>
				{
					settings =>
						<MemoryContainer
							settings={ settings }
						/>
				}
			</CachedThenFreshStrategy>
		</CallbackPromptProvider>
	);
}

const mapStateToProps = (state: RootState) => ({
	settings: state.settings.settingsMemory,
});

const mapDispatchToProps = {
	fetchMemorySettings: fetchSettingsMemoryAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(MemoryView);
