import { SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import ActiveEnergyCard from "src/app/components/MeasurementsUser/ActiveEnergyCard.component";
import ReactiveEnergy4QCard from "src/app/components/MeasurementsUser/ReactiveEnergy4qCard.component";
import ApparentEnergyCard from "src/app/components/MeasurementsUser/ApparentEnergyCard.component";

type Props = {
	settingsMeasurementsUser: SettingsMeasurementsUser
};

function PowerAndEnergyEnergyTab(props: Props) {

	const {
		settingsMeasurementsUser,
	} = props;

	return (
		<div className="p-4 flex flex-col gap-3">
			<ActiveEnergyCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<ReactiveEnergy4QCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<ApparentEnergyCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
		</div>
	);
}

export default (PowerAndEnergyEnergyTab);
