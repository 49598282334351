import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import RecordingsContainer from "src/app/containers/Settings/Recordings.container";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";
import { fetchSettingsRecordingAsync } from "src/app/store/features/settings/settings.actions";
import CallbackPromptProvider from "src/app/hoc/providers/CallbackPrompt.provider";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function RecordingsView(props: Props) {

	const {
		fetchSettingsRecording,
		settingsRecording,
	} = props;

	return (
		<CallbackPromptProvider>
			<CachedThenFreshStrategy
				request={ fetchSettingsRecording }
				state={ settingsRecording }
			>
				{
					settingsRecording =>
						<RecordingsContainer
							settingsRecording={ settingsRecording }
						/>
				}
			</CachedThenFreshStrategy>
		</CallbackPromptProvider>
	);
}

const mapStateToProps = (state: RootState) => ({
	settingsRecording: state.settings.settingsRecording,
});

const mapDispatchToProps = {
	fetchSettingsRecording: fetchSettingsRecordingAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecordingsView);
