import { createSelector } from "reselect";
import { RootState } from "src/app/store/root.reducer";
import { DataState } from "src/app/types/redux.types";

export const getLoggedUser = createSelector(
	[ (state: RootState) => state.user.loggedUser ],
	(loggedUser) => loggedUser,
);

export const isAuthorized = createSelector(
	[ getLoggedUser ],
	(loggedUser) => loggedUser.dataState === DataState.PRESENT,
);
