import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import EventsContainer from "src/app/containers/Event/Events.container";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";
import { uiFetchMoreStandardEvents, uiFetchStandardEventPqdif, uiFetchStandardEvents } from "src/app/store/features/ui/dashboard/ui.dashboard.actions";
import useWebSocket, { WebSocketUri } from "src/app/utils/hooks/useWebSocket";
import { DataBasicLive } from "src/app/types/api/ws.types";
import { useCallback, useEffect, useState } from "react";
import { fetchNewStandardEventsAsync } from "src/app/store/features/dashboard/dashboard.actions";
import { Nullable } from "src/app/types/util.types";
import { isNull } from "src/app/utils/typeguards";
import { Event } from "src/app/types/api/event.types";
import { useTranslation } from "react-i18next";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function StandardEventsView(props: Props) {

	const { t } = useTranslation();

	const {
		fetchEvents,
		fetchNewEvents,
		fetchMoreEvents,
		events,
		fetchPqDif,
	} = props;

	const [ lastEvent ] = useWebSocket<DataBasicLive>(WebSocketUri.DATA_BASIC_LIVE, true);

	const [ eventsRecorded, setEventsRecorded ] = useState<Nullable<number>>(null);
	const lastEventsRecorded = lastEvent?.events?.last_index;

	useEffect(() => {
		if (isNull(eventsRecorded)) {
			setEventsRecorded(lastEventsRecorded);
			return;
		}

		if (lastEventsRecorded !== eventsRecorded) {
			fetchNewEvents();
		}
	}, [ lastEventsRecorded ]);

	const _getEventWaveformLink = useCallback((event: Event) => `/standard-events/${ event.id }/waveform`, []);
	const _getEventRmsWaveformLink = useCallback((event: Event) => `/standard-events/${ event.id }/rms`, []);

	return (
		<CachedThenFreshStrategy
			request={ fetchEvents }
			state={ events }
		>
			{
				events =>
					<EventsContainer
						title={ t("EVENTS.events standard") }
						events={ events.events }
						fetchMoreEvents={ fetchMoreEvents }
						getEventWaveformLink={ _getEventWaveformLink }
						getEventRmsWaveformLink={ _getEventRmsWaveformLink }
						onDownloadPqDif={ event => fetchPqDif({ event_id: event.id }) }
					/>
			}
		</CachedThenFreshStrategy>
	);
}

const mapStateToProps = (state: RootState) => ({
	events: state.dashboard.standardEvents,
});

const mapDispatchToProps = {
	fetchEvents: uiFetchStandardEvents,
	fetchMoreEvents: uiFetchMoreStandardEvents,
	fetchNewEvents: fetchNewStandardEventsAsync.request,
	fetchPqDif: uiFetchStandardEventPqdif,
};

export default connect(mapStateToProps, mapDispatchToProps)(StandardEventsView);
