import { OnOff, SettingsTime, TimeSyncMode, Timezone } from "src/app/types/api/settings.types";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateField } from "src/app/utils/forms";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { getTimezone, mapEnumSettingToSelectOptions, mapEnumToSelectOptions, translateEnumValue } from "src/app/utils/helpers";
import useForm from "src/app/utils/hooks/useForm";
import { useEffect } from "react";
import { Button, Card, ToggleSwitch } from "flowbite-react";
import classNames from "classnames";
import Input from "src/app/components/Form/Input.component";
import Select from "src/app/components/Form/Select.component";
import { timeSyncModeDictionary, timezoneDictionary } from "src/app/utils/constants/dictionaries";
import { isNull } from "src/app/utils/typeguards";
import LabelValue from "src/app/components/Utils/LabelValue.component";
import Moment from "react-moment";
import { getRtcLocalTimestampDiffSeconds, getRtcUtcTimestampDiffSeconds } from "src/app/store/features/settings/settings.selectors";
import MaskInput from "src/app/components/Form/MaskInput.component";
import moment from "moment";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsTime: SettingsTime
	};

type TimeForm = {
	rtcUtcTimestamp: string
	timeSyncMode: number
	timezone: Timezone
	daylightSavingsTime: boolean
	NTPEnable: boolean
	NTPServer1Name: string
	NTPServer1NameMaxLength: number
	NTPServer2Name: string
	NTPServer2NameMaxLength: number
	NTPServer3Name: string
	NTPServer3NameMaxLength: number
	NTPServer4Name: string
	NTPServer4NameMaxLength: number
}

const validator: FormValidator<TimeForm> = {
	rtcUtcTimestamp: (rtcUtcTimestamp, optional, form) => {
		if (form.rtcUtcTimestamp.disabled) return null;
		if (rtcUtcTimestamp.includes("_")) return i18n.t("TIME.provide full date");

		if (!moment(rtcUtcTimestamp, "YYYY-MM-DD hh:mm:ss").isValid()) return i18n.t("TIME.date is invalid");

		return null;
	},
	timeSyncMode: () => null,
	timezone: () => null,
	daylightSavingsTime: () => null,
	NTPEnable: () => null,
	NTPServer1Name: (NTPServer1Name, optional, form) => validateField(i18n.t("TIME.NTP server 1"), NTPServer1Name, optional, "he", { minLength: 0, maxLength: form.NTPServer1NameMaxLength.value }),
	NTPServer1NameMaxLength: () => null,
	NTPServer2Name: (NTPServer2Name, optional, form) => validateField(i18n.t("TIME.NTP server 2"), NTPServer2Name, optional, "he", { minLength: 0, maxLength: form.NTPServer2NameMaxLength.value }),
	NTPServer2NameMaxLength: () => null,
	NTPServer3Name: (NTPServer3Name, optional, form) => validateField(i18n.t("TIME.NTP server 3"), NTPServer3Name, optional, "he", { minLength: 0, maxLength: form.NTPServer3NameMaxLength.value }),
	NTPServer3NameMaxLength: () => null,
	NTPServer4Name: (NTPServer4Name, optional, form) => validateField(i18n.t("TIME.NTP server 4"), NTPServer4Name, optional, "he", { minLength: 0, maxLength: form.NTPServer4NameMaxLength.value }),
	NTPServer4NameMaxLength: () => null,
};

export const browserTimeZoneOffsetMinutes = new Date().getTimezoneOffset() * -1;

function TimeContainer(props: Props) {

	const { t } = useTranslation();

	const {
		settingsTime,
		utcDiff,
		localDiff,
		isAdmin,
	} = props;

	const reducerForm = useReducerForm(
		"buffor",
		bufforFormActions,
		() => undefined,
	);

	const _handleSubmit = (values: TimeForm) => {
		reducerForm.handleChange("rtcUtcTimestamp", values.rtcUtcTimestamp);
		reducerForm.handleChange("timeSyncMode", values.timeSyncMode);
		reducerForm.handleChange("timezone", values.timezone);
		reducerForm.handleChange("daylightSavingsTime", values.daylightSavingsTime);
		reducerForm.handleChange("NTPEnable", values.NTPEnable);
		reducerForm.handleChange("NTPServer1Name", values.NTPServer1Name);
		reducerForm.handleChange("NTPServer2Name", values.NTPServer2Name);
		reducerForm.handleChange("NTPServer3Name", values.NTPServer3Name);
		reducerForm.handleChange("NTPServer4Name", values.NTPServer4Name);
	};

	const _getInitialState = () => {
		const NTPEnable = (reducerForm.form.NTPEnable.value !== reducerForm.form.NTPEnable.initialValue) ? reducerForm.form.NTPEnable.value : settingsTime?.time?.cfgNtpEnable?.enum?.find(enumValue => enumValue.value === settingsTime?.time?.cfgNtpEnable?.value)?.text === OnOff.ON;
		const timeSyncMode = (reducerForm.form.timeSyncMode.value !== reducerForm.form.timeSyncMode.initialValue) ? reducerForm.form.timeSyncMode.value : settingsTime?.time?.cfgTimeSyncMode?.value ?? 0;
		const isRtc = translateEnumValue(settingsTime?.time?.cfgTimeSyncMode?.enum ?? [], timeSyncMode, timeSyncModeDictionary) === timeSyncModeDictionary()[ TimeSyncMode.TIME_SYNC_RTC ];
		const daylightSavingsTime = (reducerForm.form.daylightSavingsTime.value !== reducerForm.form.daylightSavingsTime.initialValue) ? reducerForm.form.daylightSavingsTime.value : settingsTime?.time?.cfgDaylightSavingsTime?.enum?.find(enumValue => enumValue.value === settingsTime?.time?.cfgDaylightSavingsTime?.value)?.text === OnOff.ON;

		const rtc = moment((settingsTime?.time?.rtcUtcTimestamp ?? 0) * 1000)
			.subtract({ minutes: browserTimeZoneOffsetMinutes })
			.add({ minutes: ((settingsTime?.time?.cfgTimezone?.value ?? 0) + (daylightSavingsTime ? 60 : 0)) })
			.format("YYYY-MM-DD HH:mm:ss");

		return {
			rtcUtcTimestamp: createFormField(rtc, { disabled: !isAdmin || !isRtc }),
			timeSyncMode: createFormField(timeSyncMode, { disabled: !isAdmin }),
			timezone: createFormField((reducerForm.form.timezone.value !== reducerForm.form.timezone.initialValue) ? reducerForm.form.timezone.value : getTimezone(settingsTime?.time?.cfgTimezone?.value ?? 0), { disabled: !isAdmin }),
			daylightSavingsTime: createFormField(daylightSavingsTime, { disabled: !isAdmin }),
			NTPEnable: createFormField(NTPEnable, { disabled: !isAdmin }),
			NTPServer1Name: createFormField((reducerForm.form.NTPServer1Name.value !== reducerForm.form.NTPServer1Name.initialValue) ? reducerForm.form.NTPServer1Name.value : settingsTime?.time?.cfgNtpServer1Name?.value ?? "", { disabled: (!isAdmin || !NTPEnable), optional: true }),
			NTPServer1NameMaxLength: createFormField(settingsTime?.time?.cfgNtpServer1Name?.maxValue ?? 0),
			NTPServer2Name: createFormField((reducerForm.form.NTPServer2Name.value !== reducerForm.form.NTPServer2Name.initialValue) ? reducerForm.form.NTPServer2Name.value : settingsTime?.time?.cfgNtpServer2Name?.value ?? "", { disabled: (!isAdmin || !NTPEnable), optional: true }),
			NTPServer2NameMaxLength: createFormField(settingsTime?.time?.cfgNtpServer2Name?.maxValue ?? 0),
			NTPServer3Name: createFormField((reducerForm.form.NTPServer3Name.value !== reducerForm.form.NTPServer3Name.initialValue) ? reducerForm.form.NTPServer3Name.value : settingsTime?.time?.cfgNtpServer3Name?.value ?? "", { disabled: (!isAdmin || !NTPEnable), optional: true }),
			NTPServer3NameMaxLength: createFormField(settingsTime?.time?.cfgNtpServer3Name?.maxValue ?? 0),
			NTPServer4Name: createFormField((reducerForm.form.NTPServer4Name.value !== reducerForm.form.NTPServer4Name.initialValue) ? reducerForm.form.NTPServer4Name.value : settingsTime?.time?.cfgNtpServer4Name?.value ?? "", { disabled: (!isAdmin || !NTPEnable), optional: true }),
			NTPServer4NameMaxLength: createFormField(settingsTime?.time?.cfgNtpServer4Name?.maxValue ?? 0),
		};
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit, {}, true);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ settingsTime ]);

	useEffect(() => {
		toggleDisable("rtcUtcTimestamp", false);
		toggleDisable("timeSyncMode", false);
		toggleDisable("timezone", false);
		toggleDisable("daylightSavingsTime", false);
		toggleDisable("NTPEnable", false);
		toggleDisable("NTPServer1Name", false);
		toggleDisable("NTPServer2Name", false);
		toggleDisable("NTPServer3Name", false);
		toggleDisable("NTPServer4Name", false);

		handleChange("rtcUtcTimestamp", reducerForm.form.rtcUtcTimestamp.value);
		handleChange("timeSyncMode", reducerForm.form.timeSyncMode.value);
		handleChange("timezone", reducerForm.form.timezone.value);
		handleChange("daylightSavingsTime", reducerForm.form.daylightSavingsTime.value);
		handleChange("NTPEnable", reducerForm.form.NTPEnable.value);
		handleChange("NTPServer1Name", reducerForm.form.NTPServer1Name.value);
		handleChange("NTPServer2Name", reducerForm.form.NTPServer2Name.value);
		handleChange("NTPServer3Name", reducerForm.form.NTPServer3Name.value);
		handleChange("NTPServer4Name", reducerForm.form.NTPServer4Name.value);

		toggleDisable("rtcUtcTimestamp", !isAdmin);
		toggleDisable("timeSyncMode", !isAdmin);
		toggleDisable("timezone", !isAdmin);
		toggleDisable("daylightSavingsTime", !isAdmin);
		toggleDisable("NTPEnable", !isAdmin);
		toggleDisable("NTPServer1Name", !reducerForm.form.NTPEnable.value || !isAdmin);
		toggleDisable("NTPServer2Name", !reducerForm.form.NTPEnable.value || !isAdmin);
		toggleDisable("NTPServer3Name", !reducerForm.form.NTPEnable.value || !isAdmin);
		toggleDisable("NTPServer4Name", !reducerForm.form.NTPEnable.value || !isAdmin);
	}, [
		reducerForm.form.rtcUtcTimestamp.value,
		reducerForm.form.timeSyncMode.value,
		reducerForm.form.timezone.value,
		reducerForm.form.daylightSavingsTime.value,
		reducerForm.form.NTPEnable.value,
		reducerForm.form.NTPServer1Name.value,
		reducerForm.form.NTPServer2Name.value,
		reducerForm.form.NTPServer3Name.value,
		reducerForm.form.NTPServer4Name.value,
	]);

	const isRtc = translateEnumValue(settingsTime?.time?.cfgTimeSyncMode?.enum ?? [], form.timeSyncMode.value, timeSyncModeDictionary) === timeSyncModeDictionary()[ TimeSyncMode.TIME_SYNC_RTC ];

	return (
		<Card>
			<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
				{ t("TIME.time") }
			</h5>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<div className="flex gap-4">
					<div className="min-w-[150px]">
						<LabelValue
							label={ t("TIME.UTC") }
							value={
								<Moment
									utc
									format="YYYY-MM-DD HH:mm:ss"
									add={ { seconds: utcDiff } }
									interval={ 1000 }
								/>
							}
						/>
					</div>
					<div className="min-w-[150px]">
						<LabelValue
							label={ t("TIME.local time") }
							value={
								<Moment
									utc
									format="YYYY-MM-DD HH:mm:ss"
									add={ { seconds: localDiff } }
									interval={ 1000 }
								/>
							}
						/>
					</div>
				</div>
				<Select
					label={ t("TIME.time synchronisation") }
					options={ mapEnumSettingToSelectOptions(settingsTime?.time?.cfgTimeSyncMode, timeSyncModeDictionary) }
					formItem={ form.timeSyncMode }
					onChange={ option => {
						if (isNull(option)) return;

						handleChange("timeSyncMode", option?.value);
						handleBlur("timeSyncMode");

						const isRtc = translateEnumValue(settingsTime?.time?.cfgTimeSyncMode?.enum ?? [], option?.value, timeSyncModeDictionary) === timeSyncModeDictionary()[ TimeSyncMode.TIME_SYNC_RTC ];

						toggleDisable("rtcUtcTimestamp", !isRtc);
						handleChange("NTPEnable", !isRtc);

						toggleDisable("NTPServer1Name", isRtc);
						toggleDisable("NTPServer2Name", isRtc);
						toggleDisable("NTPServer3Name", isRtc);
						toggleDisable("NTPServer4Name", isRtc);
					} }
					isSearchable={ false }
					isClearable={ false }
					hasBeenChanged={ reducerForm.form.timeSyncMode.value !== reducerForm.form.timeSyncMode.initialValue }
				/>
				{
					isRtc &&
                    <MaskInput
                        formItem={ form.rtcUtcTimestamp }
                        label={ t("TIME.RTC time") + " (YYYY-MM-DD hh:mm:ss)" }
                        name="rtcTime"
                        onChange={ e => handleChange("rtcUtcTimestamp", e.target.value) }
                        onBlur={ () => handleBlur("rtcUtcTimestamp") }
                        inputProps={ { type: "text" } }
                        mask={ `XXXX-XX-XX XX:XX:XX` }
                        maskChar="_"
                        formatChars={ {
							"X": "[0-9]",
						} }
                    />
				}
				<Select
					label={ t("TIME.timezone") }
					options={ mapEnumToSelectOptions(Timezone, timezoneDictionary) }
					formItem={ form.timezone }
					onChange={ option => {
						if (isNull(option)) return;

						handleChange("timezone", option?.value);
						handleBlur("timezone");
					} }
					isSearchable={ false }
					isClearable={ false }
					hasBeenChanged={ reducerForm.form.timezone.value !== reducerForm.form.timezone.initialValue }
				/>
				<ToggleSwitch
					className={
						classNames(
							"[&>div]:w-9 [&>div]:h-5 [&>div]:after:absolute [&>div]:after:top-[2px] [&>div]:after:left-[2px] [&>div]:after:h-4 [&>div]:after:w-4",
							{ "[&>span]:!text-yellow-400": reducerForm.form.daylightSavingsTime.value !== reducerForm.form.daylightSavingsTime.initialValue },
						)
					}
					disabled={ form.daylightSavingsTime.disabled }
					checked={ form.daylightSavingsTime.value }
					label={ t("TIME.daylight saving time") }
					onChange={ () => handleChange("daylightSavingsTime", !form.daylightSavingsTime.value) }
				/>
				<ToggleSwitch
					className={
						classNames(
							"[&>div]:w-9 [&>div]:h-5 [&>div]:after:absolute [&>div]:after:top-[2px] [&>div]:after:left-[2px] [&>div]:after:h-4 [&>div]:after:w-4",
							{ "[&>span]:!text-yellow-400": reducerForm.form.NTPEnable.value !== reducerForm.form.NTPEnable.initialValue },
						)
					}
					checked={ form.NTPEnable.value }
					label={ t("TIME.NTP enable") }
					disabled={ isRtc || !isAdmin }
					onChange={ () => {
						handleChange("NTPEnable", !form.NTPEnable.value);

						toggleDisable("NTPServer1Name", form.NTPEnable.value);
						toggleDisable("NTPServer2Name", form.NTPEnable.value);
						toggleDisable("NTPServer3Name", form.NTPEnable.value);
						toggleDisable("NTPServer4Name", form.NTPEnable.value);
					} }
				/>
				<Input
					formItem={ form.NTPServer1Name }
					label={ t("TIME.NTP server 1") }
					name="NTPServer1Name"
					inputProps={ {
						type: "text",
						onChange: (e) => handleChange("NTPServer1Name", e.target.value),
						onBlur: () => handleBlur("NTPServer1Name"),
					} }
					hasBeenChanged={ reducerForm.form.NTPServer1Name.value !== reducerForm.form.NTPServer1Name.initialValue }
				/>
				<Input
					formItem={ form.NTPServer2Name }
					label={ t("TIME.NTP server 2") }
					name="NTPServer2Name"
					inputProps={ {
						type: "text",
						onChange: (e) => handleChange("NTPServer2Name", e.target.value),
						onBlur: () => handleBlur("NTPServer2Name"),
					} }
					hasBeenChanged={ reducerForm.form.NTPServer2Name.value !== reducerForm.form.NTPServer2Name.initialValue }
				/>
				<Input
					formItem={ form.NTPServer3Name }
					label={ t("TIME.NTP server 3") }
					name="NTPServer3Name"
					inputProps={ {
						type: "text",
						onChange: (e) => handleChange("NTPServer3Name", e.target.value),
						onBlur: () => handleBlur("NTPServer3Name"),
					} }
					hasBeenChanged={ reducerForm.form.NTPServer3Name.value !== reducerForm.form.NTPServer3Name.initialValue }
				/>
				<Input
					formItem={ form.NTPServer4Name }
					label={ t("TIME.NTP server 4") }
					name="NTPServer4Name"
					inputProps={ {
						type: "text",
						onChange: (e) => handleChange("NTPServer4Name", e.target.value),
						onBlur: () => handleBlur("NTPServer4Name"),
					} }
					hasBeenChanged={ reducerForm.form.NTPServer4Name.value !== reducerForm.form.NTPServer4Name.initialValue }
				/>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
	utcDiff: getRtcUtcTimestampDiffSeconds(state),
	localDiff: getRtcLocalTimestampDiffSeconds(state),
});

export default connect(mapStateToProps)(TimeContainer);
