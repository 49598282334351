import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { handleBasicActions, initialStateReducer } from "src/app/utils/redux";
import { StateReducer } from "src/app/types/redux.types";
import { BackendVersion, CountryCode } from "src/app/types/misc.types";
import { changeLanguage, fetchBackendVersionAsync, setImpersonateUserId } from "src/app/store/features/misc/misc.actions";
import { Nullable } from "src/app/types/util.types";
import { forgetSession, logoutAsync } from "src/app/store/features/user/user.actions";

const reducer = combineReducers({
	backendVersion: createReducer(initialStateReducer as StateReducer<string>)
		.handleAction([ fetchBackendVersionAsync.request, fetchBackendVersionAsync.success, fetchBackendVersionAsync.failure ], handleBasicActions(fetchBackendVersionAsync)),
	impersonateUserId: createReducer(null as Nullable<number>)
		.handleAction([ setImpersonateUserId ], (_, action) => action.payload)
		.handleAction([ logoutAsync.success, forgetSession ], () => null),
	language: createReducer(CountryCode.GB)
		.handleAction([ changeLanguage ], (_, action) => action.payload),
});

export default reducer;
