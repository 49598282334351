import { RecordingOption, SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateNumberField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import BasicRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/BasicRecordingOptionConfiguration.component";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { useContext, useEffect } from "react";
import { MeasurementsUserContext } from "src/app/components/Measurements/MeasurementsUserContent.component";
import EventRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/EventRecordingOptionConfiguration.component";
import Input from "src/app/components/Form/Input.component";
import { Button, Card } from "flowbite-react";
import { isOptionSelected } from "src/app/utils/helpers";
import { isChannelsDiff, mapDisplacementPowerFactorConfig } from "src/app/utils/configuration";
import RelayChannelChooser from "src/app/components/MeasurementsUser/Generic/RelayChannelChooser.component";
import { isEmptyArray, isNotNull } from "src/app/utils/typeguards";
import RelayActionSelect from "src/app/components/MeasurementsUser/Generic/RelayActionSelect.component";
import { EnumOptionsSetting } from "src/app/types/util.types";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";
import classNames from "classnames";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsMeasurementsUser: SettingsMeasurementsUser
	};

export type DisplacementPowerFactorForm = {
	cfgDisplacementPowerFactorRecOpt: number
	cfgDisplacementPowerFactorRecOptOptions: EnumOptionsSetting<RecordingOption>["options"]
	cfgDisplacementPowerFactorEventThresholdMax: string
	cfgDisplacementPowerFactorEventThresholdMaxMin: number
	cfgDisplacementPowerFactorEventThresholdMaxMax: number
	cfgDisplacementPowerFactorChannels: ("cfgRelayDisplacementPowerFactor1RecOpt" | "cfgRelayDisplacementPowerFactor2RecOpt" | "cfgRelayDisplacementPowerFactor3RecOpt" | "cfgRelayDisplacementPowerFactor4RecOpt")[]
	cfgDisplacementPowerFactorActions: number
}

const validator: FormValidator<DisplacementPowerFactorForm> = {
	cfgDisplacementPowerFactorRecOpt: () => null,
	cfgDisplacementPowerFactorRecOptOptions: () => null,
	cfgDisplacementPowerFactorEventThresholdMax: (cfgDisplacementPowerFactorEventThresholdMax, optional, form) => {
		if (!isOptionSelected(form.cfgDisplacementPowerFactorRecOpt.value, RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE, form.cfgDisplacementPowerFactorRecOptOptions.value)) return null;

		return validateNumberField(i18n.t("ENERGY.max"), cfgDisplacementPowerFactorEventThresholdMax, optional, "he", { from: form.cfgDisplacementPowerFactorEventThresholdMaxMin.value, to: form.cfgDisplacementPowerFactorEventThresholdMaxMax.value, decimalPlaces: 2 });
	},
	cfgDisplacementPowerFactorEventThresholdMaxMin: () => null,
	cfgDisplacementPowerFactorEventThresholdMaxMax: () => null,
	cfgDisplacementPowerFactorChannels: () => null,
	cfgDisplacementPowerFactorActions: () => null,
};

const ID = "displacement-power-factor";

function DisplacementPowerFactorCard(props: Props) {

	const { t } = useTranslation();

	const {
		settingsMeasurementsUser: {
			cfgDisplacementPowerFactor,
		},
		isAdmin,
	} = props;

	const {
		form: reducerForm,
		handleChange: reducerHandleChange,
	} = useReducerForm(
		"buffor",
		bufforFormActions,
		() => null,
	);

	const { cfgUserEnable } = useContext(MeasurementsUserContext);

	const _handleSubmit = (values: DisplacementPowerFactorForm) => {
		reducerHandleChange("cfgDisplacementPowerFactorRecOpt", values.cfgDisplacementPowerFactorRecOpt);
		reducerHandleChange("cfgDisplacementPowerFactorEventThresholdMax", values.cfgDisplacementPowerFactorEventThresholdMax);
		reducerHandleChange("cfgDisplacementPowerFactorChannels", values.cfgDisplacementPowerFactorChannels);
		reducerHandleChange("cfgDisplacementPowerFactorActions", values.cfgDisplacementPowerFactorActions);
	};

	const _getInitialState = () => {
		const [ cfgDisplacementPowerFactorChannels, cfgDisplacementPowerFactorActions ] = mapDisplacementPowerFactorConfig(cfgDisplacementPowerFactor);

		return {
			cfgDisplacementPowerFactorRecOpt: createFormField((reducerForm.cfgDisplacementPowerFactorRecOpt.value !== reducerForm.cfgDisplacementPowerFactorRecOpt.initialValue) ? reducerForm.cfgDisplacementPowerFactorRecOpt.value : cfgDisplacementPowerFactor?.cfgDisplacementPowerFactorRecOpt?.value ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgDisplacementPowerFactorRecOptOptions: createFormField(cfgDisplacementPowerFactor?.cfgDisplacementPowerFactorRecOpt?.options ?? []),
			cfgDisplacementPowerFactorEventThresholdMax: createFormField((reducerForm.cfgDisplacementPowerFactorEventThresholdMax.value !== reducerForm.cfgDisplacementPowerFactorEventThresholdMax.initialValue) ? reducerForm.cfgDisplacementPowerFactorEventThresholdMax.value : cfgDisplacementPowerFactor?.cfgDisplacementPowerFactorEventThresholdMax?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
			cfgDisplacementPowerFactorEventThresholdMaxMin: createFormField(cfgDisplacementPowerFactor?.cfgDisplacementPowerFactorEventThresholdMax?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgDisplacementPowerFactorEventThresholdMaxMax: createFormField(cfgDisplacementPowerFactor?.cfgDisplacementPowerFactorEventThresholdMax?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgDisplacementPowerFactorChannels: createFormField(isChannelsDiff(reducerForm.cfgDisplacementPowerFactorChannels) ? reducerForm.cfgDisplacementPowerFactorChannels.value : cfgDisplacementPowerFactorChannels, { disabled: !isAdmin || !cfgUserEnable }),
			cfgDisplacementPowerFactorActions: createFormField((reducerForm.cfgDisplacementPowerFactorActions.value !== reducerForm.cfgDisplacementPowerFactorActions.initialValue) ? reducerForm.cfgDisplacementPowerFactorActions.value : cfgDisplacementPowerFactorActions, { disabled: !isAdmin || !cfgUserEnable }),
		};
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ cfgDisplacementPowerFactor ]);

	useEffect(() => {
		toggleDisable("cfgDisplacementPowerFactorRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgDisplacementPowerFactorEventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgDisplacementPowerFactorChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgDisplacementPowerFactorActions", !cfgUserEnable || !isAdmin);
	}, [ cfgUserEnable ]);

	useEffect(() => {
		toggleDisable("cfgDisplacementPowerFactorRecOpt", false);
		toggleDisable("cfgDisplacementPowerFactorEventThresholdMax", false);
		toggleDisable("cfgDisplacementPowerFactorChannels", false);
		toggleDisable("cfgDisplacementPowerFactorActions", false);

		handleChange("cfgDisplacementPowerFactorRecOpt", reducerForm.cfgDisplacementPowerFactorRecOpt.value);
		handleChange("cfgDisplacementPowerFactorEventThresholdMax", reducerForm.cfgDisplacementPowerFactorEventThresholdMax.value);
		handleChange("cfgDisplacementPowerFactorChannels", reducerForm.cfgDisplacementPowerFactorChannels.value);
		handleChange("cfgDisplacementPowerFactorActions", reducerForm.cfgDisplacementPowerFactorActions.value);

		toggleDisable("cfgDisplacementPowerFactorRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgDisplacementPowerFactorEventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgDisplacementPowerFactorChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgDisplacementPowerFactorActions", !cfgUserEnable || !isAdmin);
	}, [
		reducerForm.cfgDisplacementPowerFactorRecOpt.value,
		reducerForm.cfgDisplacementPowerFactorEventThresholdMax.value,
		reducerForm.cfgDisplacementPowerFactorChannels.value,
		reducerForm.cfgDisplacementPowerFactorActions.value,
	]);

	return (
		<Card className="bg-gray-50">
			<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
				{ t("POWER.cos") }
			</h5>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<BasicRecordingOptionConfiguration
					id={ ID }
					options={ cfgDisplacementPowerFactor?.cfgDisplacementPowerFactorRecOpt?.options ?? [] }
					formItem={ form.cfgDisplacementPowerFactorRecOpt }
					handleChange={ value => handleChange("cfgDisplacementPowerFactorRecOpt", value) }
					reducerFormItem={ reducerForm.cfgDisplacementPowerFactorRecOpt }
				/>
				<div className="flex flex-col gap-3">
					<h5 className="text-base sm:text-xl font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
						{ t("POWER.events") }
					</h5>
					<EventRecordingOptionConfiguration
						id={ ID }
						options={ cfgDisplacementPowerFactor?.cfgDisplacementPowerFactorRecOpt?.options ?? [] }
						formItem={ form.cfgDisplacementPowerFactorRecOpt }
						handleChange={ value => handleChange("cfgDisplacementPowerFactorRecOpt", value) }
						reducerFormItem={ reducerForm.cfgDisplacementPowerFactorRecOpt }
					>
						{
							(isLogEventEnabled) =>
								<div className="flex flex-col gap-2.5">
									<h5 className="text-sm sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
										{ t("POWER.thresholds") }
									</h5>
									<hr className="mx-2"/>
									<div className="flex gap-2.5 flex-1">
										<Input
											className="flex-1"
											formItem={ form.cfgDisplacementPowerFactorEventThresholdMax }
											label={ t("POWER.max") }
											name="cfgDisplacementPowerFactorEventThresholdMax"
											inputProps={ {
												type: "number",
												onChange: (e) => handleChange("cfgDisplacementPowerFactorEventThresholdMax", e.target.value),
												onBlur: () => handleBlur("cfgDisplacementPowerFactorEventThresholdMax"),
												disabled: !isLogEventEnabled,
												sizing: "sm",
											} }
											hasBeenChanged={ reducerForm.cfgDisplacementPowerFactorEventThresholdMax.value !== reducerForm.cfgDisplacementPowerFactorEventThresholdMax.initialValue }
										/>
										<div
											className={
												classNames(
													"flex gap-2",
													"xl:flex-[7_1_0%]",
													"flex-[4_1_0%]",
												)
											}
										>
											<RelayChannelChooser
												options={ [
													(isNotNull(cfgDisplacementPowerFactor) && isNotNull(cfgDisplacementPowerFactor.relayChannelLabel) && isNotNull(cfgDisplacementPowerFactor.relayChannelLabel.ch1)) ? {
														value: "cfgRelayDisplacementPowerFactor1RecOpt",
														label: cfgDisplacementPowerFactor.relayChannelLabel.ch1,
													} : null,
													(isNotNull(cfgDisplacementPowerFactor) && isNotNull(cfgDisplacementPowerFactor.relayChannelLabel) && isNotNull(cfgDisplacementPowerFactor.relayChannelLabel.ch2)) ? {
														value: "cfgRelayDisplacementPowerFactor2RecOpt",
														label: cfgDisplacementPowerFactor.relayChannelLabel.ch2,
													} : null,
													(isNotNull(cfgDisplacementPowerFactor) && isNotNull(cfgDisplacementPowerFactor.relayChannelLabel) && isNotNull(cfgDisplacementPowerFactor.relayChannelLabel.ch3)) ? {
														value: "cfgRelayDisplacementPowerFactor3RecOpt",
														label: cfgDisplacementPowerFactor.relayChannelLabel.ch3,
													} : null,
													(isNotNull(cfgDisplacementPowerFactor) && isNotNull(cfgDisplacementPowerFactor.relayChannelLabel) && isNotNull(cfgDisplacementPowerFactor.relayChannelLabel.ch4)) ? {
														value: "cfgRelayDisplacementPowerFactor4RecOpt",
														label: cfgDisplacementPowerFactor.relayChannelLabel.ch4,
													} : null,
												].filter(isNotNull) }
												disabled={ !isLogEventEnabled }
												formItem={ form.cfgDisplacementPowerFactorChannels }
												reducerFormItem={ reducerForm.cfgDisplacementPowerFactorChannels }
												handleChange={ value => handleChange("cfgDisplacementPowerFactorChannels", value) }
											/>
											<RelayActionSelect
												formItem={ form.cfgDisplacementPowerFactorActions }
												reducerFormItem={ reducerForm.cfgDisplacementPowerFactorActions }
												options={ cfgDisplacementPowerFactor?.cfgRelayDisplacementPowerFactor1Map?.options ?? [] }
												disabled={ !isLogEventEnabled || isEmptyArray(form.cfgDisplacementPowerFactorChannels.value) }
												handleChange={ value => handleChange("cfgDisplacementPowerFactorActions", value) }
											/>
										</div>
									</div>
									<hr className="mx-2"/>
								</div>
						}
					</EventRecordingOptionConfiguration>
				</div>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(DisplacementPowerFactorCard);
