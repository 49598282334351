type Props = {
	label: ReactNode
	value: ReactNode
};

function LabelValue(props: Props) {

	const {
		label,
		value,
	} = props;

	return (
		<div>
			<div className="text-gray-500 text-sm sm:text-base">
				{ label }
			</div>
			<div className="font-semibold text-sm sm:text-base">
				{ value }
			</div>
		</div>
	);
}

export default (LabelValue);
