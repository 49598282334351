import { LoginPayload } from "src/app/types/api/user.types";
import { Form, FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateField, validatePassword } from "src/app/utils/forms";
import i18n from "src/app/translations/i18n";

export type LoginReducerForm = Required<LoginPayload>;

export const loginFormInitialState: Form<LoginReducerForm> = {
	login: createFormField(""),
	password: createFormField(""),
};

export const loginFormValidator: FormValidator<LoginReducerForm> = {
	login: (login, optional) => validateField(i18n.t("AUTH.login input"), login, optional, "she"),
	password: validatePassword,
};
