import { RecordingOption, SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateNumberField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import BasicRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/BasicRecordingOptionConfiguration.component";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import Input from "src/app/components/Form/Input.component";
import { Button, Card } from "flowbite-react";
import EventRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/EventRecordingOptionConfiguration.component";
import { useContext, useEffect } from "react";
import { MeasurementsUserContext } from "src/app/components/Measurements/MeasurementsUserContent.component";
import { SettingsContext } from "src/app/hoc/providers/Settings.provider";
import { isEmptyArray, isNotNull, isNull } from "src/app/utils/typeguards";
import { nominalFrequencyNumberDictionary } from "src/app/utils/constants/dictionaries";
import { getOptionMask, isOptionSelected, round } from "src/app/utils/helpers";
import { EnumOptionsSetting } from "src/app/types/util.types";
import { isChannelsDiff, mapFrequencyConfig } from "src/app/utils/configuration";
import RelativeValuesSelect from "src/app/components/MeasurementsUser/Generic/RelativeValuesSelect.component";
import RelayChannelChooser from "src/app/components/MeasurementsUser/Generic/RelayChannelChooser.component";
import RelayActionSelect from "src/app/components/MeasurementsUser/Generic/RelayActionSelect.component";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";
import classNames from "classnames";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsMeasurementsUser: SettingsMeasurementsUser
	};

export type FrequencyForm = {
	cfgNominalFrequency: number
	cfgFrequencyRecOpt: number
	cfgFrequencyRecOptOptions: EnumOptionsSetting<RecordingOption>["options"]
	cfgFrequencyEventThresholdMin: string
	cfgFrequencyEventThresholdMinMin: number
	cfgFrequencyEventThresholdMinMax: number
	cfgFrequencyEventThresholdMax: string
	cfgFrequencyEventThresholdMaxMin: number
	cfgFrequencyEventThresholdMaxMax: number
	cfgFrequencyChannels: ("cfgRelayFrequencyRecOpt")[]
	cfgFrequencyActions: number
}

const validator: FormValidator<FrequencyForm> = {
	cfgNominalFrequency: () => null,
	cfgFrequencyRecOpt: () => null,
	cfgFrequencyRecOptOptions: () => null,
	cfgFrequencyEventThresholdMin: (cfgFrequencyEventThresholdMin, optional, form) => {
		if (!isOptionSelected(form.cfgFrequencyRecOpt.value, RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE, form.cfgFrequencyRecOptOptions.value)) return null;

		if (isOptionSelected(form.cfgFrequencyRecOpt.value, RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE, form.cfgFrequencyRecOptOptions.value)) {
			const min = round((form.cfgFrequencyEventThresholdMinMin.value / form.cfgNominalFrequency.value) * 100, 2);
			const max = round((form.cfgFrequencyEventThresholdMinMax.value / form.cfgNominalFrequency.value) * 100, 2);
			return validateNumberField(i18n.t("MEASUREMENTS.min"), cfgFrequencyEventThresholdMin, optional, "he", { from: min, to: Math.min(max, +form.cfgFrequencyEventThresholdMax.value), decimalPlaces: 2 });
		} else {
			return validateNumberField(i18n.t("MEASUREMENTS.min"), cfgFrequencyEventThresholdMin, optional, "he", { from: form.cfgFrequencyEventThresholdMinMin.value, to: Math.min(form.cfgFrequencyEventThresholdMinMax.value, +form.cfgFrequencyEventThresholdMax.value), decimalPlaces: 2 });
		}
	},
	cfgFrequencyEventThresholdMinMin: () => null,
	cfgFrequencyEventThresholdMinMax: () => null,
	cfgFrequencyEventThresholdMax: (cfgFrequencyEventThresholdMax, optional, form) => {
		if (!isOptionSelected(form.cfgFrequencyRecOpt.value, RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE, form.cfgFrequencyRecOptOptions.value)) return null;

		if (isOptionSelected(form.cfgFrequencyRecOpt.value, RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE, form.cfgFrequencyRecOptOptions.value)) {
			const min = round((form.cfgFrequencyEventThresholdMaxMin.value / form.cfgNominalFrequency.value) * 100, 2);
			const max = round((form.cfgFrequencyEventThresholdMaxMax.value / form.cfgNominalFrequency.value) * 100, 2);
			return validateNumberField(i18n.t("MEASUREMENTS.max"), cfgFrequencyEventThresholdMax, optional, "he", { from: Math.max(min, +form.cfgFrequencyEventThresholdMin.value), to: max, decimalPlaces: 2 });
		} else {
			return validateNumberField(i18n.t("MEASUREMENTS.max"), cfgFrequencyEventThresholdMax, optional, "he", { from: Math.max(form.cfgFrequencyEventThresholdMaxMin.value, +form.cfgFrequencyEventThresholdMin.value), to: form.cfgFrequencyEventThresholdMaxMax.value, decimalPlaces: 2 });
		}
	},
	cfgFrequencyEventThresholdMaxMin: () => null,
	cfgFrequencyEventThresholdMaxMax: () => null,
	cfgFrequencyChannels: () => null,
	cfgFrequencyActions: () => null,
};

const ID = "frequency";

function FrequencyCard(props: Props) {

	const { t } = useTranslation();

	const {
		settingsMeasurementsUser: {
			cfgFrequency,
		},
		isAdmin,
	} = props;

	const {
		form: reducerForm,
		handleChange: reducerHandleChange,
	} = useReducerForm(
		"buffor",
		bufforFormActions,
		() => null,
	);

	const { recording } = useContext(SettingsContext);

	const { cfgUserEnable } = useContext(MeasurementsUserContext);

	const _handleSubmit = (values: FrequencyForm) => {
		reducerHandleChange("cfgFrequencyRecOpt", values.cfgFrequencyRecOpt);
		reducerHandleChange("cfgFrequencyEventThresholdMin", values.cfgFrequencyEventThresholdMin);
		reducerHandleChange("cfgFrequencyEventThresholdMax", values.cfgFrequencyEventThresholdMax);
		reducerHandleChange("cfgFrequencyChannels", values.cfgFrequencyChannels);
		reducerHandleChange("cfgFrequencyActions", values.cfgFrequencyActions);
	};

	const _getInitialState = () => {
		const nominalFrequency = recording?.recording?.cfgNominalFrequency?.enum?.find(enumOption => recording?.recording?.cfgNominalFrequency?.value === enumOption.value);
		const [ cfgFrequencyChannels, cfgFrequencyActions ] = mapFrequencyConfig(cfgFrequency);
		return {
			cfgNominalFrequency: createFormField(isNull(nominalFrequency) ? 50 : nominalFrequencyNumberDictionary()[ nominalFrequency.text ]),
			cfgFrequencyRecOpt: createFormField((reducerForm.cfgFrequencyRecOpt.value !== reducerForm.cfgFrequencyRecOpt.initialValue) ? reducerForm.cfgFrequencyRecOpt.value : cfgFrequency?.cfgFrequencyRecOpt?.value ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgFrequencyRecOptOptions: createFormField(cfgFrequency?.cfgFrequencyRecOpt?.options ?? []),
			cfgFrequencyEventThresholdMin: createFormField((reducerForm.cfgFrequencyEventThresholdMin.value !== reducerForm.cfgFrequencyEventThresholdMin.initialValue) ? reducerForm.cfgFrequencyEventThresholdMin.value : cfgFrequency?.cfgFrequencyEventThresholdMin?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
			cfgFrequencyEventThresholdMinMin: createFormField(cfgFrequency?.cfgFrequencyEventThresholdMin?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgFrequencyEventThresholdMinMax: createFormField(cfgFrequency?.cfgFrequencyEventThresholdMin?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgFrequencyEventThresholdMax: createFormField((reducerForm.cfgFrequencyEventThresholdMax.value !== reducerForm.cfgFrequencyEventThresholdMax.initialValue) ? reducerForm.cfgFrequencyEventThresholdMax.value : cfgFrequency?.cfgFrequencyEventThresholdMax?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
			cfgFrequencyEventThresholdMaxMin: createFormField(cfgFrequency?.cfgFrequencyEventThresholdMax?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgFrequencyEventThresholdMaxMax: createFormField(cfgFrequency?.cfgFrequencyEventThresholdMax?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgFrequencyChannels: createFormField(isChannelsDiff(reducerForm.cfgFrequencyChannels) ? reducerForm.cfgFrequencyChannels.value : cfgFrequencyChannels, { disabled: !isAdmin || !cfgUserEnable }),
			cfgFrequencyActions: createFormField((reducerForm.cfgFrequencyActions.value !== reducerForm.cfgFrequencyActions.initialValue) ? reducerForm.cfgFrequencyActions.value : cfgFrequencyActions, { disabled: !isAdmin || !cfgUserEnable }),
		};
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ cfgFrequency ]);

	useEffect(() => {
		toggleDisable("cfgFrequencyRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgFrequencyEventThresholdMin", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgFrequencyEventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgFrequencyChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgFrequencyActions", !cfgUserEnable || !isAdmin);
	}, [ cfgUserEnable ]);

	useEffect(() => {
		toggleDisable("cfgFrequencyRecOpt", false);
		toggleDisable("cfgFrequencyEventThresholdMin", false);
		toggleDisable("cfgFrequencyEventThresholdMax", false);
		toggleDisable("cfgFrequencyChannels", false);
		toggleDisable("cfgFrequencyActions", false);

		handleChange("cfgFrequencyRecOpt", reducerForm.cfgFrequencyRecOpt.value);
		handleChange("cfgFrequencyEventThresholdMin", reducerForm.cfgFrequencyEventThresholdMin.value);
		handleChange("cfgFrequencyEventThresholdMax", reducerForm.cfgFrequencyEventThresholdMax.value);
		handleChange("cfgFrequencyChannels", reducerForm.cfgFrequencyChannels.value);
		handleChange("cfgFrequencyActions", reducerForm.cfgFrequencyActions.value);

		toggleDisable("cfgFrequencyRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgFrequencyEventThresholdMin", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgFrequencyEventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgFrequencyChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgFrequencyActions", !cfgUserEnable || !isAdmin);
	}, [
		reducerForm.cfgFrequencyRecOpt.value,
		reducerForm.cfgFrequencyEventThresholdMin.value,
		reducerForm.cfgFrequencyEventThresholdMax.value,
		reducerForm.cfgFrequencyChannels.value,
		reducerForm.cfgFrequencyActions.value,
	]);

	const relativeValuesMask = getOptionMask(RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE, cfgFrequency?.cfgFrequencyRecOpt?.options ?? []) ?? 0;

	return (
		<Card className="bg-gray-50">
			<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
				{ t("UTILS.frequency") }
			</h5>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<BasicRecordingOptionConfiguration
					id={ ID }
					options={ cfgFrequency?.cfgFrequencyRecOpt?.options ?? [] }
					formItem={ form.cfgFrequencyRecOpt }
					handleChange={ value => handleChange("cfgFrequencyRecOpt", value) }
					reducerFormItem={ reducerForm.cfgFrequencyRecOpt } // fixme
				/>
				<div className="flex flex-col gap-3">
					<h5 className="text-base sm:text-xl font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
						{ t("MEASUREMENTS.events") }
					</h5>
					<EventRecordingOptionConfiguration
						id={ ID }
						options={ cfgFrequency?.cfgFrequencyRecOpt?.options ?? [] }
						formItem={ form.cfgFrequencyRecOpt }
						handleChange={ value => handleChange("cfgFrequencyRecOpt", value) }
						reducerFormItem={ reducerForm.cfgFrequencyRecOpt }
					>
						{
							(isLogEventEnabled) =>
								<div className="flex flex-col gap-2.5">
									<h5 className="text-sm sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
										{ t("MEASUREMENTS.thresholds") }
									</h5>
									<div className="flex flex-col gap-2.5 flex-1">
										<div className="flex flex-col gap-2.5">
											<hr className="mx-2"/>
											<div className="flex gap-4">
												<div className="flex-[1_1_0%] flex gap-2.5">
													<div className="flex-[2_1_0%] flex flex-col gap-2.5">
														<Input
															className="flex-1"
															formItem={ form.cfgFrequencyEventThresholdMin }
															label={ t("MEASUREMENTS.min") }
															name="cfgFrequencyEventThresholdMin"
															inputProps={ {
																type: "number",
																onChange: (e) => handleChange("cfgFrequencyEventThresholdMin", e.target.value),
																onBlur: () => {
																	handleBlur("cfgFrequencyEventThresholdMin");
																	handleBlur("cfgFrequencyEventThresholdMax");
																},
																disabled: !isLogEventEnabled,
																sizing: "sm",
															} }
															hasBeenChanged={ reducerForm.cfgFrequencyEventThresholdMin.value !== reducerForm.cfgFrequencyEventThresholdMin.initialValue }
														/>
														<Input
															className="flex-1"
															formItem={ form.cfgFrequencyEventThresholdMax }
															label={ t("MEASUREMENTS.max") }
															name="cfgFrequencyEventThresholdMax"
															inputProps={ {
																type: "number",
																onChange: (e) => handleChange("cfgFrequencyEventThresholdMax", e.target.value),
																onBlur: () => {
																	handleBlur("cfgFrequencyEventThresholdMin");
																	handleBlur("cfgFrequencyEventThresholdMax");
																},
																disabled: !isLogEventEnabled,
																sizing: "sm",
															} }
															hasBeenChanged={ reducerForm.cfgFrequencyEventThresholdMax.value !== reducerForm.cfgFrequencyEventThresholdMax.initialValue }
														/>
													</div>
													<div className="flex-[1_1_0%] flex items-center">
														<RelativeValuesSelect
															className="flex flex-1"
															options={ cfgFrequency?.cfgFrequencyRecOpt?.options ?? [] }
															formItem={ form.cfgFrequencyRecOpt }
															onChange={ value => {
																const newRelative = isOptionSelected(value, RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE, form.cfgFrequencyRecOptOptions.value);
																const oldRelative = isOptionSelected(form.cfgFrequencyRecOpt.value, RecordingOption.SET_REC_OPT_EVT_TH_RELATIVE, form.cfgFrequencyRecOptOptions.value);
																handleChange("cfgFrequencyRecOpt", value);

																if (newRelative !== oldRelative) {
																	if (newRelative) {
																		handleChange("cfgFrequencyEventThresholdMin", round((+form.cfgFrequencyEventThresholdMin.value / form.cfgNominalFrequency.value) * 100, 2));
																		handleChange("cfgFrequencyEventThresholdMax", round((+form.cfgFrequencyEventThresholdMax.value / form.cfgNominalFrequency.value) * 100, 2));
																	} else {
																		handleChange("cfgFrequencyEventThresholdMin", round((+form.cfgFrequencyEventThresholdMin.value / 100) * form.cfgNominalFrequency.value, 2));
																		handleChange("cfgFrequencyEventThresholdMax", round((+form.cfgFrequencyEventThresholdMax.value / 100) * form.cfgNominalFrequency.value, 2));
																	}

																	handleBlur("cfgFrequencyEventThresholdMin");
																	handleBlur("cfgFrequencyEventThresholdMax");
																}
															} }
															unit="Hz"
															unitPercent="% fn"
															inputHeight={ 34 }
															isDisabled={ !isLogEventEnabled }
															hasBeenChanged={ (reducerForm.cfgFrequencyRecOpt.value & relativeValuesMask) !== (reducerForm.cfgFrequencyRecOpt.initialValue & relativeValuesMask) }
														/>
													</div>
												</div>
												<div className="my-2 h-100 border-r border-gray-200"/>
												<div
													className={
														classNames(
															"flex flex-col justify-center gap-2.5",
															"xl:flex-[3_1_0%]",
															"flex-[2_1_0%]"
														)
													}
												>
													<div className="flex gap-2.5">
														<RelayChannelChooser
															options={ [
																(isNotNull(cfgFrequency) && isNotNull(cfgFrequency.relayChannelLabel) && isNotNull(cfgFrequency.relayChannelLabel.ch1)) ? {
																	value: "cfgRelayFrequencyRecOpt",
																	label: cfgFrequency.relayChannelLabel.ch1,
																} : null,
															].filter(isNotNull) }
															disabled={ !isLogEventEnabled }
															formItem={ form.cfgFrequencyChannels }
															reducerFormItem={ reducerForm.cfgFrequencyChannels }
															handleChange={ value => handleChange("cfgFrequencyChannels", value) }
														/>
														<RelayActionSelect
															formItem={ form.cfgFrequencyActions }
															reducerFormItem={ reducerForm.cfgFrequencyActions }
															options={ cfgFrequency?.cfgRelayFrequencyMap?.options ?? [] }
															disabled={ !isLogEventEnabled || isEmptyArray(form.cfgFrequencyChannels.value) }
															handleChange={ value => handleChange("cfgFrequencyActions", value) }
														/>
													</div>
												</div>
											</div>
											<hr className="mx-2"/>
										</div>
									</div>
								</div>
						}
					</EventRecordingOptionConfiguration>
				</div>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(FrequencyCard);
