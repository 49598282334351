import { RecordingOption, SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { createFormField, validateNumberField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import { FormValidator } from "src/app/types/ui/form.types";
import BasicRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/BasicRecordingOptionConfiguration.component";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { useContext, useEffect } from "react";
import { MeasurementsUserContext } from "src/app/components/Measurements/MeasurementsUserContent.component";
import EventRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/EventRecordingOptionConfiguration.component";
import Input from "src/app/components/Form/Input.component";
import { Button, Card } from "flowbite-react";
import { EnumOptionsSetting } from "src/app/types/util.types";
import { isOptionSelected } from "src/app/utils/helpers";
import { isChannelsDiff, mapReactivePowerConfig } from "src/app/utils/configuration";
import RelayChannelChooser from "src/app/components/MeasurementsUser/Generic/RelayChannelChooser.component";
import RelayActionSelect from "src/app/components/MeasurementsUser/Generic/RelayActionSelect.component";
import { isEmptyArray, isNotNull } from "src/app/utils/typeguards";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";
import classNames from "classnames";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		title: string
		settingsMeasurementsUser: SettingsMeasurementsUser
	};

export type ReactivePowerForm = {
	cfgReactivePowerRecOpt: number
	cfgReactivePowerRecOptOptions: EnumOptionsSetting<RecordingOption>["options"]
	cfgReactivePowerEventThresholdMax: string
	cfgReactivePowerEventThresholdMaxMin: number
	cfgReactivePowerEventThresholdMaxMax: number
	cfgReactivePowerChannels: ("cfgRelayReactivePower1RecOpt" | "cfgRelayReactivePower2RecOpt" | "cfgRelayReactivePower3RecOpt" | "cfgRelayReactivePower4RecOpt")[]
	cfgReactivePowerActions: number
}

const validator: FormValidator<ReactivePowerForm> = {
	cfgReactivePowerRecOpt: () => null,
	cfgReactivePowerRecOptOptions: () => null,
	cfgReactivePowerEventThresholdMax: (cfgReactivePowerEventThresholdMax, optional, form) => {
		if (!isOptionSelected(form.cfgReactivePowerRecOpt.value, RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE, form.cfgReactivePowerRecOptOptions.value)) return null;

		return validateNumberField(i18n.t("ENERGY.max"), cfgReactivePowerEventThresholdMax, optional, "he", { from: form.cfgReactivePowerEventThresholdMaxMin.value, to: form.cfgReactivePowerEventThresholdMaxMax.value, decimalPlaces: 2 });
	},
	cfgReactivePowerEventThresholdMaxMin: () => null,
	cfgReactivePowerEventThresholdMaxMax: () => null,
	cfgReactivePowerChannels: () => null,
	cfgReactivePowerActions: () => null,
};

const ID = "reactive-power";

function ReactivePowerCard(props: Props) {

	const { t } = useTranslation();

	const {
		title,
		settingsMeasurementsUser: {
			cfgReactivePower,
		},
		isAdmin,
	} = props;

	const {
		form: reducerForm,
		handleChange: reducerHandleChange,
	} = useReducerForm(
		"buffor",
		bufforFormActions,
		() => null,
	);

	const { cfgUserEnable } = useContext(MeasurementsUserContext);

	const _handleSubmit = (values: ReactivePowerForm) => {
		reducerHandleChange("cfgReactivePowerRecOpt", values.cfgReactivePowerRecOpt);
		reducerHandleChange("cfgReactivePowerEventThresholdMax", values.cfgReactivePowerEventThresholdMax);
		reducerHandleChange("cfgReactivePowerChannels", values.cfgReactivePowerChannels);
		reducerHandleChange("cfgReactivePowerActions", values.cfgReactivePowerActions);
	};

	const _getInitialState = () => {
		const [ cfgReactivePowerChannels, cfgReactivePowerActions ] = mapReactivePowerConfig(cfgReactivePower);
		return {
			cfgReactivePowerRecOpt: createFormField((reducerForm.cfgReactivePowerRecOpt.value !== reducerForm.cfgReactivePowerRecOpt.initialValue) ? reducerForm.cfgReactivePowerRecOpt.value : cfgReactivePower?.cfgReactivePowerRecOpt?.value ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgReactivePowerRecOptOptions: createFormField(cfgReactivePower?.cfgReactivePowerRecOpt?.options ?? []),
			cfgReactivePowerEventThresholdMax: createFormField((reducerForm.cfgReactivePowerEventThresholdMax.value !== reducerForm.cfgReactivePowerEventThresholdMax.initialValue) ? reducerForm.cfgReactivePowerEventThresholdMax.value : cfgReactivePower?.cfgReactivePowerEventThresholdMax?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
			cfgReactivePowerEventThresholdMaxMin: createFormField(cfgReactivePower?.cfgReactivePowerEventThresholdMax?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgReactivePowerEventThresholdMaxMax: createFormField(cfgReactivePower?.cfgReactivePowerEventThresholdMax?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgReactivePowerChannels: createFormField(isChannelsDiff(reducerForm.cfgReactivePowerChannels) ? reducerForm.cfgReactivePowerChannels.value : cfgReactivePowerChannels, { disabled: !isAdmin || !cfgUserEnable }),
			cfgReactivePowerActions: createFormField((reducerForm.cfgReactivePowerActions.value !== reducerForm.cfgReactivePowerActions.initialValue) ? reducerForm.cfgReactivePowerActions.value : cfgReactivePowerActions, { disabled: !isAdmin || !cfgUserEnable }),
		};
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ cfgReactivePower ]);

	useEffect(() => {
		toggleDisable("cfgReactivePowerRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgReactivePowerEventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgReactivePowerChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgReactivePowerActions", !cfgUserEnable || !isAdmin);
	}, [ cfgUserEnable ]);

	useEffect(() => {
		toggleDisable("cfgReactivePowerRecOpt", false);
		toggleDisable("cfgReactivePowerEventThresholdMax", false);
		toggleDisable("cfgReactivePowerChannels", false);
		toggleDisable("cfgReactivePowerActions", false);

		handleChange("cfgReactivePowerRecOpt", reducerForm.cfgReactivePowerRecOpt.value);
		handleChange("cfgReactivePowerEventThresholdMax", reducerForm.cfgReactivePowerEventThresholdMax.value);
		handleChange("cfgReactivePowerChannels", reducerForm.cfgReactivePowerChannels.value);
		handleChange("cfgReactivePowerActions", reducerForm.cfgReactivePowerActions.value);

		toggleDisable("cfgReactivePowerRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgReactivePowerEventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgReactivePowerChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgReactivePowerActions", !cfgUserEnable || !isAdmin);
	}, [
		reducerForm.cfgReactivePowerRecOpt.value,
		reducerForm.cfgReactivePowerEventThresholdMax.value,
		reducerForm.cfgReactivePowerChannels.value,
		reducerForm.cfgReactivePowerActions.value,
	]);

	return (
		<Card className="bg-gray-50">
			<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
				{ title }
			</h5>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<BasicRecordingOptionConfiguration
					id={ ID }
					options={ cfgReactivePower?.cfgReactivePowerRecOpt?.options ?? [] }
					formItem={ form.cfgReactivePowerRecOpt }
					handleChange={ value => handleChange("cfgReactivePowerRecOpt", value) }
					reducerFormItem={ reducerForm.cfgReactivePowerRecOpt }
				/>
				<div className="flex flex-col gap-3">
					<h5 className="text-base sm:text-xl font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
						{ t("POWER.events") }
					</h5>
					<EventRecordingOptionConfiguration
						id={ ID }
						options={ cfgReactivePower?.cfgReactivePowerRecOpt?.options ?? [] }
						formItem={ form.cfgReactivePowerRecOpt }
						handleChange={ value => handleChange("cfgReactivePowerRecOpt", value) }
						reducerFormItem={ reducerForm.cfgReactivePowerRecOpt }
					>
						{
							(isLogEventEnabled) =>
								<div className="flex flex-col gap-2.5">
									<h5 className="text-sm sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
										{ t("POWER.thresholds") }
									</h5>
									<hr className="mx-2"/>
									<div className="flex gap-2.5 flex-1">
										<Input
											className="flex-1"
											formItem={ form.cfgReactivePowerEventThresholdMax }
											label={ t("POWER.max") }
											name="cfgReactivePowerEventThresholdMax"
											inputProps={ {
												type: "number",
												onChange: (e) => handleChange("cfgReactivePowerEventThresholdMax", e.target.value),
												onBlur: () => handleBlur("cfgReactivePowerEventThresholdMax"),
												disabled: !isLogEventEnabled,
												sizing: "sm",
												className: "[&>span]:!min-w-[40px] [&>span]:justify-center",
											} }
											addonRight={ cfgReactivePower?.cfgReactivePowerEventThresholdMax?.unit ?? "-" }
											hasBeenChanged={ reducerForm.cfgReactivePowerEventThresholdMax.value !== reducerForm.cfgReactivePowerEventThresholdMax.initialValue }
										/>
										<div
											className={
												classNames(
													"flex gap-2",
													"xl:flex-[7_1_0%]",
													"flex-[4_1_0%]",
												)
											}
										>
											<RelayChannelChooser
												options={ [
													(isNotNull(cfgReactivePower) && isNotNull(cfgReactivePower.relayChannelLabel) && isNotNull(cfgReactivePower.relayChannelLabel.ch1)) ? {
														value: "cfgRelayReactivePower1RecOpt",
														label: cfgReactivePower.relayChannelLabel.ch1,
													} : null,
													(isNotNull(cfgReactivePower) && isNotNull(cfgReactivePower.relayChannelLabel) && isNotNull(cfgReactivePower.relayChannelLabel.ch2)) ? {
														value: "cfgRelayReactivePower2RecOpt",
														label: cfgReactivePower.relayChannelLabel.ch2,
													} : null,
													(isNotNull(cfgReactivePower) && isNotNull(cfgReactivePower.relayChannelLabel) && isNotNull(cfgReactivePower.relayChannelLabel.ch3)) ? {
														value: "cfgRelayReactivePower3RecOpt",
														label: cfgReactivePower.relayChannelLabel.ch3,
													} : null,
													(isNotNull(cfgReactivePower) && isNotNull(cfgReactivePower.relayChannelLabel) && isNotNull(cfgReactivePower.relayChannelLabel.ch4)) ? {
														value: "cfgRelayReactivePower4RecOpt",
														label: cfgReactivePower.relayChannelLabel.ch4,
													} : null,
												].filter(isNotNull) }
												disabled={ !isLogEventEnabled }
												formItem={ form.cfgReactivePowerChannels }
												reducerFormItem={ reducerForm.cfgReactivePowerChannels }
												handleChange={ value => handleChange("cfgReactivePowerChannels", value) }
											/>
											<RelayActionSelect
												formItem={ form.cfgReactivePowerActions }
												reducerFormItem={ reducerForm.cfgReactivePowerActions }
												options={ cfgReactivePower?.cfgRelayReactivePower1Map?.options ?? [] }
												disabled={ !isLogEventEnabled || isEmptyArray(form.cfgReactivePowerChannels.value) }
												handleChange={ value => handleChange("cfgReactivePowerActions", value) }
											/>
										</div>
									</div>
									<hr className="mx-2"/>
								</div>
						}
					</EventRecordingOptionConfiguration>
				</div>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(ReactivePowerCard);
