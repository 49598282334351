import { FirmwareInfo as FirmwareInfoType } from "src/app/types/api/firmware.types";
import UploadFirmware from "src/app/components/Firmware/UploadFirmware.component";
import FirmwareInfo from "src/app/components/Firmware/FirmwareInfo.component";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { HiCheck } from "react-icons/hi";
import classNames from "classnames";
import React, { useEffect } from "react";
import { Card } from "flowbite-react";
import { GridLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { FirmwareUpgradeStep, UploadFirmwareForm } from "src/app/utils/constants/firmwareUpgrade.form";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { firmwareUpgradeFormActions } from "src/app/store/features/form/form.actions";

type Props = {
	firmwareInfo: FirmwareInfoType
	uploadFirmware: (file: File) => void
	removeFirmware: () => void
	upgradeFirmware: () => void
};

function FirmwareContainer(props: Props) {

	const { t } = useTranslation();

	const {
		firmwareInfo,
		uploadFirmware,
		removeFirmware,
		upgradeFirmware,
	} = props;

	const _handleSubmit = (values: UploadFirmwareForm) => {
		if (isNull(values.file)) return;

		uploadFirmware(values.file);
		handleChange("file", null);
	};

	const {
		form,
		handleChange,
		resetForm,
	} = useReducerForm(
		"uploadFirmware",
		firmwareUpgradeFormActions,
		_handleSubmit,
	);

	useEffect(() => {
		return () => {
			resetForm();
		};
	}, []);

	useEffect(() => {
		if (firmwareInfo.firmware.fileSize.value === 0) {
			handleChange("step", FirmwareUpgradeStep.UPLOAD);
			handleChange("file", null);
		} else {
			handleChange("step", FirmwareUpgradeStep.INFO);
		}
	}, [ firmwareInfo.firmware.fileSize.value ]);

	return (
		<div className="grid grid-cols-12">
			<div
				className={
					classNames(
						"2xl:col-span-3",
						"col-span-2"
					)
				}
			/>
			<div
				className={
					classNames(
						"2xl:col-span-6",
						"col-span-8"
					)
				}
			>
				<div className="flex justify-center">
					<ol className="items-center space-y-4 sm:flex sm:space-x-8 sm:space-y-0 rtl:space-x-reverse mb-4">
						<li
							className={
								classNames(
									"flex items-center space-x-2.5 rtl:space-x-reverse",
									{ "text-blue-600 dark:text-blue-500": form.step.value === FirmwareUpgradeStep.UPLOAD },
									{ "text-green-600 dark:text-green-500": form.step.value !== FirmwareUpgradeStep.UPLOAD },
								)
							}
						>
        				<span
							className={
								classNames(
									"flex items-center justify-center w-8 h-8 border rounded-full shrink-0",
									{ "border-blue-600 dark:border-blue-500": form.step.value === FirmwareUpgradeStep.UPLOAD },
									{ "border-green-600 dark:border-green-500": form.step.value !== FirmwareUpgradeStep.UPLOAD },
								)
							}
						>
        				    { form.step.value === FirmwareUpgradeStep.UPLOAD ? "1" : <HiCheck/> }
        				</span>
						<span>
            				<h3 className="font-medium leading-tight">{ t("FIRMWARE.upload firmware file") }</h3>
								{/*<p className="text-sm">Step details here</p>*/ }
        				</span>
						</li>
						<li
							className={
								classNames(
									"flex items-center space-x-2.5 rtl:space-x-reverse",
									{ "text-gray-600 dark:text-gray-500": form.step.value === FirmwareUpgradeStep.UPLOAD },
									{ "text-blue-600 dark:text-blue-500": form.step.value === FirmwareUpgradeStep.INFO },
									{ "text-green-600 dark:text-green-500": form.step.value === FirmwareUpgradeStep.UPGRADE },
								)
							}
						>
        				<span
							className={
								classNames(
									"flex items-center justify-center w-8 h-8 border rounded-full shrink-0",
									{ "border-gray-600 dark:border-gray-500": form.step.value === FirmwareUpgradeStep.UPLOAD },
									{ "border-blue-600 dark:border-blue-500": form.step.value === FirmwareUpgradeStep.INFO },
									{ "border-green-600 dark:border-green-500": form.step.value === FirmwareUpgradeStep.UPGRADE },
								)
							}
						>
        				    { form.step.value !== FirmwareUpgradeStep.UPGRADE ? "2" : <HiCheck/> }
        				</span>
							<span>
            				<h3 className="font-medium leading-tight">{ t("FIRMWARE.verify firmware") }</h3>
								{/*<p className="text-sm">Step details here</p>*/ }
        				</span>
						</li>
						<li
							className={
								classNames(
									"flex items-center space-x-2.5 rtl:space-x-reverse",
									{ "text-gray-600 dark:text-gray-500": form.step.value !== FirmwareUpgradeStep.UPGRADE },
									{ "text-blue-600 dark:text-blue-500": form.step.value === FirmwareUpgradeStep.UPGRADE },
								)
							}
						>
        				<span
							className={
								classNames(
									"flex items-center justify-center w-8 h-8 border rounded-full shrink-0",
									{ "border-gray-600 dark:border-gray-500": form.step.value !== FirmwareUpgradeStep.UPGRADE },
									{ "border-blue-600 dark:border-blue-500": form.step.value === FirmwareUpgradeStep.UPGRADE },
								)
							}
						>
        				    3
        				</span>
							<span>
            				<h3 className="font-medium leading-tight">{ t("FIRMWARE.upgrade") }</h3>
								{/*<p className="text-sm">Step details here</p>*/ }
        				</span>
						</li>
					</ol>
				</div>
				{
					form.step.value === FirmwareUpgradeStep.UPLOAD &&
                    <UploadFirmware
                        uploadFirmware={ () => isNotNull(form.file.value) && uploadFirmware(form.file.value) }
                        form={ form }
                        handleChange={ handleChange }
                    />
				}
				{/*{
					(firmwareInfo.firmware.fileSize.value === 0 && isNotNull(form.file.value)) &&
                    <FirmwareFileInfo
                        file={ form.file.value }
                        onUploadFile={ handleSubmit }
                        onCancelUpload={ () => handleChange("file", null) }
                    />
				}*/ }
				{
					form.step.value === FirmwareUpgradeStep.INFO &&
                    <FirmwareInfo
                        firmware={ firmwareInfo }
                        removeFirmware={ removeFirmware }
                        upgradeFirmware={ () => {
							upgradeFirmware();
						} }
                    />
				}
				{
					form.step.value === FirmwareUpgradeStep.UPGRADE &&
                    <Card className="[&>div]:p-3 sm:[&>div]:p-6 [&>div]:gap-1 sm:[&>div]:gap-4">
                        <div className="flex flex-col justify-between gap-2">
                            <h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
								{ t("FIRMWARE.upgrade") }
                            </h5>
                            <p>{ t("FIRMWARE.upgrade instruction") }</p>
                        </div>
                        <div className="flex gap-4 items-center justify-center">
                            <GridLoader size={ 14 } color="#0093DD"/>
                            <div>
                                <p className="font-semibold">{ t("FIRMWARE.upgrade started") }</p>
                                <p className="font-semibold">{ t("FIRMWARE.wait for meter") }</p>
								{/*<p className="font-semibold">Firmware upgrade process has started</p>*/ }
                            </div>
                        </div>
                    </Card>
				}
			</div>
			<div
				className={
					classNames(
						"2xl:col-span-3",
						"col-span-2"
					)
				}
			/>
		</div>
	);
}

export default (FirmwareContainer);
