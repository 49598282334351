// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.1
//   protoc               v5.27.0
// source: src/app/proto/event_rms12.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "event_rms12";

export interface channel {
  sample: number[];
  value: number[];
}

export interface wave {
  U1N?: channel | undefined;
  U2N?: channel | undefined;
  U3N?: channel | undefined;
  U1PE?: channel | undefined;
  U2PE?: channel | undefined;
  U3PE?: channel | undefined;
  UNPE?: channel | undefined;
  U12?: channel | undefined;
  U23?: channel | undefined;
  U31?: channel | undefined;
  I1?: channel | undefined;
  I2?: channel | undefined;
  I3?: channel | undefined;
  I4?: channel | undefined;
  I5?: channel | undefined;
  INS?: channel | undefined;
}

export interface rms12 {
  left?: wave | undefined;
  right?: wave | undefined;
}

function createBasechannel(): channel {
  return { sample: [], value: [] };
}

export const channel = {
  encode(message: channel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.sample) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(18).fork();
    for (const v of message.value) {
      writer.float(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): channel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasechannel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.sample.push(reader.uint32());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.sample.push(reader.uint32());
            }

            continue;
          }

          break;
        case 2:
          if (tag === 21) {
            message.value.push(reader.float());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.value.push(reader.float());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): channel {
    return {
      sample: globalThis.Array.isArray(object?.sample) ? object.sample.map((e: any) => globalThis.Number(e)) : [],
      value: globalThis.Array.isArray(object?.value) ? object.value.map((e: any) => globalThis.Number(e)) : [],
    };
  },

  toJSON(message: channel): unknown {
    const obj: any = {};
    if (message.sample?.length) {
      obj.sample = message.sample.map((e) => Math.round(e));
    }
    if (message.value?.length) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<channel>, I>>(base?: I): channel {
    return channel.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<channel>, I>>(object: I): channel {
    const message = createBasechannel();
    message.sample = object.sample?.map((e) => e) || [];
    message.value = object.value?.map((e) => e) || [];
    return message;
  },
};

function createBasewave(): wave {
  return {
    U1N: undefined,
    U2N: undefined,
    U3N: undefined,
    U1PE: undefined,
    U2PE: undefined,
    U3PE: undefined,
    UNPE: undefined,
    U12: undefined,
    U23: undefined,
    U31: undefined,
    I1: undefined,
    I2: undefined,
    I3: undefined,
    I4: undefined,
    I5: undefined,
    INS: undefined,
  };
}

export const wave = {
  encode(message: wave, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.U1N !== undefined) {
      channel.encode(message.U1N, writer.uint32(10).fork()).ldelim();
    }
    if (message.U2N !== undefined) {
      channel.encode(message.U2N, writer.uint32(18).fork()).ldelim();
    }
    if (message.U3N !== undefined) {
      channel.encode(message.U3N, writer.uint32(26).fork()).ldelim();
    }
    if (message.U1PE !== undefined) {
      channel.encode(message.U1PE, writer.uint32(34).fork()).ldelim();
    }
    if (message.U2PE !== undefined) {
      channel.encode(message.U2PE, writer.uint32(42).fork()).ldelim();
    }
    if (message.U3PE !== undefined) {
      channel.encode(message.U3PE, writer.uint32(50).fork()).ldelim();
    }
    if (message.UNPE !== undefined) {
      channel.encode(message.UNPE, writer.uint32(58).fork()).ldelim();
    }
    if (message.U12 !== undefined) {
      channel.encode(message.U12, writer.uint32(66).fork()).ldelim();
    }
    if (message.U23 !== undefined) {
      channel.encode(message.U23, writer.uint32(74).fork()).ldelim();
    }
    if (message.U31 !== undefined) {
      channel.encode(message.U31, writer.uint32(82).fork()).ldelim();
    }
    if (message.I1 !== undefined) {
      channel.encode(message.I1, writer.uint32(90).fork()).ldelim();
    }
    if (message.I2 !== undefined) {
      channel.encode(message.I2, writer.uint32(98).fork()).ldelim();
    }
    if (message.I3 !== undefined) {
      channel.encode(message.I3, writer.uint32(106).fork()).ldelim();
    }
    if (message.I4 !== undefined) {
      channel.encode(message.I4, writer.uint32(114).fork()).ldelim();
    }
    if (message.I5 !== undefined) {
      channel.encode(message.I5, writer.uint32(122).fork()).ldelim();
    }
    if (message.INS !== undefined) {
      channel.encode(message.INS, writer.uint32(130).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): wave {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasewave();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.U1N = channel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.U2N = channel.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.U3N = channel.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.U1PE = channel.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.U2PE = channel.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.U3PE = channel.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.UNPE = channel.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.U12 = channel.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.U23 = channel.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.U31 = channel.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.I1 = channel.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.I2 = channel.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.I3 = channel.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.I4 = channel.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.I5 = channel.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.INS = channel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): wave {
    return {
      U1N: isSet(object.U1N) ? channel.fromJSON(object.U1N) : undefined,
      U2N: isSet(object.U2N) ? channel.fromJSON(object.U2N) : undefined,
      U3N: isSet(object.U3N) ? channel.fromJSON(object.U3N) : undefined,
      U1PE: isSet(object.U1PE) ? channel.fromJSON(object.U1PE) : undefined,
      U2PE: isSet(object.U2PE) ? channel.fromJSON(object.U2PE) : undefined,
      U3PE: isSet(object.U3PE) ? channel.fromJSON(object.U3PE) : undefined,
      UNPE: isSet(object.UNPE) ? channel.fromJSON(object.UNPE) : undefined,
      U12: isSet(object.U12) ? channel.fromJSON(object.U12) : undefined,
      U23: isSet(object.U23) ? channel.fromJSON(object.U23) : undefined,
      U31: isSet(object.U31) ? channel.fromJSON(object.U31) : undefined,
      I1: isSet(object.I1) ? channel.fromJSON(object.I1) : undefined,
      I2: isSet(object.I2) ? channel.fromJSON(object.I2) : undefined,
      I3: isSet(object.I3) ? channel.fromJSON(object.I3) : undefined,
      I4: isSet(object.I4) ? channel.fromJSON(object.I4) : undefined,
      I5: isSet(object.I5) ? channel.fromJSON(object.I5) : undefined,
      INS: isSet(object.INS) ? channel.fromJSON(object.INS) : undefined,
    };
  },

  toJSON(message: wave): unknown {
    const obj: any = {};
    if (message.U1N !== undefined) {
      obj.U1N = channel.toJSON(message.U1N);
    }
    if (message.U2N !== undefined) {
      obj.U2N = channel.toJSON(message.U2N);
    }
    if (message.U3N !== undefined) {
      obj.U3N = channel.toJSON(message.U3N);
    }
    if (message.U1PE !== undefined) {
      obj.U1PE = channel.toJSON(message.U1PE);
    }
    if (message.U2PE !== undefined) {
      obj.U2PE = channel.toJSON(message.U2PE);
    }
    if (message.U3PE !== undefined) {
      obj.U3PE = channel.toJSON(message.U3PE);
    }
    if (message.UNPE !== undefined) {
      obj.UNPE = channel.toJSON(message.UNPE);
    }
    if (message.U12 !== undefined) {
      obj.U12 = channel.toJSON(message.U12);
    }
    if (message.U23 !== undefined) {
      obj.U23 = channel.toJSON(message.U23);
    }
    if (message.U31 !== undefined) {
      obj.U31 = channel.toJSON(message.U31);
    }
    if (message.I1 !== undefined) {
      obj.I1 = channel.toJSON(message.I1);
    }
    if (message.I2 !== undefined) {
      obj.I2 = channel.toJSON(message.I2);
    }
    if (message.I3 !== undefined) {
      obj.I3 = channel.toJSON(message.I3);
    }
    if (message.I4 !== undefined) {
      obj.I4 = channel.toJSON(message.I4);
    }
    if (message.I5 !== undefined) {
      obj.I5 = channel.toJSON(message.I5);
    }
    if (message.INS !== undefined) {
      obj.INS = channel.toJSON(message.INS);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<wave>, I>>(base?: I): wave {
    return wave.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<wave>, I>>(object: I): wave {
    const message = createBasewave();
    message.U1N = (object.U1N !== undefined && object.U1N !== null) ? channel.fromPartial(object.U1N) : undefined;
    message.U2N = (object.U2N !== undefined && object.U2N !== null) ? channel.fromPartial(object.U2N) : undefined;
    message.U3N = (object.U3N !== undefined && object.U3N !== null) ? channel.fromPartial(object.U3N) : undefined;
    message.U1PE = (object.U1PE !== undefined && object.U1PE !== null) ? channel.fromPartial(object.U1PE) : undefined;
    message.U2PE = (object.U2PE !== undefined && object.U2PE !== null) ? channel.fromPartial(object.U2PE) : undefined;
    message.U3PE = (object.U3PE !== undefined && object.U3PE !== null) ? channel.fromPartial(object.U3PE) : undefined;
    message.UNPE = (object.UNPE !== undefined && object.UNPE !== null) ? channel.fromPartial(object.UNPE) : undefined;
    message.U12 = (object.U12 !== undefined && object.U12 !== null) ? channel.fromPartial(object.U12) : undefined;
    message.U23 = (object.U23 !== undefined && object.U23 !== null) ? channel.fromPartial(object.U23) : undefined;
    message.U31 = (object.U31 !== undefined && object.U31 !== null) ? channel.fromPartial(object.U31) : undefined;
    message.I1 = (object.I1 !== undefined && object.I1 !== null) ? channel.fromPartial(object.I1) : undefined;
    message.I2 = (object.I2 !== undefined && object.I2 !== null) ? channel.fromPartial(object.I2) : undefined;
    message.I3 = (object.I3 !== undefined && object.I3 !== null) ? channel.fromPartial(object.I3) : undefined;
    message.I4 = (object.I4 !== undefined && object.I4 !== null) ? channel.fromPartial(object.I4) : undefined;
    message.I5 = (object.I5 !== undefined && object.I5 !== null) ? channel.fromPartial(object.I5) : undefined;
    message.INS = (object.INS !== undefined && object.INS !== null) ? channel.fromPartial(object.INS) : undefined;
    return message;
  },
};

function createBaserms12(): rms12 {
  return { left: undefined, right: undefined };
}

export const rms12 = {
  encode(message: rms12, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.left !== undefined) {
      wave.encode(message.left, writer.uint32(10).fork()).ldelim();
    }
    if (message.right !== undefined) {
      wave.encode(message.right, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): rms12 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaserms12();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.left = wave.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.right = wave.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): rms12 {
    return {
      left: isSet(object.left) ? wave.fromJSON(object.left) : undefined,
      right: isSet(object.right) ? wave.fromJSON(object.right) : undefined,
    };
  },

  toJSON(message: rms12): unknown {
    const obj: any = {};
    if (message.left !== undefined) {
      obj.left = wave.toJSON(message.left);
    }
    if (message.right !== undefined) {
      obj.right = wave.toJSON(message.right);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<rms12>, I>>(base?: I): rms12 {
    return rms12.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<rms12>, I>>(object: I): rms12 {
    const message = createBaserms12();
    message.left = (object.left !== undefined && object.left !== null) ? wave.fromPartial(object.left) : undefined;
    message.right = (object.right !== undefined && object.right !== null) ? wave.fromPartial(object.right) : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
