import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { isNotNull } from "src/app/utils/typeguards";

type Props = ReturnType<typeof mapStateToProps>;

function ImpersonateUser(props: Props) {

	const {
		impersonateUserId,
	} = props;

	if (isNotNull(impersonateUserId)) {
		return (
			<div className="border-solid border-2 rounded p-2 bg-gray-100 border-gray-200 absolute left-2 bottom-16 z-50">
				{ `Actual impersonate user id: ${ impersonateUserId }` }
			</div>
		);
	} else {
		return null;
	}
}

const mapStateToProps = (state: RootState) => ({
	impersonateUserId: state.misc.impersonateUserId,
});

export default connect(mapStateToProps)(ImpersonateUser);
