import { isNotNull, isNull } from "src/app/utils/typeguards";
import { roundRange } from "src/app/utils/helpers";
import { GridLoader } from "react-spinners";
//@ts-ignore
import { Chart, ChartCanvas } from "react-stockcharts";
//@ts-ignore
import { GroupedBarSeries, StraightLine } from "react-stockcharts/lib/series";
//@ts-ignore
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
//@ts-ignore
import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";
import { appColors } from "src/app/utils/constants/theme";
import { StockChartIndex } from "src/app/types/ui/chart.types";
import { HarmonicChartData } from "src/app/components/Chart/Harmonic/HarmonicChart.component";
import HarmonicChartHoverTooltip from "src/app/components/Chart/Component/HarmonicHoverTooltip.component";
import { getPrefixToUnit } from "src/app/utils/dataFormatting";
import { Nullable } from "src/app/types/util.types";
import { Button } from "flowbite-react";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import { Dispatch, SetStateAction } from "react";
import { MeasurementCategory } from "src/app/types/misc.types";
import { useTranslation } from "react-i18next";

export enum HarmonicPowerLine {
	U1_I1_active = "U1_I1_active", // P1
	U2_I2_active = "U2_I2_active", // P2
	U3_I3_active = "U3_I3_active", // P3
	U1_I1_reactive = "U1_I1_reactive", // Q1
	U2_I2_reactive = "U2_I2_reactive", // Q2
	U3_I3_reactive = "U3_I3_reactive" // Q3
}

export type HarmonicPowerConfigState = {
	[K in HarmonicPowerLine]: boolean
}

type Props = {
	seriesName: string
	width: number
	height: number
	data: HarmonicPowerChartData[]
	config: HarmonicPowerConfigState
	units: {
		P: string
		Q: string
	}
	setDisplayedRange: Dispatch<SetStateAction<[ fromIndex: number, toIndex: number ]>>
	getTooltipLabels: (config: HarmonicPowerConfigState, currentItem: HarmonicPowerChartData & StockChartIndex) => { label: string, value: string }[]
};

export type HarmonicPowerChartData =
	{
		index: number
		date: Date
	}
	& {
		[K in HarmonicPowerLine]: Nullable<number>
	}

function HarmonicPowerChart(props: Props) {

	const { t } = useTranslation();

	const {
		width,
		height,
		data,
		config,
		units: {
			P,
			Q,
		},
		setDisplayedRange,
		getTooltipLabels,
	} = props;

	const _calculateActivePowerRange = (data: HarmonicPowerChartData[]): [ min: number, max: number ] => {
		const firstValue = data[ 0 ];
		if (isNull(firstValue)) return [ 0, 2 ];
		const firstMinValue = Math.min(
			...[
				config.U1_I1_active ? (data[ 0 ].U1_I1_active ?? 0) : null,
				config.U2_I2_active ? (data[ 0 ].U2_I2_active ?? 0) : null,
				config.U3_I3_active ? (data[ 0 ].U3_I3_active ?? 0) : null,
			].filter(isNotNull),
		);
		const firstMaxValue = Math.max(
			...[
				config.U1_I1_active ? (data[ 0 ].U1_I1_active ?? 0) : null,
				config.U2_I2_active ? (data[ 0 ].U2_I2_active ?? 0) : null,
				config.U3_I3_active ? (data[ 0 ].U3_I3_active ?? 0) : null,
			].filter(isNotNull),
		);
		const yAxisRange: [ number, number ] = data.reduce((prev, next) => [
			Math.min(
				prev[ 0 ],
				config.U1_I1_active ? (next.U1_I1_active ?? 0) : prev[ 0 ],
				config.U2_I2_active ? (next.U2_I2_active ?? 0) : prev[ 0 ],
				config.U3_I3_active ? (next.U3_I3_active ?? 0) : prev[ 0 ],
			),
			Math.max(
				prev[ 1 ],
				config.U1_I1_active ? (next.U1_I1_active ?? 0) : prev[ 1 ],
				config.U2_I2_active ? (next.U2_I2_active ?? 0) : prev[ 1 ],
				config.U3_I3_active ? (next.U3_I3_active ?? 0) : prev[ 1 ],
			),
		], [ firstMinValue, firstMaxValue ]);

		return [
			roundRange(yAxisRange[ 0 ], Math.floor),
			roundRange(yAxisRange[ 1 ], Math.ceil),
		];
	};

	const _calculateReactivePowerRange = (data: HarmonicPowerChartData[]): [ min: number, max: number ] => {
		const firstValue = data[ 0 ];
		if (isNull(firstValue)) return [ 0, 2 ];
		const firstMinValue = Math.min(
			...[
				config.U1_I1_reactive ? (data[ 0 ].U1_I1_reactive ?? 0) : null,
				config.U2_I2_reactive ? (data[ 0 ].U2_I2_reactive ?? 0) : null,
				config.U3_I3_reactive ? (data[ 0 ].U3_I3_reactive ?? 0) : null,
			].filter(isNotNull),
		);
		const firstMaxValue = Math.max(
			...[
				config.U1_I1_reactive ? (data[ 0 ].U1_I1_reactive ?? 0) : null,
				config.U2_I2_reactive ? (data[ 0 ].U2_I2_reactive ?? 0) : null,
				config.U3_I3_reactive ? (data[ 0 ].U3_I3_reactive ?? 0) : null,
			].filter(isNotNull),
		);
		const yAxisRange: [ number, number ] = data.reduce((prev, next) => [
			Math.min(
				prev[ 0 ],
				config.U1_I1_reactive ? (next.U1_I1_reactive ?? 0) : prev[ 0 ],
				config.U2_I2_reactive ? (next.U2_I2_reactive ?? 0) : prev[ 0 ],
				config.U3_I3_reactive ? (next.U3_I3_reactive ?? 0) : prev[ 0 ],
			),
			Math.max(
				prev[ 1 ],
				config.U1_I1_reactive ? (next.U1_I1_reactive ?? 0) : prev[ 1 ],
				config.U2_I2_reactive ? (next.U2_I2_reactive ?? 0) : prev[ 1 ],
				config.U3_I3_reactive ? (next.U3_I3_reactive ?? 0) : prev[ 1 ],
			),
		], [ firstMinValue, firstMaxValue ]);

		return [
			roundRange(yAxisRange[ 0 ], Math.floor),
			roundRange(yAxisRange[ 1 ], Math.ceil),
		];
	};

	if (data.length < 3) {
		return (
			<div className="w-full h-full flex items-center justify-center">
				<GridLoader size={ 15 } color="#0093DD"/>
			</div>
		);
	}

	const yExtentsActivePower = _calculateActivePowerRange(data);
	const yExtentsReactivePower = _calculateReactivePowerRange(data);

	const isDisplayingActivePower = config.U1_I1_active || config.U2_I2_active || config.U3_I3_active;
	const isDisplayingReactivePower = config.U1_I1_reactive || config.U2_I2_reactive || config.U3_I3_reactive;

	const margins = {
		left: 60,
		right: 60,
		top: 20,
		bottom: 30,
	};

	const initialIndex = 0;
	const xScaleProvider = discontinuousTimeScaleProvider
		.initialIndex(initialIndex)
		.inputDateAccessor((d: any) => d.date);

	const {
		data: chartData,
		xScale,
		xAccessor,
	} = xScaleProvider(
		data,
	);

	const firstItem = chartData[ data.length - 1 ];
	const lastItem = chartData[ 0 ];

	const xExtents = new Array(chartData.length + 2).fill(initialIndex - 1).map((item, index) => item + index);

	const legendDescriptionY = "-8";

	return (
		<div className="relative">
			<div className="absolute top-1/2 -translate-y-1/2 right-2 z-50 w-[46px] h-[102px]">
				<Button
					outline
					pill
					className="group absolute left-0 top-0 [&>span]:p-1 [&>span]:pl-1.5 [&>span]:pr-0.5"
				>
					<IoChevronForwardOutline
						className="w-8 h-8 group-hover:text-white transition"
						onClick={ () => setDisplayedRange(prevState => [ Math.min(prevState[ 0 ] + 50, 200), Math.min(prevState[ 1 ] + 50, 250) ]) }
					/>
				</Button>
				<Button
					outline
					pill
					className="group absolute left-0 top-14 [&>span]:p-1 [&>span]:pl-0.5 [&>span]:pr-1.5"
				>
					<IoChevronBackOutline
						className="w-8 h-8 group-hover:text-white transition"
						onClick={ () => setDisplayedRange(prevState => [ Math.max(prevState[ 0 ] - 50, 0), Math.max(prevState[ 1 ] - 50, 50) ]) }
					/>
				</Button>
			</div>
			<ChartCanvas
				ratio={ 1 }
				width={ width }
				height={ height }
				margin={ margins }
				type="svg"
				seriesName="HarmonicsPower"
				xExtents={ xExtents }
				data={ chartData }
				xAccessor={ xAccessor }
				xScale={ xScale }
				mouseMoveEvent={ true }
				panEvent={ false }
				zoomEvent={ false }
			>
				<Chart
					height={ (0.5 * height) - 50 }
					id={ 1 }
					yExtents={ yExtentsActivePower }
				>
					<XAxis
						axisAt="bottom"
						orient="bottom"
						tickFormat={ (index: number) => {
							const axisIndex = (chartData as (HarmonicChartData & StockChartIndex)[]).find(dataItem => dataItem.idx.index === index)?.index;
							if (isNotNull(axisIndex) && axisIndex % 2 !== 1 && axisIndex !== 0) {
								return "";
							}
							return (chartData as (HarmonicChartData & StockChartIndex)[]).find(dataItem => dataItem.idx.index === index)?.index?.toString() ?? "";
						} }
						tickValues={ chartData.map((item: HarmonicChartData & StockChartIndex) => item.idx.index) }
						zoomEnabled={ false }
						fontSize={ 12 }
						fontFamily="Segoe UI, sans-serif"
					/>
					<YAxis
						axisAt="left"
						orient="left"
						zoomEnabled={ false }
						fontFamily="Segoe UI, sans-serif"
					/>
					<GroupedBarSeries
						baseAt={ (xScale: any, yScale: any) => yScale(0) }
						yAccessor={ [
							config.U1_I1_active ? (d: HarmonicPowerChartData) => d.U1_I1_active : null,
							config.U2_I2_active ? (d: HarmonicPowerChartData) => d.U2_I2_active : null,
							config.U3_I3_active ? (d: HarmonicPowerChartData) => d.U3_I3_active : null,
						].filter(isNotNull) }
						fill={ (d: HarmonicPowerChartData, i: number) => {
							// Spaghetti code
							if (!config.U1_I1_active && config.U2_I2_active) i++; // 0 1 1; 0 1 0
							if (!config.U1_I1_active && !config.U2_I2_active) i += 2; // 0 0 1; 0 0 0
							if (config.U1_I1_active && !config.U2_I2_active && i === 1) i++; // 1 0 1

							// rest is
							// 100, 110, 111
							switch (i) {
								case 0:
									return appColors.L1P;
								case 1:
									return appColors.L2P;
								case 2:
									return appColors.L3P;
								default:
									return "#000000";
							}
						} }
						spaceBetweenBar={ -4 }
					/>
					<StraightLine yValue={ 0 }/>
					{
						isDisplayingActivePower &&
                        <>
                            <rect x="-30" y="-10" width={ 30 } height={ 16 } fill="white"/>
                            <text x="-48" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>{ `Ph[${ P }]` }</text>
                        </>
					}
				</Chart>
				<Chart
					height={ (0.5 * height) - 50 }
					id={ 2 }
					yExtents={ yExtentsReactivePower }
					origin={ (w: number, h: number) => [ 0, h - (0.5 * height) + 50 ] }
				>
					<XAxis
						axisAt="bottom"
						orient="bottom"
						tickFormat={ (index: number) => {
							const axisIndex = (chartData as (HarmonicChartData & StockChartIndex)[]).find(dataItem => dataItem.idx.index === index)?.index;
							if (isNotNull(axisIndex) && axisIndex % 2 !== 1 && axisIndex !== 0) {
								return "";
							}
							return (chartData as (HarmonicChartData & StockChartIndex)[]).find(dataItem => dataItem.idx.index === index)?.index?.toString() ?? "";
						} }
						tickValues={ chartData.map((item: HarmonicChartData & StockChartIndex) => item.idx.index) }
						zoomEnabled={ false }
						fontSize={ 12 }
						fontFamily="Segoe UI, sans-serif"
					/>
					<YAxis
						axisAt="left"
						orient="left"
						zoomEnabled={ false }
						fontFamily="Segoe UI, sans-serif"
					/>
					<GroupedBarSeries
						baseAt={ (xScale: any, yScale: any) => yScale(0) }
						yAccessor={ [
							config.U1_I1_reactive ? (d: HarmonicPowerChartData) => d.U1_I1_reactive : null,
							config.U2_I2_reactive ? (d: HarmonicPowerChartData) => d.U2_I2_reactive : null,
							config.U3_I3_reactive ? (d: HarmonicPowerChartData) => d.U3_I3_reactive : null,
						].filter(isNotNull) }
						fill={ (d: HarmonicPowerChartData, i: number) => {
							// Spaghetti code
							if (!config.U1_I1_reactive && config.U2_I2_reactive) i++; // 0 1 1; 0 1 0
							if (!config.U1_I1_reactive && !config.U2_I2_reactive) i += 2; // 0 0 1; 0 0 0
							if (config.U1_I1_reactive && !config.U2_I2_reactive && i === 1) i++; // 1 0 1

							// rest is
							// 100, 110, 111

							switch (i) {
								case 0:
									return appColors.L1Q;
								case 1:
									return appColors.L2Q;
								case 2:
									return appColors.L3Q;
								default:
									return "#000000";
							}
						} }
						spaceBetweenBar={ -4 }
					/>
					<StraightLine yValue={ 0 }/>
					{
						isDisplayingReactivePower &&
                        <>
                            <rect x="-45" y="-10" width={ 50 } height={ 16 } fill="white"/>
                            <text x="-55" y={ legendDescriptionY } fontFamily="Segoe UI, sans-serif" fontWeight={ 600 } fontSize={ 14 }>{ `Qh[${ Q }]` }</text>
                        </>
					}
				</Chart>
				<Chart
					height={ height }
					id={ 3 }
					yExtents={ yExtentsReactivePower }
				>
					<HarmonicChartHoverTooltip
						firstItem={ firstItem }
						lastItem={ lastItem }
						getTooltipContent={ (probeArgument: { currentItem: HarmonicPowerChartData & StockChartIndex }) => {
							const { currentItem } = probeArgument;
							return {
								x: `${ t("TABLE.index") }: ${ currentItem.index }`,
								y: getTooltipLabels(config, currentItem),
							};
						} }
					/>
				</Chart>
			</ChartCanvas>
		</div>
	);
}

export default (HarmonicPowerChart);
