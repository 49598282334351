import { Button, Card, ToggleSwitch } from "flowbite-react";
import { OnOff, SystemType } from "src/app/types/api/settings.types";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateNumberField } from "src/app/utils/forms";
import { useContext, useEffect } from "react";
import { SettingsContext } from "src/app/hoc/providers/Settings.provider";
import useForm from "src/app/utils/hooks/useForm";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { mapEnumSettingToSelectOptions } from "src/app/utils/helpers";
import { nominalFrequencyDictionary, phaseRotationDictionary, systemTypeDictionary } from "src/app/utils/constants/dictionaries";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import Select from "src/app/components/Form/Select.component";
import Input from "src/app/components/Form/Input.component";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";

type Props =
	ReturnType<typeof mapStateToProps>;

type RecordingGeneralForm = {
	type: number
	nominalFrequency: number
	nominalVoltage: string
	unpeChannelEnable: boolean
	minNominalVoltage: number
	maxNominalVoltage: number
	voltageTransducerRatio: string
	minVoltageTransducerRatio: number
	maxVoltageTransducerRatio: number
	currentCh1TransducerRatio: string
	minCurrentCh1TransducerRatio: number
	maxCurrentCh1TransducerRatio: number
	currentCh2TransducerRatio: string
	currentCh3TransducerRatio: string
	currentCh4TransducerRatio: string
	minCurrentCh4TransducerRatio: number
	maxCurrentCh4TransducerRatio: number
	currentCh5TransducerRatio: string
	minCurrentCh5TransducerRatio: number
	maxCurrentCh5TransducerRatio: number
	ch4Enabled: boolean
	ch5Enabled: boolean
	phaseRotation: number
}

const validator: FormValidator<RecordingGeneralForm> = {
	type: () => null,
	nominalFrequency: () => null,
	nominalVoltage: (nominalVoltage, optional, form) => validateNumberField(i18n.t("ANALYZER.nominal voltage"), nominalVoltage, optional, "it", { from: form.minNominalVoltage.value, to: form.maxNominalVoltage.value, decimalPlaces: 2 }),
	unpeChannelEnable: () => null,
	minNominalVoltage: () => null,
	maxNominalVoltage: () => null,
	voltageTransducerRatio: (voltageTransducerRatio, optional, form) => validateNumberField(i18n.t("ANALYZER.voltage transducer ratio"), voltageTransducerRatio, optional, "she", { from: form.minVoltageTransducerRatio.value, to: form.maxVoltageTransducerRatio.value, decimalPlaces: 2 }),
	minVoltageTransducerRatio: () => null,
	maxVoltageTransducerRatio: () => null,
	currentCh1TransducerRatio: (currentCh1TransducerRatio, optional, form) => validateNumberField(i18n.t("ANALYZER.current I1 transducer ratio"), currentCh1TransducerRatio, optional, "she", { from: form.minCurrentCh1TransducerRatio.value, to: form.maxCurrentCh1TransducerRatio.value, decimalPlaces: 2 }),
	minCurrentCh1TransducerRatio: () => null,
	maxCurrentCh1TransducerRatio: () => null,
	currentCh2TransducerRatio: (currentCh2TransducerRatio, optional) => validateNumberField(i18n.t("ANALYZER.current I2 transducer ratio"), currentCh2TransducerRatio, optional, "she"),
	currentCh3TransducerRatio: (currentCh3TransducerRatio, optional) => validateNumberField(i18n.t("ANALYZER.current I3 transducer ratio"), currentCh3TransducerRatio, optional, "she"),
	currentCh4TransducerRatio: (currentCh4TransducerRatio, optional, form) => validateNumberField(i18n.t("ANALYZER.current I4 transducer ratio"), currentCh4TransducerRatio, optional, "she", { from: form.minCurrentCh4TransducerRatio.value, to: form.maxCurrentCh4TransducerRatio.value, decimalPlaces: 2 }),
	minCurrentCh4TransducerRatio: () => null,
	maxCurrentCh4TransducerRatio: () => null,
	currentCh5TransducerRatio: (currentCh5TransducerRatio, optional, form) => validateNumberField(i18n.t("ANALYZER.current I5 transducer ratio"), currentCh5TransducerRatio, optional, "she", { from: form.minCurrentCh5TransducerRatio.value, to: form.maxCurrentCh5TransducerRatio.value, decimalPlaces: 2 }),
	minCurrentCh5TransducerRatio: () => null,
	maxCurrentCh5TransducerRatio: () => null,
	ch4Enabled: () => null,
	ch5Enabled: () => null,
	phaseRotation: () => null,
};

function RecordingGeneralCard(props: Props) {

	const { t } = useTranslation();

	const {
		isAdmin,
	} = props;

	const {
		recording,
	} = useContext(SettingsContext);

	const reducerForm = useReducerForm(
		"buffor",
		bufforFormActions,
		() => undefined,
	);

	const _handleSubmit = (values: RecordingGeneralForm) => {
		reducerForm.handleChange("type", values.type);
		reducerForm.handleChange("nominalFrequency", values.nominalFrequency);
		reducerForm.handleChange("nominalVoltage", values.nominalVoltage);
		reducerForm.handleChange("unpeChannelEnable", values.unpeChannelEnable);
		reducerForm.handleChange("voltageTransducerRatio", values.voltageTransducerRatio);
		reducerForm.handleChange("ch4Enabled", values.ch4Enabled);
		reducerForm.handleChange("ch5Enabled", values.ch5Enabled);
		reducerForm.handleChange("currentCh1TransducerRatio", values.currentCh1TransducerRatio);
		reducerForm.handleChange("currentCh2TransducerRatio", values.currentCh1TransducerRatio);
		reducerForm.handleChange("currentCh3TransducerRatio", values.currentCh1TransducerRatio);
		values.ch4Enabled && reducerForm.handleChange("currentCh4TransducerRatio", values.currentCh4TransducerRatio);
		values.ch5Enabled && reducerForm.handleChange("currentCh5TransducerRatio", values.currentCh5TransducerRatio);
		reducerForm.handleChange("phaseRotation", values.phaseRotation);
	};

	const _getInitialState = () => ({
		type: createFormField((reducerForm.form.type.value !== reducerForm.form.type.initialValue) ? reducerForm.form.type.value : recording?.recording?.cfgSystemType?.value ?? 0, { disabled: !isAdmin }),
		nominalFrequency: createFormField((reducerForm.form.nominalFrequency.value !== reducerForm.form.nominalFrequency.initialValue) ? reducerForm.form.nominalFrequency.value : recording?.recording?.cfgNominalFrequency?.value ?? 0, { disabled: !isAdmin }),
		nominalVoltage: createFormField((reducerForm.form.nominalVoltage.value !== reducerForm.form.nominalVoltage.initialValue) ? reducerForm.form.nominalVoltage.value : recording?.recording?.cfgNominalVoltage?.value?.toFixed(2) ?? "", { disabled: !isAdmin }),
		unpeChannelEnable: createFormField((reducerForm.form.unpeChannelEnable.value !== reducerForm.form.unpeChannelEnable.initialValue) ? reducerForm.form.unpeChannelEnable.value : recording?.recording?.cfgUnpeChannelEnable?.enum?.find(enumValue => enumValue.value === recording?.recording?.cfgUnpeChannelEnable?.value)?.text === OnOff.ON, { disabled: !isAdmin }),
		minNominalVoltage: createFormField(recording?.recording?.cfgNominalVoltage?.minValue ?? 0.01),
		maxNominalVoltage: createFormField(recording?.recording?.cfgNominalVoltage?.maxValue ?? 100_000),
		voltageTransducerRatio: createFormField((reducerForm.form.voltageTransducerRatio.value !== reducerForm.form.voltageTransducerRatio.initialValue) ? reducerForm.form.voltageTransducerRatio.value : recording?.recording?.cfgVoltageTransducerRatio?.value?.toFixed(2) ?? "", { disabled: !isAdmin }),
		minVoltageTransducerRatio: createFormField(recording?.recording?.cfgVoltageTransducerRatio?.minValue ?? 0.01),
		maxVoltageTransducerRatio: createFormField(recording?.recording?.cfgVoltageTransducerRatio?.maxValue ?? 100_000),
		currentCh1TransducerRatio: createFormField((reducerForm.form.currentCh1TransducerRatio.value !== reducerForm.form.currentCh1TransducerRatio.initialValue) ? reducerForm.form.currentCh1TransducerRatio.value : recording?.recording?.cfgCurrentCh1TransducerRatio?.value?.toFixed(2) ?? "", { disabled: !isAdmin }),
		minCurrentCh1TransducerRatio: createFormField(recording?.recording?.cfgCurrentCh1TransducerRatio?.minValue ?? 0.01),
		maxCurrentCh1TransducerRatio: createFormField(recording?.recording?.cfgCurrentCh1TransducerRatio?.maxValue ?? 100000),
		currentCh2TransducerRatio: createFormField((reducerForm.form.currentCh1TransducerRatio.value !== reducerForm.form.currentCh1TransducerRatio.initialValue) ? reducerForm.form.currentCh1TransducerRatio.value : recording?.recording?.cfgCurrentCh1TransducerRatio?.value?.toFixed(2) ?? "", { disabled: !isAdmin }),
		currentCh3TransducerRatio: createFormField((reducerForm.form.currentCh1TransducerRatio.value !== reducerForm.form.currentCh1TransducerRatio.initialValue) ? reducerForm.form.currentCh1TransducerRatio.value : recording?.recording?.cfgCurrentCh1TransducerRatio?.value?.toFixed(2) ?? "", { disabled: !isAdmin }),
		currentCh4TransducerRatio: createFormField((reducerForm.form.currentCh4TransducerRatio.value !== reducerForm.form.currentCh4TransducerRatio.initialValue) ? reducerForm.form.currentCh4TransducerRatio.value : recording?.recording?.cfgCurrentCh4TransducerRatio?.value?.toFixed(2) ?? "", { disabled: !isAdmin }),
		minCurrentCh4TransducerRatio: createFormField(recording?.recording?.cfgCurrentCh4TransducerRatio?.minValue ?? 0.01),
		maxCurrentCh4TransducerRatio: createFormField(recording?.recording?.cfgCurrentCh4TransducerRatio?.maxValue ?? 100000),
		currentCh5TransducerRatio: createFormField((reducerForm.form.currentCh5TransducerRatio.value !== reducerForm.form.currentCh5TransducerRatio.initialValue) ? reducerForm.form.currentCh5TransducerRatio.value : recording?.recording?.cfgCurrentCh5TransducerRatio?.value?.toFixed(2) ?? "", { disabled: !isAdmin }),
		minCurrentCh5TransducerRatio: createFormField(recording?.recording?.cfgCurrentCh5TransducerRatio?.minValue ?? 0.01),
		maxCurrentCh5TransducerRatio: createFormField(recording?.recording?.cfgCurrentCh5TransducerRatio?.maxValue ?? 100000),
		ch4Enabled: createFormField((reducerForm.form.ch4Enabled.value !== reducerForm.form.ch4Enabled.initialValue) ? reducerForm.form.ch4Enabled.value : recording?.recording?.cfgCurrentCh4Enable?.enum?.find(enumValue => enumValue.value === recording?.recording?.cfgCurrentCh4Enable?.value)?.text === OnOff.ON, { disabled: !isAdmin }),
		ch5Enabled: createFormField((reducerForm.form.ch5Enabled.value !== reducerForm.form.ch5Enabled.initialValue) ? reducerForm.form.ch5Enabled.value : recording?.recording?.cfgCurrentCh5Enable?.enum?.find(enumValue => enumValue.value === recording?.recording?.cfgCurrentCh5Enable?.value)?.text === OnOff.ON, { disabled: !isAdmin }),
		phaseRotation: createFormField((reducerForm.form.phaseRotation.value !== reducerForm.form.phaseRotation.initialValue) ? reducerForm.form.phaseRotation.value : recording?.recording?.cfgPhaseRotation?.value ?? 0, { disabled: !isAdmin }),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ recording ]);

	useEffect(() => {
		handleChange("type", reducerForm.form.type.value);
		handleChange("nominalFrequency", reducerForm.form.nominalFrequency.value);
		handleChange("nominalVoltage", reducerForm.form.nominalVoltage.value);
		handleChange("unpeChannelEnable", reducerForm.form.unpeChannelEnable.value);
		handleChange("voltageTransducerRatio", reducerForm.form.voltageTransducerRatio.value);
		handleChange("ch4Enabled", reducerForm.form.ch4Enabled.value);
		handleChange("ch5Enabled", reducerForm.form.ch5Enabled.value);
		handleChange("currentCh1TransducerRatio", reducerForm.form.currentCh1TransducerRatio.value);
		handleChange("currentCh2TransducerRatio", reducerForm.form.currentCh1TransducerRatio.value);
		handleChange("currentCh3TransducerRatio", reducerForm.form.currentCh1TransducerRatio.value);
		handleChange("currentCh4TransducerRatio", reducerForm.form.currentCh4TransducerRatio.value);
		handleChange("currentCh5TransducerRatio", reducerForm.form.currentCh5TransducerRatio.value);
		handleChange("phaseRotation", reducerForm.form.phaseRotation.value);
	}, [
		reducerForm.form.type.value,
		reducerForm.form.nominalFrequency.value,
		reducerForm.form.nominalVoltage.value,
		reducerForm.form.unpeChannelEnable.value,
		reducerForm.form.voltageTransducerRatio.value,
		reducerForm.form.ch4Enabled.value,
		reducerForm.form.ch5Enabled.value,
		reducerForm.form.currentCh1TransducerRatio.value,
		reducerForm.form.currentCh2TransducerRatio.value,
		reducerForm.form.currentCh3TransducerRatio.value,
		reducerForm.form.currentCh4TransducerRatio.value,
		reducerForm.form.currentCh5TransducerRatio.value,
		reducerForm.form.phaseRotation.value,
	]);

	const systemType = recording?.recording?.cfgSystemType?.enum?.find(enumOption => enumOption.value === form.type.value)?.text;

	return (
		<Card>
			<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
				{ t("ANALYZER.general") }
			</h5>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<Select
					label={ t("ANALYZER.mains system") }
					options={ mapEnumSettingToSelectOptions(recording?.recording?.cfgSystemType, systemTypeDictionary) }
					formItem={ form.type }
					onChange={ option => {
						if (isNull(option)) return;

						handleChange("type", option?.value);
						handleBlur("type");

						const systemType = recording?.recording?.cfgSystemType?.enum?.find(enumOption => enumOption.value === option?.value)?.text;
						if (isNotNull(systemType) && (systemType === SystemType.ST_3PH_3W || systemType === SystemType.ST_3PH_3W_ARON)) {
							handleChange("ch4Enabled", false);
							handleChange("ch5Enabled", false);
							handleChange("unpeChannelEnable", false);
						}
					} }
					isSearchable={ false }
					isClearable={ false }
					hasBeenChanged={ reducerForm.form.type.value !== reducerForm.form.type.initialValue }
				/>
				<ToggleSwitch
					className={
						classNames(
							"[&>div]:w-9 [&>div]:h-5 [&>div]:after:absolute [&>div]:after:top-[2px] [&>div]:after:left-[2px] [&>div]:after:h-4 [&>div]:after:w-4",
							{ "[&>span]:!text-yellow-400": reducerForm.form.ch4Enabled.value !== reducerForm.form.ch4Enabled.initialValue },
						)
					}
					disabled={ (isNotNull(systemType) && (systemType === SystemType.ST_3PH_3W || systemType === SystemType.ST_3PH_3W_ARON)) || form.ch4Enabled.disabled }
					checked={ form.ch4Enabled.value }
					label={ t("ANALYZER.current I4") }
					onChange={ () => handleChange("ch4Enabled", !form.ch4Enabled.value) }
				/>
				<ToggleSwitch
					className={
						classNames(
							"[&>div]:w-9 [&>div]:h-5 [&>div]:after:absolute [&>div]:after:top-[2px] [&>div]:after:left-[2px] [&>div]:after:h-4 [&>div]:after:w-4",
							{ "[&>span]:!text-yellow-400": reducerForm.form.ch5Enabled.value !== reducerForm.form.ch5Enabled.initialValue },
						)
					}
					disabled={ form.ch5Enabled.disabled }
					checked={ form.ch5Enabled.value }
					label={ t("ANALYZER.current I5") }
					onChange={ () => handleChange("ch5Enabled", !form.ch5Enabled.value) }
				/>
				<Select
					label={ `${ t("ANALYZER.nominal frequency") } [${ recording?.recording?.cfgNominalFrequency?.unit ?? "---" }]` }
					options={ mapEnumSettingToSelectOptions(recording?.recording?.cfgNominalFrequency, nominalFrequencyDictionary) }
					formItem={ form.nominalFrequency }
					onChange={ option => {
						if (isNull(option)) return;

						handleChange("nominalFrequency", option?.value);
						handleBlur("nominalFrequency");
					} }
					isSearchable={ false }
					isClearable={ false }
					hasBeenChanged={ reducerForm.form.nominalFrequency.value !== reducerForm.form.nominalFrequency.initialValue }
				/>
				<Input
					formItem={ form.nominalVoltage }
					label={ `${ t("ANALYZER.nominal voltage") } [${ recording?.recording?.cfgNominalVoltage?.unit ?? "---" }]` }
					name="nominalVoltage"
					inputProps={ {
						type: "number",
						onChange: (e) => handleChange("nominalVoltage", e.target.value),
						onBlur: () => handleBlur("nominalVoltage"),
					} }
					hasBeenChanged={ reducerForm.form.nominalVoltage.value !== reducerForm.form.nominalVoltage.initialValue }
				/>
				<ToggleSwitch
					className={
						classNames(
							"[&>div]:w-9 [&>div]:h-5 [&>div]:after:absolute [&>div]:after:top-[2px] [&>div]:after:left-[2px] [&>div]:after:h-4 [&>div]:after:w-4",
							{ "[&>span]:!text-yellow-400": reducerForm.form.unpeChannelEnable.value !== reducerForm.form.unpeChannelEnable.initialValue },
						)
					}
					disabled={ (isNotNull(systemType) && (systemType === SystemType.ST_3PH_3W || systemType === SystemType.ST_3PH_3W_ARON)) || form.unpeChannelEnable.disabled }
					checked={ form.unpeChannelEnable.value }
					label={ t("ANALYZER.unpe channel enable") }
					onChange={ () => handleChange("unpeChannelEnable", !form.unpeChannelEnable.value) }
				/>
				<Input
					formItem={ form.voltageTransducerRatio }
					label={ t("ANALYZER.voltage transducer ratio") }
					name="voltageTransducerRatio"
					inputProps={ {
						type: "number",
						onChange: (e) => handleChange("voltageTransducerRatio", e.target.value),
						onBlur: () => handleBlur("voltageTransducerRatio"),
					} }
					hasBeenChanged={ reducerForm.form.voltageTransducerRatio.value !== reducerForm.form.voltageTransducerRatio.initialValue }
				/>
				<Input
					formItem={ form.currentCh1TransducerRatio }
					label={ t("ANALYZER.current I1 transducer ratio") }
					name="currentCh1TransducerRatio"
					inputProps={ {
						type: "number",
						onChange: (e) => handleChange("currentCh1TransducerRatio", e.target.value),
						onBlur: () => handleBlur("currentCh1TransducerRatio"),
					} }
					hasBeenChanged={ reducerForm.form.currentCh1TransducerRatio.value !== reducerForm.form.currentCh1TransducerRatio.initialValue }
				/>
				<Input
					formItem={ form.currentCh1TransducerRatio }
					label={ t("ANALYZER.current I2 transducer ratio") }
					name="currentCh2TransducerRatio"
					inputProps={ {
						type: "number",
						disabled: true,
						onChange: (e) => handleChange("currentCh2TransducerRatio", e.target.value),
						onBlur: () => handleBlur("currentCh2TransducerRatio"),
					} }
					hasBeenChanged={ reducerForm.form.currentCh2TransducerRatio.value !== reducerForm.form.currentCh2TransducerRatio.initialValue }
				/>
				<Input
					formItem={ form.currentCh1TransducerRatio }
					label={ t("ANALYZER.current I3 transducer ratio") }
					name="currentCh3TransducerRatio"
					inputProps={ {
						type: "number",
						disabled: true,
						onChange: (e) => handleChange("currentCh3TransducerRatio", e.target.value),
						onBlur: () => handleBlur("currentCh3TransducerRatio"),
					} }
					hasBeenChanged={ reducerForm.form.currentCh3TransducerRatio.value !== reducerForm.form.currentCh3TransducerRatio.initialValue }
				/>
				<Input
					formItem={ form.currentCh4TransducerRatio }
					label={ t("ANALYZER.current I4 transducer ratio") }
					name="currentCh4TransducerRatio"
					inputProps={ {
						type: "number",
						onChange: (e) => handleChange("currentCh4TransducerRatio", e.target.value),
						onBlur: () => handleBlur("currentCh4TransducerRatio"),
						disabled: !form.ch4Enabled.value,
					} }
					hasBeenChanged={ reducerForm.form.currentCh4TransducerRatio.value !== reducerForm.form.currentCh4TransducerRatio.initialValue }
				/>
				<Input
					formItem={ form.currentCh5TransducerRatio }
					label={ t("ANALYZER.current I5 transducer ratio") }
					name="currentCh5TransducerRatio"
					inputProps={ {
						type: "number",
						onChange: (e) => handleChange("currentCh5TransducerRatio", e.target.value),
						onBlur: () => handleBlur("currentCh5TransducerRatio"),
						disabled: !form.ch5Enabled.value,
					} }
					hasBeenChanged={ reducerForm.form.currentCh5TransducerRatio.value !== reducerForm.form.currentCh5TransducerRatio.initialValue }
				/>
				<Select
					label={ t("ANALYZER.phase rotation") }
					options={ mapEnumSettingToSelectOptions(recording?.recording?.cfgPhaseRotation, phaseRotationDictionary) }
					formItem={ form.phaseRotation }
					onChange={ option => {
						if (isNull(option)) return;

						handleChange("phaseRotation", option?.value);
						handleBlur("phaseRotation");
					} }
					isSearchable={ false }
					isClearable={ false }
					hasBeenChanged={ reducerForm.form.phaseRotation.value !== reducerForm.form.phaseRotation.initialValue }
				/>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(RecordingGeneralCard);
