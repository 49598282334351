import { combineReducers } from "redux";
import { createFormReducer } from "src/app/utils/redux";
import { loginFormInitialState, loginFormValidator } from "src/app/utils/constants/login.form";
import { bufforFormActions, firmwareUpgradeFormActions, loginFormActions } from "src/app/store/features/form/form.actions";
import { bufforFormInitialState, bufforFormValidator } from "src/app/utils/constants/buffor.form";
import { uploadFirmwareInitialState, uploadFirmwareValidator } from "src/app/utils/constants/firmwareUpgrade.form";

const reducer = combineReducers({
	login: createFormReducer(loginFormInitialState, loginFormActions, loginFormValidator),
	buffor: createFormReducer(bufforFormInitialState, bufforFormActions, bufforFormValidator, {}, { noCallbackPrompt: true }),
	uploadFirmware: createFormReducer(uploadFirmwareInitialState, firmwareUpgradeFormActions, uploadFirmwareValidator),
});

export default reducer;
