import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { RecordingOption, SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateNumberField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import BasicRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/BasicRecordingOptionConfiguration.component";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { useContext, useEffect } from "react";
import { MeasurementsUserContext } from "src/app/components/Measurements/MeasurementsUserContent.component";
import EventRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/EventRecordingOptionConfiguration.component";
import Input from "src/app/components/Form/Input.component";
import { Button, Card } from "flowbite-react";
import { isOptionSelected } from "src/app/utils/helpers";
import { EnumOptionsSetting } from "src/app/types/util.types";
import { isChannelsDiff, mapCurrentConfig } from "src/app/utils/configuration";
import RelayChannelChooser from "src/app/components/MeasurementsUser/Generic/RelayChannelChooser.component";
import RelayActionSelect from "src/app/components/MeasurementsUser/Generic/RelayActionSelect.component";
import { isEmptyArray, isNotNull } from "src/app/utils/typeguards";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";
import classNames from "classnames";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsMeasurementsUser: SettingsMeasurementsUser
	};

export type CurrentRmsForm = {
	cfgCurrentRmsRecOpt: number
	cfgCurrentRmsRecOptOptions: EnumOptionsSetting<RecordingOption>["options"]
	cfgCurrentRmsEventThresholdMax: string
	cfgCurrentRmsEventThresholdMaxMin: number
	cfgCurrentRmsEventThresholdMaxMax: number
	cfgCurrentRmsEventThresholdMaxCh4: string
	cfgCurrentRmsEventThresholdMaxCh4Min: number
	cfgCurrentRmsEventThresholdMaxCh4Max: number
	cfgCurrentRmsEventThresholdMaxCh5: string
	cfgCurrentRmsEventThresholdMaxCh5Min: number
	cfgCurrentRmsEventThresholdMaxCh5Max: number
	cfgCurrentChannels: ("cfgRelayCurrent1RecOpt" | "cfgRelayCurrent2RecOpt" | "cfgRelayCurrent3RecOpt" | "cfgRelayCurrent4RecOpt" | "cfgRelayCurrent5RecOpt")[]
	cfgCurrentActions: number
}

const validator: FormValidator<CurrentRmsForm> = {
	cfgCurrentRmsRecOpt: () => null,
	cfgCurrentRmsRecOptOptions: () => null,
	cfgCurrentRmsEventThresholdMax: (cfgCurrentRmsEventThresholdMax, optional, form) => {
		if (!isOptionSelected(form.cfgCurrentRmsRecOpt.value, RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE, form.cfgCurrentRmsRecOptOptions.value)) return null;

		return validateNumberField(i18n.t("MEASUREMENTS.max L"), cfgCurrentRmsEventThresholdMax, optional, "he", { from: form.cfgCurrentRmsEventThresholdMaxMin.value, to: form.cfgCurrentRmsEventThresholdMaxMax.value, decimalPlaces: 2 });
	},
	cfgCurrentRmsEventThresholdMaxMin: () => null,
	cfgCurrentRmsEventThresholdMaxMax: () => null,
	cfgCurrentRmsEventThresholdMaxCh4: (cfgCurrentRmsEventThresholdMaxCh4, optional, form) => {
		if (!isOptionSelected(form.cfgCurrentRmsRecOpt.value, RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE, form.cfgCurrentRmsRecOptOptions.value)) return null;

		return validateNumberField(i18n.t("MEASUREMENTS.max N"), cfgCurrentRmsEventThresholdMaxCh4, optional, "he", { from: form.cfgCurrentRmsEventThresholdMaxCh4Min.value, to: form.cfgCurrentRmsEventThresholdMaxCh4Max.value, decimalPlaces: 2 });
	},
	cfgCurrentRmsEventThresholdMaxCh4Min: () => null,
	cfgCurrentRmsEventThresholdMaxCh4Max: () => null,
	cfgCurrentRmsEventThresholdMaxCh5: (cfgCurrentRmsEventThresholdMaxCh5, optional, form) => {
		if (!isOptionSelected(form.cfgCurrentRmsRecOpt.value, RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE, form.cfgCurrentRmsRecOptOptions.value)) return null;

		return validateNumberField(i18n.t("MEASUREMENTS.max E"), cfgCurrentRmsEventThresholdMaxCh5, optional, "he", { from: form.cfgCurrentRmsEventThresholdMaxCh5Min.value, to: form.cfgCurrentRmsEventThresholdMaxCh5Max.value, decimalPlaces: 2 });
	},
	cfgCurrentRmsEventThresholdMaxCh5Min: () => null,
	cfgCurrentRmsEventThresholdMaxCh5Max: () => null,
	cfgCurrentChannels: () => null,
	cfgCurrentActions: () => null,
};

const ID = "current-rms";

function CurrentRmsCard(props: Props) {

	const { t } = useTranslation();

	const {
		settingsMeasurementsUser: {
			cfgCurrentRms,
		},
		isAdmin,
	} = props;

	const {
		form: reducerForm,
		handleChange: reducerHandleChange,
	} = useReducerForm(
		"buffor",
		bufforFormActions,
		() => null,
	);

	const { cfgUserEnable } = useContext(MeasurementsUserContext);

	const _handleSubmit = (values: CurrentRmsForm) => {
		reducerHandleChange("cfgCurrentRmsRecOpt", values.cfgCurrentRmsRecOpt);
		reducerHandleChange("cfgCurrentRmsEventThresholdMax", values.cfgCurrentRmsEventThresholdMax);
		reducerHandleChange("cfgCurrentRmsEventThresholdMaxCh4", values.cfgCurrentRmsEventThresholdMaxCh4);
		reducerHandleChange("cfgCurrentRmsEventThresholdMaxCh5", values.cfgCurrentRmsEventThresholdMaxCh5);
		reducerHandleChange("cfgCurrentChannels", values.cfgCurrentChannels);
		reducerHandleChange("cfgCurrentActions", values.cfgCurrentActions);
	};

	const _getInitialState = () => {
		const [ cfgCurrentChannels, cfgCurrentActions ] = mapCurrentConfig(cfgCurrentRms);
		return {
			cfgCurrentRmsRecOpt: createFormField((reducerForm.cfgCurrentRmsRecOpt.value !== reducerForm.cfgCurrentRmsRecOpt.initialValue) ? reducerForm.cfgCurrentRmsRecOpt.value : cfgCurrentRms?.cfgCurrentRmsRecOpt?.value ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgCurrentRmsRecOptOptions: createFormField(cfgCurrentRms?.cfgCurrentRmsRecOpt?.options ?? []),
			cfgCurrentRmsEventThresholdMax: createFormField((reducerForm.cfgCurrentRmsEventThresholdMax.value !== reducerForm.cfgCurrentRmsEventThresholdMax.initialValue) ? reducerForm.cfgCurrentRmsEventThresholdMax.value : cfgCurrentRms?.cfgCurrentRmsEventThresholdMax?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
			cfgCurrentRmsEventThresholdMaxMin: createFormField(cfgCurrentRms?.cfgCurrentRmsEventThresholdMax?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgCurrentRmsEventThresholdMaxMax: createFormField(cfgCurrentRms?.cfgCurrentRmsEventThresholdMax?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgCurrentRmsEventThresholdMaxCh4: createFormField((reducerForm.cfgCurrentRmsEventThresholdMaxCh4.value !== reducerForm.cfgCurrentRmsEventThresholdMaxCh4.initialValue) ? reducerForm.cfgCurrentRmsEventThresholdMaxCh4.value : cfgCurrentRms?.cfgCurrentRmsEventThresholdMaxCh4?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
			cfgCurrentRmsEventThresholdMaxCh4Min: createFormField(cfgCurrentRms?.cfgCurrentRmsEventThresholdMaxCh4?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgCurrentRmsEventThresholdMaxCh4Max: createFormField(cfgCurrentRms?.cfgCurrentRmsEventThresholdMaxCh4?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgCurrentRmsEventThresholdMaxCh5: createFormField((reducerForm.cfgCurrentRmsEventThresholdMaxCh5.value !== reducerForm.cfgCurrentRmsEventThresholdMaxCh5.initialValue) ? reducerForm.cfgCurrentRmsEventThresholdMaxCh5.value : cfgCurrentRms?.cfgCurrentRmsEventThresholdMaxCh5?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
			cfgCurrentRmsEventThresholdMaxCh5Min: createFormField(cfgCurrentRms?.cfgCurrentRmsEventThresholdMaxCh5?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgCurrentRmsEventThresholdMaxCh5Max: createFormField(cfgCurrentRms?.cfgCurrentRmsEventThresholdMaxCh5?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgCurrentChannels: createFormField(isChannelsDiff(reducerForm.cfgCurrentChannels) ? reducerForm.cfgCurrentChannels.value : cfgCurrentChannels, { disabled: !isAdmin || !cfgUserEnable }),
			cfgCurrentActions: createFormField((reducerForm.cfgCurrentActions.value !== reducerForm.cfgCurrentActions.initialValue) ? reducerForm.cfgCurrentActions.value : cfgCurrentActions, { disabled: !isAdmin || !cfgUserEnable }),
		};
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ cfgCurrentRms ]);

	useEffect(() => {
		toggleDisable("cfgCurrentRmsRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgCurrentRmsEventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgCurrentRmsEventThresholdMaxCh4", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgCurrentRmsEventThresholdMaxCh5", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgCurrentChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgCurrentActions", !cfgUserEnable || !isAdmin);
	}, [ cfgUserEnable ]);

	useEffect(() => {
		toggleDisable("cfgCurrentRmsRecOpt", false);
		toggleDisable("cfgCurrentRmsEventThresholdMax", false);
		toggleDisable("cfgCurrentRmsEventThresholdMaxCh4", false);
		toggleDisable("cfgCurrentRmsEventThresholdMaxCh5", false);
		toggleDisable("cfgCurrentChannels", false);
		toggleDisable("cfgCurrentActions", false);

		handleChange("cfgCurrentRmsRecOpt", reducerForm.cfgCurrentRmsRecOpt.value);
		handleChange("cfgCurrentRmsEventThresholdMax", reducerForm.cfgCurrentRmsEventThresholdMax.value);
		handleChange("cfgCurrentRmsEventThresholdMaxCh4", reducerForm.cfgCurrentRmsEventThresholdMaxCh4.value);
		handleChange("cfgCurrentRmsEventThresholdMaxCh5", reducerForm.cfgCurrentRmsEventThresholdMaxCh5.value);
		handleChange("cfgCurrentChannels", reducerForm.cfgCurrentChannels.value);
		handleChange("cfgCurrentActions", reducerForm.cfgCurrentActions.value);

		toggleDisable("cfgCurrentRmsRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgCurrentRmsEventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgCurrentRmsEventThresholdMaxCh4", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgCurrentRmsEventThresholdMaxCh5", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgCurrentChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgCurrentActions", !cfgUserEnable || !isAdmin);
	}, [
		reducerForm.cfgCurrentRmsRecOpt.value,
		reducerForm.cfgCurrentRmsEventThresholdMax.value,
		reducerForm.cfgCurrentRmsEventThresholdMaxCh4.value,
		reducerForm.cfgCurrentRmsEventThresholdMaxCh5.value,
		reducerForm.cfgCurrentChannels.value,
		reducerForm.cfgCurrentActions.value,
	]);
	return (
		<Card className="bg-gray-50">
			<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
				{ t("MEASUREMENTS.phase current") }
			</h5>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<BasicRecordingOptionConfiguration
					id={ ID }
					options={ cfgCurrentRms?.cfgCurrentRmsRecOpt?.options ?? [] }
					formItem={ form.cfgCurrentRmsRecOpt }
					handleChange={ value => handleChange("cfgCurrentRmsRecOpt", value) }
					reducerFormItem={ reducerForm.cfgCurrentRmsRecOpt }
				/>
				<div className="flex flex-col gap-3">
					<h5 className="text-base sm:text-xl font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
						{ t("MEASUREMENTS.events") }
					</h5>
					<EventRecordingOptionConfiguration
						id={ ID }
						options={ cfgCurrentRms?.cfgCurrentRmsRecOpt?.options ?? [] }
						formItem={ form.cfgCurrentRmsRecOpt }
						handleChange={ value => handleChange("cfgCurrentRmsRecOpt", value) }
						reducerFormItem={ reducerForm.cfgCurrentRmsRecOpt }
					>
						{
							(isLogEventEnabled) =>
								<div className="flex flex-col gap-2.5">
									<h5 className="text-sm sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
										{ t("MEASUREMENTS.thresholds") }
									</h5>
									<hr className="mx-2"/>
									<div
										className={
											classNames(
												"flex gap-2.5 flex-1",
												"2xl:flex-row",
												"flex-col",
											)
										}
									>
										<div className="flex flex-[2_1_0%] gap-2.5 items-center">
											<Input
												className="flex-1"
												formItem={ form.cfgCurrentRmsEventThresholdMax }
												label={ t("MEASUREMENTS.max L") }
												name="cfgCurrentRmsEventThresholdMax"
												inputProps={ {
													type: "number",
													onChange: (e) => handleChange("cfgCurrentRmsEventThresholdMax", e.target.value),
													onBlur: () => handleBlur("cfgCurrentRmsEventThresholdMax"),
													disabled: !isLogEventEnabled,
													sizing: "sm",
													className: "[&>span]:!min-w-[40px] [&>span]:justify-center",
												} }
												addonRight={ cfgCurrentRms?.cfgCurrentRmsEventThresholdMax?.unit ?? "-" }
												hasBeenChanged={ reducerForm.cfgCurrentRmsEventThresholdMax.value !== reducerForm.cfgCurrentRmsEventThresholdMax.initialValue }
											/>
											<Input
												className="flex-1"
												formItem={ form.cfgCurrentRmsEventThresholdMaxCh4 }
												label={ t("MEASUREMENTS.max N") }
												name="cfgCurrentRmsEventThresholdMaxCh4"
												inputProps={ {
													type: "number",
													onChange: (e) => handleChange("cfgCurrentRmsEventThresholdMaxCh4", e.target.value),
													onBlur: () => handleBlur("cfgCurrentRmsEventThresholdMaxCh4"),
													disabled: !isLogEventEnabled,
													sizing: "sm",
													className: "[&>span]:!min-w-[40px] [&>span]:justify-center",
												} }
												addonRight={ cfgCurrentRms?.cfgCurrentRmsEventThresholdMaxCh4?.unit ?? "-" }
												hasBeenChanged={ reducerForm.cfgCurrentRmsEventThresholdMaxCh4.value !== reducerForm.cfgCurrentRmsEventThresholdMaxCh4.initialValue }
											/>
											<Input
												className="flex-1"
												formItem={ form.cfgCurrentRmsEventThresholdMaxCh5 }
												label={ t("MEASUREMENTS.max E") }
												name="cfgCurrentRmsEventThresholdMaxCh5"
												inputProps={ {
													type: "number",
													onChange: (e) => handleChange("cfgCurrentRmsEventThresholdMaxCh5", e.target.value),
													onBlur: () => handleBlur("cfgCurrentRmsEventThresholdMaxCh5"),
													disabled: !isLogEventEnabled,
													sizing: "sm",
													className: "[&>span]:!min-w-[40px] [&>span]:justify-center",
												} }
												addonRight={ cfgCurrentRms?.cfgCurrentRmsEventThresholdMaxCh5?.unit ?? "-" }
												hasBeenChanged={ reducerForm.cfgCurrentRmsEventThresholdMaxCh5.value !== reducerForm.cfgCurrentRmsEventThresholdMaxCh5.initialValue }
											/>
										</div>
										<div
											className={
												classNames(
													"flex-[3_1_0%] items-center flex gap-2",
												)
											}
										>
											<RelayChannelChooser
												options={ [
													(isNotNull(cfgCurrentRms) && isNotNull(cfgCurrentRms.relayChannelLabel) && isNotNull(cfgCurrentRms.relayChannelLabel.ch1)) ? {
														value: "cfgRelayCurrent1RecOpt",
														label: cfgCurrentRms.relayChannelLabel.ch1,
													} : null,
													(isNotNull(cfgCurrentRms) && isNotNull(cfgCurrentRms.relayChannelLabel) && isNotNull(cfgCurrentRms.relayChannelLabel.ch2)) ? {
														value: "cfgRelayCurrent2RecOpt",
														label: cfgCurrentRms.relayChannelLabel.ch2,
													} : null,
													(isNotNull(cfgCurrentRms) && isNotNull(cfgCurrentRms.relayChannelLabel) && isNotNull(cfgCurrentRms.relayChannelLabel.ch3)) ? {
														value: "cfgRelayCurrent3RecOpt",
														label: cfgCurrentRms.relayChannelLabel.ch3,
													} : null,
													(isNotNull(cfgCurrentRms) && isNotNull(cfgCurrentRms.relayChannelLabel) && isNotNull(cfgCurrentRms.relayChannelLabel.ch4)) ? {
														value: "cfgRelayCurrent4RecOpt",
														label: cfgCurrentRms.relayChannelLabel.ch4,
													} : null,
													(isNotNull(cfgCurrentRms) && isNotNull(cfgCurrentRms.relayChannelLabel) && isNotNull(cfgCurrentRms.relayChannelLabel.ch5)) ? {
														value: "cfgRelayCurrent5RecOpt",
														label: cfgCurrentRms.relayChannelLabel.ch5,
													} : null,
												].filter(isNotNull) }
												disabled={ !isLogEventEnabled }
												formItem={ form.cfgCurrentChannels }
												reducerFormItem={ reducerForm.cfgCurrentChannels }
												handleChange={ value => handleChange("cfgCurrentChannels", value) }
											/>
											<RelayActionSelect
												formItem={ form.cfgCurrentActions }
												reducerFormItem={ reducerForm.cfgCurrentActions }
												options={ cfgCurrentRms?.cfgRelayCurrent1Map?.options ?? [] }
												disabled={ !isLogEventEnabled || isEmptyArray(form.cfgCurrentChannels.value) }
												handleChange={ value => handleChange("cfgCurrentActions", value) }
											/>
										</div>
									</div>
									<hr className="mx-2"/>
								</div>
						}
					</EventRecordingOptionConfiguration>
				</div>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(CurrentRmsCard);
