import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { handleBasicActions, initialStateReducer } from "src/app/utils/redux";
import { StateReducer } from "src/app/types/redux.types";
import { SettingsFtp, SettingsIec, SettingsIo, SettingsMeasurementsStandard, SettingsMeasurementsUser, SettingsMemory, SettingsModbus, SettingsRecording, SettingsTime } from "src/app/types/api/settings.types";
import { fetchSettingsFtpAsync, fetchSettingsIecAsync, fetchSettingsIoAsync, fetchSettingsMeasurementsStandardAsync, fetchSettingsMeasurementsUserAsync, fetchSettingsMemoryAsync, fetchSettingsModbusAsync, fetchSettingsRecordingAsync, fetchSettingsTimeAsync } from "src/app/store/features/settings/settings.actions";
import { forgetSession } from "src/app/store/features/user/user.actions";

const reducer = combineReducers({
	settingsRecording: createReducer(initialStateReducer as StateReducer<SettingsRecording>)
		.handleAction([ fetchSettingsRecordingAsync.request, fetchSettingsRecordingAsync.success, fetchSettingsRecordingAsync.failure ], handleBasicActions(fetchSettingsRecordingAsync))
		.handleAction([ forgetSession ], () => initialStateReducer),
	settingsMemory: createReducer(initialStateReducer as StateReducer<SettingsMemory>)
		.handleAction([ fetchSettingsMemoryAsync.request, fetchSettingsMemoryAsync.success, fetchSettingsMemoryAsync.failure ], handleBasicActions(fetchSettingsMemoryAsync))
		.handleAction([ forgetSession ], () => initialStateReducer),
	settingsModbus: createReducer(initialStateReducer as StateReducer<SettingsModbus>)
		.handleAction([ fetchSettingsModbusAsync.request, fetchSettingsModbusAsync.success, fetchSettingsModbusAsync.failure ], handleBasicActions(fetchSettingsModbusAsync))
		.handleAction([ forgetSession ], () => initialStateReducer),
	settingsFtp: createReducer(initialStateReducer as StateReducer<SettingsFtp>)
		.handleAction([ fetchSettingsFtpAsync.request, fetchSettingsFtpAsync.success, fetchSettingsFtpAsync.failure ], handleBasicActions(fetchSettingsFtpAsync))
		.handleAction([ forgetSession ], () => initialStateReducer),
	settingsTime: createReducer(initialStateReducer as StateReducer<SettingsTime>)
		.handleAction([ fetchSettingsTimeAsync.request, fetchSettingsTimeAsync.success, fetchSettingsTimeAsync.failure ], handleBasicActions(fetchSettingsTimeAsync))
		.handleAction([ forgetSession ], () => initialStateReducer),
	settingsMeasurementsUser: createReducer(initialStateReducer as StateReducer<SettingsMeasurementsUser>)
		.handleAction([ fetchSettingsMeasurementsUserAsync.request, fetchSettingsMeasurementsUserAsync.success, fetchSettingsMeasurementsUserAsync.failure ], handleBasicActions(fetchSettingsMeasurementsUserAsync))
		.handleAction([ forgetSession ], () => initialStateReducer),
	settingsMeasurementsStandard: createReducer(initialStateReducer as StateReducer<SettingsMeasurementsStandard>)
		.handleAction([ fetchSettingsMeasurementsStandardAsync.request, fetchSettingsMeasurementsStandardAsync.success, fetchSettingsMeasurementsStandardAsync.failure ], handleBasicActions(fetchSettingsMeasurementsStandardAsync))
		.handleAction([ forgetSession ], () => initialStateReducer),
	settingsIo: createReducer(initialStateReducer as StateReducer<SettingsIo>)
		.handleAction([ fetchSettingsIoAsync.request, fetchSettingsIoAsync.success, fetchSettingsIoAsync.failure ], handleBasicActions(fetchSettingsIoAsync))
		.handleAction([ forgetSession ], () => initialStateReducer),
	settingsIec: createReducer(initialStateReducer as StateReducer<SettingsIec>)
		.handleAction([ fetchSettingsIecAsync.request, fetchSettingsIecAsync.success, fetchSettingsIecAsync.failure ], handleBasicActions(fetchSettingsIecAsync))
		.handleAction([ forgetSession ], () => initialStateReducer),
});

export default reducer;
