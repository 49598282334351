import { Enum, EnumOptionsSetting } from "src/app/types/util.types";
import { FormItem } from "src/app/types/ui/form.types";
import { Checkbox, Label } from "flowbite-react";
import { getOptionMask, isOptionsContainsOption, isOptionSelected } from "src/app/utils/helpers";
import classNames from "classnames";
import { isEmptyArray } from "src/app/utils/typeguards";

type Props<T extends Enum> = {
	id: string
	formItem: FormItem<number>
	label: string
	options: EnumOptionsSetting<T>["options"]
	option: T
	handleChange: (value: number) => void
	hasBeenChanged?: boolean
	dependingOn?: T[]
	disabled?: boolean
	className?: string
};

function EnumOptionsCheckbox<T extends Enum>(props: Props<T>) {

	const {
		id,
		formItem,
		label,
		options,
		option,
		handleChange,
		hasBeenChanged = false,
		dependingOn = [],
		disabled = false,
		className,
	} = props;

	if (!isOptionsContainsOption(option, options)) return null;

	const isSelected = isOptionSelected(formItem.value, option, options);

	const isDisabled = !isEmptyArray(dependingOn) && !options.filter(settingOption => dependingOn.includes(settingOption.text)).every(settingOption => isOptionSelected(formItem.value, settingOption.text, options));

	return (
		<div
			className={
				classNames(
					"flex items-center gap-x-2",
					className,
				)
			}
		>
			<Checkbox
				className={
					classNames(
						"my-auto relative",
						{ "text-yellow-400": hasBeenChanged },
					)
				}
				id={ id }
				name={ id }
				checked={ isSelected }
				disabled={ isDisabled || formItem.disabled || disabled }
				onChange={ () => {
					const mask = getOptionMask(option, options) ?? 0;
					if (isSelected) {
						handleChange(formItem.value - mask);
					} else {
						handleChange(formItem.value + mask);
					}
				} }
			/>
			<Label
				htmlFor={ id }
				disabled={ isDisabled || formItem.disabled || disabled }
				className={
					classNames(
						"cursor-pointer",
						{ "text-yellow-400": hasBeenChanged },
					)
				}
			>
				{ label }
			</Label>
		</div>
	);
}

export default (EnumOptionsCheckbox);
