import { RootState } from "src/app/store/root.reducer";
import { createSelector } from "reselect";
import { mergeThreeStateReducers, mergeTwoStateReducers } from "src/app/utils/redux";
import { DataState } from "src/app/types/redux.types";
import { OnOff } from "src/app/types/api/settings.types";
import moment from "moment";

const settingsRecordingSelector = (state: RootState) => state.settings.settingsRecording;
const settingsMemorySelector = (state: RootState) => state.settings.settingsMemory;
const settingsModbusSelector = (state: RootState) => state.settings.settingsModbus;
const settingsFtpSelector = (state: RootState) => state.settings.settingsFtp;
const settingsTimeSelector = (state: RootState) => state.settings.settingsTime;
const settingsMeasurementsUserSelector = (state: RootState) => state.settings.settingsMeasurementsUser;
const settingsMeasurementsStandardSelector = (state: RootState) => state.settings.settingsMeasurementsStandard;
const settingsIoSelector = (state: RootState) => state.settings.settingsIo;
const settingsIecSelector = (state: RootState) => state.settings.settingsIec;

export const getCommunicationsSettings = createSelector(
	[
		settingsModbusSelector,
		settingsIecSelector,
	],
	(modbus, iec) => mergeTwoStateReducers(modbus, iec, (modbus, iec) => ({ modbus, iec })),
);

export const getMeasurementsSettings = createSelector(
	[
		settingsMeasurementsStandardSelector,
		settingsMeasurementsUserSelector,
	],
	(standard, user) => mergeTwoStateReducers(
		standard,
		user,
		(standard, user) => ({
			standard,
			user,
		}),
	),
);

export const getRtcUtcTimestampDiffSeconds = createSelector(
	[
		settingsTimeSelector,
	],
	(settingsTime): number => {
		if (settingsTime.dataState !== DataState.PRESENT) return 0;

		return (settingsTime.data.time?.rtcUtcTimestamp ?? moment().unix()) - settingsTime.fetchedAt.unix();
	},
);

export const getRtcLocalTimestampDiffSeconds = createSelector(
	[
		settingsTimeSelector,
	],
	(settingsTime): number => {
		if (settingsTime.dataState !== DataState.PRESENT) return 0;

		const isDaylightTime = (settingsTime.data.time?.cfgDaylightSavingsTime?.enum?.find(enumOption => enumOption.value === settingsTime.data.time?.cfgDaylightSavingsTime?.value)?.text ?? OnOff.OFF) === OnOff.ON;

		return (settingsTime.data.time?.rtcUtcTimestamp ?? moment().unix()) - settingsTime.fetchedAt.unix() + (settingsTime.data.time?.cfgTimezone?.value ?? 0) * 60 + (isDaylightTime ? 3600 : 0);
	},
);

export const getSettings = createSelector(
		[
			settingsRecordingSelector,
			settingsMemorySelector,
			settingsModbusSelector,
			settingsFtpSelector,
			settingsTimeSelector,
			settingsMeasurementsUserSelector,
			settingsMeasurementsStandardSelector,
			settingsIoSelector,
			settingsIecSelector,
		],
		(
			settingsRecording, settingsMemory, settingsModbus,
			settingsFtp, settingsTime, settingsMeasurementsUser,
			settingsMeasurementsStandard, settingsIo, settingsIec,
		) =>
			mergeThreeStateReducers(
				mergeThreeStateReducers(
					settingsRecording,
					settingsMemory,
					settingsModbus,
					(settingsRecording, settingsMemory, settingsModbus) => ({
						settingsRecording, settingsMemory, settingsModbus,
					}),
				),
				mergeThreeStateReducers(
					settingsFtp,
					settingsTime,
					settingsMeasurementsUser,
					(settingsFtp, settingsTime, settingsMeasurementsUser) => ({
						settingsFtp, settingsTime, settingsMeasurementsUser,
					}),
				),
				mergeThreeStateReducers(
					settingsMeasurementsStandard,
					settingsIo,
					settingsIec,
					(settingsMeasurementsStandard, settingsIo, settingsIec) => ({
						settingsMeasurementsStandard, settingsIo, settingsIec,
					}),
				),
				(
					{ settingsRecording, settingsMemory, settingsModbus },
					{ settingsFtp, settingsTime, settingsMeasurementsUser },
					{ settingsMeasurementsStandard, settingsIo, settingsIec },
				) =>
					({
						settingsRecording, settingsMemory, settingsModbus,
						settingsFtp, settingsTime, settingsMeasurementsUser,
						settingsMeasurementsStandard, settingsIo, settingsIec,
					}),
			),
	)
;
