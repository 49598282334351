import { FirmwareInfo as FirmwareInfoType } from "src/app/types/api/firmware.types";
import { Button, Card, Tooltip } from "flowbite-react";
import LabelValue from "src/app/components/Utils/LabelValue.component";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import ConfirmModal from "src/app/components/Utils/ConfirmModal.component";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { isEmptyString } from "src/app/utils/typeguards";
import { SnackbarMessageType } from "src/app/types/ui/message.types";
import SnackbarComponent from "src/app/components/Message/Snackbar.component";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		firmware: FirmwareInfoType
		removeFirmware: () => void
		upgradeFirmware: () => void
	};

function FirmwareInfo(props: Props) {

	const { t } = useTranslation();

	const {
		firmware,
		removeFirmware,
		upgradeFirmware,
		isRemoving,
		isUpgrading,
	} = props;

	const [ isRemoveModalVisible, toggleRemoveModal ] = useState(false);
	const [ isUpgradeModalVisible, toggleUpgradeModal ] = useState(false);

	const isNewerVersion = +firmware.firmware.cfgFirmwareVersion.value < +firmware.firmware.newFirmwareVersion.value;

	return (
		<>
			<Card className="[&>div]:p-3 sm:[&>div]:p-6 [&>div]:gap-1 sm:[&>div]:gap-4">
				<div className="flex flex-col justify-between gap-2">
					<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
						{ t("FIRMWARE.verify firmware") }
					</h5>
					<p>{ t("FIRMWARE.verify firmware instruction") }</p>
				</div>
				{
					isEmptyString(firmware.firmware.newFirmwareVersion.value) &&
                    <SnackbarComponent
                        type={ SnackbarMessageType.ERROR }
                        message={ t("FIRMWARE.invalid firmware file") }
                    />
				}
				<div className="flex flex-col gap-1.5">
					<LabelValue
						label={ t("FIRMWARE.firmware version") }
						value={ firmware.firmware.cfgFirmwareVersion.value }
					/>
					<LabelValue
						label={ t("FIRMWARE.uploaded firmware version") }
						value={ isEmptyString(firmware.firmware.newFirmwareVersion.value) ? "---" : firmware.firmware.newFirmwareVersion.value }
					/>
					<LabelValue
						label={ t("FIRMWARE.uploaded firmware size") }
						value={ `${ Math.round((firmware.firmware.fileSize.value / 1024 / 1000) * 10) / 10 } MB` }
					/>
				</div>
				<div className="flex justify-end gap-2">
					<Button
						color="light"
						onClick={ () => toggleRemoveModal(true) }
						isProcessing={ isRemoving }
					>
						{ t("FIRMWARE.remove file") }
					</Button>
					{
						isNewerVersion ?
							<Button
								color="primary"
								onClick={ () => toggleUpgradeModal(true) }
							>
								{ t("FIRMWARE.upgrade firmware") }
							</Button>
							:
							<Tooltip content={ isEmptyString(firmware.firmware.newFirmwareVersion.value) ? t("FIRMWARE.invalid firmware file") : t("FIRMWARE.cannot upgrade") }>
								<Button
									color="primary"
									disabled
								>
									{ t("FIRMWARE.upgrade firmware") }
								</Button>
							</Tooltip>
					}
				</div>
			</Card>
			<ConfirmModal
				title={ t("FIRMWARE.remove uploaded firmware") }
				warning={ t("FIRMWARE.sure this operation will delete prev file") }
				isOpen={ isRemoveModalVisible }
				isLoading={ isRemoving }
				handleClose={ () => toggleRemoveModal(false) }
				onConfirm={ () => {
					removeFirmware();
					toggleRemoveModal(false);
				} }
			/>
			<ConfirmModal
				title={ t("FIRMWARE.upgrade firmware") }
				warning={ t("FIRMWARE.sure operation will start upgrade process") }
				isOpen={ isUpgradeModalVisible }
				handleClose={ () => toggleUpgradeModal(false) }
				confirmWord={ t("UTILS.upgrade") }
				isLoading={ isUpgrading }
				confirmText={ t("FIRMWARE.upgrade firmware") }
				onConfirm={ () => {
					upgradeFirmware();
					toggleUpgradeModal(false);
				} }
			/>
		</>
	);
}

const mapStateToProps = (state: RootState) => ({
	isRemoving: didLoadingRecordExist(state, { loadableType: LoadableType.REMOVE_FIRMWARE_FILE }),
	isUpgrading: didLoadingRecordExist(state, { loadableType: LoadableType.UPGRADE_FIRMWARE_REQUEST }),
});

export default connect(mapStateToProps)(FirmwareInfo);
