import { FormItem } from "src/app/types/ui/form.types";
import { SelectOption } from "src/app/types/util.types";
import { ClassNamesConfig, GroupBase, StylesConfig } from "react-select";
import classNames from "classnames";
import { isNotNull, isNull } from "src/app/utils/typeguards";

// React-select
export function getSelectClassNames<T, V extends boolean>(
	formItem: FormItem<any>,
	isClearable: boolean,
	hasBeenChanged: boolean,
): ClassNamesConfig<SelectOption<T>, V, GroupBase<SelectOption<T>>> {
	return {
		control: props =>
			classNames(
				"!w-full !border !shadow-none !bg-gray-50 dark:!bg-gray-700 dark:!placeholder-gray-400 !rounded-lg !p-0.5 !text-sm",
				// Focused and unfocused
				{ "!border-gray-300 dark:!border-gray-600": (!props.isFocused && isNull(formItem.error)) },
				{ "!ring-1 !border-primary-500 !ring-primary-500 dark:!border-primary-500 dark:!ring-primary-500": (props.isFocused && isNull(formItem.error) && !hasBeenChanged) },
				{ "!ring-1 !border-yellow-400 !ring-yellow-400 dark:!border-yellow-400 dark:!ring-yellow-400": (props.isFocused && isNull(formItem.error) && hasBeenChanged) },
				// Error
				{ "!bg-red-50 dark:!bg-red-100": isNotNull(formItem.error) },
				{ "!border-red-500 dark:!border-red-400": (!props.isFocused && isNotNull(formItem.error)) },
				{ "!ring-1 !border-red-500 !ring-red-500 dark:!border-red-500 dark:!ring-red-500": (props.isFocused && isNotNull(formItem.error)) },
				// Disabled
				{ "!cursor-not-allowed !opacity-50 !pointer-events-auto": props.isDisabled },
				{ "!border-yellow-500 !bg-yellow-50": hasBeenChanged && isNull(formItem.error) }
			),
		valueContainer: () => "!flex !gap-1",
		menu: () => "!rounded-lg !overflow-hidden !bg-gray-50 dark:!bg-gray-700 !border !border-gray-300 dark:!border-gray-600 !drop-shadow-lg !shadow-none",
		singleValue: () =>
			classNames(
				{ "!text-gray-900 dark:!text-white": isNull(formItem.error) },
				{ "!text-red-500": isNotNull(formItem.error) },
			),
		input: () =>
			classNames(
				"[&>input]:!text-gray-900 [&>input]:!ring-transparent [&>input]:!border-none [&>input]:!shadow-none [&>input]:!outline-none",
				{ "[&>input]:dark:!text-white": isNull(formItem.error) },
			),
		indicatorSeparator: () =>
			classNames(
				"!bg-gray-300",
				{ "hidden": (!isClearable || isNull(formItem.value) || formItem.disabled) },
				{ "dark:!bg-gray-600": isNull(formItem.error) },
			),
		dropdownIndicator: () =>
			classNames(
				"!text-gray-600",
				{ "dark:!text-gray-300": isNull(formItem.error) },
			),
		clearIndicator: () =>
			classNames(
				"cursor-pointer hover:!text-gray-400",
				{ "!text-gray-600 dark:!text-gray-300 dark:hover:!text-gray-500": isNull(formItem.error) },
				{ "!text-gray-500": isNotNull(formItem.error) },
			),
		option: props =>
			classNames(
				"!text-sm !py-1.5",
				{ "!bg-primary-500": props.isSelected },
				{ "dark:!text-white": (!props.isFocused || props.isSelected) },
				{ "!bg-primary-100 dark:!text-black": (props.isFocused && !props.isSelected) },
			),
		noOptionsMessage: () => "!text-gray-900 dark:!text-white",
		multiValue: () =>
			classNames(
				"!m-0 !rounded-lg !bg-gray-200 !overflow-hidden",
				{ "dark:!bg-gray-400": isNull(formItem.error) },
				{ "dark:!bg-gray-300": isNotNull(formItem.error) },
			),
		multiValueLabel: () => "!pl-2 !text-sm",
		multiValueRemove: () => "hover:!bg-red-300 hover:!text-red-600",
	};
}

export function getSelectStyles<T, V extends boolean>(
	optionsHeight: number,
	inputHeight?: number,
): StylesConfig<SelectOption<T>, V, GroupBase<SelectOption<T>>> | undefined {
	return {
		control: provided => ({
			...provided,
			minHeight: isNotNull(inputHeight) ? `${ inputHeight }px` : provided.minHeight,
			height: isNotNull(inputHeight) ? `${ inputHeight }px` : provided.height,
		}),
		menuList: provided => ({
			...provided,
			maxHeight: optionsHeight,
		}),
		indicatorsContainer: provided => ({
			...provided,
			height: isNotNull(inputHeight) ? `${ inputHeight - 6 }px` : provided.height,
		}),
		valueContainer: provided => ({
			...provided,
			height: isNotNull(inputHeight) ? `${ inputHeight - 6 }px` : provided.height,
		}),
	};
}

export function getMultiSelectStyles<T, V extends boolean>(
	optionsHeight: number,
	inputHeight?: number,
): StylesConfig<SelectOption<T>, V, GroupBase<SelectOption<T>>> | undefined {
	return {
		control: provided => ({
			...provided,
			minHeight: isNotNull(inputHeight) ? `${ inputHeight }px` : provided.minHeight,
		}),
		menuList: provided => ({
			...provided,
			maxHeight: optionsHeight,
		}),
		indicatorsContainer: provided => ({
			...provided,
			height: isNotNull(inputHeight) ? `${ inputHeight - 6 }px` : provided.height,
		}),
	};
}