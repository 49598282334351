import { Timezone } from "src/app/types/api/settings.types";
import { Form, FormValidator } from "src/app/types/ui/form.types";
import { createFormField } from "src/app/utils/forms";
import { VoltageRmsForm } from "src/app/components/MeasurementsUser/VoltageRmsCard.component";
import { FrequencyForm } from "src/app/components/MeasurementsUser/FrequencyCard.component";
import { FlicketPstForm } from "src/app/components/MeasurementsUser/FlickerPstCard.component";
import { FlickerPltForm } from "src/app/components/MeasurementsUser/FlickerPltCard.component";
import { CurrentRmsForm } from "src/app/components/MeasurementsUser/CurrentRmsCard.component";
import { ReactivePowerForm } from "src/app/components/MeasurementsUser/ReactivePowerCard.component";
import { ApparentPowerForm } from "src/app/components/MeasurementsUser/ApparentPowerCard.component";
import { ActivePowerPlusForm } from "src/app/components/MeasurementsUser/ActivePowerPlusCard.component";
import { ActivePowerMinusForm } from "src/app/components/MeasurementsUser/ActivePowerMinusCard.component";
import { DisplacementPowerFactorForm } from "src/app/components/MeasurementsUser/DisplacementPowerFactorCard.component";
import { PowerFactorForm } from "src/app/components/MeasurementsUser/PowerFactorCard.component";
import { VoltageThdfForm } from "src/app/components/MeasurementsUser/VoltageThdfCard.component";
import { CurrentThdfForm } from "src/app/components/MeasurementsUser/CurrentThdfCard.component";

export type BufforReducerForm = {
	// Recording General
	type: number
	nominalFrequency: number
	nominalVoltage: string
	unpeChannelEnable: boolean
	voltageTransducerRatio: string
	ch4Enabled: boolean
	ch5Enabled: boolean
	currentCh1TransducerRatio: string
	currentCh2TransducerRatio: string
	currentCh3TransducerRatio: string
	currentCh4TransducerRatio: string
	currentCh5TransducerRatio: string
	phaseRotation: number

	// Recording Events
	eventsHysteresis: string
	waveformFormat: number
	waveformRecDuration: string
	waveformPretriggerDuration: string
	rms12RecTime: string
	rms12PretriggerTime: string

	// Memory
	userRecordingPerc: string
	stdRecordingPerc: string
	energyCountersPerc: string
	userDataModel: number
	stdDataModel: number
	energyDataModel: number

	// Modbus TCP
	tcpEnabled: boolean
	tcpSlaveAddress: string
	tcpPort: string
	tcpEndianness: number

	// Modbus RTU
	rtuEnabled: boolean
	rtuSlaveAddress: string
	rtuBaudrate: number
	rtuParity: number
	rtuEndianness: number

	// FTP
	ftpEnable: boolean
	ftpConnectionType: number
	ftpMode: number
	ftpUploadTime: number
	ftpServerIp: string
	ftpServerPort: string
	ftpUserName: string
	ftpPassword: string
	ftpDestDirectory: string

	// Time
	rtcUtcTimestamp: string
	timeSyncMode: number
	timezone: Timezone
	daylightSavingsTime: boolean
	NTPEnable: boolean
	NTPServer1Name: string
	NTPServer2Name: string
	NTPServer3Name: string
	NTPServer4Name: string

	// Measurements -> User
	cfgUserEnable: boolean
	cfgAggregationPeriodUser: number
	cfgUserAggregationWaveformEnable: boolean
	cfgPowerCalculationMethod: number

	// Voltage -> Basic
	cfgVoltageRmsRecOpt: number
	cfgVoltageRmsEventThresholdUserDip: string
	cfgVoltageRmsEventThresholdUserSwell: string
	cfgVoltageRmsEventThresholdUserInterruption: string
	cfgVoltageRmsEventThresholdMaxCh4: string
	cfgDipChannels: VoltageRmsForm["cfgDipChannels"]
	cfgDipActions: number
	cfgSwellChannels: VoltageRmsForm["cfgSwellChannels"]
	cfgSwellActions: number
	cfgInterruptChannels: VoltageRmsForm["cfgInterruptChannels"]
	cfgInterruptActions: number

	cfgVoltageDcRecOpt: number

	cfgWaveshapeVariationsRecOpt: number
	cfgWaveshapeVariationsDeadtime: string
	cfgWaveshapeVariationsThreshold: string
	cfgWaveshapeVariationsChannels: VoltageRmsForm["cfgWaveshapeVariationsChannels"]
	cfgWaveshapeVariationsActions: number

	cfgPhaseJumpsRecOpt: number
	cfgPhaseJumpsThreshold: string
	cfgPhaseJumpsChannels: VoltageRmsForm["cfgPhaseJumpsChannels"]
	cfgPhaseJumpsActions: number

	// Voltage -> Additional
	cfgFrequencyRecOpt: number
	cfgFrequencyEventThresholdMin: string
	cfgFrequencyEventThresholdMax: string
	cfgFrequencyChannels: FrequencyForm["cfgFrequencyChannels"]
	cfgFrequencyActions: number

	cfgVoltageCrestFactorRecOpt: number

	cfgVoltageUnbalanceRecOpt: number
	cfgVoltageUnbalanceThresholdMax: string

	cfgFlickerPstRecOpt: number
	cfgFlickerPstThresholdMax: string
	cfgFlickerPstChannels: FlicketPstForm["cfgFlickerPstChannels"]
	cfgFlickerPstActions: number

	cfgFlickerPltRecOpt: number
	cfgFlickerPltThresholdMax: string
	cfgFlickerPltChannels: FlickerPltForm["cfgFlickerPltChannels"]
	cfgFlickerPltActions: number

	// Current -> Basic
	cfgCurrentRmsRecOpt: number
	cfgCurrentRmsEventThresholdMax: string
	cfgCurrentRmsEventThresholdMaxCh4: string
	cfgCurrentRmsEventThresholdMaxCh5: string
	cfgCurrentChannels: CurrentRmsForm["cfgCurrentChannels"]
	cfgCurrentActions: number

	cfgCurrentCrestFactorRecOpt: number

	cfgCurrentUnbalanceRecOpt: number

	// Power and energy -> Power
	cfgActivePowerRecOpt: number
	cfgActivePowerEventThresholdMax: string

	cfgActivePowerPlusRecOpt: number
	cfgActivePowerPlusEventThresholdMax: string
	cfgActivePowerPlusChannels: ActivePowerPlusForm["cfgActivePowerPlusChannels"]
	cfgActivePowerPlusActions: number

	cfgActivePowerMinusRecOpt: number
	cfgActivePowerMinusEventThresholdMax: string
	cfgActivePowerMinusChannels: ActivePowerMinusForm["cfgActivePowerMinusChannels"]
	cfgActivePowerMinusActions: number

	cfgActivePowerFundamentalRecOpt: number

	cfgApparentPowerRecOpt: number
	cfgApparentPowerEventThresholdMax: string
	cfgApparentPowerChannels: ApparentPowerForm["cfgApparentPowerChannels"]
	cfgApparentPowerActions: number

	cfgApparentPowerFundamentalRecOpt: number

	cfgApparentPowerNonfundamentalRecOpt: number

	cfgDistortionPowerBudeanuRecOpt: number

	cfgReactivePowerRecOpt: number
	cfgReactivePowerEventThresholdMax: string
	cfgReactivePowerChannels: ReactivePowerForm["cfgReactivePowerChannels"]
	cfgReactivePowerActions: number

	// Power and energy -> Additional
	cfgPowerFactorRecOpt: number
	cfgPowerFactorEventThresholdMax: string
	cfgPowerFactorChannels: PowerFactorForm["cfgPowerFactorChannels"]
	cfgPowerFactorActions: number

	cfgPowerFactorFundamentalRecOpt: number

	cfgDisplacementPowerFactorRecOpt: number
	cfgDisplacementPowerFactorEventThresholdMax: string
	cfgDisplacementPowerFactorChannels: DisplacementPowerFactorForm["cfgDisplacementPowerFactorChannels"]
	cfgDisplacementPowerFactorActions: number

	cfgTangent4QRecOpt: number
	// cfgTangentQuadrant1EventThresholdMin: string
	// cfgTangentQuadrant1EventThresholdMax: string
	// cfgTangentQuadrant2EventThresholdMin: string
	// cfgTangentQuadrant2EventThresholdMax: string
	// cfgTangentQuadrant3EventThresholdMin: string
	// cfgTangentQuadrant3EventThresholdMax: string
	// cfgTangentQuadrant4EventThresholdMin: string
	// cfgTangentQuadrant4EventThresholdMax: string

	// Power and energy -> Energy
	cfgActiveEnergyRecOpt: number
	cfgActiveEnergyPlusEventThresholdMax: string
	cfgActiveEnergyMinusEventThresholdMax: string

	cfgApparentEnergyRecOpt: number
	cfgApparentEnergyEventThresholdMax: string

	cfgReactiveEnergy4QRecOpt: number
	cfgReactiveEnergyQuadrant1EventThresholdMax: string
	cfgReactiveEnergyQuadrant2EventThresholdMax: string
	cfgReactiveEnergyQuadrant3EventThresholdMax: string
	cfgReactiveEnergyQuadrant4EventThresholdMax: string

	// Harmonics -> Voltage
	cfgVoltageThdfRecOpt: number
	cfgVoltageThdfThresholdMax: string
	cfgVoltageThdfChannels: VoltageThdfForm["cfgVoltageThdfChannels"]
	cfgVoltageThdfActions: number

	cfgVoltageThdrRecOpt: number

	cfgVoltageHarmonicsRecOpt: number

	// Harmonics -> Current
	cfgCurrentThdfRecOpt: number
	cfgCurrentThdfThresholdMax: string
	cfgCurrentThdfChannels: CurrentThdfForm["cfgCurrentThdfChannels"]
	cfgCurrentThdfActions: number

	cfgCurrentThdrRecOpt: number

	cfgCurrentHarmonicsRecOpt: number

	cfgVoltageInterharmonicsRecOpt: number
	cfgCurrentInterharmonicsRecOpt: number

	cfgKfactorRecOpt: number
	cfgPhasorRecOpt: number

	cfgMainsSignallingUr1RecOpt: number
	cfgMainsSignallingUr1Frequency: string
	cfgMainsSignallingUr2RecOpt: number
	cfgMainsSignallingUr2Frequency: string

	// Harmonics -> Additional
	cfgActivePowerHarmonicsRecOpt: number

	cfgHarmonicsAnglesUiRecOpt: number

	cfgReactivePowerHarmonicsRecOpt: number

	// Interharmonics -> Voltage
	cfgVoltageTidfRecOpt: number

	cfgVoltageTidrRecOpt: number

	// Interharmonics -> Current
	cfgCurrentTidfRecOpt: number

	cfgCurrentTidrRecOpt: number

	// Voltage monitoring
	cfgVoltage29KRecOpt: number
	cfgVoltage8150KRecOpt: number

	// Measurements -> Standard
	cfgStdEnable: boolean
	cfgStdType: number
	cfgStdAggregationWaveformEnable: boolean

	cfgIec61850Enable: boolean
	cfgIec61850IedName: string
	cfgIec61850PhaseVoltageDbRef: string
	cfgIec61850PhaseVoltageDb: string
	cfgIec61850PhaseToPhaseVoltageDbRef: string
	cfgIec61850PhaseToPhaseVoltageDb: string
	cfgIec61850UneVoltageDbRef: string
	cfgIec61850UneVoltageDb: string
	cfgIec61850PhaseCurrentDbRef: string
	cfgIec61850PhaseCurrentDb: string
	cfgIec61850NCurrentDbRef: string
	cfgIec61850NCurrentDb: string
	cfgIec61850ECurrentDbRef: string
	cfgIec61850ECurrentDb: string
	cfgIec61850ActivePowerTotalDbRef: string
	cfgIec61850ActivePowerTotalDb: string
	cfgIec61850ReactivePowerTotalDbRef: string
	cfgIec61850ReactivePowerTotalDb: string
	cfgIec61850ApparentPowerTotalDbRef: string
	cfgIec61850ApparentPowerTotalDb: string
	cfgIec61850ActivePowerPhaseDbRef: string
	cfgIec61850ActivePowerPhaseDb: string
	cfgIec61850ReactivePowerPhaseDbRef: string
	cfgIec61850ReactivePowerPhaseDb: string
	cfgIec61850ApparentPowerPhaseDbRef: string
	cfgIec61850ApparentPowerPhaseDb: string
	cfgIec61850FrequencyDbRef: string
	cfgIec61850FrequencyDb: string
	cfgIec61850VoltageHarmonicsDbRef: string
	cfgIec61850VoltageHarmonicsDb: string
	cfgIec61850CurrentHarmonicsDbRef: string
	cfgIec61850CurrentHarmonicsDb: string
	cfgIec61850VoltageInterharmonicsDbRef: string
	cfgIec61850VoltageInterharmonicsDb: string
	cfgIec61850CurrentInterharmonicsDbRef: string
	cfgIec61850CurrentInterharmonicsDb: string
	cfgIec61850VoltageThdDbRef: string
	cfgIec61850VoltageThdDb: string
	cfgIec61850CurrentThdDbRef: string
	cfgIec61850CurrentThdDb: string
	cfgIec61850VoltageTidDbRef: string
	cfgIec61850VoltageTidDb: string
	cfgIec61850CurrentTidDbRef: string
	cfgIec61850CurrentTidDb: string
	cfgIec61850UnbalanceVoltagePositiveSequenceDbRef: string
	cfgIec61850UnbalanceVoltagePositiveSequenceDb: string
	cfgIec61850UnbalanceVoltageNegativeSequenceDbRef: string
	cfgIec61850UnbalanceVoltageNegativeSequenceDb: string
	cfgIec61850UnbalanceVoltageZeroSequenceDbRef: string
	cfgIec61850UnbalanceVoltageZeroSequenceDb: string
	cfgIec61850UnbalanceCurrentPositiveSequenceDbRef: string
	cfgIec61850UnbalanceCurrentPositiveSequenceDb: string
	cfgIec61850UnbalanceCurrentNegativeSequenceDbRef: string
	cfgIec61850UnbalanceCurrentNegativeSequenceDb: string
	cfgIec61850UnbalanceCurrentZeroSequenceDbRef: string
	cfgIec61850UnbalanceCurrentZeroSequenceDb: string
	cfgIec61850UnbalanceCurrentNegativeDbRef: string
	cfgIec61850UnbalanceCurrentNegativeDb: string
	cfgIec61850FlickerPstDbRef: string
	cfgIec61850FlickerPstDb: string
	cfgIec61850FlickerPltDbRef: string
	cfgIec61850FlickerPltDb: string
	cfgIec6185029KhzVoltageDbRef: string
	cfgIec6185029KhzVoltageDb: string
	cfgIec6185029KhzVoltageMaxDbRef: string
	cfgIec6185029KhzVoltageMaxDb: string
	cfgIec618509150KhzVoltageDbRef: string
	cfgIec618509150KhzVoltageDb: string
	cfgIec618509150KhzVoltageMaxDbRef: string
	cfgIec618509150KhzVoltageMaxDb: string
	cfgIec61850BatteryVoltageDbRef: string
	cfgIec61850BatteryVoltageDb: string

	// Io
	cfgDigitalInputInternal1ActiveState: number
	cfgDigitalInputInternal1RecOpt: number
	cfgDigitalInputInternal2ActiveState: number
	cfgDigitalInputInternal2RecOpt: number
	cfgRelay1ActiveState: number
	cfgRelay1Mode: number
	cfgRelay1ActiveTime: string
	cfgRelay1DeadTime: string
	cfgRelay2ActiveState: number
	cfgRelay2Mode: number
	cfgRelay2ActiveTime: string
	cfgRelay2DeadTime: string
}

export const bufforFormInitialState: Form<BufforReducerForm> = {
	type: createFormField(0),
	nominalFrequency: createFormField(0),
	nominalVoltage: createFormField(""),
	unpeChannelEnable: createFormField(false),
	voltageTransducerRatio: createFormField(""),
	ch4Enabled: createFormField(false),
	ch5Enabled: createFormField(false),
	currentCh1TransducerRatio: createFormField(""),
	currentCh2TransducerRatio: createFormField(""),
	currentCh3TransducerRatio: createFormField(""),
	currentCh4TransducerRatio: createFormField(""),
	currentCh5TransducerRatio: createFormField(""),
	phaseRotation: createFormField(0),

	eventsHysteresis: createFormField(""),
	waveformFormat: createFormField(0),
	waveformRecDuration: createFormField(""),
	waveformPretriggerDuration: createFormField(""),
	rms12RecTime: createFormField(""),
	rms12PretriggerTime: createFormField(""),

	userRecordingPerc: createFormField(""),
	stdRecordingPerc: createFormField(""),
	energyCountersPerc: createFormField(""),
	userDataModel: createFormField(0),
	stdDataModel: createFormField(0),
	energyDataModel: createFormField(0),

	tcpEnabled: createFormField(false),
	tcpSlaveAddress: createFormField(""),
	tcpPort: createFormField(""),
	tcpEndianness: createFormField(0),

	rtuEnabled: createFormField(false),
	rtuSlaveAddress: createFormField(""),
	rtuBaudrate: createFormField(0),
	rtuParity: createFormField(0),
	rtuEndianness: createFormField(0),

	ftpEnable: createFormField(false),
	ftpConnectionType: createFormField(0),
	ftpMode: createFormField(0),
	ftpUploadTime: createFormField(0),
	ftpServerIp: createFormField(""),
	ftpServerPort: createFormField(""),
	ftpUserName: createFormField(""),
	ftpPassword: createFormField(""),
	ftpDestDirectory: createFormField(""),

	rtcUtcTimestamp: createFormField(""),
	timeSyncMode: createFormField(0),
	timezone: createFormField(Timezone.UTC_00),
	daylightSavingsTime: createFormField(false),
	NTPEnable: createFormField(false),
	NTPServer1Name: createFormField(""),
	NTPServer2Name: createFormField(""),
	NTPServer3Name: createFormField(""),
	NTPServer4Name: createFormField(""),

	cfgUserEnable: createFormField(true),
	cfgAggregationPeriodUser: createFormField(0),
	cfgUserAggregationWaveformEnable: createFormField(false),
	cfgPowerCalculationMethod: createFormField(0),

	cfgVoltageRmsRecOpt: createFormField(0),
	cfgVoltageRmsEventThresholdUserDip: createFormField(""),
	cfgVoltageRmsEventThresholdUserSwell: createFormField(""),
	cfgVoltageRmsEventThresholdUserInterruption: createFormField(""),
	cfgVoltageRmsEventThresholdMaxCh4: createFormField(""),
	cfgDipChannels: createFormField([]),
	cfgDipActions: createFormField(0),
	cfgSwellChannels: createFormField([]),
	cfgSwellActions: createFormField(0),
	cfgInterruptChannels: createFormField([]),
	cfgInterruptActions: createFormField(0),
	cfgVoltageDcRecOpt: createFormField(0),
	cfgWaveshapeVariationsRecOpt: createFormField(0),
	cfgWaveshapeVariationsDeadtime: createFormField(""),
	cfgWaveshapeVariationsThreshold: createFormField(""),
	cfgWaveshapeVariationsChannels: createFormField([]),
	cfgWaveshapeVariationsActions: createFormField(0),
	cfgPhaseJumpsRecOpt: createFormField(0),
	cfgPhaseJumpsThreshold: createFormField(""),
	cfgPhaseJumpsChannels: createFormField([]),
	cfgPhaseJumpsActions: createFormField(0),
	cfgFrequencyRecOpt: createFormField(0),
	cfgFrequencyEventThresholdMin: createFormField(""),
	cfgFrequencyEventThresholdMax: createFormField(""),
	cfgFrequencyChannels: createFormField([]),
	cfgFrequencyActions: createFormField(0),
	cfgVoltageCrestFactorRecOpt: createFormField(0),
	cfgVoltageUnbalanceRecOpt: createFormField(0),
	cfgVoltageUnbalanceThresholdMax: createFormField(""),
	cfgFlickerPstRecOpt: createFormField(0),
	cfgFlickerPstThresholdMax: createFormField(""),
	cfgFlickerPstChannels: createFormField([]),
	cfgFlickerPstActions: createFormField(0),
	cfgFlickerPltRecOpt: createFormField(0),
	cfgFlickerPltThresholdMax: createFormField(""),
	cfgFlickerPltChannels: createFormField([]),
	cfgFlickerPltActions: createFormField(0),
	cfgCurrentRmsRecOpt: createFormField(0),
	cfgCurrentRmsEventThresholdMax: createFormField(""),
	cfgCurrentRmsEventThresholdMaxCh4: createFormField(""),
	cfgCurrentRmsEventThresholdMaxCh5: createFormField(""),
	cfgCurrentChannels: createFormField([]),
	cfgCurrentActions: createFormField(0),
	cfgCurrentCrestFactorRecOpt: createFormField(0),
	cfgCurrentUnbalanceRecOpt: createFormField(0),

	cfgActivePowerRecOpt: createFormField(0),
	cfgActivePowerEventThresholdMax: createFormField(""),
	cfgActivePowerPlusRecOpt: createFormField(0),
	cfgActivePowerPlusEventThresholdMax: createFormField(""),
	cfgActivePowerPlusChannels: createFormField([]),
	cfgActivePowerPlusActions: createFormField(0),
	cfgActivePowerMinusRecOpt: createFormField(0),
	cfgActivePowerMinusEventThresholdMax: createFormField(""),
	cfgActivePowerMinusChannels: createFormField([]),
	cfgActivePowerMinusActions: createFormField(0),
	cfgActivePowerFundamentalRecOpt: createFormField(0),
	cfgApparentPowerRecOpt: createFormField(0),
	cfgApparentPowerEventThresholdMax: createFormField(""),
	cfgApparentPowerChannels: createFormField([]),
	cfgApparentPowerActions: createFormField(0),
	cfgApparentPowerFundamentalRecOpt: createFormField(0),
	cfgApparentPowerNonfundamentalRecOpt: createFormField(0),
	cfgDistortionPowerBudeanuRecOpt: createFormField(0),
	cfgReactivePowerRecOpt: createFormField(0),
	cfgReactivePowerEventThresholdMax: createFormField(""),
	cfgReactivePowerChannels: createFormField([]),
	cfgReactivePowerActions: createFormField(0),
	cfgPowerFactorRecOpt: createFormField(0),
	cfgPowerFactorEventThresholdMax: createFormField(""),
	cfgPowerFactorChannels: createFormField([]),
	cfgPowerFactorActions: createFormField(0),
	cfgPowerFactorFundamentalRecOpt: createFormField(0),
	cfgDisplacementPowerFactorRecOpt: createFormField(0),
	cfgDisplacementPowerFactorEventThresholdMax: createFormField(""),
	cfgDisplacementPowerFactorChannels: createFormField([]),
	cfgDisplacementPowerFactorActions: createFormField(0),
	cfgTangent4QRecOpt: createFormField(0),
	// cfgTangentQuadrant1EventThresholdMin: createFormField(""),
	// cfgTangentQuadrant1EventThresholdMax: createFormField(""),
	// cfgTangentQuadrant2EventThresholdMin: createFormField(""),
	// cfgTangentQuadrant2EventThresholdMax: createFormField(""),
	// cfgTangentQuadrant3EventThresholdMin: createFormField(""),
	// cfgTangentQuadrant3EventThresholdMax: createFormField(""),
	// cfgTangentQuadrant4EventThresholdMin: createFormField(""),
	// cfgTangentQuadrant4EventThresholdMax: createFormField(""),
	cfgActiveEnergyRecOpt: createFormField(0),
	cfgActiveEnergyPlusEventThresholdMax: createFormField(""),
	cfgActiveEnergyMinusEventThresholdMax: createFormField(""),
	cfgApparentEnergyRecOpt: createFormField(0),
	cfgApparentEnergyEventThresholdMax: createFormField(""),
	cfgReactiveEnergy4QRecOpt: createFormField(0),
	cfgReactiveEnergyQuadrant1EventThresholdMax: createFormField(""),
	cfgReactiveEnergyQuadrant2EventThresholdMax: createFormField(""),
	cfgReactiveEnergyQuadrant3EventThresholdMax: createFormField(""),
	cfgReactiveEnergyQuadrant4EventThresholdMax: createFormField(""),
	cfgVoltageThdfRecOpt: createFormField(0),
	cfgVoltageThdfThresholdMax: createFormField(""),
	cfgVoltageThdfChannels: createFormField([]),
	cfgVoltageThdfActions: createFormField(0),
	cfgVoltageThdrRecOpt: createFormField(0),
	cfgVoltageHarmonicsRecOpt: createFormField(0),
	cfgCurrentThdfRecOpt: createFormField(0),
	cfgCurrentThdfThresholdMax: createFormField(""),
	cfgCurrentThdfChannels: createFormField([]),
	cfgCurrentThdfActions: createFormField(0),
	cfgCurrentThdrRecOpt: createFormField(0),
	cfgCurrentHarmonicsRecOpt: createFormField(0),
	cfgActivePowerHarmonicsRecOpt: createFormField(0),
	cfgHarmonicsAnglesUiRecOpt: createFormField(0),
	cfgReactivePowerHarmonicsRecOpt: createFormField(0),
	cfgVoltageTidfRecOpt: createFormField(0),
	cfgVoltageTidrRecOpt: createFormField(0),
	cfgCurrentTidfRecOpt: createFormField(0),
	cfgCurrentTidrRecOpt: createFormField(0),

	cfgVoltageInterharmonicsRecOpt: createFormField(0),
	cfgCurrentInterharmonicsRecOpt: createFormField(0),
	cfgKfactorRecOpt: createFormField(0),
	cfgPhasorRecOpt: createFormField(0),

	cfgMainsSignallingUr1RecOpt: createFormField(0),
	cfgMainsSignallingUr1Frequency: createFormField(""),
	cfgMainsSignallingUr2RecOpt: createFormField(0),
	cfgMainsSignallingUr2Frequency: createFormField(""),

	cfgVoltage29KRecOpt: createFormField(0),
	cfgVoltage8150KRecOpt: createFormField(0),

	cfgStdEnable: createFormField(true),
	cfgStdType: createFormField(0),
	cfgStdAggregationWaveformEnable: createFormField(false),

	cfgIec61850Enable: createFormField(false),
	cfgIec61850IedName: createFormField(""),
	cfgIec61850PhaseVoltageDbRef: createFormField(""),
	cfgIec61850PhaseVoltageDb: createFormField(""),
	cfgIec61850PhaseToPhaseVoltageDbRef: createFormField(""),
	cfgIec61850PhaseToPhaseVoltageDb: createFormField(""),
	cfgIec61850UneVoltageDbRef: createFormField(""),
	cfgIec61850UneVoltageDb: createFormField(""),
	cfgIec61850PhaseCurrentDbRef: createFormField(""),
	cfgIec61850PhaseCurrentDb: createFormField(""),
	cfgIec61850NCurrentDbRef: createFormField(""),
	cfgIec61850NCurrentDb: createFormField(""),
	cfgIec61850ECurrentDbRef: createFormField(""),
	cfgIec61850ECurrentDb: createFormField(""),
	cfgIec61850ActivePowerTotalDbRef: createFormField(""),
	cfgIec61850ActivePowerTotalDb: createFormField(""),
	cfgIec61850ReactivePowerTotalDbRef: createFormField(""),
	cfgIec61850ReactivePowerTotalDb: createFormField(""),
	cfgIec61850ApparentPowerTotalDbRef: createFormField(""),
	cfgIec61850ApparentPowerTotalDb: createFormField(""),
	cfgIec61850ActivePowerPhaseDbRef: createFormField(""),
	cfgIec61850ActivePowerPhaseDb: createFormField(""),
	cfgIec61850ReactivePowerPhaseDbRef: createFormField(""),
	cfgIec61850ReactivePowerPhaseDb: createFormField(""),
	cfgIec61850ApparentPowerPhaseDbRef: createFormField(""),
	cfgIec61850ApparentPowerPhaseDb: createFormField(""),
	cfgIec61850FrequencyDbRef: createFormField(""),
	cfgIec61850FrequencyDb: createFormField(""),
	cfgIec61850VoltageHarmonicsDbRef: createFormField(""),
	cfgIec61850VoltageHarmonicsDb: createFormField(""),
	cfgIec61850CurrentHarmonicsDbRef: createFormField(""),
	cfgIec61850CurrentHarmonicsDb: createFormField(""),
	cfgIec61850VoltageInterharmonicsDbRef: createFormField(""),
	cfgIec61850VoltageInterharmonicsDb: createFormField(""),
	cfgIec61850CurrentInterharmonicsDbRef: createFormField(""),
	cfgIec61850CurrentInterharmonicsDb: createFormField(""),
	cfgIec61850VoltageThdDbRef: createFormField(""),
	cfgIec61850VoltageThdDb: createFormField(""),
	cfgIec61850CurrentThdDbRef: createFormField(""),
	cfgIec61850CurrentThdDb: createFormField(""),
	cfgIec61850VoltageTidDbRef: createFormField(""),
	cfgIec61850VoltageTidDb: createFormField(""),
	cfgIec61850CurrentTidDbRef: createFormField(""),
	cfgIec61850CurrentTidDb: createFormField(""),
	cfgIec61850UnbalanceVoltagePositiveSequenceDbRef: createFormField(""),
	cfgIec61850UnbalanceVoltagePositiveSequenceDb: createFormField(""),
	cfgIec61850UnbalanceVoltageNegativeSequenceDbRef: createFormField(""),
	cfgIec61850UnbalanceVoltageNegativeSequenceDb: createFormField(""),
	cfgIec61850UnbalanceVoltageZeroSequenceDbRef: createFormField(""),
	cfgIec61850UnbalanceVoltageZeroSequenceDb: createFormField(""),
	cfgIec61850UnbalanceCurrentPositiveSequenceDbRef: createFormField(""),
	cfgIec61850UnbalanceCurrentPositiveSequenceDb: createFormField(""),
	cfgIec61850UnbalanceCurrentNegativeSequenceDbRef: createFormField(""),
	cfgIec61850UnbalanceCurrentNegativeSequenceDb: createFormField(""),
	cfgIec61850UnbalanceCurrentZeroSequenceDbRef: createFormField(""),
	cfgIec61850UnbalanceCurrentZeroSequenceDb: createFormField(""),
	cfgIec61850UnbalanceCurrentNegativeDbRef: createFormField(""),
	cfgIec61850UnbalanceCurrentNegativeDb: createFormField(""),
	cfgIec61850FlickerPstDbRef: createFormField(""),
	cfgIec61850FlickerPstDb: createFormField(""),
	cfgIec61850FlickerPltDbRef: createFormField(""),
	cfgIec61850FlickerPltDb: createFormField(""),
	cfgIec6185029KhzVoltageDbRef: createFormField(""),
	cfgIec6185029KhzVoltageDb: createFormField(""),
	cfgIec6185029KhzVoltageMaxDbRef: createFormField(""),
	cfgIec6185029KhzVoltageMaxDb: createFormField(""),
	cfgIec618509150KhzVoltageDbRef: createFormField(""),
	cfgIec618509150KhzVoltageDb: createFormField(""),
	cfgIec618509150KhzVoltageMaxDbRef: createFormField(""),
	cfgIec618509150KhzVoltageMaxDb: createFormField(""),
	cfgIec61850BatteryVoltageDbRef: createFormField(""),
	cfgIec61850BatteryVoltageDb: createFormField(""),

	cfgDigitalInputInternal1ActiveState: createFormField(0),
	cfgDigitalInputInternal1RecOpt: createFormField(0),
	cfgDigitalInputInternal2ActiveState: createFormField(0),
	cfgDigitalInputInternal2RecOpt: createFormField(0),
	cfgRelay1ActiveState: createFormField(0),
	cfgRelay1Mode: createFormField(0),
	cfgRelay1ActiveTime: createFormField(""),
	cfgRelay1DeadTime: createFormField(""),
	cfgRelay2ActiveState: createFormField(0),
	cfgRelay2Mode: createFormField(0),
	cfgRelay2ActiveTime: createFormField(""),
	cfgRelay2DeadTime: createFormField(""),
};

// Should be validated on previous forms - dont need to be revalidate
export const bufforFormValidator: FormValidator<BufforReducerForm> = {
	type: () => null,
	nominalFrequency: () => null,
	nominalVoltage: () => null,
	unpeChannelEnable: () => null,
	voltageTransducerRatio: () => null,
	ch4Enabled: () => null,
	ch5Enabled: () => null,
	currentCh1TransducerRatio: () => null,
	currentCh2TransducerRatio: () => null,
	currentCh3TransducerRatio: () => null,
	currentCh4TransducerRatio: () => null,
	currentCh5TransducerRatio: () => null,
	phaseRotation: () => null,

	eventsHysteresis: () => null,
	waveformFormat: () => null,
	waveformRecDuration: () => null,
	waveformPretriggerDuration: () => null,
	rms12RecTime: () => null,
	rms12PretriggerTime: () => null,

	userRecordingPerc: () => null,
	stdRecordingPerc: () => null,
	energyCountersPerc: () => null,
	userDataModel: () => null,
	stdDataModel: () => null,
	energyDataModel: () => null,

	tcpEnabled: () => null,
	tcpSlaveAddress: () => null,
	tcpPort: () => null,
	tcpEndianness: () => null,

	rtuEnabled: () => null,
	rtuSlaveAddress: () => null,
	rtuBaudrate: () => null,
	rtuParity: () => null,
	rtuEndianness: () => null,

	ftpEnable: () => null,
	ftpConnectionType: () => null,
	ftpMode: () => null,
	ftpUploadTime: () => null,
	ftpServerIp: () => null,
	ftpServerPort: () => null,
	ftpUserName: () => null,
	ftpPassword: () => null,
	ftpDestDirectory: () => null,

	rtcUtcTimestamp: () => null,
	timeSyncMode: () => null,
	timezone: () => null,
	daylightSavingsTime: () => null,
	NTPEnable: () => null,
	NTPServer1Name: () => null,
	NTPServer2Name: () => null,
	NTPServer3Name: () => null,
	NTPServer4Name: () => null,

	cfgUserEnable: () => null,
	cfgAggregationPeriodUser: () => null,
	cfgUserAggregationWaveformEnable: () => null,
	cfgPowerCalculationMethod: () => null,

	cfgVoltageRmsRecOpt: () => null,
	cfgVoltageRmsEventThresholdUserDip: () => null,
	cfgVoltageRmsEventThresholdUserSwell: () => null,
	cfgVoltageRmsEventThresholdUserInterruption: () => null,
	cfgVoltageRmsEventThresholdMaxCh4: () => null,
	cfgDipChannels: () => null,
	cfgDipActions: () => null,
	cfgSwellChannels: () => null,
	cfgSwellActions: () => null,
	cfgInterruptChannels: () => null,
	cfgInterruptActions: () => null,
	cfgVoltageDcRecOpt: () => null,
	cfgWaveshapeVariationsRecOpt: () => null,
	cfgWaveshapeVariationsDeadtime: () => null,
	cfgWaveshapeVariationsThreshold: () => null,
	cfgWaveshapeVariationsChannels: () => null,
	cfgWaveshapeVariationsActions: () => null,
	cfgPhaseJumpsRecOpt: () => null,
	cfgPhaseJumpsThreshold: () => null,
	cfgPhaseJumpsChannels: () => null,
	cfgPhaseJumpsActions: () => null,
	cfgFrequencyRecOpt: () => null,
	cfgFrequencyEventThresholdMin: () => null,
	cfgFrequencyEventThresholdMax: () => null,
	cfgFrequencyChannels: () => null,
	cfgFrequencyActions: () => null,
	cfgVoltageCrestFactorRecOpt: () => null,
	cfgVoltageUnbalanceRecOpt: () => null,
	cfgVoltageUnbalanceThresholdMax: () => null,
	cfgFlickerPstRecOpt: () => null,
	cfgFlickerPstThresholdMax: () => null,
	cfgFlickerPstChannels: () => null,
	cfgFlickerPstActions: () => null,
	cfgFlickerPltRecOpt: () => null,
	cfgFlickerPltThresholdMax: () => null,
	cfgFlickerPltChannels: () => null,
	cfgFlickerPltActions: () => null,
	cfgCurrentRmsRecOpt: () => null,
	cfgCurrentRmsEventThresholdMax: () => null,
	cfgCurrentRmsEventThresholdMaxCh4: () => null,
	cfgCurrentRmsEventThresholdMaxCh5: () => null,
	cfgCurrentChannels: () => null,
	cfgCurrentActions: () => null,
	cfgCurrentCrestFactorRecOpt: () => null,
	cfgCurrentUnbalanceRecOpt: () => null,

	cfgActivePowerRecOpt: () => null,
	cfgActivePowerEventThresholdMax: () => null,
	cfgActivePowerPlusRecOpt: () => null,
	cfgActivePowerPlusEventThresholdMax: () => null,
	cfgActivePowerPlusChannels: () => null,
	cfgActivePowerPlusActions: () => null,
	cfgActivePowerMinusRecOpt: () => null,
	cfgActivePowerMinusEventThresholdMax: () => null,
	cfgActivePowerMinusChannels: () => null,
	cfgActivePowerMinusActions: () => null,
	cfgActivePowerFundamentalRecOpt: () => null,
	cfgApparentPowerRecOpt: () => null,
	cfgApparentPowerEventThresholdMax: () => null,
	cfgApparentPowerChannels: () => null,
	cfgApparentPowerActions: () => null,
	cfgApparentPowerFundamentalRecOpt: () => null,
	cfgApparentPowerNonfundamentalRecOpt: () => null,
	cfgDistortionPowerBudeanuRecOpt: () => null,
	cfgReactivePowerRecOpt: () => null,
	cfgReactivePowerEventThresholdMax: () => null,
	cfgReactivePowerChannels: () => null,
	cfgReactivePowerActions: () => null,
	cfgPowerFactorRecOpt: () => null,
	cfgPowerFactorEventThresholdMax: () => null,
	cfgPowerFactorChannels: () => null,
	cfgPowerFactorActions: () => null,
	cfgPowerFactorFundamentalRecOpt: () => null,
	cfgDisplacementPowerFactorRecOpt: () => null,
	cfgDisplacementPowerFactorEventThresholdMax: () => null,
	cfgDisplacementPowerFactorChannels: () => null,
	cfgDisplacementPowerFactorActions: () => null,
	cfgTangent4QRecOpt: () => null,
	// cfgTangentQuadrant1EventThresholdMin: () => null,
	// cfgTangentQuadrant1EventThresholdMax: () => null,
	// cfgTangentQuadrant2EventThresholdMin: () => null,
	// cfgTangentQuadrant2EventThresholdMax: () => null,
	// cfgTangentQuadrant3EventThresholdMin: () => null,
	// cfgTangentQuadrant3EventThresholdMax: () => null,
	// cfgTangentQuadrant4EventThresholdMin: () => null,
	// cfgTangentQuadrant4EventThresholdMax: () => null,
	cfgActiveEnergyRecOpt: () => null,
	cfgActiveEnergyPlusEventThresholdMax: () => null,
	cfgActiveEnergyMinusEventThresholdMax: () => null,
	cfgApparentEnergyRecOpt: () => null,
	cfgApparentEnergyEventThresholdMax: () => null,
	cfgReactiveEnergy4QRecOpt: () => null,
	cfgReactiveEnergyQuadrant1EventThresholdMax: () => null,
	cfgReactiveEnergyQuadrant2EventThresholdMax: () => null,
	cfgReactiveEnergyQuadrant3EventThresholdMax: () => null,
	cfgReactiveEnergyQuadrant4EventThresholdMax: () => null,
	cfgVoltageThdfRecOpt: () => null,
	cfgVoltageThdfThresholdMax: () => null,
	cfgVoltageThdfChannels: () => null,
	cfgVoltageThdfActions: () => null,
	cfgVoltageThdrRecOpt: () => null,
	cfgVoltageHarmonicsRecOpt: () => null,
	cfgCurrentThdfRecOpt: () => null,
	cfgCurrentThdfThresholdMax: () => null,
	cfgCurrentThdfChannels: () => null,
	cfgCurrentThdfActions: () => null,
	cfgCurrentThdrRecOpt: () => null,
	cfgCurrentHarmonicsRecOpt: () => null,
	cfgActivePowerHarmonicsRecOpt: () => null,
	cfgHarmonicsAnglesUiRecOpt: () => null,
	cfgReactivePowerHarmonicsRecOpt: () => null,
	cfgVoltageTidfRecOpt: () => null,
	cfgVoltageTidrRecOpt: () => null,
	cfgCurrentTidfRecOpt: () => null,
	cfgCurrentTidrRecOpt: () => null,

	cfgVoltageInterharmonicsRecOpt: () => null,
	cfgCurrentInterharmonicsRecOpt: () => null,
	cfgKfactorRecOpt: () => null,
	cfgPhasorRecOpt: () => null,

	cfgMainsSignallingUr1RecOpt: () => null,
	cfgMainsSignallingUr1Frequency: () => null,
	cfgMainsSignallingUr2RecOpt: () => null,
	cfgMainsSignallingUr2Frequency: () => null,

	cfgVoltage29KRecOpt: () => null,
	cfgVoltage8150KRecOpt: () => null,

	cfgStdEnable: () => null,
	cfgStdType: () => null,
	cfgStdAggregationWaveformEnable: () => null,

	cfgIec61850Enable: () => null,
	cfgIec61850IedName: () => null,
	cfgIec61850PhaseVoltageDbRef: () => null,
	cfgIec61850PhaseVoltageDb: () => null,
	cfgIec61850PhaseToPhaseVoltageDbRef: () => null,
	cfgIec61850PhaseToPhaseVoltageDb: () => null,
	cfgIec61850UneVoltageDbRef: () => null,
	cfgIec61850UneVoltageDb: () => null,
	cfgIec61850PhaseCurrentDbRef: () => null,
	cfgIec61850PhaseCurrentDb: () => null,
	cfgIec61850NCurrentDbRef: () => null,
	cfgIec61850NCurrentDb: () => null,
	cfgIec61850ECurrentDbRef: () => null,
	cfgIec61850ECurrentDb: () => null,
	cfgIec61850ActivePowerTotalDbRef: () => null,
	cfgIec61850ActivePowerTotalDb: () => null,
	cfgIec61850ReactivePowerTotalDbRef: () => null,
	cfgIec61850ReactivePowerTotalDb: () => null,
	cfgIec61850ApparentPowerTotalDbRef: () => null,
	cfgIec61850ApparentPowerTotalDb: () => null,
	cfgIec61850ActivePowerPhaseDbRef: () => null,
	cfgIec61850ActivePowerPhaseDb: () => null,
	cfgIec61850ReactivePowerPhaseDbRef: () => null,
	cfgIec61850ReactivePowerPhaseDb: () => null,
	cfgIec61850ApparentPowerPhaseDbRef: () => null,
	cfgIec61850ApparentPowerPhaseDb: () => null,
	cfgIec61850FrequencyDbRef: () => null,
	cfgIec61850FrequencyDb: () => null,
	cfgIec61850VoltageHarmonicsDbRef: () => null,
	cfgIec61850VoltageHarmonicsDb: () => null,
	cfgIec61850CurrentHarmonicsDbRef: () => null,
	cfgIec61850CurrentHarmonicsDb: () => null,
	cfgIec61850VoltageInterharmonicsDbRef: () => null,
	cfgIec61850VoltageInterharmonicsDb: () => null,
	cfgIec61850CurrentInterharmonicsDbRef: () => null,
	cfgIec61850CurrentInterharmonicsDb: () => null,
	cfgIec61850VoltageThdDbRef: () => null,
	cfgIec61850VoltageThdDb: () => null,
	cfgIec61850CurrentThdDbRef: () => null,
	cfgIec61850CurrentThdDb: () => null,
	cfgIec61850VoltageTidDbRef: () => null,
	cfgIec61850VoltageTidDb: () => null,
	cfgIec61850CurrentTidDbRef: () => null,
	cfgIec61850CurrentTidDb: () => null,
	cfgIec61850UnbalanceVoltagePositiveSequenceDbRef: () => null,
	cfgIec61850UnbalanceVoltagePositiveSequenceDb: () => null,
	cfgIec61850UnbalanceVoltageNegativeSequenceDbRef: () => null,
	cfgIec61850UnbalanceVoltageNegativeSequenceDb: () => null,
	cfgIec61850UnbalanceVoltageZeroSequenceDbRef: () => null,
	cfgIec61850UnbalanceVoltageZeroSequenceDb: () => null,
	cfgIec61850UnbalanceCurrentPositiveSequenceDbRef: () => null,
	cfgIec61850UnbalanceCurrentPositiveSequenceDb: () => null,
	cfgIec61850UnbalanceCurrentNegativeSequenceDbRef: () => null,
	cfgIec61850UnbalanceCurrentNegativeSequenceDb: () => null,
	cfgIec61850UnbalanceCurrentZeroSequenceDbRef: () => null,
	cfgIec61850UnbalanceCurrentZeroSequenceDb: () => null,
	cfgIec61850UnbalanceCurrentNegativeDbRef: () => null,
	cfgIec61850UnbalanceCurrentNegativeDb: () => null,
	cfgIec61850FlickerPstDbRef: () => null,
	cfgIec61850FlickerPstDb: () => null,
	cfgIec61850FlickerPltDbRef: () => null,
	cfgIec61850FlickerPltDb: () => null,
	cfgIec6185029KhzVoltageDbRef: () => null,
	cfgIec6185029KhzVoltageDb: () => null,
	cfgIec6185029KhzVoltageMaxDbRef: () => null,
	cfgIec6185029KhzVoltageMaxDb: () => null,
	cfgIec618509150KhzVoltageDbRef: () => null,
	cfgIec618509150KhzVoltageDb: () => null,
	cfgIec618509150KhzVoltageMaxDbRef: () => null,
	cfgIec618509150KhzVoltageMaxDb: () => null,
	cfgIec61850BatteryVoltageDbRef: () => null,
	cfgIec61850BatteryVoltageDb: () => null,

	cfgDigitalInputInternal1ActiveState: () => null,
	cfgDigitalInputInternal1RecOpt: () => null,
	cfgDigitalInputInternal2ActiveState: () => null,
	cfgDigitalInputInternal2RecOpt: () => null,
	cfgRelay1ActiveState: () => null,
	cfgRelay1Mode: () => null,
	cfgRelay1ActiveTime: () => null,
	cfgRelay1DeadTime: () => null,
	cfgRelay2ActiveState: () => null,
	cfgRelay2Mode: () => null,
	cfgRelay2ActiveTime: () => null,
	cfgRelay2DeadTime: () => null,
};
