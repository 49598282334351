import { Arrow, Layer, Text } from "react-konva";
import { useRef } from "react";

type Props = {
	points: [ number, number, number, number ]
	labelPoints: [ number, number, number, number ]
	name: string
	angle: number
	color: string
	strokeWidth?: number
};
const yFrom0To45Degree = (angle: number) => (-angle / 90) - (1 / 2);
const yFrom135To225Degree = (angle: number) => (angle / 90) - (5 / 2);
const yFrom315To360Degree = (angle: number) => (-angle / 90) + (7 / 2);

const xFrom45To135Degree = (angle: number) => (angle / 90) - (3 / 2);
const xFrom225To315Degree = (angle: number) => (-angle / 90) + (5 / 2);

function VectorArrow(props: Props) {

	const {
		points,
		labelPoints,
		name,
		angle,
		color,
		strokeWidth = 4,
	} = props;

	const ref = useRef<any>();
	const textWidth = ref.current?.textWidth ?? 20;
	const textHeight = ref.current?.textHeight ?? 16;

	const _getY = () => {
		const newAngle = angle < 0 ? angle + 360 : angle;
		if (newAngle >= 0 && newAngle < 45) {
			return yFrom0To45Degree(newAngle) * textHeight;
		} else if (newAngle >= 45 && newAngle < 135) {
			return -textHeight;
		} else if (newAngle >= 135 && newAngle < 225) {
			return yFrom135To225Degree(newAngle) * textHeight;
		} else if (newAngle >= 225 && newAngle < 315) {
			return 0;
		} else {
			return yFrom315To360Degree(newAngle) * textHeight;
		}
	};

	const _getX = () => {
		const newAngle = angle < 0 ? angle + 360 : angle;
		if (newAngle >= 0 && newAngle < 45) {
			return -textWidth;
		} else if (newAngle >= 45 && newAngle < 135) {
			return xFrom45To135Degree(newAngle) * textWidth;
		} else if (newAngle >= 135 && newAngle < 225) {
			return 0;
		} else if (newAngle >= 225 && newAngle < 315) {
			return xFrom225To315Degree(newAngle) * textWidth;
		} else {
			return -textWidth;
		}
	};

	const [ x1, y1, x2, y2 ] = points;
	const [ _1, _2, labelX2, labelY2 ] = labelPoints;

	return (
		<Layer name={ name }>
			<Arrow
				points={
					[
						x1, y1,
						x2, y2,
					]
				}
				stroke={ color }
				fill={ color }
				strokeWidth={ strokeWidth }
			/>
			<Text
				ref={ ref }
				text={ name }
				fontSize={ 16 }
				x={ labelX2 + _getX() }
				y={ labelY2 + _getY() }
				stroke={ color }
				strokeWidth={ 1 }
				fill={ color }
			/>
		</Layer>
	);
}

export default (VectorArrow);
