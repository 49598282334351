import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import { fetchSettingsFtpAsync } from "src/app/store/features/settings/settings.actions";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";
import FtpContainer from "src/app/containers/Settings/Ftp.container";
import CallbackPromptProvider from "src/app/hoc/providers/CallbackPrompt.provider";
import { uiTestFtpConnection } from "src/app/store/features/ui/settings/ui.settings.actions";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function FtpView(props: Props) {

	const {
		ftpSettings,
		fetchFtpSettings,
		testConnection,
	} = props;

	return (
		<CallbackPromptProvider>
			<CachedThenFreshStrategy
				request={ fetchFtpSettings }
				state={ ftpSettings }
			>
				{
					ftpSettings =>
						<FtpContainer
							ftpSettings={ ftpSettings }
							testConnection={ testConnection }
						/>
				}
			</CachedThenFreshStrategy>
		</CallbackPromptProvider>
	);
}

const mapStateToProps = (state: RootState) => ({
	ftpSettings: state.settings.settingsFtp,
});

const mapDispatchToProps = {
	fetchFtpSettings: fetchSettingsFtpAsync.request,
	testConnection: uiTestFtpConnection,
};
export default connect(mapStateToProps, mapDispatchToProps)(FtpView);
