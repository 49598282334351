import { memo, useState } from "react";
import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import { ModalConfig, SortCompare } from "src/app/types/util.types";
import Table from "src/app/components/Utils/Table.component";
import { CreateUserPayload, DeleteUserPayload, ResetPasswordPayload, User } from "src/app/types/api/user.types";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { Button, Tooltip } from "flowbite-react";
import CreateUserModal from "src/app/components/User/CreateUserModal.component";
import { HiOutlineTrash } from "react-icons/hi2";
import ConfirmModal from "src/app/components/Utils/ConfirmModal.component";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { isNotNull } from "src/app/utils/typeguards";
import { MdLockReset } from "react-icons/md";
import ResetPasswordModal from "src/app/components/User/ResetPasswordModal.component";
import { useTranslation } from "react-i18next";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		title: string,
		users: User[],
		onCreateUser: (payload: CreateUserPayload) => void,
		onDeleteUser: (payload: DeleteUserPayload) => void,
		onResetPassword: (payload: ResetPasswordPayload) => void,
	};

function UsersContainer(props: Props) {

	const {
		title,
		mainHeight,
		users,
		onCreateUser,
		onDeleteUser,
		onResetPassword,
		isDeleting,
		isResetting,
	} = props;

	return (
		<div className="grid grid-cols-12 gap-4">
			<div className="col-span-12">
				<UserTable
					title={ title }
					mainHeight={ mainHeight }
					users={ users }
					onCreateUser={ onCreateUser }
					onDeleteUser={ onDeleteUser }
					onResetPassword={ onResetPassword }
					isDeleting={ isDeleting }
					isResetting={ isResetting }
				/>
			</div>
		</div>
	);
}

type UserTableProps = {
	mainHeight: number,
	users: User[],
	title: string,
	onCreateUser: (payload: CreateUserPayload) => void,
	onDeleteUser: (payload: DeleteUserPayload) => void,
	onResetPassword: (payload: ResetPasswordPayload) => void,
	isDeleting: (userName: string) => boolean,
	isResetting: (userName: string) => boolean,
}

const UserTable = memo((props: UserTableProps) => {

	const { t } = useTranslation();

	const {
		title,
		users,
		mainHeight,
		onCreateUser,
		onDeleteUser,
		onResetPassword,
		isDeleting,
		isResetting,
	} = props;

	const [ isCreateModalOpen, toggleIsCreateModalOpen ] = useState(false);
	const [ deleteUserModal, setDeleteUserModal ] = useState<ModalConfig<User>>({
		isOpen: false,
		value: null,
	});
	const [ resetPasswordModal, setResetPasswordModal ] = useState<ModalConfig<User>>({
		isOpen: false,
		value: null,
	});

	const userColumns: MUIDataTableColumn[] = [
		{
			name: "ID",
			label: t("USERS.id"),
			options: {
				filter: false,
				customBodyRender: (user: User) => user.id,
				sort: true,
				sortCompare: order => (a: SortCompare<User>, b: SortCompare<User>) => {
					if (order === "asc") {
						return a.data.id - b.data.id;
					} else {
						return b.data.id - a.data.id;
					}
				},
			},
		},
		{
			name: "Name",
			label: t("USERS.name"),
			options: {
				filter: false,
				customBodyRender: (user: User) => user.name,
				sort: true,
				sortCompare: order => (a: SortCompare<User>, b: SortCompare<User>) => {
					if (order === "asc") {
						return a.data.name.localeCompare(b.data.name);
					} else {
						return b.data.name.localeCompare(a.data.name);
					}
				},
			},
		},
		{
			name: "Actions",
			label: t("USERS.actions"),
			options: {
				filter: false,
				sort: false,
				customBodyRender: (user: User) =>
					<div className="flex gap-2 items-center sm:justify-end">
						<Tooltip content={ t("USERS.reset user password") }>
							<Button
								onClick={ e => {
									e.stopPropagation();
									setResetPasswordModal({
										isOpen: true,
										value: user,
									});
								} }
								color="transparent"
								size="sm"
								className="p-0 [&>span]:p-2"
							>
								<MdLockReset className="h-5 w-5 text-[#009FE3] dark:text-dark-textGray flex-shrink-0"/>
							</Button>
						</Tooltip>
						<Tooltip content={ t("USERS.delete user") }>
							<Button
								onClick={ e => {
									e.stopPropagation();
									setDeleteUserModal({
										isOpen: true,
										value: user,
									});
								} }
								color="transparent"
								size="sm"
								className="p-0 [&>span]:p-2"
							>
								<HiOutlineTrash className="h-5 w-5 text-[#009FE3] dark:text-dark-textGray flex-shrink-0"/>
							</Button>
						</Tooltip>
					</div>,
				setCellHeaderProps: () => ({ style: { textAlign: "right" } }),
			},
		},
	];

	const tableOptions: MUIDataTableOptions = {
		search: false,
		filter: false,
		customToolbar: () =>
			<Button
				color="primary"
				onClick={ () => toggleIsCreateModalOpen(true) }
				className="order-first mr-3 p-0 [&>span]:p-2"
			>
				{ t("USERS.create user") }
			</Button>,
	};

	const _handleConfirmDeleteUser = () => {
		if (isNotNull(deleteUserModal.value)) {
			onDeleteUser({ user_name: deleteUserModal.value.name });
			setDeleteUserModal(prevState => ({ ...prevState, isOpen: false }));
		}
	};

	const _handleResetPassword = (user_password: string) => {
		if (isNotNull(resetPasswordModal.value)) {
			onResetPassword({ user_name: resetPasswordModal.value.name, user_password });
			setResetPasswordModal(prevState => ({ ...prevState, isOpen: false }));
		}
	};

	const isDeletingUser = isNotNull(deleteUserModal.value) && isDeleting(deleteUserModal.value.name);
	const isResettingPassword = isNotNull(resetPasswordModal.value) && isResetting(resetPasswordModal.value.name);

	return (
		<>
			<Table
				title={ title }
				columns={ userColumns }
				options={ tableOptions }
				wrapperHeight={ mainHeight }
				data={ users }
				cellHeight={ 53 }
			/>
			<CreateUserModal
				isOpen={ isCreateModalOpen }
				handleClose={ () => toggleIsCreateModalOpen(false) }
				handleCreate={ onCreateUser }
			/>
			<ConfirmModal
				title={ t("USERS.delete user") }
				warning={ t("USERS.are you sure") }
				isOpen={ deleteUserModal.isOpen }
				handleClose={ () => setDeleteUserModal({ isOpen: false, value: null }) }
				onConfirm={ _handleConfirmDeleteUser }
				confirmWord={ t("USERS.delete") }
				isLoading={ isDeletingUser }
			/>
			<ResetPasswordModal
				isOpen={ resetPasswordModal.isOpen }
				handleClose={ () => setResetPasswordModal({ isOpen: false, value: null }) }
				handleResetPassword={ _handleResetPassword }
				isResettingPassword={ isResettingPassword }
			/>
		</>
	);
});

const mapStateToProps = (state: RootState) => ({
	mainHeight: state.ui.layout.mainSize.height,
	isDeleting: (userName: string) => didLoadingRecordExist(state, { loadableId: userName, loadableType: LoadableType.DELETE_USER }),
	isResetting: (userName: string) => didLoadingRecordExist(state, { loadableId: userName, loadableType: LoadableType.RESET_PASSWORD }),
});

export default connect(mapStateToProps)(UsersContainer);