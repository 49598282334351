import { FormItem } from "src/app/types/ui/form.types";
import { Label, LabelProps, TextInput, TextInputProps } from "flowbite-react";
import { ChangeEventHandler, RefAttributes } from "react";
import ReactInputMask from "react-input-mask";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import classNames from "classnames";

type Props = {
	className?: string
	formItem: FormItem<string>
	name: string // Friendly name for HTML Semantic
	onChange: ChangeEventHandler<HTMLInputElement>
	onBlur: () => void

	mask: string
	maskChar?: string
	formatChars?: {
		[K in string]: string
	}
	alwaysShowMask?: boolean

	inputProps?: Omit<TextInputProps & RefAttributes<HTMLInputElement>, "onChange" | "onBlur">
	labelProps?: LabelProps
	label?: string | ReactNode
	displayErrorMessage?: boolean
	role?: string
}

function MaskInput(props: Props) {

	const {
		className,
		formItem,
		name,
		onChange,
		onBlur,
		mask,
		maskChar,
		formatChars,
		alwaysShowMask = true,
		inputProps = {},
		labelProps = {},
		label,
		displayErrorMessage = true,
		role,
	} = props;

	const _getErrorMessage = () => {
		if (isNull(formItem.error)) return null;

		if (displayErrorMessage) {
			if (formItem.error instanceof Array) {
				return (
					<ul className="form__error--list">
						{
							formItem.error.map((err, i) =>
								<li key={ i } className="mb-1">{ err }</li>,
							)
						}
					</ul>
				);
			} else {
				return formItem.error;
			}
		} else {
			return " ";
		}
	};

	return (
		<div
			className={
				classNames(
					"flex flex-col gap-y-0.5",
					className,
				)
			}
			role={ role }
		>
			{
				isNotNull(label) &&
                <Label
                    htmlFor={ name }
                    disabled={ formItem.disabled || (inputProps?.disabled ?? false) }
					{ ...labelProps }
                >
					{ label }
                </Label>
			}
			<ReactInputMask
				value={ formItem.value }
				onChange={ onChange }
				onBlur={ onBlur }
				mask={ mask }
				maskChar={ maskChar }
				formatChars={ formatChars }
				disabled={ formItem.disabled || (inputProps?.disabled ?? false) }
				alwaysShowMask={ alwaysShowMask }
			>
				{
					// @ts-ignore
					(reactInputMaskProps: any) => {
						return (
							<TextInput
								{ ...reactInputMaskProps }
								disabled={ formItem.disabled || (inputProps?.disabled ?? false) }
								name={ name }
								color={ isNotNull(formItem.error) ? "failure" : undefined }
								helperText={ (isNotNull(formItem.error) && displayErrorMessage) ? <><span className="font-medium" role="input-error">{ _getErrorMessage() }</span></> : undefined }
								className={ classNames(inputProps?.className, "focus-visible:[&_input]:outline-primary-500") }
							/>
						);
					}
				}
			</ReactInputMask>
		</div>
	);
}

export default (MaskInput);
