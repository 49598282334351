import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import ChangePasswordContainer from "src/app/containers/User/ChangePassword.container";
import { uiChangePassword } from "src/app/store/features/ui/user/ui.user.actions";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function ChangePasswordView(props: Props) {

	const {
		changePassword,
	} = props;

	return (
		<ChangePasswordContainer
			onUpdatePassword={ changePassword }
		/>
	);
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {
	changePassword: uiChangePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordView);
