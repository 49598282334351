import { RecordingOption, SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateNumberField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import BasicRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/BasicRecordingOptionConfiguration.component";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { useContext, useEffect } from "react";
import { MeasurementsUserContext } from "src/app/components/Measurements/MeasurementsUserContent.component";
import EventRecordingOptionConfiguration from "src/app/components/MeasurementsUser/Generic/EventRecordingOptionConfiguration.component";
import Input from "src/app/components/Form/Input.component";
import { Button, Card } from "flowbite-react";
import { EnumOptionsSetting } from "src/app/types/util.types";
import { isOptionSelected } from "src/app/utils/helpers";
import { isChannelsDiff, mapActivePowerMinusConfig } from "src/app/utils/configuration";
import RelayChannelChooser from "src/app/components/MeasurementsUser/Generic/RelayChannelChooser.component";
import { isEmptyArray, isNotNull } from "src/app/utils/typeguards";
import RelayActionSelect from "src/app/components/MeasurementsUser/Generic/RelayActionSelect.component";
import { useTranslation } from "react-i18next";
import i18n from "src/app/translations/i18n";
import classNames from "classnames";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsMeasurementsUser: SettingsMeasurementsUser
	};

export type ActivePowerMinusForm = {
	cfgActivePowerMinusRecOpt: number
	cfgActivePowerMinusRecOptOptions: EnumOptionsSetting<RecordingOption>["options"]
	cfgActivePowerMinusEventThresholdMax: string
	cfgActivePowerMinusEventThresholdMaxMin: number
	cfgActivePowerMinusEventThresholdMaxMax: number
	cfgActivePowerMinusChannels: ("cfgRelayActivePowerMinus1RecOpt" | "cfgRelayActivePowerMinus2RecOpt" | "cfgRelayActivePowerMinus3RecOpt" | "cfgRelayActivePowerMinus4RecOpt")[]
	cfgActivePowerMinusActions: number
}

const validator: FormValidator<ActivePowerMinusForm> = {
	cfgActivePowerMinusRecOpt: () => null,
	cfgActivePowerMinusRecOptOptions: () => null,
	cfgActivePowerMinusEventThresholdMax: (cfgActivePowerMinusEventThresholdMax, optional, form) => {
		if (!isOptionSelected(form.cfgActivePowerMinusRecOpt.value, RecordingOption.SET_REC_OPT_EVT_LOG_ENABLE, form.cfgActivePowerMinusRecOptOptions.value)) return null;

		return validateNumberField(i18n.t("ENERGY.max"), cfgActivePowerMinusEventThresholdMax, optional, "he", { from: form.cfgActivePowerMinusEventThresholdMaxMin.value, to: form.cfgActivePowerMinusEventThresholdMaxMax.value, decimalPlaces: 2 });
	},
	cfgActivePowerMinusEventThresholdMaxMin: () => null,
	cfgActivePowerMinusEventThresholdMaxMax: () => null,
	cfgActivePowerMinusChannels: () => null,
	cfgActivePowerMinusActions: () => null,
};

const ID = "active-power-minus";

function ActivePowerMinusCard(props: Props) {

	const { t } = useTranslation();

	const {
		settingsMeasurementsUser: {
			cfgActivePowerMinus,
		},
		isAdmin,
	} = props;

	const {
		form: reducerForm,
		handleChange: reducerHandleChange,
	} = useReducerForm(
		"buffor",
		bufforFormActions,
		() => null,
	);

	const { cfgUserEnable } = useContext(MeasurementsUserContext);

	const _handleSubmit = (values: ActivePowerMinusForm) => {
		reducerHandleChange("cfgActivePowerMinusRecOpt", values.cfgActivePowerMinusRecOpt);
		reducerHandleChange("cfgActivePowerMinusEventThresholdMax", values.cfgActivePowerMinusEventThresholdMax);
		reducerHandleChange("cfgActivePowerMinusChannels", values.cfgActivePowerMinusChannels);
		reducerHandleChange("cfgActivePowerMinusActions", values.cfgActivePowerMinusActions);
	};

	const _getInitialState = () => {
		const [ cfgActivePowerMinusChannels, cfgActivePowerMinusActions ] = mapActivePowerMinusConfig(cfgActivePowerMinus);
		return {
			cfgActivePowerMinusRecOpt: createFormField((reducerForm.cfgActivePowerMinusRecOpt.value !== reducerForm.cfgActivePowerMinusRecOpt.initialValue) ? reducerForm.cfgActivePowerMinusRecOpt.value : cfgActivePowerMinus?.cfgActivePowerMinusRecOpt?.value ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgActivePowerMinusRecOptOptions: createFormField(cfgActivePowerMinus?.cfgActivePowerMinusRecOpt?.options ?? []),
			cfgActivePowerMinusEventThresholdMax: createFormField((reducerForm.cfgActivePowerMinusEventThresholdMax.value !== reducerForm.cfgActivePowerMinusEventThresholdMax.initialValue) ? reducerForm.cfgActivePowerMinusEventThresholdMax.value : cfgActivePowerMinus?.cfgActivePowerMinusEventThresholdMax?.value?.toFixed(2) ?? "", { disabled: !isAdmin || !cfgUserEnable }),
			cfgActivePowerMinusEventThresholdMaxMin: createFormField(cfgActivePowerMinus?.cfgActivePowerMinusEventThresholdMax?.minValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgActivePowerMinusEventThresholdMaxMax: createFormField(cfgActivePowerMinus?.cfgActivePowerMinusEventThresholdMax?.maxValue ?? 0, { disabled: !isAdmin || !cfgUserEnable }),
			cfgActivePowerMinusChannels: createFormField(isChannelsDiff(reducerForm.cfgActivePowerMinusChannels) ? reducerForm.cfgActivePowerMinusChannels.value : cfgActivePowerMinusChannels, { disabled: !isAdmin || !cfgUserEnable }),
			cfgActivePowerMinusActions: createFormField((reducerForm.cfgActivePowerMinusActions.value !== reducerForm.cfgActivePowerMinusActions.initialValue) ? reducerForm.cfgActivePowerMinusActions.value : cfgActivePowerMinusActions, { disabled: !isAdmin || !cfgUserEnable }),
		};
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ cfgActivePowerMinus ]);

	useEffect(() => {
		toggleDisable("cfgActivePowerMinusRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgActivePowerMinusEventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgActivePowerMinusChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgActivePowerMinusActions", !cfgUserEnable || !isAdmin);
	}, [ cfgUserEnable ]);

	useEffect(() => {
		toggleDisable("cfgActivePowerMinusRecOpt", false);
		toggleDisable("cfgActivePowerMinusEventThresholdMax", false);
		toggleDisable("cfgActivePowerMinusChannels", false);
		toggleDisable("cfgActivePowerMinusActions", false);

		handleChange("cfgActivePowerMinusRecOpt", reducerForm.cfgActivePowerMinusRecOpt.value);
		handleChange("cfgActivePowerMinusEventThresholdMax", reducerForm.cfgActivePowerMinusEventThresholdMax.value);
		handleChange("cfgActivePowerMinusChannels", reducerForm.cfgActivePowerMinusChannels.value);
		handleChange("cfgActivePowerMinusActions", reducerForm.cfgActivePowerMinusActions.value);

		toggleDisable("cfgActivePowerMinusRecOpt", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgActivePowerMinusEventThresholdMax", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgActivePowerMinusChannels", !cfgUserEnable || !isAdmin);
		toggleDisable("cfgActivePowerMinusActions", !cfgUserEnable || !isAdmin);
	}, [
		reducerForm.cfgActivePowerMinusRecOpt.value,
		reducerForm.cfgActivePowerMinusEventThresholdMax.value,
		reducerForm.cfgActivePowerMinusChannels.value,
		reducerForm.cfgActivePowerMinusActions.value,
	]);

	return (
		<Card className="bg-gray-50">
			<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
				{ t("POWER.active power minus") }
			</h5>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<BasicRecordingOptionConfiguration
					id={ ID }
					options={ cfgActivePowerMinus?.cfgActivePowerMinusRecOpt?.options ?? [] }
					formItem={ form.cfgActivePowerMinusRecOpt }
					handleChange={ value => handleChange("cfgActivePowerMinusRecOpt", value) }
					reducerFormItem={ reducerForm.cfgActivePowerMinusRecOpt }
				/>
				<div className="flex flex-col gap-3">
					<h5 className="text-base sm:text-xl font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
						{ t("POWER.events") }
					</h5>
					<EventRecordingOptionConfiguration
						id={ ID }
						options={ cfgActivePowerMinus?.cfgActivePowerMinusRecOpt?.options ?? [] }
						formItem={ form.cfgActivePowerMinusRecOpt }
						handleChange={ value => handleChange("cfgActivePowerMinusRecOpt", value) }
						reducerFormItem={ reducerForm.cfgActivePowerMinusRecOpt }
					>
						{
							(isLogEventEnabled) =>
								<div className="flex flex-col gap-2.5">
									<h5 className="text-sm sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none">
										{ t("POWER.thresholds") }
									</h5>
									<hr className="mx-2"/>
									<div className="flex gap-2.5 flex-1">
										<Input
											className="flex-1"
											formItem={ form.cfgActivePowerMinusEventThresholdMax }
											label={ t("POWER.max") }
											name="cfgActivePowerMinusEventThresholdMax"
											inputProps={ {
												type: "number",
												onChange: (e) => handleChange("cfgActivePowerMinusEventThresholdMax", e.target.value),
												onBlur: () => handleBlur("cfgActivePowerMinusEventThresholdMax"),
												disabled: !isLogEventEnabled,
												sizing: "sm",
												className: "[&>span]:!min-w-[40px] [&>span]:justify-center",
											} }
											addonRight={ cfgActivePowerMinus?.cfgActivePowerMinusEventThresholdMax?.unit ?? "-" }
											hasBeenChanged={ reducerForm.cfgActivePowerMinusEventThresholdMax.value !== reducerForm.cfgActivePowerMinusEventThresholdMax.initialValue }
										/>
										<div
											className={
												classNames(
													"flex gap-2",
													"xl:flex-[7_1_0%]",
													"flex-[4_1_0%]",
												)
											}
										>
											<RelayChannelChooser
												options={ [
													(isNotNull(cfgActivePowerMinus) && isNotNull(cfgActivePowerMinus.relayChannelLabel) && isNotNull(cfgActivePowerMinus.relayChannelLabel.ch1)) ? {
														value: "cfgRelayActivePowerMinus1RecOpt",
														label: cfgActivePowerMinus.relayChannelLabel.ch1,
													} : null,
													(isNotNull(cfgActivePowerMinus) && isNotNull(cfgActivePowerMinus.relayChannelLabel) && isNotNull(cfgActivePowerMinus.relayChannelLabel.ch2)) ? {
														value: "cfgRelayActivePowerMinus2RecOpt",
														label: cfgActivePowerMinus.relayChannelLabel.ch2,
													} : null,
													(isNotNull(cfgActivePowerMinus) && isNotNull(cfgActivePowerMinus.relayChannelLabel) && isNotNull(cfgActivePowerMinus.relayChannelLabel.ch3)) ? {
														value: "cfgRelayActivePowerMinus3RecOpt",
														label: cfgActivePowerMinus.relayChannelLabel.ch3,
													} : null,
													(isNotNull(cfgActivePowerMinus) && isNotNull(cfgActivePowerMinus.relayChannelLabel) && isNotNull(cfgActivePowerMinus.relayChannelLabel.ch4)) ? {
														value: "cfgRelayActivePowerMinus4RecOpt",
														label: cfgActivePowerMinus.relayChannelLabel.ch4,
													} : null,
												].filter(isNotNull) }
												disabled={ !isLogEventEnabled }
												formItem={ form.cfgActivePowerMinusChannels }
												reducerFormItem={ reducerForm.cfgActivePowerMinusChannels }
												handleChange={ value => handleChange("cfgActivePowerMinusChannels", value) }
											/>
											<RelayActionSelect
												formItem={ form.cfgActivePowerMinusActions }
												reducerFormItem={ reducerForm.cfgActivePowerMinusActions }
												options={ cfgActivePowerMinus?.cfgRelayActivePowerMinus1Map?.options ?? [] }
												disabled={ !isLogEventEnabled || isEmptyArray(form.cfgActivePowerMinusChannels.value) }
												handleChange={ value => handleChange("cfgActivePowerMinusActions", value) }
											/>
										</div>
									</div>
									<hr className="mx-2"/>
								</div>
						}
					</EventRecordingOptionConfiguration>
				</div>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            color="primary"
                            type="submit"
                        >
							{ t("UTILS.save") }
                        </Button>
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(ActivePowerMinusCard);
