import { Button, Modal } from "flowbite-react";
import { createFormField, validatePassword } from "src/app/utils/forms";
import { Form, FormValidator } from "src/app/types/ui/form.types";
import useForm from "src/app/utils/hooks/useForm";
import Input from "src/app/components/Form/Input.component";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';

type Props = {
		isOpen: boolean
		handleClose: () => void
		handleResetPassword: (user_password: string) => void
		isResettingPassword: boolean
	};

type ResetPasswordModalForm = {
	password: string
}

const validator: FormValidator<ResetPasswordModalForm> = {
	password: (password) => validatePassword(password)
};

function ResetPasswordModal(props: Props) {

	const { t } = useTranslation();

	const {
		isOpen,
		handleClose,
		isResettingPassword = false,
		handleResetPassword
	} = props;

	const _handleSubmit = (values: ResetPasswordModalForm) => {
		handleResetPassword(values.password);
	};

	const _getInitialState = (): Form<ResetPasswordModalForm> => ({
		password: createFormField(""),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm
	} = useForm(
		_getInitialState(),
		validator,
		_handleSubmit,
	);

	useEffect(() => {
		if (isOpen) {
			setForm(_getInitialState());
		}
	}, [ isOpen, isResettingPassword ]);

	return (
		<Modal
			show={ isOpen || isResettingPassword }
			onClose={ handleClose }
		>
			<Modal.Header>{t('USERS.reset password')}</Modal.Header>
			<form noValidate onSubmit={ handleSubmit }>
				<Modal.Body>
					<div className="flex flex-col gap-4">
						<Input
							formItem={ form.password }
							label={t('PASSWORD.new password')}
							name="password"
							inputProps={ {
								type: "password",
								onChange: (e) => handleChange("password", e.target.value),
								onBlur: () => handleBlur("password")
							} }
						/>
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-between gap-2">
					<Button color="gray" onClick={ handleClose }>{t('USERS.cancel')}</Button>
					<Button color="primary" type="submit" isProcessing={ isResettingPassword }>
						{t('USERS.save')}
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

export default ResetPasswordModal;