import { SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import VoltageRmsCard from "src/app/components/MeasurementsUser/VoltageRmsCard.component";
import VoltageDcCard from "src/app/components/MeasurementsUser/VoltageDcCard.component";
import { isNotNull } from "src/app/utils/typeguards";
import { useTranslation } from 'react-i18next';

type Props = {
	settingsMeasurementsUser: SettingsMeasurementsUser
};

function VoltageBasicTab(props: Props) {

	const { t } = useTranslation();

	const {
		settingsMeasurementsUser,
	} = props;

	return (
		<div className="p-4 flex flex-col gap-3">
			{
				isNotNull(settingsMeasurementsUser.cfgPhaseVoltageRms) &&
                <VoltageRmsCard
                    title={t('MEASUREMENTS.phase voltage')}
                    settingsMeasurementsUser={ settingsMeasurementsUser }
                    cfgVoltageRms={ settingsMeasurementsUser.cfgPhaseVoltageRms }
                />
			}
			{
				isNotNull(settingsMeasurementsUser.cfgPhaseToPhaseVoltageRms) &&
                <VoltageRmsCard
                    title={t('MEASUREMENTS.phase to phase voltage')}
                    settingsMeasurementsUser={ settingsMeasurementsUser }
                    cfgVoltageRms={ settingsMeasurementsUser.cfgPhaseToPhaseVoltageRms }
                />
			}
			<VoltageDcCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
		</div>
	);
}

export default (VoltageBasicTab);
