import { Error } from "src/app/types/api/api.types";
import { Enum, EnumDictionary, EnumOptionsSetting, EnumSetting, MinMaxValue, Nullable, SelectOption } from "src/app/types/util.types";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { DataState, FetchPaginatedDataBasicRequestFilters, LoadingState, PaginationStateReducer } from "src/app/types/redux.types";
import { TableURLParamsKey } from "src/app/types/ui/table.types";
import Konva from "konva";
// @ts-ignore
import SaveSvgAsPng from "save-svg-as-png";
import { Timezone } from "src/app/types/api/settings.types";
import moment from "moment";
import { unitOfTime } from "moment/moment";

export const isDevelopmentEnvironment = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const getEnvValue = (value: string, defaultValue = ""): string =>
	isDevelopmentEnvironment ?
		(process.env[ value ] || defaultValue) :
		(window as any).config?.[ value ] || process.env[ value ] || defaultValue;

export const getErrorMessage = (error: Error) =>
	isDevelopmentEnvironment
		?
		`${ error.httpStatus }: ${ error.message }`
		:
		error.message;

export function round(value: number, places: number = 1, roundingFunc = Math.round) {
	const factor = Math.pow(10, places);
	return roundingFunc(value * factor) / factor;
}

export function roundToFirstNonZeroValue(value: number, roundingFunc: (x: number) => number) {
	if (value === 0 || !isFinite(value)) return value;
	let i = 0;
	let copiedValue = Number(value);
	while (Math.abs(copiedValue) < 0.1) {
		copiedValue *= 10;
		i++;
	}
	return roundingFunc(copiedValue) * Math.pow(10, -i);
}

export function roundRange(value: number, roundingFunc: (x: number) => number) {
	if (value === 0 || !isFinite(value)) return value;
	let i = 0;
	let copiedValue = Number(value);
	if (Math.abs(copiedValue) > 10) {
		while (Math.abs(copiedValue) > 10) {
			copiedValue /= 10;
			i++;
		}
	} else if (Math.abs(copiedValue) < 0.1) {
		while (Math.abs(copiedValue) < 0.1) {
			copiedValue *= 10;
			i--;
		}
	}
	return roundingFunc(copiedValue) * Math.pow(10, i);
}

export function getSignificantDigitRound(value: number, significantDigits = 5) {
	const ceilLength = Math.ceil(value).toString().length;
	const afterDot = significantDigits - ceilLength;

	return round(value, afterDot);
}

export const mapEnumToSelectOptions =
	<T extends string | number | symbol>(
		enumName: any,
		enumDictionary?: EnumDictionary<T, string>,
	): SelectOption<T>[] =>
		Object
			.keys(enumName)
			.map(key => ({
					value: enumName[ key ],
					label: isNotNull(enumDictionary) ? enumDictionary()[ enumName[ key ] as T ] : formatEnumValue(enumName[ key ]),
				}),
			);

export const formatEnumValue = (enumValue: string) => {
	const replacedUnderscore = enumValue.replace("_", " ");
	return replacedUnderscore.charAt(0).toUpperCase() + replacedUnderscore.slice(1).toLowerCase();
};

// Pagination
export const reverseTranslation = <T extends string | number | symbol>(translatedValues: string[], dictionary: EnumDictionary<T, string>): T[] =>
	translatedValues
		.map(translatedValue => {
			const entry =
				Object.entries<string>(dictionary())
					  .find((entry) => {
						  const value = entry[ 1 ];

						  return value === translatedValue;
					  }) as [ T, string ];

			return entry[ 0 ];
		})
		.filter(isNotNull);

export const isDifferentPaginationOptions = (
	pageSizes: { actualPageSize: number, newPageSize: number },
	searches: { actualSearch: Nullable<string>, newSearch: Nullable<string> },
	sorts: { actualSort: Nullable<string>, newSort: Nullable<string> },
	filters: { actualFilters: FetchPaginatedDataBasicRequestFilters, newFilters: FetchPaginatedDataBasicRequestFilters },
) => (
	pageSizes.actualPageSize !== pageSizes.newPageSize ||
	searches.actualSearch !== searches.newSearch ||
	sorts.actualSort !== sorts.newSort ||
	!isSamePaginationFilters(filters.newFilters, filters.actualFilters)
);

const isSamePaginationFilters = (newFilters: { [ p: string ]: string[] }, actualFilters: { [ p: string ]: string[] }): boolean => {
	const isFiltersDifferent = Object.keys(newFilters).some(filterKey => {
		const newFilterValues = newFilters[ filterKey ] ?? [];
		const actualFilterValues = actualFilters[ filterKey ] ?? [];
		if (newFilterValues.length === 0 && actualFilterValues.length === 0) { // Filters from current key are empty = same
			return false;
		} else if (newFilterValues.length !== actualFilterValues.length) { // Filters from current keys has different length = !same
			return true;
		} else {
			return newFilterValues.some(newFilterValue => !actualFilterValues.includes(newFilterValue)); // Filters from current key has same values, lengthA = lengthB
		}
	});

	return !isFiltersDifferent;
};

export const getBoundaryPageIndexes = (
	statePages: PaginationStateReducer<any>["pages"],
	requestingPageIndex: number,
	options: {
		maxPageIndex: number,
		boundaryPagesOffset?: number
	},
): number[] => {
	const DEFAULT_BOUNDARY_PAGE_OFFSET = 1;
	const BOUNDARY_PAGES_OFFSET = options.boundaryPagesOffset ?? DEFAULT_BOUNDARY_PAGE_OFFSET;
	return new Array((2 * BOUNDARY_PAGES_OFFSET) + 1)
		.fill(0)
		.map((pageIndex, index) => requestingPageIndex - (BOUNDARY_PAGES_OFFSET - index))
		.filter(pageIndex => pageIndex !== requestingPageIndex)
		.filter(pageIndex => pageIndex >= 0 && pageIndex <= options.maxPageIndex)
		.filter(pageIndex => {
			const page = statePages.find(page => page.pageIndex === pageIndex);
			return (
				isNull(page) ||
				(page.data.dataState === DataState.NOT_PRESENT && page.data.loadingState === LoadingState.NOT_LOADING)
			);
		});
};

export const getMaxPageIndex = (
	totalCount: number,
	pageSize: number,
) => {
	// TotalCount / PageSize -> MaxPageIndex
	// 9 / 10 -> 0
	// 10 / 10 -> 0
	// 11 / 10 -> 1
	const maxPageIndex = totalCount / pageSize;
	return Math.floor(maxPageIndex) - (Number.isInteger(maxPageIndex) ? 1 : 0);
};

export const getFiltersFromUrl = (urlFilters: string[], filterValueSeparator: string = TableURLParamsKey.FILTER_SEPARATOR) => {
	const highestIndex = urlFilters.reduce((prev, next) => Math.max(prev, +next.split(filterValueSeparator)[ 0 ]), 0);
	return urlFilters.reduce<string[][]>((prev, next) => {
		const [ index, value ] = next.split(filterValueSeparator);
		return [ ...prev.slice(0, +index), [ ...prev[ +index ], value ], ...prev.slice(+index + 1, prev.length) ];
	}, new Array(highestIndex + 1).fill([]));
};

export const getBrowserInfo = () => {
	let nAgt = navigator.userAgent;
	let browserName = navigator.appName;
	let fullVersion = "" + parseFloat(navigator.appVersion);
	let majorVersion: number, nameOffset, verOffset, ix;

	// In Opera, the true version is after "OPR" or after "Version"
	if ((verOffset = nAgt.indexOf("OPR")) != -1) {
		browserName = "Opera";
		fullVersion = nAgt.substring(verOffset + 4);
		if ((verOffset = nAgt.indexOf("Version")) != -1) {
			fullVersion = nAgt.substring(verOffset + 8);
		}
	}
	// In MS Edge, the true version is after "Edg" in userAgent
	else if ((verOffset = nAgt.indexOf("Edg")) != -1) {
		browserName = "Microsoft Edge";
		fullVersion = nAgt.substring(verOffset + 4);
	}
	// In MSIE, the true version is after "MSIE" in userAgent
	else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
		browserName = "Microsoft Internet Explorer";
		fullVersion = nAgt.substring(verOffset + 5);
	}
	// In Chrome, the true version is after "Chrome"
	else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
		browserName = "Chrome";
		fullVersion = nAgt.substring(verOffset + 7);
	}
	// In Safari, the true version is after "Safari" or after "Version"
	else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
		browserName = "Safari";
		fullVersion = nAgt.substring(verOffset + 7);
		if ((verOffset = nAgt.indexOf("Version")) != -1) {
			fullVersion = nAgt.substring(verOffset + 8);
		}
	}
	// In Firefox, the true version is after "Firefox"
	else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
		browserName = "Firefox";
		fullVersion = nAgt.substring(verOffset + 8);
	}
	// In most other browsers, "name/version" is at the end of userAgent
	else if ((nameOffset = nAgt.lastIndexOf(" ") + 1) <
		(verOffset = nAgt.lastIndexOf("/"))) {
		browserName = nAgt.substring(nameOffset, verOffset);
		fullVersion = nAgt.substring(verOffset + 1);
		if (browserName.toLowerCase() == browserName.toUpperCase()) {
			browserName = navigator.appName;
		}
	}
	// trim the fullVersion string at semicolon/space if present
	if ((ix = fullVersion.indexOf(";")) != -1) {
		fullVersion = fullVersion.substring(0, ix);
	}
	if ((ix = fullVersion.indexOf(" ")) != -1) {
		fullVersion = fullVersion.substring(0, ix);
	}

	majorVersion = parseInt("" + fullVersion, 10);
	if (isNaN(majorVersion)) {
		fullVersion = "" + parseFloat(navigator.appVersion);
		majorVersion = parseInt(navigator.appVersion, 10);
	}

	let OSName = "Unknown OS";
	if (navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";
	if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";
	if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
	if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";

	return {
		browserName,
		fullVersion,
		majorVersion,
		appName: navigator.appName,
		userAgent: navigator.userAgent,
		OS: OSName,
	};
};

export const saveStockchart = (name: string, element: Nullable<Element>, background: string = "#ffffff") => {
	const doc = document;
	var dx = 0;
	var dy = 0;

	const targetWidth = 1920;

	// Get svg for it's dimensions
	const svgElement = element?.getElementsByClassName("react-stockchart")[ 0 ]?.getElementsByTagName("svg")[ 0 ];
	if (!svgElement) return;

	// SVG Dimensions
	const svgWidth = svgElement.clientWidth || 0;
	const svgHeight = svgElement.clientHeight || 0;

	// Calc height for keeping the original scale
	const targetHeight = targetWidth * (svgHeight / svgWidth);

	SaveSvgAsPng.svgAsDataUri(svgElement, { scale: 1 }, function (uri: string) {
		var image = new Image();
		image.onload = function () {
			var canvas = doc.createElement("canvas");
			canvas.width = targetWidth;
			canvas.height = targetHeight;

			var context = canvas.getContext("2d");
			if (isNull(context)) return;

			context.fillStyle = background;
			context.fillRect(0, 0, canvas.width, canvas.height);

			context.drawImage(image, dx, dy, targetWidth, targetHeight);

			var a = document.createElement("a");
			const dataURL = canvas.toDataURL("image/png");

			// console.log(getDataURLByteLength(dataURL);

			a.setAttribute("href", dataURL);
			a.setAttribute("download", `${ name }.png`);

			document.body.appendChild(a);
			a.addEventListener("click", function () {
				a.parentNode?.removeChild(a);
			});

			a.click();
		};
		image.src = uri;
	});
};

export const saveKonvaChart = (name: string, element: Konva.Stage) => {
	const targetWidth = 1920;
	const width = element.attrs.width;
	const pixelRatio = targetWidth / width;
	const dataURL = element.toDataURL({ pixelRatio });
	// console.log(getDataURLByteLength(dataURL);
	let link = document.createElement("a");
	link.download = name;
	link.href = dataURL;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const getDataURLByteLength = (dataURL: string): number => {
	const base64String = dataURL.replace(/^data:image\/png;base64,/, "");
	return (base64String.length * (3 / 4)) - ((base64String.match(/=+$/) || []).length);
};

export const getTimezone = (timeZoneOffset: number): Timezone => {
	switch (timeZoneOffset) {
		case -12 * 60:
			return Timezone.UTC_MINUS_12;
		case -11 * 60:
			return Timezone.UTC_MINUS_11;
		case -10 * 60:
			return Timezone.UTC_MINUS_10;
		case -9.5 * 60:
			return Timezone.UTC_MINUS_0930;
		case -9 * 60:
			return Timezone.UTC_MINUS_09;
		case -8 * 60:
			return Timezone.UTC_MINUS_08;
		case -7 * 60:
			return Timezone.UTC_MINUS_07;
		case -6 * 60:
			return Timezone.UTC_MINUS_06;
		case -5 * 60:
			return Timezone.UTC_MINUS_05;
		case -4 * 60:
			return Timezone.UTC_MINUS_04;
		case -3.5 * 60:
			return Timezone.UTC_MINUS_0330;
		case -3 * 60:
			return Timezone.UTC_MINUS_03;
		case -2 * 60:
			return Timezone.UTC_MINUS_02;
		case -60:
			return Timezone.UTC_MINUS_01;
		case 0:
			return Timezone.UTC_00;
		case 60:
			return Timezone.UTC_01;
		case 2 * 60:
			return Timezone.UTC_02;
		case 3 * 60:
			return Timezone.UTC_03;
		case 3.5 * 60:
			return Timezone.UTC_0330;
		case 4 * 60:
			return Timezone.UTC_04;
		case 4.5 * 60:
			return Timezone.UTC_0430;
		case 5 * 60:
			return Timezone.UTC_05;
		case 5.5 * 60:
			return Timezone.UTC_0530;
		case 5.75 * 60:
			return Timezone.UTC_0545;
		case 6 * 60:
			return Timezone.UTC_06;
		case 6.5 * 60:
			return Timezone.UTC_0630;
		case 7 * 60:
			return Timezone.UTC_07;
		case 8 * 60:
			return Timezone.UTC_08;
		case 8.75 * 60:
			return Timezone.UTC_0845;
		case 9 * 60:
			return Timezone.UTC_09;
		case 9.5 * 60:
			return Timezone.UTC_0930;
		case 10 * 60:
			return Timezone.UTC_10;
		case 10.5 * 60:
			return Timezone.UTC_1030;
		case 11 * 60:
			return Timezone.UTC_11;
		case 12 * 60:
			return Timezone.UTC_12;
		case 12.75 * 60:
			return Timezone.UTC_1245;
		case 13 * 60:
			return Timezone.UTC_13;
		case 14 * 60:
			return Timezone.UTC_14;
		default:
			return Timezone.UTC_00;
	}
};

export const formatDuration = (duration: Nullable<number>, unit: Nullable<unitOfTime.Base>) => {
	if (isNull(duration) || isNull(unit)) return "---";
	const durationMs = moment.duration(duration, unit).asMilliseconds();
	const timeBaseMoment = moment.utc(durationMs);
	if (durationMs >= 24 * 60 * 60 * 1000) {
		const days = Math.floor(durationMs / (24 * 60 * 60 * 1000));
		return `${ days }d ${ timeBaseMoment.format(`H[h] m[m] s[s]`) }`;
	} else if (durationMs >= 60 * 60 * 1000) { // Greater than 1 hour
		return timeBaseMoment.format("H[h] m[m] s[s]");
	} else if (durationMs >= 60 * 1000) { // Greater than 1 minute
		return timeBaseMoment.format("m[m] s[s]");
	} else if (durationMs >= 1000) {
		return timeBaseMoment.format("s.SS[s]");
	} else if (duration < 1000) {
		return `${ getSignificantDigitRound(duration, 2) }ms`;
	} else {
		return `${ getSignificantDigitRound(duration / 1000, 2) }ms`;
	}
};

export function translateEnumValue<T extends Enum>(enumDef: EnumSetting<T>["enum"], value: number, dictionary: EnumDictionary<T, string>): string {
	const text = getEnumValue(enumDef, value);

	if (isNull(text)) {
		return `Unknown value (${ value })`;
	}
	return dictionary()[ text ] ?? `Unknown value (${ value })`;
}

export function getEnumValue<T extends Enum>(enumDef: EnumSetting<T>["enum"], value: number): Nullable<T> {
	return enumDef.find(enumValue => enumValue.value === value)?.text;
}

export function mapEnumSettingToSelectOptions<T extends Enum>(enumSetting: Nullable<EnumSetting<T>>, dictionary: EnumDictionary<T, string>): SelectOption<number>[] {
	return enumSetting?.enum?.map(enumValue => ({
		value: enumValue.value,
		label: dictionary()[ enumValue.text ] ?? enumValue.text,
	})) ?? [];
}

// Toggle Switch
export function isOptionsContainsOption<T extends Enum>(option: T, options: EnumOptionsSetting<T>["options"]): boolean {
	return options.some(settingOption => settingOption.text === option);
}

export function getOptionMask<T extends Enum>(option: T, options: EnumOptionsSetting<T>["options"]): Nullable<number> {
	return options.find(settingOption => settingOption.text === option)?.mask;
}

export function isOptionSelected<T extends Enum>(value: number, option: T, options: EnumOptionsSetting<T>["options"]): boolean {
	const mask = getOptionMask(option, options);
	if (isNull(mask)) return false;

	return (value & mask) !== 0;
}

export function translateValueToPercent(field: Nullable<MinMaxValue<number>>): number {
	if (isNull(field)) return 0;

	const newMin = 0;
	const newMax = 100;
	const {
		value: oldValue,
		minValue: oldMin,
		maxValue: oldMax,
	} = field;

	return (((oldValue - oldMin) * (newMax - newMin)) / (oldMax - oldMin)) + newMin;
}

export function translateValueFromPercent(field: MinMaxValue<number>, oldValue: number): number {
	const oldMin = 0;
	const oldMax = 100;
	const {
		minValue: newMin,
		maxValue: newMax,
	} = field;

	return (((oldValue - oldMin) * (newMax - newMin)) / (oldMax - oldMin)) + newMin;
}
