import QueryParamTabs from "src/app/components/Utils/QueryParamTabs.component";
import { SettingsIec, SettingsModbus } from "src/app/types/api/settings.types";
import ModbusRtuCard from "src/app/components/Communications/ModbusRtuCard.component";
import ModbusTcpCard from "src/app/components/Communications/ModbusTcpCard.component";
import Rs485Card from "src/app/components/Communications/Rs485Card.component";
import Iec61850Card from "src/app/components/Communications/Iec61850Card.component";
import { useTranslation } from "react-i18next";

type Props = {
	settingsModbus: SettingsModbus
	settingsIec: SettingsIec
	fetchIecSettingsProfile: (profileName: string) => void
};

enum CommunicationsTabs {
	RS485 = "rs-485",
	MODBUS_TCP = "modbus-tcp",
	MODBUS_RTU = "modbus-rtu",
	IEC61850 = "iec-61850"
}

function CommunicationsContainer(props: Props) {

	const {
		settingsModbus,
		settingsIec,
		fetchIecSettingsProfile,
	} = props;

	const { t } = useTranslation();

	return (
		<div>
			<QueryParamTabs<CommunicationsTabs>
				style="underline"
				items={ () => ({
					[ CommunicationsTabs.RS485 ]: {
						name: t("COMMUNICATIONS.RS-485"),
						component: (
							<Rs485Card/>
						),
					},
					[ CommunicationsTabs.MODBUS_TCP ]: {
						name: t("COMMUNICATIONS.modbus tcp"),
						component: (
							<ModbusTcpCard
								settingsModbus={ settingsModbus }
							/>
						),
					},
					[ CommunicationsTabs.MODBUS_RTU ]: {
						name: t("COMMUNICATIONS.modbus rtu"),
						component: (
							<ModbusRtuCard
								settingsModbus={ settingsModbus }
							/>
						),
					},
					[ CommunicationsTabs.IEC61850 ]: {
						name: t("COMMUNICATIONS.iec 61850"),
						component: (
							<Iec61850Card
								settingsIec={ settingsIec }
								fetchIecSettingsProfile={ fetchIecSettingsProfile }
							/>
						),
					},
				}) }
			/>
		</div>
	);
}

export default (CommunicationsContainer);
