import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import MeasurementsContainer from "src/app/containers/Settings/Measurements.container";
import { getMeasurementsSettings } from "src/app/store/features/settings/settings.selectors";
import { fetchSettingsMeasurementsStandardAsync, fetchSettingsMeasurementsUserAsync } from "src/app/store/features/settings/settings.actions";
import CallbackPromptProvider from "src/app/hoc/providers/CallbackPrompt.provider";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function MeasurementsView(props: Props) {

	const {
		measurementsSettings,
		fetchMeasurementsStandard,
		fetchMeasurementsUser,
	} = props;

	const _handleRequest = () => {
		fetchMeasurementsStandard();
		fetchMeasurementsUser();
	};

	return (
		<CallbackPromptProvider>
			<CachedThenFreshStrategy
				request={ _handleRequest }
				state={ measurementsSettings }
			>
				{
					({ standard, user }) =>
						<MeasurementsContainer
							settingsStandard={ standard }
							settingsUser={ user }
						/>
				}
			</CachedThenFreshStrategy>
		</CallbackPromptProvider>
	);
}

const mapStateToProps = (state: RootState) => ({
	measurementsSettings: getMeasurementsSettings(state),
});

const mapDispatchToProps = {
	fetchMeasurementsStandard: fetchSettingsMeasurementsStandardAsync.request,
	fetchMeasurementsUser: fetchSettingsMeasurementsUserAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(MeasurementsView);
