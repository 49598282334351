import RecordingEventsCard from "src/app/components/Recording/RecordingEventsCard.component";
import { SettingsRecording } from "src/app/types/api/settings.types";

type Props = {
	settingsRecording: SettingsRecording
};

function RecordingsContainer(props: Props) {

	const {
		settingsRecording,
	} = props;

	return (
		<RecordingEventsCard
			settingsRecording={ settingsRecording }
		/>
	);
}

export default (RecordingsContainer);
