import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { isDevelopmentEnvironment } from "src/app/utils/helpers";
import { FailurePayload, SuccessPayload } from "src/app/types/api/api.types";
import camelcaseKeys from "camelcase-keys";
import { baseUrl, isHostSource } from "src/app/utils/constants/constants";
import { isNotNull } from "src/app/utils/typeguards";

export const abortController = new AbortController();

export const request = (config: AxiosRequestConfig & { decoder?: (input: Uint8Array) => any, noCamelize: boolean }) =>
	axios.request({
		...config,
		data: config.data,
		url: isHostSource
			?
			`${ window.location.protocol }//${ window.location.host }/api${ config.url }`
			:
			`${ baseUrl }${ config.url }`,
		// url: `http://localhost:3006${ config.url }`,
		headers: config.headers,
	}).then((response: AxiosResponse<SuccessPayload<any>>) => {
		if (isNotNull(config.responseType) && config.responseType === "arraybuffer" && isNotNull(config.decoder)) {
			try {
				const arrayBuffer = response.data as unknown as ArrayBuffer;
				const uint = new Uint8Array(arrayBuffer);
				const camelizeResponse = camelcaseKeys(response, { deep: true });
				isDevelopmentEnvironment && console.log("SUCCESS", {
					...camelizeResponse,
					data: config.decoder(uint),
				});
				return Promise.resolve({
					...camelizeResponse,
					data: config.decoder(uint),
				});
			} catch (e) {
				return Promise.reject(e);
			}
		}
		if (config.noCamelize) {
			isDevelopmentEnvironment && console.log("SUCCESS", response);
			return Promise.resolve(response as unknown as any);
		}
		const camelizeResponse = camelcaseKeys(response, { deep: true });
		isDevelopmentEnvironment && console.log("SUCCESS", camelizeResponse);
		return Promise.resolve(camelizeResponse);
	}).catch((error: AxiosError<FailurePayload>) => {
		const camelizeError: AxiosError<FailurePayload> = camelcaseKeys(error);
		isDevelopmentEnvironment && console.log("ERROR", camelizeError.response);
		if (camelizeError.response) return Promise.resolve(camelizeError.response);
		return Promise.reject(camelizeError);
	});
