import { SettingsMeasurementsUser } from "src/app/types/api/settings.types";
import CurrentThdfCard from "src/app/components/MeasurementsUser/CurrentThdfCard.component";
import CurrentThdrCard from "src/app/components/MeasurementsUser/CurrentThdrCard.component";
import CurrentHarmonicsCard from "src/app/components/MeasurementsUser/CurrentHarmonicsCard.component";
import CurrentTidrCard from "src/app/components/MeasurementsUser/CurrentTidrCard.component";
import CurrentTidfCard from "src/app/components/MeasurementsUser/CurrentTidfCard.component";
import CurrentInterharmonicsCard from "src/app/components/MeasurementsUser/CurrentInterharmonicsCard.component";

type Props = {
	settingsMeasurementsUser: SettingsMeasurementsUser
};

function HarmonicsCurrentTab(props: Props) {

	const {
		settingsMeasurementsUser,
	} = props;

	return (
		<div className="p-4 flex flex-col gap-3">
			<CurrentThdfCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<CurrentThdrCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<CurrentTidfCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<CurrentTidrCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<CurrentHarmonicsCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
			<CurrentInterharmonicsCard
				settingsMeasurementsUser={ settingsMeasurementsUser }
			/>
		</div>
	);
}

export default (HarmonicsCurrentTab);
