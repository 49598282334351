import { combineReducers, Reducer } from "redux";
import { StateType } from "typesafe-actions";
import { RouterState } from "redux-first-history";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import messageReducer from "src/app/store/features/message/message.reducer";
import formReducer from "src/app/store/features/form/form.reducer";
import userReducer from "src/app/store/features/user/user.reducer";
import miscReducer from "src/app/store/features/misc/misc.reducer";
import uiReducer from "src/app/store/features/ui/ui.reducer";
import dashboardReducer from "src/app/store/features/dashboard/dashboard.reducer";
import firmwareReducer from "src/app/store/features/firmware/firmware.reducer";
import settingsReducer from "src/app/store/features/settings/settings.reducer";
// Reducers imports - don't remove

export type RootState = StateType<ReturnType<typeof rootReducer>>;

const miscPersistConfig = {
	key: "misc",
	storage: storage,
	whitelist: [ "impersonateUserId", "language" ],
};

const userPersistConfig = {
	key: "loggedUser",
	storage: storage,
	whitelist: [ "loggedUser" ],
};

const rootReducer = (routerReducer: Reducer<RouterState>) =>
	combineReducers({
		router: routerReducer,
		message: messageReducer,
		form: formReducer,
		misc: persistReducer(miscPersistConfig, miscReducer),
		ui: uiReducer,
		// ws: wsReducer,
		user: persistReducer(userPersistConfig, userReducer),
		dashboard: dashboardReducer,
		firmware: firmwareReducer,
		settings: settingsReducer,
		// Reducers object - don't remove
	});

export default rootReducer;
