import FileInput from "src/app/components/Form/FileInput.component";
import { Button, Card, Progress } from "flowbite-react";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { GridLoader } from "react-spinners";
import { Form } from "src/app/types/ui/form.types";
import { isNull } from "src/app/utils/typeguards";
import React from "react";
import { HiDocumentCheck } from "react-icons/hi2";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { UploadFirmwareForm } from "src/app/utils/constants/firmwareUpgrade.form";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		uploadFirmware: () => void
		form: Form<UploadFirmwareForm>
		handleChange: (prop: keyof UploadFirmwareForm, value: any) => void
	};

function UploadFirmware(props: Props) {

	const { t } = useTranslation();

	const {
		form,
		handleChange,
		uploadFirmware,
		isUploading,
		isProcessing,
		uploadProgress,
	} = props;

	return (
		<Card>
			<div className="flex flex-col justify-between gap-2">
				<h5 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white leading-none">
					{ t("FIRMWARE.upload firmware file") }
				</h5>
				<p>{ t("FIRMWARE.upload instruction") }</p>
			</div>
			{
				isNull(form.file.value) ?
					<FileInput
						buttonText={ t("FIRMWARE.upload or drag firmware") }
						name="firmware"
						className="[&>div:first-child]:py-14 flex-grow"
						onChange={ file => handleChange("file", file) }
						options={ {
							disabled: isUploading,
							accept: {
								"application/octet-stream": [ ".pqf" ],
							},
						} }
						accept=".pqf"
					/>
					:
					<div className="relative py-14 flex items-center justify-between px-4 border-2 rounded-lg border-green-400 transition duration-100 ease-in-out bg-transparent">
						<div className="flex items-center gap-3">
							<HiDocumentCheck className="w-10 h-10 text-green-400"/>
							<div className="flex flex-col gap-1">
								<div className="text-sm dark:text-white">{ form.file.value.name }</div>
								<div className="text-xs text-gray-400">{ Math.round((form.file.value.size / 1024 / 1000) * 10) / 10 }MB</div>
							</div>
						</div>
						<div className="flex gap-2">
							<Button
								color="failure"
								onClick={ () => handleChange("file", null) }
								disabled={ isUploading || isProcessing }
							>
								{ t("FIRMWARE.cancel") }
							</Button>
							<Button
								color="primary"
								onClick={ uploadFirmware }
								disabled={ isUploading || isProcessing }
							>
								{ t("FIRMWARE.upload") }
							</Button>
						</div>
					</div>
			}
			{
				(isUploading && !isProcessing) &&
                <div className="relative">
                    <Progress
                        className="[&>div]:transition-[width]"
                        progress={ uploadProgress }
                        progressLabelPosition="inside"
                        textLabelPosition="inside"
                        size="lg"
                    />
                    <span
                        className={
							classNames(
								"absolute text-sm top-[1px] -translate-x-1/2 left-1/2 leading-none",
								{ "text-white": uploadProgress > 51 },
							)
						}
                    >
						{ `${ uploadProgress }%` }
					</span>
                </div>
			}
			{
				isProcessing &&
                <div className="flex gap-4 items-center justify-center">
                    <GridLoader size={ 14 } color="#0093DD"/>
                    <div>
                        <p className="font-semibold">{ t("FIRMWARE.file has been uploaded") }</p>
                        <p className="font-semibold">{ t("FIRMWARE.wait for meter") }</p>
                    </div>
                </div>
			}
			{/*<div>
				<ol className="list-decimal pl-4 text-base text-gray-900">
					<li>User initiates file upload process by dragging and dropping a file onto field presented above.</li>
					<li>The system displays a progress bar indicating the percentage of the file that has been successfully uploaded to the meter.</li>
					<li>Once the upload is complete, the system checks the status of the uploaded file.</li>
					<li>User can view the current version of the meter along with the version from the uploaded file for comparison purposes.</li>
					<li>After successful upload, the user is given options to either delete the uploaded file or directly load it into the meter (upgrade).</li>
				</ol>
			</div>*/ }
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isUploading: didLoadingRecordExist(state, { loadableType: LoadableType.UPLOADING_FIRMWARE_FILE }),
	isProcessing: didLoadingRecordExist(state, { loadableType: LoadableType.PROCESSING_FIRMWARE_FILE }),
	uploadProgress: state.firmware.uploadProgress,
});

export default connect(mapStateToProps)(UploadFirmware);
