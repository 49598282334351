import { isNotNull, isNull } from "src/app/utils/typeguards";
// @ts-ignore
import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";
// @ts-ignore
import { Chart, ChartCanvas } from "react-stockcharts";
// @ts-ignore
import { LineSeries } from "react-stockcharts/lib/series";
import { Nullable } from "src/app/types/util.types";
import { scaleRange } from "src/app/utils/chart";
import { GridLoader } from "react-spinners";

type Props = {
	width: number
	height: number
	data: LineChartData[]
	color: string
	readOnly: true
};

export type LineChartData = {
	date: Date
	value: Nullable<number>
}

const _calculateYRange = (data: LineChartData[]): [ min: number, max: number ] => {
	const firstValue = data[ 0 ];
	if (isNull(firstValue) || isNull(firstValue.value)) return [ 0, 500 ];
	const yAxisRange: [ number, number ] = data.reduce((prev, next) => [
		Math.min(...[ prev[ 0 ], next.value ].filter(isNotNull)),
		Math.max(...[ prev[ 1 ], next.value ].filter(isNotNull)),
	], [ firstValue.value, firstValue.value ]);

	return scaleRange(yAxisRange);
};

const xExtents = [ 0, 60 ];

function LineChart(props: Props) {

	const {
		data,
		width,
		height,
		color,
		readOnly,
	} = props;

	if (data.length <= 2 * Math.ceil(xExtents[ 1 ] / width)) {
		return (
			<div style={ { height, width } }>
				<div className="w-full h-full flex items-center justify-center">
					<GridLoader size={ 8 } color="#0093DD"/>
				</div>
			</div>
		);
	}

	const yRange: [ number, number ] = _calculateYRange(data.slice(-xExtents[ 1 ]));

	const xScaleProvider = discontinuousTimeScaleProvider
		.initialIndex(0)
		.inputDateAccessor((d: any) => d.date);

	const {
		data: chartData,
		xScale,
		xAccessor,
		displayXAccessor,
	} = xScaleProvider(data.slice(-xExtents[ 1 ]));

	return (
		<ChartCanvas
			ratio={ 1 }
			width={ width }
			height={ height }
			margin={ {
				left: 0,
				right: 0,
				top: 0,
				bottom: 0,
			} }
			type="svg"
			seriesName="measurements"
			data={ chartData }
			xScale={ xScale }
			xAccessor={ xAccessor }
			displayXAccessor={ displayXAccessor }
			mouseMoveEvent={ false }
			panEvent={ false }
			zoomEvent={ false }
			xExtents={ xExtents }
			useCrossHairStyleCursor={ !readOnly }
		>
			<Chart
				id="line-chart"
				yExtents={ yRange }
			>
				<LineSeries
					yAccessor={ (d: LineChartData) => d.value }
					strokeWidth={ 2 }
					stroke={ color }
				/>
			</Chart>
		</ChartCanvas>
	);
}

export default (LineChart);
