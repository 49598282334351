import { RootState } from "src/app/store/root.reducer";
import { createSelector } from "reselect";
import { ValidRoutes } from "src/app/types/ui/router.types";
import { validRoutesDictionary } from "src/app/utils/constants/dictionaries";
import { isNotNull, isNull } from "src/app/utils/typeguards";

const routerStackSelector = (state: RootState) => state.ui.router;

export const getLastFiveRoutes = createSelector(
	[
		routerStackSelector,
		(state: RootState) => state.misc.language,
	],
	(routes, language) => {
		const validRoutes = Object.values<string>(ValidRoutes);
		return routes
			.reduceRight<string[]>((prev, next) => {
				if (prev.length >= 5) return prev;
				if (prev.includes(next)) return prev;
				if (!validRoutes.includes(next)) return prev;

				return [ ...prev, next ];
			}, [])
			.map(route => {
				const translatedRoute = validRoutesDictionary(language)[ route as ValidRoutes ];
				if (isNull(translatedRoute)) return null;
				return translatedRoute;
			})
			.filter(isNotNull);
	},
);
