import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { getMessagesFromView } from "src/app/store/features/message/message.selectors";
import { View } from "src/app/types/ui/message.types";
import { removeMessagesFromView } from "src/app/store/features/message/message.actions";
import { useEffect } from "react";
import SnackbarComponent from "src/app/components/Message/Snackbar.component";
import classNames from "classnames";
import { addMessageContentDictionary } from "src/app/utils/constants/dictionaries";

type ComponentProps = {
	className?: string
	view: View
}

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps
	& ComponentProps;

function StaticSnackbar(props: Props) {

	const {
		className = "",
		view,
		snackbarMessages,
		removeMessages,
		appLanguage,
	} = props;

	useEffect(() => {
		return () => {
			removeMessages(view);
		};
	}, []);

	if (snackbarMessages.length === 0) return null;

	return (
		<div
			className={
				classNames(
					className,
					"flex flex-col gap-y-2",
				)
			}
		>
			{
				snackbarMessages.map(message =>
					<SnackbarComponent
						key={ message.id }
						type={ message.type }
						message={ addMessageContentDictionary()[ message.message ] }
					/>,
				)
			}
		</div>
	);
}

const mapStateToProps = (state: RootState, props: ComponentProps) => ({
	snackbarMessages: getMessagesFromView(state, props.view),
	appLanguage: state.misc.language,
});

const mapDispatchToProps = {
	removeMessages: removeMessagesFromView,
};

export default connect(mapStateToProps, mapDispatchToProps)(StaticSnackbar);
