import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { Button } from "flowbite-react";
import classNames from "classnames";
import ConfirmModal from "src/app/components/Utils/ConfirmModal.component";
import { useContext, useState } from "react";
import { createFormField } from "src/app/utils/forms";
import ConfirmConfigurationModal from "src/app/components/Layout/ConfirmConfigurationModal.component";
import { uiSetBufforConfiguration } from "src/app/store/features/ui/settings/ui.settings.actions";
import { OnOff } from "src/app/types/api/settings.types";
import { SettingsContext } from "src/app/hoc/providers/Settings.provider";
import { getTimezone, translateValueFromPercent, translateValueToPercent } from "src/app/utils/helpers";
import { timezoneNumberDictionary } from "src/app/utils/constants/dictionaries";
import moment from "moment";
import { browserTimeZoneOffsetMinutes } from "src/app/containers/Settings/Time.container";
import {
	translateActivePowerMinusConfig,
	translateActivePowerPlusConfig,
	translateApparentPowerConfig,
	translateCurrentConfig,
	translateCurrentThdfConfig,
	translateDipsConfig,
	translateDisplacementPowerFactorConfig,
	translateFlickerPltConfig,
	translateFlickerPstConfig,
	translateFrequencyConfig,
	translateInterruptConfig,
	translatePhaseJumpConfig,
	translatePowerFactorConfig,
	translateReactivePowerConfig,
	translateSwellConfig,
	translateVoltageThdfConfig,
	translateWaveshapeVariationsConfig,
} from "src/app/utils/configuration";
import { isArray, isNotNull } from "src/app/utils/typeguards";
import { SetBufforRecordingPayload } from "src/app/types/api/dashboard.types";
import { useTranslation } from "react-i18next";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps
	& {
		className?: string
	};

function ConfigurationSaver(props: Props) {

	const { t } = useTranslation();

	const {
		className,
		saveConfiguration,
	} = props;

	const {
		form,
		setForm,
	} = useReducerForm(
		"buffor",
		bufforFormActions,
		() => undefined,
	);

	const {
		recording,
		modbus,
		ftp,
		time,
		memory,
		measurementsUser,
		measurementsStandard,
		iec,
		io,
	} = useContext(SettingsContext);

	const [ isDiscardModalOpen, toggleDiscardModal ] = useState(false);
	const [ isConfirmModalOpen, toggleConfirmModal ] = useState(false);

	const length = Object.values(form).filter(formItem => {
		if (isArray(formItem.value) && isArray(formItem.initialValue)) {
			const formItemValue: any[] = formItem.value;
			const formItemInitialValue: any[] = formItem.initialValue;
			return formItemValue.length !== formItemInitialValue.length || formItemInitialValue.some(value => !formItemValue.includes(value));
		}
		return formItem.value !== formItem.initialValue;
	}).length;

	const _handleSaveConfiguration = () => {
		const eventsHysteresis = recording.recording?.cfgEventsHysteresis?.value ?? 0;
		const waveformFormat = recording.recording?.cfgWaveformFormat?.value ?? 0;
		const waveformRecDuration = recording.recording?.cfgWaveformRecDuration?.value ?? 0;
		const waveformPretriggerDuration = recording.recording?.cfgWaveformPretriggerDuration?.value ?? 0;
		const rms12RecTime = recording.recording?.cfgRms12RecTime?.value ?? 0;
		const rms12PretriggerTime = recording.recording?.cfgRms12PretriggerTime?.value ?? 0;

		const type = recording.recording?.cfgSystemType?.value ?? 0;
		const ch4Enabled = recording.recording?.cfgCurrentCh4Enable?.enum?.find(enumValue => enumValue.value === recording.recording?.cfgCurrentCh4Enable?.value)?.text === OnOff.ON;
		const ch5Enabled = recording.recording?.cfgCurrentCh5Enable?.enum?.find(enumValue => enumValue.value === recording.recording?.cfgCurrentCh5Enable?.value)?.text === OnOff.ON;
		const nominalFrequency = recording.recording?.cfgNominalFrequency?.value ?? 0;
		const nominalVoltage = recording.recording?.cfgNominalVoltage?.value ?? 0;
		const unpeChannelEnable = recording.recording?.cfgUnpeChannelEnable?.enum?.find(enumValue => enumValue.value === recording.recording?.cfgUnpeChannelEnable?.value)?.text === OnOff.ON;
		const voltageTransducerRatio = recording.recording?.cfgVoltageTransducerRatio?.value ?? 0;
		const cfgCurrentCh1TransducerRatio = recording.recording?.cfgCurrentCh1TransducerRatio?.value ?? 0;
		const cfgCurrentCh2TransducerRatio = recording.recording?.cfgCurrentCh2TransducerRatio?.value ?? 0;
		const cfgCurrentCh3TransducerRatio = recording.recording?.cfgCurrentCh3TransducerRatio?.value ?? 0;
		const cfgCurrentCh4TransducerRatio = recording.recording?.cfgCurrentCh4TransducerRatio?.value ?? 0;
		const cfgCurrentCh5TransducerRatio = recording.recording?.cfgCurrentCh5TransducerRatio?.value ?? 0;
		const phaseRotation = recording.recording?.cfgPhaseRotation?.value ?? 0;

		const userRecordingPerc = memory.memory?.cfgUserRecordingSpaceQuotaInPercent?.value ?? 0;
		const stdRecordingPerc = memory.memory?.cfgStdRecordingSpaceQuotaInPercent?.value ?? 0;
		const energyCountersPerc = memory.memory?.cfgEnergyCountersSpaceQuotaInPercent?.value ?? 0;
		const userDataModel = memory.memory?.cfgUserDataMemoryModel?.value ?? 0;
		const stdDataModel = memory.memory?.cfgStdDataMemoryModel?.value ?? 0;
		const energyDataModel = memory.memory?.cfgEnergyDataMemoryModel?.value ?? 0;

		const tcpEnabled = modbus.communications?.cfgModbusTcpEnable?.enum?.find(enumValue => enumValue.value === modbus.communications?.cfgModbusTcpEnable?.value)?.text === OnOff.ON;
		const tcpSlaveAddress = modbus.communications?.cfgModbusTcpSlaveAddress?.value ?? 0;
		const tcpPort = modbus.communications?.cfgModbusTcpPort?.value ?? 0;
		const tcpEndianness = modbus.communications?.cfgModbusTcpEndianness?.value ?? 0;

		const rtuEnabled = modbus.communications?.cfgModbusRtuEnable?.enum?.find(enumValue => enumValue.value === modbus.communications?.cfgModbusRtuEnable?.value)?.text === OnOff.ON;
		const rtuSlaveAddress = modbus.communications?.cfgModbusRtuSlaveAddress?.value ?? 0;
		const rtuBaudrate = modbus.communications?.cfgModbusRtuBaudrate?.value ?? 0;
		const rtuParity = modbus.communications?.cfgModbusRtuParity?.value ?? 0;
		const rtuEndianness = modbus.communications?.cfgModbusRtuEndianness?.value ?? 0;

		const ftpEnable = ftp.communications?.cfgFtpEnable?.enum?.find(enumValue => enumValue.value === ftp.communications?.cfgFtpEnable?.value)?.text === OnOff.ON;
		const ftpConnectionType = ftp.communications?.cfgFtpConnectionType?.value ?? 0;
		const ftpMode = ftp.communications?.cfgFtpMode?.value ?? 0;
		const ftpUploadTime = ftp.communications?.cfgFtpUploadTime?.value ?? 0;
		const ftpServerIp = ftp.communications?.cfgFtpServerIp?.value ?? 0;
		const ftpServerPort = ftp.communications?.cfgFtpServerPort?.value ?? 0;
		const ftpUserName = ftp.communications?.cfgFtpUserName?.value ?? 0;
		const ftpPassword = ftp.communications?.cfgFtpPassword?.value ?? 0;
		const ftpDestDirectory = ftp.communications?.cfgFtpDestDirectory?.value ?? 0;

		const timeSyncMode = time.time?.cfgTimeSyncMode?.value ?? 0;
		const timezone = getTimezone(time.time?.cfgTimezone?.value ?? 0);
		const daylightSavingsTime = time.time?.cfgDaylightSavingsTime?.enum?.find(enumValue => enumValue.value === time.time?.cfgDaylightSavingsTime?.value)?.text === OnOff.ON;
		const rtc = moment((time.time?.rtcUtcTimestamp ?? 0) * 1000)
			.subtract({ minutes: browserTimeZoneOffsetMinutes })
			.add({ minutes: ((time.time?.cfgTimezone?.value ?? 0) + (daylightSavingsTime ? 60 : 0)) })
			.format("YYYY-MM-DD HH:mm:ss");

		const rtcLocal = (time.time?.rtcUtcTimestamp ?? 0) + (moment(form.rtcUtcTimestamp.value, "YYYY-MM-DD HH:mm:ss").unix() - moment(form.rtcUtcTimestamp.initialValue, "YYYY-MM-DD HH:mm:ss").unix());

		const NTPEnable = time.time?.cfgNtpEnable?.enum?.find(enumValue => enumValue.value === time.time?.cfgNtpEnable?.value)?.text === OnOff.ON;
		const NTPServer1Name = time.time?.cfgNtpServer1Name?.value ?? 0;
		const NTPServer2Name = time.time?.cfgNtpServer2Name?.value ?? 0;
		const NTPServer3Name = time.time?.cfgNtpServer3Name?.value ?? 0;
		const NTPServer4Name = time.time?.cfgNtpServer4Name?.value ?? 0;

		const cfgUserEnable = measurementsUser.measurementUser?.cfgUserDataRecEnable?.enum?.find(enumValue => enumValue.value === measurementsUser.measurementUser?.cfgUserDataRecEnable?.value)?.text === OnOff.ON;
		const cfgAggregationPeriodUser = measurementsUser.measurementUser?.cfgAggregationPeriodUser?.value ?? 0;
		const cfgUserAggregationWaveformEnable = measurementsUser.measurementUser?.cfgUserWaveformsAndRms12RecEnable?.enum?.find(enumValue => enumValue.value === measurementsUser.measurementUser?.cfgUserWaveformsAndRms12RecEnable?.value)?.text === OnOff.ON;
		const cfgPowerCalculationMethod = measurementsUser.measurementUser?.cfgPowerCalculationMethod?.value ?? 0;

		const cfgVoltageRmsRecOpt = (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.cfgVoltageRmsRecOpt?.value ?? 0;
		const cfgVoltageRmsEventThresholdUserDip = (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.cfgVoltageRmsEventThresholdUserDip?.value?.toFixed(2) ?? "";
		const cfgVoltageRmsEventThresholdUserSwell = (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.cfgVoltageRmsEventThresholdUserSwell?.value?.toFixed(2) ?? "";
		const cfgVoltageRmsEventThresholdUserInterruption = (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.cfgVoltageRmsEventThresholdUserInterruption?.value?.toFixed(2) ?? "";
		const cfgVoltageRmsEventThresholdMaxCh4 = (measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms)?.cfgVoltageRmsEventThresholdMaxCh4?.value?.toFixed(2) ?? "";

		const cfgVoltageDcRecOpt = measurementsUser.cfgVoltageDc?.cfgVoltageDcRecOpt?.value ?? 0;
		const cfgWaveshapeVariationsRecOpt = measurementsUser.cfgWaveshapeVariations?.cfgWaveshapeVariationsRecOpt?.value ?? 0;
		const cfgWaveshapeVariationsDeadtime = measurementsUser.cfgWaveshapeVariations?.cfgWaveshapeVariationsDeadtime?.value?.toFixed(2) ?? "";
		const cfgWaveshapeVariationsThreshold = measurementsUser.cfgWaveshapeVariations?.cfgWaveshapeVariationsThreshold?.value?.toFixed(2) ?? "";

		const cfgPhaseJumpsRecOpt = measurementsUser.cfgPhaseJumps?.cfgPhaseJumpsRecOpt?.value ?? 0;
		const cfgPhaseJumpsThreshold = measurementsUser.cfgPhaseJumps?.cfgPhaseJumpsThreshold?.value?.toFixed(2) ?? "";

		const cfgFrequencyRecOpt = measurementsUser.cfgFrequency?.cfgFrequencyRecOpt?.value ?? 0;
		const cfgFrequencyEventThresholdMin = measurementsUser.cfgFrequency?.cfgFrequencyEventThresholdMin?.value?.toFixed(2) ?? "";
		const cfgFrequencyEventThresholdMax = measurementsUser.cfgFrequency?.cfgFrequencyEventThresholdMax?.value?.toFixed(2) ?? "";

		const cfgVoltageCrestFactorRecOpt = measurementsUser.cfgVoltageCrestFactor?.cfgVoltageCrestFactorRecOpt?.value ?? 0;
		const cfgVoltageUnbalanceRecOpt = measurementsUser.cfgVoltageUnbalance?.cfgVoltageUnbalanceRecOpt?.value ?? 0;
		const cfgVoltageUnbalanceThresholdMax = measurementsUser.cfgVoltageUnbalance?.cfgVoltageUnbalanceThresholdMax?.value?.toFixed(2) ?? "";
		const cfgFlickerPstRecOpt = measurementsUser.cfgFlickerPst?.cfgFlickerPstRecOpt?.value ?? 0;
		const cfgFlickerPstThresholdMax = measurementsUser.cfgFlickerPst?.cfgFlickerPstThresholdMax?.value?.toFixed(2) ?? "";
		const cfgFlickerPltRecOpt = measurementsUser.cfgFlickerPlt?.cfgFlickerPltRecOpt?.value ?? 0;
		const cfgFlickerPltThresholdMax = measurementsUser.cfgFlickerPlt?.cfgFlickerPltThresholdMax?.value?.toFixed(2) ?? "";
		const cfgCurrentRmsRecOpt = measurementsUser.cfgCurrentRms?.cfgCurrentRmsRecOpt?.value ?? 0;
		const cfgCurrentRmsEventThresholdMax = measurementsUser.cfgCurrentRms?.cfgCurrentRmsEventThresholdMax?.value?.toFixed(2) ?? "";
		const cfgCurrentRmsEventThresholdMaxCh4 = measurementsUser.cfgCurrentRms?.cfgCurrentRmsEventThresholdMaxCh4?.value?.toFixed(2) ?? "";
		const cfgCurrentRmsEventThresholdMaxCh5 = measurementsUser.cfgCurrentRms?.cfgCurrentRmsEventThresholdMaxCh5?.value?.toFixed(2) ?? "";
		const cfgCurrentCrestFactorRecOpt = measurementsUser.cfgCurrentCrestFactor?.cfgCurrentCrestFactorRecOpt?.value ?? 0;
		const cfgCurrentUnbalanceRecOpt = measurementsUser.cfgCurrentUnbalance?.cfgCurrentUnbalanceRecOpt?.value ?? 0;
		const cfgActivePowerRecOpt = measurementsUser.cfgActivePower?.cfgActivePowerRecOpt?.value ?? 0;
		const cfgActivePowerEventThresholdMax = measurementsUser.cfgActivePower?.cfgActivePowerEventThresholdMax?.value?.toFixed(2) ?? "";
		const cfgActivePowerPlusRecOpt = measurementsUser.cfgActivePowerPlus?.cfgActivePowerPlusRecOpt?.value ?? 0;
		const cfgActivePowerPlusEventThresholdMax = measurementsUser.cfgActivePowerPlus?.cfgActivePowerPlusEventThresholdMax?.value?.toFixed(2) ?? "";
		const cfgActivePowerMinusRecOpt = measurementsUser.cfgActivePowerMinus?.cfgActivePowerMinusRecOpt?.value ?? 0;
		const cfgActivePowerMinusEventThresholdMax = measurementsUser.cfgActivePowerMinus?.cfgActivePowerMinusEventThresholdMax?.value?.toFixed(2) ?? "";
		const cfgActivePowerFundamentalRecOpt = measurementsUser.cfgActivePowerFundamental?.cfgActivePowerFundamentalRecOpt?.value ?? 0;
		const cfgApparentPowerRecOpt = measurementsUser.cfgApparentPower?.cfgApparentPowerRecOpt?.value ?? 0;
		const cfgApparentPowerEventThresholdMax = measurementsUser.cfgApparentPower?.cfgApparentPowerEventThresholdMax?.value?.toFixed(2) ?? "";
		const cfgApparentPowerFundamentalRecOpt = measurementsUser.cfgApparentPowerFundamental?.cfgApparentPowerFundamentalRecOpt?.value ?? 0;
		const cfgApparentPowerNonfundamentalRecOpt = measurementsUser.cfgApparentPowerNonfundamental?.cfgApparentPowerNonfundamentalRecOpt?.value ?? 0;
		const cfgDistortionPowerBudeanuRecOpt = measurementsUser.cfgDistortionPowerBudeanu?.cfgDistortionPowerBudeanuRecOpt?.value ?? 0;
		const cfgReactivePowerRecOpt = measurementsUser.cfgReactivePower?.cfgReactivePowerRecOpt?.value ?? 0;
		const cfgReactivePowerEventThresholdMax = measurementsUser.cfgReactivePower?.cfgReactivePowerEventThresholdMax?.value?.toFixed(2) ?? "";
		const cfgPowerFactorRecOpt = measurementsUser.cfgPowerFactor?.cfgPowerFactorRecOpt?.value ?? 0;
		const cfgPowerFactorEventThresholdMax = measurementsUser.cfgPowerFactor?.cfgPowerFactorEventThresholdMax?.value?.toFixed(2) ?? "";
		const cfgPowerFactorFundamentalRecOpt = measurementsUser.cfgPowerFactorFundamental?.cfgPowerFactorFundamentalRecOpt?.value ?? 0;
		const cfgDisplacementPowerFactorRecOpt = measurementsUser.cfgDisplacementPowerFactor?.cfgDisplacementPowerFactorRecOpt?.value ?? 0;
		const cfgDisplacementPowerFactorEventThresholdMax = measurementsUser.cfgDisplacementPowerFactor?.cfgDisplacementPowerFactorEventThresholdMax?.value?.toFixed(2) ?? "";
		const cfgTangent4QRecOpt = measurementsUser.cfgTangent4Q?.cfgTangent4QRecOpt?.value ?? 0;
		// const cfgTangentQuadrant1EventThresholdMin = measurementsUser.cfgTangent4Q?.cfgTangentQuadrant1EventThresholdMin?.value?.toFixed(2) ?? "";
		// const cfgTangentQuadrant1EventThresholdMax = measurementsUser.cfgTangent4Q?.cfgTangentQuadrant1EventThresholdMax?.value?.toFixed(2) ?? "";
		// const cfgTangentQuadrant2EventThresholdMin = measurementsUser.cfgTangent4Q?.cfgTangentQuadrant2EventThresholdMin?.value?.toFixed(2) ?? "";
		// const cfgTangentQuadrant2EventThresholdMax = measurementsUser.cfgTangent4Q?.cfgTangentQuadrant2EventThresholdMax?.value?.toFixed(2) ?? "";
		// const cfgTangentQuadrant3EventThresholdMin = measurementsUser.cfgTangent4Q?.cfgTangentQuadrant3EventThresholdMin?.value?.toFixed(2) ?? "";
		// const cfgTangentQuadrant3EventThresholdMax = measurementsUser.cfgTangent4Q?.cfgTangentQuadrant3EventThresholdMax?.value?.toFixed(2) ?? "";
		// const cfgTangentQuadrant4EventThresholdMin = measurementsUser.cfgTangent4Q?.cfgTangentQuadrant4EventThresholdMin?.value?.toFixed(2) ?? "";
		// const cfgTangentQuadrant4EventThresholdMax = measurementsUser.cfgTangent4Q?.cfgTangentQuadrant4EventThresholdMax?.value?.toFixed(2) ?? "";
		const cfgActiveEnergyRecOpt = measurementsUser.cfgActiveEnergy?.cfgActiveEnergyRecOpt?.value ?? 0;
		const cfgActiveEnergyPlusEventThresholdMax = measurementsUser.cfgActiveEnergy?.cfgActiveEnergyPlusEventThresholdMax?.value?.toFixed(2) ?? "";
		const cfgActiveEnergyMinusEventThresholdMax = measurementsUser.cfgActiveEnergy?.cfgActiveEnergyMinusEventThresholdMax?.value?.toFixed(2) ?? "";
		const cfgApparentEnergyRecOpt = measurementsUser.cfgApparentEnergy?.cfgApparentEnergyRecOpt?.value ?? 0;
		const cfgApparentEnergyEventThresholdMax = measurementsUser.cfgApparentEnergy?.cfgApparentEnergyEventThresholdMax?.value?.toFixed(2) ?? "";
		const cfgReactiveEnergy4QRecOpt = measurementsUser.cfgReactiveEnergy4Q?.cfgReactiveEnergy4QRecOpt?.value ?? 0;
		const cfgReactiveEnergyQuadrant1EventThresholdMax = measurementsUser.cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant1EventThresholdMax?.value?.toFixed(2) ?? "";
		const cfgReactiveEnergyQuadrant2EventThresholdMax = measurementsUser.cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant2EventThresholdMax?.value?.toFixed(2) ?? "";
		const cfgReactiveEnergyQuadrant3EventThresholdMax = measurementsUser.cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant3EventThresholdMax?.value?.toFixed(2) ?? "";
		const cfgReactiveEnergyQuadrant4EventThresholdMax = measurementsUser.cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant4EventThresholdMax?.value?.toFixed(2) ?? "";
		const cfgVoltageThdfRecOpt = measurementsUser.cfgVoltageThdf?.cfgVoltageThdfRecOpt?.value ?? 0;
		const cfgVoltageThdfThresholdMax = measurementsUser.cfgVoltageThdf?.cfgVoltageThdfThresholdMax?.value?.toFixed(2) ?? "";
		const cfgVoltageThdrRecOpt = measurementsUser.cfgVoltageThdr?.cfgVoltageThdrRecOpt?.value ?? 0;
		const cfgVoltageHarmonicsRecOpt = measurementsUser.cfgVoltageHarmonics?.cfgVoltageHarmonicsRecOpt?.value ?? 0;
		const cfgCurrentThdfRecOpt = measurementsUser.cfgCurrentThdf?.cfgCurrentThdfRecOpt?.value ?? 0;
		const cfgCurrentThdfThresholdMax = measurementsUser.cfgCurrentThdf?.cfgCurrentThdfThresholdMax?.value?.toFixed(2) ?? "";
		const cfgCurrentThdrRecOpt = measurementsUser.cfgCurrentThdr?.cfgCurrentThdrRecOpt?.value ?? 0;
		const cfgCurrentHarmonicsRecOpt = measurementsUser.cfgCurrentHarmonics?.cfgCurrentHarmonicsRecOpt?.value ?? 0;
		const cfgActivePowerHarmonicsRecOpt = measurementsUser.cfgActivePowerHarmonics?.cfgActivePowerHarmonicsRecOpt?.value ?? 0;
		const cfgHarmonicsAnglesUiRecOpt = measurementsUser.cfgHarmonicsAnglesUi?.cfgHarmonicsAnglesUiRecOpt?.value ?? 0;
		const cfgReactivePowerHarmonicsRecOpt = measurementsUser.cfgReactivePowerHarmonics?.cfgReactivePowerHarmonicsRecOpt?.value ?? 0;
		const cfgVoltageTidfRecOpt = measurementsUser.cfgVoltageTidf?.cfgVoltageTidfRecOpt?.value ?? 0;
		const cfgVoltageTidrRecOpt = measurementsUser.cfgVoltageTidr?.cfgVoltageTidrRecOpt?.value ?? 0;
		const cfgCurrentTidfRecOpt = measurementsUser.cfgCurrentTidf?.cfgCurrentTidfRecOpt?.value ?? 0;
		const cfgCurrentTidrRecOpt = measurementsUser.cfgCurrentTidr?.cfgCurrentTidrRecOpt?.value ?? 0;

		const cfgVoltageInterharmonicsRecOpt = measurementsUser.cfgVoltageInterharmonics?.cfgVoltageInterharmonicsRecOpt?.value ?? 0;
		const cfgCurrentInterharmonicsRecOpt = measurementsUser.cfgCurrentInterharmonics?.cfgCurrentInterharmonicsRecOpt?.value ?? 0;
		const cfgKfactorRecOpt = measurementsUser.cfgKfactor?.cfgKfactorRecOpt?.value ?? 0;
		const cfgPhasorRecOpt = measurementsUser.measurementUser?.cfgPhasorRecOpt?.value ?? 0;

		const cfgMainsSignallingUr1RecOpt = measurementsUser.cfgMainsSignallingUr1?.cfgMainsSignallingUr1RecOpt?.value ?? 0;
		const cfgMainsSignallingUr1Frequency = measurementsUser.cfgMainsSignallingUr1?.cfgMainsSignallingUr1Frequency?.value?.toFixed(2) ?? "";
		const cfgMainsSignallingUr2RecOpt = measurementsUser.cfgMainsSignallingUr2?.cfgMainsSignallingUr2RecOpt?.value ?? 0;
		const cfgMainsSignallingUr2Frequency = measurementsUser.cfgMainsSignallingUr2?.cfgMainsSignallingUr2Frequency?.value?.toFixed(2) ?? "";

		const cfgVoltage29KRecOpt = measurementsUser.cfgVoltage29K?.cfgVoltage29KRecOpt?.value ?? 0;
		const cfgVoltage8150KRecOpt = measurementsUser.cfgVoltage8150K?.cfgVoltage8150KRecOpt?.value ?? 0;

		const cfgStdEnable = measurementsStandard.measurementStandard?.cfgStdDataRecEnable?.enum?.find(enumValue => enumValue.value === measurementsStandard.measurementStandard?.cfgStdDataRecEnable?.value)?.text === OnOff.ON;
		const cfgStdType = measurementsStandard.measurementStandard?.cfgStdType?.value ?? 0;
		const cfgStdAggregationWaveformEnable = measurementsStandard.measurementStandard?.cfgStdWaveformsAndRms12RecEnable?.enum?.find(enumValue => enumValue.value === measurementsStandard.measurementStandard?.cfgStdWaveformsAndRms12RecEnable?.value)?.text === OnOff.ON;

		const cfgEthernetIpAddress = iec.communications?.cfgEthernetIpAddress?.value ?? "";
		const cfgIec61850IpAddress = iec.communications?.cfgIec61850IpAddress?.value ?? "";
		const cfgIec61850Enable = iec.communications?.cfgIec61850Enable?.enum?.find(enumValue => enumValue.value === iec.communications?.cfgIec61850Enable?.value)?.text === OnOff.ON;
		const cfgIec61850IedName = iec.communications?.cfgIec61850IedName?.value?.toString() ?? "";
		const cfgIec61850PhaseVoltageDbRef = iec.communications?.cfgIec61850PhaseVoltageDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850PhaseVoltageDb = translateValueToPercent(iec.communications?.cfgIec61850PhaseVoltageDb).toFixed(2);
		const cfgIec61850PhaseToPhaseVoltageDbRef = iec.communications?.cfgIec61850PhaseToPhaseVoltageDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850PhaseToPhaseVoltageDb = translateValueToPercent(iec.communications?.cfgIec61850PhaseToPhaseVoltageDb).toFixed(2);
		const cfgIec61850UneVoltageDbRef = iec.communications?.cfgIec61850UneVoltageDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850UneVoltageDb = translateValueToPercent(iec.communications?.cfgIec61850UneVoltageDb).toFixed(2);
		const cfgIec61850PhaseCurrentDbRef = iec.communications?.cfgIec61850PhaseCurrentDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850PhaseCurrentDb = translateValueToPercent(iec.communications?.cfgIec61850PhaseCurrentDb).toFixed(2);
		const cfgIec61850NCurrentDbRef = iec.communications?.cfgIec61850NCurrentDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850NCurrentDb = translateValueToPercent(iec.communications?.cfgIec61850NCurrentDb).toFixed(2);
		const cfgIec61850ECurrentDbRef = iec.communications?.cfgIec61850ECurrentDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850ECurrentDb = translateValueToPercent(iec.communications?.cfgIec61850ECurrentDb).toFixed(2);
		const cfgIec61850ActivePowerTotalDbRef = iec.communications?.cfgIec61850ActivePowerTotalDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850ActivePowerTotalDb = translateValueToPercent(iec.communications?.cfgIec61850ActivePowerTotalDb).toFixed(2);
		const cfgIec61850ReactivePowerTotalDbRef = iec.communications?.cfgIec61850ReactivePowerTotalDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850ReactivePowerTotalDb = translateValueToPercent(iec.communications?.cfgIec61850ReactivePowerTotalDb).toFixed(2);
		const cfgIec61850ApparentPowerTotalDbRef = iec.communications?.cfgIec61850ApparentPowerTotalDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850ApparentPowerTotalDb = translateValueToPercent(iec.communications?.cfgIec61850ApparentPowerTotalDb).toFixed(2);
		const cfgIec61850ActivePowerPhaseDbRef = iec.communications?.cfgIec61850ActivePowerPhaseDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850ActivePowerPhaseDb = translateValueToPercent(iec.communications?.cfgIec61850ActivePowerPhaseDb).toFixed(2);
		const cfgIec61850ReactivePowerPhaseDbRef = iec.communications?.cfgIec61850ReactivePowerPhaseDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850ReactivePowerPhaseDb = translateValueToPercent(iec.communications?.cfgIec61850ReactivePowerPhaseDb).toFixed(2);
		const cfgIec61850ApparentPowerPhaseDbRef = iec.communications?.cfgIec61850ApparentPowerPhaseDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850ApparentPowerPhaseDb = translateValueToPercent(iec.communications?.cfgIec61850ApparentPowerPhaseDb).toFixed(2);
		const cfgIec61850FrequencyDbRef = iec.communications?.cfgIec61850FrequencyDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850FrequencyDb = translateValueToPercent(iec.communications?.cfgIec61850FrequencyDb).toFixed(2);
		const cfgIec61850VoltageHarmonicsDbRef = iec.communications?.cfgIec61850VoltageHarmonicsDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850VoltageHarmonicsDb = translateValueToPercent(iec.communications?.cfgIec61850VoltageHarmonicsDb).toFixed(2);
		const cfgIec61850CurrentHarmonicsDbRef = iec.communications?.cfgIec61850CurrentHarmonicsDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850CurrentHarmonicsDb = translateValueToPercent(iec.communications?.cfgIec61850CurrentHarmonicsDb).toFixed(2);
		const cfgIec61850VoltageInterharmonicsDbRef = iec.communications?.cfgIec61850VoltageInterharmonicsDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850VoltageInterharmonicsDb = translateValueToPercent(iec.communications?.cfgIec61850VoltageInterharmonicsDb).toFixed(2);
		const cfgIec61850CurrentInterharmonicsDbRef = iec.communications?.cfgIec61850CurrentInterharmonicsDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850CurrentInterharmonicsDb = translateValueToPercent(iec.communications?.cfgIec61850CurrentInterharmonicsDb).toFixed(2);
		const cfgIec61850VoltageThdDbRef = iec.communications?.cfgIec61850VoltageThdDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850VoltageThdDb = translateValueToPercent(iec.communications?.cfgIec61850VoltageThdDb).toFixed(2);
		const cfgIec61850CurrentThdDbRef = iec.communications?.cfgIec61850CurrentThdDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850CurrentThdDb = translateValueToPercent(iec.communications?.cfgIec61850CurrentThdDb).toFixed(2);
		const cfgIec61850VoltageTidDbRef = iec.communications?.cfgIec61850VoltageTidDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850VoltageTidDb = translateValueToPercent(iec.communications?.cfgIec61850VoltageTidDb).toFixed(2);
		const cfgIec61850CurrentTidDbRef = iec.communications?.cfgIec61850CurrentTidDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850CurrentTidDb = translateValueToPercent(iec.communications?.cfgIec61850CurrentTidDb).toFixed(2);
		const cfgIec61850UnbalanceVoltagePositiveSequenceDbRef = iec.communications?.cfgIec61850UnbalanceVoltagePositiveSequenceDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850UnbalanceVoltagePositiveSequenceDb = translateValueToPercent(iec.communications?.cfgIec61850UnbalanceVoltagePositiveSequenceDb).toFixed(2);
		const cfgIec61850UnbalanceVoltageNegativeSequenceDbRef = iec.communications?.cfgIec61850UnbalanceVoltageNegativeSequenceDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850UnbalanceVoltageNegativeSequenceDb = translateValueToPercent(iec.communications?.cfgIec61850UnbalanceVoltageNegativeSequenceDb).toFixed(2);
		const cfgIec61850UnbalanceVoltageZeroSequenceDbRef = iec.communications?.cfgIec61850UnbalanceVoltageZeroSequenceDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850UnbalanceVoltageZeroSequenceDb = translateValueToPercent(iec.communications?.cfgIec61850UnbalanceVoltageZeroSequenceDb).toFixed(2);
		const cfgIec61850UnbalanceCurrentPositiveSequenceDbRef = iec.communications?.cfgIec61850UnbalanceCurrentPositiveSequenceDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850UnbalanceCurrentPositiveSequenceDb = translateValueToPercent(iec.communications?.cfgIec61850UnbalanceCurrentPositiveSequenceDb).toFixed(2);
		const cfgIec61850UnbalanceCurrentNegativeSequenceDbRef = iec.communications?.cfgIec61850UnbalanceCurrentNegativeSequenceDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850UnbalanceCurrentNegativeSequenceDb = translateValueToPercent(iec.communications?.cfgIec61850UnbalanceCurrentNegativeSequenceDb).toFixed(2);
		const cfgIec61850UnbalanceCurrentZeroSequenceDbRef = iec.communications?.cfgIec61850UnbalanceCurrentZeroSequenceDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850UnbalanceCurrentZeroSequenceDb = translateValueToPercent(iec.communications?.cfgIec61850UnbalanceCurrentZeroSequenceDb).toFixed(2);
		const cfgIec61850UnbalanceCurrentNegativeDbRef = iec.communications?.cfgIec61850UnbalanceCurrentNegativeDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850UnbalanceCurrentNegativeDb = translateValueToPercent(iec.communications?.cfgIec61850UnbalanceCurrentNegativeDb).toFixed(2);
		const cfgIec61850FlickerPstDbRef = iec.communications?.cfgIec61850FlickerPstDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850FlickerPstDb = translateValueToPercent(iec.communications?.cfgIec61850FlickerPstDb).toFixed(2);
		const cfgIec61850FlickerPltDbRef = iec.communications?.cfgIec61850FlickerPltDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850FlickerPltDb = translateValueToPercent(iec.communications?.cfgIec61850FlickerPltDb).toFixed(2);
		const cfgIec6185029KhzVoltageDbRef = iec.communications?.cfgIec6185029KhzVoltageDbRef?.value?.toFixed(2) ?? "";
		const cfgIec6185029KhzVoltageDb = translateValueToPercent(iec.communications?.cfgIec6185029KhzVoltageDb).toFixed(2);
		const cfgIec6185029KhzVoltageMaxDbRef = iec.communications?.cfgIec6185029KhzVoltageMaxDbRef?.value?.toFixed(2) ?? "";
		const cfgIec6185029KhzVoltageMaxDb = translateValueToPercent(iec.communications?.cfgIec6185029KhzVoltageMaxDb).toFixed(2);
		const cfgIec618509150KhzVoltageDbRef = iec.communications?.cfgIec618509150KhzVoltageDbRef?.value?.toFixed(2) ?? "";
		const cfgIec618509150KhzVoltageDb = translateValueToPercent(iec.communications?.cfgIec618509150KhzVoltageDb).toFixed(2);
		const cfgIec618509150KhzVoltageMaxDbRef = iec.communications?.cfgIec618509150KhzVoltageMaxDbRef?.value?.toFixed(2) ?? "";
		const cfgIec618509150KhzVoltageMaxDb = translateValueToPercent(iec.communications?.cfgIec618509150KhzVoltageMaxDb).toFixed(2);
		const cfgIec61850BatteryVoltageDbRef = iec.communications?.cfgIec61850BatteryVoltageDbRef?.value?.toFixed(2) ?? "";
		const cfgIec61850BatteryVoltageDb = translateValueToPercent(iec.communications?.cfgIec61850BatteryVoltageDb).toFixed(2);

		const cfgDigitalInputInternal1ActiveState = io.io?.cfgDigitalInputInternal1ActiveState?.value ?? 0;
		const cfgDigitalInputInternal1RecOpt = io.io?.cfgDigitalInputInternal1RecOpt?.value ?? 0;
		const cfgDigitalInputInternal2ActiveState = io.io?.cfgDigitalInputInternal2ActiveState?.value ?? 0;
		const cfgDigitalInputInternal2RecOpt = io.io?.cfgDigitalInputInternal2RecOpt?.value ?? 0;
		const cfgRelay1ActiveState = io.io?.cfgRelay1ActiveState?.value ?? 0;
		const cfgRelay1Mode = io.io?.cfgRelay1Mode?.value ?? 0;
		const cfgRelay1ActiveTime = io.io?.cfgRelay1ActiveTime?.value?.toString() ?? "";
		const cfgRelay1DeadTime = io.io?.cfgRelay1DeadTime?.value?.toFixed(0) ?? "";
		const cfgRelay2ActiveState = io.io?.cfgRelay2ActiveState?.value ?? 0;
		const cfgRelay2Mode = io.io?.cfgRelay2Mode?.value ?? 0;
		const cfgRelay2ActiveTime = io.io?.cfgRelay2ActiveTime?.value?.toString() ?? "";
		const cfgRelay2DeadTime = io.io?.cfgRelay2DeadTime?.value?.toFixed(0) ?? "";

		const iecPayload: SetBufforRecordingPayload["payload"] = {
			...(form.cfgIec61850Enable.value !== cfgIec61850Enable ? { cfg_iec61850_enable: { value: Number(form.cfgIec61850Enable.value) } } : {}),
			...(form.cfgIec61850IedName.value !== cfgIec61850IedName ? { cfg_iec61850_ied_name: { value: form.cfgIec61850IedName.value } } : {}),
			...(form.cfgIec61850PhaseVoltageDbRef.value !== cfgIec61850PhaseVoltageDbRef ? { cfg_iec61850_phase_voltage_db_ref: { value: +form.cfgIec61850PhaseVoltageDbRef.value } } : {}),
			...((form.cfgIec61850PhaseVoltageDb.value !== cfgIec61850PhaseVoltageDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850PhaseVoltageDb)) ? { cfg_iec61850_phase_voltage_db: { value: translateValueFromPercent(iec.communications.cfgIec61850PhaseVoltageDb, +form.cfgIec61850PhaseVoltageDb.value) } } : {}),
			...(form.cfgIec61850PhaseToPhaseVoltageDbRef.value !== cfgIec61850PhaseToPhaseVoltageDbRef ? { cfg_iec61850_phase_to_phase_voltage_db_ref: { value: +form.cfgIec61850PhaseToPhaseVoltageDbRef.value } } : {}),
			...((form.cfgIec61850PhaseToPhaseVoltageDb.value !== cfgIec61850PhaseToPhaseVoltageDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850PhaseToPhaseVoltageDb)) ? { cfg_iec61850_phase_to_phase_voltage_db: { value: translateValueFromPercent(iec.communications.cfgIec61850PhaseToPhaseVoltageDb, +form.cfgIec61850PhaseToPhaseVoltageDb.value) } } : {}),
			...(form.cfgIec61850UneVoltageDbRef.value !== cfgIec61850UneVoltageDbRef ? { cfg_iec61850_une_voltage_db_ref: { value: +form.cfgIec61850UneVoltageDbRef.value } } : {}),
			...((form.cfgIec61850UneVoltageDb.value !== cfgIec61850UneVoltageDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850UneVoltageDb)) ? { cfg_iec61850_une_voltage_db: { value: translateValueFromPercent(iec.communications.cfgIec61850UneVoltageDb, +form.cfgIec61850UneVoltageDb.value) } } : {}),
			...(form.cfgIec61850PhaseCurrentDbRef.value !== cfgIec61850PhaseCurrentDbRef ? { cfg_iec61850_phase_current_db_ref: { value: +form.cfgIec61850PhaseCurrentDbRef.value } } : {}),
			...((form.cfgIec61850PhaseCurrentDb.value !== cfgIec61850PhaseCurrentDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850PhaseCurrentDb)) ? { cfg_iec61850_phase_current_db: { value: translateValueFromPercent(iec.communications.cfgIec61850PhaseCurrentDb, +form.cfgIec61850PhaseCurrentDb.value) } } : {}),
			...(form.cfgIec61850NCurrentDbRef.value !== cfgIec61850NCurrentDbRef ? { cfg_iec61850_n_current_db_ref: { value: +form.cfgIec61850NCurrentDbRef.value } } : {}),
			...((form.cfgIec61850NCurrentDb.value !== cfgIec61850NCurrentDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850NCurrentDb)) ? { cfg_iec61850_n_current_db: { value: translateValueFromPercent(iec.communications.cfgIec61850NCurrentDb, +form.cfgIec61850NCurrentDb.value) } } : {}),
			...(form.cfgIec61850ECurrentDbRef.value !== cfgIec61850ECurrentDbRef ? { cfg_iec61850_e_current_db_ref: { value: +form.cfgIec61850ECurrentDbRef.value } } : {}),
			...((form.cfgIec61850ECurrentDb.value !== cfgIec61850ECurrentDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850ECurrentDb)) ? { cfg_iec61850_e_current_db: { value: translateValueFromPercent(iec.communications.cfgIec61850ECurrentDb, +form.cfgIec61850ECurrentDb.value) } } : {}),
			...(form.cfgIec61850ActivePowerTotalDbRef.value !== cfgIec61850ActivePowerTotalDbRef ? { cfg_iec61850_active_power_total_db_ref: { value: +form.cfgIec61850ActivePowerTotalDbRef.value } } : {}),
			...((form.cfgIec61850ActivePowerTotalDb.value !== cfgIec61850ActivePowerTotalDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850ActivePowerTotalDb)) ? { cfg_iec61850_active_power_total_db: { value: translateValueFromPercent(iec.communications.cfgIec61850ActivePowerTotalDb, +form.cfgIec61850ActivePowerTotalDb.value) } } : {}),
			...(form.cfgIec61850ReactivePowerTotalDbRef.value !== cfgIec61850ReactivePowerTotalDbRef ? { cfg_iec61850_reactive_power_total_db_ref: { value: +form.cfgIec61850ReactivePowerTotalDbRef.value } } : {}),
			...((form.cfgIec61850ReactivePowerTotalDb.value !== cfgIec61850ReactivePowerTotalDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850ReactivePowerTotalDb)) ? { cfg_iec61850_reactive_power_total_db: { value: translateValueFromPercent(iec.communications.cfgIec61850ReactivePowerTotalDb, +form.cfgIec61850ReactivePowerTotalDb.value) } } : {}),
			...(form.cfgIec61850ApparentPowerTotalDbRef.value !== cfgIec61850ApparentPowerTotalDbRef ? { cfg_iec61850_apparent_power_total_db_ref: { value: +form.cfgIec61850ApparentPowerTotalDbRef.value } } : {}),
			...((form.cfgIec61850ApparentPowerTotalDb.value !== cfgIec61850ApparentPowerTotalDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850ApparentPowerTotalDb)) ? { cfg_iec61850_apparent_power_total_db: { value: translateValueFromPercent(iec.communications.cfgIec61850ApparentPowerTotalDb, +form.cfgIec61850ApparentPowerTotalDb.value) } } : {}),
			...(form.cfgIec61850ActivePowerPhaseDbRef.value !== cfgIec61850ActivePowerPhaseDbRef ? { cfg_iec61850_active_power_phase_db_ref: { value: +form.cfgIec61850ActivePowerPhaseDbRef.value } } : {}),
			...((form.cfgIec61850ActivePowerPhaseDb.value !== cfgIec61850ActivePowerPhaseDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850ActivePowerPhaseDb)) ? { cfg_iec61850_active_power_phase_db: { value: translateValueFromPercent(iec.communications.cfgIec61850ActivePowerPhaseDb, +form.cfgIec61850ActivePowerPhaseDb.value) } } : {}),
			...(form.cfgIec61850ReactivePowerPhaseDbRef.value !== cfgIec61850ReactivePowerPhaseDbRef ? { cfg_iec61850_reactive_power_phase_db_ref: { value: +form.cfgIec61850ReactivePowerPhaseDbRef.value } } : {}),
			...((form.cfgIec61850ReactivePowerPhaseDb.value !== cfgIec61850ReactivePowerPhaseDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850ReactivePowerPhaseDb)) ? { cfg_iec61850_reactive_power_phase_db: { value: translateValueFromPercent(iec.communications.cfgIec61850ReactivePowerPhaseDb, +form.cfgIec61850ReactivePowerPhaseDb.value) } } : {}),
			...(form.cfgIec61850ApparentPowerPhaseDbRef.value !== cfgIec61850ApparentPowerPhaseDbRef ? { cfg_iec61850_apparent_power_phase_db_ref: { value: +form.cfgIec61850ApparentPowerPhaseDbRef.value } } : {}),
			...((form.cfgIec61850ApparentPowerPhaseDb.value !== cfgIec61850ApparentPowerPhaseDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850ApparentPowerPhaseDb)) ? { cfg_iec61850_apparent_power_phase_db: { value: translateValueFromPercent(iec.communications.cfgIec61850ApparentPowerPhaseDb, +form.cfgIec61850ApparentPowerPhaseDb.value) } } : {}),
			...(form.cfgIec61850FrequencyDbRef.value !== cfgIec61850FrequencyDbRef ? { cfg_iec61850_frequency_db_ref: { value: +form.cfgIec61850FrequencyDbRef.value } } : {}),
			...((form.cfgIec61850FrequencyDb.value !== cfgIec61850FrequencyDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850FrequencyDb)) ? { cfg_iec61850_frequency_db: { value: translateValueFromPercent(iec.communications.cfgIec61850FrequencyDb, +form.cfgIec61850FrequencyDb.value) } } : {}),
			...(form.cfgIec61850VoltageHarmonicsDbRef.value !== cfgIec61850VoltageHarmonicsDbRef ? { cfg_iec61850_voltage_harmonics_db_ref: { value: +form.cfgIec61850VoltageHarmonicsDbRef.value } } : {}),
			...((form.cfgIec61850VoltageHarmonicsDb.value !== cfgIec61850VoltageHarmonicsDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850VoltageHarmonicsDb)) ? { cfg_iec61850_voltage_harmonics_db: { value: translateValueFromPercent(iec.communications.cfgIec61850VoltageHarmonicsDb, +form.cfgIec61850VoltageHarmonicsDb.value) } } : {}),
			...(form.cfgIec61850CurrentHarmonicsDbRef.value !== cfgIec61850CurrentHarmonicsDbRef ? { cfg_iec61850_current_harmonics_db_ref: { value: +form.cfgIec61850CurrentHarmonicsDbRef.value } } : {}),
			...((form.cfgIec61850CurrentHarmonicsDb.value !== cfgIec61850CurrentHarmonicsDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850CurrentHarmonicsDb)) ? { cfg_iec61850_current_harmonics_db: { value: translateValueFromPercent(iec.communications.cfgIec61850CurrentHarmonicsDb, +form.cfgIec61850CurrentHarmonicsDb.value) } } : {}),
			...(form.cfgIec61850VoltageInterharmonicsDbRef.value !== cfgIec61850VoltageInterharmonicsDbRef ? { cfg_iec61850_voltage_interharmonics_db_ref: { value: +form.cfgIec61850VoltageInterharmonicsDbRef.value } } : {}),
			...((form.cfgIec61850VoltageInterharmonicsDb.value !== cfgIec61850VoltageInterharmonicsDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850VoltageInterharmonicsDb)) ? { cfg_iec61850_voltage_interharmonics_db: { value: translateValueFromPercent(iec.communications.cfgIec61850VoltageInterharmonicsDb, +form.cfgIec61850VoltageInterharmonicsDb.value) } } : {}),
			...(form.cfgIec61850CurrentInterharmonicsDbRef.value !== cfgIec61850CurrentInterharmonicsDbRef ? { cfg_iec61850_current_interharmonics_db_ref: { value: +form.cfgIec61850CurrentInterharmonicsDbRef.value } } : {}),
			...((form.cfgIec61850CurrentInterharmonicsDb.value !== cfgIec61850CurrentInterharmonicsDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850CurrentInterharmonicsDb)) ? { cfg_iec61850_current_interharmonics_db: { value: translateValueFromPercent(iec.communications.cfgIec61850CurrentInterharmonicsDb, +form.cfgIec61850CurrentInterharmonicsDb.value) } } : {}),
			...(form.cfgIec61850VoltageThdDbRef.value !== cfgIec61850VoltageThdDbRef ? { cfg_iec61850_voltage_thd_db_ref: { value: +form.cfgIec61850VoltageThdDbRef.value } } : {}),
			...((form.cfgIec61850VoltageThdDb.value !== cfgIec61850VoltageThdDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850VoltageThdDb)) ? { cfg_iec61850_voltage_thd_db: { value: translateValueFromPercent(iec.communications.cfgIec61850VoltageThdDb, +form.cfgIec61850VoltageThdDb.value) } } : {}),
			...(form.cfgIec61850CurrentThdDbRef.value !== cfgIec61850CurrentThdDbRef ? { cfg_iec61850_current_thd_db_ref: { value: +form.cfgIec61850CurrentThdDbRef.value } } : {}),
			...((form.cfgIec61850CurrentThdDb.value !== cfgIec61850CurrentThdDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850CurrentThdDb)) ? { cfg_iec61850_current_thd_db: { value: translateValueFromPercent(iec.communications.cfgIec61850CurrentThdDb, +form.cfgIec61850CurrentThdDb.value) } } : {}),
			...(form.cfgIec61850VoltageTidDbRef.value !== cfgIec61850VoltageTidDbRef ? { cfg_iec61850_voltage_tid_db_ref: { value: +form.cfgIec61850VoltageTidDbRef.value } } : {}),
			...((form.cfgIec61850VoltageTidDb.value !== cfgIec61850VoltageTidDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850VoltageTidDb)) ? { cfg_iec61850_voltage_tid_db: { value: translateValueFromPercent(iec.communications.cfgIec61850VoltageTidDb, +form.cfgIec61850VoltageTidDb.value) } } : {}),
			...(form.cfgIec61850CurrentTidDbRef.value !== cfgIec61850CurrentTidDbRef ? { cfg_iec61850_current_tid_db_ref: { value: +form.cfgIec61850CurrentTidDbRef.value } } : {}),
			...((form.cfgIec61850CurrentTidDb.value !== cfgIec61850CurrentTidDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850CurrentTidDb)) ? { cfg_iec61850_current_tid_db: { value: translateValueFromPercent(iec.communications.cfgIec61850CurrentTidDb, +form.cfgIec61850CurrentTidDb.value) } } : {}),
			...(form.cfgIec61850UnbalanceVoltagePositiveSequenceDbRef.value !== cfgIec61850UnbalanceVoltagePositiveSequenceDbRef ? { cfg_iec61850_unbalance_voltage_positive_sequence_db_ref: { value: +form.cfgIec61850UnbalanceVoltagePositiveSequenceDbRef.value } } : {}),
			...((form.cfgIec61850UnbalanceVoltagePositiveSequenceDb.value !== cfgIec61850UnbalanceVoltagePositiveSequenceDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850UnbalanceVoltagePositiveSequenceDb)) ? { cfg_iec61850_unbalance_voltage_positive_sequence_db: { value: translateValueFromPercent(iec.communications.cfgIec61850UnbalanceVoltagePositiveSequenceDb, +form.cfgIec61850UnbalanceVoltagePositiveSequenceDb.value) } } : {}),
			...(form.cfgIec61850UnbalanceVoltageNegativeSequenceDbRef.value !== cfgIec61850UnbalanceVoltageNegativeSequenceDbRef ? { cfg_iec61850_unbalance_voltage_negative_sequence_db_ref: { value: +form.cfgIec61850UnbalanceVoltageNegativeSequenceDbRef.value } } : {}),
			...((form.cfgIec61850UnbalanceVoltageNegativeSequenceDb.value !== cfgIec61850UnbalanceVoltageNegativeSequenceDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850UnbalanceVoltageNegativeSequenceDb)) ? { cfg_iec61850_unbalance_voltage_negative_sequence_db: { value: translateValueFromPercent(iec.communications.cfgIec61850UnbalanceVoltageNegativeSequenceDb, +form.cfgIec61850UnbalanceVoltageNegativeSequenceDb.value) } } : {}),
			...(form.cfgIec61850UnbalanceVoltageZeroSequenceDbRef.value !== cfgIec61850UnbalanceVoltageZeroSequenceDbRef ? { cfg_iec61850_unbalance_voltage_zero_sequence_db_ref: { value: +form.cfgIec61850UnbalanceVoltageZeroSequenceDbRef.value } } : {}),
			...((form.cfgIec61850UnbalanceVoltageZeroSequenceDb.value !== cfgIec61850UnbalanceVoltageZeroSequenceDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850UnbalanceVoltageZeroSequenceDb)) ? { cfg_iec61850_unbalance_voltage_zero_sequence_db: { value: translateValueFromPercent(iec.communications.cfgIec61850UnbalanceVoltageZeroSequenceDb, +form.cfgIec61850UnbalanceVoltageZeroSequenceDb.value) } } : {}),
			...(form.cfgIec61850UnbalanceCurrentPositiveSequenceDbRef.value !== cfgIec61850UnbalanceCurrentPositiveSequenceDbRef ? { cfg_iec61850_unbalance_current_positive_sequence_db_ref: { value: +form.cfgIec61850UnbalanceCurrentPositiveSequenceDbRef.value } } : {}),
			...((form.cfgIec61850UnbalanceCurrentPositiveSequenceDb.value !== cfgIec61850UnbalanceCurrentPositiveSequenceDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850UnbalanceCurrentPositiveSequenceDb)) ? { cfg_iec61850_unbalance_current_positive_sequence_db: { value: translateValueFromPercent(iec.communications.cfgIec61850UnbalanceCurrentPositiveSequenceDb, +form.cfgIec61850UnbalanceCurrentPositiveSequenceDb.value) } } : {}),
			...(form.cfgIec61850UnbalanceCurrentNegativeSequenceDbRef.value !== cfgIec61850UnbalanceCurrentNegativeSequenceDbRef ? { cfg_iec61850_unbalance_current_negative_sequence_db_ref: { value: +form.cfgIec61850UnbalanceCurrentNegativeSequenceDbRef.value } } : {}),
			...((form.cfgIec61850UnbalanceCurrentNegativeSequenceDb.value !== cfgIec61850UnbalanceCurrentNegativeSequenceDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850UnbalanceCurrentNegativeSequenceDb)) ? { cfg_iec61850_unbalance_current_negative_sequence_db: { value: translateValueFromPercent(iec.communications.cfgIec61850UnbalanceCurrentNegativeSequenceDb, +form.cfgIec61850UnbalanceCurrentNegativeSequenceDb.value) } } : {}),
			...(form.cfgIec61850UnbalanceCurrentZeroSequenceDbRef.value !== cfgIec61850UnbalanceCurrentZeroSequenceDbRef ? { cfg_iec61850_unbalance_current_zero_sequence_db_ref: { value: +form.cfgIec61850UnbalanceCurrentZeroSequenceDbRef.value } } : {}),
			...((form.cfgIec61850UnbalanceCurrentZeroSequenceDb.value !== cfgIec61850UnbalanceCurrentZeroSequenceDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850UnbalanceCurrentZeroSequenceDb)) ? { cfg_iec61850_unbalance_current_zero_sequence_db: { value: translateValueFromPercent(iec.communications.cfgIec61850UnbalanceCurrentZeroSequenceDb, +form.cfgIec61850UnbalanceCurrentZeroSequenceDb.value) } } : {}),
			...(form.cfgIec61850UnbalanceCurrentNegativeDbRef.value !== cfgIec61850UnbalanceCurrentNegativeDbRef ? { cfg_iec61850_unbalance_current_negative_db_ref: { value: +form.cfgIec61850UnbalanceCurrentNegativeDbRef.value } } : {}),
			...((form.cfgIec61850UnbalanceCurrentNegativeDb.value !== cfgIec61850UnbalanceCurrentNegativeDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850UnbalanceCurrentNegativeDb)) ? { cfg_iec61850_unbalance_current_negative_db: { value: translateValueFromPercent(iec.communications.cfgIec61850UnbalanceCurrentNegativeDb, +form.cfgIec61850UnbalanceCurrentNegativeDb.value) } } : {}),
			...(form.cfgIec61850FlickerPstDbRef.value !== cfgIec61850FlickerPstDbRef ? { cfg_iec61850_flicker_pst_db_ref: { value: +form.cfgIec61850FlickerPstDbRef.value } } : {}),
			...((form.cfgIec61850FlickerPstDb.value !== cfgIec61850FlickerPstDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850FlickerPstDb)) ? { cfg_iec61850_flicker_pst_db: { value: translateValueFromPercent(iec.communications.cfgIec61850FlickerPstDb, +form.cfgIec61850FlickerPstDb.value) } } : {}),
			...(form.cfgIec61850FlickerPltDbRef.value !== cfgIec61850FlickerPltDbRef ? { cfg_iec61850_flicker_plt_db_ref: { value: +form.cfgIec61850FlickerPltDbRef.value } } : {}),
			...((form.cfgIec61850FlickerPltDb.value !== cfgIec61850FlickerPltDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850FlickerPltDb)) ? { cfg_iec61850_flicker_plt_db: { value: translateValueFromPercent(iec.communications.cfgIec61850FlickerPltDb, +form.cfgIec61850FlickerPltDb.value) } } : {}),
			...(form.cfgIec6185029KhzVoltageDbRef.value !== cfgIec6185029KhzVoltageDbRef ? { cfg_iec61850_2_9_khz_voltage_db_ref: { value: +form.cfgIec6185029KhzVoltageDbRef.value } } : {}),
			...((form.cfgIec6185029KhzVoltageDb.value !== cfgIec6185029KhzVoltageDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec6185029KhzVoltageDb)) ? { cfg_iec61850_2_9_khz_voltage_db: { value: translateValueFromPercent(iec.communications.cfgIec6185029KhzVoltageDb, +form.cfgIec6185029KhzVoltageDb.value) } } : {}),
			...(form.cfgIec6185029KhzVoltageMaxDbRef.value !== cfgIec6185029KhzVoltageMaxDbRef ? { cfg_iec61850_2_9_khz_voltage_max_db_ref: { value: +form.cfgIec6185029KhzVoltageMaxDbRef.value } } : {}),
			...((form.cfgIec6185029KhzVoltageMaxDb.value !== cfgIec6185029KhzVoltageMaxDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec6185029KhzVoltageMaxDb)) ? { cfg_iec61850_2_9_khz_voltage_max_db: { value: translateValueFromPercent(iec.communications.cfgIec6185029KhzVoltageMaxDb, +form.cfgIec6185029KhzVoltageMaxDb.value) } } : {}),
			...(form.cfgIec618509150KhzVoltageDbRef.value !== cfgIec618509150KhzVoltageDbRef ? { cfg_iec61850_9_150_khz_voltage_db_ref: { value: +form.cfgIec618509150KhzVoltageDbRef.value } } : {}),
			...((form.cfgIec618509150KhzVoltageDb.value !== cfgIec618509150KhzVoltageDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec618509150KhzVoltageDb)) ? { cfg_iec61850_9_150_khz_voltage_db: { value: translateValueFromPercent(iec.communications.cfgIec618509150KhzVoltageDb, +form.cfgIec618509150KhzVoltageDb.value) } } : {}),
			...(form.cfgIec618509150KhzVoltageMaxDbRef.value !== cfgIec618509150KhzVoltageMaxDbRef ? { cfg_iec61850_9_150_khz_voltage_max_db_ref: { value: +form.cfgIec618509150KhzVoltageMaxDbRef.value } } : {}),
			...((form.cfgIec618509150KhzVoltageMaxDb.value !== cfgIec618509150KhzVoltageMaxDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec618509150KhzVoltageMaxDb)) ? { cfg_iec61850_9_150_khz_voltage_max_db: { value: translateValueFromPercent(iec.communications.cfgIec618509150KhzVoltageMaxDb, +form.cfgIec618509150KhzVoltageMaxDb.value) } } : {}),
			...(form.cfgIec61850BatteryVoltageDbRef.value !== cfgIec61850BatteryVoltageDbRef ? { cfg_iec61850_battery_voltage_db_ref: { value: +form.cfgIec61850BatteryVoltageDbRef.value } } : {}),
			...((form.cfgIec61850BatteryVoltageDb.value !== cfgIec61850BatteryVoltageDb && isNotNull(iec.communications) && isNotNull(iec.communications.cfgIec61850BatteryVoltageDb)) ? { cfg_iec61850_battery_voltage_db: { value: translateValueFromPercent(iec.communications.cfgIec61850BatteryVoltageDb, +form.cfgIec61850BatteryVoltageDb.value) } } : {}),
		};

		saveConfiguration({
			form: {
				type: createFormField(form.type.value),
				nominalFrequency: createFormField(form.nominalFrequency.value),
				nominalVoltage: createFormField(form.nominalVoltage.value),
				unpeChannelEnable: createFormField(form.unpeChannelEnable.value),
				voltageTransducerRatio: createFormField(form.voltageTransducerRatio.value),
				ch4Enabled: createFormField(form.ch4Enabled.value),
				ch5Enabled: createFormField(form.ch5Enabled.value),
				currentCh1TransducerRatio: createFormField(form.currentCh1TransducerRatio.value),
				currentCh2TransducerRatio: createFormField(form.currentCh2TransducerRatio.value),
				currentCh3TransducerRatio: createFormField(form.currentCh3TransducerRatio.value),
				currentCh4TransducerRatio: createFormField(form.currentCh4TransducerRatio.value),
				currentCh5TransducerRatio: createFormField(form.currentCh5TransducerRatio.value),
				phaseRotation: createFormField(form.phaseRotation.value),

				eventsHysteresis: createFormField(form.eventsHysteresis.value),
				waveformFormat: createFormField(form.waveformFormat.value),
				waveformRecDuration: createFormField(form.waveformRecDuration.value),
				waveformPretriggerDuration: createFormField(form.waveformPretriggerDuration.value),
				rms12RecTime: createFormField(form.rms12RecTime.value),
				rms12PretriggerTime: createFormField(form.rms12PretriggerTime.value),

				userRecordingPerc: createFormField(form.userRecordingPerc.value),
				stdRecordingPerc: createFormField(form.stdRecordingPerc.value),
				energyCountersPerc: createFormField(form.energyCountersPerc.value),
				userDataModel: createFormField(form.userDataModel.value),
				stdDataModel: createFormField(form.stdDataModel.value),
				energyDataModel: createFormField(form.energyDataModel.value),

				tcpEnabled: createFormField(form.tcpEnabled.value),
				tcpSlaveAddress: createFormField(form.tcpSlaveAddress.value),
				tcpPort: createFormField(form.tcpPort.value),
				tcpEndianness: createFormField(form.tcpEndianness.value),

				rtuEnabled: createFormField(form.rtuEnabled.value),
				rtuSlaveAddress: createFormField(form.rtuSlaveAddress.value),
				rtuBaudrate: createFormField(form.rtuBaudrate.value),
				rtuParity: createFormField(form.rtuParity.value),
				rtuEndianness: createFormField(form.rtuEndianness.value),

				ftpEnable: createFormField(form.ftpEnable.value),
				ftpConnectionType: createFormField(form.ftpConnectionType.value),
				ftpMode: createFormField(form.ftpMode.value),
				ftpUploadTime: createFormField(form.ftpUploadTime.value),
				ftpServerIp: createFormField(form.ftpServerIp.value),
				ftpServerPort: createFormField(form.ftpServerPort.value),
				ftpUserName: createFormField(form.ftpUserName.value),
				ftpPassword: createFormField(form.ftpPassword.value),
				ftpDestDirectory: createFormField(form.ftpDestDirectory.value),

				rtcUtcTimestamp: createFormField(form.rtcUtcTimestamp.value),
				timeSyncMode: createFormField(form.timeSyncMode.value),
				timezone: createFormField(form.timezone.value),
				daylightSavingsTime: createFormField(form.daylightSavingsTime.value),
				NTPEnable: createFormField(form.NTPEnable.value),
				NTPServer1Name: createFormField(form.NTPServer1Name.value),
				NTPServer2Name: createFormField(form.NTPServer2Name.value),
				NTPServer3Name: createFormField(form.NTPServer3Name.value),
				NTPServer4Name: createFormField(form.NTPServer4Name.value),

				cfgUserEnable: createFormField(form.cfgUserEnable.value),
				cfgAggregationPeriodUser: createFormField(form.cfgAggregationPeriodUser.value),
				cfgUserAggregationWaveformEnable: createFormField(form.cfgUserAggregationWaveformEnable.value),
				cfgPowerCalculationMethod: createFormField(form.cfgPowerCalculationMethod.value),

				cfgVoltageRmsRecOpt: createFormField(form.cfgVoltageRmsRecOpt.value),
				cfgVoltageRmsEventThresholdUserDip: createFormField(form.cfgVoltageRmsEventThresholdUserDip.value),
				cfgVoltageRmsEventThresholdUserSwell: createFormField(form.cfgVoltageRmsEventThresholdUserSwell.value),
				cfgVoltageRmsEventThresholdUserInterruption: createFormField(form.cfgVoltageRmsEventThresholdUserInterruption.value),
				cfgVoltageRmsEventThresholdMaxCh4: createFormField(form.cfgVoltageRmsEventThresholdMaxCh4.value),
				cfgDipChannels: createFormField(form.cfgDipChannels.value),
				cfgDipActions: createFormField(form.cfgDipActions.value),
				cfgSwellChannels: createFormField(form.cfgSwellChannels.value),
				cfgSwellActions: createFormField(form.cfgSwellActions.value),
				cfgInterruptChannels: createFormField(form.cfgInterruptChannels.value),
				cfgInterruptActions: createFormField(form.cfgInterruptActions.value),
				cfgVoltageDcRecOpt: createFormField(form.cfgVoltageDcRecOpt.value),
				cfgWaveshapeVariationsRecOpt: createFormField(form.cfgWaveshapeVariationsRecOpt.value),
				cfgWaveshapeVariationsDeadtime: createFormField(form.cfgWaveshapeVariationsDeadtime.value),
				cfgWaveshapeVariationsThreshold: createFormField(form.cfgWaveshapeVariationsThreshold.value),
				cfgWaveshapeVariationsChannels: createFormField(form.cfgWaveshapeVariationsChannels.value),
				cfgWaveshapeVariationsActions: createFormField(form.cfgWaveshapeVariationsActions.value),
				cfgPhaseJumpsRecOpt: createFormField(form.cfgPhaseJumpsRecOpt.value),
				cfgPhaseJumpsThreshold: createFormField(form.cfgPhaseJumpsThreshold.value),
				cfgPhaseJumpsChannels: createFormField(form.cfgPhaseJumpsChannels.value),
				cfgPhaseJumpsActions: createFormField(form.cfgPhaseJumpsActions.value),
				cfgFrequencyRecOpt: createFormField(form.cfgFrequencyRecOpt.value),
				cfgFrequencyEventThresholdMin: createFormField(form.cfgFrequencyEventThresholdMin.value),
				cfgFrequencyEventThresholdMax: createFormField(form.cfgFrequencyEventThresholdMax.value),
				cfgFrequencyChannels: createFormField(form.cfgFrequencyChannels.value),
				cfgFrequencyActions: createFormField(form.cfgFrequencyActions.value),
				cfgVoltageCrestFactorRecOpt: createFormField(form.cfgVoltageCrestFactorRecOpt.value),
				cfgVoltageUnbalanceRecOpt: createFormField(form.cfgVoltageUnbalanceRecOpt.value),
				cfgVoltageUnbalanceThresholdMax: createFormField(form.cfgVoltageUnbalanceThresholdMax.value),
				cfgFlickerPstRecOpt: createFormField(form.cfgFlickerPstRecOpt.value),
				cfgFlickerPstThresholdMax: createFormField(form.cfgFlickerPstThresholdMax.value),
				cfgFlickerPstChannels: createFormField(form.cfgFlickerPstChannels.value),
				cfgFlickerPstActions: createFormField(form.cfgFlickerPstActions.value),
				cfgFlickerPltRecOpt: createFormField(form.cfgFlickerPltRecOpt.value),
				cfgFlickerPltThresholdMax: createFormField(form.cfgFlickerPltThresholdMax.value),
				cfgFlickerPltChannels: createFormField(form.cfgFlickerPltChannels.value),
				cfgFlickerPltActions: createFormField(form.cfgFlickerPltActions.value),
				cfgCurrentRmsRecOpt: createFormField(form.cfgCurrentRmsRecOpt.value),
				cfgCurrentRmsEventThresholdMax: createFormField(form.cfgCurrentRmsEventThresholdMax.value),
				cfgCurrentRmsEventThresholdMaxCh4: createFormField(form.cfgCurrentRmsEventThresholdMaxCh4.value),
				cfgCurrentRmsEventThresholdMaxCh5: createFormField(form.cfgCurrentRmsEventThresholdMaxCh5.value),
				cfgCurrentChannels: createFormField(form.cfgCurrentChannels.value),
				cfgCurrentActions: createFormField(form.cfgCurrentActions.value),
				cfgCurrentCrestFactorRecOpt: createFormField(form.cfgCurrentCrestFactorRecOpt.value),
				cfgCurrentUnbalanceRecOpt: createFormField(form.cfgCurrentUnbalanceRecOpt.value),
				cfgActivePowerRecOpt: createFormField(form.cfgActivePowerRecOpt.value),
				cfgActivePowerEventThresholdMax: createFormField(form.cfgActivePowerEventThresholdMax.value),
				cfgActivePowerPlusRecOpt: createFormField(form.cfgActivePowerPlusRecOpt.value),
				cfgActivePowerPlusEventThresholdMax: createFormField(form.cfgActivePowerPlusEventThresholdMax.value),
				cfgActivePowerPlusChannels: createFormField(form.cfgActivePowerPlusChannels.value),
				cfgActivePowerPlusActions: createFormField(form.cfgActivePowerPlusActions.value),
				cfgActivePowerMinusRecOpt: createFormField(form.cfgActivePowerMinusRecOpt.value),
				cfgActivePowerMinusEventThresholdMax: createFormField(form.cfgActivePowerMinusEventThresholdMax.value),
				cfgActivePowerMinusChannels: createFormField(form.cfgActivePowerMinusChannels.value),
				cfgActivePowerMinusActions: createFormField(form.cfgActivePowerMinusActions.value),
				cfgActivePowerFundamentalRecOpt: createFormField(form.cfgActivePowerFundamentalRecOpt.value),
				cfgApparentPowerRecOpt: createFormField(form.cfgApparentPowerRecOpt.value),
				cfgApparentPowerEventThresholdMax: createFormField(form.cfgApparentPowerEventThresholdMax.value),
				cfgApparentPowerChannels: createFormField(form.cfgApparentPowerChannels.value),
				cfgApparentPowerActions: createFormField(form.cfgApparentPowerActions.value),
				cfgApparentPowerFundamentalRecOpt: createFormField(form.cfgApparentPowerFundamentalRecOpt.value),
				cfgApparentPowerNonfundamentalRecOpt: createFormField(form.cfgApparentPowerNonfundamentalRecOpt.value),
				cfgDistortionPowerBudeanuRecOpt: createFormField(form.cfgDistortionPowerBudeanuRecOpt.value),
				cfgReactivePowerRecOpt: createFormField(form.cfgReactivePowerRecOpt.value),
				cfgReactivePowerEventThresholdMax: createFormField(form.cfgReactivePowerEventThresholdMax.value),
				cfgReactivePowerChannels: createFormField(form.cfgReactivePowerChannels.value),
				cfgReactivePowerActions: createFormField(form.cfgReactivePowerActions.value),
				cfgPowerFactorRecOpt: createFormField(form.cfgPowerFactorRecOpt.value),
				cfgPowerFactorEventThresholdMax: createFormField(form.cfgPowerFactorEventThresholdMax.value),
				cfgPowerFactorChannels: createFormField(form.cfgPowerFactorChannels.value),
				cfgPowerFactorActions: createFormField(form.cfgPowerFactorActions.value),
				cfgPowerFactorFundamentalRecOpt: createFormField(form.cfgPowerFactorFundamentalRecOpt.value),
				cfgDisplacementPowerFactorRecOpt: createFormField(form.cfgDisplacementPowerFactorRecOpt.value),
				cfgDisplacementPowerFactorEventThresholdMax: createFormField(form.cfgDisplacementPowerFactorEventThresholdMax.value),
				cfgDisplacementPowerFactorChannels: createFormField(form.cfgDisplacementPowerFactorChannels.value),
				cfgDisplacementPowerFactorActions: createFormField(form.cfgDisplacementPowerFactorActions.value),
				cfgTangent4QRecOpt: createFormField(form.cfgTangent4QRecOpt.value),
				// cfgTangentQuadrant1EventThresholdMin: createFormField(form.cfgTangentQuadrant1EventThresholdMin.value),
				// cfgTangentQuadrant1EventThresholdMax: createFormField(form.cfgTangentQuadrant1EventThresholdMax.value),
				// cfgTangentQuadrant2EventThresholdMin: createFormField(form.cfgTangentQuadrant2EventThresholdMin.value),
				// cfgTangentQuadrant2EventThresholdMax: createFormField(form.cfgTangentQuadrant2EventThresholdMax.value),
				// cfgTangentQuadrant3EventThresholdMin: createFormField(form.cfgTangentQuadrant3EventThresholdMin.value),
				// cfgTangentQuadrant3EventThresholdMax: createFormField(form.cfgTangentQuadrant3EventThresholdMax.value),
				// cfgTangentQuadrant4EventThresholdMin: createFormField(form.cfgTangentQuadrant4EventThresholdMin.value),
				// cfgTangentQuadrant4EventThresholdMax: createFormField(form.cfgTangentQuadrant4EventThresholdMax.value),
				cfgActiveEnergyRecOpt: createFormField(form.cfgActiveEnergyRecOpt.value),
				cfgActiveEnergyPlusEventThresholdMax: createFormField(form.cfgActiveEnergyPlusEventThresholdMax.value),
				cfgActiveEnergyMinusEventThresholdMax: createFormField(form.cfgActiveEnergyMinusEventThresholdMax.value),
				cfgApparentEnergyRecOpt: createFormField(form.cfgApparentEnergyRecOpt.value),
				cfgApparentEnergyEventThresholdMax: createFormField(form.cfgApparentEnergyEventThresholdMax.value),
				cfgReactiveEnergy4QRecOpt: createFormField(form.cfgReactiveEnergy4QRecOpt.value),
				cfgReactiveEnergyQuadrant1EventThresholdMax: createFormField(form.cfgReactiveEnergyQuadrant1EventThresholdMax.value),
				cfgReactiveEnergyQuadrant2EventThresholdMax: createFormField(form.cfgReactiveEnergyQuadrant2EventThresholdMax.value),
				cfgReactiveEnergyQuadrant3EventThresholdMax: createFormField(form.cfgReactiveEnergyQuadrant3EventThresholdMax.value),
				cfgReactiveEnergyQuadrant4EventThresholdMax: createFormField(form.cfgReactiveEnergyQuadrant4EventThresholdMax.value),
				cfgVoltageThdfRecOpt: createFormField(form.cfgVoltageThdfRecOpt.value),
				cfgVoltageThdfThresholdMax: createFormField(form.cfgVoltageThdfThresholdMax.value),
				cfgVoltageThdfChannels: createFormField(form.cfgVoltageThdfChannels.value),
				cfgVoltageThdfActions: createFormField(form.cfgVoltageThdfActions.value),
				cfgVoltageThdrRecOpt: createFormField(form.cfgVoltageThdrRecOpt.value),
				cfgVoltageHarmonicsRecOpt: createFormField(form.cfgVoltageHarmonicsRecOpt.value),
				cfgCurrentThdfRecOpt: createFormField(form.cfgCurrentThdfRecOpt.value),
				cfgCurrentThdfThresholdMax: createFormField(form.cfgCurrentThdfThresholdMax.value),
				cfgCurrentThdfChannels: createFormField(form.cfgCurrentThdfChannels.value),
				cfgCurrentThdfActions: createFormField(form.cfgCurrentThdfActions.value),
				cfgCurrentThdrRecOpt: createFormField(form.cfgCurrentThdrRecOpt.value),
				cfgCurrentHarmonicsRecOpt: createFormField(form.cfgCurrentHarmonicsRecOpt.value),
				cfgActivePowerHarmonicsRecOpt: createFormField(form.cfgActivePowerHarmonicsRecOpt.value),
				cfgHarmonicsAnglesUiRecOpt: createFormField(form.cfgHarmonicsAnglesUiRecOpt.value),
				cfgReactivePowerHarmonicsRecOpt: createFormField(form.cfgReactivePowerHarmonicsRecOpt.value),
				cfgVoltageTidfRecOpt: createFormField(form.cfgVoltageTidfRecOpt.value),
				cfgVoltageTidrRecOpt: createFormField(form.cfgVoltageTidrRecOpt.value),
				cfgCurrentTidfRecOpt: createFormField(form.cfgCurrentTidfRecOpt.value),
				cfgCurrentTidrRecOpt: createFormField(form.cfgCurrentTidrRecOpt.value),
				cfgVoltageInterharmonicsRecOpt: createFormField(form.cfgVoltageInterharmonicsRecOpt.value),
				cfgCurrentInterharmonicsRecOpt: createFormField(form.cfgCurrentInterharmonicsRecOpt.value),
				cfgKfactorRecOpt: createFormField(form.cfgKfactorRecOpt.value),
				cfgPhasorRecOpt: createFormField(form.cfgPhasorRecOpt.value),

				cfgMainsSignallingUr1RecOpt: createFormField(form.cfgMainsSignallingUr1RecOpt.value),
				cfgMainsSignallingUr1Frequency: createFormField(form.cfgMainsSignallingUr1Frequency.value),
				cfgMainsSignallingUr2RecOpt: createFormField(form.cfgMainsSignallingUr2RecOpt.value),
				cfgMainsSignallingUr2Frequency: createFormField(form.cfgMainsSignallingUr2Frequency.value),

				cfgVoltage29KRecOpt: createFormField(form.cfgVoltage29KRecOpt.value),
				cfgVoltage8150KRecOpt: createFormField(form.cfgVoltage8150KRecOpt.value),

				cfgStdEnable: createFormField(form.cfgStdEnable.value),
				cfgStdType: createFormField(form.cfgStdType.value),
				cfgStdAggregationWaveformEnable: createFormField(form.cfgStdAggregationWaveformEnable.value),

				cfgIec61850Enable: createFormField(form.cfgIec61850Enable.value),
				cfgIec61850IedName: createFormField(form.cfgIec61850IedName.value),
				cfgIec61850PhaseVoltageDbRef: createFormField(form.cfgIec61850PhaseVoltageDbRef.value),
				cfgIec61850PhaseVoltageDb: createFormField(form.cfgIec61850PhaseVoltageDb.value),
				cfgIec61850PhaseToPhaseVoltageDbRef: createFormField(form.cfgIec61850PhaseToPhaseVoltageDbRef.value),
				cfgIec61850PhaseToPhaseVoltageDb: createFormField(form.cfgIec61850PhaseToPhaseVoltageDb.value),
				cfgIec61850UneVoltageDbRef: createFormField(form.cfgIec61850UneVoltageDbRef.value),
				cfgIec61850UneVoltageDb: createFormField(form.cfgIec61850UneVoltageDb.value),
				cfgIec61850PhaseCurrentDbRef: createFormField(form.cfgIec61850PhaseCurrentDbRef.value),
				cfgIec61850PhaseCurrentDb: createFormField(form.cfgIec61850PhaseCurrentDb.value),
				cfgIec61850NCurrentDbRef: createFormField(form.cfgIec61850NCurrentDbRef.value),
				cfgIec61850NCurrentDb: createFormField(form.cfgIec61850NCurrentDb.value),
				cfgIec61850ECurrentDbRef: createFormField(form.cfgIec61850ECurrentDbRef.value),
				cfgIec61850ECurrentDb: createFormField(form.cfgIec61850ECurrentDb.value),
				cfgIec61850ActivePowerTotalDbRef: createFormField(form.cfgIec61850ActivePowerTotalDbRef.value),
				cfgIec61850ActivePowerTotalDb: createFormField(form.cfgIec61850ActivePowerTotalDb.value),
				cfgIec61850ReactivePowerTotalDbRef: createFormField(form.cfgIec61850ReactivePowerTotalDbRef.value),
				cfgIec61850ReactivePowerTotalDb: createFormField(form.cfgIec61850ReactivePowerTotalDb.value),
				cfgIec61850ApparentPowerTotalDbRef: createFormField(form.cfgIec61850ApparentPowerTotalDbRef.value),
				cfgIec61850ApparentPowerTotalDb: createFormField(form.cfgIec61850ApparentPowerTotalDb.value),
				cfgIec61850ActivePowerPhaseDbRef: createFormField(form.cfgIec61850ActivePowerPhaseDbRef.value),
				cfgIec61850ActivePowerPhaseDb: createFormField(form.cfgIec61850ActivePowerPhaseDb.value),
				cfgIec61850ReactivePowerPhaseDbRef: createFormField(form.cfgIec61850ReactivePowerPhaseDbRef.value),
				cfgIec61850ReactivePowerPhaseDb: createFormField(form.cfgIec61850ReactivePowerPhaseDb.value),
				cfgIec61850ApparentPowerPhaseDbRef: createFormField(form.cfgIec61850ApparentPowerPhaseDbRef.value),
				cfgIec61850ApparentPowerPhaseDb: createFormField(form.cfgIec61850ApparentPowerPhaseDb.value),
				cfgIec61850FrequencyDbRef: createFormField(form.cfgIec61850FrequencyDbRef.value),
				cfgIec61850FrequencyDb: createFormField(form.cfgIec61850FrequencyDb.value),
				cfgIec61850VoltageHarmonicsDbRef: createFormField(form.cfgIec61850VoltageHarmonicsDbRef.value),
				cfgIec61850VoltageHarmonicsDb: createFormField(form.cfgIec61850VoltageHarmonicsDb.value),
				cfgIec61850CurrentHarmonicsDbRef: createFormField(form.cfgIec61850CurrentHarmonicsDbRef.value),
				cfgIec61850CurrentHarmonicsDb: createFormField(form.cfgIec61850CurrentHarmonicsDb.value),
				cfgIec61850VoltageInterharmonicsDbRef: createFormField(form.cfgIec61850VoltageInterharmonicsDbRef.value),
				cfgIec61850VoltageInterharmonicsDb: createFormField(form.cfgIec61850VoltageInterharmonicsDb.value),
				cfgIec61850CurrentInterharmonicsDbRef: createFormField(form.cfgIec61850CurrentInterharmonicsDbRef.value),
				cfgIec61850CurrentInterharmonicsDb: createFormField(form.cfgIec61850CurrentInterharmonicsDb.value),
				cfgIec61850VoltageThdDbRef: createFormField(form.cfgIec61850VoltageThdDbRef.value),
				cfgIec61850VoltageThdDb: createFormField(form.cfgIec61850VoltageThdDb.value),
				cfgIec61850CurrentThdDbRef: createFormField(form.cfgIec61850CurrentThdDbRef.value),
				cfgIec61850CurrentThdDb: createFormField(form.cfgIec61850CurrentThdDb.value),
				cfgIec61850VoltageTidDbRef: createFormField(form.cfgIec61850VoltageTidDbRef.value),
				cfgIec61850VoltageTidDb: createFormField(form.cfgIec61850VoltageTidDb.value),
				cfgIec61850CurrentTidDbRef: createFormField(form.cfgIec61850CurrentTidDbRef.value),
				cfgIec61850CurrentTidDb: createFormField(form.cfgIec61850CurrentTidDb.value),
				cfgIec61850UnbalanceVoltagePositiveSequenceDbRef: createFormField(form.cfgIec61850UnbalanceVoltagePositiveSequenceDbRef.value),
				cfgIec61850UnbalanceVoltagePositiveSequenceDb: createFormField(form.cfgIec61850UnbalanceVoltagePositiveSequenceDb.value),
				cfgIec61850UnbalanceVoltageNegativeSequenceDbRef: createFormField(form.cfgIec61850UnbalanceVoltageNegativeSequenceDbRef.value),
				cfgIec61850UnbalanceVoltageNegativeSequenceDb: createFormField(form.cfgIec61850UnbalanceVoltageNegativeSequenceDb.value),
				cfgIec61850UnbalanceVoltageZeroSequenceDbRef: createFormField(form.cfgIec61850UnbalanceVoltageZeroSequenceDbRef.value),
				cfgIec61850UnbalanceVoltageZeroSequenceDb: createFormField(form.cfgIec61850UnbalanceVoltageZeroSequenceDb.value),
				cfgIec61850UnbalanceCurrentPositiveSequenceDbRef: createFormField(form.cfgIec61850UnbalanceCurrentPositiveSequenceDbRef.value),
				cfgIec61850UnbalanceCurrentPositiveSequenceDb: createFormField(form.cfgIec61850UnbalanceCurrentPositiveSequenceDb.value),
				cfgIec61850UnbalanceCurrentNegativeSequenceDbRef: createFormField(form.cfgIec61850UnbalanceCurrentNegativeSequenceDbRef.value),
				cfgIec61850UnbalanceCurrentNegativeSequenceDb: createFormField(form.cfgIec61850UnbalanceCurrentNegativeSequenceDb.value),
				cfgIec61850UnbalanceCurrentZeroSequenceDbRef: createFormField(form.cfgIec61850UnbalanceCurrentZeroSequenceDbRef.value),
				cfgIec61850UnbalanceCurrentZeroSequenceDb: createFormField(form.cfgIec61850UnbalanceCurrentZeroSequenceDb.value),
				cfgIec61850UnbalanceCurrentNegativeDbRef: createFormField(form.cfgIec61850UnbalanceCurrentNegativeDbRef.value),
				cfgIec61850UnbalanceCurrentNegativeDb: createFormField(form.cfgIec61850UnbalanceCurrentNegativeDb.value),
				cfgIec61850FlickerPstDbRef: createFormField(form.cfgIec61850FlickerPstDbRef.value),
				cfgIec61850FlickerPstDb: createFormField(form.cfgIec61850FlickerPstDb.value),
				cfgIec61850FlickerPltDbRef: createFormField(form.cfgIec61850FlickerPltDbRef.value),
				cfgIec61850FlickerPltDb: createFormField(form.cfgIec61850FlickerPltDb.value),
				cfgIec6185029KhzVoltageDbRef: createFormField(form.cfgIec6185029KhzVoltageDbRef.value),
				cfgIec6185029KhzVoltageDb: createFormField(form.cfgIec6185029KhzVoltageDb.value),
				cfgIec6185029KhzVoltageMaxDbRef: createFormField(form.cfgIec6185029KhzVoltageMaxDbRef.value),
				cfgIec6185029KhzVoltageMaxDb: createFormField(form.cfgIec6185029KhzVoltageMaxDb.value),
				cfgIec618509150KhzVoltageDbRef: createFormField(form.cfgIec618509150KhzVoltageDbRef.value),
				cfgIec618509150KhzVoltageDb: createFormField(form.cfgIec618509150KhzVoltageDb.value),
				cfgIec618509150KhzVoltageMaxDbRef: createFormField(form.cfgIec618509150KhzVoltageMaxDbRef.value),
				cfgIec618509150KhzVoltageMaxDb: createFormField(form.cfgIec618509150KhzVoltageMaxDb.value),
				cfgIec61850BatteryVoltageDbRef: createFormField(form.cfgIec61850BatteryVoltageDbRef.value),
				cfgIec61850BatteryVoltageDb: createFormField(form.cfgIec61850BatteryVoltageDb.value),

				cfgDigitalInputInternal1ActiveState: createFormField(form.cfgDigitalInputInternal1ActiveState.value),
				cfgDigitalInputInternal1RecOpt: createFormField(form.cfgDigitalInputInternal1RecOpt.value),
				cfgDigitalInputInternal2ActiveState: createFormField(form.cfgDigitalInputInternal2ActiveState.value),
				cfgDigitalInputInternal2RecOpt: createFormField(form.cfgDigitalInputInternal2RecOpt.value),
				cfgRelay1ActiveState: createFormField(form.cfgRelay1ActiveState.value),
				cfgRelay1Mode: createFormField(form.cfgRelay1Mode.value),
				cfgRelay1ActiveTime: createFormField(form.cfgRelay1ActiveTime.value),
				cfgRelay1DeadTime: createFormField(form.cfgRelay1DeadTime.value),
				cfgRelay2ActiveState: createFormField(form.cfgRelay2ActiveState.value),
				cfgRelay2Mode: createFormField(form.cfgRelay2Mode.value),
				cfgRelay2ActiveTime: createFormField(form.cfgRelay2ActiveTime.value),
				cfgRelay2DeadTime: createFormField(form.cfgRelay2DeadTime.value),
			},
			payload: {
				// Recording General
				...(+form.eventsHysteresis.value !== eventsHysteresis ? { cfg_events_hysteresis: { value: +form.eventsHysteresis.value } } : {}),
				...(form.waveformFormat.value !== waveformFormat ? { cfg_waveform_format: { value: form.waveformFormat.value } } : {}),
				...(+form.waveformRecDuration.value !== waveformRecDuration ? { cfg_waveform_rec_duration: { value: +form.waveformRecDuration.value } } : {}),
				...(+form.waveformPretriggerDuration.value !== waveformPretriggerDuration ? { cfg_waveform_pretrigger_duration: { value: +form.waveformPretriggerDuration.value } } : {}),
				...(+form.rms12RecTime.value !== rms12RecTime ? { cfg_rms12_rec_time: { value: +form.rms12RecTime.value } } : {}),
				...(+form.currentCh1TransducerRatio.value !== cfgCurrentCh1TransducerRatio ? { cfg_current_ch1_transducer_ratio: { value: +form.currentCh1TransducerRatio.value } } : {}),
				...(+form.currentCh2TransducerRatio.value !== cfgCurrentCh2TransducerRatio ? { cfg_current_ch2_transducer_ratio: { value: +form.currentCh2TransducerRatio.value } } : {}),
				...(+form.currentCh3TransducerRatio.value !== cfgCurrentCh3TransducerRatio ? { cfg_current_ch3_transducer_ratio: { value: +form.currentCh3TransducerRatio.value } } : {}),
				...(+form.currentCh4TransducerRatio.value !== cfgCurrentCh4TransducerRatio ? { cfg_current_ch4_transducer_ratio: { value: +form.currentCh4TransducerRatio.value } } : {}),
				...(+form.currentCh5TransducerRatio.value !== cfgCurrentCh5TransducerRatio ? { cfg_current_ch5_transducer_ratio: { value: +form.currentCh5TransducerRatio.value } } : {}),
				...(+form.rms12PretriggerTime.value !== rms12PretriggerTime ? { cfg_rms12_pretrigger_time: { value: +form.rms12PretriggerTime.value } } : {}),

				// Recording Events
				...(form.type.value !== type ? { cfg_system_type: { value: form.type.value } } : {}),
				...(form.nominalFrequency.value !== nominalFrequency ? { cfg_nominal_frequency: { value: form.nominalFrequency.value } } : {}),
				...(+form.voltageTransducerRatio.value !== voltageTransducerRatio ? { cfg_voltage_transducer_ratio: { value: +form.voltageTransducerRatio.value } } : {}),
				...(form.ch5Enabled.value !== ch5Enabled ? { cfg_current_ch5_enable: { value: Number(form.ch5Enabled.value) } } : {}),
				...(+form.nominalVoltage.value !== nominalVoltage ? { cfg_nominal_voltage: { value: +form.nominalVoltage.value } } : {}),
				...(form.unpeChannelEnable.value !== unpeChannelEnable ? { cfg_unpe_channel_enable: { value: Number(form.unpeChannelEnable.value) } } : {}),
				...(form.ch4Enabled.value !== ch4Enabled ? { cfg_current_ch4_enable: { value: Number(form.ch4Enabled.value) } } : {}),
				...(form.phaseRotation.value !== phaseRotation ? { cfg_phase_rotation: { value: form.phaseRotation.value } } : {}),

				// Memory
				...(+form.userRecordingPerc.value !== userRecordingPerc ? { cfg_user_recording_space_quota_in_percent: { value: +form.userRecordingPerc.value } } : {}),
				...(+form.stdRecordingPerc.value !== stdRecordingPerc ? { cfg_std_recording_space_quota_in_percent: { value: +form.stdRecordingPerc.value } } : {}),
				...(+form.energyCountersPerc.value !== energyCountersPerc ? { cfg_energy_counters_space_quota_in_percent: { value: +form.energyCountersPerc.value } } : {}),
				...(form.userDataModel.value !== userDataModel ? { cfg_user_data_memory_model: { value: form.userDataModel.value } } : {}),
				...(form.stdDataModel.value !== stdDataModel ? { cfg_std_data_memory_model: { value: form.stdDataModel.value } } : {}),
				...(form.energyDataModel.value !== energyDataModel ? { cfg_energy_data_memory_model: { value: form.energyDataModel.value } } : {}),

				// Modbus TCP
				...(form.tcpEnabled.value !== tcpEnabled ? { cfg_modbus_tcp_enable: { value: Number(form.tcpEnabled.value) } } : {}),
				...(+form.tcpSlaveAddress.value !== tcpSlaveAddress ? { cfg_modbus_tcp_slave_address: { value: +form.tcpSlaveAddress.value } } : {}),
				...(+form.tcpPort.value !== tcpPort ? { cfg_modbus_tcp_port: { value: +form.tcpPort.value } } : {}),
				...(form.tcpEndianness.value !== tcpEndianness ? { cfg_modbus_tcp_endianness: { value: form.tcpEndianness.value } } : {}),

				// Modbus RTU
				...(form.rtuEnabled.value !== rtuEnabled ? { cfg_modbus_rtu_enable: { value: Number(form.rtuEnabled.value) } } : {}),
				...(+form.rtuSlaveAddress.value !== rtuSlaveAddress ? { cfg_modbus_rtu_slave_address: { value: +form.rtuSlaveAddress.value } } : {}),
				...(form.rtuEndianness.value !== rtuEndianness ? { cfg_modbus_rtu_endianness: { value: form.rtuEndianness.value } } : {}),
				...(form.rtuParity.value !== rtuParity ? { cfg_modbus_rtu_parity: { value: form.rtuParity.value } } : {}),
				...(form.rtuBaudrate.value !== rtuBaudrate ? { cfg_modbus_rtu_baudrate: { value: form.rtuBaudrate.value } } : {}),

				// FTP
				...(form.ftpEnable.value !== ftpEnable ? { cfg_ftp_enable: { value: Number(form.ftpEnable.value) } } : {}),
				...(form.ftpConnectionType.value !== ftpConnectionType ? { cfg_ftp_connection_type: { value: form.ftpConnectionType.value } } : {}),
				...(form.ftpMode.value !== ftpMode ? { cfg_ftp_mode: { value: form.ftpMode.value } } : {}),
				...(form.ftpUploadTime.value !== ftpUploadTime ? { cfg_ftp_upload_time: { value: form.ftpUploadTime.value } } : {}),
				...(form.ftpServerIp.value !== ftpServerIp ? { cfg_ftp_server_ip: { value: form.ftpServerIp.value } } : {}),
				...(+form.ftpServerPort.value !== ftpServerPort ? { cfg_ftp_server_port: { value: +form.ftpServerPort.value } } : {}),
				...(form.ftpUserName.value !== ftpUserName ? { cfg_ftp_user_name: { value: form.ftpUserName.value } } : {}),
				...(form.ftpPassword.value !== ftpPassword ? { cfg_ftp_password: { value: form.ftpPassword.value } } : {}),
				...(form.ftpDestDirectory.value !== ftpDestDirectory ? { cfg_ftp_dest_directory: { value: form.ftpDestDirectory.value } } : {}),

				// Time
				...(form.rtcUtcTimestamp.value !== rtc ? { rtc_utc_timestamp: { value: rtcLocal } } : {}),
				...(form.timeSyncMode.value !== timeSyncMode ? { cfg_time_sync_mode: { value: form.timeSyncMode.value } } : {}),
				...(form.timezone.value !== timezone ? { cfg_timezone: { value: timezoneNumberDictionary()[ form.timezone.value ] } } : {}),
				...(form.daylightSavingsTime.value !== daylightSavingsTime ? { cfg_daylight_savings_time: { value: Number(form.daylightSavingsTime.value) } } : {}),
				...(form.NTPEnable.value !== NTPEnable ? { cfg_ntp_enable: { value: Number(form.NTPEnable.value) } } : {}),
				...(form.NTPServer1Name.value !== NTPServer1Name ? { cfg_ntp_server_1_name: { value: form.NTPServer1Name.value } } : {}),
				...(form.NTPServer2Name.value !== NTPServer2Name ? { cfg_ntp_server_2_name: { value: form.NTPServer2Name.value } } : {}),
				...(form.NTPServer3Name.value !== NTPServer3Name ? { cfg_ntp_server_3_name: { value: form.NTPServer3Name.value } } : {}),
				...(form.NTPServer4Name.value !== NTPServer4Name ? { cfg_ntp_server_4_name: { value: form.NTPServer4Name.value } } : {}),

				// Measurements User
				...(form.cfgUserEnable.value !== cfgUserEnable ? { cfg_user_data_rec_enable: { value: Number(form.cfgUserEnable.value) } } : {}),
				...(form.cfgAggregationPeriodUser.value !== cfgAggregationPeriodUser ? { cfg_aggregation_period_user: { value: form.cfgAggregationPeriodUser.value } } : {}),
				...(form.cfgUserAggregationWaveformEnable.value !== cfgUserAggregationWaveformEnable ? { cfg_user_waveforms_and_rms12_rec_enable: { value: Number(form.cfgUserAggregationWaveformEnable.value) } } : {}),
				...(form.cfgPowerCalculationMethod.value !== cfgPowerCalculationMethod ? { cfg_power_calculation_method: { value: form.cfgPowerCalculationMethod.value } } : {}),

				...(form.cfgVoltageRmsRecOpt.value !== cfgVoltageRmsRecOpt ? { cfg_voltage_rms_rec_opt: { value: form.cfgVoltageRmsRecOpt.value } } : {}),
				...(form.cfgVoltageRmsEventThresholdUserDip.value !== cfgVoltageRmsEventThresholdUserDip ? { cfg_voltage_rms_event_threshold_user_dip: { value: +form.cfgVoltageRmsEventThresholdUserDip.value } } : {}),
				...(form.cfgVoltageRmsEventThresholdUserSwell.value !== cfgVoltageRmsEventThresholdUserSwell ? { cfg_voltage_rms_event_threshold_user_swell: { value: +form.cfgVoltageRmsEventThresholdUserSwell.value } } : {}),
				...(form.cfgVoltageRmsEventThresholdUserInterruption.value !== cfgVoltageRmsEventThresholdUserInterruption ? { cfg_voltage_rms_event_threshold_user_interruption: { value: +form.cfgVoltageRmsEventThresholdUserInterruption.value } } : {}),
				...(form.cfgVoltageRmsEventThresholdMaxCh4.value !== cfgVoltageRmsEventThresholdMaxCh4 ? { cfg_voltage_rms_event_threshold_max_ch4: { value: +form.cfgVoltageRmsEventThresholdMaxCh4.value } } : {}),
				...(translateDipsConfig(measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms, form.cfgDipChannels.value, form.cfgDipActions.value)),
				...(translateSwellConfig(measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms, form.cfgSwellChannels.value, form.cfgSwellActions.value)),
				...(translateInterruptConfig(measurementsUser.cfgPhaseVoltageRms ?? measurementsUser.cfgPhaseToPhaseVoltageRms, form.cfgInterruptChannels.value, form.cfgInterruptActions.value)),
				...(form.cfgVoltageDcRecOpt.value !== cfgVoltageDcRecOpt ? { cfg_voltage_dc_rec_opt: { value: form.cfgVoltageDcRecOpt.value } } : {}),
				...(form.cfgWaveshapeVariationsRecOpt.value !== cfgWaveshapeVariationsRecOpt ? { cfg_waveshape_variations_rec_opt: { value: form.cfgWaveshapeVariationsRecOpt.value } } : {}),
				...(form.cfgWaveshapeVariationsDeadtime.value !== cfgWaveshapeVariationsDeadtime ? { cfg_waveshape_variations_deadtime: { value: +form.cfgWaveshapeVariationsDeadtime.value } } : {}),
				...(form.cfgWaveshapeVariationsThreshold.value !== cfgWaveshapeVariationsThreshold ? { cfg_waveshape_variations_threshold: { value: +form.cfgWaveshapeVariationsThreshold.value } } : {}),
				...(translateWaveshapeVariationsConfig(measurementsUser.cfgWaveshapeVariations, form.cfgWaveshapeVariationsChannels.value, form.cfgWaveshapeVariationsActions.value)),
				...(form.cfgPhaseJumpsRecOpt.value !== cfgPhaseJumpsRecOpt ? { cfg_phase_jumps_rec_opt: { value: form.cfgPhaseJumpsRecOpt.value } } : {}),
				...(form.cfgPhaseJumpsThreshold.value !== cfgPhaseJumpsThreshold ? { cfg_phase_jumps_threshold: { value: +form.cfgPhaseJumpsThreshold.value } } : {}),
				...(translatePhaseJumpConfig(measurementsUser.cfgPhaseJumps, form.cfgPhaseJumpsChannels.value, form.cfgPhaseJumpsActions.value)),
				...(form.cfgFrequencyRecOpt.value !== cfgFrequencyRecOpt ? { cfg_frequency_rec_opt: { value: form.cfgFrequencyRecOpt.value } } : {}),
				...(form.cfgFrequencyEventThresholdMin.value !== cfgFrequencyEventThresholdMin ? { cfg_frequency_event_threshold_min: { value: +form.cfgFrequencyEventThresholdMin.value } } : {}),
				...(form.cfgFrequencyEventThresholdMax.value !== cfgFrequencyEventThresholdMax ? { cfg_frequency_event_threshold_max: { value: +form.cfgFrequencyEventThresholdMax.value } } : {}),
				...(translateFrequencyConfig(measurementsUser.cfgFrequency, form.cfgFrequencyChannels.value, form.cfgFrequencyActions.value)),
				...(form.cfgVoltageCrestFactorRecOpt.value !== cfgVoltageCrestFactorRecOpt ? { cfg_voltage_crest_factor_rec_opt: { value: form.cfgVoltageCrestFactorRecOpt.value } } : {}),
				...(form.cfgVoltageUnbalanceRecOpt.value !== cfgVoltageUnbalanceRecOpt ? { cfg_voltage_unbalance_rec_opt: { value: form.cfgVoltageUnbalanceRecOpt.value } } : {}),
				...(form.cfgVoltageUnbalanceThresholdMax.value !== cfgVoltageUnbalanceThresholdMax ? { cfg_voltage_unbalance_threshold_max: { value: +form.cfgVoltageUnbalanceThresholdMax.value } } : {}),
				...(form.cfgFlickerPstRecOpt.value !== cfgFlickerPstRecOpt ? { cfg_flicker_pst_rec_opt: { value: form.cfgFlickerPstRecOpt.value } } : {}),
				...(form.cfgFlickerPstThresholdMax.value !== cfgFlickerPstThresholdMax ? { cfg_flicker_pst_threshold_max: { value: +form.cfgFlickerPstThresholdMax.value } } : {}),
				...(translateFlickerPstConfig(measurementsUser.cfgFlickerPst, form.cfgFlickerPstChannels.value, form.cfgFlickerPstActions.value)),
				...(form.cfgFlickerPltRecOpt.value !== cfgFlickerPltRecOpt ? { cfg_flicker_plt_rec_opt: { value: form.cfgFlickerPltRecOpt.value } } : {}),
				...(form.cfgFlickerPltThresholdMax.value !== cfgFlickerPltThresholdMax ? { cfg_flicker_plt_threshold_max: { value: +form.cfgFlickerPltThresholdMax.value } } : {}),
				...(translateFlickerPltConfig(measurementsUser.cfgFlickerPlt, form.cfgFlickerPltChannels.value, form.cfgFlickerPltActions.value)),
				...(form.cfgCurrentRmsRecOpt.value !== cfgCurrentRmsRecOpt ? { cfg_current_rms_rec_opt: { value: form.cfgCurrentRmsRecOpt.value } } : {}),
				...(form.cfgCurrentRmsEventThresholdMax.value !== cfgCurrentRmsEventThresholdMax ? { cfg_current_rms_event_threshold_max: { value: +form.cfgCurrentRmsEventThresholdMax.value } } : {}),
				...(form.cfgCurrentRmsEventThresholdMaxCh4.value !== cfgCurrentRmsEventThresholdMaxCh4 ? { cfg_current_rms_event_threshold_max_ch4: { value: +form.cfgCurrentRmsEventThresholdMaxCh4.value } } : {}),
				...(form.cfgCurrentRmsEventThresholdMaxCh5.value !== cfgCurrentRmsEventThresholdMaxCh5 ? { cfg_current_rms_event_threshold_max_ch5: { value: +form.cfgCurrentRmsEventThresholdMaxCh5.value } } : {}),
				...(translateCurrentConfig(measurementsUser.cfgCurrentRms, form.cfgCurrentChannels.value, form.cfgCurrentActions.value)),
				...(form.cfgCurrentCrestFactorRecOpt.value !== cfgCurrentCrestFactorRecOpt ? { cfg_current_crest_factor_rec_opt: { value: form.cfgCurrentCrestFactorRecOpt.value } } : {}),
				...(form.cfgCurrentUnbalanceRecOpt.value !== cfgCurrentUnbalanceRecOpt ? { cfg_current_unbalance_rec_opt: { value: form.cfgCurrentUnbalanceRecOpt.value } } : {}),
				...(form.cfgActivePowerRecOpt.value !== cfgActivePowerRecOpt ? { cfg_active_power_rec_opt: { value: form.cfgActivePowerRecOpt.value } } : {}),
				...(form.cfgActivePowerEventThresholdMax.value !== cfgActivePowerEventThresholdMax ? { cfg_active_power_event_threshold_max: { value: +form.cfgActivePowerEventThresholdMax.value } } : {}),
				...(form.cfgActivePowerPlusRecOpt.value !== cfgActivePowerPlusRecOpt ? { cfg_active_power_plus_rec_opt: { value: form.cfgActivePowerPlusRecOpt.value } } : {}),
				...(form.cfgActivePowerPlusEventThresholdMax.value !== cfgActivePowerPlusEventThresholdMax ? { cfg_active_power_plus_event_threshold_max: { value: +form.cfgActivePowerPlusEventThresholdMax.value } } : {}),
				...(translateActivePowerPlusConfig(measurementsUser.cfgActivePowerPlus, form.cfgActivePowerPlusChannels.value, form.cfgActivePowerPlusActions.value)),
				...(form.cfgActivePowerMinusRecOpt.value !== cfgActivePowerMinusRecOpt ? { cfg_active_power_minus_rec_opt: { value: form.cfgActivePowerMinusRecOpt.value } } : {}),
				...(form.cfgActivePowerMinusEventThresholdMax.value !== cfgActivePowerMinusEventThresholdMax ? { cfg_active_power_minus_event_threshold_max: { value: +form.cfgActivePowerMinusEventThresholdMax.value } } : {}),
				...(translateActivePowerMinusConfig(measurementsUser.cfgActivePowerMinus, form.cfgActivePowerMinusChannels.value, form.cfgActivePowerMinusActions.value)),
				...(form.cfgActivePowerFundamentalRecOpt.value !== cfgActivePowerFundamentalRecOpt ? { cfg_active_power_fundamental_rec_opt: { value: form.cfgActivePowerFundamentalRecOpt.value } } : {}),
				...(form.cfgApparentPowerRecOpt.value !== cfgApparentPowerRecOpt ? { cfg_apparent_power_rec_opt: { value: form.cfgApparentPowerRecOpt.value } } : {}),
				...(form.cfgApparentPowerEventThresholdMax.value !== cfgApparentPowerEventThresholdMax ? { cfg_apparent_power_event_threshold_max: { value: +form.cfgApparentPowerEventThresholdMax.value } } : {}),
				...(translateApparentPowerConfig(measurementsUser.cfgApparentPower, form.cfgApparentPowerChannels.value, form.cfgApparentPowerActions.value)),
				...(form.cfgApparentPowerFundamentalRecOpt.value !== cfgApparentPowerFundamentalRecOpt ? { cfg_apparent_power_fundamental_rec_opt: { value: form.cfgApparentPowerFundamentalRecOpt.value } } : {}),
				...(form.cfgApparentPowerNonfundamentalRecOpt.value !== cfgApparentPowerNonfundamentalRecOpt ? { cfg_apparent_power_nonfundamental_rec_opt: { value: form.cfgApparentPowerNonfundamentalRecOpt.value } } : {}),
				...(form.cfgDistortionPowerBudeanuRecOpt.value !== cfgDistortionPowerBudeanuRecOpt ? { cfg_distortion_power_budeanu_rec_opt: { value: form.cfgDistortionPowerBudeanuRecOpt.value } } : {}),
				...(form.cfgReactivePowerRecOpt.value !== cfgReactivePowerRecOpt ? { cfg_reactive_power_rec_opt: { value: form.cfgReactivePowerRecOpt.value } } : {}),
				...(form.cfgReactivePowerEventThresholdMax.value !== cfgReactivePowerEventThresholdMax ? { cfg_reactive_power_event_threshold_max: { value: +form.cfgReactivePowerEventThresholdMax.value } } : {}),
				...(translateReactivePowerConfig(measurementsUser.cfgReactivePower, form.cfgReactivePowerChannels.value, form.cfgReactivePowerActions.value)),
				...(form.cfgPowerFactorRecOpt.value !== cfgPowerFactorRecOpt ? { cfg_power_factor_rec_opt: { value: form.cfgPowerFactorRecOpt.value } } : {}),
				...(form.cfgPowerFactorEventThresholdMax.value !== cfgPowerFactorEventThresholdMax ? { cfg_power_factor_event_threshold_max: { value: +form.cfgPowerFactorEventThresholdMax.value } } : {}),
				...(translatePowerFactorConfig(measurementsUser.cfgPowerFactor, form.cfgPowerFactorChannels.value, form.cfgPowerFactorActions.value)),
				...(form.cfgPowerFactorFundamentalRecOpt.value !== cfgPowerFactorFundamentalRecOpt ? { cfg_power_factor_fundamental_rec_opt: { value: form.cfgPowerFactorFundamentalRecOpt.value } } : {}),
				...(form.cfgDisplacementPowerFactorRecOpt.value !== cfgDisplacementPowerFactorRecOpt ? { cfg_displacement_power_factor_rec_opt: { value: form.cfgDisplacementPowerFactorRecOpt.value } } : {}),
				...(form.cfgDisplacementPowerFactorEventThresholdMax.value !== cfgDisplacementPowerFactorEventThresholdMax ? { cfg_displacement_power_factor_event_threshold_max: { value: +form.cfgDisplacementPowerFactorEventThresholdMax.value } } : {}),
				...(translateDisplacementPowerFactorConfig(measurementsUser.cfgDisplacementPowerFactor, form.cfgDisplacementPowerFactorChannels.value, form.cfgDisplacementPowerFactorActions.value)),
				...(form.cfgTangent4QRecOpt.value !== cfgTangent4QRecOpt ? { cfg_tangent4q_rec_opt: { value: form.cfgTangent4QRecOpt.value } } : {}),
				// ...(form.cfgTangentQuadrant1EventThresholdMin.value !== cfgTangentQuadrant1EventThresholdMin ? { cfg_tangent_quadrant1_event_threshold_min: { value: +form.cfgTangentQuadrant1EventThresholdMin.value } } : {}),
				// ...(form.cfgTangentQuadrant1EventThresholdMax.value !== cfgTangentQuadrant1EventThresholdMax ? { cfg_tangent_quadrant1_event_threshold_max: { value: +form.cfgTangentQuadrant1EventThresholdMax.value } } : {}),
				// ...(form.cfgTangentQuadrant2EventThresholdMin.value !== cfgTangentQuadrant2EventThresholdMin ? { cfg_tangent_quadrant2_event_threshold_min: { value: +form.cfgTangentQuadrant2EventThresholdMin.value } } : {}),
				// ...(form.cfgTangentQuadrant2EventThresholdMax.value !== cfgTangentQuadrant2EventThresholdMax ? { cfg_tangent_quadrant2_event_threshold_max: { value: +form.cfgTangentQuadrant2EventThresholdMax.value } } : {}),
				// ...(form.cfgTangentQuadrant3EventThresholdMin.value !== cfgTangentQuadrant3EventThresholdMin ? { cfg_tangent_quadrant3_event_threshold_min: { value: +form.cfgTangentQuadrant3EventThresholdMin.value } } : {}),
				// ...(form.cfgTangentQuadrant3EventThresholdMax.value !== cfgTangentQuadrant3EventThresholdMax ? { cfg_tangent_quadrant3_event_threshold_max: { value: +form.cfgTangentQuadrant3EventThresholdMax.value } } : {}),
				// ...(form.cfgTangentQuadrant4EventThresholdMin.value !== cfgTangentQuadrant4EventThresholdMin ? { cfg_tangent_quadrant4_event_threshold_min: { value: +form.cfgTangentQuadrant4EventThresholdMin.value } } : {}),
				// ...(form.cfgTangentQuadrant4EventThresholdMax.value !== cfgTangentQuadrant4EventThresholdMax ? { cfg_tangent_quadrant4_event_threshold_max: { value: +form.cfgTangentQuadrant4EventThresholdMax.value } } : {}),
				...(form.cfgActiveEnergyRecOpt.value !== cfgActiveEnergyRecOpt ? { cfg_active_energy_rec_opt: { value: form.cfgActiveEnergyRecOpt.value } } : {}),
				...(form.cfgActiveEnergyPlusEventThresholdMax.value !== cfgActiveEnergyPlusEventThresholdMax ? { cfg_active_energy_plus_event_threshold_max: { value: +form.cfgActiveEnergyPlusEventThresholdMax.value } } : {}),
				...(form.cfgActiveEnergyMinusEventThresholdMax.value !== cfgActiveEnergyMinusEventThresholdMax ? { cfg_active_energy_minus_event_threshold_max: { value: +form.cfgActiveEnergyMinusEventThresholdMax.value } } : {}),
				...(form.cfgApparentEnergyRecOpt.value !== cfgApparentEnergyRecOpt ? { cfg_apparent_energy_rec_opt: { value: form.cfgApparentEnergyRecOpt.value } } : {}),
				...(form.cfgApparentEnergyEventThresholdMax.value !== cfgApparentEnergyEventThresholdMax ? { cfg_apparent_energy_event_threshold_max: { value: +form.cfgApparentEnergyEventThresholdMax.value } } : {}),
				...(form.cfgReactiveEnergy4QRecOpt.value !== cfgReactiveEnergy4QRecOpt ? { cfg_reactive_energy4q_rec_opt: { value: form.cfgReactiveEnergy4QRecOpt.value } } : {}),
				...(form.cfgReactiveEnergyQuadrant1EventThresholdMax.value !== cfgReactiveEnergyQuadrant1EventThresholdMax ? { cfg_reactive_energy_quadrant1_event_threshold_max: { value: +form.cfgReactiveEnergyQuadrant1EventThresholdMax.value } } : {}),
				...(form.cfgReactiveEnergyQuadrant2EventThresholdMax.value !== cfgReactiveEnergyQuadrant2EventThresholdMax ? { cfg_reactive_energy_quadrant2_event_threshold_max: { value: +form.cfgReactiveEnergyQuadrant2EventThresholdMax.value } } : {}),
				...(form.cfgReactiveEnergyQuadrant3EventThresholdMax.value !== cfgReactiveEnergyQuadrant3EventThresholdMax ? { cfg_reactive_energy_quadrant3_event_threshold_max: { value: +form.cfgReactiveEnergyQuadrant3EventThresholdMax.value } } : {}),
				...(form.cfgReactiveEnergyQuadrant4EventThresholdMax.value !== cfgReactiveEnergyQuadrant4EventThresholdMax ? { cfg_reactive_energy_quadrant4_event_threshold_max: { value: +form.cfgReactiveEnergyQuadrant4EventThresholdMax.value } } : {}),
				...(form.cfgVoltageThdfRecOpt.value !== cfgVoltageThdfRecOpt ? { cfg_voltage_thdf_rec_opt: { value: form.cfgVoltageThdfRecOpt.value } } : {}),
				...(form.cfgVoltageThdfThresholdMax.value !== cfgVoltageThdfThresholdMax ? { cfg_voltage_thdf_threshold_max: { value: +form.cfgVoltageThdfThresholdMax.value } } : {}),
				...(translateVoltageThdfConfig(measurementsUser.cfgVoltageThdf, form.cfgVoltageThdfChannels.value, form.cfgVoltageThdfActions.value)),
				...(form.cfgVoltageThdrRecOpt.value !== cfgVoltageThdrRecOpt ? { cfg_voltage_thdr_rec_opt: { value: form.cfgVoltageThdrRecOpt.value } } : {}),
				...(form.cfgVoltageHarmonicsRecOpt.value !== cfgVoltageHarmonicsRecOpt ? { cfg_voltage_harmonics_rec_opt: { value: form.cfgVoltageHarmonicsRecOpt.value } } : {}),
				...(form.cfgCurrentThdfRecOpt.value !== cfgCurrentThdfRecOpt ? { cfg_current_thdf_rec_opt: { value: form.cfgCurrentThdfRecOpt.value } } : {}),
				...(form.cfgCurrentThdfThresholdMax.value !== cfgCurrentThdfThresholdMax ? { cfg_current_thdf_threshold_max: { value: +form.cfgCurrentThdfThresholdMax.value } } : {}),
				...(translateCurrentThdfConfig(measurementsUser.cfgCurrentThdf, form.cfgCurrentThdfChannels.value, form.cfgCurrentThdfActions.value)),
				...(form.cfgCurrentThdrRecOpt.value !== cfgCurrentThdrRecOpt ? { cfg_current_thdr_rec_opt: { value: form.cfgCurrentThdrRecOpt.value } } : {}),
				...(form.cfgCurrentHarmonicsRecOpt.value !== cfgCurrentHarmonicsRecOpt ? { cfg_current_harmonics_rec_opt: { value: form.cfgCurrentHarmonicsRecOpt.value } } : {}),
				...(form.cfgActivePowerHarmonicsRecOpt.value !== cfgActivePowerHarmonicsRecOpt ? { cfg_active_power_harmonics_rec_opt: { value: form.cfgActivePowerHarmonicsRecOpt.value } } : {}),
				...(form.cfgHarmonicsAnglesUiRecOpt.value !== cfgHarmonicsAnglesUiRecOpt ? { cfg_harmonics_angles_ui_rec_opt: { value: form.cfgHarmonicsAnglesUiRecOpt.value } } : {}),
				...(form.cfgReactivePowerHarmonicsRecOpt.value !== cfgReactivePowerHarmonicsRecOpt ? { cfg_reactive_power_harmonics_rec_opt: { value: form.cfgReactivePowerHarmonicsRecOpt.value } } : {}),
				...(form.cfgVoltageTidfRecOpt.value !== cfgVoltageTidfRecOpt ? { cfg_voltage_tidf_rec_opt: { value: form.cfgVoltageTidfRecOpt.value } } : {}),
				...(form.cfgVoltageTidrRecOpt.value !== cfgVoltageTidrRecOpt ? { cfg_voltage_tidr_rec_opt: { value: form.cfgVoltageTidrRecOpt.value } } : {}),
				...(form.cfgCurrentTidfRecOpt.value !== cfgCurrentTidfRecOpt ? { cfg_current_tidf_rec_opt: { value: form.cfgCurrentTidfRecOpt.value } } : {}),
				...(form.cfgCurrentTidrRecOpt.value !== cfgCurrentTidrRecOpt ? { cfg_current_tidr_rec_opt: { value: form.cfgCurrentTidrRecOpt.value } } : {}),
				...(form.cfgVoltageInterharmonicsRecOpt.value !== cfgVoltageInterharmonicsRecOpt ? { cfg_voltage_interharmonics_rec_opt: { value: form.cfgVoltageInterharmonicsRecOpt.value } } : {}),
				...(form.cfgCurrentInterharmonicsRecOpt.value !== cfgCurrentInterharmonicsRecOpt ? { cfg_current_interharmonics_rec_opt: { value: form.cfgCurrentInterharmonicsRecOpt.value } } : {}),
				...(form.cfgKfactorRecOpt.value !== cfgKfactorRecOpt ? { cfg_kfactor_rec_opt: { value: form.cfgKfactorRecOpt.value } } : {}),
				...(form.cfgPhasorRecOpt.value !== cfgPhasorRecOpt ? { cfg_phasor_rec_opt: { value: form.cfgPhasorRecOpt.value } } : {}),

				...(form.cfgMainsSignallingUr1RecOpt.value !== cfgMainsSignallingUr1RecOpt ? { cfg_mains_signalling_ur1_rec_opt: { value: form.cfgMainsSignallingUr1RecOpt.value } } : {}),
				...(form.cfgMainsSignallingUr1Frequency.value !== cfgMainsSignallingUr1Frequency ? { cfg_mains_signalling_ur1_frequency: { value: +form.cfgMainsSignallingUr1Frequency.value } } : {}),
				...(form.cfgMainsSignallingUr2RecOpt.value !== cfgMainsSignallingUr2RecOpt ? { cfg_mains_signalling_ur2_rec_opt: { value: form.cfgMainsSignallingUr2RecOpt.value } } : {}),
				...(form.cfgMainsSignallingUr2Frequency.value !== cfgMainsSignallingUr2Frequency ? { cfg_mains_signalling_ur2_frequency: { value: +form.cfgMainsSignallingUr2Frequency.value } } : {}),

				...(form.cfgVoltage29KRecOpt.value !== cfgVoltage29KRecOpt ? { cfg_voltage_2_9k_rec_opt: { value: form.cfgVoltage29KRecOpt.value } } : {}),
				...(form.cfgVoltage8150KRecOpt.value !== cfgVoltage8150KRecOpt ? { cfg_voltage_8_150k_rec_opt: { value: form.cfgVoltage8150KRecOpt.value } } : {}),

				...(form.cfgStdEnable.value !== cfgStdEnable ? { cfg_std_data_rec_enable: { value: Number(form.cfgStdEnable.value) } } : {}),
				...(form.cfgStdType.value !== cfgStdType ? { cfg_std_type: { value: form.cfgStdType.value } } : {}),
				...(form.cfgStdAggregationWaveformEnable.value !== cfgStdAggregationWaveformEnable ? { cfg_std_waveforms_and_rms12_rec_enable: { value: Number(form.cfgStdAggregationWaveformEnable.value) } } : {}),

				...((Object.values(iecPayload).length > 0 && cfgEthernetIpAddress !== cfgIec61850IpAddress) ? { cfg_iec61850_ip_address: { value: cfgEthernetIpAddress } } : {}),
				...iecPayload,

				...(form.cfgDigitalInputInternal1ActiveState.value !== cfgDigitalInputInternal1ActiveState ? { cfg_digital_input_internal1_active_state: { value: form.cfgDigitalInputInternal1ActiveState.value } } : {}),
				...(form.cfgDigitalInputInternal1RecOpt.value !== cfgDigitalInputInternal1RecOpt ? { cfg_digital_input_internal1_rec_opt: { value: form.cfgDigitalInputInternal1RecOpt.value } } : {}),
				...(form.cfgDigitalInputInternal2ActiveState.value !== cfgDigitalInputInternal2ActiveState ? { cfg_digital_input_internal2_active_state: { value: form.cfgDigitalInputInternal2ActiveState.value } } : {}),
				...(form.cfgDigitalInputInternal2RecOpt.value !== cfgDigitalInputInternal2RecOpt ? { cfg_digital_input_internal2_rec_opt: { value: form.cfgDigitalInputInternal2RecOpt.value } } : {}),
				...(form.cfgRelay1ActiveState.value !== cfgRelay1ActiveState ? { cfg_relay1_active_state: { value: form.cfgRelay1ActiveState.value } } : {}),
				...(form.cfgRelay1Mode.value !== cfgRelay1Mode ? { cfg_relay1_mode: { value: form.cfgRelay1Mode.value } } : {}),
				...(form.cfgRelay1ActiveTime.value !== cfgRelay1ActiveTime ? { cfg_relay1_active_time: { value: +form.cfgRelay1ActiveTime.value } } : {}),
				...(form.cfgRelay1DeadTime.value !== cfgRelay1DeadTime ? { cfg_relay1_dead_time: { value: +form.cfgRelay1DeadTime.value } } : {}),
				...(form.cfgRelay2ActiveState.value !== cfgRelay2ActiveState ? { cfg_relay2_active_state: { value: form.cfgRelay2ActiveState.value } } : {}),
				...(form.cfgRelay2Mode.value !== cfgRelay2Mode ? { cfg_relay2_mode: { value: form.cfgRelay2Mode.value } } : {}),
				...(form.cfgRelay2ActiveTime.value !== cfgRelay2ActiveTime ? { cfg_relay2_active_time: { value: +form.cfgRelay2ActiveTime.value } } : {}),
				...(form.cfgRelay2DeadTime.value !== cfgRelay2DeadTime ? { cfg_relay2_dead_time: { value: +form.cfgRelay2DeadTime.value } } : {}),
			},
		});

		toggleConfirmModal(false);
	};

	if (length === 0) return null;

	return (
		<>
			<div
				className={
					classNames(
						className,
						"flex gap-2 items-center",
					)
				}
			>
				<span className="font-semibold text-sm text-gray-500">{ t("SETTINGS.pending configuration") }:</span>
				<Button
					className="animate-bg-pulse"
					color="primary"
					onClick={ () => toggleConfirmModal(true) }
				>
					{ `${ t("SETTINGS.save changes") } (${ length })` }
				</Button>
				<Button
					color="primary"
					outline
					onClick={ () => toggleDiscardModal(true) }
				>
					{ t("SETTINGS.discard") }
				</Button>
			</div>
			<ConfirmModal
				title={ t("SETTINGS.discard configuration changes") }
				warning={ t("SETTINGS.discard configuration warning") }
				isOpen={ isDiscardModalOpen }
				handleClose={ () => toggleDiscardModal(false) }
				confirmText={ t("SETTINGS.discard") }
				onConfirm={ () => {
					setForm({
						type: createFormField(form.type.initialValue),
						nominalFrequency: createFormField(form.nominalFrequency.initialValue),
						nominalVoltage: createFormField(form.nominalVoltage.initialValue),
						unpeChannelEnable: createFormField(form.unpeChannelEnable.initialValue),
						voltageTransducerRatio: createFormField(form.voltageTransducerRatio.initialValue),
						currentCh1TransducerRatio: createFormField(form.currentCh1TransducerRatio.initialValue),
						currentCh2TransducerRatio: createFormField(form.currentCh2TransducerRatio.initialValue),
						currentCh3TransducerRatio: createFormField(form.currentCh3TransducerRatio.initialValue),
						currentCh4TransducerRatio: createFormField(form.currentCh4TransducerRatio.initialValue),
						currentCh5TransducerRatio: createFormField(form.currentCh5TransducerRatio.initialValue),
						ch4Enabled: createFormField(form.ch4Enabled.initialValue),
						ch5Enabled: createFormField(form.ch5Enabled.initialValue),
						phaseRotation: createFormField(form.phaseRotation.initialValue),

						eventsHysteresis: createFormField(form.eventsHysteresis.initialValue),
						waveformFormat: createFormField(form.waveformFormat.initialValue),
						waveformRecDuration: createFormField(form.waveformRecDuration.initialValue),
						waveformPretriggerDuration: createFormField(form.waveformPretriggerDuration.initialValue),
						rms12RecTime: createFormField(form.rms12RecTime.initialValue),
						rms12PretriggerTime: createFormField(form.rms12PretriggerTime.initialValue),

						userRecordingPerc: createFormField(form.userRecordingPerc.initialValue),
						stdRecordingPerc: createFormField(form.stdRecordingPerc.initialValue),
						energyCountersPerc: createFormField(form.energyCountersPerc.initialValue),

						userDataModel: createFormField(form.userDataModel.initialValue),
						stdDataModel: createFormField(form.stdDataModel.initialValue),
						energyDataModel: createFormField(form.energyDataModel.initialValue),

						tcpEnabled: createFormField(form.tcpEnabled.initialValue),
						tcpSlaveAddress: createFormField(form.tcpSlaveAddress.initialValue),
						tcpPort: createFormField(form.tcpPort.initialValue),
						tcpEndianness: createFormField(form.tcpEndianness.initialValue),

						rtuEnabled: createFormField(form.rtuEnabled.initialValue),
						rtuSlaveAddress: createFormField(form.rtuSlaveAddress.initialValue),
						rtuBaudrate: createFormField(form.rtuBaudrate.initialValue),
						rtuParity: createFormField(form.rtuParity.initialValue),
						rtuEndianness: createFormField(form.rtuEndianness.initialValue),

						ftpEnable: createFormField(form.ftpEnable.initialValue),
						ftpConnectionType: createFormField(form.ftpConnectionType.initialValue),
						ftpMode: createFormField(form.ftpMode.initialValue),
						ftpUploadTime: createFormField(form.ftpUploadTime.initialValue),
						ftpServerIp: createFormField(form.ftpServerIp.initialValue),
						ftpServerPort: createFormField(form.ftpServerPort.initialValue),
						ftpUserName: createFormField(form.ftpUserName.initialValue),
						ftpPassword: createFormField(form.ftpPassword.initialValue),
						ftpDestDirectory: createFormField(form.ftpDestDirectory.initialValue),

						rtcUtcTimestamp: createFormField(form.rtcUtcTimestamp.initialValue),
						timeSyncMode: createFormField(form.timeSyncMode.initialValue),
						timezone: createFormField(form.timezone.initialValue),
						daylightSavingsTime: createFormField(form.daylightSavingsTime.initialValue),
						NTPEnable: createFormField(form.NTPEnable.initialValue),
						NTPServer1Name: createFormField(form.NTPServer1Name.initialValue),
						NTPServer2Name: createFormField(form.NTPServer2Name.initialValue),
						NTPServer3Name: createFormField(form.NTPServer3Name.initialValue),
						NTPServer4Name: createFormField(form.NTPServer4Name.initialValue),

						cfgUserEnable: createFormField(form.cfgUserEnable.initialValue),
						cfgAggregationPeriodUser: createFormField(form.cfgAggregationPeriodUser.initialValue),
						cfgUserAggregationWaveformEnable: createFormField(form.cfgUserAggregationWaveformEnable.initialValue),
						cfgPowerCalculationMethod: createFormField(form.cfgPowerCalculationMethod.initialValue),

						cfgVoltageRmsRecOpt: createFormField(form.cfgVoltageRmsRecOpt.initialValue),
						cfgVoltageRmsEventThresholdUserDip: createFormField(form.cfgVoltageRmsEventThresholdUserDip.initialValue),
						cfgVoltageRmsEventThresholdUserSwell: createFormField(form.cfgVoltageRmsEventThresholdUserSwell.initialValue),
						cfgVoltageRmsEventThresholdUserInterruption: createFormField(form.cfgVoltageRmsEventThresholdUserInterruption.initialValue),
						cfgVoltageRmsEventThresholdMaxCh4: createFormField(form.cfgVoltageRmsEventThresholdMaxCh4.initialValue),
						cfgDipChannels: createFormField(form.cfgDipChannels.initialValue),
						cfgDipActions: createFormField(form.cfgDipActions.initialValue),
						cfgSwellChannels: createFormField(form.cfgSwellChannels.initialValue),
						cfgSwellActions: createFormField(form.cfgSwellActions.initialValue),
						cfgInterruptChannels: createFormField(form.cfgInterruptChannels.initialValue),
						cfgInterruptActions: createFormField(form.cfgInterruptActions.initialValue),
						cfgVoltageDcRecOpt: createFormField(form.cfgVoltageDcRecOpt.initialValue),
						cfgWaveshapeVariationsRecOpt: createFormField(form.cfgWaveshapeVariationsRecOpt.initialValue),
						cfgWaveshapeVariationsDeadtime: createFormField(form.cfgWaveshapeVariationsDeadtime.initialValue),
						cfgWaveshapeVariationsThreshold: createFormField(form.cfgWaveshapeVariationsThreshold.initialValue),
						cfgWaveshapeVariationsChannels: createFormField(form.cfgWaveshapeVariationsChannels.initialValue),
						cfgWaveshapeVariationsActions: createFormField(form.cfgWaveshapeVariationsActions.initialValue),
						cfgPhaseJumpsRecOpt: createFormField(form.cfgPhaseJumpsRecOpt.initialValue),
						cfgPhaseJumpsThreshold: createFormField(form.cfgPhaseJumpsThreshold.initialValue),
						cfgPhaseJumpsChannels: createFormField(form.cfgPhaseJumpsChannels.initialValue),
						cfgPhaseJumpsActions: createFormField(form.cfgPhaseJumpsActions.initialValue),
						cfgFrequencyRecOpt: createFormField(form.cfgFrequencyRecOpt.initialValue),
						cfgFrequencyEventThresholdMin: createFormField(form.cfgFrequencyEventThresholdMin.initialValue),
						cfgFrequencyEventThresholdMax: createFormField(form.cfgFrequencyEventThresholdMax.initialValue),
						cfgFrequencyChannels: createFormField(form.cfgFrequencyChannels.initialValue),
						cfgFrequencyActions: createFormField(form.cfgFrequencyActions.initialValue),
						cfgVoltageCrestFactorRecOpt: createFormField(form.cfgVoltageCrestFactorRecOpt.initialValue),
						cfgVoltageUnbalanceRecOpt: createFormField(form.cfgVoltageUnbalanceRecOpt.initialValue),
						cfgVoltageUnbalanceThresholdMax: createFormField(form.cfgVoltageUnbalanceThresholdMax.initialValue),
						cfgFlickerPstRecOpt: createFormField(form.cfgFlickerPstRecOpt.initialValue),
						cfgFlickerPstThresholdMax: createFormField(form.cfgFlickerPstThresholdMax.initialValue),
						cfgFlickerPstChannels: createFormField(form.cfgFlickerPstChannels.initialValue),
						cfgFlickerPstActions: createFormField(form.cfgFlickerPstActions.initialValue),
						cfgFlickerPltRecOpt: createFormField(form.cfgFlickerPltRecOpt.initialValue),
						cfgFlickerPltThresholdMax: createFormField(form.cfgFlickerPltThresholdMax.initialValue),
						cfgFlickerPltChannels: createFormField(form.cfgFlickerPltChannels.initialValue),
						cfgFlickerPltActions: createFormField(form.cfgFlickerPltActions.initialValue),
						cfgCurrentRmsRecOpt: createFormField(form.cfgCurrentRmsRecOpt.initialValue),
						cfgCurrentRmsEventThresholdMax: createFormField(form.cfgCurrentRmsEventThresholdMax.initialValue),
						cfgCurrentRmsEventThresholdMaxCh4: createFormField(form.cfgCurrentRmsEventThresholdMaxCh4.initialValue),
						cfgCurrentRmsEventThresholdMaxCh5: createFormField(form.cfgCurrentRmsEventThresholdMaxCh5.initialValue),
						cfgCurrentChannels: createFormField(form.cfgCurrentChannels.initialValue),
						cfgCurrentActions: createFormField(form.cfgCurrentActions.initialValue),
						cfgCurrentCrestFactorRecOpt: createFormField(form.cfgCurrentCrestFactorRecOpt.initialValue),
						cfgCurrentUnbalanceRecOpt: createFormField(form.cfgCurrentUnbalanceRecOpt.initialValue),
						cfgActivePowerRecOpt: createFormField(form.cfgActivePowerRecOpt.initialValue),
						cfgActivePowerEventThresholdMax: createFormField(form.cfgActivePowerEventThresholdMax.initialValue),
						cfgActivePowerPlusRecOpt: createFormField(form.cfgActivePowerPlusRecOpt.initialValue),
						cfgActivePowerPlusEventThresholdMax: createFormField(form.cfgActivePowerPlusEventThresholdMax.initialValue),
						cfgActivePowerPlusChannels: createFormField(form.cfgActivePowerPlusChannels.initialValue),
						cfgActivePowerPlusActions: createFormField(form.cfgActivePowerPlusActions.initialValue),
						cfgActivePowerMinusRecOpt: createFormField(form.cfgActivePowerMinusRecOpt.initialValue),
						cfgActivePowerMinusEventThresholdMax: createFormField(form.cfgActivePowerMinusEventThresholdMax.initialValue),
						cfgActivePowerMinusChannels: createFormField(form.cfgActivePowerMinusChannels.initialValue),
						cfgActivePowerMinusActions: createFormField(form.cfgActivePowerMinusActions.initialValue),
						cfgActivePowerFundamentalRecOpt: createFormField(form.cfgActivePowerFundamentalRecOpt.initialValue),
						cfgApparentPowerRecOpt: createFormField(form.cfgApparentPowerRecOpt.initialValue),
						cfgApparentPowerEventThresholdMax: createFormField(form.cfgApparentPowerEventThresholdMax.initialValue),
						cfgApparentPowerChannels: createFormField(form.cfgApparentPowerChannels.initialValue),
						cfgApparentPowerActions: createFormField(form.cfgApparentPowerActions.initialValue),
						cfgApparentPowerFundamentalRecOpt: createFormField(form.cfgApparentPowerFundamentalRecOpt.initialValue),
						cfgApparentPowerNonfundamentalRecOpt: createFormField(form.cfgApparentPowerNonfundamentalRecOpt.initialValue),
						cfgDistortionPowerBudeanuRecOpt: createFormField(form.cfgDistortionPowerBudeanuRecOpt.initialValue),
						cfgReactivePowerRecOpt: createFormField(form.cfgReactivePowerRecOpt.initialValue),
						cfgReactivePowerEventThresholdMax: createFormField(form.cfgReactivePowerEventThresholdMax.initialValue),
						cfgReactivePowerChannels: createFormField(form.cfgReactivePowerChannels.initialValue),
						cfgReactivePowerActions: createFormField(form.cfgReactivePowerActions.initialValue),
						cfgPowerFactorRecOpt: createFormField(form.cfgPowerFactorRecOpt.initialValue),
						cfgPowerFactorEventThresholdMax: createFormField(form.cfgPowerFactorEventThresholdMax.initialValue),
						cfgPowerFactorChannels: createFormField(form.cfgPowerFactorChannels.initialValue),
						cfgPowerFactorActions: createFormField(form.cfgPowerFactorActions.initialValue),
						cfgPowerFactorFundamentalRecOpt: createFormField(form.cfgPowerFactorFundamentalRecOpt.initialValue),
						cfgDisplacementPowerFactorRecOpt: createFormField(form.cfgDisplacementPowerFactorRecOpt.initialValue),
						cfgDisplacementPowerFactorEventThresholdMax: createFormField(form.cfgDisplacementPowerFactorEventThresholdMax.initialValue),
						cfgDisplacementPowerFactorChannels: createFormField(form.cfgDisplacementPowerFactorChannels.initialValue),
						cfgDisplacementPowerFactorActions: createFormField(form.cfgDisplacementPowerFactorActions.initialValue),
						cfgTangent4QRecOpt: createFormField(form.cfgTangent4QRecOpt.initialValue),
						// cfgTangentQuadrant1EventThresholdMin: createFormField(form.cfgTangentQuadrant1EventThresholdMin.initialValue),
						// cfgTangentQuadrant1EventThresholdMax: createFormField(form.cfgTangentQuadrant1EventThresholdMax.initialValue),
						// cfgTangentQuadrant2EventThresholdMin: createFormField(form.cfgTangentQuadrant2EventThresholdMin.initialValue),
						// cfgTangentQuadrant2EventThresholdMax: createFormField(form.cfgTangentQuadrant2EventThresholdMax.initialValue),
						// cfgTangentQuadrant3EventThresholdMin: createFormField(form.cfgTangentQuadrant3EventThresholdMin.initialValue),
						// cfgTangentQuadrant3EventThresholdMax: createFormField(form.cfgTangentQuadrant3EventThresholdMax.initialValue),
						// cfgTangentQuadrant4EventThresholdMin: createFormField(form.cfgTangentQuadrant4EventThresholdMin.initialValue),
						// cfgTangentQuadrant4EventThresholdMax: createFormField(form.cfgTangentQuadrant4EventThresholdMax.initialValue),
						cfgActiveEnergyRecOpt: createFormField(form.cfgActiveEnergyRecOpt.initialValue),
						cfgActiveEnergyPlusEventThresholdMax: createFormField(form.cfgActiveEnergyPlusEventThresholdMax.initialValue),
						cfgActiveEnergyMinusEventThresholdMax: createFormField(form.cfgActiveEnergyMinusEventThresholdMax.initialValue),
						cfgApparentEnergyRecOpt: createFormField(form.cfgApparentEnergyRecOpt.initialValue),
						cfgApparentEnergyEventThresholdMax: createFormField(form.cfgApparentEnergyEventThresholdMax.initialValue),
						cfgReactiveEnergy4QRecOpt: createFormField(form.cfgReactiveEnergy4QRecOpt.initialValue),
						cfgReactiveEnergyQuadrant1EventThresholdMax: createFormField(form.cfgReactiveEnergyQuadrant1EventThresholdMax.initialValue),
						cfgReactiveEnergyQuadrant2EventThresholdMax: createFormField(form.cfgReactiveEnergyQuadrant2EventThresholdMax.initialValue),
						cfgReactiveEnergyQuadrant3EventThresholdMax: createFormField(form.cfgReactiveEnergyQuadrant3EventThresholdMax.initialValue),
						cfgReactiveEnergyQuadrant4EventThresholdMax: createFormField(form.cfgReactiveEnergyQuadrant4EventThresholdMax.initialValue),
						cfgVoltageThdfRecOpt: createFormField(form.cfgVoltageThdfRecOpt.initialValue),
						cfgVoltageThdfThresholdMax: createFormField(form.cfgVoltageThdfThresholdMax.initialValue),
						cfgVoltageThdfChannels: createFormField(form.cfgVoltageThdfChannels.initialValue),
						cfgVoltageThdfActions: createFormField(form.cfgVoltageThdfActions.initialValue),
						cfgVoltageThdrRecOpt: createFormField(form.cfgVoltageThdrRecOpt.initialValue),
						cfgVoltageHarmonicsRecOpt: createFormField(form.cfgVoltageHarmonicsRecOpt.initialValue),
						cfgCurrentThdfRecOpt: createFormField(form.cfgCurrentThdfRecOpt.initialValue),
						cfgCurrentThdfThresholdMax: createFormField(form.cfgCurrentThdfThresholdMax.initialValue),
						cfgCurrentThdfChannels: createFormField(form.cfgCurrentThdfChannels.initialValue),
						cfgCurrentThdfActions: createFormField(form.cfgCurrentThdfActions.initialValue),
						cfgCurrentThdrRecOpt: createFormField(form.cfgCurrentThdrRecOpt.initialValue),
						cfgCurrentHarmonicsRecOpt: createFormField(form.cfgCurrentHarmonicsRecOpt.initialValue),
						cfgActivePowerHarmonicsRecOpt: createFormField(form.cfgActivePowerHarmonicsRecOpt.initialValue),
						cfgHarmonicsAnglesUiRecOpt: createFormField(form.cfgHarmonicsAnglesUiRecOpt.initialValue),
						cfgReactivePowerHarmonicsRecOpt: createFormField(form.cfgReactivePowerHarmonicsRecOpt.initialValue),
						cfgVoltageTidfRecOpt: createFormField(form.cfgVoltageTidfRecOpt.initialValue),
						cfgVoltageTidrRecOpt: createFormField(form.cfgVoltageTidrRecOpt.initialValue),
						cfgCurrentTidfRecOpt: createFormField(form.cfgCurrentTidfRecOpt.initialValue),
						cfgCurrentTidrRecOpt: createFormField(form.cfgCurrentTidrRecOpt.initialValue),
						cfgVoltageInterharmonicsRecOpt: createFormField(form.cfgVoltageInterharmonicsRecOpt.initialValue),
						cfgCurrentInterharmonicsRecOpt: createFormField(form.cfgCurrentInterharmonicsRecOpt.initialValue),
						cfgKfactorRecOpt: createFormField(form.cfgKfactorRecOpt.initialValue),
						cfgPhasorRecOpt: createFormField(form.cfgPhasorRecOpt.initialValue),

						cfgMainsSignallingUr1RecOpt: createFormField(form.cfgMainsSignallingUr1RecOpt.initialValue),
						cfgMainsSignallingUr1Frequency: createFormField(form.cfgMainsSignallingUr1Frequency.initialValue),
						cfgMainsSignallingUr2RecOpt: createFormField(form.cfgMainsSignallingUr2RecOpt.initialValue),
						cfgMainsSignallingUr2Frequency: createFormField(form.cfgMainsSignallingUr2Frequency.initialValue),

						cfgVoltage29KRecOpt: createFormField(form.cfgVoltage29KRecOpt.initialValue),
						cfgVoltage8150KRecOpt: createFormField(form.cfgVoltage8150KRecOpt.initialValue),

						cfgStdEnable: createFormField(form.cfgStdEnable.initialValue),
						cfgStdType: createFormField(form.cfgStdType.initialValue),
						cfgStdAggregationWaveformEnable: createFormField(form.cfgStdAggregationWaveformEnable.initialValue),

						cfgIec61850Enable: createFormField(form.cfgIec61850Enable.initialValue),
						cfgIec61850IedName: createFormField(form.cfgIec61850IedName.initialValue),
						cfgIec61850PhaseVoltageDbRef: createFormField(form.cfgIec61850PhaseVoltageDbRef.initialValue),
						cfgIec61850PhaseVoltageDb: createFormField(form.cfgIec61850PhaseVoltageDb.initialValue),
						cfgIec61850PhaseToPhaseVoltageDbRef: createFormField(form.cfgIec61850PhaseToPhaseVoltageDbRef.initialValue),
						cfgIec61850PhaseToPhaseVoltageDb: createFormField(form.cfgIec61850PhaseToPhaseVoltageDb.initialValue),
						cfgIec61850UneVoltageDbRef: createFormField(form.cfgIec61850UneVoltageDbRef.initialValue),
						cfgIec61850UneVoltageDb: createFormField(form.cfgIec61850UneVoltageDb.initialValue),
						cfgIec61850PhaseCurrentDbRef: createFormField(form.cfgIec61850PhaseCurrentDbRef.initialValue),
						cfgIec61850PhaseCurrentDb: createFormField(form.cfgIec61850PhaseCurrentDb.initialValue),
						cfgIec61850NCurrentDbRef: createFormField(form.cfgIec61850NCurrentDbRef.initialValue),
						cfgIec61850NCurrentDb: createFormField(form.cfgIec61850NCurrentDb.initialValue),
						cfgIec61850ECurrentDbRef: createFormField(form.cfgIec61850ECurrentDbRef.initialValue),
						cfgIec61850ECurrentDb: createFormField(form.cfgIec61850ECurrentDb.initialValue),
						cfgIec61850ActivePowerTotalDbRef: createFormField(form.cfgIec61850ActivePowerTotalDbRef.initialValue),
						cfgIec61850ActivePowerTotalDb: createFormField(form.cfgIec61850ActivePowerTotalDb.initialValue),
						cfgIec61850ReactivePowerTotalDbRef: createFormField(form.cfgIec61850ReactivePowerTotalDbRef.initialValue),
						cfgIec61850ReactivePowerTotalDb: createFormField(form.cfgIec61850ReactivePowerTotalDb.initialValue),
						cfgIec61850ApparentPowerTotalDbRef: createFormField(form.cfgIec61850ApparentPowerTotalDbRef.initialValue),
						cfgIec61850ApparentPowerTotalDb: createFormField(form.cfgIec61850ApparentPowerTotalDb.initialValue),
						cfgIec61850ActivePowerPhaseDbRef: createFormField(form.cfgIec61850ActivePowerPhaseDbRef.initialValue),
						cfgIec61850ActivePowerPhaseDb: createFormField(form.cfgIec61850ActivePowerPhaseDb.initialValue),
						cfgIec61850ReactivePowerPhaseDbRef: createFormField(form.cfgIec61850ReactivePowerPhaseDbRef.initialValue),
						cfgIec61850ReactivePowerPhaseDb: createFormField(form.cfgIec61850ReactivePowerPhaseDb.initialValue),
						cfgIec61850ApparentPowerPhaseDbRef: createFormField(form.cfgIec61850ApparentPowerPhaseDbRef.initialValue),
						cfgIec61850ApparentPowerPhaseDb: createFormField(form.cfgIec61850ApparentPowerPhaseDb.initialValue),
						cfgIec61850FrequencyDbRef: createFormField(form.cfgIec61850FrequencyDbRef.initialValue),
						cfgIec61850FrequencyDb: createFormField(form.cfgIec61850FrequencyDb.initialValue),
						cfgIec61850VoltageHarmonicsDbRef: createFormField(form.cfgIec61850VoltageHarmonicsDbRef.initialValue),
						cfgIec61850VoltageHarmonicsDb: createFormField(form.cfgIec61850VoltageHarmonicsDb.initialValue),
						cfgIec61850CurrentHarmonicsDbRef: createFormField(form.cfgIec61850CurrentHarmonicsDbRef.initialValue),
						cfgIec61850CurrentHarmonicsDb: createFormField(form.cfgIec61850CurrentHarmonicsDb.initialValue),
						cfgIec61850VoltageInterharmonicsDbRef: createFormField(form.cfgIec61850VoltageInterharmonicsDbRef.initialValue),
						cfgIec61850VoltageInterharmonicsDb: createFormField(form.cfgIec61850VoltageInterharmonicsDb.initialValue),
						cfgIec61850CurrentInterharmonicsDbRef: createFormField(form.cfgIec61850CurrentInterharmonicsDbRef.initialValue),
						cfgIec61850CurrentInterharmonicsDb: createFormField(form.cfgIec61850CurrentInterharmonicsDb.initialValue),
						cfgIec61850VoltageThdDbRef: createFormField(form.cfgIec61850VoltageThdDbRef.initialValue),
						cfgIec61850VoltageThdDb: createFormField(form.cfgIec61850VoltageThdDb.initialValue),
						cfgIec61850CurrentThdDbRef: createFormField(form.cfgIec61850CurrentThdDbRef.initialValue),
						cfgIec61850CurrentThdDb: createFormField(form.cfgIec61850CurrentThdDb.initialValue),
						cfgIec61850VoltageTidDbRef: createFormField(form.cfgIec61850VoltageTidDbRef.initialValue),
						cfgIec61850VoltageTidDb: createFormField(form.cfgIec61850VoltageTidDb.initialValue),
						cfgIec61850CurrentTidDbRef: createFormField(form.cfgIec61850CurrentTidDbRef.initialValue),
						cfgIec61850CurrentTidDb: createFormField(form.cfgIec61850CurrentTidDb.initialValue),
						cfgIec61850UnbalanceVoltagePositiveSequenceDbRef: createFormField(form.cfgIec61850UnbalanceVoltagePositiveSequenceDbRef.initialValue),
						cfgIec61850UnbalanceVoltagePositiveSequenceDb: createFormField(form.cfgIec61850UnbalanceVoltagePositiveSequenceDb.initialValue),
						cfgIec61850UnbalanceVoltageNegativeSequenceDbRef: createFormField(form.cfgIec61850UnbalanceVoltageNegativeSequenceDbRef.initialValue),
						cfgIec61850UnbalanceVoltageNegativeSequenceDb: createFormField(form.cfgIec61850UnbalanceVoltageNegativeSequenceDb.initialValue),
						cfgIec61850UnbalanceVoltageZeroSequenceDbRef: createFormField(form.cfgIec61850UnbalanceVoltageZeroSequenceDbRef.initialValue),
						cfgIec61850UnbalanceVoltageZeroSequenceDb: createFormField(form.cfgIec61850UnbalanceVoltageZeroSequenceDb.initialValue),
						cfgIec61850UnbalanceCurrentPositiveSequenceDbRef: createFormField(form.cfgIec61850UnbalanceCurrentPositiveSequenceDbRef.initialValue),
						cfgIec61850UnbalanceCurrentPositiveSequenceDb: createFormField(form.cfgIec61850UnbalanceCurrentPositiveSequenceDb.initialValue),
						cfgIec61850UnbalanceCurrentNegativeSequenceDbRef: createFormField(form.cfgIec61850UnbalanceCurrentNegativeSequenceDbRef.initialValue),
						cfgIec61850UnbalanceCurrentNegativeSequenceDb: createFormField(form.cfgIec61850UnbalanceCurrentNegativeSequenceDb.initialValue),
						cfgIec61850UnbalanceCurrentZeroSequenceDbRef: createFormField(form.cfgIec61850UnbalanceCurrentZeroSequenceDbRef.initialValue),
						cfgIec61850UnbalanceCurrentZeroSequenceDb: createFormField(form.cfgIec61850UnbalanceCurrentZeroSequenceDb.initialValue),
						cfgIec61850UnbalanceCurrentNegativeDbRef: createFormField(form.cfgIec61850UnbalanceCurrentNegativeDbRef.initialValue),
						cfgIec61850UnbalanceCurrentNegativeDb: createFormField(form.cfgIec61850UnbalanceCurrentNegativeDb.initialValue),
						cfgIec61850FlickerPstDbRef: createFormField(form.cfgIec61850FlickerPstDbRef.initialValue),
						cfgIec61850FlickerPstDb: createFormField(form.cfgIec61850FlickerPstDb.initialValue),
						cfgIec61850FlickerPltDbRef: createFormField(form.cfgIec61850FlickerPltDbRef.initialValue),
						cfgIec61850FlickerPltDb: createFormField(form.cfgIec61850FlickerPltDb.initialValue),
						cfgIec6185029KhzVoltageDbRef: createFormField(form.cfgIec6185029KhzVoltageDbRef.initialValue),
						cfgIec6185029KhzVoltageDb: createFormField(form.cfgIec6185029KhzVoltageDb.initialValue),
						cfgIec6185029KhzVoltageMaxDbRef: createFormField(form.cfgIec6185029KhzVoltageMaxDbRef.initialValue),
						cfgIec6185029KhzVoltageMaxDb: createFormField(form.cfgIec6185029KhzVoltageMaxDb.initialValue),
						cfgIec618509150KhzVoltageDbRef: createFormField(form.cfgIec618509150KhzVoltageDbRef.initialValue),
						cfgIec618509150KhzVoltageDb: createFormField(form.cfgIec618509150KhzVoltageDb.initialValue),
						cfgIec618509150KhzVoltageMaxDbRef: createFormField(form.cfgIec618509150KhzVoltageMaxDbRef.initialValue),
						cfgIec618509150KhzVoltageMaxDb: createFormField(form.cfgIec618509150KhzVoltageMaxDb.initialValue),
						cfgIec61850BatteryVoltageDbRef: createFormField(form.cfgIec61850BatteryVoltageDbRef.initialValue),
						cfgIec61850BatteryVoltageDb: createFormField(form.cfgIec61850BatteryVoltageDb.initialValue),

						cfgDigitalInputInternal1ActiveState: createFormField(form.cfgDigitalInputInternal1ActiveState.initialValue),
						cfgDigitalInputInternal1RecOpt: createFormField(form.cfgDigitalInputInternal1RecOpt.initialValue),
						cfgDigitalInputInternal2ActiveState: createFormField(form.cfgDigitalInputInternal2ActiveState.initialValue),
						cfgDigitalInputInternal2RecOpt: createFormField(form.cfgDigitalInputInternal2RecOpt.initialValue),
						cfgRelay1ActiveState: createFormField(form.cfgRelay1ActiveState.initialValue),
						cfgRelay1Mode: createFormField(form.cfgRelay1Mode.initialValue),
						cfgRelay1ActiveTime: createFormField(form.cfgRelay1ActiveTime.initialValue),
						cfgRelay1DeadTime: createFormField(form.cfgRelay1DeadTime.initialValue),
						cfgRelay2ActiveState: createFormField(form.cfgRelay2ActiveState.initialValue),
						cfgRelay2Mode: createFormField(form.cfgRelay2Mode.initialValue),
						cfgRelay2ActiveTime: createFormField(form.cfgRelay2ActiveTime.initialValue),
						cfgRelay2DeadTime: createFormField(form.cfgRelay2DeadTime.initialValue),
					});
					toggleDiscardModal(false);
				} }
			/>
			<ConfirmConfigurationModal
				isOpen={ isConfirmModalOpen }
				handleClose={ () => toggleConfirmModal(false) }
				onConfirm={ _handleSaveConfiguration }
			/>
		</>
	);
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {
	saveConfiguration: uiSetBufforConfiguration,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationSaver);
